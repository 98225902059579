import TLang from "model/application/Lang";

import eng from "./en.json";
import esp from "./es.json";
import fra from "./fr.json";

const en = eng as any as TLang;
const fr = fra as any as TLang;
const es = esp as any as TLang;

export { en, es, fr };
