import { useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";

import CustomDialogForm from "components/Dialog/CustomDialogForm";
import { formatString } from "lang/utils";
import TLang from "model/application/Lang";

import InputText, { INPUT_TEXT_TYPE } from "../InputText/InputText";
interface IImageURLModal {
  trigger: React.ReactElement;
  onAddURL: (url: string) => void;
}

const ImageURLModal = ({ trigger, onAddURL }: IImageURLModal) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState<string>("");

  const onConfirmAction = async () => {
    // This has been prevented in InputImage: cannot have canAddUrl = true and non-empty mimeTypes
    // if (_.isArray(mimeTypes)) {
    //   const fileType = await getFileMimeType(url);

    //   if (!_.includes(mimeTypes, fileType)) {
    //     const error = getInvalidFileTypeError(mimeTypes, lang);
    //     setErrorText(error);
    //     onAddURL("");
    //     setIsOpen(false);
    //   }
    //   return;
    // }

    onAddURL(url);
    setIsOpen(false);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} display="inline-block">
      <Box
        onClick={() => setIsOpen(true)}
        role="button"
        style={{ cursor: "pointer" }}
      >
        {trigger}
      </Box>
      <CustomDialogForm
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        title="Set picture URL"
        confirmBtnText="Confirm"
        onConfirmAction={onConfirmAction}
        lang={{ modal: { cancel: "Cancel" } } as TLang}
      >
        <InputText
          name={"picture_url"}
          type={INPUT_TEXT_TYPE.TEXT}
          onChange={(value: string) => setUrl(value)}
        />
      </CustomDialogForm>
    </Box>
  );
};

export const getInvalidFileTypeError = (mimeTypes: string[], lang: TLang) => {
  const fileExtensions = _.map(mimeTypes, (mimeType) => mimeType.split("/")[1]);

  return formatString(lang.components.inputImage.fileTypeNotSupported, [
    fileExtensions.join(", "),
  ]);
};

/**
 * WARNING: this does not work if the server does not have an open CORS policy
 * (and there is nothing we can do about it ?)
 */
// async function getFileMimeType(url: string) {
//   try {
//     const response = await fetch(url, { method: "HEAD" });
//     if (!response.ok) {
//       return null;
//     }
//     return response.headers.get("Content-Type");
//   } catch (e) {
//     return null;
//   }
// }

export default ImageURLModal;
