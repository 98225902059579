import React, { FormEvent, useState } from "react";

import "react-checkbox-tree/lib/react-checkbox-tree.css";

import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons/faMinusSquare";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import { faSquare } from "@fortawesome/free-solid-svg-icons/faSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckboxTree from "react-checkbox-tree";

import InputSearch from "components/Input/InputSearch";
import TLang from "model/application/Lang";
import { clone } from "utils/utils";

import { IFilter } from "../../../../model/application/Filter";
import FilterValidateButton from "./FilterValidateButton";
import styles from "./styles";
import { findExpandedHierarchy, getAllValues } from "./utils";

const useStyles = makeStyles(styles as any);

export interface Node {
  label: React.ReactNode;
  value: string;
  children?: Array<Node>;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  showCheckbox?: boolean;
  title?: string;
}

export interface IHierarchyFilterProps {
  filter: IFilter<string[]>;
  onChangeFilter: (name: string, value: any) => void;
  lang?: TLang;
}

export const HierarchyFilter: React.FunctionComponent<IHierarchyFilterProps> = (
  props: IHierarchyFilterProps
) => {
  const classes = useStyles();
  const { filter, onChangeFilter } = props;

  const [hierarchyTree, setHierarchyTree] = useState<
    Partial<{ checked: string[]; expanded: string[]; keyword: string }>
  >({
    checked: filter.value,
    expanded: [],
    keyword: "",
  });

  const [searchedNodes, setValue] = useState<{ node: Node[] }>({
    node: filter.hierarchy!,
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChangeFilter(filter.tag, hierarchyTree.checked);
  };

  const icons = {
    check: (
      <FontAwesomeIcon
        icon={faCheckSquare}
        className={`rct-icon rct-icon-check ${classes.IconColor}`}
      />
    ),
    uncheck: (
      <FontAwesomeIcon
        className={`rct-icon rct-icon-uncheck ${classes.IconColorUncheck}`}
        icon={faSquare}
      />
    ),
    halfCheck: (
      <FontAwesomeIcon
        className="rct-icon rct-icon-half-check"
        icon={faCheckSquare}
      />
    ),
    expandClose: (
      <FontAwesomeIcon
        className="rct-icon rct-icon-expand-close"
        icon={faPlusSquare}
      />
    ),
    expandOpen: (
      <FontAwesomeIcon
        className="rct-icon rct-icon-expand-open"
        icon={faMinusSquare}
      />
    ),
    expandAll: (
      <FontAwesomeIcon
        className="rct-icon rct-icon-expand-all"
        icon={faPlusSquare}
      />
    ),
    collapseAll: (
      <FontAwesomeIcon
        className="rct-icon rct-icon-collapse-all"
        icon={faMinusSquare}
      />
    ),
    parentClose: <span className={classes.DisplayNone} />,
    parentOpen: <span className={classes.DisplayNone} />,
    leaf: <span className={classes.DisplayNone} />,
  };

  //search
  const onChangeSearch = (search: string) => {
    const newNodes = findExpandedHierarchy(
      clone(props.filter.hierarchy!),
      search
    );
    setValue({ node: newNodes });
    setHierarchyTree({
      checked: hierarchyTree.checked,
      expanded: getAllValues(newNodes, []),
      keyword: search,
    });
  };

  //end of search
  return (
    <div className={classes.DropdownItem}>
      <form onSubmit={handleSubmit}>
        <InputSearch onChange={onChangeSearch} />

        <Box margin="12px 0">
          <CheckboxTree
            nodes={searchedNodes.node}
            checked={hierarchyTree.checked}
            expanded={hierarchyTree.expanded}
            onCheck={(checked: any) =>
              setHierarchyTree({
                checked: checked,
                expanded: hierarchyTree.expanded,
              })
            }
            onExpand={(expanded: any) =>
              setHierarchyTree({
                checked: hierarchyTree.checked,
                expanded: expanded,
              })
            }
            icons={icons}
          />
        </Box>

        <FilterValidateButton classes={classes} />
      </form>
    </div>
  );
};

export default HierarchyFilter;
