import useTranslations from "hooks/useTranslations";

import InputText from "../InputText/InputText";

/**
 * Fourth step (optional): default value for ME
 */
export interface IMetaExpressionWizardFourthStep {
  defaultMEValue?: string;
  onChangeDefaultMEValue: (value: string) => void;
}

export const MetaExpressionWizardFourthStep = ({
  defaultMEValue,
  onChangeDefaultMEValue,
}: IMetaExpressionWizardFourthStep) => {
  const lang = useTranslations();
  const metaExpressionWizardLang =
    lang.containers.workflows.subCategories.metaExpressions.createEditModal;

  return (
    <div>
      <InputText
        name="default_value"
        lang={metaExpressionWizardLang.inputDefaultValue}
        defaultValue={defaultMEValue}
        onChange={(value) => onChangeDefaultMEValue(value)}
      />
    </div>
  );
};
