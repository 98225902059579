export { LOGOUT_REQUEST_SUCCESS } from "../authentication/redux/actionTypes";

/**
 * Notification action types
 * */

export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_INFO = "NOTIFICATION_INFO";
export const NOTIFICATION_WARN = "NOTIFICATION_WARN";

const NOTIFICATION_ACTION_TYPE = {
  SUCCESS: NOTIFICATION_SUCCESS,
  ERROR: NOTIFICATION_ERROR,
  INFO: NOTIFICATION_INFO,
  WARN: NOTIFICATION_WARN,
};

export type TNotificationActionType =
  (typeof NOTIFICATION_ACTION_TYPE)[keyof typeof NOTIFICATION_ACTION_TYPE];

export default NOTIFICATION_ACTION_TYPE;
