import { useEffect } from "react";

import Table from "@material-ui/core/Table";

import { TPivotInfo } from "components/Dashboard/Matrix/MatrixChart";
import CustomTableBody from "components/Table/CustomTable/CustomTableBody";
import CustomTableHeader from "components/Table/CustomTable/CustomTableHeader";
import { formatColumnTypesForMatrix } from "components/Table/utils";
import { AGGREGATOR, AGGREGATOR_DIMENSION } from "model/entities/Dashboard";

import { aggregateData, aggregatePivot, prepareFullMatrix } from "./utils";

export type MatrixData = {
  column: string;
  row: string;
  data: { [attrname: string]: number | string }[];
}[];

export interface ICustomMatrixProps {
  data: MatrixData;
  emptyMessage?: string;
  rowPivotInfo: TPivotInfo;
  columnPivotInfo: TPivotInfo;
  onMatrixDataLoaded: (data: any[][]) => void;
  aggregation: AGGREGATOR;
  aggregationDimension?: AGGREGATOR_DIMENSION; // COLUMN by default
}

const CustomMatrix = ({
  data,
  emptyMessage,
  rowPivotInfo,
  columnPivotInfo,
  aggregation,
  aggregationDimension,
  onMatrixDataLoaded,
}: ICustomMatrixProps) => {
  useEffect(
    () => {
      const dataForDownload = prepareFullMatrix(
        data,
        rowPivotInfo,
        columnPivotInfo,
        aggregationDimension === AGGREGATOR_DIMENSION.ROW
      ).fullMatrix.map((d) => Object.values(d));

      onMatrixDataLoaded(dataForDownload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // TODO: add key={JSON.stringify(data)} in parent if you need to refresh when data changes ?
      // Because there is an infinite loop if we just check reference equality here
      // data,
      aggregationDimension,
      onMatrixDataLoaded,
      columnPivotInfo,
      rowPivotInfo,
    ]
  );

  // Change the pivotInfos et the data objects according to the level of aggregation
  const rowPivotInfoAggregated = aggregatePivot(rowPivotInfo);
  const columnPivotInfoAggregated = aggregatePivot(columnPivotInfo);
  const dataAggregated = aggregateData(
    data,
    columnPivotInfo,
    rowPivotInfo,
    aggregation
  );

  const { fullMatrix, nbOfExtraColumns, nbOfExtraRows } = prepareFullMatrix(
    dataAggregated,
    rowPivotInfoAggregated,
    columnPivotInfoAggregated,
    aggregationDimension === AGGREGATOR_DIMENSION.ROW
  );

  const rowData = fullMatrix.slice(nbOfExtraRows);
  return (
    <Table>
      {Array.from(Array(nbOfExtraRows).keys()).map((i, index) => (
        <CustomTableHeader
          key={index}
          columnNames={Object.keys(fullMatrix[i]).map((att) => ({
            name: att,
            title: fullMatrix[i][att],
          }))}
        />
      ))}
      <CustomTableBody
        rowData={rowData}
        dataCount={rowData.length}
        totalCount={rowData.length}
        rowsPerPage={10000}
        columnTypes={formatColumnTypesForMatrix(
          fullMatrix.slice(nbOfExtraRows)
        )}
        page={0}
        emptyMessage={emptyMessage}
        hasCheckBox={false}
        columnsForBorder={nbOfExtraColumns}
      />
    </Table>
  );
};

export default CustomMatrix;
