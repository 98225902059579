import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { GreyDark } from "assets/colors";
import CellContent from "components/Table/Cell/CellContent";
import { TCategoryType, TColumnType, TRowType } from "components/Table/model";
import SimpleTable from "components/Table/SimpleTable";

export interface IMatrixViewer {
  title?: string;
  categories: TCategoryType[];
  rows: TRowType[];
  columns: TColumnType[];
}

const useStyles = makeStyles({
  MatrixViewerTitle: {
    fontSize: "16px",
    color: GreyDark,
    fontWeight: 500,
    marginBottom: "8px",
  },
});

const MatrixViewer = ({ title, categories, rows, columns }: IMatrixViewer) => {
  const classes = useStyles();

  if (!columns || !rows || _.isEmpty(rows)) {
    return null;
  }

  if (_.isEmpty(categories)) {
    return (
      <SimpleTable columns={columns} rows={rows} CellContent={CellContent} />
    );
  }

  return (
    <div>
      {title && <Box className={classes.MatrixViewerTitle}>{title}</Box>}

      {(() => {
        const rowsDisplayedInCategories: TRowType[] = [];

        const categoryRows = _.map(
          categories,
          ({ key: _category_id, label }) => {
            const categoryRows = _.filter(rows, { _category_id });
            rowsDisplayedInCategories.push(...categoryRows);
            return (
              <SimpleTable
                key={_category_id}
                title={label}
                columns={columns}
                rows={categoryRows}
                CellContent={CellContent}
              />
            );
          }
        );

        // if for some reason some rows have no matching category or is undefined, they should still show
        return (
          <>
            {categoryRows}
            <SimpleTable
              columns={columns}
              rows={_.without(rows, ...rowsDisplayedInCategories)}
              CellContent={CellContent}
            />
          </>
        );
      })()}
    </div>
  );
};

export default MatrixViewer;
