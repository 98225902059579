import React, { useState } from "react";

import {
  AccordionDetails,
  Divider,
  makeStyles,
  SvgIconProps,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Styles } from "@material-ui/core/styles/withStyles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/BarChart";
import ClientIcon from "@material-ui/icons/Business";
import TeamIcon from "@material-ui/icons/GroupWork";
import UnknownIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import PlaceIcon from "@material-ui/icons/Place";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import StorageIcon from "@material-ui/icons/Storage";
import UserIcon from "@material-ui/icons/SupervisorAccount";
import WorkflowIcon from "@material-ui/icons/Work";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as H from "history";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";

import { ERP_ROUTE, USERS_ROUTE } from "model/constants/routes";

import { ISidebarCategory } from "./Sidebar";
import styles from "./styles";
const useStyles = makeStyles(styles as Styles<unknown, object, string>);
export interface ISidebarItemProps {
  activeRoute: string;
  path: string;
  icon: string;
  title: string;
  open: boolean;
  changeSubCategoryFunction: (id: string) => void;
  subCategories: ISidebarCategory[];
  selectedSubCategory: string;
  disabled: boolean;
  onToggle?: (id: string) => boolean;
  lastClickedItem: (itemName: string) => boolean;
  history: H.History;
}

/**
 * SidebarItem stateless component. This represents a single Sidebar Item component on Sidebar
 * @param {Object} Properties object with path, icon and title
 */
export const SidebarItem: React.FunctionComponent<ISidebarItemProps> = ({
  activeRoute,
  path,
  icon,
  title,
  open,
  changeSubCategoryFunction,
  selectedSubCategory,
  subCategories,
  disabled,
  onToggle,
  history,
  lastClickedItem,
}: ISidebarItemProps) => {
  const lastClicked = lastClickedItem(title);

  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  let Icon: React.ComponentType<SvgIconProps> = InboxIcon;

  switch (icon) {
    case "calendar":
      Icon = CalendarMonthIcon as React.ComponentType<SvgIconProps>;
      break;
    case "home":
      Icon = HomeIcon;
      break;
    case "dashboard":
      Icon = DashboardIcon;
      break;
    case "environment":
      Icon = StorageIcon;
      break;
    case "person":
      Icon = UserIcon;
      break;
    case "group_work":
      Icon = TeamIcon;
      break;
    case "work":
      Icon = WorkflowIcon;
      break;
    case "list":
      Icon = ListIcon;
      break;
    case "erp":
      Icon = ShoppingCartOutlinedIcon;
      break;
    case "panorama":
      Icon = ShoppingCartOutlinedIcon;
      break;
    case "photo_camera":
      Icon = PhotoIcon;
      break;
    case "place":
      Icon = PlaceIcon;
      break;
    case "supervisor_account":
      Icon = ClientIcon;
      break;
    case "assignment":
      Icon = AssignmentIcon;
      break;
    default:
      Icon = UnknownIcon;
  }
  return (
    <div
      className={
        activeRoute
          ? classes.ExpansionPanelRootActive
          : classes.ExpansionPanelRoot
      }
    >
      <div
        onClick={(e) => {
          if (subCategories.length <= 1) {
            history.push(path);
          }
          if (disabled) {
            e.preventDefault();
          }
          if (onToggle) {
            if (onToggle(title)) {
              setExpand(!expand);
            } else {
              setExpand(true);
            }
          }
          if (subCategories[0]) {
            return changeSubCategoryFunction(subCategories[0].id);
          } else {
            return {};
          }
        }}
      >
        <ListItem
          button
          disabled={disabled}
          key={title}
          style={{
            alignItems: "center",
            display: "grid",
            gridTemplateColumns: "45px 140px auto",
          }}
        >
          <ListItemIcon
            className={
              activeRoute
                ? classes.SidebarItemIconActive
                : classes.SidebarItemIcon
            }
          >
            <Icon className={classes.ListItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={
              activeRoute
                ? classes.SidebarItemTextActive
                : classes.SidebarItemText
            }
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
              },
            }}
            primary={title}
          />
          <div>
            {subCategories?.length > 1 ? (
              expand && lastClicked ? (
                <ExpandLessIcon
                  className={
                    activeRoute
                      ? classes.SidebarItemIconActive
                      : classes.SidebarItemIcon
                  }
                />
              ) : (
                <ExpandMoreIcon
                  className={
                    activeRoute
                      ? classes.SidebarItemIconActive
                      : classes.SidebarItemIcon
                  }
                />
              )
            ) : null}
          </div>
        </ListItem>
      </div>
      <Divider />
      <PerfectScrollbar className={classes.SubcategoriesScroller}>
        {open && lastClicked && expand ? (
          subCategories.length > 1 &&
          subCategories.map((cat, i) => {
            return (
              <NavLink
                key={cat.id}
                to={
                  path === USERS_ROUTE || path === ERP_ROUTE
                    ? `${path}/${cat.id}`
                    : i !== 0
                    ? `${path}/${cat.id.toLowerCase().replace(/ /g, "_")}`
                    : path
                }
              >
                <AccordionDetails
                  onClick={() => {
                    changeSubCategoryFunction(cat.id);
                  }}
                  key={cat.id}
                  classes={{
                    root: classes.AccordionDetailsRoot,
                  }}
                >
                  <ListItem button disabled={disabled} key={cat.id}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText
                      className={
                        selectedSubCategory === cat.id
                          ? classes.SidebarItemTextActive
                          : classes.SidebarItemText
                      }
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                        },
                      }}
                      primary={cat.name}
                    />
                  </ListItem>
                </AccordionDetails>
              </NavLink>
            );
          })
        ) : (
          <></>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default SidebarItem;
