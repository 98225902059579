import { TagifySettings } from "@yaireo/tagify";
import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import { IMetaExpressionInTable } from "model/entities/MetaExpression";

import { IInputMetaExpressionProps } from "../InputMetaExpression";

interface IGetTagifySettings
  extends Pick<IInputMetaExpressionProps, "maxTags" | "disabled"> {
  metaExpressions: IMetaExpressionInTable[];
  type?: CUSTOM_FIELD_TYPE;
}
export const getTagifySettings = ({
  metaExpressions,
  disabled,
  maxTags,
  type,
}: IGetTagifySettings) => {
  const settings: TagifySettings = {
    pattern: /@|#/,
    //required,
    skipInvalid: true,
    mode: "mix",
    enforceWhitelist: true,
    userInput: !disabled,
    mixMode: {
      insertAfterTag: "", // to fix https://optimetriks.atlassian.net/browse/FP-4197
    },
    dropdown: {
      // since currently there is no way to filter the dropdown suggestion list
      // we disable it in case of a type restriction
      enabled: type ? false : 0,
      mapValueTo: "value",
      searchKeys: ["value"],
      highlightFirst: true,
      maxItems: 30,
    },
    autoComplete: {
      enabled: metaExpressions?.length > 0,
      rightKey: true,
    },
    duplicates: true,
    maxTags: maxTags ? maxTags : Infinity,
    editTags: 1,
    whitelist: _.map(metaExpressions, (me) => {
      return { value: me.title, expression: me.expression };
    }),
  };

  return settings;
};
