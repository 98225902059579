import React from "react";

import _ from "lodash";

import ICustomer from "model/entities/Customer";
import {
  ICustomerDetailForGPSTracking,
  IDashboard,
  IKPI,
  ITimelineActionsForGPSTracking,
} from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IMobileUser } from "model/entities/User";

import GeoTrackingChartCleanMatching from "./GeoTrackingChartCleanMatching";
import { prepareLocationData } from "./GeoTrackingChartContainer.utils";

export interface IUserPositionData {
  [userId: string]: {
    [date: string]: {
      lat: number;
      lng: number;
    };
  };
}

interface IGeoTrackingChartContainerProps {
  chart: IKPI;
  selectedUser?: string;
  userFilter: { key: string }[];
  customerList: IList<ICustomer>;
  index: number;
  users: IMobileUser[];
  changeSelectedUser: (idUser?: string) => void;
  onRunQuery?: (
    dashboard: IDashboard,
    query: any,
    callback?: any,
    degradedModeKpis?: string[]
  ) => Promise<any>;
  queryFilters?: any;
}

const GeoTrackingChartContainer: React.FunctionComponent<
  IGeoTrackingChartContainerProps
> = ({
  chart,
  selectedUser,
  userFilter,
  index,
  users,
  changeSelectedUser,
  onRunQuery,
  queryFilters,
}) => {
  const customersVisited: ICustomerDetailForGPSTracking[] = _.find(chart.data, {
    label: "customers_visited",
  })?.data;

  const timelineActionsData = _.find(chart.data, {
    label: "timeline_actions",
  });

  const userTimelineActions: ITimelineActionsForGPSTracking[] = _.filter(
    timelineActionsData?.data,
    { user_id: selectedUser }
  );

  const formattedData = prepareLocationData(
    chart,
    userFilter,
    userTimelineActions,
    customersVisited,
    selectedUser,
    false
  );

  const formattedDataSimplified = prepareLocationData(
    chart,
    userFilter,
    userTimelineActions,
    customersVisited,
    selectedUser,
    true
  );

  return (
    <GeoTrackingChartCleanMatching
      userFilter={userFilter}
      index={index}
      users={users}
      // TODO: move this state down
      selectedUser={selectedUser}
      changeSelectedUser={changeSelectedUser}
      formattedData={formattedData}
      simplifiedFormattedData={formattedDataSimplified}
      timelineActions={userTimelineActions}
      customersVisited={customersVisited}
      onRunQuery={onRunQuery}
      queryFilters={queryFilters}
    />
  );
};

export default GeoTrackingChartContainer;
