import {
  IClientBilling,
  IClientLicensePlan,
  IClientSupportPack,
} from "fieldpro-tools";
import _ from "lodash";
import uniq from "lodash/uniq";
import moment from "moment";

import apiService from "api/apiService";
import { LOGOUT_REQUEST_SUCCESS } from "containers/authentication/redux/actionTypes";
import { IClient, IInvoice, TLightClient } from "model/entities/Client";
import { IWebUser } from "model/entities/User";
import { IAction, IActionError } from "redux/store/model";

import { DATE_FORMAT_FULL_DATE } from "../../../utils/constants";
import {
  convertArrayToObject,
  extractIdsFromArray,
  insertNewItemToArr,
  removeObjectFromArray,
  removeObjectFromHash,
  updateObjectInArray,
} from "../../../utils/reducerUtils";
import {
  IEditClientBillingInfoSuccessAction,
  IEditClientPackSuccessAction,
  IEditClientPlanSuccessAction,
  IFetchAllClientsSuccessActionCreator,
  IFetchClientSuccessActionCreator,
  IFetchStripeInvoicesSuccessActionCreator,
  ISetSelectedClientActionCreator,
  IUpdateClientSuccessActionCreator,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IClientsStates {
  allClients: TLightClient[];
  allIds: string[];
  byId: { [id: string]: IClient };
  errors: any[];
  selectedClient: IClient; // FIXME: can be undefined if not logged in !
  isFetchingClient: boolean;
  isFetchingAllClients: boolean;
  isUpdatingClient: boolean;
  isDeletingClient: boolean;
  isCreatingClient: boolean;
  isArchivingClient: boolean;
  isRestoringClient: boolean;
  isReplicatingClient: boolean;
  lastUpdated?: Date;
  invalidateCache: boolean;
  owners: IWebUser[];
  invoices: IInvoice[];
  hasMoreInvoice?: boolean;
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function clientReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_CLIENT_BEGIN:
      return {
        ...state,
        isCreatingClient: true,
      };

    case types.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        isCreatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.CREATE_CLIENT_SUCCESS: {
      const { client } = action as any; // FIXME: write a real type
      client.active = true;
      return {
        ...state,
        isCreatingClient: false,
        allClients: insertNewItemToArr(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
        allIds: uniq(state.allIds.concat([client.id])),
      };
    }
    case types.REPLICATE_CLIENT_BEGIN:
      return {
        ...state,
        isReplicatingClient: true,
      };

    case types.REPLICATE_CLIENT_FAILURE:
      return {
        ...state,
        isReplicatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.REPLICATE_CLIENT_SUCCESS: {
      const { client } = action as any;
      client.active = true;
      return {
        ...state,
        isReplicatingClient: false,
        allClients: insertNewItemToArr(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
        allIds: uniq(state.allIds.concat([client.id])),
      };
    }

    case types.FETCH_CLIENT_BEGIN:
      return {
        ...state,
        isFetchingClient: true,
      };

    case types.FETCH_ALL_CLIENTS_BEGIN:
      return {
        ...state,
        isFetchingAllClients: true,
        isUpdatingClient: false,
        isDeletingClient: false,
        isCreatingClient: false,
        isArchivingClient: false,
        isRestoringClient: false,
      };

    case types.FETCH_CLIENT_FAILURE:
      return {
        ...state,
        isFetchingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.FETCH_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        isFetchingAllClients: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.FETCH_CLIENT_SUCCESS: {
      const { client } = action as IFetchClientSuccessActionCreator;

      const updatedClients = _.map(state.allClients, (storedClient) => {
        if (storedClient.id === client.id) {
          return {
            ...storedClient,
            ...client,
          };
        }
        return storedClient;
      });

      const updatedClient = {
        ...state.byId[client.id],
        ...client,
      };

      return {
        ...state,
        // Updage selected client if ID matches
        ...(client.id === state.selectedClient.id && {
          selectedClient: updatedClient,
        }),
        isFetchingClient: false,
        allClients: updatedClients,
        byId: {
          ...state.byId,
          [client.id]: updatedClient,
        },
        allIds: uniq(state.allIds.concat(client.id)),
        isUpdatingClient: false,
        isDeletingClient: false,
        isCreatingClient: false,
        isArchivingClient: false,
        isRestoringClient: false,
      };
    }

    case types.FETCH_ALL_CLIENTS_SUCCESS: {
      const { clients } = action as IFetchAllClientsSuccessActionCreator;
      return {
        ...state,
        isFetchingAllClients: false,
        allClients: clients,
        byId: convertArrayToObject(state.byId, clients, "id"),
        allIds: extractIdsFromArray(clients),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_CLIENT_BEGIN: {
      return {
        ...state,
        isUpdatingClient: true,
      };
    }

    case types.UPDATE_CLIENT_FAILURE: {
      return {
        ...state,
        isUpdatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    }

    case types.UPDATE_CLIENT_SUCCESS: {
      const { client } = action as IUpdateClientSuccessActionCreator;

      if (state.selectedClient.id === client.id) {
        state.selectedClient = {
          ...state.selectedClient,
          ...client,
        };
      }

      return {
        ...state,
        isUpdatingClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.DELETE_CLIENT_BEGIN:
      return {
        ...state,
        isDeletingClient: true,
      };

    case types.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        isDeletingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.DELETE_CLIENT_SUCCESS: {
      const { id } = action as any; // FIXME: write a real type
      return {
        ...state,
        isDeletingClient: false,
        allClients: removeObjectFromArray(state.allClients, id),
        byId: removeObjectFromHash(state.byId, id),
        allIds: state.allIds.filter((did) => did !== id),
      };
    }

    case types.ARCHIVE_CLIENT_BEGIN:
      return {
        ...state,
        isArchivingClient: true,
      };

    case types.ARCHIVE_CLIENT_FAILURE:
      return {
        ...state,
        isArchivingClient: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.ARCHIVE_CLIENT_SUCCESS: {
      const { clientId } = action as any; // FIXME: write a real type
      const client = state.byId[clientId];
      client.active = false;
      return {
        ...state,
        isArchivingClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.RESTORE_CLIENT_BEGIN:
      return {
        ...state,
        isRestoringClient: true,
      };

    case types.RESTORE_CLIENT_FAILURE:
      return {
        ...state,
        isRestoringClient: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESTORE_CLIENT_SUCCESS: {
      const { clientId } = action as any; // FIXME: write a real type
      const client = state.byId[clientId];
      client.active = true;
      return {
        ...state,
        isRestoringClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.SET_SELECTED_CLIENT: {
      const { selectedClient } = action as ISetSelectedClientActionCreator;

      // IMPORTANT: synchronise apiService with store
      apiService.setClientId(selectedClient?.id || "");
      return {
        ...state,
        selectedClient,
      };
    }

    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.FETCH_OWNERS_SUCCESS:
      const { owners } = action as any;
      return {
        ...state,
        owners,
      };

    case types.FETCH_STRIPE_INVOICES_FAILURE: {
      return {
        ...state,
        invoices: [],
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };
    }

    case types.FETCH_STRIPE_INVOICES_SUCCESS: {
      const { invoices, hasMore, parameters } =
        action as IFetchStripeInvoicesSuccessActionCreator;

      return {
        ...state,
        invoices:
          parameters && !_.isEmpty(parameters.starting_after)
            ? [...state.invoices, ...invoices]
            : invoices,
        hasMoreInvoice: hasMore,
        isFetchingInvoices: false,
      };
    }

    case types.EDIT_CLIENT_PLAN_SUCCESS: {
      const { clientId, licensePlan } = action as IEditClientPlanSuccessAction;

      let newSelectedClient;
      if (state.selectedClient.id === clientId) {
        const updatedBilling = updateBillingPlan(
          state.selectedClient.billing as IClientBilling,
          licensePlan
        );

        newSelectedClient = updateClientBilling(
          state.selectedClient,
          updatedBilling
        );
      }

      const clientInState = state.byId[clientId];
      const updatedBilling = updateBillingPlan(
        clientInState.billing as IClientBilling,
        licensePlan
      );
      const clientWithUpdatedBilling = updateClientBilling(
        clientInState,
        updatedBilling
      );

      return {
        ...state,
        ...(newSelectedClient && {
          selectedClient: newSelectedClient,
        }),
        allClients: updateObjectInArray(
          state.allClients,
          clientWithUpdatedBilling
        ),
        byId: {
          ...state.byId,
          [clientId]: clientWithUpdatedBilling,
        },
      };
    }

    case types.EDIT_BILLING_INFO_SUCCESS: {
      const { clientId, billing } =
        action as IEditClientBillingInfoSuccessAction;

      let newSelectedClient;
      if (state.selectedClient.id === clientId) {
        newSelectedClient = updateClientBilling(state.selectedClient, billing);
      }

      const clientInState = state.byId[clientId];
      const clientWithUpdatedBilling = updateClientBilling(
        clientInState,
        billing
      );

      return {
        ...state,
        ...(newSelectedClient && {
          selectedClient: newSelectedClient,
        }),
        allClients: updateObjectInArray(
          state.allClients,
          clientWithUpdatedBilling
        ),
        byId: {
          ...state.byId,
          [clientId]: clientWithUpdatedBilling,
        },
      };
    }

    case types.EDIT_CLIENT_PACK_SUCCESS: {
      const { clientId, supportPack } = action as IEditClientPackSuccessAction;

      let newSelectedClient;
      if (state.selectedClient.id === clientId) {
        const updatedBilling = updateSupportPack(
          state.selectedClient.billing as IClientBilling,
          supportPack
        );

        newSelectedClient = updateClientBilling(
          state.selectedClient,
          updatedBilling
        );
      }

      const clientInState = state.byId[clientId];
      const updatedBilling = updateSupportPack(
        clientInState.billing as IClientBilling,
        supportPack
      );
      const clientWithUpdatedBilling = updateClientBilling(
        clientInState,
        updatedBilling
      );

      return {
        ...state,
        ...(newSelectedClient && {
          selectedClient: newSelectedClient,
        }),
        allClients: updateObjectInArray(
          state.allClients,
          clientWithUpdatedBilling
        ),
        byId: {
          ...state.byId,
          [clientId]: clientWithUpdatedBilling,
        },
      };
    }

    default:
      return state;
  }
}

const updateClientBilling = (client: IClient, billing: IClientBilling) => {
  return {
    ...client,
    billing,
  };
};

const updateBillingPlan = (
  billing: IClientBilling,
  plan: IClientLicensePlan
): IClientBilling => {
  return {
    ...billing,
    license_plan: plan,
  };
};

const updateSupportPack = (
  billing: IClientBilling,
  pack: IClientSupportPack
): IClientBilling => {
  return {
    ...billing,
    support_pack: pack,
  };
};
