import { Box } from "@mui/material";

import { lightgray, OffWhite } from "assets/colors";

interface IFileCell {
  link?: string;
  onClick?: () => void;
}

const FileCell = ({ link, onClick }: IFileCell) => {
  if (!link) {
    return null;
  }
  return (
    <Box
      width="50px"
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: OffWhite, cursor: "pointer" }}
      onClick={onClick}
    >
      <span className="material-icons" style={{ color: lightgray }}>
        insert_drive_file
      </span>
    </Box>
  );
};

export default FileCell;
