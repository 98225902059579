import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicEditIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <span className="material-icons-outlined">edit</span>
    </SmallIconButton>
  );
};

export default BasicEditIcon;
