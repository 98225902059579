export function makeColorTransparent(
  hexColor: string,
  opacity: number
): string {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!colorRegex.test(hexColor)) {
    return hexColor;
  }
  const color = hexColor.replace("#", "");
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgbaColor;
}
