/**
 * Selector file for deriving computed data from the Application Container
 */
import memoize from "lodash/memoize";
import { createSelectorCreator } from "reselect";

const hashFn = (...args) =>
  args.reduce((acc, val) => acc + "-" + JSON.stringify(val), "");

/**
 * Creates a custom selector which will create an unbounded cache. ONLY use this when in need to update the selector
 * when the state tree is updated. This is not necessary to use however.
 * Uses memoize from lodash to memoize the resulting function, this is used as a custom memoize instead of using
 * the defaultMemoize from reselect and a hashFn is passed onto the memoize function as the second parameter after
 * the resultFunc is passed in as first argument.
 * Reference
 * @link https://github.com/reactjs/reselect#customize-equalitycheck-for-defaultmemoize
 */
export const customSelectorCreator = createSelectorCreator(memoize, hashFn);

/**
 * Gets the state of the login. Returns a Boolean value indicating whether this is the first login
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFirstLoginSelector = (state) => state.app.isFirstLogin;

export const getAppNotifications = (state) => state.app.notifications;

export const getWarningModalParamsSelector = (state) => state.app.warningModal;
