import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicShareIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <span
        className="material-icons-outlined"
        // style={{ fontSize: 22 }}
      >
        share
      </span>
    </SmallIconButton>
  );
};

export default BasicShareIcon;
