import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";

export interface ICheckBoxProps {
  onChecked: (value: boolean, name: string) => void;
  isChecked: boolean;
  isIndeterminate: boolean;
  classes: any;
  label: string;
  name: string;
}

interface ICheckBoxStates {
  isChecked: boolean;
  isIndeterminate: boolean;
}

export class CustomCheckBox extends React.Component<
  ICheckBoxProps,
  ICheckBoxStates
> {
  public static defaultProps = {
    label: "",
    name: "checkbox",
    isIndeterminate: false,
  };

  constructor(props: ICheckBoxProps) {
    super(props);
    this.state = {
      isChecked: false,
      isIndeterminate: props.isIndeterminate,
    };
  }

  /**
   * Handle the change of the Checkbox state from checked to unchecked and vice versa
   * @param name Name of the checkbox
   * @return {Function}
   */
  handleChange =
    (name: string) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const { isIndeterminate } = this.state;
      const { onChecked } = this.props;
      if (isIndeterminate) {
        this.setState({
          isChecked: false,
        });

        if (onChecked) {
          onChecked(false, name);
        }
        return;
      }
      this.setState({
        isChecked: checked,
      });

      if (onChecked) {
        onChecked(checked, name);
      }
    };

  /**
   * Updates the state based on the Props received from the parent
   * @param nextProps Props received from Parent
   * @param prevState Previous state of this component
   * @return {Object}
   */
  static getDerivedStateFromProps(
    nextProps: ICheckBoxProps,
    prevState: ICheckBoxStates
  ) {
    const { isChecked, isIndeterminate } = nextProps;
    if (
      isChecked === prevState.isChecked &&
      isIndeterminate === prevState.isIndeterminate
    ) {
      return null;
    } else {
      return {
        isChecked,
        isIndeterminate,
      };
    }
  }

  render = () => {
    const { name, classes, label, isIndeterminate } = this.props;
    const { isChecked } = this.state;
    return (
      <div>
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={this.handleChange(name)}
          value={name}
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
        />
        {label ? <div className="form-check-inline"> {label}</div> : null}
      </div>
    );
  };
}

export default withStyles(styles as any)(CustomCheckBox);
