import { CUSTOM_FIELD_TYPE } from "fieldpro-tools/dist/src/types";
import _ from "lodash";

import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import TLang from "model/application/Lang";
import { IList, IListSchema } from "model/entities/List";

const getOptionsOfChoiceFilter = (
  schema: IListSchema[],
  nameFilter: string
): { name: string; options: any[] } | undefined => {
  const schemaColumn = schema.find((s) => s.column_tag === nameFilter);
  if (!schemaColumn || !schemaColumn.options) {
    return undefined;
  }

  return {
    name: `${nameFilter}Options`,
    options: schemaColumn.options.map((opt) => ({ ...opt, id: opt.key })),
  };
};

export const getAllFiltersOptionsForChoiceInputs = (
  schema: IListSchema[]
): { name: string; options: any[] }[] => {
  const filtersOptions: { name: string; options: any[] }[] = [];
  schema
    .filter(
      (att) =>
        att.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE ||
        att.type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE
    )
    .forEach((att) => {
      const filterOption = getOptionsOfChoiceFilter(schema, att.column_tag);
      if (filterOption) {
        filtersOptions.push(filterOption);
      }
    });

  return filtersOptions;
};

export const getColumnTypeFromAttrType = (
  attrType: CUSTOM_FIELD_TYPE
): TABLE_COLUMN_TYPE => {
  switch (attrType) {
    case CUSTOM_FIELD_TYPE.UPLOAD_FILE:
      return TABLE_COLUMN_TYPE.UPLOAD;
    case CUSTOM_FIELD_TYPE.BOOLEAN:
      return TABLE_COLUMN_TYPE.SWITCH;
    case CUSTOM_FIELD_TYPE.DATE_PICKER:
      return TABLE_COLUMN_TYPE.DATE;
    case CUSTOM_FIELD_TYPE.GPS:
      return TABLE_COLUMN_TYPE.GPS;
    case CUSTOM_FIELD_TYPE.PICTURE:
      return TABLE_COLUMN_TYPE.PICTURE;
    case CUSTOM_FIELD_TYPE.SIGNATURE:
      return TABLE_COLUMN_TYPE.PICTURE;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
      return TABLE_COLUMN_TYPE.SINGLE_CHOICE;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
      return TABLE_COLUMN_TYPE.SINGLE_CHOICE_ON_LIST;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      return TABLE_COLUMN_TYPE.MULTIPLE_CHOICE;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST:
      return TABLE_COLUMN_TYPE.MULTIPLE_CHOICE_ON_LIST;
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.COMPUTE:
      return TABLE_COLUMN_TYPE.NUMBER;
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.BARCODE_SCANNER:
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      return TABLE_COLUMN_TYPE.TEXT;
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
      return TABLE_COLUMN_TYPE.MATRIX_ON_LIST;
  }
  return TABLE_COLUMN_TYPE.TEXT;
};

const addUnsortableToColumnTypes = (
  columns: TColumnType[],
  schema: IListSchema[]
) => {
  return columns.map((column) => {
    return {
      ...column,
      unsortable: !getBackendSortableColumns(schema).includes(column.name),
    };
  });
};

export const getColumnTypes = (list: IList, lang: TLang): TColumnType[] => {
  let result: TColumnType[] = [
    {
      name: "_displayed_name",
      type: TABLE_COLUMN_TYPE.TEXT,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .itemName,
      hidden: false,
    },
  ];
  if (list.business_id_template) {
    result.push({
      name: "_displayed_business_id",
      type: TABLE_COLUMN_TYPE.TEXT,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .businessId,
      hidden: false,
    });
  }
  if (list.show_ids) {
    result = [
      {
        name: "item_id",
        type: TABLE_COLUMN_TYPE.TEXT,
        label: lang.containers.lists.subCategories.items.columns.itemId,
        hidden: false,
      },
      ...result,
    ];
  }

  // Don't show deprecated attributes at all;
  const activeSchema = getActiveSchema(list?.schema);

  const schemaColumns = _.map(activeSchema, (att) => ({
    name: att.column_tag,
    type: getColumnTypeFromAttrType(att.type),
    label: att.column_name,
    hidden: !isImportant(att, list?.important), // Only show Important attributes by default
  }));

  result = _.concat(result, schemaColumns);

  if (
    ["TEAM", "SINGLE_TEAM", "MOBILE_USER", "SINGLE_MOBILE_USER"].includes(
      list.scope
    )
  )
    result.push({
      name: "_owners",
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      label: lang.components.filters.owners,
      hidden: false,
    });

  if (list.list_type === "CUSTOMER") {
    result.push({
      name: "_last_visit_date",
      type: TABLE_COLUMN_TYPE.DATE,
      label: lang.components.maps.legend.lastVisit,
      hidden: false,
    });
    result.push({
      name: "_created_at",
      type: TABLE_COLUMN_TYPE.DATE,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .createdAt,
      hidden: false,
    });
  }

  result = addUnsortableToColumnTypes(result, list?.schema);
  return result;
};

export const getActiveSchema = (schema: IListSchema[]) => {
  return _.filter(schema, (att) => !att.deprecated);
};

export const isImportant = (attribute: IListSchema, important?: string[]) => {
  return _.includes(important, attribute.column_tag);
};

export const getBackendSortableColumns = (schema: IListSchema[]) => {
  const nonCustomFields = [
    "_active",
    "_name",
    "_displayed_name",
    "_displayed_business_id",
    "_created_at",
    "_created_locally_at",
    "_created_source",
    "_created_by",
    "_updated_at",
    "_updated_locally_at",
    "_updated_source",
    "_updated_by",
    "_last_visit_date",
  ];
  const backendSortableColumns = schema
    .filter((s) =>
      [
        CUSTOM_FIELD_TYPE.BOOLEAN,
        CUSTOM_FIELD_TYPE.COMPUTE,
        CUSTOM_FIELD_TYPE.DATE_PICKER,
        CUSTOM_FIELD_TYPE.DECIMAL,
        CUSTOM_FIELD_TYPE.INTEGER,
        CUSTOM_FIELD_TYPE.TIME_PICKER,
        CUSTOM_FIELD_TYPE.TEXT,
        CUSTOM_FIELD_TYPE.RATING,
        CUSTOM_FIELD_TYPE.PHONE_NUMBER,
        CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
      ].includes(s.type)
    )
    .map((s) => s.column_tag)
    .concat(...nonCustomFields);
  return backendSortableColumns;
};
