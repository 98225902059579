import { META_EXPRESSION_SCOPE, STEP_TYPE } from "fieldpro-tools";

const stepTypeToMeScopeMap = {
  [STEP_TYPE.ACTIVITY]: META_EXPRESSION_SCOPE.ACTIVITY_REPORT,
  [STEP_TYPE.TRANSFORMATION]: META_EXPRESSION_SCOPE.TRANSFORMATION,
  [STEP_TYPE.CREATE_REPORT]: META_EXPRESSION_SCOPE.JOB_REPORT,
  [STEP_TYPE.SCRIPT]: META_EXPRESSION_SCOPE.JOB_REPORT,
  [STEP_TYPE.START_WORKFLOW]: META_EXPRESSION_SCOPE.WORKFLOW_REPORT,
  [STEP_TYPE.HTTP_REQUEST]: META_EXPRESSION_SCOPE.JOB_REPORT,
};

export function stepTypeToMeScope(
  stepType: STEP_TYPE
): META_EXPRESSION_SCOPE | undefined {
  return stepTypeToMeScopeMap[stepType];
}
