import FieldProLogger from "log/FieldProLogger";
import { Middleware } from "redux";

import IRootState from "redux/store/model";

/**
 * Sends crash reports as state is updated and listeners are notified.
 */
const crashReporter: Middleware<{}, IRootState> =
  (store) => (next: any) => (action: any) => {
    try {
      return next(action);
    } catch (e) {
      const { auth, clients } = store.getState();
      const { selectedClient: client } = clients ?? {};
      const { user } = auth ?? {};

      const logger = FieldProLogger({ client, user });

      logger.logError(e);
      throw e;
    }
  };

export default crashReporter;
