export const CREATE_FOLDER_BEGIN = "CREATE_FOLDER_BEGIN";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";

export const DELETE_FOLDER_BEGIN = "DELETE_FOLDER_BEGIN";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";

export const UPDATE_FOLDER_BEGIN = "UPDATE_FOLDER_BEGIN";
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS";
export const UPDATE_FOLDER_FAILURE = "UPDATE_FOLDER_FAILURE";

export const FETCH_FOLDERS_BEGIN = "FETCH_FOLDERS_BEGIN";
export const FETCH_FOLDERS_SUCCESS = "FETCH_FOLDERS_SUCCESS";
export const FETCH_FOLDERS_FAILURE = "FETCH_FOLDERS_FAILURE";

export const ARCHIVE_FOLDER_BEGIN = "ARCHIVE_FOLDER_BEGIN";
export const ARCHIVE_FOLDER_SUCCESS = "ARCHIVE_FOLDER_SUCCESS";
export const ARCHIVE_FOLDER_FAILURE = "ARCHIVE_FOLDER_FAILURE";

export const RESTORE_FOLDER_BEGIN = "RESTORE_FOLDER_BEGIN";
export const RESTORE_FOLDER_SUCCESS = "RESTORE_FOLDER_SUCCESS";
export const RESTORE_FOLDER_FAILURE = "RESTORE_FOLDER_FAILURE";
