import _ from "lodash";

import { getDurationFromDates } from "containers/dashboards/utils/getDurationFromDates";
import TLang from "model/application/Lang";
import { TIMELINE_EVENT_TYPE, TTimelineData } from "model/entities/Dashboard";

export function getTimelineDuration(data: TTimelineData[], lang: TLang) {
  const checkin = _.find(data, { action: TIMELINE_EVENT_TYPE.CHECK_IN });
  const checkout = _.find(data, { action: TIMELINE_EVENT_TYPE.CHECK_OUT });

  if (
    checkin &&
    checkout &&
    checkin.hasOwnProperty("timestamp") &&
    checkin.hasOwnProperty("timestamp")
  ) {
    const time1: any = new Date(checkin["timestamp"]);
    const time2: any = new Date(checkout["timestamp"]);
    return getDurationFromDates(time1, time2, lang);
  }
  return "";
}
