import { IWebhook } from "model/entities/Webhook";
import { IAction } from "redux/store/model";

import {
  insertNewItemToArr,
  removeObjectFromArray,
  updateObjectInArray,
} from "../../../../utils/reducerUtils"; //REVIEW: just utils/reducerUtils fail
import {
  ICreateWebhookSuccessAction,
  IDeleteWebhookSuccessAction,
  IUpdateWebhookSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IWebhookExpression {
  webhooks: IWebhook[];
  isLoading: false;
  isCreating: false;
  isDeleting: false;
  isUpdating: false;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_WEBHOOK_BEGIN: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case types.CREATE_WEBHOOK_FAILURE: {
      return {
        ...state,
        isCreating: false,
      };
    }
    case types.CREATE_WEBHOOK_SUCCESS: {
      const { webhook } = action as ICreateWebhookSuccessAction;
      const webhooks = insertNewItemToArr(state.webhooks, webhook);
      return {
        ...state,
        isCreating: false,
        webhooks,
      };
    }
    case types.FETCH_ALL_WEBHOOKS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_ALL_WEBHOOKS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.FETCH_ALL_WEBHOOKS_SUCCESS: {
      const { webhooks } = action as any;
      return {
        ...state,
        isLoading: false,
        webhooks,
      };
    }
    case types.DELETE_WEBHOOK_BEGIN: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case types.DELETE_WEBHOOK_FAILURE: {
      return {
        ...state,
        isDeleting: false,
      };
    }
    case types.DELETE_WEBHOOK_SUCCESS: {
      const { webhookId } = action as IDeleteWebhookSuccessAction;
      const webhooks = removeObjectFromArray(state.webhooks, webhookId);

      return {
        ...state,
        isDeleting: false,
        webhooks,
      };
    }
    case types.UPDATE_WEBHOOK_BEGIN: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case types.UPDATE_WEBHOOK_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case types.UPDATE_WEBHOOK_SUCCESS: {
      const { webhook } = action as IUpdateWebhookSuccessAction;
      const newAllWebhooks = updateObjectInArray(state.webhooks, webhook);

      return {
        ...state,
        isUpdating: false,
        webhooks: newAllWebhooks,
      };
    }
  }
  return state;
}
