import { DEFAULT_DASHBOARD, IDashboard } from "fieldpro-tools";
import _ from "lodash";

export function prepareDashboardsForFrontEnd(
  dashboards: IDashboard[]
): IDashboard[] {
  return dashboards.map((dashboard) => {
    const dashboardCopy = { ...(dashboard || {}) };
    if (dashboardCopy.id === DEFAULT_DASHBOARD.GPS_TRACKING) {
      //TEMP-FIX:only keep the first element in kpi array
      dashboardCopy.kpis = _.take(dashboardCopy?.kpis, 1);
    }
    return dashboardCopy;
  });
}
