import _ from "lodash";

import {
  buildBooleanFilter,
  buildDateFilter,
  buildMoreFilter,
  buildMultipleChoiceFilter,
  buildNumberFilter,
  buildTextFilter,
} from "components/Filter/prepareFiltersUtils";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import { isDisplayed } from "containers/lists/subcategories/prepareFilters";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { ITableAction } from "model/application/Table";

export const convertColumnToFilter = (
  column: TColumnType,
  data: any[]
): IFilter | undefined => {
  let res;
  switch (column.type) {
    case TABLE_COLUMN_TYPE.DATE: {
      res = buildDateFilter(column.label, column.name);
      break;
    }
    case TABLE_COLUMN_TYPE.NUMBER: {
      res = buildNumberFilter(column.label, column.name);
      break;
    }
    case TABLE_COLUMN_TYPE.SWITCH: {
      res = buildBooleanFilter(column.label, column.name);
      break;
    }
    case TABLE_COLUMN_TYPE.TEXT: {
      res = buildTextFilter(column.label, column.name);
      break;
    }
    case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE: {
      let columnOptions: IOption[] = [];
      if (!_.isEmpty(column.options)) {
        columnOptions = _.compact(column.options);
      } else {
        _.forEach(data, (row) => {
          _.forEach(row[column.name], (cell) => {
            columnOptions.push({
              label: cell?.label,
              key: cell?.label,
            });
          });
        });
        columnOptions = _.uniqBy(columnOptions, "key");
      }
      res = buildMultipleChoiceFilter(columnOptions, column.label, column.name);
      break;
    }
    case TABLE_COLUMN_TYPE.SINGLE_CHOICE: {
      let columnOptions = column.options;
      // If the options are not defined in the columnType, computed them based on data available in the table
      if (!columnOptions) {
        columnOptions = _.map(data, (c) => ({
          label: c[column.name].label,
          key: c[column.name].label,
        }));
      }

      columnOptions = _.uniqBy(columnOptions, "key");
      columnOptions = [{ key: "_", label: "NOTHING" }, ...columnOptions];

      res = buildMultipleChoiceFilter(columnOptions, column.label, column.name);

      break;
    }
  }
  return res;
};

export const prepareFilters = (
  columnTypes: TColumnType[],
  data: any[],
  params: any,
  customFilters: IFilter<any>[] | undefined = undefined
): IFilter[] => {
  const filters: IFilter[] = [];
  _.forEach(columnTypes, (column) => {
    const res = convertColumnToFilter(column, data);
    if (res) {
      res.isSecondaryFilter = true;
      filters.push(res);
    }
  });
  // create the "MORE" filter
  const options = columnTypes
    .filter((column) => {
      if (
        column.type &&
        [
          TABLE_COLUMN_TYPE.NUMBER,
          TABLE_COLUMN_TYPE.TEXT,
          TABLE_COLUMN_TYPE.DATE,
          TABLE_COLUMN_TYPE.SINGLE_CHOICE,
          TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
          TABLE_COLUMN_TYPE.SWITCH,
        ].includes(column.type)
      ) {
        return true;
      }
      return false;
    })
    .map((col) => ({
      key: col.name,
      label: col.label,
    }));

  if (customFilters) {
    customFilters.forEach((filter) => {
      options.push({
        key: filter.tag,
        label: filter.label,
      });
    });
  }

  if (options.length > 0) {
    filters.push(buildMoreFilter(options, "Add Filter"));
  }
  return filters.map((f) => {
    if (params[f.tag]) {
      f.value = params[f.tag];
    }
    if (f.tag === FILTER_TAG.MORE_FILTER) {
      f.options = _.filter(f.options, (option) => !isDisplayed(option.key, f));
    }
    return f;
  });
};

export function groupTableActions(actions: Array<ITableAction>, lang: TLang) {
  const langKey = lang.containers.lists.subCategories.items.actions;
  const assignementActionsLabels = [
    langKey.bulkAssignToTeams,
    langKey.bulkAssignToMobileUsers,
    langKey.bulkUnassign,
    langKey.bulkUnassignMobileUsers,
  ];
  const assignementActions = _.orderBy(
    _.filter(actions, (action) =>
      assignementActionsLabels.includes(action.label)
    ),
    (action) => assignementActionsLabels.indexOf(action.label)
  );

  const remainingActions = _.difference(actions, assignementActions);

  return _.compact([
    _.first(remainingActions),
    ...assignementActions,
    ..._.slice(remainingActions, 1),
  ]);
}
