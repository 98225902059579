import { stripMetaExpression } from "fieldpro-tools/dist/src/metaExpressions/parseRawMetaExpression";
import _ from "lodash";

import { META_EXPRESSION_REGEX } from "components/Input/InputMetaExpression/InputMetaExpressionField";

interface IResolveMEinQuestionTitle {
  text: string;
  resolvedMetaExpressions: Record<string, any>;
  isComputingMetaExpressions: boolean;
}
export function replaceMetaExpressionInString({
  text,
  resolvedMetaExpressions,
  isComputingMetaExpressions,
}: IResolveMEinQuestionTitle) {
  const matchResult = text.match(META_EXPRESSION_REGEX);
  if (matchResult) {
    const expression = matchResult[0];
    const resolved = resolvedMetaExpressions[stripMetaExpression(expression)];
    if (resolved !== null && resolved !== undefined) {
      return text.replace(expression, _.toString(resolved));
    } else if (isComputingMetaExpressions) {
      return text.replace(expression, "");
    }
  }
  return text;
}
