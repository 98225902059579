import _ from "lodash";
import { Dispatch } from "redux";

import { getLang } from "containers/authentication/redux/selector";
import { IQuery } from "containers/lists/utils";
import * as lang from "lang";
import {
  extractDataAndCheckErrorStatus,
  IGenericQueryToSendToBackend,
  treatErrorNotification,
} from "redux/actions/appActions";
import IRootState from "redux/store/model";

import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";
import {
  changeSubcategorySuccessActionCreator,
  setCustomerLocationsActionCreator,
  setCustomersAttributeActionCreator,
  setCustomersLegendActionCreator,
} from "./actionCreator";
import {
  fetchCustomersAttributeApiCall,
  fetchCustomersLayerApiCall,
  fetchCustomersLegendApiCall,
} from "./api";
/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "map" or "list"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changeSubcategoryAction(subcategory: any): any {
  return (dispatch: Dispatch) => {
    return dispatch(changeSubcategorySuccessActionCreator(subcategory));
  };
}

export type TFetchCustomersLayerAction = (
  query?: IGenericQueryToSendToBackend
) => Promise<{ customers: ICustomerLocation[] } | undefined>;

export const fetchCustomersLayerAction = (
  query: IGenericQueryToSendToBackend = { filters: {} }
) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    try {
      if (query.teams) {
        // ugly reformating to match with the backend. To change maybe one day...
        query.filters.teams = query.teams;
        delete query.teams;
      }

      const response = await fetchCustomersLayerApiCall(query);
      const data = extractDataAndCheckErrorStatus<{
        customers: ICustomerLocation[];
      }>(response);
      dispatch(setCustomerLocationsActionCreator(data.customers));
      return data;
    } catch (e) {
      treatErrorNotification(
        dispatch,
        "FetchCustomersLayerError",
        e,
        null,
        currLang
      );
    }
  };
};

export const fetchCustomersLegendAction = (query: IQuery) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    try {
      const response = await fetchCustomersLegendApiCall(query);
      const { legends } = extractDataAndCheckErrorStatus(response);
      dispatch(setCustomersLegendActionCreator(legends, currLang));
      return legends;
    } catch (e) {
      treatErrorNotification(
        dispatch,
        "FetchCustomersLayerError",
        e,
        null,
        currLang
      );
    }
  };
};

export const fetchCustomersAttributeAction = (
  attributeTag: string,
  query: IQuery,
  isGeoAttribute = false
) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    try {
      const customerLocations = getState().customers.customerLocations;

      if (shouldFetchAttribute({ customerLocations, attributeTag })) {
        const response = await fetchCustomersAttributeApiCall(
          attributeTag,
          query
        );
        const { value } = extractDataAndCheckErrorStatus(response);
        dispatch(
          setCustomersAttributeActionCreator(
            attributeTag,
            value,
            isGeoAttribute
          )
        );
      }
    } catch (e) {
      treatErrorNotification(
        dispatch,
        "FetchCustomersAttributeError",
        e,
        null,
        currLang
      );
    }
  };
};

interface IShouldRefetchAttribute {
  customerLocations: ICustomerLocation[] | undefined;
  attributeTag: string;
}
const shouldFetchAttribute = ({
  attributeTag,
  customerLocations,
}: IShouldRefetchAttribute) => {
  if (!customerLocations) return false;

  return !_.has(customerLocations?.at(0), attributeTag);
};
