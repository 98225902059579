import _ from "lodash";

export function prepareDashboardQuery(query: Object) {
  const queryCopy = { ...query };
  if (queryCopy.hasOwnProperty("param1")) {
    const param = queryCopy["param1"];
    queryCopy["param1"] = _.get(param, "0.key");
  }

  return queryCopy;
}
