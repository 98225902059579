import { ChangeEvent, useState } from "react";

import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating";

import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";

export interface IInputRating extends IInputBaseLayout {
  value?: number;
  label?: string;
  viewMode?: TViewMode;
  onChange?: Function;
  max: number;
  step: number;
  name: string;
  viewStacked?: boolean;
}

const InputRating = ({
  value,
  label,
  onChange,
  viewMode = "VIEW",
  max,
  step,
  name,
  viewStacked = false,
  ...rest
}: IInputRating) => {
  const [valueState, setValueState] = useState<number>(value ?? 0);

  const icon = (
    <StarIcon
      style={{
        width: "15px",
        height: "15px",
      }}
    />
  );

  const handleRatingChange = (
    event: ChangeEvent<{}>,
    newValue: number | null
  ) => {
    setValueState(newValue ?? 0);
    if (onChange) {
      onChange(newValue, name);
    }
  };

  return (
    <InputBaseLayout
      {...rest}
      viewStacked={viewStacked}
      viewMode={viewMode}
      label={label}
    >
      <Rating
        style={{ verticalAlign: "middle" }}
        name={name}
        icon={icon}
        defaultValue={valueState}
        size="small"
        precision={step}
        max={max}
        onChange={handleRatingChange}
        readOnly={viewMode === "VIEW"}
      />
    </InputBaseLayout>
  );
};

export default InputRating;
