import { ITableOptimization } from "model/entities/TableOptimization";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface ICreateTableOptimizationSuccessAction extends IAction {
  tableOptimization: ITableOptimization;
}
export interface IDeleteTableOptimizationSuccessAction extends IAction {
  id: string;
}
export interface IFetchTableOptimizationsSuccessAction extends IAction {
  tableOptimizations: ITableOptimization[];
}

/**
 * Create tableOptimization begin action creator
 * This creates an action informing redux store that the creation process for a tableOptimization has just began
 * @returns {Object}
 */
export function createTableOptimizationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_TABLEOPTIMIZATION_BEGIN,
  };
}

/**
 * Failure Action for creating aN tableOptimization instructing that the creation process for aN tableOptimization has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createTableOptimizationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_TABLEOPTIMIZATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a tableOptimization
 * @param {Object} tableOptimization TableOptimization object,
 * @returns {Object}
 */
export const createTableOptimizationSuccessActionCreator = (
  tableOptimization: ITableOptimization
): ICreateTableOptimizationSuccessAction => {
  return {
    type: types.CREATE_TABLEOPTIMIZATION_SUCCESS,
    tableOptimization,
  };
};
/**
 * Delete tableOptimization begin action creator
 * This creates an action informing redux store that the creation process for a tableOptimization has just began
 * @returns {Object}
 */
export function deleteTableOptimizationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_TABLEOPTIMIZATION_BEGIN,
  };
}

/**
 * Failure Action for creating a tableOptimization instructing that the creation process for a tableOptimization has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteTableOptimizationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_TABLEOPTIMIZATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a tableOptimization
 * @param {Object} tableOptimizationId TableOptimization object,
 * @returns {Object}
 */
export function deleteTableOptimizationSuccessActionCreator(
  tableOptimizationId: string
): IDeleteTableOptimizationSuccessAction {
  return {
    type: types.DELETE_TABLEOPTIMIZATION_SUCCESS,
    id: tableOptimizationId,
  };
}

/**
 * begin action when there is a request to fetch all tableOptimizations
 * @returns {Object}
 * */
export const fetchAllTableOptimizationsBeginActionCreator =
  (): IBeginAction => ({
    type: types.FETCH_ALL_TABLEOPTIMIZATIONS_BEGIN,
  });

/**
 * Failure action for fetching all tableOptimizations. This action is dispatched when there is an error fetching all
 * tableOptimizations
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllTableOptimizationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_TABLEOPTIMIZATIONS_FAILURE,
  error,
});

/**
 * Fetch all tableOptimizations action creator
 * @param {Array} tableOptimizations an array of tableOptimization objects
 * @returns {Object}
 * */
export const fetchAllTableOptimizationsSuccessActionCreator = (
  tableOptimizations: ITableOptimization[]
): IFetchTableOptimizationsSuccessAction => ({
  type: types.FETCH_ALL_TABLEOPTIMIZATIONS_SUCCESS,
  tableOptimizations,
});
