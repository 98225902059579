import * as d3 from "d3";
import {
  ICustomersMapKpiData,
  TKpiCategoriesLegendValue,
} from "fieldpro-tools";
import _ from "lodash";

import { IKPI } from "model/entities/Dashboard";

export function getLinearLegendClasses(
  chart: IKPI<Array<ICustomersMapKpiData>>
) {
  if (!chart.legend) return;
  const classes = _(chart.data)
    .map(chart.legend?.tag)
    .map(_.toString)
    .uniq()
    .value();
  return classes;
}

export function getLinearLegendClassColors(
  chart: IKPI<Array<ICustomersMapKpiData>>
): Array<TKpiCategoriesLegendValue> | undefined {
  if (!chart.legend?.tag) return;

  const classes = getLinearLegendClasses(chart);
  const legendTag = chart.legend.tag;
  const colorAccents = d3.scaleOrdinal(d3.schemeAccent);

  return _(classes)
    .map((c, i) => {
      const dataWithClass = chart.data?.find((d) => d[legendTag] === c);
      if (dataWithClass) {
        return {
          color: dataWithClass.color || colorAccents(i.toString()),
          label: c,
        };
      }
    })
    .compact()
    .value();
}
