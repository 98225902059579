import { IAction } from "redux/store/model";

import { FetchStockSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IStock {
  sku_id: string;
  quantity: number;
  updated_at: Date;
}

export interface IStockState {
  stock: IStock[];
  isFetchingStock: boolean;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_STOCK_SUCCESS: {
      const { stock } = action as FetchStockSuccessAction;

      return {
        ...state,
        isFetchingStock: false,
        stock,
      };
    }

    case types.FETCH_STOCK_BEGIN:
      return {
        ...state,
        isFetchingStock: true,
      };
    case types.FETCH_STOCK_FAILURE:
      return {
        ...state,
        isFetchingStock: false,
      };
    default:
      return state;
  }
}
