import _ from "lodash";
import { orderBy as naturalOrderBy } from "natural-orderby";

import { ITableSorting } from "components/Table/CustomTable/CustomTable";
import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";

interface ISortedReports<T> {
  rows: T[];
  sorting: ITableSorting;
  columnTypes: TColumnType[];
}

type TGetSortedRows = <T>({
  rows,
  sorting,
  columnTypes,
}: ISortedReports<T>) => T[];

export const getSortedRows: TGetSortedRows = ({
  rows,
  sorting,
  columnTypes,
}) => {
  const columnType = getOrderByColumnType(sorting.orderBy, columnTypes);
  const sorter = getSortingFunction(columnType);
  return sorter({
    rows,
    orderBy: sorting.orderBy,
    order: sorting.order,
  });
};

export const getDefaultOrderBy = (columnTypes: TColumnType[]) => {
  if (_.find(columnTypes, { name: "_displayed_name" })) {
    return "_displayed_name";
  }
  return "name";
};

export const getDefaultOrder = (columnType: TABLE_COLUMN_TYPE) => {
  switch (columnType) {
    case TABLE_COLUMN_TYPE.TEXT: {
      return "asc";
    }
    default: {
      return "desc";
    }
  }
};

export const getOrderByColumnType = (
  orderBy: string,
  columnTypes: TColumnType[]
) => {
  const column = _.find(columnTypes, { name: orderBy });
  return column?.type || TABLE_COLUMN_TYPE.TEXT;
};

const getSortingFunction = (columnType: TABLE_COLUMN_TYPE) => {
  switch (columnType) {
    case TABLE_COLUMN_TYPE.TEXT: {
      return textSorter;
    }
    case TABLE_COLUMN_TYPE.DATE_TIME:
    case TABLE_COLUMN_TYPE.DATE:
    case TABLE_COLUMN_TYPE.TIME: {
      return dateSorter;
    }
    case TABLE_COLUMN_TYPE.BOOLEAN: {
      return booleanSorter;
    }
    case TABLE_COLUMN_TYPE.NUMBER: {
      return numberSorter;
    }
    default: {
      return defaultSorter;
    }
  }
};

interface ISorterArgs<T> {
  rows: T[];
  orderBy?: string;
  order?: "asc" | "desc";
}

export type TSorter = <T>({ rows, orderBy, order }: ISorterArgs<T>) => T[];

const textSorter: TSorter = ({ rows, orderBy, order = "desc" }) => {
  if (!orderBy) {
    return rows;
  }
  return naturalOrderBy(rows, orderBy, order);
};

const defaultSorter: TSorter = ({ rows, orderBy, order = "desc" }) => {
  if (!orderBy) {
    return rows;
  }

  // TODO: TEST (needs clarification)
  const defaultFormatter = (row: any) => {
    const cellValue = row[orderBy];
    return cellValue
      ? typeof cellValue === "string"
        ? cellValue.toLowerCase()
        : cellValue.toString()
      : cellValue != null
      ? cellValue.toString()
      : "z"; // put empty values last instead of first
  };

  return _.orderBy(rows, [defaultFormatter], order);
};

const booleanSorter: TSorter = ({ rows, orderBy, order = "desc" }) => {
  if (!orderBy) {
    return rows;
  }

  const booleanFormatter = (row: any) =>
    row[orderBy] === true ? 1 : row[orderBy] === false ? 0 : -1;

  return _.orderBy(rows, [booleanFormatter], order);
};

const numberSorter: TSorter = ({ rows, orderBy, order = "desc" }) => {
  if (!orderBy) {
    return rows;
  }
  return _.orderBy(rows, orderBy, order);
};

const dateSorter: TSorter = ({ rows, orderBy, order = "desc" }) => {
  if (!orderBy) {
    return rows;
  }
  const dateFormatter = (row: any) => new Date(row[orderBy]).getTime();
  return _.orderBy(rows, [dateFormatter], order);
};

export default getSortingFunction;
