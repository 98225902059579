import { ITrigger } from "fieldpro-tools";

import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as notificationLevels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import { extractDataAndCheckErrorStatus } from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createTriggerBeginActionCreator,
  createTriggerFailureActionCreator,
  createTriggerSuccessActionCreator,
  deleteTriggerBeginActionCreator,
  deleteTriggerFailureActionCreator,
  deleteTriggerSuccessActionCreator,
  fetchAllTriggersBeginActionCreator,
  fetchAllTriggersFailureActionCreator,
  fetchAllTriggersSuccessActionCreator,
  updateTriggerBeginActionCreator,
  updateTriggerFailureActionCreator,
  updateTriggerSuccessActionCreator,
} from "./actionCreators";
import {
  createTriggerApiCall,
  deleteTriggerApiCall,
  fetchAllTriggersApiCall,
  UpdateTriggerApiCall,
} from "./api";
export type IFetchAllTriggersActionFunc = () => IDispatchAndGetState<void>;
export type IDeleteTriggerActionFunc = (
  triggerId: string,
  actionName: string
) => IDispatchAndGetState<void>;

export const fetchTriggersAction: IFetchAllTriggersActionFunc = () => {
  return async (dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(fetchAllTriggersBeginActionCreator());

    return fetchAllTriggersApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { triggers } = data;

        dispatch(ajaxSuccessAction());
        dispatch(fetchAllTriggersSuccessActionCreator({ triggers }));
      })
      .catch((error) => {
        dispatch(fetchAllTriggersFailureActionCreator(error));
      });
  };
};
export const deleteTriggerAction: IDeleteTriggerActionFunc = (triggerId) => {
  return async (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    try {
      dispatch(deleteTriggerBeginActionCreator());
      dispatch(ajaxRequestAction());

      const serverResponse = await deleteTriggerApiCall(triggerId);
      extractDataAndCheckErrorStatus(serverResponse);
      dispatch(deleteTriggerSuccessActionCreator(triggerId));
      dispatch(
        showNotificationActionCreator(
          notificationTypes.NOTIFICATION_SUCCESS,
          notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
          getSuccessNotificationMessage(
            currLang,
            LANG_ACTIONS.DELETE,
            SUB_CATEGORIES.TRIGGER,
            triggerId
          )
        )
      );
      dispatch(ajaxSuccessAction());
    } catch (error) {
      dispatch(deleteTriggerFailureActionCreator(error));
    }
  };
};

export type ICreateTriggerActionFunc = (
  trigger: ITrigger
) => IDispatchAndGetState<void>;

export const createTriggerAction: ICreateTriggerActionFunc = (
  trigger: ITrigger
) => {
  return async (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    try {
      dispatch(createTriggerBeginActionCreator());
      dispatch(ajaxRequestAction());
      const serverResponse = await createTriggerApiCall(trigger);
      const dataId = extractDataAndCheckErrorStatus(serverResponse);
      dispatch(
        createTriggerSuccessActionCreator({ ...trigger, ...dataId } as ITrigger)
      );
      dispatch(
        showNotificationActionCreator(
          notificationTypes.NOTIFICATION_SUCCESS,
          notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
          getSuccessNotificationMessage(
            currLang,
            LANG_ACTIONS.CREATE,
            SUB_CATEGORIES.TRIGGER,
            trigger.name
          )
        )
      );
      dispatch(ajaxSuccessAction());
    } catch (error) {
      dispatch(createTriggerFailureActionCreator(error));
    }
  };
};

export type IUpdateTriggerActionFunc = (
  trigger: ITrigger,
  triggerId: string
) => IDispatchAndGetState<void>;

export const UpdateTriggerAction: IUpdateTriggerActionFunc = (
  trigger: ITrigger,
  triggerId: string
) => {
  return async (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    try {
      dispatch(updateTriggerBeginActionCreator());

      dispatch(ajaxRequestAction());

      const serverResponse = await UpdateTriggerApiCall(triggerId, trigger);
      extractDataAndCheckErrorStatus(serverResponse);

      dispatch(updateTriggerSuccessActionCreator(trigger));
      dispatch(
        showNotificationActionCreator(
          notificationTypes.NOTIFICATION_SUCCESS,
          notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
          getSuccessNotificationMessage(
            currLang,
            LANG_ACTIONS.EDIT,
            SUB_CATEGORIES.TRIGGER,
            trigger.name
          )
        )
      );

      dispatch(ajaxSuccessAction());
    } catch (error) {
      dispatch(updateTriggerFailureActionCreator(error));
    }
  };
};
