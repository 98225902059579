import { useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { DropResult } from "react-beautiful-dnd";

import InputSearch from "components/Input/InputSearch";
import { TColumnType } from "components/Table/model";
import { useTranslations } from "hooks/useTranslations";

import style from "../Card/styles";
import ColumnSelectorMenuSection from "./ColumnSelectorMenuSection";

const useStyle = makeStyles(style as any);

const filterColumnsType = (columnTypes: TColumnType[], isHidden: boolean) => {
  const columns = _.filter(columnTypes, { hidden: isHidden });
  return columns;
};

const filterByPosition = (array: TColumnType[], position: number) => {
  const columnsData: TColumnType[] = _.filter(
    array,
    (_element, index: number) => index !== position
  );
  return columnsData;
};

const ColumnSelectorMenu = ({ columnTypes, onChangeColumnTypes }: any) => {
  const classes = useStyle();
  const [searchValue, setSearchValue] = useState("");
  const [showColumn, setShowColumn] = useState(
    filterColumnsType(columnTypes, false)
  );
  const [hideColumn, setHideColumn] = useState(
    filterColumnsType(columnTypes, true)
  );
  const lang = useTranslations();

  const handleHideColumn = (id: string) => {
    const element = _.concat(showColumn, hideColumn);
    element.map((el) => {
      if (el.name === id) {
        el.hidden = true;
      }
    });

    setShowColumn(element.filter((col) => col.hidden === false));
    setHideColumn(element.filter((col) => col.hidden === true));

    onChangeColumnTypes(
      element.filter((col) => col.hidden === false),
      element.filter((col) => col.hidden === true)
    );
  };

  const handleShowColumn = (id: string) => {
    const element = _.concat(showColumn, hideColumn);
    element.map((el) => {
      if (el.name === id) {
        el.hidden = false;
      }
    });

    setShowColumn(element.filter((col) => col.hidden === false));
    setHideColumn(element.filter((col) => col.hidden === true));

    onChangeColumnTypes(
      element.filter((col) => col.hidden === false),
      element.filter((col) => col.hidden === true)
    );
  };

  const handleSearchColumn = (e: any) => {
    if (e.length > 0) {
      setSearchValue(e);
    } else setSearchValue("");
  };

  const getColumnAccordingSearchValue = (type: string) => {
    const columnsList = [];
    if (type === "show") {
      for (const option of showColumn) {
        if (option.label.toLowerCase().includes(searchValue.toLowerCase())) {
          columnsList.push(option);
        }
      }
      return columnsList;
    } else {
      for (const option of hideColumn) {
        if (option.label.toLowerCase().includes(searchValue.toLowerCase())) {
          columnsList.push(option);
        }
      }
      return columnsList;
    }
  };

  const handleShowAll = () => {
    const shows: TColumnType[] = [];
    hideColumn.map((el) => {
      el.hidden = false;
      shows.push(el);
    });
    const element = _.concat(showColumn, shows);
    setShowColumn(element.filter((col) => col.hidden === false));
    setHideColumn(element.filter((col) => col.hidden === true));
    onChangeColumnTypes(
      element.filter((col) => col.hidden === false),
      element.filter((col) => col.hidden === true)
    );
  };

  const reArrangeDataHide = (param: DropResult) => {
    const columnsData: TColumnType[] = filterByPosition(
      hideColumn,
      param.source.index
    );
    if (param.destination) {
      columnsData.splice(
        param.destination?.index,
        0,
        hideColumn[param.source?.index]
      );
    } else {
      columnsData.splice(
        param.source?.index,
        0,
        hideColumn[param.source?.index]
      );
    }
    setHideColumn(columnsData);
    onChangeColumnTypes(showColumn, columnsData);
  };

  const reArrangeDataShow = (param: DropResult) => {
    const columnsData: TColumnType[] = filterByPosition(
      showColumn,
      param.source.index
    );
    if (param.destination) {
      columnsData.splice(
        param.destination?.index,
        0,
        showColumn[param.source?.index]
      );
    } else {
      columnsData.splice(
        param.source?.index,
        0,
        showColumn[param.source?.index]
      );
    }
    setShowColumn(columnsData);
    onChangeColumnTypes(columnsData, hideColumn);
  };

  const hideCondition =
    hideColumn.length > 0 || getColumnAccordingSearchValue("hide").length > 0;

  const showCondition =
    showColumn.length > 0 || getColumnAccordingSearchValue("show").length > 0;
  return (
    <div className={classes.SearchDropdown}>
      <div style={{ marginBottom: 10 }}>
        <span className={classes.typography}>
          {lang.components.columns.showHideFilter.header}
        </span>
      </div>
      <InputSearch onChange={(value) => handleSearchColumn(value)} />
      <Box className={classes.SearchDropdownItems}>
        {showCondition && (
          <ColumnSelectorMenuSection
            isShow
            onClickItem={handleHideColumn}
            options={
              !searchValue || searchValue.length === 0
                ? showColumn
                : getColumnAccordingSearchValue("show")
            }
            title={lang.components.columns.showHideFilter.shown}
            onDragEnd={reArrangeDataShow}
          />
        )}
        {hideCondition && (
          <ColumnSelectorMenuSection
            isShow={false}
            onClickItem={handleShowColumn}
            options={
              !searchValue || searchValue.length === 0
                ? hideColumn
                : getColumnAccordingSearchValue("hide")
            }
            title={lang.components.columns.showHideFilter.hidden}
            onClickAll={handleShowAll}
            onDragEnd={reArrangeDataHide}
          />
        )}
      </Box>
    </div>
  );
};
export default ColumnSelectorMenu;
