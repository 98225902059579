import _ from "lodash";

// Need 0 and false to return false
export const isEmptyValue = (value: any) => {
  if (value === 0) return false;
  if (value === false) return false;
  if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value)) {
    return true;
  }
  if (_.isDate(value)) {
    return false;
  }
  if (_.isObject(value) && _.isEmpty(value)) {
    return true;
  }
  if (value === "") {
    return true;
  }
  return false;
};
