/**
 * @notes: CLIENTs Action creator for actionCreator
 */

import {
  IClientBilling,
  IClientLicensePlan,
  IClientSupportPack,
} from "fieldpro-tools";

import { IClient, TLightClient } from "model/entities/Client";
import ITeam from "model/entities/Team";
import { IWebUser } from "model/entities/User";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

/**
 * Fetch CLIENT begin action, is used to dispatch action that triggers a 'loading' progress on the
 * redux store
 * @return {Object} Object with type of action
 * */
export function fetchClientBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_CLIENT_BEGIN,
  };
}

/**
 * Fetch All clients begin action. is used to dispatch action to the redux store a fetch all CLIENT request is being
 * requested.
 * @return {Object} Object with type of action
 * */
export function fetchAllClientsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ALL_CLIENTS_BEGIN,
  };
}

/**
 * Fetch CLIENT failure action is dispatched when there was an error fetching all clients from the redux store
 * @param {Object} error Error info object
 * @return {Object} Object with type of action and error object
 * */
export function fetchClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_CLIENT_FAILURE,
    error,
  };
}

/**
 * Fetch All CLIENT Failure action dispatched when there was an error fetching all clients. This will take in the
 * error object that will be used for logging.
 * @param {Object} error Error object with information of the failure
 * @return {Object} Object with type of action and the error object
 * */
export function fetchAllClientsFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_ALL_CLIENTS_FAILURE,
    error,
  };
}

export interface IFetchClientSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * Fetch CLIENT success action. This is dispatched when fetching a single CLIENT object from redux store was successful
 * @param {Object} CLIENT CLIENT object
 * @return {Object} Object with type of action that the CLIENT object
 * */
export function fetchClientSuccessActionCreator(
  client: IClient
): IFetchClientSuccessActionCreator {
  return {
    type: types.FETCH_CLIENT_SUCCESS,
    client,
  };
}

export interface IFetchAllClientsSuccessActionCreator extends IAction {
  clients: TLightClient[];
}

/**
 * Fetch all clients success action, which is dispatched when there was a successful retrieval of clients from the API
 * or redux store
 * @param {Array} clients An array of CLIENT objects
 * @return {Object} Object Object with type of action and the array of CLIENT objects
 * */
export function fetchAllClientsSuccessActionCreator(
  clients: TLightClient[]
): IFetchAllClientsSuccessActionCreator {
  return {
    type: types.FETCH_ALL_CLIENTS_SUCCESS,
    clients,
  };
}

/**
 * Action creator return the type of action that informs reducer a CLIENT is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createClientBeginActionCreator(): IAction {
  return {
    type: types.CREATE_CLIENT_BEGIN,
  };
}

/**
 * CLIENT failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_CLIENT_FAILURE,
    error,
  };
}

interface ICreateClientSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * CLIENT create success action is used to inform reducer of successful ajax request
 * @param {Object} client Object with CLIENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createClientSuccessActionCreator(
  client: IClient
): ICreateClientSuccessActionCreator {
  return {
    type: types.CREATE_CLIENT_SUCCESS,
    client,
  };
}

export interface IUpdateClientSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * CLIENT update success action is used to inform reducer of successful ajax request
 * @param {Object} client Object with CLIENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateClientSuccessActionCreator(
  client: IClient
): IUpdateClientSuccessActionCreator {
  return {
    type: types.UPDATE_CLIENT_SUCCESS,
    client,
  };
}

/**
 * CLIENT update begin action is used to inform reducer of beginning ajax request
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateClientBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_CLIENT_BEGIN,
  };
}

/**
 * CLIENT update failure action is used to inform reducer of failed ajax request
 * @return {Object} Object with type of action for reducer to handle
 * @param {Object} error Object with error information
 * */
export function updateClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_CLIENT_FAILURE,
    error,
  };
}

interface ISetForceMaintenanceSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * CLIENT update success action is used to inform reducer of successful ajax request
 * @param {Object} client Object with CLIENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function setForceMaintenanceSuccessActionCreator(
  client: IClient
): ISetForceMaintenanceSuccessActionCreator {
  return {
    type: types.UPDATE_CLIENT_SUCCESS,
    client,
  };
}

/**
 * CLIENT update begin action is used to inform reducer of beginning ajax request
 * @return {Object} Object with type of action for reducer to handle
 * */
export function setForceMaintenanceBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_CLIENT_BEGIN,
  };
}

/**
 * CLIENT update failure action is used to inform reducer of failed ajax request
 * @return {Object} Object with type of action for reducer to handle
 * @param {Object} error Object with error information
 * */
export function setForceMaintenanceFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_CLIENT_FAILURE,
    error,
  };
}

interface ISetClientLiveSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * CLIENT update success action is used to inform reducer of successful ajax request
 * @param {Object} client Object with CLIENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function setClientLiveSuccessActionCreator(
  client: IClient
): ISetClientLiveSuccessActionCreator {
  return {
    type: types.UPDATE_CLIENT_SUCCESS,
    client,
  };
}

/**
 * CLIENT update begin action is used to inform reducer of beginning ajax request
 * @return {Object} Object with type of action for reducer to handle
 * */
export function setClientLiveBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_CLIENT_BEGIN,
  };
}

/**
 * CLIENT update failure action is used to inform reducer of failed ajax request
 * @return {Object} Object with type of action for reducer to handle
 * @param {Object} error Object with error information
 * */
export function setClientLiveFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_CLIENT_FAILURE,
    error,
  };
}

/**
 * Delete CLIENT action used to inform reducer of beginning ajax request to delete a CLIENT
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteClientBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_CLIENT_BEGIN,
  };
}

interface IDeleteClientSuccessActionCreator extends IAction {
  id: string;
}

/**
 * Delete CLIENT action used to inform reducer of successful ajax request to delete a CLIENT
 * @param {String} id Id of the CLIENT to delete
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteClientSuccessActionCreator(
  id: string
): IDeleteClientSuccessActionCreator {
  return {
    type: types.DELETE_CLIENT_SUCCESS,
    id,
  };
}

/**
 * Delete CLIENT action used to inform reducer of failed ajax request to delete a CLIENT
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_CLIENT_FAILURE,
    error,
  };
}

/**
 * Creates an action when we have started the archive process for archiving a client
 * @returns {Object}
 */
export function archiveClientBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_CLIENT_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when archiving an client
 * @param {Object} error Error object
 * @returns {Object}
 */
export function archiveClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.ARCHIVE_CLIENT_FAILURE,
    error,
  };
}

interface IArchiveClientSuccessActionCreator extends IAction {
  clientId: string;
}

/**
 * Creates success action when we have successfully archive a client
 * @param {String} clientId
 * @returns {Object}
 */
export function archiveClientSuccessActionCreator(
  clientId: string
): IArchiveClientSuccessActionCreator {
  return {
    type: types.ARCHIVE_CLIENT_SUCCESS,
    clientId,
  };
}

/**
 * Creates an action when we have started the restore process for restoring a client
 * @returns {Object}
 */
export function restoreClientBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_CLIENT_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when restoring an client
 * @param {Object} error Error object
 * @returns {Object}
 */
export function restoreClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESTORE_CLIENT_FAILURE,
    error,
  };
}

interface IRestoreClientSuccessActionCreator extends IAction {
  clientId: string;
  updated?: any;
}

/**
 * Creates success action when we have successfully archive a client
 * @param {String} clientId
 * @param {Boolean} updated
 * @returns {Object}
 */
export function restoreClientSuccessActionCreator(
  clientId: string,
  updated?: any
): IRestoreClientSuccessActionCreator {
  return {
    type: types.RESTORE_CLIENT_SUCCESS,
    clientId,
    updated,
  };
}

/**
 * Action creator return the type of action that informs reducer a CLIENT is being replicated. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function replicateClientBeginActionCreator(): IAction {
  return {
    type: types.REPLICATE_CLIENT_BEGIN,
  };
}

/**
 * CLIENT failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function replicateClientFailureActionCreator(error: any): IActionError {
  return {
    type: types.REPLICATE_CLIENT_FAILURE,
    error,
  };
}

interface IReplicateClientSuccessActionCreator extends IAction {
  client: IClient;
}

/**
 * CLIENT replication success action is used to inform reducer of successful ajax request
 * @param {Object} client Object with CLIENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function replicateClientSuccessActionCreator(
  client: IClient
): IReplicateClientSuccessActionCreator {
  return {
    type: types.REPLICATE_CLIENT_SUCCESS,
    client,
  };
}

/**
 * Creates an action when we have started to upload a file
 * @returns {Object}
 */
export function uploadFileBeginActionCreator(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when uploading a file
 * @param {Object} error Error object
 * @returns {Object}
 */
export function uploadFileFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

interface IUploadFileSuccessActionCreator extends IAction {
  clientId: string;
}

/**
 * Creates success action when we have successfully uploaded the file
 * @param {String} clientId
 * @param {Boolean} updated
 * @returns {Object}
 */
export function uploadFileSuccessActionCreator(
  clientId: string
): IUploadFileSuccessActionCreator {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
    clientId,
  };
}

/**
 * Creates an action when we have started to fetch teams by client
 * @returns {Object}
 */
export function fetchTeamsByClientBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_TEAMS_BY_CLIENT_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when fetching teams by client
 * @param {Object} error Error object
 * @returns {Object}
 */
export function fetchTeamsByClientFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_TEAMS_BY_CLIENT_FAILURE,
    error,
  };
}

interface IFetchTeamsByClientSuccessActionCreator extends IAction {
  teams: ITeam[];
}

/**
 * Creates success action when we have successfully fetch teams by client
 * @param {String} teams
 * @returns {Object}
 */
export function fetchTeamsByClientSuccessActionCreator(
  teams: ITeam[]
): IFetchTeamsByClientSuccessActionCreator {
  return {
    type: types.FETCH_TEAMS_BY_CLIENT_SUCCESS,
    teams,
  };
}

export interface ISetSelectedClientActionCreator extends IAction {
  selectedClient?: IClient;
}

export function setSelectedClientActionCreator(
  selectedClient?: IClient
): ISetSelectedClientActionCreator {
  return {
    type: types.SET_SELECTED_CLIENT,
    selectedClient,
  };
}

export interface IChangeSelectedClientActionCreator extends IAction {
  selectedClient?: IClient;
}

export function changeSelectedClientActionCreator(
  selectedClient?: IClient
): IChangeSelectedClientActionCreator {
  return {
    type: types.CHANGE_SELECTED_CLIENT,
    selectedClient,
  };
}

/**
 * Creates fetch stripe session link action
 * @returns {Object}
 */
export function fetchStripeSessionLinkBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_STRIPE_SESSION_BEGIN,
  };
}
/**
 * Creates a fetch stripe session link failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchStripeSessionLinkFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_STRIPE_SESSION_FAILURE,
    error,
  };
}
/**
 * Creates a fetch stripe session link failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchStripeInvoicesFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_STRIPE_INVOICES_FAILURE,
    error,
  };
}
export interface IFetchStripeSessionLinkSuccessActionCreator extends IAction {
  session_link: String;
}
/**
 * creates a success action when we have successfully fetched stripe session link
 * @param {String} session_link session link fetched
 * @returns {Object}
 */
export function fetchStripeSessionLinkSuccessActionCreator(
  session_link: String
): IFetchStripeSessionLinkSuccessActionCreator {
  return {
    type: types.FETCH_STRIPE_SESSION_SUCCESS,
    session_link,
  };
}

export interface IFetchStripeInvoicesSuccessActionCreator extends IAction {
  invoices: any[];
  hasMore: boolean;
  parameters?: { starting_after?: string };
}
/**
 * creates a success action when we have successfully fetched stripe session link
 * @param {String} session_link session link fetched
 * @returns {Object}
 */
export function fetchStripeInvoicesSuccessActionCreator(
  invoices: any[],
  hasMore: boolean,
  parameters?: { starting_after?: string }
): IFetchStripeInvoicesSuccessActionCreator {
  return {
    type: types.FETCH_STRIPE_INVOICES_SUCCESS,
    invoices,
    hasMore,
    parameters,
  };
}
/**
 * Creates fetch owners action
 * @returns {Object}
 */
export function fetchOwnersBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_OWNERS_BEGIN,
  };
}
/**
 * Creates a fetch owners failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchOwnersFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_OWNERS_FAILURE,
    error,
  };
}
export interface IFetchOwnersSuccessActionCreator extends IAction {
  owners: IWebUser[];
}
/**
 * creates a success action when we have successfully fetched owners
 * @param {IWebUser[]} owners owners fetched
 * @returns {Object}
 */
export function fetchOwnersSuccessActionCreator(
  owners: IWebUser[]
): IFetchOwnersSuccessActionCreator {
  return {
    type: types.FETCH_OWNERS_SUCCESS,
    owners,
  };
}

/**********************************************************************
 *
 *  Billing
 *
 **********************************************************************/

export interface IEditClientPlanSuccessAction extends IAction {
  clientId: string;
  licensePlan: IClientLicensePlan;
}

export function editClientPlanSuccessActionCreator(
  clientId: string,
  licensePlan: IClientLicensePlan
): IEditClientPlanSuccessAction {
  return {
    type: types.EDIT_CLIENT_PLAN_SUCCESS,
    clientId,
    licensePlan,
  };
}

export interface IEditClientBillingInfoSuccessAction extends IAction {
  clientId: string;
  billing: IClientBilling;
}

export function editClientBillingInfoSuccessActionCreator(
  clientId: string,
  billing: IClientBilling
): IEditClientBillingInfoSuccessAction {
  return {
    type: types.EDIT_BILLING_INFO_SUCCESS,
    clientId,
    billing,
  };
}

export interface IEditClientPackSuccessAction extends IAction {
  clientId: string;
  supportPack: IClientSupportPack;
}

export function editClientPackSuccessActionCreator(
  clientId: string,
  supportPack: IClientSupportPack
): IEditClientPackSuccessAction {
  return {
    type: types.EDIT_CLIENT_PACK_SUCCESS,
    clientId,
    supportPack,
  };
}
