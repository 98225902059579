export default () => ({
  card: {
    padding: "20px",
    minWidth: "280px",
    maxHeight: "220px",
    height: "220px",
  },
  gaugeContainer: {
    height: "140px",
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    fontSize: "1.2em",
    fontWeight: "bold",
    margin: "0px",
  },
  chartCard: {
    display: "flex",
  },
  chartCardLeft: {
    width: "90%",
  },
  chartCardRight: {
    width: "10%",
  },
  rounded: {
    borderRadius: "6px",
  },
});
