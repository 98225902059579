import { LIST_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { formatString } from "lang/utils";
import TLang from "model/application/Lang";

import { TBulkAssignItemsProps } from "./utils/bulkModalUtils";

type TLink =
  | {
      item_id: string;
      mobile_user_id?: string;
    }
  | {
      item_id: string;
      team_id?: string;
    };
export const CSV_ARRAY_SEPARATOR = "|";
export const parseCSVForBulkAssignOrUnassign: TBulkAssignItemsProps["parseCSV"] =
  (lines, _csvColumnNames, additionnalProps) => {
    const links: Array<TLink> = [];
    const { listScope, lang } = additionnalProps;
    const scopeError = validateUnassigneMobileUsersScope(listScope, lang);
    const linesError = validateAssignOrUnassignCsvArraySyntax(
      lines,
      listScope,
      lang
    );
    const formatError = scopeError || linesError;
    if (formatError) {
      return formatError;
    }

    const scopeKey =
      listScope === LIST_SCOPE.MOBILE_USER ||
      listScope === LIST_SCOPE.SINGLE_MOBILE_USER
        ? "mobile_user_id"
        : "team_id";
    try {
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        if (!line) {
          continue;
        }
        const emptyFieldsError = validateEmptyFields(line, lang);
        if (emptyFieldsError) return emptyFieldsError;

        const scopedElement = _.first(line); // mobile_user_id or team_id
        const items = _.last(line)?.split(CSV_ARRAY_SEPARATOR);
        _.forEach(items, (item) => {
          if (_.isEmpty(item) || _.isEmpty(scopedElement)) return;

          links.push({
            item_id: item,
            [scopeKey]: scopedElement,
          });
        });
      }
      return links;
    } catch (e: any) {
      return {
        err: e.message,
      };
    }
  };
/* ------------------------------- VALIDATION ------------------------------- */
function validateUnassigneMobileUsersScope(listScope: LIST_SCOPE, lang: TLang) {
  const langKey =
    lang.containers.lists.subCategories.items.createEditModal
      .unAssignMobileUsers.customErrorMessages.wrongScope;
  if (_.includes([LIST_SCOPE.GLOBAL], listScope)) {
    return {
      err: langKey,
    };
  }
}
function validateAssignOrUnassignCsvArraySyntax(
  lines: string[][],
  listScope: LIST_SCOPE,
  lang: TLang
) {
  const langKey =
    lang.containers.lists.subCategories.items.createEditModal.assignUsers
      .customErrorMessages.wrongLine;
  if (
    [LIST_SCOPE.MOBILE_USER, LIST_SCOPE.SINGLE_MOBILE_USER].includes(
      listScope
    ) &&
    lines[0].join(",") !== "Mobile User ID,Item ID"
  ) {
    return {
      err: formatString(langKey, ["Mobile User ID", "Item ID"]),
    };
  }

  if (
    [LIST_SCOPE.SINGLE_TEAM, LIST_SCOPE.TEAM].includes(listScope) &&
    lines[0].join(",") !== "Team ID,Item ID"
  ) {
    return {
      err: formatString(langKey, ["Team ID", "Item ID"]),
    };
  }
}
function validateEmptyFields(words: string[], lang: TLang) {
  const langKey =
    lang.containers.lists.subCategories.items.createEditModal
      .unAssignMobileUsers.customErrorMessages.blankLine;
  if (words[0] === "") {
    return { err: langKey };
  }
}
