import _ from "lodash";

import { IList } from "model/entities/List";

export function getItemCategory(
  id: string,
  list: IList,
  categoryKey: string | undefined
) {
  if (categoryKey) {
    const item = _.find(list.items, {
      _id: id,
    });
    if (item) {
      return item[categoryKey];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
