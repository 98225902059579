import apiService from "api/apiService";
import IPreparedPictureFilterParams from "containers/pictures/model/IPreparedPictureFilterParams";
import IPicture from "model/entities/Picture";
import { IServerResponse } from "redux/actions/appActions";

const PICTURES_ENDPOINT = "pictures";

export type FetchPicturesFunc = (
  filters: IPreparedPictureFilterParams
) => Promise<IServerResponse<IFetchPictureResponse>>;
export interface IFetchPictureResponse {
  pictures: IPicture[];
  total_pictures: number;
  pictures_with_submission_deleted: number;
}

export const fetchPicturesByClientApiCall: FetchPicturesFunc = (params) => {
  return apiService.get(`${PICTURES_ENDPOINT}`, params as any);
};

export const fetchPictureAnalysis = (params: { picture_ids: string[] }) => {
  return apiService.get(`/picture_analysis`, params as any);
};
