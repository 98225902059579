import { useEffect } from "react";

import { ISecret } from "fieldpro-tools";

import { getErrorFromAttribute } from "components/Input/InputMultipleCreate/InputMultipleCreate";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";

interface ICreateEditEnvVariableModalAdditionnalProps {
  secrets: ISecret[];
}

export interface ICreateEditEnvVariableModalProps {
  onChange: (e: ISecret) => void;
  additionnalProps: ICreateEditEnvVariableModalAdditionnalProps;
  element: ISecret;
}

const CreateEditEnvVariableModal: React.FC<
  ICreateEditEnvVariableModalProps
> = ({ onChange, element }) => {
  const formStateHook = useFormState({
    initAttributes: element,
  });
  useEffect(() => {
    onChange(formStateHook.attributes);
  }, [formStateHook.attributes, onChange]);
  const lang = useTranslations();
  const labelToDisplay =
    lang.containers.clients.subCategories.clients.createEditModal;

  const handleInputChange = (value: any, name: string) => {
    formStateHook.handleInputChange(value, name);
  };

  return (
    <>
      <InputText
        error={getErrorFromAttribute(formStateHook.attributes, "name")}
        name="name"
        lang={labelToDisplay.inputVariableName}
        defaultValue={formStateHook.attributes.name}
        onChange={handleInputChange}
        uppercase
        replaceSpaces
        required
      />
      <InputText
        error={getErrorFromAttribute(formStateHook.attributes, "description")}
        name="description"
        multiline
        maxRows={2}
        lang={labelToDisplay.inputVariableDescription}
        defaultValue={formStateHook.attributes.description}
        onChange={handleInputChange}
      />
      <InputText
        error={getErrorFromAttribute(formStateHook.attributes, "value")}
        name="value"
        lang={labelToDisplay.inputVariableValue}
        defaultValue={formStateHook.attributes.value}
        onChange={handleInputChange}
        type={INPUT_TEXT_TYPE.PASSWORD}
        required
      />
    </>
  );
};

export default CreateEditEnvVariableModal;
