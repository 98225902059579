import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function TimelineSkeleton() {
  return (
    <Box
      data-testid="timeline-skeleton"
      display={"flex"}
      flexDirection={"row"}
      height={"100%"}
      width={"100%"}
    >
      <Box>
        <Skeleton
          variant="rect"
          width={20}
          height={"90%"}
          style={{ marginRight: "10px", borderRadius: "4px" }}
        />
      </Box>
      <Box width={"100%"}>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={"174px"}
          style={{ marginRight: "10px", borderRadius: "4px" }}
        />
      </Box>
    </Box>
  );
}

export default TimelineSkeleton;
