import { useRef } from "react";

import TableCell from "@material-ui/core/TableCell";

import CustomCheckBox from "components/SelectControls/CustomCheckBox";
import useContainerDimensions from "hooks/useContainerDimensions";

interface ICustomTableHeaderCheckbox {
  className?: string;
  checkBoxStatus?: number;
  onSelectAll: () => void;
}

const CustomTableHeaderCheckbox = ({
  className,
  checkBoxStatus,
  onSelectAll,
}: ICustomTableHeaderCheckbox) => {
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  return (
    <TableCell
      ref={ref}
      className={className}
      style={{
        position: "sticky",
        zIndex: 1000,
        left: 1,
        width,
      }}
    >
      <CustomCheckBox
        isIndeterminate={checkBoxStatus === 2}
        isChecked={checkBoxStatus === 1}
        onChecked={onSelectAll}
      />
    </TableCell>
  );
};

export default CustomTableHeaderCheckbox;
