import { createSelector } from "reselect";

import {
  IHttpRequest,
  IIntegration,
  IJobScript,
  INotification,
  IStream,
  ITransformation,
  STEP_TYPE,
} from "model/entities/Job";
import IRootState from "redux/store/model";

import { TAnyJob } from "../Jobscreen/utils";
import { allHttpRequestsSelector } from "./http_requests/selectors";
import { allIntegrationsSelector } from "./integrations/selectors";
import { allNotificationsSelector } from "./notifications/selectors";
import { allScriptsSelector } from "./scripts/selectors";
import { allStreamsSelector } from "./streams/selectors";
import { getAllTransformations } from "./transformations/selectors";
/**
 * Composed selector for all jobs
 */
export const allJobsComposedSelector = createSelector(
  [
    allStreamsSelector,
    allNotificationsSelector,
    getAllTransformations,
    allIntegrationsSelector,
    allScriptsSelector,
    allHttpRequestsSelector,
  ],
  (
    streams: IStream[],
    notifications: INotification[],
    transformations: ITransformation[],
    integrations: IIntegration[],
    scripts: IJobScript[],
    httpRequests: IHttpRequest[]
  ): TAnyJob[] => {
    // TOBEDONE: Move this to the back-end
    return [
      ...streams.map((stream) => {
        return { ...stream, job_type: STEP_TYPE.STREAM };
      }),
      ...notifications.map((notif) => {
        return { ...notif, job_type: STEP_TYPE.NOTIFICATION };
      }),
      ...transformations.map((tranfo) => {
        return { ...tranfo, job_type: STEP_TYPE.TRANSFORMATION };
      }),
      ...integrations.map((integ) => {
        return { ...integ, job_type: STEP_TYPE.INTEGRATION };
      }),
      ...scripts.map((script) => {
        return { ...script, job_type: STEP_TYPE.SCRIPT };
      }),
      ...httpRequests.map((request) => {
        return { ...request, job_type: STEP_TYPE.HTTP_REQUEST };
      }),
    ] as TAnyJob[];
  }
);
/**
 * Gets the status of fetching jobs
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const getIsFetchingJobs = (state: IRootState) =>
  state.jobs.transformations.isFetchingAll ||
  state.jobs.streams.isFetchingAll ||
  state.jobs.notifications.isFetchingAll ||
  state.jobs.integrations.isFetchingAll ||
  state.jobs.http_requests.isFetchingAll;

/**
 * Gets the status of creating a job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingJob = (state: IRootState) =>
  state.jobs.transformations.isCreating ||
  state.jobs.streams.isCreating ||
  state.jobs.notifications.isCreating ||
  state.jobs.integrations.isCreating ||
  state.jobs.http_requests.isCreating;

/**
 * Gets the status of updating a job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingJob = (state: IRootState) =>
  state.jobs.transformations.isUpdating ||
  state.jobs.notifications.isUpdating ||
  state.jobs.streams.isUpdating ||
  state.jobs.integrations.isUpdating ||
  state.jobs.http_requests.isUpdating;

/**
 * Gets the status of deleting a job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingJob = (state: IRootState) =>
  state.jobs.transformations.isDeleting ||
  state.jobs.streams.isDeleting ||
  state.jobs.notifications.isDeleting ||
  state.jobs.integrations.isDeleting ||
  state.jobs.http_requests.isDeleting;
/**
 * Gets the status of running a job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRunningJob = (state: IRootState) =>
  state.jobs.notifications.isRunning ||
  state.jobs.streams.isRunning ||
  state.jobs.http_requests.isRunning ||
  state.jobs.transformations.isRunning;
/**
 * Gets the status of fetching job history
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingJobHistory = (state: IRootState) =>
  state.jobs.notifications.isFetchingJobHistory ||
  state.jobs.streams.isFetchingJobHistory ||
  state.jobs.integrations.isFetchingJobHistory ||
  state.jobs.http_requests.isFetchingJobHistory ||
  state.jobs.transformations.isFetchingJobHistory;
