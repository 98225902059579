import { FormEvent,useState } from "react";

import { Box, Button, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FILTER_TYPE from "components/Filter/TypeFilter";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { useTranslations } from "hooks/useTranslations";
import { TInputAttributeLang } from "model/application/Lang";

import { IFilter } from "../../../../model/application/Filter";
import FilterValidateButton from "./FilterValidateButton";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface ITextFilterProps {
  filter: IFilter<string | number>;
  onChangeFilter: (name: string, value: any) => any;
}

export const TextFilter: React.FunctionComponent<ITextFilterProps> = (
  props: ITextFilterProps
) => {
  const lang = useTranslations();
  const classes = useStyles();

  const { filter, onChangeFilter } = props;

  const [value, setValue] = useState(filter.value);

  const handleTextChange = (value: string) => {
    if (filter.type === FILTER_TYPE.NUMBER) {
      setValue(Number.parseFloat(value));
    }
    setValue(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChangeFilter(filter.tag, value);
  };

  return (
    <div className={classes.DropdownItem}>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <InputText
            viewMode="INLINE"
            name={"text"}
            onChange={handleTextChange}
            lang={{ title: "" } as TInputAttributeLang}
            defaultValue={value}
            type={
              filter.type === FILTER_TYPE.TEXT
                ? INPUT_TEXT_TYPE.TEXT
                : INPUT_TEXT_TYPE.DECIMAL
            }
          />

          <Box marginTop="16px" />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Button
              className={classes.ClearSelectionButton}
              onClick={() => setValue("")}
            >
              {lang.components.filters.clear}
            </Button>
            <FilterValidateButton classes={classes} />
          </Box>
        </FormControl>
      </form>
    </div>
  );
};

export default TextFilter;
