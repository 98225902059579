import BaseOptionsContainer, { IInputOption } from "../BaseOptionsContainer";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import { IInputMultipleSelectPropsBase } from "../InputMultipleSelect/InputMultipleSelect";

export interface IOptionsViewProps
  extends Pick<
      IInputMultipleSelectPropsBase,
      | "options"
      | "viewMode"
      | "error"
      | "viewStacked"
      | "labelIcons"
      | "disabled"
    >,
    IInputBaseLayout {}
function OptionsView({
  tooltip,
  label,
  options,
  disabled,
  error,
  labelIcons,
  viewMode,
  viewStacked,
  highlightContent,
  ...inputBaseLayoutProps
}: IOptionsViewProps) {
  return (
    <InputBaseLayout
      {...inputBaseLayoutProps}
      key={JSON.stringify(options)}
      label={label}
      tooltip={tooltip}
      viewMode={viewMode}
      error={error}
      viewStacked={viewStacked}
      labelIcons={labelIcons}
      disabled={disabled}
    >
      <BaseOptionsContainer
        options={options as IInputOption<string | undefined>[]}
        placeholder="No selection"
        highlightContent={highlightContent}
        disabled={viewMode !== "VIEW" ? disabled : false}
      />
    </InputBaseLayout>
  );
}

export default OptionsView;
