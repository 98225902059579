import { useState } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { EventChangeScope } from "fieldpro-tools";
import _ from "lodash";

import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";

export interface IRepeatingEventConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (mode: EventChangeScope) => void;
  viewMode?: "DELETE" | "EDIT";
}

function RepeatingEventConfirmationDialog({
  onClose,
  onConfirm,
  open,
  viewMode,
}: IRepeatingEventConfirmationDialogProps) {
  const lang = useTranslations();
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal;
  const [pickedMode, setPickedMode] = useState<EventChangeScope>(
    EventChangeScope.THIS_EVENT
  );

  function handleConfirm() {
    onConfirm(pickedMode);
  }
  function getTitle() {
    const key = lang.containers.calendar.subCategories.calendar;
    switch (viewMode) {
      case "EDIT":
        return key.createEditModal.inputVisit.customMessage.editVisit;
      case "DELETE":
        return key.createEditModal.inputVisit.customMessage.deleteVisit;
    }
  }
  function getConfirmText() {
    switch (viewMode) {
      case "EDIT":
        return lang.actions.edit;
      case "DELETE":
        return lang.actions.delete;
      default:
        return lang.modal.confirm;
    }
  }

  return (
    <ConfirmationDialog
      open={open}
      title={getTitle() || ""}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText={_.upperFirst(getConfirmText())}
    >
      <RadioGroup
        name="mode"
        value={pickedMode}
        onChange={(e) => setPickedMode(e.target.value as EventChangeScope)}
      >
        <FormControlLabel
          value={EventChangeScope.THIS_EVENT}
          control={<Radio style={{ color: "black" }} />}
          label={formatString(
            langKey.repeatingEventDialogScope.options.THIS_EVENT,
            [langKey.visit.title]
          )}
        />

        <FormControlLabel
          value={EventChangeScope.THIS_AND_FUTURE_EVENTS}
          control={<Radio style={{ color: "black" }} />}
          label={formatString(
            langKey.repeatingEventDialogScope.options.THIS_AND_FUTURE_EVENTS,
            [langKey.visit.options.visits]
          )}
        />

        <FormControlLabel
          value={EventChangeScope.ALL_EVENTS}
          control={<Radio style={{ color: "black" }} />}
          label={formatString(
            langKey.repeatingEventDialogScope.options.ALL_EVENTS,
            [langKey.visit.options.visits]
          )}
        />
      </RadioGroup>
    </ConfirmationDialog>
  );
}

export default RepeatingEventConfirmationDialog;
