import React from "react";

import { useSelector } from "react-redux";

import InputBoolean from "components/Input/InputBoolean";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";

const CalendarSettings: React.FC<{
  viewMode: TViewMode;
  is_calendar_type_day_only?: boolean;
  onInputChange: (value: any, name: string) => void;
}> = ({ onInputChange, viewMode, is_calendar_type_day_only = false }) => {
  const client = useSelector(getSelectedClient);
  const lang = useTranslations();
  const planningLangKey =
    lang.containers.calendar.subCategories.calendar.createEditModal
      .planningConfig;

  return (
    <>
      <InputBoolean
        defaultValue={client?.enable_visit_planning_weekends}
        onChange={onInputChange}
        viewMode={viewMode}
        lang={{
          title: planningLangKey.customMessage.displayWeekends,
          tooltip: planningLangKey.customMessage.displayWeekendsDescription,
        }}
        name="enable_visit_planning_weekends"
      />
      <InputBoolean
        defaultValue={client?.calendar_validation_enabled}
        onChange={onInputChange}
        viewMode={viewMode}
        lang={{
          title: planningLangKey.customMessage.calendarValidationEnabled,
          tooltip:
            planningLangKey.customMessage.calendarValidationEnabledDescription,
        }}
        name="calendar_validation_enabled"
      />
      <InputBoolean
        defaultValue={is_calendar_type_day_only}
        onChange={onInputChange}
        viewMode={viewMode}
        lang={{
          title: planningLangKey.customMessage.calendarIsDayOnly,
          tooltip: planningLangKey.customMessage.calendarIsDayOnlyDescription,
        }}
        name="is_calendar_type_day_only"
      />
    </>
  );
};

export default CalendarSettings;
