import { IDashboard as IBEDashboard } from "fieldpro-tools/dist/src/types/dashboards";
import _ from "lodash";

import {
  AGGREGATOR,
  AXIS_TYPE,
  DB_TYPE,
  DEFAULT_DASHBOARD,
  IDashboard,
  IKPI,
  ISubQuery,
} from "model/entities/Dashboard";
import { isEmptyValue } from "utils/isEmptyValue";
import { clone } from "utils/utils";

const prepareDashboardForBackend = (
  originalDashboard: IDashboard
): IDashboard => {
  const dashboard = clone(originalDashboard);
  Object.keys(dashboard).forEach((att) => {
    if (
      !["date_filter", "team_filter", "user_filter"].includes(att) &&
      dashboard[att] === ""
    )
      delete dashboard[att];
  });
  if (dashboard.alias_mapping)
    dashboard.alias_mapping = dashboard.alias_mapping.map((e: any) => {
      delete e.index;
      return e;
    });
  dashboard.kpis = dashboard.kpis.map((kpi: { [x: string]: any }) => {
    delete kpi.id;
    delete kpi.key;
    // delete kpi.value;
    delete kpi.data; // make sure there is no data in the kpi (happens sometimes when uploading a WIP json file)
    delete kpi.db_total_rows;

    delete kpi.variationData;
    Object.keys(kpi).forEach((att) => {
      if (kpi[att] === "") delete kpi[att];
    });
    if (kpi.sub_queries) {
      kpi.sub_queries = kpi.sub_queries.map((subq: any) => {
        delete subq.index;
        delete subq.key;
        Object.keys(subq).forEach((subqatt) => {
          if (subq[subqatt] === "") delete subq[subqatt];
        });
        return subq;
      });
      kpi.sub_queries.forEach((sq: ISubQuery) => delete sq.index);
    }
    return kpi;
  });
  delete dashboard.id;
  delete dashboard.client_id;
  delete dashboard.updated_at;
  delete dashboard.created_at;
  delete dashboard.query;
  delete dashboard.raw_data;
  return dashboard;
};

const prepareDashboardsForFrontend = (
  originalDashboards: IBEDashboard[]
): IDashboard[] => {
  let dashboards = clone(originalDashboards);
  // make sure all the default attributes are set
  dashboards = dashboards.map((d: any) => {
    d.query = {};
    if (!d.hasOwnProperty("db_type")) d.db_type = DB_TYPE.OPTIMETRIKS;
    if (!d.hasOwnProperty("host")) d.host = "";
    if (!d.hasOwnProperty("port")) d.port = "";
    if (!d.hasOwnProperty("db_name")) d.db_name = "";
    if (!d.hasOwnProperty("user")) d.user = "";
    if (!d.hasOwnProperty("password")) d.password = "";
    if (!d.hasOwnProperty("list_id")) d.list_id = "";
    d.kpis = d.kpis
      .sort(
        (a: any, b: any) => Number.parseInt(a.index) - Number.parseInt(b.index)
      )
      .map((k: any, idx: number) => ({ ...k, index: idx }))
      .map((k: IKPI) => {
        if (!k.hasOwnProperty("sub_queries")) k.sub_queries = [];
        if (!k.hasOwnProperty("row_query")) k.row_query = "";
        if (!k.hasOwnProperty("query")) k.query = "";
        if (!k.hasOwnProperty("aggregator")) k.aggregator = AGGREGATOR.SUM;
        if (!k.hasOwnProperty("x_axis_type")) k.x_axis_type = AXIS_TYPE.TEXT;
        if (!k.hasOwnProperty("is_filled")) k.is_filled = false;
        if (!k.hasOwnProperty("full_width")) k.full_width = false;
        if (!k.hasOwnProperty("unit")) k.unit = "";
        if (k.sub_queries) {
          k.sub_queries = k.sub_queries.map((subq: any, index: number) => ({
            ...subq,
            index,
          }));
        }
        return k;
      });
    if (d.alias_mapping && d.alias_mapping.length > 0) {
      d.alias_mapping = d.alias_mapping.map((mapping: any, index: number) => ({
        ...mapping,
        index,
      }));
    }
    if (d.id === "_log_report") {
      d.kpis[0].full_width = true;
    }
    return d;
  });
  return dashboards;
};

const cleanKpis = (
  dashboardId: string,
  kpis: Partial<IKPI>[]
): Partial<IKPI>[] => {
  const result = kpis;
  if (dashboardId === DEFAULT_DASHBOARD.GPS_TRACKING) {
    result[0].data = kpis[0].data.map((d: any) => {
      if (d.label === "places_visited") {
        d.data = d.data.filter((e: any) => e.lat && e.lng);
      }
      return d;
    });
  }
  return result;
};

const formatErrorMessage = (message: any) =>
  JSON.stringify(message, null, 2).replace(/\\n/g, "\n").replace(/\\t/g, "  ");

export const generateErrorBlockHtmlStyle = () => ({
  layout: `
    display: flex; 
    flex-direction: column; 
    gap: 40px;
    `,
  div: `
      display: flex; 
      flex-direction: column; 
      gap: 5px;
      `,
  h3: `
      font-size: 16px; 
      font-weight: bold; 
      color: black;
    `,
  code: `
      display: block; 
      background-color: #fefefe; 
      color: #6C6C6C; 
      padding: 15px; 
      border-radius: 5px; 
      white-space: pre; 
      font-family: 'Courier New', Courier, monospace;  width: 100%; 
      overflow: auto; 
      text-align: start; 
      font-size: 16px; 
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    `,
});

const generateErrorBlockHtmlLayout = (children: string) => {
  const style = generateErrorBlockHtmlStyle();
  return `
  <div style="${style.layout}">
    ${children}
  </div>
  `;
};

const generateErrorBlockHtml = (
  kpiId: string,
  kpiTitle: string,
  error: string
) => {
  const style = generateErrorBlockHtmlStyle();
  return `
  <div style="${style.div}">
    <h3 style="${style.h3}">${kpiTitle} (ID: ${kpiId})</h3>
    <code style="${style.code}">${formatErrorMessage(error)}</code>
  </div>
  `;
};

export const generateErrorBlocks = (
  errors: {
    kpiId: string;
    kpiTitle: string;
    error: string;
  }[]
) => {
  const content = _.join(
    _.map(errors, ({ kpiId, kpiTitle, error }) => {
      return generateErrorBlockHtml(kpiId, kpiTitle, error);
    }),
    ""
  );
  return !isEmptyValue(content)
    ? generateErrorBlockHtmlLayout(content)
    : content;
};

export { cleanKpis, prepareDashboardForBackend, prepareDashboardsForFrontend };
