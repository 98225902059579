import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  root: {
    overflow: "hidden",
  },
  customCardActionBar: {
    padding: "0px",
    height: "100%",
  },
  ContentLoader: {
    overflowY: "auto",
    height: "calc(100vh - 80px - 20px - 100px - 20px)",
  },
  customTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ActionButtonBigContainer: {
    justifyContent: "flex-end !important",
    paddingTop: "8px",
    paddingRight: "8px",
    margin: "0px",
    marginRight: "-15px",
  },
  ActionButtonSmallContainer: {
    justifyContent: "flex-end",
    marginRight: "0px",
    marginLeft: "auto",
    position: "relative",
    display: "flex",
    width: "max-content",
    background: "white",
  },

  typography: {
    fontWeight: "bold",
    fontSize: 16,
  },

  ColumnButton: {
    flexShrink: 0,
    color: `${theme.palette.primary.main} !important`,
    padding: "2px 8px",
    textTransform: "capitalize !important",
  },

  SearchDropdown: {
    padding: "20px",
  },

  SearchDropdownItems: {
    margin: "0 -16px",
    marginTop: "12px",
    maxHeight: "264px",
    minWidth: "10rem",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  showColumnStyle: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",

    "&:hover .eyeContainer": {
      display: "flex !important",
    },
  },

  hideColumnStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
