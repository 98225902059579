import { createSelector } from "reselect";

import { getAllWorkflows } from "containers/workflows/redux/selectors";
import { IOption } from "model/application/components";
import { IClient } from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";
import { ITeam, ITeamSelector } from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";
import { IWorkflow } from "model/entities/Workflow";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../redux/selectors/appSelector";

/**
 * Selected sub-category
 * teams
 */
export const getSelectedSubCategory = (state: IRootState) =>
  state.teams.subCategorySelected;

/**
 * Selector to get the state of creating a team
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsCreatingTeam = (state: IRootState) =>
  state.teams.isCreatingTeam;

/**
 * Selector to get the state of creating teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsCreatingTeams = (state: IRootState) =>
  state.teams.isCreatingTeams;

/**
 * Selector to get the state of updating a team
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsUpdatingTeam = (state: IRootState) =>
  state.teams.isUpdatingTeam;

/**
 * Selector to get the state of deleting teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsDeletingTeams = (state: IRootState) =>
  state.teams.isDeletingTeams;

/**
 * Selector to get the state of archive teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsArchivingTeams = (state: IRootState) =>
  state.teams.isArchivingTeams;

/**
 * Selector to get the state of restoring teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsRestoringTeams = (state: IRootState) =>
  state.teams.isRestoringTeams;

/**
 * Selector to get the state of unassigning users from teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsUnAssigningUsersFromTeams = (state: IRootState) =>
  state.teams.isUnAssigningUsersFromTeams;

/**
 * Gets the state of fetching teams for a given client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingTeamsForClient = (state: IRootState) =>
  state.teams.isFetchingTeamsForClient;

/**
 * Get all teams from redux store
 * @param {Object} state
 * @returns {Array} Array of group objects
 */
const getAllTeams = (state: IRootState) => {
  return state.teams.allTeams;
};

/**
 * Get the teams by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getTeamsById = (state: IRootState, id: string) =>
  state.teams.allTeams.find((t) => t.id === id);

/**
 * Get app Users
 * @param {Object} state State of Redux store
 */
const getAllMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.allUsers;

/**
 * Get app Users
 * @param {Object} state State of Redux store
 */
const getAllWebUsers = (state: IRootState) => state.users.webUsers.allUsers;

const getSelectedClient = (state: IRootState) => state.clients.selectedClient;

/**
 * Get dashboards
 * @param {Object} state State of Redux store
 */
const getAllDashboards = (state: IRootState): IDashboard[] =>
  state.dashboards.dashboards;

/**
 * Selector to get teams by id
 * @param {Object} state
 * @returns {Object}
 */
export const teamsByIdSelector = createSelector(getTeamsById, (team) => team);

/**
 * Select to get the id of all active teams for a given client
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const teamSimplifiedSelector = customSelectorCreator(
  [getAllTeams],
  (teams: ITeam[]) => {
    return teams
      .filter((t) => t.active)
      .map((t) => ({
        id: t.id,
        name: t.name,
      }));
  }
);

/**
 * Composed Selector to get teams by the given client
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const teamsComposedSelector = customSelectorCreator(
  [
    getAllTeams,
    getAllWorkflows,
    getAllDashboards,
    getAllMobileUsers,
    getAllWebUsers,
    getSelectedClient,
  ],
  (
    teams: ITeam[],
    workflows: IWorkflow[],
    dashboards: IDashboard[],
    mobileUsers: IMobileUser[],
    webUsers: IWebUser[],
    selectedClient: IClient
  ): ITeamSelector[] => {
    return teams.map((t) => {
      const {
        workflows: wfs,
        mobile_users: mus,
        web_users: wus,
        dashboards: ds,
        active: act,
        ...rest
      } = t;
      const workflowsLinkedToTeam = wfs
        .map((wid) =>
          workflows.filter((w) => w.active).find((w) => w.id === wid)
        )
        .filter((w) => w) as IWorkflow[];
      const workflowOptions: IOption[] = workflowsLinkedToTeam.map((w) => ({
        key: w.id,
        label: w.name,
      }));
      const mobUsersLinkedToTeam = mus
        .map((uid) =>
          mobileUsers.filter((u) => u.licensed).find((u) => u.id === uid)
        )
        .filter((u) => u) as IMobileUser[];
      const mobUserOptions: IOption[] = mobUsersLinkedToTeam.map((u) => ({
        key: u.id,
        label: `${u.first_name} ${u.last_name}`,
      }));
      const webUsersLinkedToTeam = wus
        .map((uid) => webUsers.find((u) => u.id === uid))
        .filter((u) => u) as IWebUser[];
      const webUserOptions: IOption[] = webUsersLinkedToTeam
        .filter((u) => {
          const rolesAccessToRestrictedResources =
            selectedClient.access_right_profiles
              .filter(
                (p) =>
                  (p.hasOwnProperty("access_all_resources") &&
                    !p.access_all_resources) ||
                  !p.hasOwnProperty("access_all_resources")
              )
              .map((p) => p.name);
          const result = rolesAccessToRestrictedResources.includes(u.role);
          return result;
        })
        .map((u) => ({
          key: u.id,
          label: `${u.first_name} ${u.last_name}`,
        }));
      const dashboardsLinkedToTeam = ds
        .map((did) => dashboards.find((d) => d.id === did))
        .filter((d) => d && d.active) as IDashboard[];
      const dashboardOptions: IOption[] = dashboardsLinkedToTeam.map((d) => ({
        key: d.id,
        label: d.name,
      }));
      const active =
        !t.hasOwnProperty("active") || act === null || act === undefined || act;
      const teamToReturn: ITeamSelector = {
        active,
        workflows: active ? workflowOptions : [],
        mobile_users: active ? mobUserOptions : [],
        web_users: active ? webUserOptions : [],
        dashboards: active ? dashboardOptions : [],
        ...rest,
      };
      Object.keys(selectedClient.meta_hierarchy_dependencies).forEach(
        (level) => {
          teamToReturn[level] = t[level];
        }
      );
      return teamToReturn;
    });
  }
);

export const activeTeamsComposedSelector = createSelector(
  teamsComposedSelector,
  (teams: ITeamSelector[]) => teams.filter((t) => t.active === true)
);
/**
 * Selector to get the state of assigning workflows to teams
 * @param {Object} state redux store
 * @returns {Boolean}
 */
export const getIsAssigningWorkflowsToTeams = (state: IRootState) =>
  state.teams.isAssigningWorkflowsToTeams;

/** Groups selector. Selects the teams from store*/
export const teamsSelector = createSelector(getAllTeams, (groups) => groups);

/**
 * composed selector to get all teams from store and create an options selector
 * @param {Object} state Redux store state
 */
export const teamOptionsComposedSelector = createSelector(
  teamsSelector,
  (allTeams) => {
    return allTeams.map((g) => {
      return {
        id: g.id,
        label: g.name,
        value: g.name,
      };
    });
  }
);
