import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicLaunchIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <span className="material-icons-outlined">launch</span>
    </SmallIconButton>
  );
};

export default BasicLaunchIcon;
