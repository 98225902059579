import { Query } from "fieldpro-tools";
import _ from "lodash";

//this is necessary to convert code back into something tagify can understand
//it will go through the code and find any query pointer like $queryName$
//then format it into tagify format = [[value : something,...anykey]]
//this is necessary because tagify will not accept $queryName$ as a tag

export function replaceQueryPointersWithTags(code: string, queries: Query[]) {
  if (!code) return "";
  const queryPointerRegex = /\$[\w\s-]+\$/gm; //matches $queryName$ or $query-name$ or $query name$
  code.match(queryPointerRegex)?.forEach((m) => {
    const queryName = m.replaceAll("$", "");
    const query = _.find(queries, {
      name: queryName,
    });

    if (query) {
      code = code.replaceAll(m, JSON.stringify(convertQueryToTag(query)));
    }
  });
  return code;
}

export function convertQueryToTag(query: Query) {
  return [
    [
      {
        ...query,
        value: query?.name,
      },
    ],
  ];
}
