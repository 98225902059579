import { DependencyList, useMemo } from "react";

import _ from "lodash";

// See: https://dmitripavlutin.com/react-throttle-debounce/#3-debouncing-a-callback-second-attempt
function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  duration: number,
  deps: DependencyList
) {
  // https://ellenaua.medium.com/throttle-debounce-behavior-lodash-6bcae1494e03
  const options = {
    leading: true, // At least one call, immediately
    trailing: true, // false => means we will miss some updates
  };

  const debouncedCallback = useMemo(
    () => _.debounce(callback, duration, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
  return debouncedCallback;
}

export default useDebouncedCallback;
