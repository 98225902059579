import { createCheckoutSessionAction } from "containers/clients/redux/actions";
import { useActions } from "hooks/useActions";

const useOpenStripeCheckout = ({
  clientId,
  withTrial,
}: {
  clientId: string;
  withTrial?: boolean;
}) => {
  const createCheckoutSession = useActions(createCheckoutSessionAction);

  const openStripeCheckout = async () => {
    // Idea: open plan selector modal to select billing period and number of licenses (and associated price)
    // For now, fetching Pro's default_price id from Stripe in the back-end.
    // The quantity (=numberOfLicenses) is editable by the user on the Checkout page.
    // However, the billing cycle will have to be adjusted later in the Portal if necessary.
    const checkoutUrl = await createCheckoutSession({
      clientId,
      withTrial,
      // numberOfLicenses: 0,
      // billingCycle: "month", // determines the priceId
      // priceId: "pk_1234",
    });

    // other method: window.location.href = checkoutUrl;
    window.open(checkoutUrl, "_self");
  };

  return openStripeCheckout;
};

export default useOpenStripeCheckout;
