import { Black, darkgray, GreyBorder, GreyLight } from "assets/colors";

const styles = {
  inputBorder: {
    width: "100%",
    border: `dashed 1px ${GreyBorder}`,
    background: "red",
    height: "50px",
  },
  ThumbnailContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    alignItems: "center",
  },
  container: {
    width: "90px", // "GEOJSON" text should fit
    height: "80px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
  },
  description: {
    padding: "0px 7px",
    background: darkgray,
    color: "white",
    width: "inherit",
    zIndex: 2,
  },
  icon: {
    fontSize: "60px !important",
    color: GreyLight,
    transform: "translateY(15px)",
    zIndex: "1",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "3",
    opacity: "0.5",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "Center",
    background: "grey",
    transition: "0.3s",
  },
  overlayButton: {
    background: Black,
    color: "white",
    transition: "0.1s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  controls: {
    display: "flex",
    flexDirection: "row",
  },
};

export default styles;
