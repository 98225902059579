import { useEffect, useState } from "react";

import { Box, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import TPicture from "model/entities/Picture";

import PictureGalleryChips from "./PictureGalleryChips";
import PictureGalleryList from "./PictureGalleryList";
import PictureGallerySlide from "./PictureGallerySlide";

const styles = {
  imageListArea: {
    overflowY: "auto",
    maxHeight: "500px",
    padding: "0px",
    justifyContent: "center",
  },
  pictureGallery: {
    overflow: "hidden",
  },
} as const;

const useStyles = makeStyles(styles);
export interface IPictureGalleryProps {
  pictures: TPicture[];
}

function PictureGallery({ pictures, ...props }: IPictureGalleryProps) {
  const classes = useStyles();

  const [selectedLabel, setSelectedLabel] = useState<string | undefined>();
  const [selectedPictureIndex, setSelectedPictureIndex] = useState<number>(0);
  const [openPictureModalState, setOpenPictureModalState] = useState(false);

  useEffect(() => {
    setSelectedLabel(undefined); // reset chips on activity report change
  }, [pictures]);

  if (_.isEmpty(pictures)) {
    return null;
  }

  return (
    <Box className={classes.pictureGallery} {...props}>
      <Grid
        container
        direction="column"
        style={{
          width: "100%",
          columnGap: "16px",
          rowGap: "16px",
          paddingBottom: "16px",
        }}
      >
        <Grid item>
          <PictureGalleryChips
            pictures={pictures}
            selectedTag={selectedLabel}
            label="label"
            onChipClick={(label) => setSelectedLabel(label)}
          />
        </Grid>

        <Grid item className={classes.imageListArea}>
          <PictureGalleryList
            pictures={pictures}
            selectedLabel={selectedLabel}
            onImageClick={(_imageTag, imageIndex) => {
              setSelectedPictureIndex(imageIndex);
              setOpenPictureModalState(true);
            }}
          />

          <PictureGallerySlide
            open={openPictureModalState}
            onArrowClick={function (newIndex) {
              setSelectedPictureIndex(newIndex);
            }}
            pictures={pictures}
            currentIndex={selectedPictureIndex}
            onModalClose={function () {
              setOpenPictureModalState(false);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PictureGallery;
