import { ICellContentProps } from "components/Table/Cell/CellContent";
import getValueAsString from "utils/formatting/getValueAsString";

interface SimpleCellContent extends ICellContentProps {}

const SimpleCellContent = ({ value }: SimpleCellContent) => {
  return <span>{getValueAsString(value)}</span>;
};

export default SimpleCellContent;
