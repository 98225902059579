import { IJobScriptState } from "./reducer";

const initialState: IJobScriptState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  isFetchingJobHistory: false,
  isRunning: false,
  selectedScript: null,
  scripts: [],
  errors: [],
};

export default initialState;
