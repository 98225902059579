import { Component } from "react";

import _ from "lodash";

import UploadFileInput from "components/Input/LegacyInput/UploadFileInput";
import CustomTypography from "components/Typography/CustomTypography";
import { formatString, getEntityFromSubCategory } from "lang/utils";
import TLang, {
  LANG_ACTIONS,
  SUB_CATEGORIES,
  TInputAttributeLang,
} from "model/application/Lang";
import {
  IParseCSVFunc,
  IReturnErrorParseCSV,
} from "model/application/modal/BulkModal";

import CustomDialogForm from "./CustomDialogForm";

export interface IAdditionnalProps<T, T2 = {}> {
  csvBodyTemplate: (additionnalProps: T2) => string;
  parseCSV: IParseCSVFunc<T, T2>;
  csvColumnNames?: string[];
  subCategoryInLang: SUB_CATEGORIES;
  modalNameInLang: string;
  warningNameInLang?: string;
  additionnalProps?: T2;
  operationType: LANG_ACTIONS;
}

export interface ICustomDialogBulkOperationProps<T, T2 = {}>
  extends IAdditionnalProps<T, T2> {
  onAction: (objects: T[], keysToConsider?: string[]) => void;
  isOpen?: boolean;
  onClose: () => void;
  lang: TLang;
}
interface ICustomDialogBulkOperationState<T> {
  objects: T[];
  hasError: boolean;
  validFile: boolean;
  keysToConsider: string[];
  error?: any;
}

/**
 * Make sure to have the following properties in the lang object:
 * - lang.containers[entityNameInLang].subCategory[subCategoryInLang][modalNameInLang].description
 * - lang.containers[entityNameInLang].subCategory[subCategoryInLang].subject
 */
class CustomDialogBulkOperation<T, T2 = {}> extends Component<
  ICustomDialogBulkOperationProps<T, T2>,
  ICustomDialogBulkOperationState<T>
> {
  public static defaultProps = {
    additionnalProps: {},
  };

  constructor(props: ICustomDialogBulkOperationProps<T, T2>) {
    super(props);
    this.state = {
      hasError: false,
      validFile: false,
      keysToConsider: [],
      objects: [],
    };
  }

  validate = (validFile: boolean) => {
    return {
      validFile: !validFile,
    };
  };

  handleConfirmAction = () => {
    const { objects } = this.state;
    const { onAction } = this.props;
    onAction(objects, this.state.keysToConsider);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      validFile: false,
    });
    this.props.onClose();
  };

  parseCSVBody = (lines: string[][], csvColumnNames: string[]) => {
    const { parseCSV, additionnalProps } = this.props;
    const csvHeader = csvColumnNames.join(",");
    const line0 = lines[0].join(",");
    // check header
    if (line0 !== csvHeader) {
      return { err: `The first line should contain ${csvHeader}` };
    }
    // check body
    return parseCSV(lines, csvColumnNames, additionnalProps ?? ({} as T2));
  };

  /**
   * Handles file input
   * @param {Object} listFile List file
   */
  handleFileInput = (data: string[][]) => {
    const { csvColumnNames, parseCSV, additionnalProps } = this.props;

    //initialisation
    const header = data[0];
    this.setState({
      ...this.state,
      validFile: false,
      keysToConsider: header,
    });

    // re-compute the additionnalProps
    let addProps = additionnalProps ?? ({} as T2);
    if (additionnalProps && (additionnalProps as any).schema) {
      addProps = {
        ...additionnalProps,
        initialSchema: (additionnalProps as any).schema,
        schema: _.filter((additionnalProps as any).schema, (att: any) =>
          _.includes(header, att.column_tag)
        ),
      };
    }

    // parse file input
    let objects;
    if (csvColumnNames) {
      objects = this.parseCSVBody(data, csvColumnNames);
    } else {
      objects = parseCSV(data, [], addProps);
    }

    if ((objects as IReturnErrorParseCSV).err) {
      throw new Error((objects as IReturnErrorParseCSV).err);
    }

    this.setState({
      ...this.state,
      objects: objects as T[],
      validFile: true,
    });
  };

  csvTemplate = () => {
    const { csvBodyTemplate, csvColumnNames, additionnalProps } = this.props;
    let template = "data:text/csv;charset=utf-8,";
    if (csvColumnNames) {
      const csvHeader = csvColumnNames.join(",");
      template += csvHeader + "\n";
    }
    template += csvBodyTemplate(additionnalProps || ({} as T2));
    return template;
  };

  confirmButtonEnabled = () => {
    const { validFile } = this.state;
    const errors = this.validate(validFile);
    return !Object.keys(errors).some((x) => errors[x]) && validFile;
  };

  onErrorFile = (error: any) => {
    this.setState({
      validFile: false,
      error,
    });
  };

  render() {
    const {
      isOpen = false,
      lang,
      modalNameInLang,
      warningNameInLang,
      subCategoryInLang,
      operationType,
    } = this.props;

    const { validFile, error, objects } = this.state;

    const modalLang =
      lang.containers[getEntityFromSubCategory(subCategoryInLang)]
        .subCategories[subCategoryInLang];

    const langlabel: { [att: string]: TInputAttributeLang } = {
      warningOnConfirm: {
        title: lang.modal.bulk.warningOnConfirm.title,
        description: formatString(
          modalLang[warningNameInLang]?.description ??
            lang.modal.bulk.warningOnConfirm.description,
          [objects.length, subCategoryInLang]
        ),
      } as TInputAttributeLang,
    };

    const messageDescriptionTemplate = (
      <CustomTypography>
        {modalLang?.[modalNameInLang]
          ? modalLang[modalNameInLang].description
          : formatString(lang.modal.bulk.mainModal.description, [
              operationType,
              lang.containers[getEntityFromSubCategory(subCategoryInLang)]
                .subCategories[subCategoryInLang].subject,
            ])}{" "}
        <a
          className="link"
          download="template.csv"
          href={encodeURI(this.csvTemplate())}
        >
          link
        </a>
        .
      </CustomTypography>
    );

    return (
      <CustomDialogForm
        isOpen={isOpen}
        onClose={this.handleClose}
        title={
          modalLang[modalNameInLang]?.title ??
          formatString(lang.modal.bulk.mainModal.title, [operationType])
        }
        langlabel={langlabel}
        onConfirmAction={this.handleConfirmAction}
        confirmBtnText={lang.modal.proceed}
        isDisabled={!this.confirmButtonEnabled()}
        lang={lang}
      >
        <UploadFileInput
          lang={lang}
          description={messageDescriptionTemplate}
          onUpdateFile={this.handleFileInput}
          onError={this.onErrorFile}
          isErrorFile={!validFile && error}
          errorMessageFile={error}
        />
      </CustomDialogForm>
    );
  }
}

export default CustomDialogBulkOperation;
