import React from "react";

import { Typography } from "@material-ui/core";

import { Black } from "assets/colors";

interface IInputViewValue {
  value: React.ReactNode;
}

const InputViewValue = ({ value }: IInputViewValue) => {
  return (
    <Typography
      style={{
        fontSize: "16px",
        color: Black,
        fontFamily: "BasierCircle",
      }}
      data-testid="input-view-value"
    >
      {value}
    </Typography>
  );
};

export default InputViewValue;
