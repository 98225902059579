import _ from "lodash";

import { IFileInputData } from "hooks/useFormState";
import {
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  getDetailedActionName,
} from "model/application/ActionCode";

export interface IUploadFormFiles {
  metadata: Object;
  uploadFilesFunction: Function;
  filesToUpload: IFileInputData[];
}

interface IUploadFile {
  file: string;
  upload_id: string;
  url: string;
}

export interface IUploadMatrixFilesResult {
  uploaded: boolean;
  urls: IUploadFile[];
}
export const uploadMatrixFiles = async ({
  metadata,
  uploadFilesFunction,
  filesToUpload,
}: IUploadFormFiles): Promise<IUploadMatrixFilesResult | null | undefined> => {
  if (_.isEmpty(filesToUpload)) {
    return null;
  }
  const metas = [];
  const files = [];

  for (const fileData of filesToUpload) {
    const { file, tag, fileName } = fileData || {};

    if (file) {
      const meta = {
        tag,
        filename: fileName,
        ...metadata,
      };

      files.push(fileData);
      metas.push(meta);
    }
  }

  if (_.isEmpty(files)) {
    return null;
  }

  try {
    const actionName = getDetailedActionName(
      DETAILED_ACTION_TYPE.UPLOAD_FILE_FOR,
      DETAILED_OBJECT_CATEGORY.ACTIVITY_REPORT
    );
    return uploadFilesFunction(actionName, files, metas);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
