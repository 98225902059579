import _ from "lodash";

import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { CUSTOM_FIELD_TYPE, IList, IListSchema } from "model/entities/List";
import {
  CONDITION_OPERATOR,
  ICondition,
  IConditionOperand,
  IOperation,
  IOperationOperand,
  IQuestion,
} from "model/entities/Workflow";
import { questionTypeToColumnTypeForConditionAndOperation } from "utils/businessUtils";

import {
  OPERAND_REAL_TYPE,
  TExpression,
} from "../../model/entities/Expression";

export const formatOperandForChip = (
  operande: IConditionOperand | IOperationOperand,
  conditions?: { id: number; label: string }[],
  matrixQuestionTag?: string,
  matrixListName?: string
): string => {
  if (operande.tag) {
    let chipName = `${operande.tag}`;
    if (operande.attr) chipName = `${chipName}.${operande.attr}`;
    if (operande.param) chipName = `${chipName}.${operande.param}`;
    if (matrixQuestionTag) {
      chipName = `${matrixQuestionTag}.${chipName}`;
    }
    return chipName;
  } else if ((operande as any).cell_attr) {
    return `${matrixListName}.${(operande as any).cell_attr}`;
  } else if (matrixListName && (operande.attr || operande.param)) {
    return `${matrixListName}.${
      operande.attr ? operande.attr : operande.param
    }`;
  } else if ((operande as IConditionOperand).condition_id) {
    // case of condition selected
    const condition = _.find(
      conditions,
      (cond) => cond.id === (operande as IConditionOperand).condition_id
    );
    return condition?.label || "";
  } else if ((operande as IOperationOperand).operation_id) {
    // case of condition selected
    const condition = _.find(
      conditions,
      (cond) => cond.id === (operande as IOperationOperand).operation_id
    );
    return condition?.label || "";
  } else {
    // case of constant selected
    return operande.value || "";
  }
};

export const findOperatorOptions = (
  lang: TLang,
  type: OPERAND_REAL_TYPE | undefined
): IOption<CONDITION_OPERATOR>[] => {
  let possibleOperators: CONDITION_OPERATOR[] = [];

  if (type === OPERAND_REAL_TYPE.BOOLEAN) {
    possibleOperators = [
      CONDITION_OPERATOR.AND,
      CONDITION_OPERATOR.DIFFERENT,
      CONDITION_OPERATOR.EQUAL,
      CONDITION_OPERATOR.OR,
      CONDITION_OPERATOR.IS_EMPTY,
      CONDITION_OPERATOR.IS_NOT_EMPTY,
    ];
  } else if (type === OPERAND_REAL_TYPE.STRING) {
    possibleOperators = [
      CONDITION_OPERATOR.EQUAL,
      CONDITION_OPERATOR.DIFFERENT,
      CONDITION_OPERATOR.CONTAINS,
      CONDITION_OPERATOR.IS_EMPTY,
      CONDITION_OPERATOR.IS_NOT_EMPTY,
    ];
  } else if (type === OPERAND_REAL_TYPE.NUMBER) {
    possibleOperators = [
      CONDITION_OPERATOR.DIFFERENT,
      CONDITION_OPERATOR.EQUAL,
      CONDITION_OPERATOR.GREATER_OR_EQUAL_TO,
      CONDITION_OPERATOR.GREATER_THAN,
      CONDITION_OPERATOR.LESS_OR_EQUAL_TO,
      CONDITION_OPERATOR.LESS_THAN,
      CONDITION_OPERATOR.IS_EMPTY,
      CONDITION_OPERATOR.IS_NOT_EMPTY,
    ];
  } else {
    possibleOperators = Object.keys(CONDITION_OPERATOR) as CONDITION_OPERATOR[];
  }
  return _.map(possibleOperators, (operator) => ({
    key: operator,
    label: lang.genericTerms.operators[operator],
  }));
};

export const findListAttributeOptions = (
  questions: IQuestion[],
  lists: IList[],
  matrixQuestionTag: string | undefined,
  allowedTypes: OPERAND_REAL_TYPE[] = [OPERAND_REAL_TYPE.NUMBER]
): IOption[] => {
  const result: IOption[] = [];
  const matrixQuestion = questions.find((q) => q.tag === matrixQuestionTag);
  const list = lists.find((l) => l.id === matrixQuestion?.list_id);
  list?.schema.forEach((att) => {
    const realType = getTypeFromAttributeType(att.type);
    if (realType && allowedTypes.includes(realType)) {
      result.push({
        key: JSON.stringify({
          cell_attr: att.column_tag,
          type: realType,
        }),
        label: `${list?.name}.${att.column_tag}`,
      });
    }
  });
  return result;
};

export const getTypeFromAttributeType = (
  attrType: CUSTOM_FIELD_TYPE
): OPERAND_REAL_TYPE | undefined => {
  switch (attrType) {
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      return OPERAND_REAL_TYPE.STRING;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      return OPERAND_REAL_TYPE.BOOLEAN;
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.INTEGER:
      return OPERAND_REAL_TYPE.NUMBER;
    default:
      return undefined;
  }
};

export const getTypeFromColumnType = (columnType: string) => {
  switch (columnType) {
    case "integer":
    case "float":
    case "decimal": {
      return OPERAND_REAL_TYPE.NUMBER;
    }
    case "string": {
      return OPERAND_REAL_TYPE.STRING;
    }
    case "boolean": {
      return OPERAND_REAL_TYPE.BOOLEAN;
    }
  }
};

export const prepareChipTitle = (
  conditionOrOperation: IOperation | ICondition,
  conditions: any[]
): string => {
  const labelForFirst = prepareOperandeTitle(
    conditionOrOperation.first,
    conditions
  );
  const labelForSecond = prepareOperandeTitle(
    conditionOrOperation.second,
    conditions
  );
  const labelForOperator = `${conditionOrOperation.operator || ""}`;
  return `${labelForFirst} ${labelForOperator} ${labelForSecond}`;
};

export const prepareOperandeTitle = (
  operande: any,
  conditionsOrOperations: any[]
): string => {
  if (!operande.hasOwnProperty("result_id")) {
    return formatOperandForChip(operande, [], undefined, undefined);
  } else {
    const conditionOrOperation = conditionsOrOperations.filter(
      (cond) => cond.id === operande.result_id
    )[0];
    return `(${prepareChipTitle(
      conditionOrOperation,
      conditionsOrOperations
    )})`;
  }
};

export const selectContainingConditions = (conditionsOrOperations: any[]) => {
  const result = [];
  const kids = []; // the ids of all kids conditions will be stored here

  // Warning: we must avoid mutations here
  const conditionsOperationsPerPriority = [...conditionsOrOperations].sort(
    (a, b) => {
      return b.priority - a.priority;
    }
  );
  for (const conditionOrOperation of conditionsOperationsPerPriority) {
    if (conditionOrOperation.first.hasOwnProperty("result_id")) {
      kids.push(conditionOrOperation.first.result_id);
    }
    if (conditionOrOperation.second.hasOwnProperty("result_id")) {
      kids.push(conditionOrOperation.second.result_id);
    }
    if (!kids.includes(conditionOrOperation.id))
      result.push(conditionOrOperation);
  }
  return result;
};

export const isInputMeta = (input?: string) => {
  if (!input) {
    return false;
  }
  const b = input.match(/"\$\{(.*?)\}"/g);
  return b && b.length > 0 ? true : false;
};

export const getValueFromInput = (input: string) => {
  if (isInputMeta(input)) {
    const output = input.replace('"', "").replace('"', "").replace("\n", "");
    return output;
  }
  return input ? input : "";
};

type TExpressionWithGroupId = TExpression & { groupId: number };

export const removeGroupIds = (items: TExpressionWithGroupId[]) => {
  return _.map(items, (item) => _.omit(item, "groupId"));
};

export const getOperandRealType = (
  operand: any,
  questions: IQuestion[],
  lists: IList[],
  matrixTag?: string
): OPERAND_REAL_TYPE | undefined => {
  if (operand.hasOwnProperty("value")) {
    if (["TRUE", "FALSE"].includes(operand.value)) {
      return OPERAND_REAL_TYPE.BOOLEAN;
    } else {
      return isNaN(operand.value)
        ? OPERAND_REAL_TYPE.STRING
        : OPERAND_REAL_TYPE.NUMBER;
    }
  } else if (operand.hasOwnProperty("condition_id")) {
    return OPERAND_REAL_TYPE.BOOLEAN;
  } else if (operand.type) {
    return operand.type as OPERAND_REAL_TYPE;
  } else {
    return getQuestionRealType(
      operand.tag ? operand.tag : matrixTag,
      questions,
      lists,
      operand.attr
    );
  }
};

export const getQuestionRealType = (
  tag: string,
  questions: IQuestion[],
  lists: IList[],
  listAttribute?: string
): OPERAND_REAL_TYPE | undefined => {
  // the operand has a tag
  if (listAttribute) {
    // get the question to know which list is linked to the operand
    const listId = questions.find((q) => q.tag === tag)?.list_id;
    const schema: IListSchema[] =
      lists.find((l) => l.id === listId)?.schema ?? [];
    const attrType = schema?.find(
      (att) => att?.column_tag === listAttribute
    )?.type;
    return getTypeFromAttributeType(attrType as CUSTOM_FIELD_TYPE);
  } else {
    const questionType = questions.find((q) => q.tag === tag)?.type;
    return getTypeFromColumnType(
      questionTypeToColumnTypeForConditionAndOperation(
        questionType as CUSTOM_FIELD_TYPE
      )
    ) as OPERAND_REAL_TYPE;
  }
};
