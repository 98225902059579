import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import TLang from "model/application/Lang";

import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface ICarouselChartProps {
  data: { url: string; label: string }[];
  fullWidth: boolean;
  initialSlide?: any;
  lang?: TLang;
}

export const CarouselChart: React.FunctionComponent<ICarouselChartProps> = ({
  data,
  fullWidth,
}) => {
  const classes = useStyles();

  return (
    <div
      className={fullWidth ? classes.CarouselFullWidth : classes.CarouselChart}
    >
      {data.length ? (
        data.map((picture, index) => {
          return (
            <div className={classes.imageWrapper} key={index}>
              <div className={classes.blocImage}>
                <img
                  src={picture.url}
                  className={classes.image}
                  alt={`pic ${picture.url}`}
                  style={{ width: "100%" }}
                />
                <div className={classes.blocLegend}>
                  {picture.label ? picture.label : "Label"}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>No pictures to display</div>
      )}
    </div>
  );
};

export default CarouselChart;
