/**
 * @notes: AppErrorBoundary error Boundary. Wrap this around your component to granulise the application
 * this prevents the whole React Component Tree from being unmounted when an error occurs in
 * single component. if the wrapped component crashes, the rest of the application runs as
 * expected.
 * Refer to https://reactjs.org/docs/error-boundaries.html
 */

import { Component } from "react";

import { Box } from "@material-ui/core";
import FieldProLogger from "log/FieldProLogger";

import FallbackScreen from "components/Errors/FallbackScreen";
import {
  IErrorBoundaryProps,
  IErrorBoundaryState,
} from "model/application/Container";

/**
 * Application Error Boundary, wrapping the whole application in an Error Boundary
 * Ideally, this should not be the case, as it means the whole application tree is unmounted when an error occurs
 * Thus, every class component should be wrapped in an error boundary
 * */
export default class AppErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: undefined,
      info: undefined,
    };
  }

  /**
   * Catches errors thrown by children
   * @param {Object} error Error thrown
   * @param {Object} info Information of the error Thrown
   * */
  componentDidCatch(error: any, info: any) {
    const { client, user } = this.props;

    const logger = FieldProLogger({ client, user });
    logger.logError(error);
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      // An appropriate error message
      // render a custom fallback UI
      return (
        <Box height="100vh">
          <FallbackScreen />
        </Box>
      );
    }

    // render children if there is no error
    return this.props.children;
  }
}
