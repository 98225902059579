import { createSelector } from "reselect";

import IUser, { IMobileUser, IWebUser } from "model/entities/User";

import { allMobileUsersComposedSelector } from "./selectors/mobileUsersSelector";
import { allWebUsersComposedSelector } from "./selectors/webUsersSelector";

/**
 * Composed selector for all users, both app and web
 */
export const allUsersComposedSelector = createSelector(
  [allMobileUsersComposedSelector, allWebUsersComposedSelector],
  (mobileUsers: IMobileUser[], webUsers: IWebUser[]): IUser[] => {
    return [...mobileUsers, ...webUsers];
  }
);
