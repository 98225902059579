import axios from "axios";
import _ from "lodash";
import { TLogger } from "log/FieldProLogger";

// TODO: use Mapbox official SDK
// https://github.com/mapbox/mapbox-sdk-js/tree/main?tab=readme-ov-file#mapboxmapbox-sdk
// TODO: add UTs
// TODO: type userData, response (found in SDK ?)
const fetchMatchingRoutes = async (
  userData: any,
  logger: TLogger,
  selectedUser: string
) => {
  let formattedDates = _.keys(userData).sort(
    (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
  );

  const NUM_OF_POINTS_LIMIT = 99;
  if (formattedDates.length > NUM_OF_POINTS_LIMIT) {
    formattedDates = formattedDates.slice(0, NUM_OF_POINTS_LIMIT - 1);
    logger.logError(`
    Route for user ${selectedUser} has more than ${NUM_OF_POINTS_LIMIT} data points. Data is cut to adhere with Mapbox requirements.`);
  }

  const coords = _.map(formattedDates, (dateStr: string) => {
    return `${userData[dateStr].lng},${userData[dateStr].lat}`;
  });

  if (coords.length < 2) {
    return;
  }

  const coordsPath = coords.join(";");
  const radiuses = _.map(coords, () => 25).join(";");
  const params = `radiuses=${radiuses}&steps=true&geometries=geojson&overview=full&access_token=${process.env.REACT_APP_MAPBOX_API}`;

  let response;
  try {
    // https://docs.mapbox.com/api/navigation/map-matching/
    response = await axios.get(
      `https://api.mapbox.com/matching/v5/mapbox/driving/${coordsPath}?${params}`
    );
  } catch (e) {
    const message = `Error while matching route with Mapbox: ${e}`;
    logger.logError(e, {
      message,
      data: {
        mobile_user_id: selectedUser,
        url: `https://api.mapbox.com/matching/v5/mapbox/driving/${coordsPath}?${params}`,
      },
    });
    return;
  }

  const { code, tracepoints, matchings } = response?.data || {};

  if (response?.status !== 200 || code !== "Ok") {
    return;
  }

  const newFormattedData = {};
  let matchingCoords: any = [];
  let indexMatching = 0;

  _.each(tracepoints, (tracepoint: any, i: number) => {
    if (!tracepoint) {
      newFormattedData[formattedDates[i]] = {
        ...userData[formattedDates[i]],
      };

      const coords = [
        newFormattedData[formattedDates[i]].lng,
        newFormattedData[formattedDates[i]].lat,
      ];
      matchingCoords.push(coords);
      return;
    }

    newFormattedData[formattedDates[i]] = {
      timestamp: formattedDates[i],
      lng: tracepoint.location[0],
      lat: tracepoint.location[1],
    };

    if (tracepoint.matchings_index === indexMatching) {
      const newCoords = matchings[indexMatching].geometry.coordinates;
      matchingCoords = matchingCoords.concat(newCoords);
      indexMatching++;
    }
  });

  return matchingCoords;
};

export default fetchMatchingRoutes;
