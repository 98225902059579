import { Component } from "react";

import FieldProLogger from "log/FieldProLogger";
import { connect } from "react-redux";

import { getSignedInUser } from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import {
  IErrorBoundaryProps,
  IErrorBoundaryState,
} from "model/application/Container";
import IRootState from "redux/store/model";

import FallbackScreen from "./FallbackScreen";

class ScreenErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: {},
      info: {},
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, _info: any) {
    const { client, user } = this.props;
    const logger = FieldProLogger({
      client,
      user,
    });
    logger.logError(error);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <FallbackScreen />;
    }
    return this.props.children;
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    selectedClient: getSelectedClient(state),
    signedInUser: getSignedInUser(state),
  };
};

export default connect(mapStateToProps)(ScreenErrorBoundary);
