import React from "react";

import { Box } from "@material-ui/core";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputMultipleCreate from "components/Input/InputMultipleCreate";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";

import CreateEditMetaLevelModal from "../CreateEditMetaLevelModal";
import {
  convertMetaLevelsInFormFormat,
  getHierarchySynthetis,
} from "./TeamsTab.utils";

export interface ITeamsTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onChangeMetaHierarchyDependencies: any;
  isLoadingClientTeams: boolean;
}

export const TeamsTab: React.FunctionComponent<ITeamsTabProps> = ({
  viewMode = "CREATE",
  meta_hierarchy_dependencies,
  onChangeMetaHierarchyDependencies,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const metaHierarchyDependencies: IMetaHierarchyDependency =
    meta_hierarchy_dependencies ?? {};

  const metaFormatted = convertMetaLevelsInFormFormat(
    metaHierarchyDependencies
  );

  const hierarchySynthesis = getHierarchySynthetis(metaFormatted);

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputMultipleCreate
          viewMode={viewMode}
          editOnly
          CreateEditModal={CreateEditMetaLevelModal}
          name={"metaHierarchy"}
          createModalTitleFunction={() =>
            label.inputLevels.createEditModal.createTitle
          }
          editModalTitleFunction={() =>
            label.inputLevels.createEditModal.editTitle
          }
          langlabel={label.inputLevels}
          additionnalProps={{
            otherElements: metaFormatted,
          }}
          defaultValueForNewElement={{
            active: false,
            index: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
              .map((n) => `${n}`)
              .find((n) =>
                Object.keys(metaHierarchyDependencies).map(
                  (levelN) => levelN.split("level_")[1] !== n
                )
              ),
            is_hierarchy: false,
            level_name: "_",
          }}
          onChange={onChangeMetaHierarchyDependencies}
          defaultValue={metaFormatted}
          chipTitleTemplate={`slot $index$ $level_name$`}
          lang={lang}
        />
      </FormFieldBackground>

      {hierarchySynthesis && <Box padding="8px 0px">{hierarchySynthesis}</Box>}
    </>
  );
};

export default TeamsTab;
