import { IJobScript as IBEScript } from "fieldpro-tools/dist/src/types";

import { IJobScript } from "model/entities/Job";
import { clone } from "utils/utils";

const prepareScriptsForFrontend = (
  originalScripts: IBEScript[]
): IJobScript[] => {
  let scripts = clone(originalScripts);
  scripts = scripts.map((s: any) => {
    s.response_schema = s.response_schema.map((c: any, idx: number) => ({
      ...c,
      index: idx,
    }));
    return s;
  });
  return scripts;
};

const prepareScriptForBackend = (originalScript: IJobScript): IJobScript => {
  const script = JSON.parse(JSON.stringify(originalScript));
  delete script.indexes;
  delete script.job_type;
  return script;
};

export { prepareScriptForBackend, prepareScriptsForFrontend };
