import React from "react";

import "react-perfect-scrollbar/dist/css/styles.css";

import { withStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";

import { TPivotInfo } from "components/Dashboard/Matrix/MatrixChart";
import CustomMatrix, {
  MatrixData,
} from "components/Matrix/CustomMatrix/CustomMatrix";
import { AGGREGATOR, AGGREGATOR_DIMENSION } from "model/entities/Dashboard";

import styles from "./styles";

interface ICustomCardMatrixProps {
  data: MatrixData;
  rowPivotInfo: TPivotInfo;
  columnPivotInfo: TPivotInfo;
  onMatrixDataLoaded: (data: any[][]) => void;
  aggregationDimension: AGGREGATOR_DIMENSION;
  aggregation: AGGREGATOR;
}

export class CustomCardMatrix extends React.Component<ICustomCardMatrixProps> {
  render() {
    const {
      data,
      rowPivotInfo,
      columnPivotInfo,
      onMatrixDataLoaded,
      aggregation,
      aggregationDimension,
    } = this.props;

    return (
      <PerfectScrollbar>
        <CustomMatrix
          data={data}
          rowPivotInfo={rowPivotInfo}
          columnPivotInfo={columnPivotInfo}
          aggregation={aggregation}
          aggregationDimension={aggregationDimension}
          onMatrixDataLoaded={onMatrixDataLoaded}
        />
      </PerfectScrollbar>
    );
  }
}

export default withStyles(styles as any)(CustomCardMatrix);
