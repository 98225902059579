import {
  black,
  blue,
  darkgray,
  lightblue,
  lightgray,
  lightred,
  teal,
  yellow,
} from "assets/colors";

export const hexToRgb = (hex: string): number[] => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)!
    .map((x) => parseInt(x, 16));
};

export const rgbToHex = (r: number, g: number, b: number) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const interpolateHexColors = (
  color1: string,
  color2: string,
  factor: number
) => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);
  const rgb = interpolateRgbColors(rgb1, rgb2, factor);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
};

export const interpolateRgbColors = (
  color1: number[],
  color2: number[],
  factor: number
) => {
  if (!factor) {
    factor = 0.5;
  }
  const result = color1.slice(0);
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

/**
 * Returns the specified number of colors in array between
 * the two given colors
 * */
export const createPalette = (
  color1: string,
  color2: string,
  steps: number
) => {
  const stepFactor = 1 / steps,
    interpolatedColorArray = [];
  const color1Rgb = hexToRgb(color1);
  const color2Rgb = hexToRgb(color2);
  for (let i = 0; i < steps; i++) {
    const interpolatedColor = interpolateRgbColors(
      color1Rgb,
      color2Rgb,
      stepFactor * (i + 1)
    );
    const r = interpolatedColor[0];
    const g = interpolatedColor[1];
    const b = interpolatedColor[2];
    const hex = rgbToHex(r, g, b);
    interpolatedColorArray.push(hex);
  }
  return interpolatedColorArray;
};

// Returns an array of hexadecimal optimetriks colors by the given scss variable colors
export const strColorToHex = (clrs: string): string[] => {
  const colors: string[] = clrs ? clrs.split(",") : [black];
  if (colors) {
    if (colors[0] === "all") {
      return [
        black,
        blue,
        darkgray,
        lightgray,
        lightblue,
        lightred,
        teal,
        yellow,
      ];
    }
    return colors.map((color) => {
      color = color.trim();
      if (String(color).startsWith("#")) {
        return color;
      }
      switch (color) {
        case "teal": {
          return teal;
        }
        case "darkgray": {
          return darkgray;
        }
        case "blue": {
          return blue;
        }
        case "lightblue": {
          return lightblue;
        }
        case "yellow": {
          return yellow;
        }
        case "lightred": {
          return lightred;
        }
        case "lightgray": {
          return lightgray;
        }
        case "black": {
          return black;
        }
        default: {
          return black;
        }
      }
    });
  } else return [black];
};

export const linearInterpolate = (hex1: string, hex2: string, coef: number) => {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  const r = parseInt(`${rgb2[0] - (rgb2[0] - rgb1[0]) * coef}`).toString(16);
  const g = parseInt(`${rgb2[1] - (rgb2[1] - rgb1[1]) * coef}`).toString(16);
  const b = parseInt(`${rgb2[2] - (rgb2[2] - rgb1[2]) * coef}`).toString(16);

  const result = "#" + r + g + b;
  return result;
};

export default { createPalette, strColorToHex, interpolateHexColors };
