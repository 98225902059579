/**
 * @notes: Endpoints, update the API version here, along with the DOMAIN that will be used
 * This will be used all across the application in several containers and actions.
 * This creates a central place for updates that will be globally affected.
 * Note that the process.env.REACT_APP_ENV variable is set when the application is started.
 * 1. production environment variable is set when a build is run using yarn build (or npm run build), after which
 *    the build can be run using yarn serve (or npm run serve)
 *
 * 2. staging environment variable is set using the yarn start:staging script (or npm run start:staging)
 *
 * 3. development environment variable is set using yarn start:dev script (or npm run start:dev).
 *    This will target using a mockApi
 *
 * 4. for Stroybook builds and deployments, we must set STORYBOOK_APP_ENV and STORYBOOK_APP_MAJOR_VERSION (see below)
 */

const PROD_LATEST_VERSION = "v3-0";

// NOTE: Storybook removes all env vars not starting with STORYBOOK_
// see https://github.com/storybookjs/storybook/issues/12270 for ideas and workarounds
const APP_ENV = process.env.REACT_APP_ENV || process.env.STORYBOOK_APP_ENV;
const APP_MAJOR_VERSION =
  process.env.REACT_APP_MAJOR_VERSION ||
  process.env.STORYBOOK_APP_MAJOR_VERSION;

export const API_BASE_URL = () => {
  if (!APP_MAJOR_VERSION) {
    alert("Missing env variables REACT_APP_MAJOR_VERSION");
    return;
  }

  if (!APP_ENV) {
    throw new Error(
      "Missing environment variable REACT_APP_ENV (OR STORYBOOK_APP_ENV)"
    );
  }

  // setup version for the url: add "v3." as prefix if we are in v3. If we are in prod env, write the prod version
  let versionForUrl = process.env.REACT_APP_MAJOR_VERSION === "v3" ? "v3." : "";

  // setup environment for the url. If we are in production, don't specify it.
  let envForUrl;

  switch (APP_ENV) {
    case "local":
      return "http://localhost:8907/web";
    case "preprod":
      return `https://v3.preprod.staging.fieldproapp.com/web`;
    case "develop":
      envForUrl = "develop";
      break;
    case "staging":
      envForUrl = "staging";
      break;
    case "production":
      envForUrl = "";
      versionForUrl = PROD_LATEST_VERSION;
      break;
    case "stagingdirect":
      return `https://v3.direct.staging.fieldproapp.com/web`;
    case "productiondirect":
      return `https://v3.direct.prod.fieldproapp.com/web`;
  }

  return `https://${versionForUrl}${envForUrl}.fieldproapp.com/web`;
};
