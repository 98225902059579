import _ from "lodash";

import { IAction } from "redux/store/model";

import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  IcreateEventsSuccessAction,
  IdeleteEventsSuccessAction,
  IfetchEventsSuccessAction,
  IupdateEventsSuccessAction,
  IUpdateSelectedFieldUserAction,
} from "./types";

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_EVENTS_BEGIN: {
      return {
        ...state,
        isCreatingEvent: true,
      };
    }
    case types.CREATE_EVENTS_FAILURE: {
      return {
        ...state,
        isCreatingEvent: false,
      };
    }
    case types.CREATE_EVENTS_SUCCESS: {
      const events = (action as IcreateEventsSuccessAction).events;
      return {
        ...state,
        isCreatingEvent: false,
        events: [...events, ...state.events],
      };
    }

    case types.FETCH_EVENTS_BEGIN: {
      return {
        ...state,
        isFetchingEvent: true,
        lastFetched: new Date(),
      };
    }
    case types.FETCH_EVENTS_FAILURE: {
      return {
        ...state,
        isFetchingEvent: false,
      };
    }
    case types.FETCH_EVENTS_SUCCESS: {
      const events = (action as IfetchEventsSuccessAction).events;
      return {
        ...state,
        isFetchingEvent: false,
        events,
      };
    }
    case types.UPDATE_EVENTS_BEGIN: {
      return {
        ...state,
        isUpdatingEvent: true,
      };
    }
    case types.UPDATE_EVENTS_SUCCESS: {
      const { events: updatedEvents, deleted_ids } =
        action as IupdateEventsSuccessAction;
      const newEvents = _.concat(
        _.filter(state.events, (event) => {
          return !_.find(updatedEvents, { id: event.id });
        }),
        updatedEvents
      );
      return {
        ...state,
        isUpdatingEvent: false,
        events: _.filter(_.uniqBy(newEvents, "id"), (event) => {
          return !_.includes(deleted_ids || [], event.id);
        }),
      };
    }
    case types.UPDATE_EVENTS_FAILURE: {
      return {
        ...state,
        isUpdatingEvent: false,
      };
    }

    case types.DELETE_EVENTS_BEGIN: {
      return {
        ...state,
        isDeletingEvent: true,
      };
    }
    case types.DELETE_EVENTS_SUCCESS: {
      const ids = (action as IdeleteEventsSuccessAction).ids;
      return {
        ...state,
        isDeletingEvent: false,
        events: _.filter(state.events, (event) => {
          return !_.includes(ids, event.id);
        }),
      };
    }
    case types.DELETE_EVENTS_FAILURE: {
      return {
        ...state,
        isDeletingEvent: false,
      };
    }
    case types.UPDATE_SELECTED_FIELD_USER: {
      return {
        ...state,
        selectedFieldUsers: (action as IUpdateSelectedFieldUserAction)
          .selectedFieldUsers,
      };
    }

    default:
      return state;
  }
}
