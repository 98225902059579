import { makeStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import { INote } from "fieldpro-tools";

import NoteCard from "./NoteCard";

interface ICustomerNotes {
  notes: INote[];
}
const useStyles = makeStyles((_) => ({
  notes: {
    overflowY: "auto",
    justifyContent: "center",
  },
}));
const CustomerNotes = ({ notes }: ICustomerNotes) => {
  const classes = useStyles();

  return (
    <div className={classes.notes}>
      <Timeline align="left">
        {notes.map((note, index) => (
          <NoteCard
            key={note.id}
            isLast={notes.length - 1 === index}
            note={note}
          />
        ))}
      </Timeline>
    </div>
  );
};
export default CustomerNotes;
