import { FormEvent,useState } from "react";

import { Box, Button, FormControl } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { useTranslations } from "hooks/useTranslations";
import { TInputAttributeLang } from "model/application/Lang";
import { NUMBER_FILTER } from "model/entities/Client";

import { IFilter } from "../../../../model/application/Filter";
import FilterValidateButton from "./FilterValidateButton";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface INumberFilter {
  filter: IFilter<
    | { number_filter_type?: NUMBER_FILTER; search_value?: number | "_" }
    | undefined
  >;
  onChangeFilter: (name: string, value: any) => void;
}

export const NumberFilter: React.FunctionComponent<INumberFilter> = (
  props: INumberFilter
) => {
  const lang = useTranslations();
  const classes = useStyles();

  const { onChangeFilter, filter } = props;

  const [number_filter_type, setNumberFilterType] = useState<
    NUMBER_FILTER | undefined
  >(filter.value?.number_filter_type || NUMBER_FILTER.IS);

  const [search_value, setSearchValue] = useState<
    number | "_" | undefined | ""
  >(filter.value?.search_value);

  const onClear = () => {
    setNumberFilterType(undefined);
    setSearchValue("");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newValue =
      number_filter_type && _.isFinite(Number(search_value))
        ? {
            number_filter_type: number_filter_type,
            search_value: search_value,
          }
        : null;

    number_filter_type && onChangeFilter(filter.tag, newValue);
  };

  const handleTextChange = (value: number | "_" | "") => {
    setSearchValue(value);
  };

  return (
    <div className={classes.DropdownItem}>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <RadioGroup
            key={`type${number_filter_type}`}
            aria-label="numberFilterRadioGroup"
            name="numberFilter"
            value={number_filter_type}
            onChange={(event) =>
              setNumberFilterType(event.target.value as NUMBER_FILTER)
            }
          >
            {[
              NUMBER_FILTER.IS,
              NUMBER_FILTER.IS_NOT,
              NUMBER_FILTER.GREATER_THAN,
              NUMBER_FILTER.GREATER_THAN_OR_EQUAL,
              NUMBER_FILTER.LESS_THAN,
              NUMBER_FILTER.LESS_THAN_OR_EQUAL,
            ].map((opt) => {
              return (
                <FormControlLabel
                  key={opt}
                  label={lang.genericTerms.operators[opt]}
                  control={<Radio className={classes.RadioCustom} />}
                  value={opt}
                  className={classes.RadioButtonsCustom}
                />
              );
            })}
          </RadioGroup>

          <Box margin="12px 0 24px 0">
            <InputText
              viewMode="INLINE"
              name={"text"}
              onChange={handleTextChange}
              lang={{ title: "" } as TInputAttributeLang}
              defaultValue={search_value}
              type={INPUT_TEXT_TYPE.TEXT}
              // TOFIX: pattern is wrong for decimal filter
              pattern={"[0-9]+|[_]{1}"}
              required={!!number_filter_type}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Button className={classes.ClearSelectionButton} onClick={onClear}>
              {lang.components.filters.clear}
            </Button>
            <FilterValidateButton classes={classes} />
          </Box>
        </FormControl>
      </form>
    </div>
  );
};

export default NumberFilter;
