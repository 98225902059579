import React from "react";

import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";

export const useHooks = (filter: IFilter) => {
  const [checkedOptions, setCheckedOptions] = React.useState<string[]>(
    filter.value
  );
  const [options, setOptions] = React.useState<IOption[]>(filter.options || []);

  const handleCheckboxChange = (opt: string, value: boolean) => {
    //if checkbox is selected
    if (value) {
      setCheckedOptions([opt]);
    } else {
      //remove the unselected filter
      setCheckedOptions(checkedOptions.filter((f: string) => f !== opt));
    }
  };

  //search
  const onChangeSearch = (search: string) => {
    if (search.length > 0) {
      setOptions(
        filter.options!.filter((o) => {
          return o.label.toLowerCase().search(search.toLowerCase()) !== -1;
        })
      );
    } else {
      setOptions(filter.options!);
    }
  };

  return {
    checkedOptions,
    onChangeSearch,
    handleCheckboxChange,
    setCheckedOptions,
    options,
  };
};
