import React from "react";

import { TablePagination } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import MoreVertOutlined from "@material-ui/icons/MoreVertOutlined";
import Skeleton from "@material-ui/lab/Skeleton";

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "transparent",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});
interface ISkeletonLoaderProps {
  rowsNumber?: number;
  columnsNumber?: number;
  showPagination?: boolean;
  showTableSearch?: boolean;
  showCreateButton?: boolean;
}
export const TableSkeletonLoader: React.FunctionComponent<
  ISkeletonLoaderProps
> = ({
  rowsNumber,
  columnsNumber,
  showPagination = true,
  showTableSearch = true,
  showCreateButton = true,
}) => {
  const classes = useStyles();
  return (
    <>
      {showCreateButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Skeleton variant="rect" width={180} height={50} />
        </div>
      )}
      {showTableSearch && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "10px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Skeleton
              variant="rect"
              width={20}
              height={20}
              style={{ marginRight: "10px" }}
            />
          </div>
          <div>
            <Skeleton
              variant="rect"
              width={200}
              height={35}
              style={{ marginRight: "10px" }}
            />
          </div>
          <div>
            <Skeleton
              variant="text"
              width={120}
              style={{ marginRight: "10px" }}
            />
          </div>
          <div>
            <Skeleton
              variant="rect"
              width={20}
              height={20}
              style={{ marginRight: "10px" }}
            >
              <MoreVertOutlined />
            </Skeleton>
          </div>
        </div>
      )}
      <TableContainer
        className={classes.root}
        data-testid="table-container-skeleton"
      >
        <Table>
          <TableBody>
            {Array.from({ length: rowsNumber || 4 }).map((__, index) => (
              <StyledTableRow key={index}>
                {Array.from({ length: columnsNumber || 4 }).map((_, key) => {
                  return key === 0 ? (
                    <StyledTableCell component="td" scope="row" key={key}>
                      <Skeleton />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="right" key={key}>
                      <Skeleton />
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <Skeleton>
          <TablePagination
            component="div"
            count={100}
            page={1}
            onPageChange={(_) => {}}
            rowsPerPage={10}
            onRowsPerPageChange={() => {}}
          />
        </Skeleton>
      )}
    </>
  );
};
export default TableSkeletonLoader;
