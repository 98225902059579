import { combineReducers } from "redux";

import { IAction } from "redux/store/model";

import * as types from "../actionTypes/userActionTypes";
import initialState from "./initialState";
import mobileUsers, { IMobileUsersState } from "./mobileUsersReducer";
import webUsers, { IWebUsersState } from "./webUsersReducer";

export interface IUsersState {
  subCategorySelected: "mobileuser" | "webuser";
  mobileUsers: IMobileUsersState;
  webUsers: IWebUsersState;
}

export function reducer(
  state = initialState.subCategorySelected,
  action: IAction
) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as any;
      return subcategory;
    }
    default:
      return state;
  }
}

export default combineReducers({
  subCategorySelected: reducer,
  webUsers,
  mobileUsers,
});
