/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "api/apiService";
import { formatObjectToKeyValuePairs } from "containers/workflows/utils/";
import { IJobScript, STEP_TYPE } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareScriptForBackend } from "./utils";

export const SCRIPTS_ENDPOINT = "scripts";

// SCRIPTS

export type TCreateScriptFunc = (
  actionName: string,
  script: IJobScript
) => Promise<IServerResponse<ICreateScriptResponse>>;
export interface ICreateScriptResponse {
  id: string;
}

export type TUpdateScriptFunc = (
  actionName: string,
  script: IJobScript
) => Promise<IServerResponse<IUpdateScriptResponse>>;
export interface IUpdateScriptResponse {}

export type TDeleteScriptFunc = (
  actionName: string,
  script_id: string
) => Promise<IServerResponse<IDeleteScriptResponse>>;
export interface IDeleteScriptResponse {}

export type TFetchScriptsFunc = () => Promise<
  IServerResponse<IFetchScriptsResponse>
>;
export interface IFetchScriptsResponse {
  scripts: IJobScript[];
}

export type TUseScriptFunc = (
  id: string,
  table?: string,
  zip?: any
) => Promise<IServerResponse<IUseScriptResponse>>;
export interface IUseScriptResponse {}

export type TRunScriptFunc = (
  actionName: string,
  script: IJobScript
) => Promise<IServerResponse<IRunScriptResponse>>;
export interface IRunScriptResponse {
  success: boolean;
}

/**
 * Api call to fetch all
 */
export const fetchScriptsApiCall: TFetchScriptsFunc = () => {
  return apiService.get(`${SCRIPTS_ENDPOINT}`);
};

/**
 * Creates a new script
 * @param {Object} script script details
 */
export const createScriptApiCall: TCreateScriptFunc = (actionName, script) => {
  const payload = prepareScriptForBackend(script);
  return apiService.post(`${SCRIPTS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted script
 * @param {Object} script Detail of the new script
 */
export const updateScriptApiCall: TUpdateScriptFunc = (actionName, script) => {
  const payload = prepareScriptForBackend(script);
  return apiService.patch(
    `${SCRIPTS_ENDPOINT}/${script.id}`,
    payload,
    actionName
  );
};

/**
 * Delete script API call makes a POST request to delete a single script
 * @param {String} script Id of the script to delete
 * @return {Promise}
 * */
export const deleteScriptApiCall: TDeleteScriptFunc = (
  actionName,
  script_id
) => {
  return apiService.delete(`${SCRIPTS_ENDPOINT}/${script_id}`, {}, actionName);
};

/**
 * Use script API call makes a POST request to use a script
 * @param {String} id Id of the script to use
 * @param {String} table Name of the origin table
 * @param {String} zip Information of the zip
 * @return {Promise}
 * */
export const runScriptApiCall: TUseScriptFunc = (id, table, zip) => {
  if (table) {
    return apiService.post(`${SCRIPTS_ENDPOINT}/${id}/use`, {
      source_table: table,
    });
  } else {
    return apiService.multipartUpdate(`${SCRIPTS_ENDPOINT}/${id}/use`, [
      { file: zip.file, fileName: zip.fileName },
    ]);
  }
};
/**
 * Creates a new script
 * @param {Object} script Script details
 */
export const runScriptJobApiCall: TRunScriptFunc = (actionName, script) => {
  return apiService.post(
    `/step`,
    {
      job_type: STEP_TYPE.SCRIPT,
      job_schema_id: script.id,
      parameters: formatObjectToKeyValuePairs(script),
    },
    actionName
  );
};
