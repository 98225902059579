import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import Moment from "moment";

export const computeNewEventIndex = <
  T extends { index?: number; start_time?: Date }
>(
  events: TCalendarEvent[],
  newEvent: T,
  moment: typeof Moment
) => {
  const event = _.clone(newEvent);
  const eventsOfSameDay = events.filter((e) =>
    moment(e.start_time).isSame(moment(event.start_time), "day")
  );
  event.index = eventsOfSameDay.length + 1;
  return event;
};
