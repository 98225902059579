import React from "react";

import {
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";

import { primaryColor } from "assets/colors";

const useStyles = makeStyles({
  linearBox: {
    display: "flex",
    alignItems: "center",
  },
});

const BorderLinearProgress = withStyles(() =>
  createStyles({
    colorPrimary: {
      height: "2px",
      borderRadius: "4px",
      backgroundColor: "#F7F9FA",
    },
    bar: {
      height: "2px",
      borderRadius: "4px",
      backgroundColor: primaryColor,
    },
  })
)(LinearProgress);

export interface ISmallProgressBarProps {
  max: number;
  currentValue: number; //step completed only in the step completed case
}

const StepProgressBar: React.FunctionComponent<ISmallProgressBarProps> = ({
  max,
  currentValue,
}) => {
  const classes = useStyles();
  const normalise = (value: number) => ((value - 0) * 100) / (max - 0);
  return (
    <Box className={classes.linearBox}>
      <Box width="100%" mr={1}>
        <BorderLinearProgress
          variant="determinate"
          value={normalise(currentValue)}
        />
      </Box>

      <Typography variant="body2" color="textSecondary">
        {`${currentValue}/${max}`}
      </Typography>
    </Box>
  );
};

export default StepProgressBar;
