import React from "react";

import { CircularProgress, makeStyles, Typography } from "@material-ui/core";

import styles from "./styles";

interface ICircularLoaderComponentProps {
  small?: boolean;
  inRow?: boolean;
  color?: "primary" | "secondary" | "inherit";
  darkMode?: boolean;
  title?: string;
}

const useStyles = makeStyles(styles as any);

export const CircularLoaderComponent: React.FunctionComponent<
  ICircularLoaderComponentProps
> = ({
  small = false,
  inRow = false,
  darkMode = false,
  color,
  title = "Loading...",
}) => {
  const classes = useStyles();

  return (
    <div className={inRow ? classes.LoadingContainerInRow : ""}>
      {!small && (
        <Typography
          className={
            darkMode ? classes.LoadingTextDarkMode : classes.LoadingText
          }
        >
          {title}
        </Typography>
      )}
      <CircularProgress
        classes={{ root: classes.CircularProgressRoot }}
        color={color ? color : "primary"}
      />
    </div>
  );
};

export default CircularLoaderComponent;
