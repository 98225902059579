import _ from "lodash";

import { IMobileUser } from "model/entities/User";

import { TCalendarFilterObject } from "../types";
import { CALENDAR_FILTERS } from "./prepareFilters";

export function getCurrentFieldUser(
  mobileUser: IMobileUser | undefined,
  isManager: boolean,
  filterQuery: TCalendarFilterObject
) {
  if (isManager) {
    return {
      currentFieldUser: _.first(_.get(filterQuery, CALENDAR_FILTERS.USERS)),
      allSelectedUsers: _.get(filterQuery, CALENDAR_FILTERS.USERS) || [],
    };
  }
  return {
    currentFieldUser: mobileUser?.id,
    allSelectedUsers: _.compact([mobileUser?.id]),
  };
}
