// Component tooltip style

export default () => ({
  Tooltip: {
    fontSize: 16,
    whiteSpace: "break-spaces",
  },
  TooltipButton: {
    position: "relative",
    float: "right",
    transform: "scale(0.5)",
    marginTop: "-50px",
    height: "50px",
    width: "50px",
    visibility: "hidden",
  },
  TooltipButtonCell: {
    position: "relative",
    float: "right",
    transform: "scale(0.5)",
    marginTop: "-50px",
    top: "14px",
    height: "50px",
    width: "50px",
    visibility: "hidden",
  },
  TooltipButtonIcon: {
    transform: "scale(2)",
    visibility: "visible",
  },
  TooltipButtonIconWithError: {
    transform: "scale(2)",
    visibility: "visible",
    color: "red",
  },
  TooltipButtonIconWithWarning: {
    transform: "scale(2)",
    visibility: "visible",
    color: "#FFC364",
  },
  Content: {
    paddingRight: "50px",
  },
  ErrorMessage: {
    color: "#FF8888",
    fontWeight: "bold",
  },

  WarningMessage: {
    color: "#FFC364",
    fontWeight: "italic",
  },
});
