import { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import CustomCardMatrix from "components/Card/CustomCardMatrix";
import InputSelect from "components/Input/InputSelect/InputSelect";
import { MatrixData } from "components/Matrix/CustomMatrix/CustomMatrix";
import { aggregateData, fillData } from "components/Matrix/CustomMatrix/utils";
import CircularLoaderComponent from "components/Progress/CustomCircularProgress";
import { IOption } from "model/application/components";
import TLang, { TInputAttributeLang } from "model/application/Lang";
import {
  AGGREGATOR,
  AGGREGATOR_DIMENSION,
  IKPI,
  PIVOT_TYPE,
} from "model/entities/Dashboard";
import { clone } from "utils/utils";

import styles from "../styles";
import { initPivotInfo, reorderPivotOptions } from "./utils";

interface IMatrixChartProps {
  classes: any;
  data: {
    data: MatrixData;
  };
  onMatrixDataLoaded: (data: any[][]) => void;
  chart: IKPI;
  lang: TLang;
}

export type TPivotOption = {
  [attrname: string]: string;
  value: string;
  label: string;
};

export type TPivotInfo = {
  type: PIVOT_TYPE;
  levelSelected: string;
  options: TPivotOption[];
  levelOptions: IOption[];
};

interface IMatrixChartState {
  rowPivotInfo?: TPivotInfo;
  columnPivotInfo?: TPivotInfo;
}

export class MatrixChart extends Component<
  IMatrixChartProps,
  IMatrixChartState
> {
  constructor(props: IMatrixChartProps) {
    super(props);

    const singleQuery =
      props.chart.row_pivot_type === PIVOT_TYPE.NONE &&
      props.chart.column_pivot_type === PIVOT_TYPE.NONE;
    const rowPivotInfo = initPivotInfo(props.chart, "row", singleQuery);
    const columnPivotInfo = initPivotInfo(props.chart, "column", singleQuery);

    this.state = {
      rowPivotInfo: {
        ...rowPivotInfo,
        options: reorderPivotOptions(rowPivotInfo),
      },
      columnPivotInfo: {
        ...columnPivotInfo,
        options: reorderPivotOptions(columnPivotInfo),
      },
    };
  }

  handleChangeLevel = (newValue: string, variableName: string) => {
    if (variableName === "row") {
      this.setState({
        rowPivotInfo: {
          ...this.state.rowPivotInfo!,
          levelSelected: newValue,
        },
      });
    } else {
      this.setState({
        columnPivotInfo: {
          ...this.state.columnPivotInfo!,
          levelSelected: newValue,
        },
      });
    }
  };

  render() {
    const {
      data,
      classes,
      chart: { aggregator, aggregator_dimension },
      onMatrixDataLoaded,
    } = this.props;
    const { columnPivotInfo, rowPivotInfo } = this.state;
    if (!data) {
      return (
        <div className={classes.progress}>
          <CircularLoaderComponent />
        </div>
      );
    } else if (
      !columnPivotInfo ||
      !rowPivotInfo ||
      (data.data &&
        (data.data.length === 0 ||
          !(data.data as any[]).some((d: any) => d.data.length > 0)))
    ) {
      return "No data to display";
    }
    const rowLevels = rowPivotInfo.levelOptions;
    const rowLevel = rowPivotInfo.levelSelected;
    const columnLevels = columnPivotInfo.levelOptions;
    const columnLevel = columnPivotInfo.levelSelected;
    return (
      <>
        {rowLevels.length < 2 ? null : (
          <InputSelect
            viewMode={"EDIT"}
            lang={{ title: "" } as TInputAttributeLang}
            name={"row"}
            value={rowLevel}
            options={rowLevels}
            smallDisplay={true}
            onChange={this.handleChangeLevel}
          />
        )}
        {columnLevels.length < 2 ? null : (
          <InputSelect
            viewMode={"EDIT"}
            lang={{ title: "" } as TInputAttributeLang}
            name={"column"}
            smallDisplay={true}
            value={columnLevel}
            options={columnLevels}
            onChange={this.handleChangeLevel}
          />
        )}
        <CustomCardMatrix
          data={aggregateData(
            fillData(clone(data.data)),
            {
              ...rowPivotInfo,
              levelSelected: "value",
            },
            {
              ...columnPivotInfo,
              levelSelected: "value",
            },
            aggregator ? aggregator : AGGREGATOR.SUM
          )}
          rowPivotInfo={rowPivotInfo}
          columnPivotInfo={columnPivotInfo}
          onMatrixDataLoaded={onMatrixDataLoaded}
          aggregation={aggregator ? aggregator : AGGREGATOR.SUM}
          aggregationDimension={
            aggregator_dimension
              ? aggregator_dimension
              : AGGREGATOR_DIMENSION.COLUMN
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(MatrixChart);
