import _ from "lodash";

export function canParseJSON(jsonString: string) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

const keyValueObject = (obj: object) => {
  return _.hasIn(obj, "key") && _.hasIn(obj, "value");
};

type TStringNumber = number | string;
const mapStringArrayToObject = (value: TStringNumber[]) => {
  const object = {};
  _.map(value, (val: TStringNumber, index: number) => {
    object[`message ${index + 1}`] = _.toString(val);
  });
  return [object];
};

const transformValue = (value: any) => {
  if (_.isString(value) || _.isNumber(value)) return value;
  if (_.isArray(value)) return JSON.stringify(value);
  if (_.isObject(value)) return JSON.stringify(value);
  return value;
};

const transformObject = (value: object) => {
  const object = {};
  const entries = Object.entries(value);
  _.map(entries, (entry) => {
    object[entry[0]] = transformValue(entry[1]);
  });
  return object;
};

export const getArrayFormatted = (value: any[]): object[] => {
  if (_.every(value, _.isString) || _.every(value, _.isNumber)) {
    return mapStringArrayToObject(value);
  }

  if (_.every(value, _.isObject)) {
    return _.map(value, (val, index) => {
      if (_.isArray(val)) {
        const result = {};
        result[`info ${index}`] = JSON.stringify(val);
        return result;
      }

      if (keyValueObject(val)) {
        const { key, value, ...rest } = val;
        const result = {};
        result[key] = transformValue(value);
        return { ...result, ...transformObject(rest) };
      }

      return transformObject(val);
    }) as object[];
  }
  return value;
};
