import { useEffect, useRef, useState } from "react";

import useContainerDimensions from "./useContainerDimensions";

interface IUseResizeHandle {
  onMouseDown?: (...args: any) => void;
  minWidth?: number;
}

const useResizeHandle = ({ onMouseDown, minWidth = 0 }: IUseResizeHandle) => {
  const ref = useRef();
  const { width: measuredWidth } = useContainerDimensions(ref);
  const [width, setWidth] = useState(Math.max(measuredWidth || minWidth));

  const initalWidthWasSetRef = useRef(false);

  useEffect(() => {
    if (measuredWidth && !initalWidthWasSetRef.current) {
      setWidth(measuredWidth);
      initalWidthWasSetRef.current = true;
    }
  }, [measuredWidth]);

  const onResizeHandleMouseDown = (mouseDownEvent: any) => {
    if (onMouseDown) {
      onMouseDown();
    }

    const startWidth = width; // keep initial width during the resizing

    const onMouseMove = (mouseMoveEvent: any) => {
      setWidth(
        Math.max(
          minWidth,
          startWidth + mouseMoveEvent.pageX - mouseDownEvent.pageX
        )
      );
    };

    const onMouseUp = () => {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      // document.body.removeEventListener("mouseup", onMouseUp);
    };

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  return {
    ref,
    width,
    onResizeHandleMouseDown,
  };
};

export default useResizeHandle;
