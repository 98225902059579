import dateFormat from "dateformat";
import _ from "lodash";

import {
  TABLE_AGGREGATOR,
  TABLE_COLUMN_TYPE,
  TColumnType,
} from "components/Table/model";
import { buildSyntheticRow } from "components/Table/utils";
import { IOption } from "model/application/components";
import {
  AXIS_TYPE,
  IKPI,
  IKPIData,
  INivoConfiguration,
  PIVOT_TYPE,
} from "model/entities/Dashboard";
import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";
import { ITeamSelector } from "model/entities/Team";
import IUser from "model/entities/User";
import { isEmptyValue } from "utils/isEmptyValue";
import { aggregateValues, transposeLocalTimeToUTCTime } from "utils/utils";

import { ILegend } from "../Legend/Legend";
import { TPivotOption } from "../Matrix/MatrixChart";

export const NUMBER_SEPARATOR = ",";
export const DATE_DAY_FORMAT = "yyyy-mm-dd";
export const DATE_TIME_FORMAT = "mm/dd - HH:MM";

export abstract class ChartDataUtils {
  public static getTickValues(chart: IKPI, configuration: INivoConfiguration) {
    if (
      !chart.data ||
      chart.data.error ||
      configuration.axeXType !== AXIS_TYPE.TIME
    ) {
      return;
    }
    const chartData = chart.data as any[];
    const data: any[] =
      chartData && ChartDataUtils.is3DChart(chart)
        ? chartData[0].data
        : chartData;
    let ticks: any[] = [];
    let tempTicks = [];
    let i = 1;
    if (data) {
      const startDate = this.getStartDate(chart) || new Date();
      const endDate = this.getEndDate(chart) || new Date();
      const deltaDays = this.getDeltaDays(startDate, endDate);
      let frequency = 1;
      if (deltaDays > 90) {
        frequency = 30;
      } else if (deltaDays > 14 && deltaDays <= 30) {
        frequency = 2;
      } else if (deltaDays > 30 && deltaDays <= 90) {
        frequency = 7;
      }
      ticks = [];
      for (const point of data) {
        tempTicks.push(point.x);
        if (i % frequency === 0) {
          tempTicks = tempTicks.slice(0, 1);
          ticks = ticks.concat(tempTicks);
          tempTicks = [];
        }
        i++;
      }
      ticks = frequency > 1 ? ticks.concat(tempTicks.slice(0, 1)) : tempTicks;
    }
    return ticks;
  }

  public static formatMatrix(
    chart: IKPI,
    params: {
      metaHierarchy?: IMetaHierarchyDependency;
      teams?: ITeamSelector[];
      users?: IUser[];
      startDate?: Date;
      endDate?: Date;
    }
  ) {
    const getWeekFromDay = (d: Date): number => {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      const yearStart: number = new Date(
        Date.UTC(d.getUTCFullYear(), 0, 1)
      ) as unknown as number;
      // Calculate full weeks to nearest Thursday
      const weekNo: number = Math.ceil(
        (((d as unknown as number) - yearStart) / 86400000 + 1) / 7
      );
      // Return week number
      return weekNo;
    };

    const getMonthFromDay = (d: Date): string => {
      let month = "";
      switch (d.getMonth()) {
        case 0:
          month = "January";
          break;
        case 1:
          month = "Febuary";
          break;
        case 2:
          month = "March";
          break;
        case 3:
          month = "April";
          break;
        case 4:
          month = "May";
          break;
        case 5:
          month = "June";
          break;
        case 6:
          month = "July";
          break;
        case 7:
          month = "August";
          break;
        case 8:
          month = "September";
          break;
        case 9:
          month = "October";
          break;
        case 10:
          month = "November";
          break;
        default:
          month = "December";
      }
      return month;
    };
    const { metaHierarchy, teams, users, startDate, endDate } = params;
    const getTeamPossibilities = (): TPivotOption[] => {
      return _.map(teams, (t) => {
        const result = {
          value: t.id,
          alias: t.name,
          label: "team",
        };
        _.keys(metaHierarchy)
          .filter((h) => metaHierarchy?.[h].level_type_number >= 0)
          .sort(
            (a, b) =>
              metaHierarchy?.[b].level_type_number -
              metaHierarchy?.[a].level_type_number
          )
          .forEach((h, idx) => {
            result[`level_${idx}_value`] = t[h];
            result[`level_${idx}_label`] = metaHierarchy?.[h].level_type_name;
          });
        return result;
      });
    };
    const getUserPossibilities = (): TPivotOption[] => {
      return _.map(users, (u) => ({
        value: u.id,
        label: "user",
        alias: `${u.first_name} ${u.last_name}`,
      }));
    };
    const getDatePossibilities = (_t: "row" | "column"): TPivotOption[] => {
      const nbOfDays =
        endDate && startDate
          ? Math.round(
              (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
            )
          : 0;
      if (!chart.data.data || chart.data.data.length === 0)
        return [
          { value: new Date().toISOString(), label: "day", alias: "date" },
        ];
      return _.compact(
        Array(nbOfDays)
          .fill(0)
          .map((_e, idx) => {
            if (!startDate) return undefined;
            const date = new Date(
              startDate.getTime() + 1000 * 60 * 60 * 24 * idx
            );
            return {
              value: new Date(
                transposeLocalTimeToUTCTime(startDate).getTime() +
                  1000 * 60 * 60 * 24 * idx
              ).toISOString(),
              label: "day",
              alias: date.toDateString(),
              level_0_value: `week ${getWeekFromDay(date)}`,
              level_0_label: "week",
              level_1_value: getMonthFromDay(date),
              level_1_label: "month",
            };
          })
      );
    };
    ["row", "column"].forEach((t) => {
      const pivotType: PIVOT_TYPE = chart[`${t}_pivot_type`];
      switch (pivotType) {
        case PIVOT_TYPE.TEAM: {
          chart.data[`${t}_possibilities`] = getTeamPossibilities();
          break;
        }
        case PIVOT_TYPE.MOBILE_USER: {
          chart.data[`${t}_possibilities`] = getUserPossibilities();
          break;
        }
        case PIVOT_TYPE.DATE: {
          chart.data[`${t}_possibilities`] = getDatePossibilities(
            t as "row" | "column"
          );
          break;
        }
        case PIVOT_TYPE.NONE: {
          if (
            chart.row_pivot_type === PIVOT_TYPE.NONE &&
            chart.column_pivot_type === PIVOT_TYPE.NONE
          ) {
            // case of single query => do nothing
          } else {
            chart.data.data = chart.data.data.map((d: any) => ({
              ...d,
              [t]: "_none",
            }));
          }
        }
      }
    });
    return chart;
  }

  public static getChartColors = (
    data?: IKPIData[]
  ): { label: string; color: string }[] => {
    return (
      data
        ?.filter((item) => item.color)
        .map((item) => ({
          label: item.label,
          color: item.color as string,
        })) || []
    );
  };

  public static hasColorField = (data: IKPIData[] | undefined) => {
    return _.some(data, (row) => _.has(row, "color"));
  };

  public static getDownloadFormatForTable(tableData: {
    rows: any[];
    columns: any[];
    columnTypes: TColumnType[];
  }): any[][] {
    const { rows, columnTypes } = tableData;
    const syntheticRow = buildSyntheticRow(
      columnTypes,
      rows.map((r: any[]) => {
        const rowInMap = {};
        columnTypes.forEach((t: TColumnType, idx: number) => {
          rowInMap[t.name] = r[idx];
        });
        return rowInMap;
      })
    );
    return [
      columnTypes.map((t: TColumnType) => t.name),
      ...rows,
      Object.values(syntheticRow),
    ];
  }

  public static formatRows(columnTypes: TColumnType[], rows: any[]) {
    let formattedRows = rows;

    _.forEach(columnTypes, (column, index) => {
      switch (column.type) {
        case TABLE_COLUMN_TYPE.PICTURE:
          formattedRows = _.map(formattedRows, (row) => {
            const newRow = [...row];
            newRow[index] = { url: row[index] };
            return newRow;
          });
          break;

        case TABLE_COLUMN_TYPE.NUMBER: {
          formattedRows = _.map(formattedRows, (row) => {
            const newRow = [...row];
            //Ensure a number is always returned, whether the input is a number or a numeric string.
            newRow[index] = _.toNumber(newRow[index]);
            return newRow;
          });
          break;
        }

        default:
          break;
      }
    });

    return formattedRows;
  }
  public static formatTable(
    chart: IKPI
  ): { rows: any[]; columns: any[]; columnTypes: TColumnType[] } | undefined {
    if (!chart || !chart.data) return;
    // do nothing
    const chartData = chart.data as any[];
    const chartClone = JSON.parse(JSON.stringify(chart.data?.[0]));
    const columns = chartClone ? Object.keys(chartClone) : [];
    const rows = chartData.map((e) => columns.map((c) => e[c]));
    const columnTypes = columns.map((c, cidx) => {
      let aggregator: TABLE_AGGREGATOR = chart.aggregator
        ? (chart.aggregator as unknown as TABLE_AGGREGATOR)
        : TABLE_AGGREGATOR.NONE;
      // if the type "Total" or "Average" is detected on the column name, choose the relevant aggregator
      if (
        c.toLowerCase().includes("average") ||
        c.toLowerCase().includes("avg")
      )
        aggregator = TABLE_AGGREGATOR.MEAN;
      if (c.toLowerCase().includes("total")) aggregator = TABLE_AGGREGATOR.SUM;
      const type = ChartDataUtils.getColumnType(chartData, columns, cidx);
      let options: Array<IOption> | undefined = undefined;
      const column = columns[cidx];
      options = _(chartData)
        .map((data) => {
          const value = data[column];
          if (_.isString(value)) {
            return {
              key: value,
              label: value,
            };
          }
        })
        .uniqBy("key")
        .compact()
        .value();

      return {
        name: c,
        type,
        label: c,
        aggregator,
        ...(!_.isEmpty(options) && { options }),
      };
    });

    return {
      rows: ChartDataUtils.formatRows(columnTypes, rows),
      columns,
      columnTypes,
    };
  }

  public static getColumnType = (
    chartData: any[],
    columns: string[],
    cidx: number
  ) => {
    // const urlRegex = /^(http(s)?:\/\/)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

    if (!chartData) return TABLE_COLUMN_TYPE.TEXT;

    const column = columns[cidx];

    //Date type
    const isDateType = _.includes(["Go live date", "Creation Date"], column);
    if (isDateType) return TABLE_COLUMN_TYPE.DATE;

    //Picture && Link type
    if (_.startsWith(column, "picture_")) return TABLE_COLUMN_TYPE.PICTURE;
    if (_.startsWith(column, "link_")) return TABLE_COLUMN_TYPE.LINK;

    //Text type
    const isTextType = _.find(
      chartData,
      (data) =>
        isNaN(data[column]) || data[column] == null || data[column]?.[0] === "+"
    );
    if (isTextType) return TABLE_COLUMN_TYPE.TEXT;

    // Boolean type
    if (_.find(chartData, (data) => _.isBoolean(data[column])))
      return TABLE_COLUMN_TYPE.BOOLEAN;

    // Number type
    const isNumberType =
      _.find(chartData, (data) => _.isNumber(data[column])) ||
      _.every(chartData, (data) => {
        const parsedValue = Number(data[column]);
        return !isNaN(parsedValue);
      });
    if (isNumberType) return TABLE_COLUMN_TYPE.NUMBER;

    return TABLE_COLUMN_TYPE.TEXT;
  };

  public static getStartDate(chart: IKPI): Date | undefined {
    if (!chart || !chart.data || chart.data.error) {
      return;
    }
    const chartData = (!chart.data.error ? chart.data : []) as any[];
    // Find the start date. If all x values are contained between the filters start and end date, take
    // the dates in the filter as min and max. Otherwise, take the min and the max of the x values.
    return chartData.reduce((acc: any, curr: any) => {
      if (curr.data) {
        const v = curr.data.reduce((ac: any, cur: any) => {
          return ac > this.createDateAsUTC(new Date(cur.x))
            ? this.createDateAsUTC(new Date(cur.x))
            : ac;
        }, Infinity);
        return acc > v ? v : acc;
      }
      return acc > this.createDateAsUTC(new Date(curr.x))
        ? this.createDateAsUTC(new Date(curr.x))
        : acc;
    }, Infinity);
  }
  public static createDateAsUTC(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
  public static getEndDate(chart: IKPI): Date | undefined {
    if (!chart || !chart.data) {
      return;
    }
    const chartData = (!chart.data.error ? chart.data : []) as any[];
    // Find the end date. If all x values are contained between the filters start and end date, take
    // the dates in the filter as min and max. Otherwise, take the min and the max of the x values.
    return chartData.reduce((acc: any, curr: any) => {
      if (curr.data) {
        const v = curr.data.reduce(
          (ac: any, cur: any) => (ac < new Date(cur.x) ? new Date(cur.x) : ac),
          -Infinity
        );
        return acc < v ? v : acc;
      }
      return acc < new Date(curr.x) ? new Date(curr.x) : acc;
    }, -Infinity);
  }

  static getDeltaDays(startDate: Date, endDate: Date): number {
    return (
      Math.trunc(
        (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }

  static getAggregatedTimeData(
    chart: IKPI,
    startDate: Date,
    endDate: Date
  ): any {
    if (!chart || !chart.data) return;
    // init the data object with an empty array for each day
    const dataAggregated = {};
    const minXValue = this.getStartDate(chart);
    const maxXValue = this.getEndDate(chart);
    if (minXValue && maxXValue) {
      const lowerBound: Date =
        startDate > minXValue || endDate < maxXValue ? minXValue : startDate;
      const upperBound: Date =
        startDate > minXValue || endDate < maxXValue ? maxXValue : endDate;
      const deltaDays = this.getDeltaDays(lowerBound, upperBound);
      for (let i = 0; i < deltaDays; i++) {
        dataAggregated[
          this.formatDate(
            new Date(new Date(lowerBound).getTime() + 1000 * 60 * 60 * 24 * i),
            undefined,
            false
          )
        ] = [];
      }
    }
    return dataAggregated;
  }

  static countXValues(chart: IKPI): number {
    let data = chart && chart.data ? chart.data : null;
    data =
      data && ChartDataUtils.is3DChart(chart)
        ? ((data[0] as IKPIData).data as any)
        : (data as any);
    return data ? (data as any[]).length : 0;
  }

  static countYValues(chart: IKPI): number {
    const data = chart && chart.data ? chart.data : null;
    return data ? (data as any[]).length : 0;
  }

  static aggregate3DTimeData(chart: any, dataAggregated: any) {
    if (!chart || !chart.data) return;
    // for 3 dimensions charts
    // aggregate all the values according to the aggregator
    return chart.data.map((c: any) => {
      const tempDataAggregated = JSON.parse(JSON.stringify(dataAggregated));
      // populate the dataAggregated array
      c.data.forEach((d: any) => {
        const timeKey = ChartDataUtils.formatValue(
          new Date(d.x),
          DATE_DAY_FORMAT
        );
        if (!tempDataAggregated[timeKey]) {
          // most likely a timezone error. Don't push the value.
        } else {
          tempDataAggregated[timeKey].push(d.y);
        }
      });
      return {
        id: c.label,
        label: c.label,
        color: c.color,
        data: Object.keys(tempDataAggregated).map((date) => ({
          x: date,
          y: aggregateValues(tempDataAggregated[date], chart.aggregator),
        })),
      };
    });
  }

  static aggregate2DTimeData(chart: any, dataAggregated: any) {
    if (!chart || !chart.data) return;
    // for "normal" 2 dimensions charts
    // populate the dataAggregated array
    chart.data.forEach((d: any) => {
      const timeKey = ChartDataUtils.formatValue(
        new Date(d.x),
        DATE_DAY_FORMAT
      );
      if (!dataAggregated[timeKey]) {
        // most likely a timezone error. Don't push the value.
      } else {
        dataAggregated[timeKey].push(d.y);
      }
    });
    // aggregate all the values according to the aggregator
    return Object.keys(dataAggregated).map((date) => ({
      x: date,
      y: aggregateValues(dataAggregated[date], chart.aggregator),
    }));
  }

  static format3DData(chart: any) {
    if (!chart || !chart.data) return;
    // for 3 dimensions charts
    // aggregate all the values according to the aggregator
    const xValues: any = {};
    if (chart && chart.data) {
      chart.data.forEach((d: any) => {
        d.data.map((d2: any) => {
          xValues[d2.x] = 0;
          return xValues;
        });
      });
    }
    return chart.data.map((c: any) => {
      const resetXValues = Object.assign({}, xValues);
      c.data.forEach((d: any) => {
        resetXValues[ChartDataUtils.formatValue(d.x)] = d.y;
      });
      return {
        id: c.label,
        label: c.label,
        color: c.color,
        data: Object.keys(resetXValues).map((key) => ({
          x: key,
          y: resetXValues[key],
        })),
      };
    });
  }

  static aggregateData(chart: any) {
    if (!chart || !chart.data) return;
    // init the data object with an empty array for each day
    const dataAggregated = {};
    chart.data.forEach((d: any) => (dataAggregated[d.x] = []));
    chart.data.forEach((d: any) => dataAggregated[d.x].push(d.y));
    // aggregate all the values according to the aggregator
    chart.data = Object.keys(dataAggregated).map((text) => ({
      x: text,
      y: aggregateValues(dataAggregated[text], chart.aggregator),
    }));
  }

  /*
    For date formats see https://www.npmjs.com/package/dateformat
 */
  public static formatDate(date: Date, format?: string, utc?: boolean): any {
    format = format ? format : DATE_DAY_FORMAT;
    if (isEmptyValue(date) || !this.isValidDate(date)) return "";
    return dateFormat(date, format, utc === undefined ? true : utc);
  }

  public static formatValue(value: any, format?: string): any {
    let formattedValue = value;
    if (ChartDataUtils.isNumeric(value)) {
      if (value[0] === "+") {
        return value;
      }
      formattedValue = Math.round((Number(value) + Number.EPSILON) * 100) / 100;
      return ChartDataUtils.addThousandSeparator(
        formattedValue,
        NUMBER_SEPARATOR
      );
    }
    //const timezoneOffset = (new Date()).getTimezoneOffset();
    //let formattedAsDate = new Date(value.getTime() - timezoneOffset*(1000*60))
    const formattedAsDate = new Date(value);
    if (ChartDataUtils.isValidDate(formattedAsDate)) {
      if (format) {
        formattedValue = ChartDataUtils.formatDate(formattedAsDate, format);
      }
    }
    return formattedValue;
  }

  public static isNumeric(number: any) {
    return !isNaN(parseFloat(number)) && isFinite(number);
  }

  public static isValidDate(date: any) {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  }

  public static is3DChart(chart: IKPI) {
    return (
      chart && chart.data && chart.data[0] && (chart.data as any[])[0].data
    );
  }

  public static addThousandSeparator(x: number, separator: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }

  public static updateLabels(uid: string): ILegend[] {
    // Dirty way to get text label with associated color in generated SVG chart
    // Get the element by id then identify legend elements by its properties size
    // and size property then isolate text and associated color
    const chartContainer = document.getElementById(uid);
    if (!chartContainer) {
      return [];
    }
    let result: any = [];
    const htmlCollection = chartContainer.getElementsByTagName("rect");
    result = Array.from(htmlCollection)
      .map((element: SVGRectElement) => {
        return {
          width: Number(element.getAttribute("width")),
          height: Number(element.getAttribute("height")),
          color: element.getAttribute("fill")
            ? element.getAttribute("fill")
            : "",
          style: element.getAttribute("style"),
          textContent: element.nextElementSibling
            ? element.nextElementSibling.textContent
            : "",
        };
      })
      .filter(
        (elemAttributes: { width: number; height: number; style: any }) =>
          elemAttributes.width === 12 &&
          elemAttributes.height === 12 &&
          String(elemAttributes.style).indexOf("pointer-events: none;") > -1
      )
      .map((legend) => {
        return {
          label: legend.textContent,
          color: legend.color,
        };
      })
      .reverse();
    return result;
  }

  public static generateUid() {
    return Math.random().toString(36).slice(2);
  }
}
