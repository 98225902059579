import { FunctionComponent, useState } from "react";

import {
  BaseTextFieldProps,
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import RemoveRounded from "@material-ui/icons/RemoveRounded";
import _ from "lodash";

import CustomTooltipWrapper from "components/Tooltip/CustomTooltipWrapper";
import { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import { INPUT_TEXT_TYPE } from "../InputText/InputText";
import styles from "../styles";

export interface IInputMultipleTextValue {
  value: string | number;
  timestamp: string;
}

const useStyles = makeStyles(styles as any);

const getDefaultInitValue = (name: string) => [
  {
    index: 0,
    value: undefined,
    error: true,
    name: `${name}_0`,
    timestamp: new Date().toISOString(),
  },
];
export interface IInputMultipleTextPropsBase
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  lang?: TInputAttributeLang;
  required?: boolean;
  label?: string;
  type?:
    | INPUT_TEXT_TYPE.TEXT
    | INPUT_TEXT_TYPE.DECIMAL
    | INPUT_TEXT_TYPE.INTEGER;
  error?: string;
  defaultValue?: IInputMultipleTextValue[];
  onChange: (value: IInputMultipleTextValue[], name: string) => void;
  viewMode?: TViewMode;
  name: string;
  viewStacked?: boolean;
}
export interface IInputMultipleTextProps
  extends IInputMultipleTextPropsBase,
    Omit<IInputBaseLayout, keyof IInputMultipleTextPropsBase> {}
export const InputMultipleText: FunctionComponent<IInputMultipleTextProps> = ({
  lang,
  label,
  defaultValue,
  error,
  onChange,
  type,
  required,
  viewMode,
  name,
  viewStacked = false,
  ...rest
}) => {
  const initValue = !_.isEmpty(defaultValue)
    ? formatValue(defaultValue)
    : getDefaultInitValue(name);

  const [fields, setFields] = useState(initValue);
  const classes = useStyles();
  const handleChange = (event: any) => {
    const field_value = event.target.value;
    const newFields = fields.map((field) => {
      if (field.name === event.target.name) {
        field.value = field_value;
        field.error = field_value ? false : true;
      }
      return field;
    });
    setFields(newFields);
    onChange(
      newFields
        .filter((f) => f.value)
        .map((f) => ({ value: f.value, timestamp: f.timestamp })),
      name
    );
  };

  return (
    <InputBaseLayout
      {...rest}
      label={label}
      viewStacked={viewStacked}
      viewMode={viewMode}
      required={required}
    >
      {viewMode === "EDIT" || viewMode === "CREATE" || viewMode === "INLINE" ? (
        <div className={classes.InputOuterWrapper}>
          <CustomTooltipWrapper
            errorMessage={error}
            title={lang ? lang.tooltip : undefined}
            disableFocusListener={!lang || !lang.tooltip}
            disableHoverListener={!lang || !lang.tooltip}
          >
            <>
              <div
                className={
                  error ? classes.InputWrapperError : classes.InputWrapper
                }
              >
                <div key={fields.length}>
                  {fields.map((_, index) => {
                    return (
                      <Box
                        key={index}
                        width={"100%"}
                        className={classes.MultipleFieldsBox}
                      >
                        <FormControl fullWidth className={classes.margin}>
                          <Input
                            id={`${name}_${index}`}
                            name={`${name}_${index}`}
                            placeholder={"enter value"}
                            // defaultValue={fields[index].value}
                            value={fields[index].value}
                            onChange={handleChange}
                            error={fields[index].error ? true : false}
                            required={true}
                            type={
                              type === INPUT_TEXT_TYPE.TEXT ? "text" : "number"
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="remove input"
                                  style={{
                                    marginLeft: "-77px",
                                  }}
                                  onClick={() => {
                                    const newFields = fields
                                      .filter((f) => f.index !== index)
                                      .map((f, index) => ({
                                        index: index,
                                        value: f.value,
                                        name: `${name}_${index}`,
                                        timestamp: f.timestamp,
                                        error: f.error,
                                      }));
                                    setFields(newFields);
                                  }}
                                >
                                  {<RemoveRounded fontSize="small" />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Box>
                    );
                  })}
                </div>
                <Button
                  color="primary"
                  onClick={() => {
                    const newFields = fields
                      .map((f, index) => ({
                        index: index,
                        value: f.value,
                        name: `${name}_${index}`,
                        timestamp: f.timestamp,
                        error: f.error,
                      }))
                      .concat([
                        {
                          index: fields.length,
                          value: undefined,
                          name: `${name}_${fields.length}`,
                          timestamp: new Date().toISOString(),
                          error: true,
                        },
                      ]);
                    setFields(newFields);
                  }}
                >
                  Add new entry
                </Button>
              </div>
            </>
          </CustomTooltipWrapper>
        </div>
      ) : null}

      {viewMode === "VIEW" ? (
        <div>
          {defaultValue
            ? _.map(defaultValue, function (value) {
                return value.value;
              }).join("  ")
            : null}
        </div>
      ) : null}
    </InputBaseLayout>
  );
};

const formatValue = (value?: IInputMultipleTextValue[]) => {
  return _.map(value, (field: any, index: any) => ({
    index,
    value: field.value,
    error: false,
    name: `${name}_${index}`,
    timestamp: field.timestamp,
  }));
};

export default InputMultipleText;
