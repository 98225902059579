import { FunctionComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";

import { TTableLayout } from "../model";
import styles from "../styles";
import { ITableSorting } from "./CustomTable";
import CustomTableHeaderCheckbox from "./CustomTableHeaderCheckbox";
import CustomTableHeaderItem from "./CustomTableHeaderItem";
interface ICustomTableHeaderProps {
  columnNames: { title: string; name: string; unsortable?: boolean }[];
  hasCheckBox?: boolean;
  hasActions?: boolean;
  order?: "desc" | "asc";
  orderBy?: string;
  handleSorting?: (sorting: ITableSorting) => void;
  onSelectAll?: any;
  tableLayout?: TTableLayout;
  setTableLayout?: (tableLayout: TTableLayout) => void;
  checkBoxStatus?: number;
  classes: any;
  sticky?: boolean;
  scrollValue?: {
    x: number;
    y: number;
  };
}

const CustomTableHeader: FunctionComponent<ICustomTableHeaderProps> = ({
  columnNames,
  handleSorting,
  order = "asc",
  orderBy = "",
  hasCheckBox = false,
  onSelectAll,
  checkBoxStatus,
  classes,
  sticky = false,
  scrollValue,
  tableLayout,
  setTableLayout,
  hasActions,
}) => {
  const createTableHeaderItems = () => {
    const columnHeaders = [];
    if (hasCheckBox) {
      columnHeaders.push(
        <CustomTableHeaderCheckbox
          key="checkbox-header"
          className={classNames(classes.TableHeader, classes.TableHeaderCell)}
          checkBoxStatus={checkBoxStatus}
          onSelectAll={onSelectAll}
        />
      );
    }

    columnHeaders.push(
      columnNames.map((column, index) => {
        return (
          <CustomTableHeaderItem
            key={column.name}
            column={column}
            isSortable={!!handleSorting && !column.unsortable}
            // IMPORTANT: hide the last resize handle, otherwise it make the header overflow
            // (and also it is not handy to do on the edge of the table)
            isResizable={index !== columnNames.length - 1}
            order={order}
            hasCheckBox={hasCheckBox}
            index={index}
            orderBy={orderBy}
            handleSorting={handleSorting}
            scrollValue={scrollValue}
            tableLayout={tableLayout}
            setTableLayout={setTableLayout}
          />
        );
      })
    );

    if (hasActions) {
      columnHeaders.push(
        <th
          className={classes.TableHeaderActionsCell}
          key={columnHeaders.length}
        />
      );
    }

    return columnHeaders;
  };

  return (
    <TableHead
      {...(sticky && { style: { top: 0, position: "sticky", zIndex: 10 } })}
    >
      <TableRow>{createTableHeaderItems()}</TableRow>
    </TableHead>
  );
};

export default withStyles(styles as any)(CustomTableHeader);
