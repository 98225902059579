export enum FILTER_TYPE {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  BOOLEAN = "BOOLEAN",
  HIERARCHY = "HIERARCHY",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE_ON_LIST = "MULTIPLE_CHOICE_ON_LIST",
}

export enum FILTER_TAG {
  // list of predefined tags that have a special behavior in the application
  DATE = "_date", // impossible to remove. Is changed to start_date and end_date at the root of the filter object
  TEAMS = "_teams", // is linked to the hierarchy and labels filter
  LABELS = "_labels", // is linked to the hierarchy and teams filter (not sent to the backend)
  USERS = "_users", //
  WORKFLOWS = "_workflows", // adds question_tag filter
  ACTIVITIES = "_activities",
  QUESTION_TAG = "_question_tag", //
  LISTS = "_lists", // adds attribute filter,
  ATTRIBUTE_TAG = "_attribute_tag", //
  HIERARCHY = "_hierarchy", // is linked to the teams filter (not sent to the backend)
  ACTION_CODE = "_action_code", // adds workflows / lists filters if it applies
  MORE_FILTER = "_more_filter", // displays / hide the secondary filters (not sent to the backend)
  OWNERS = "_owners", //
  ITEMS_DETECTED = "_items_detected",
  ARCHIVED = "_archived",
  MOBILE_ROLES = "mobile_user_roles",
}

export default FILTER_TYPE;
