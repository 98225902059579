import { ITriggerExpression } from "./reducer";

const initialState: ITriggerExpression = {
  triggers: [],
  isloading: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
};

export default initialState;
