import * as lang from "lang";

import { BUSINESS_ERROR } from "../../model/application/Api";

export type TErrorDetails = object;

class CustomBusinessError extends Error {
  status;
  errorStatus;
  name;
  details;
  isBusinessError = true;
  response;

  constructor(
    message: string,
    errorStatus: string,
    name: string,
    details?: TErrorDetails
  ) {
    super(message);
    this.status = 200;
    this.errorStatus = errorStatus;
    this.name = name ? name : "InternalError";
    if (details) this.details = details;
    this.response = {
      data: {
        data: {
          error: {
            message: details,
          },
        },
      },
    };
  }
}

export class PartialFailureBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Partial error: ${message}`,
      BUSINESS_ERROR.PARTIAL_SUCCESS,
      "PartialFailureError",
      details
    );
  }
}

export class ValidationBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Validation: ${message}`,
      BUSINESS_ERROR.VALIDATION_ERROR,
      "ValidationError",
      details
    );
  }
}

export class NotFoundBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Not found error: ${message}`,
      BUSINESS_ERROR.NOT_FOUND,
      "NotFoundError",
      details
    );
  }
}

export class NotAllowedBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Not allowed error: ${message}`,
      BUSINESS_ERROR.NOT_ALLOWED,
      "NotAllowedError",
      details
    );
  }
}

export class AlreadyExistsBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Already exists error: ${message}`,
      BUSINESS_ERROR.ALREADY_EXISTS,
      "AlreadyExistsError",
      details
    );
  }
}

export class RunInBackgroundBusinessError extends CustomBusinessError {
  constructor() {
    super(
      `Run in background`,
      BUSINESS_ERROR.RUN_IN_BACKGROUND,
      "RunInBackgroundError"
    );
  }
}
export class NotUpToDateBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Not up to date error: ${message}`,
      BUSINESS_ERROR.NOT_UP_TO_DATE,
      "NotUpToDateError",
      details
    );
  }
}

export class AuthenticationBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Authentication error: ${message}`,
      BUSINESS_ERROR.AUTHENTICATION,
      "AuthenticationError",
      details
    );
  }
}

export class QuotaExceededBusinessError extends CustomBusinessError {
  constructor(message?: string, details?: TErrorDetails) {
    super(
      message ?? `Quota exceeded. Try later.`,
      BUSINESS_ERROR.QUOTA_EXCEEDED,
      "QuotaExceededError",
      details
    );
  }
}

export class PayloadTooLargeBusinessError extends CustomBusinessError {
  constructor(details?: TErrorDetails) {
    super(
      `Payload too large. Try to restrict your query.`,
      BUSINESS_ERROR.PAYLOAD_TOO_LARGE,
      "PayloadTooLargeBusinessError",
      details
    );
  }
}

export class TimeOutBusinessError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      `Timeout error: ${message}`,
      BUSINESS_ERROR.TIMEOUT,
      "TimeOutBusinessError",
      details
    );
  }
}

export class FreeTrialLimitReachedError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      lang["en"].notifications.errorNotifications.freeTrialReachedError,
      BUSINESS_ERROR.FREE_TRIAL_LIMIT_REACHED,
      "FreeTrialError",
      details
    );
  }
}

export class TimeoutError extends CustomBusinessError {
  constructor(message: string, details?: TErrorDetails) {
    super(
      lang["en"].notifications.errorNotifications.timeoutError,
      BUSINESS_ERROR.TIMEOUT,
      "TimeoutError",
      details
    );
  }
}

// Cannot import from src/__mocks__ inside app code, so we do it the other way around
export class MockAxiosError extends Error {
  constructor(message: string) {
    super(message);
  }
}
