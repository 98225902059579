import { useTheme } from "@nivo/core";

const ChartTick = ({ maxLineLength, ...props }: any) => {
  const theme = useTheme();
  const gStyle = { ...props.animatedProps.opacity }; // animatedProps.opacity is a SpringValue which is "read only"
  return (
    <g transform={`translate(${props.x},${props.y})`} style={gStyle}>
      <text
        alignmentBaseline={props.textBaseline}
        textAnchor={props.textAnchor}
        style={theme.axis.ticks.text}
        transform={`translate(${props.textX},${props.textY})`}
      >
        <TextWithTooltip value={props.value} maxLineLength={maxLineLength} />
      </text>
    </g>
  );
};

/**
 *
 * @param value
 * @param maxLineLength
 * @returns Line with ellipsis ("...") if label is truncated
 */
interface ITextWithTooltip {
  value: string;
  maxLineLength: number;
}
const TextWithTooltip = ({ value, maxLineLength }: ITextWithTooltip) => {
  const text =
    value.length > maxLineLength - 3
      ? value.slice(0, maxLineLength - 3) + "..."
      : value;
  return (
    <tspan cursor="help">
      <tspan>{text}</tspan>
      <title>{value}</title>
    </tspan>
  );
};

export default ChartTick;
