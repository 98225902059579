import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";

export type TLocationInputType = "address" | "coordinates";

interface ILocationInputSwitch {
  locationInputType: TLocationInputType;
  handleChangeAddressInputType: RadioGroupProps["onChange"];
}

const LocationInputSwitch = ({
  locationInputType: locationInputType,
  handleChangeAddressInputType,
}: ILocationInputSwitch) => (
  <FormControl style={{ height: "10px" }}>
    <RadioGroup
      data-testid="form-radio"
      name="radio-buttons-group"
      value={locationInputType}
      onChange={handleChangeAddressInputType}
      row
    >
      <FormControlLabel
        label="Address"
        value="address"
        control={<Radio style={{ color: "black" }} size={"small"} />}
        style={{ marginBottom: "0px", paddingRight: "16px" }}
      />

      <FormControlLabel
        style={{ marginBottom: "0px" }}
        label="Coordinates"
        value="coordinates"
        control={<Radio style={{ color: "black" }} size={"small"} />}
      />
    </RadioGroup>
  </FormControl>
);

export default LocationInputSwitch;
