import { cleanDirtyDate, getDefaultDateTimeFormat } from "utils/dateUtils";

export interface IDateTimeCell {
  date?: string;
}

const DateTimeCell = ({ date: dirtyDate }: IDateTimeCell) => {
  const dateFormatted = cleanDirtyDate(dirtyDate, getDefaultDateTimeFormat);
  if (!dateFormatted) return null;
  return <span>{dateFormatted}</span>;
};

export default DateTimeCell;
