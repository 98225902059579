import { CalendarEventRepetitionType } from "fieldpro-tools";
import _ from "lodash";

import { IOption } from "model/application/components";
import TLang from "model/application/Lang";

export function getDurationOptions(lang: TLang) {
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal
      .inputDuration.options;
  return _.map(_.keys(langKey), (key) => {
    return {
      key,
      label: langKey[key],
    };
  });
}
export function getRecurrenceOptions(
  lang: TLang
): Array<IOption<CalendarEventRepetitionType>> {
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal
      .inputRepetition.options;
  return _.map(_.keys(langKey), (key: any) => {
    return {
      key,
      label: langKey[key],
    };
  });
}
