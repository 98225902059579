import { CalendarEventStatus, IMobileUser } from "fieldpro-tools";
import _ from "lodash";

import TLang from "model/application/Lang";
import getFullName from "utils/formatting/getFullName";

import EventStatusMenuButton from "../CalendarEventsListView/EventStatusMenuButton";
import { TCalendarEventFE } from "../redux/types";
import { TEventsTableColumnData } from "./CreateEventsTableColumns";

interface IGetEventsTableRows {
  events: TCalendarEventFE[];
  mobileUsers: IMobileUser[];
  lang: TLang;
  onChangeStatus?: (
    events: TCalendarEventFE[],
    status: CalendarEventStatus
  ) => Promise<void>;
}
export function getEventsTableRows({
  events,
  mobileUsers,
  onChangeStatus,
  lang,
}: IGetEventsTableRows): Array<TEventsTableColumnData> {
  return _.map(events, (event) => {
    const mobileUser = _.find(mobileUsers, { id: event.assigned_to });

    const data: TEventsTableColumnData = {
      ...event,
      customer_name: event.customer_name ?? lang.genericTerms.none,
      field_user_name: _.get(mobileUser, "name", getFullName(mobileUser)),
      phone_number: mobileUser?.phone,
      status: (
        <EventStatusMenuButton
          event={event}
          key={event.id}
          onChangeStatus={onChangeStatus}
        />
      ),
      statusText: event.status || CalendarEventStatus.PLANNED,
    };

    return data;
  });
}
