import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import CustomDialogCreateEdit from "components/Dialog/CustomDialogCreateEdit";
import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import ChangePasswordModal from "containers/authentication/components/modals/ChangePasswordModal";
import {
  getIsChangingPassword,
  getShouldChangePassword,
} from "containers/authentication/redux/selector";
import { useTranslations } from "hooks";

import { getErrorMessagesForPassword } from "../../Layout/utils/getErrorMessagesForPassword";

interface IChangePasswordDialog {
  open?: boolean;
  onClose: () => void;
  onEdit?: (attributes: any) => void;
}
const ChangePasswordDialog = ({
  open,
  onEdit,
  onClose,
}: IChangePasswordDialog) => {
  const lang = useTranslations();
  const shouldChangePassword = useSelector(getShouldChangePassword);
  const isChanging = useSelector(getIsChangingPassword);
  const [shouldChange, setShouldChange] = useState(shouldChangePassword);

  useEffect(() => {
    setShouldChange(shouldChangePassword);
  }, [shouldChangePassword]);

  return (
    <>
      <CustomDialogCreateEdit
        additionnalProps={{}}
        isEditOpen={open || !!shouldChange}
        CreateEditModal={ChangePasswordModal}
        editModalTitle={lang.mainLayout.changePasswordModal.title}
        createModalTitle={lang.mainLayout.changePasswordModal.title}
        validate={getErrorMessagesForPassword}
        attributes={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onClose={onClose}
        onEditElement={onEdit}
        closeOnSave={true}
        lang={lang}
        hideCloseButton={shouldChange}
      />

      <SnackbarMessage isOtherActionOngoing={isChanging} lang={lang} />
    </>
  );
};

export default ChangePasswordDialog;
