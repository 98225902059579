import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import InputCustomField from "components/Input/InputCustomField/InputCustomField";
import { allListsSelector } from "containers/lists/redux/selectors";
import { ITerritory } from "containers/territories/model";
import { getTerritoriesSchema } from "containers/territories/redux/selectors";
import { IFormState } from "hooks/useFormState";
import { IList, IListSchema } from "model/entities/List";

interface ITerritoryForm {
  formState: IFormState<ITerritory>;
}

// TODO: add test and check duplicate name and shape fields are not displayed here,
// or even better, keep real _name and _shape and update TerritoryFormField with appropriate inputs for those fields
export const TerritoryForm = ({ formState }: ITerritoryForm) => {
  const schema = useSelector(getTerritoriesSchema);
  const lists = useSelector(allListsSelector);
  return (
    <>
      {_.map(schema, (attributeSchema) => {
        const { column_tag } = attributeSchema;
        const list = _.find(lists, { id: attributeSchema.list_id });
        return (
          <InputCustomField
            key={column_tag}
            viewMode="CREATE"
            customField={attributeSchema}
            answer={formatAnswer(
              formState.attributes?.[column_tag],
              attributeSchema,
              list
            )}
            list={list}
            formState={formState}
            onUpdateGeneralState={() => {}}
          />
        );
      })}
    </>
  );
};

// TODO: should be removed with FP-6214
const formatAnswer = (value: any, schema: IListSchema, list?: IList) => {
  switch (schema.type) {
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE: {
      const values = (schema?.options ?? []).filter((opt) =>
        _.includes(value, opt.key)
      );

      return schema.type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE
        ? values
        : values[0];
    }
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      const listItemIds = _.isArray(value) ? value : _.compact([value]);

      const values = _.compact(
        _.map(listItemIds, (_id) => {
          const item = _.find(list?.items, { _id });
          if (!item) {
            return null;
          }
          return { key: _id, label: item._name };
        })
      );

      return schema.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
        ? values[0]
        : values;
    }
    default: {
      return value;
    }
  }
};
