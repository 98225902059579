import { useEffect, useState } from "react";

import { Box, FormGroup, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { formatOptions } from "components/Filter/FilterModals/components/utils";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { useActions, useTranslations } from "hooks";
import { IOption } from "model/application/components";

import InputBaseLayout from "../InputBaseLayout/InputBaseLayout";
import { TickBox } from "../InputMultipleSelect/InputMultipleSelect";
import styles from "../styles";
import {
  IInputMultipleSelectOnListProps,
  MAXIMUM_ENTRIES_FOR_SMALL_LIST,
} from "./InputMultipleSelectOnList";

const useStyles = makeStyles(styles as any);

interface MultipleOptionsOnSmallList
  extends Pick<
    IInputMultipleSelectOnListProps,
    | "listId"
    | "options"
    | "defaultValue"
    | "viewMode"
    | "langlabel"
    | "error"
    | "disabled"
    | "multipleSelection"
    | "onChange"
    | "name"
  > {
  handleRemoveOptions(options: Array<IOption>): IOption<string, any>[];
  handleAddOptions(options: Array<IOption>): IOption<string, any>[] | undefined;
}
const MultipleOptionsOnSmallList: React.FC<MultipleOptionsOnSmallList> = ({
  listId,
  options,
  defaultValue,
  viewMode,
  langlabel,
  error,
  disabled,
  multipleSelection,
  handleRemoveOptions,
  handleAddOptions,
  name,
  onChange,
}) => {
  const [fetched, setFetched] = useState(false);
  const lang = useTranslations();
  const classes = useStyles();
  const fetchItemsForList = useActions(fetchItemsForListAction);
  const [availableOptions, setAvailableOptions] = useState<IOption[]>(
    options ?? []
  );

  const CLEAR_OPTION = {
    key: "__CLEAR",
    label: lang.components.inputSelect.clear,
    tooltip: "",
    disabled: false,
  };

  async function loadListOptions() {
    if (fetched) return;
    const response = await fetchItemsForList(listId, {
      filters: {},
      limit: MAXIMUM_ENTRIES_FOR_SMALL_LIST,
      include_linked_options: true,
      order: "asc",
      order_by: "_displayed_name",
      with_count: true,
    });

    const responseOptions = formatOptions(response?.items);

    const newOptions = _.uniqBy(
      _.concat(responseOptions, availableOptions),
      "key"
    );

    const opts = _.map(defaultValue, (opt) => {
      return _.find(newOptions, {
        key: opt.key,
      });
    });

    onChange && onChange(_.compact(opts), name);

    setAvailableOptions(newOptions);
    setFetched(true);
  }

  useEffect(() => {
    if (_.size(options) < MAXIMUM_ENTRIES_FOR_SMALL_LIST) {
      loadListOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsToDisplay = _.uniqBy(
    _.concat(_.slice(availableOptions, 0, MAXIMUM_ENTRIES_FOR_SMALL_LIST), [
      CLEAR_OPTION,
    ]),
    "key"
  );

  return (
    <InputBaseLayout
      key={JSON.stringify(availableOptions)}
      label={langlabel?.title}
      tooltip={langlabel?.tooltip}
      viewMode={viewMode}
      error={error}
      disabled={disabled}
    >
      <div
        className={
          viewMode === "EDIT"
            ? classes.ChipContainerEditMode
            : classes.ChipContainer
        }
      >
        <Box className={classes.formControlContent}>
          <FormGroup>
            {optionsToDisplay.map((option) => {
              const checked =
                _.find(defaultValue, (selectedOption) => {
                  return _.isEqual(selectedOption.key, option.key);
                }) !== undefined;
              return (
                <TickBox
                  checked={checked}
                  dataTestId={`radio-${option.key}`}
                  tooltip={option.tooltip}
                  disabled={option.disabled}
                  key={option.key}
                  option={option}
                  multipleSelection={multipleSelection}
                  onClick={() => {
                    const result = checked
                      ? handleRemoveOptions([option])
                      : handleAddOptions([option]);
                    onChange && onChange(_.compact(result), name);
                  }}
                />
              );
            })}
          </FormGroup>
        </Box>
      </div>
    </InputBaseLayout>
  );
};

export default MultipleOptionsOnSmallList;
