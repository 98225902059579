import {
  getOverlappingEvent,
  ICalendarEventBase,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import Moment from "moment";
import { DropResult } from "react-beautiful-dnd";

export interface IcomputeNewDraggedEvent {
  dropResult: DropResult;
  events: ICalendarEventBase[];
  moment: typeof Moment;
}
export function computeNewDraggedEvent({
  dropResult,
  events,
  moment,
}: IcomputeNewDraggedEvent): TCalendarEvent[] {
  if (!dropResult.destination) return events;
  const { destination, draggableId } = dropResult;

  const destinationUserId = destination?.droppableId.split("_")[1];
  const draggedUserId = dropResult?.source?.droppableId.split("_")[1];
  if (destinationUserId && draggedUserId && destinationUserId !== draggedUserId)
    return events;

  const draggedEvent = events.find((v) => v.id === draggableId);
  if (!draggedEvent) return events;
  const eventsCopy = _.cloneDeep(events);
  const newIndex = destination.index;
  const eventDuration = moment(draggedEvent.end_time).diff(
    moment(draggedEvent.start_time),
    "minutes"
  );

  //get the array matching the elements in the destination board
  //otherwise the destination index will be incorrect
  const eventsOfSameDay = eventsCopy.filter(
    (v) =>
      moment(v.start_time)
        .startOf("day")
        .isSame(moment(destination.droppableId).startOf("day")) &&
      v.id !== draggedEvent.id
  );
  const newstart_time = moment(
    _.first(destination.droppableId.split("_"))
  ).toDate();
  //if the newstart_time + duration is greater than the end of the day
  //end the event at the end of the day
  const newend_time = moment(newstart_time)
    .add(eventDuration, "minutes")
    .isSameOrAfter(moment(newstart_time).endOf("day"))
    ? moment(newstart_time).endOf("day").toDate()
    : moment(newstart_time).add(eventDuration, "minutes").toDate();
  const newEventObject = {
    ...draggedEvent,
    start_time: newstart_time,
    end_time: newend_time,
  };
  if (
    getOverlappingEvent(
      events.filter(
        (e) =>
          e.id !== newEventObject.id &&
          e.assigned_to === newEventObject.assigned_to
      ),
      newEventObject
    )
  )
    return events;

  eventsOfSameDay.splice(newIndex, 0, newEventObject);

  const newevents = [
    ...events.filter((v) => !_.map(eventsOfSameDay, "id").includes(v.id)),
    ...eventsOfSameDay,
  ];
  return newevents;
}
