import { STEP_STATUS, STEP_TYPE } from "fieldpro-tools";
import _ from "lodash";

import {
  IActivity,
  IWorkflow,
  IWorkflowReportWithStepOverview,
} from "model/entities/Workflow";
import { resolveCondition } from "utils/expressions/conditionSolver";

export const getNextStepId = (
  selectedWorkflowReport: IWorkflowReportWithStepOverview | undefined,
  selectedWorkflow: IWorkflow,
  onlyActivity: boolean = true
): string | undefined => {
  if (!selectedWorkflowReport) {
    // first case: we are starting a workflow report. The first step should be selected.
    return selectedWorkflow?.starting_step_id;
  } else {
    // second case: we are continuing an ongoing workflow report. The next step should be selected.
    const nextStepToExecute = getNextStepsToExecute(
      selectedWorkflow,
      selectedWorkflowReport,
      onlyActivity
    )[0];
    if (nextStepToExecute) {
      return nextStepToExecute;
    } else {
      return undefined;
    }
  }
};

export const getNextStepActivity = (
  selectedWorkflowReport: IWorkflowReportWithStepOverview | undefined,
  selectedWorkflow: IWorkflow,
  activities: IActivity[]
): { activity: IActivity | undefined; stepId: string | undefined } => {
  const stepId = getNextStepId(selectedWorkflowReport, selectedWorkflow);
  if (stepId) {
    const actId = _.find(
      selectedWorkflow.steps || [],
      (s) => s.id === stepId
    )?.schema_id;
    return {
      stepId,
      activity: activities.find((a) => a.id === actId),
    };
  } else {
    return {
      stepId,
      activity: undefined,
    };
  }
};

export const getNextStepsToExecute = (
  workflow: IWorkflow,
  workflowReport: IWorkflowReportWithStepOverview,
  onlyActivity: boolean = true
): string[] => {
  const result: string[] = [];
  if (!workflowReport || !workflowReport.step_overview) return [];
  const allSteps = onlyActivity
    ? workflow.steps.filter((s) => s.type === STEP_TYPE.ACTIVITY)
    : workflow.steps;
  const stepsDoneIds = allSteps
    .filter(
      (s) =>
        workflowReport.step_overview.find((so) => so.step_id === s.id)
          ?.step_status === STEP_STATUS.DONE
    )
    .map((s) => s.id);
  for (const chaining of workflow.chainings) {
    // iterate on all the chaining in the workflow
    const isOriginStepEditable = allSteps.find(
      (s) => s.id === chaining.origin_step
    )?.is_editable;
    if (
      !stepsDoneIds.includes(chaining.destination_step) ||
      isOriginStepEditable
    ) {
      // keep only steps not done yet or steps that are editable
      if (stepsDoneIds.includes(chaining.origin_step)) {
        // keep only steps with the previous step done
        if (allSteps.map((s) => s.id).includes(chaining.destination_step)) {
          // keep only steps that are activities
          if (
            !chaining.conditions ||
            chaining.conditions.length === 0 ||
            resolveCondition(chaining.conditions)
          ) {
            // keep only the chainings with a condition equal to true
            result.push(chaining.destination_step);
          }
        }
      }
    }
  }
  return result;
};
