import { IHttpRequest } from "model/entities/Job";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareHttpRequestsForFrontend } from "./utils";

export interface IHttpRequestState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isRunning: false;
  isFetchingJobHistory: boolean;
  selectedHttpRequest: IHttpRequest | null;
  http_requests: IHttpRequest[];
  errors: any[];
}

/**
 * HttpRequests reducer takes current state and action and returns a new state for the HttpRequest object in redux store
 * @param state initial state of HttpRequests
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_HTTP_REQUEST_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_HTTP_REQUEST_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_HTTP_REQUEST_SUCCESS: {
      const { httpRequest } =
        action as actionCreators.ICreateHttpRequestSuccessAction;

      return {
        ...state,
        isCreating: false,
        http_requests: insertNewItemToArr(state.http_requests, httpRequest),
      };
    }

    case types.UPDATE_HTTP_REQUEST_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_HTTP_REQUEST_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_HTTP_REQUEST_SUCCESS: {
      const { httpRequest } =
        action as actionCreators.IUpdateHttpRequestSuccessAction;

      const http_requests = state.http_requests.map((t) =>
        t.id === httpRequest.id ? httpRequest : t
      );

      return {
        ...state,
        isUpdating: false,
        http_requests,
      };
    }

    case types.DELETE_HTTP_REQUEST_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_HTTP_REQUEST_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_HTTP_REQUEST_SUCCESS: {
      const { id } = action as actionCreators.IDeleteHttpRequestSuccessAction;

      const http_requests = state.http_requests.filter((t) => t.id !== id);

      return {
        ...state,
        isDeleting: false,
        http_requests,
      };
    }

    case types.FETCH_ALL_HTTP_REQUESTS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_HTTP_REQUESTS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_HTTP_REQUESTS_SUCCESS: {
      const { allHttpRequests: beEmailGenerations } =
        action as actionCreators.IFetchHttpRequestsSuccessAction;
      const http_requests = prepareHttpRequestsForFrontend(beEmailGenerations);
      return {
        ...state,
        isFetchingAll: false,
        http_requests,
      };
    }
    case types.RUN_HTTP_REQUEST_JOB_BEGIN:
      return {
        ...state,
        isRunning: true,
      };

    case types.RUN_HTTP_REQUEST_JOB_FAILURE:
      return {
        ...state,
        isRunning: false,
        errors: updateErrorsList(state, action),
      };

    case types.RUN_HTTP_REQUEST_JOB_SUCCESS: {
      return {
        ...state,
        isRunning: false,
      };
    }

    default:
      return state;
  }
}
