import _ from "lodash";

import IRootState from "redux/store/model";

export const getIsFetchingTerritories = (state: IRootState) =>
  state.territories.isFetchingTerritoriesItems;

export const getIsCreatingTerritories = (state: IRootState) =>
  state.territories.isCreatingTerritoriesItems;

export const getIsDeletingTerritories = (state: IRootState) =>
  state.territories.isDeletingTerritoriesItems;

export const getIsUpdatingTerritories = (state: IRootState) =>
  state.territories.isUpdatingTerritoriesItems;

export const getTerritories = (state: IRootState) =>
  state.territories.territories;

export const getTerritoriesCount = (state: IRootState) =>
  state.territories.item_count;

export const getFullTerritoriesSchema = (state: IRootState) =>
  state.territories.territoriesSchema;

// TODO: we are not using the FULL schema for legacy reasons, but this should be refactored one day
export const getTerritoriesSchema = (state: IRootState) =>
  _.reject(state.territories.territoriesSchema, ({ column_tag }) =>
    _.includes(["_name", "_shape"], column_tag)
  );

export const getFetchTerritoriesQuery = (state: IRootState) =>
  state.territories.filterQuery;
