import { IScheduleState } from "./types";

const initialState: IScheduleState = {
  errors: [],
  isCreatingEvent: false,
  isDeletingEvent: false,
  isFetchingEvent: false,
  isUpdatingEvent: false,
  events: [],
  lastFetched: null,
  selectedFieldUsers: null,
};

export default initialState;
