import {
  CalendarEventRepetitionType,
  getOverlappingEvent,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import Moment from "moment";

import { TEventPlaceHolder } from "../Board";

export const REPEATING_EVENT_ID_REGEX = /\w{16}-rr-\d{4}-\d{2}-\d{2}/;
export function isRepeatingEvent(event: TCalendarEvent): boolean {
  return (
    !_.includes(
      [undefined, CalendarEventRepetitionType.NEVER],
      event?.repetition_type
    ) || !_.isUndefined(event.calendar_schema_id)
  );
}

export function setRepeatingEventsDates(
  events: TCalendarEvent[],
  date: string,
  moment: typeof Moment
): TCalendarEvent[] {
  return events.map((event) => {
    return {
      ...event,
      start_time: moment(date)
        .set({
          hour: moment(event.start_time).hour(),
          minute: moment(event.start_time).minute(),
        })
        .toDate(),
      end_time: moment(date)
        .set({
          hour: moment(event.end_time).hour(),
          minute: moment(event.end_time).minute(),
        })
        .toDate(),
    };
  });
}
export function cleanRepeatingEventsThatOverlapWithFixedEvents(
  fixedEvents: TCalendarEvent[],
  repeatingEvents: TCalendarEvent[]
) {
  return repeatingEvents.filter((repeatingEvent) => {
    return _.isEmpty(getOverlappingEvent(fixedEvents, repeatingEvent));
  });
}

//used to compute if a board matches the frequency of a repeating event and should be shown
export function shouldShowBoardItem(
  boardItem: TEventPlaceHolder | IVisitEvent,
  date: string,
  moment: typeof Moment
) {
  const { start_time, repeat_until, repetition_type } = boardItem;
  const isAfterStart = moment(date).isSameOrAfter(
    moment(start_time).startOf("day")
  );

  if (
    repeat_until &&
    moment(date).isAfter(moment(repeat_until).startOf("day"))
  ) {
    return false;
  }
  switch (repetition_type) {
    case CalendarEventRepetitionType.DAILY: {
      return isAfterStart;
    }
    case CalendarEventRepetitionType.WEEKDAYS: {
      const isWeekDay = moment(date).day() <= 5 && moment(date).day() >= 1;
      return isWeekDay && isAfterStart;
    }
    case CalendarEventRepetitionType.WEEKLY: {
      const isSameDay = moment(start_time).day() === moment(date).day();
      return (
        moment(start_time).startOf("day").isSame(moment(date).startOf("day")) ||
        (isSameDay && isAfterStart)
      );
    }
    case CalendarEventRepetitionType.MONTHLY: {
      const preciseDifferenceInMonths = moment(date)
        .startOf("day")
        .diff(moment(start_time).startOf("day"), "months", true);
      return _.isInteger(preciseDifferenceInMonths) && isAfterStart;
    }
    case CalendarEventRepetitionType.YEARLY: {
      const preciseDifferenceInYears = moment(date)
        .startOf("day")
        .diff(moment(start_time).startOf("day"), "years", true);
      return _.isInteger(preciseDifferenceInYears) && isAfterStart;
    }
  }

  return moment(start_time).startOf("day").isSame(moment(date).startOf("day"));
}

export function computeCurrentTimeIndicatorPosition(
  moment: typeof Moment,
  boardStartTime: Date
) {
  const timeSlotHeight = 55;
  return (
    timeSlotHeight * moment().diff(boardStartTime, "hours") +
    moment().minutes() * (timeSlotHeight / 60)
  );
}
