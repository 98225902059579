import { LIST_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { IOption } from "model/application/components";
import TLang, { TInputAttributeLang } from "model/application/Lang";

export const getOwnersLang = (scope: LIST_SCOPE, lang: TLang) => {
  let ownersLang: Partial<TInputAttributeLang> = {
    title: "",
    selectModal: { title: "" },
  };

  const modalLang = lang.containers.lists.subCategories.items.createEditModal;
  switch (scope) {
    case LIST_SCOPE.TEAM: {
      ownersLang = modalLang.inputMultipleTeamOwners;
      break;
    }
    case LIST_SCOPE.SINGLE_TEAM: {
      ownersLang = modalLang.inputTeamOwner;
      break;
    }
    case LIST_SCOPE.SINGLE_MOBILE_USER:
    case LIST_SCOPE.MOBILE_USER: {
      ownersLang = modalLang.inputMobileUserOwner;
      break;
    }
    default: {
      break;
    }
  }
  return ownersLang;
};

export const getDefaultValueForOwners = (
  ownerIds: string[] | undefined,
  ownerOptions: IOption[]
) => {
  if (_.isEmpty(_.compact(ownerIds))) {
    return [];
  }
  return _(ownerIds)
    .filter((id) => !!_.find(ownerOptions, { key: id }))
    .map((id) => {
      const owner = _.find(ownerOptions, { key: id });
      return {
        key: id,
        label: owner?.label ?? "",
      };
    })
    .value();
};
