import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { IChangePasswordModalState } from "containers/authentication/components/modals/ChangePasswordModal";

export const getErrorMessagesForPassword: TValidateFunction<
  IChangePasswordModalState
> = ({ attributes, lang }) => {
  const { oldPassword, newPassword, confirmPassword } = attributes;
  const result: any = {};
  if (!oldPassword || attributes.oldPassword.length === 0)
    result["oldPassword"] = lang.components.inputErrors.empty;
  if (!newPassword) {
    result["newPassword"] = lang.components.inputErrors.empty;
  } else {
    if (
      !new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[1-9]).{8,}$").test(
        attributes.newPassword
      )
    )
      result["newPassword"] = lang.components.inputErrors.notSecuredEnough;
  }
  if (!confirmPassword || attributes.confirmPassword !== attributes.newPassword)
    result["confirmPassword"] = lang.components.inputErrors.empty;
  if (Object.keys(result).length === 0) return {};
  return result;
};
