import _ from "lodash";

import { IDashboard, KPI_TYPE } from "model/entities/Dashboard";
import { IAction } from "redux/store/model";

import { IKPI } from "../../../model/entities/Dashboard";
import {
  insertNewItemToArr,
  updateErrorsList,
} from "../../../utils/reducerUtils";
import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareDashboardsForFrontend } from "./utils";

export interface DashboardsState {
  subCategorySelected: string;
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  isComputingKpi: boolean;
  isDownloadKpi: boolean;
  computedKpiResult:
    | {
        kpiWithData?: IKPI;
        error?: any;
      }
    | null
    | undefined;
  selectedDashboard: IDashboard | null;
  dashboards: IDashboard[];
  frequentlyViewedIds: string[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_DASHBOARD_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_DASHBOARD_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_DASHBOARD_SUCCESS: {
      const { dashboard } =
        action as actionCreators.ICreateDashboardSuccessAction;

      dashboard.query = {};

      return {
        ...state,
        isCreating: false,
        dashboards: insertNewItemToArr(state.dashboards, dashboard),
      };
    }

    case types.UPDATE_DASHBOARD_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_DASHBOARD_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_DASHBOARD_SUCCESS: {
      const { dashboard } =
        action as actionCreators.IUpdateDashboardSuccessAction;

      const dashboards = state.dashboards.map((d) =>
        d.id === dashboard.id ? dashboard : d
      );

      return {
        ...state,
        isUpdating: false,
        dashboards,
      };
    }
    case types.MOVE_ARCHIVED_DASHBOARDS_TO_DEFAULT_FOLDER_SUCCESS: {
      const { folderArchivedDashboardIds } =
        action as actionCreators.IMovearchivedDashboardsToDefaultFolderSuccessAction;

      const dashboards = state.dashboards.map((d) => {
        return _.includes(folderArchivedDashboardIds, d.id)
          ? { ...d, folder: "default" }
          : d;
      });

      return {
        ...state,
        isUpdating: false,
        dashboards,
      };
    }
    case types.DELETE_DASHBOARD_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_DASHBOARD_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_DASHBOARD_SUCCESS: {
      const { id } = action as actionCreators.IDeleteDashboardSuccessAction;

      const dashboards = state.dashboards.filter((d) => d.id !== id);

      return {
        ...state,
        isDeleting: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    case types.ARCHIVE_DASHBOARDS_BEGIN:
      return {
        ...state,
        isArchiving: true,
      };

    case types.ARCHIVE_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isArchiving: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.ARCHIVE_DASHBOARDS_SUCCESS: {
      const { ids } = action as actionCreators.IArchiveDashboardsSuccessAction;

      const dashboards = state.dashboards.map((d) => {
        if (ids.includes(d.id)) {
          d.active = false;
        }
        return d;
      });

      return {
        ...state,
        isArchiving: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    // DOWNLOAD KPI
    case types.DOWNLOAD_DASHBOARD_KPI_BEGIN:
      return {
        ...state,
        isDownloadKpi: true,
      };

    case types.DOWNLOAD_DASHBOARD_KPI_FAILURE:
      return {
        ...state,
        isDownloadKpi: false,
        errors: updateErrorsList(state, action),
      };

    case types.DOWNLOAD_DASHBOARD_KPI_SUCCESS:
      return {
        ...state,
        isDownloadKpi: false,
      };

    case types.RESTORE_DASHBOARDS_BEGIN:
      return {
        ...state,
        isRestoring: true,
      };

    case types.RESTORE_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isRestoring: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.RESTORE_DASHBOARDS_SUCCESS: {
      const { ids } = action as actionCreators.IRestoreDashboardsSuccessAction;

      const dashboards = state.dashboards.map((d) => {
        if (ids.includes(d.id)) {
          d.active = true;
        }
        return d;
      });

      return {
        ...state,
        isRestoring: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    case types.FETCH_ALL_DASHBOARDS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_DASHBOARDS_SUCCESS: {
      let { dashboards: beDashboards } =
        action as actionCreators.IFetchDashboardsSuccessAction;
      beDashboards = beDashboards.filter((d) => (d.type as any) !== "POWERBI");
      const dashboards = prepareDashboardsForFrontend(beDashboards);
      return {
        ...state,
        isFetchingAll: false,
        dashboards,
      };
    }

    case types.COMPUTE_DASHBOARD_BEGIN: {
      return {
        ...state,
        isComputing: true,
      };
    }

    case types.COMPUTE_DASHBOARD_FAILURE: {
      return {
        ...state,
        isComputing: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.COMPUTE_DASHBOARD_SUCCESS: {
      const { kpis, dashboardId, query, raw_data } =
        action as actionCreators.IComputeDashboardSuccessAction;
      const dashboard = state.dashboards.filter((d) => d.id === dashboardId)[0];
      const dashboardsWithData = state.dashboards.map((d) => {
        if (d.id === dashboardId) {
          d.raw_data = raw_data;
          d.query = query;
          const kpisWitData = dashboard.kpis.map((kpi) => {
            const kpiWithData = kpis.filter((k) => k.tag === kpi.tag)[0];
            if (kpiWithData) {
              let data = kpiWithData.data;
              const variationData = kpiWithData.variationData;
              if (kpiWithData.hasOwnProperty("data")) {
                if (Array.isArray(data)) {
                  data = data
                    .filter((d) => d)
                    .map((d) => {
                      if (d.hasOwnProperty("data")) {
                        // if there is a data attribute, check if it is an array. Otherwise, cast it to string,
                        // and change its name to avoid any confusion with the "data" reserved-world for the kpi structure.
                        if (!Array.isArray(d.data)) {
                          d._data = JSON.stringify(d.data);
                          delete d.data;
                        }
                      }
                      return d;
                    });
                }
                return {
                  ...kpi,
                  ...(kpi.type === KPI_TYPE.TABLE && {
                    db_total_rows: kpiWithData.total_rows,
                  }),
                  data,
                  variationData,
                };
              } else {
                return kpi;
              }
            } else {
              return kpi;
            }
          });
          return {
            ...d,
            kpis: kpisWitData,
          };
        } else {
          return d;
        }
      });

      return {
        ...state,
        isComputing: false,
        dashboards: dashboardsWithData,
      };
    }
    case types.COMPUTE_KPI_BEGIN: {
      return {
        ...state,
        isComputingKpi: true,
      };
    }

    case types.COMPUTE_KPI_FAILURE: {
      return {
        ...state,
        isComputingKpi: false,
        computedKpiResult: {
          error: {
            title: (action as any).error.message,
            details: (action as any).error.value,
          },
          kpiWithData: null,
          kpid: (action as any).kpid,
        },
        errors: updateErrorsList(state, action),
      };
    }

    case types.COMPUTE_KPI_SUCCESS: {
      const { raw_data, kpi } =
        action as actionCreators.IComputeKpiSuccessAction;
      return {
        ...state,
        isComputingKpi: false,
        computedKpiResult: {
          kpiWithData:
            raw_data && raw_data.error ? null : { ...kpi, data: raw_data },
          kpid: kpi.tag,
          error: raw_data && raw_data.error ? raw_data.error : null,
        },
      };
    }

    case types.FETCH_FREQUENTLY_VIEWED_DASHBOARDS_BEGIN: {
      return {
        ...state,
        isFetchingFrequentlyViewed: true,
      };
    }

    case types.FETCH_FREQUENTLY_VIEWED_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isFetchingFrequentlyViewed: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_FREQUENTLY_VIEWED_DASHBOARDS_SUCCESS: {
      const { dashboardIds } =
        action as actionCreators.IFetchFrequentlyViewedDashboardsSuccessAction;
      return {
        ...state,
        isFetchingFrequentlyViewed: false,
        frequentlyViewedIds: dashboardIds,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
