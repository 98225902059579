export const FETCH_DOCUMENTS_BEGIN = "FETCH_Documents_BEGIN";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_Documents_SUCCESS";
export const FETCH_DOCUMENTS_FAILURE = "FETCH_Documents_FAILURE";

export const UPLOAD_DOCUMENTS_BEGIN = "UPLOAD_Documents_BEGIN";
export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_Documents_SUCCESS";
export const UPLOAD_DOCUMENTS_FAILURE = "UPLOAD_Documents_FAILURE";

export const DELETE_DOCUMENTS_BEGIN = "DELETE_Documents_BEGIN";
export const DELETE_DOCUMENTS_SUCCESS = "DELETE_Documents_SUCCESS";
export const DELETE_DOCUMENTS_FAILURE = "DELETE_Documents_FAILURE";

export const UPDATE_DOCUMENT_BEGIN = "UPDATE_DOCUMENT_BEGIN";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_Documents_FAILURE";

export const CHANGE_SUBCATEGORY_SELECTED_SUCCESS =
  "CHANGE_WORKFLOW_SUBCATEGORY_SELECTED_SUCCESS";

export const CHANGE_FOLDER_SELECTED_SUCCESS =
  "CHANGE_WORKFLOW_FOLDER_SELECTED_SUCCESS";
