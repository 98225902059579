import { CUSTOM_FIELD_TYPE, IOption as IBEOption } from "fieldpro-tools";
import _ from "lodash";

import {
  getMatrixFieldsSchema,
  getNameKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { TColumnType } from "components/Table/model";
import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import {
  ICustomField,
  IDynamicObjects,
} from "model/application/DynamicObjects";
import TLang from "model/application/Lang";
import { IList } from "model/entities/List";
import { IActivityReportInTable, IQuestion } from "model/entities/Workflow";
import { getFieldByTag } from "utils/formatting/customFieldValues";
import {
  getAugmentedMatrixRows,
  getMatrixCategories,
  getMatrixColumns,
} from "utils/formatting/matrixFields";

import { formatReportMetadata } from "./formatActivityReport";

/**
 * Formats an "augmented" activity report for the table
 */
export const formatAugmentedActivityReport = (
  report: IAugmentedActivityReport,
  isLoading: boolean,
  columnTypes: TColumnType[],
  questions: IQuestion[],
  lists: IList[],
  lang: TLang
): IActivityReportInTable => {
  const formattedMetadata = report && formatReportMetadata(report, isLoading);
  const formattedValues = formatAugmentedCustomFieldValues(
    report.customFieldValues,
    questions,
    lists,
    lang
  );

  const res = {
    ...formattedMetadata,
    ...formattedValues,
  };

  const resKeys = Object.keys(res);
  _.map(columnTypes, ({ name }) => {
    if (!_.includes(resKeys, name)) {
      res[name] = report.metadata?.[name];
    }
  });

  return res;
};

/**
 * IMPORTANT: DO NOT USE list.items /!\ ONLY list.schema allowed here
 */
export const formatAugmentedCustomFieldValues = (
  customFieldValues: ICustomField[],
  questions: IQuestion[],
  lists: IList[],
  lang: TLang
) => {
  const res = {};
  _.forEach(customFieldValues, (customField: ICustomField) => {
    let formattedValue = customField.value;

    switch (customField.type) {
      case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
      case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST: {
        formattedValue = formatBEOption(customField.value as IBEOption);
        break;
      }
      case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
        formattedValue = _(customField.value as IBEOption[])
          .map((option) => {
            return formatBEOption(option);
          })
          .compact()
          .value();
        break;
      }
      case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST: {
        const matrixField = getFieldByTag(questions, customField.key);

        const list = _.find(lists, { id: matrixField?.list_id });
        if (!matrixField || !list) {
          return null;
        }

        const categoryTag = matrixField.category_question_tag;
        const categories = getMatrixCategories({ list, categoryTag }) || [];
        const columns = getMatrixColumns({ question: matrixField, lang });

        // recursive formatting of matrix fields
        const rows = getAugmentedMatrixRows({
          dynamicObjects: customField.value as IDynamicObjects[],
          fieldsSchema: getMatrixFieldsSchema(matrixField) as IQuestion[],
          lists,
          lang,
        });

        formattedValue = {
          title: matrixField[getNameKey(matrixField)],
          categories,
          columns,
          rows,
        };
        break;
      }
    }

    res[customField.key] = formattedValue;
  });
  return res;
};

const formatBEOption = (option?: IBEOption) => {
  if (_.isEmpty(option)) {
    return;
  }
  return {
    key: option.key,
    label: option.value,
  };
};
