import {
  getActionsAvailableSelector,
  getLang,
} from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IJobScript } from "model/entities/Job";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createScriptBeginActionCreator,
  createScriptFailureActionCreator,
  createScriptSuccessActionCreator,
  deleteScriptBeginActionCreator,
  deleteScriptFailureActionCreator,
  deleteScriptSuccessActionCreator,
  fetchAllScriptsBeginActionCreator,
  fetchAllScriptsFailureActionCreator,
  fetchAllScriptsSuccessActionCreator,
  runScriptBeginActionCreator,
  runScriptFailureActionCreator,
  runScriptJobBeginActionCreator,
  runScriptJobFailureActionCreator,
  runScriptJobSuccessActionCreator,
  runScriptSuccessActionCreator,
  updateScriptBeginActionCreator,
  updateScriptFailureActionCreator,
  updateScriptSuccessActionCreator,
} from "./actionCreators";
import {
  createScriptApiCall,
  deleteScriptApiCall,
  fetchScriptsApiCall,
  runScriptApiCall,
  runScriptJobApiCall,
  updateScriptApiCall,
} from "./api";

// SCRIPTS

/**
 * Create script action which creates a script for embedding
 *   destination_table: string;
  columns: array;
  add_data_strategy: string;
  primary_key: string;
  indexes: string;
 * @param {String} id Id of the script
 * @param {String} destination_table Name of the destination table of the script
 * @param {Array} columns Array of columns
 * @param {String} add_data_strategy The strategy of addition of data
 * @param {String} primary_key Primary key of the script
 * @param {String} indexes Indexes of the script
 * @returns {Function}
 */
export const createScriptAction: TCreateScriptActionFunc = (
  actionName: string,
  script: IJobScript
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createScriptBeginActionCreator());

    return createScriptApiCall(actionName, script)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const scriptWithId = {
          ...script,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(createScriptSuccessActionCreator(scriptWithId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.SCRIPT
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateScript",
          error,
          createScriptFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update Script action which creates a script for embedding
 * @param {Object} script script object to edit
 * @returns {Function}
 */
export const updateScriptAction: TUpdateScriptActionFunc = (
  actionName,
  script
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateScriptBeginActionCreator());

    return updateScriptApiCall(actionName, { ...script })
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateScriptSuccessActionCreator(script));

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.SCRIPT,
              script.id
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateScript",
          error,
          updateScriptFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch allScripts
 * @returns {Function}
 */
export const fetchAllScriptsAction: TFetchAllScriptsActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    const availableActions = getActionsAvailableSelector(getState());
    dispatch(ajaxRequestAction());
    dispatch(fetchAllScriptsBeginActionCreator());

    return fetchScriptsApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { scripts } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          fetchAllScriptsSuccessActionCreator(scripts, availableActions)
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchAllScriptsError",
          error,
          fetchAllScriptsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete Script Action dispatches action creators to redux store and makes api calls to delete the script by id
 * @param {String} script_id Script id to delete
 * @param {String} script_name Script name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteScriptAction: TDeleteScriptActionFunc = (
  actionName,
  scriptId,
  scriptName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteScriptBeginActionCreator());

    return deleteScriptApiCall(actionName, scriptId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteScriptSuccessActionCreator(scriptId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.SCRIPT,
              scriptName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteScriptError",
          error,
          deleteScriptFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Use Script Action dispatches action creators to redux store and makes api calls to use the script
 * @param {String} scriptId Script id to use
 * @param {String} table The origin table
 * @param {} zip The zip file to use as origin
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const useScriptAction: TUseScriptActionFunc = (scriptId, table, zip) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runScriptBeginActionCreator());

    return runScriptApiCall(scriptId, table, zip)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(runScriptSuccessActionCreator(scriptId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            "Done!"
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UseScriptError",
          error,
          runScriptFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Run script job action which creates a script job for embedding
 *   destination_table: string;
  columns: array;
  add_data_strategy: string;
  primary_key: string;
  indexes: string;
 * @param {String} id Id of the script
 * @param {String} destination_table Name of the destination table of the script
 * @param {Array} columns Array of columns
 * @param {String} add_data_strategy The strategy of addition of data
 * @param {String} primary_key Primary key of the script
 * @param {String} indexes Indexes of the script
 * @returns {Function}
 */
export const runScriptJobAction: TRunScriptJobActionFunc = (
  actionName: string,
  script: IJobScript
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runScriptJobBeginActionCreator());

    return runScriptJobApiCall(actionName, script)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(runScriptJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.SCRIPT
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RunScriptJob",
          error,
          runScriptJobFailureActionCreator,
          currLang
        );
      });
  };
};

export type TCreateScriptActionFunc = (
  actionName: string,
  script: IJobScript
) => IDispatchAndGetState<void>;
export type TRunScriptJobActionFunc = (
  actionName: string,
  script: IJobScript
) => IDispatchAndGetState<void>;
export type TUpdateScriptActionFunc = (
  actionName: string,
  script: IJobScript
) => IDispatchAndGetState<any>;
export type TFetchAllScriptsActionFunc = () => IDispatchAndGetState<void>;
export type TDeleteScriptActionFunc = (
  actionName: string,
  scriptId: string,
  scriptName: string
) => IDispatchAndGetState<void>;
export type TUseScriptActionFunc = (
  scriptId: string,
  table?: string,
  zip?: any
) => IDispatchAndGetState<void>;

export interface IJobScriptActions {
  createScriptAction: TCreateScriptActionFunc;
  updateScriptAction: TUpdateScriptActionFunc;
  fetchAllScriptsAction: TFetchAllScriptsActionFunc;
  deleteScriptAction: TDeleteScriptActionFunc;
  useScriptAction: TUseScriptActionFunc;
  runScriptJobAction: TRunScriptJobActionFunc;
}

const actions: IJobScriptActions = {
  createScriptAction: createScriptAction,
  updateScriptAction: updateScriptAction,
  fetchAllScriptsAction: fetchAllScriptsAction,
  deleteScriptAction: deleteScriptAction,
  useScriptAction: useScriptAction,
  runScriptJobAction: runScriptJobAction,
};

export default actions;
