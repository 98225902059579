const NumberdPin: React.FC<{
  number: number;
}> = ({ number }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23042_942)">
        <path
          d="M24 4C16.26 4 10 10.26 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.26 31.74 4 24 4ZM24 23C21.24 23 19 20.76 19 18C19 15.24 21.24 13 24 13C26.76 13 29 15.24 29 18C29 20.76 26.76 23 24 23Z"
          fill="#124E5D"
        />
      </g>
      <rect x="18" y="12" width="12" height="12" fill="#124E5D" />
      <defs>
        <clipPath id="clip0_23042_942">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
      <text
        x="50%"
        y="45%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontWeight={700}
        fontSize={18}
      >
        {number}
      </text>
    </svg>
  );
};

export default NumberdPin;
