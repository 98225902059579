import _ from "lodash";

import { replaceHTMLTags } from "containers/workflows/subcategories/jobs/Jobscreen/notification/EmailNotificationConfigForm/utils/replaceHTMLTags";
import { IMetaExpressionInTable } from "model/entities/MetaExpression";

import { IInputMetaExpressionProps } from "../InputMetaExpression";
import { META_EXPRESSION_REGEX } from "../InputMetaExpressionField";

interface IGetFormattedDefaultValue
  extends Pick<IInputMetaExpressionProps, "defaultValue"> {
  metaExpressions: IMetaExpressionInTable[];
}

export function getFormattedDefaultValue({
  defaultValue,
  metaExpressions,
}: IGetFormattedDefaultValue) {
  let formattedValue = _.toString(defaultValue) ?? "";

  // The following part is a bit weird, but if we don't do that, tagify removes
  // mysteriously the first \n of the content... This causes severe problems especially for python scripts
  const splittedByLinebreak = defaultValue ? defaultValue.split("\n") : [];
  if (splittedByLinebreak.length > 1) {
    formattedValue = `${splittedByLinebreak[0]}\n\n${splittedByLinebreak
      .slice(1)
      .join("\n")}`;
  }

  formattedValue.match(META_EXPRESSION_REGEX)?.forEach((val) => {
    const v = val
      .replace(/\$\{/g, "")
      .replace(/\}/g, "")
      .replace(/\$\^/g, "")
      .replace(/\^/g, "");

    const relatedMeta = _.find(metaExpressions, (me) => me.expression === v);

    if (!relatedMeta && formattedValue) {
      // keep formattedValue
      return;
    }

    formattedValue = relatedMeta
      ? _.replace(
          formattedValue,
          val,
          `[[{"value":"${relatedMeta.title}","expression":"${relatedMeta.expression}"}]]`
        )
      : "";
  });

  return replaceHTMLTags(formattedValue);
}
