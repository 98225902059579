import { Box } from "@material-ui/core";

import InputPlaceholder from "components/Typography/InputPlaceholder";
import { useTranslations } from "hooks";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import { IInputMultipleSelectOnListProps } from "../InputMultipleSelectOnList/InputMultipleSelectOnList";

interface IEmptyOptionsView
  extends Pick<
      IInputMultipleSelectOnListProps,
      | "options"
      | "viewMode"
      | "error"
      | "viewStacked"
      | "labelIcons"
      | "disabled"
      | "noOptionsText"
    >,
    IInputBaseLayout {}
function EmptyOptionsView({
  tooltip,
  label,
  viewMode,
  error,
  disabled,
  noOptionsText,
}: IEmptyOptionsView) {
  const lang = useTranslations();
  return (
    <InputBaseLayout
      label={label}
      tooltip={tooltip}
      viewMode={viewMode}
      error={error}
      disabled={disabled}
      dataTestId="EmptyOptionsView"
    >
      <Box marginTop="8px">
        <InputPlaceholder
          placeholder={noOptionsText ?? lang.genericTerms.noOptionsAvailable}
        />
      </Box>
    </InputBaseLayout>
  );
}

export default EmptyOptionsView;
