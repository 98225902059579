import {
  ICustomerDetailForGPSTracking,
  TTimelineData,
} from "model/entities/Dashboard";

import orderTimelineEvents from "./orderTimelineEvents";
import { sortVisitChunksByCustomerVisitOrder } from "./sortVisitChunksByDate";
import { splitByCheckInOut } from "./splitByCheckInOut";

export function getOrderedTimeLineData(
  customersVisited: ICustomerDetailForGPSTracking[],
  data: TTimelineData[]
) {
  //1. order the events by visit_id -> checkin -> <any> -> checkout -> visit_id -> ...
  //2. split the events into chunks everytime theres a checkout
  //3. sort the chunks by the visit order of the customer

  //with step 2 + 3 we ensure that visits to the same customer on different dates arent on the same line
  return sortVisitChunksByCustomerVisitOrder(
    splitByCheckInOut(orderTimelineEvents(data)),
    customersVisited
  );
}
