import { INotification as IBENotification } from "fieldpro-tools";

import { INotification } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface ICreateNotificationSuccessAction extends IAction {
  notification: INotification;
}
export interface IUpdateNotificationSuccessAction extends IAction {
  notification: INotification;
}
export interface IRunNotificationJobSuccessAction extends IAction {
  success: boolean;
}
export interface IDeleteNotificationSuccessAction extends IAction {
  id: string;
}
export interface IArchiveNotificationsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreNotificationsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchNotificationsSuccessAction extends IAction {
  allNotifications: IBENotification[];
}

/**
 * Create notification begin action creator
 * This creates an action informing redux store that the creation process for an notification has just began
 * @returns {Object}
 */
export function createNotificationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_NOTIFICATION_BEGIN,
  };
}

/**
 * Failure Action for creating aN notification instructing that the creation process for a notification has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createNotificationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_NOTIFICATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a notification
 * @param {Object} notification Notification object,
 * @returns {Object}
 */
export const createNotificationSuccessActionCreator = (
  notification: INotification
): ICreateNotificationSuccessAction => {
  return {
    type: types.CREATE_NOTIFICATION_SUCCESS,
    notification,
  };
};

/**
 * Edit Notification begin action creator
 * This creates an action informing redux store that the creation process for a notification has just began
 * @returns {Object}
 */
export function updateNotificationBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_NOTIFICATION_BEGIN,
  };
}

/**
 * Failure Action for editing a notification instructing that the edition process for a notification has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateNotificationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_NOTIFICATION_FAILURE,
    error,
  };
}

/**
 * Success action for editing a notification
 * @param {Object} notification Notification object,
 * @returns {Object}
 */
export function updateNotificationSuccessActionCreator(
  notification: INotification
): IUpdateNotificationSuccessAction {
  return {
    type: types.UPDATE_NOTIFICATION_SUCCESS,
    notification,
  };
}

/**
 * Delete notification begin action creator
 * This creates an action informing redux store that the creation process for a notification has just began
 * @returns {Object}
 */
export function deleteNotificationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_NOTIFICATION_BEGIN,
  };
}

/**
 * Failure Action for creating a notification instructing that the creation process for a notification has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteNotificationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_NOTIFICATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a notification
 * @param {Object} notificationId Notification object,
 * @returns {Object}
 */
export function deleteNotificationSuccessActionCreator(
  notificationId: string
): IDeleteNotificationSuccessAction {
  return {
    type: types.DELETE_NOTIFICATION_SUCCESS,
    id: notificationId,
  };
}

/**
 * begin action when there is a request to fetch all allNotifications
 * @returns {Object}
 * */
export const fetchAllNotificationsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_NOTIFICATIONS_BEGIN,
});

/**
 * Failure action for fetching all allNotifications. This action is dispatched when there is an error fetching all
 * allNotifications
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllNotificationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_NOTIFICATIONS_FAILURE,
  error,
});

/**
 * Fetch all allNotifications action creator
 * @param {Array} allNotifications an array of emailGeneration objects
 * @returns {Object}
 * */
export const fetchAllNotificationsSuccessActionCreator = (
  allNotifications: IBENotification[]
): IFetchNotificationsSuccessAction => ({
  type: types.FETCH_ALL_NOTIFICATIONS_SUCCESS,
  allNotifications,
});
/**
 * Run notification job begin action creator
 * This creates an action informing redux store that the running process for a notification job has just began
 * @returns {Object}
 */
export function runNotificationJobBeginActionCreator(): IBeginAction {
  return {
    type: types.RUN_NOTIFICATION_JOB_BEGIN,
  };
}

/**
 * Failure Action for running a notification job instructing that the running process for a notification job has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runNotificationJobFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.RUN_NOTIFICATION_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for running a notification job
 * @param {Object} notification Notification object,
 * @returns {Object}
 */
export const runNotificationJobSuccessActionCreator = (
  success: boolean
): IRunNotificationJobSuccessAction => {
  return {
    type: types.RUN_NOTIFICATION_JOB_SUCCESS,
    success,
  };
};
