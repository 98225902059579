import apiService from "api/apiService";
import { IFolder } from "model/entities/Client";
import { IServerResponse } from "redux/actions/appActions";

import * as urls from "./endpoints";

/**
 * Creates a Folder by making a POST request to api with the given parameters
 * @param {Object} body details of the new created older
 * @return {Promise}
 * */
export type TCreateFolderResponse = {
  actionName: string;
  id: string;
};
export type TCreateFolderFunc = (
  actionName: string,
  body: IFolder
) => Promise<IServerResponse<TCreateFolderResponse>>;
export const createFolderApiCall: TCreateFolderFunc = (actionName, body) => {
  return apiService.post(urls.FOLDER_ENDPOINT, body, actionName);
};
/**
 * Updates the folder
 * @param {String} id Id of folder to update
 * @param {Object} body Detail of the folder updated
 * @return {Promise}
 * */
export type TUpdateFolderFunc = (
  actionName: string,
  id: string,
  body: IFolder
) => Promise<
  IServerResponse<{ folder_id: string; archivedDashboardsIds: string[] }>
>;
export const updateFolderApiCall: TUpdateFolderFunc = (
  actionName: string,
  id,
  body
) => {
  return apiService.patch(`${urls.FOLDER_ENDPOINT}/${id}`, body, actionName);
};

/**
 * Delete Folder API call makes a POST request to delete a single Folder
 * @param {String} id Id of the Folder to delete
 * @return {Promise}
 * */
export type TDeleteFolderFunc = (
  actionName: string,
  id: string
) => Promise<IServerResponse<{}>>;
export const deleteFolderApiCall: TDeleteFolderFunc = (
  actionName: string,
  id
) => {
  return apiService.delete(`${urls.FOLDER_ENDPOINT}/${id}`, {}, actionName);
};

/**
 * Fetch all folders api call
 * @return {Promise}
 * */
export type TFetchFoldersResponse = { folders: IFolder[] };
export type TFetchFoldersFunc = () => Promise<
  IServerResponse<TFetchFoldersResponse>
>;
export const fetchFoldersApiCall: TFetchFoldersFunc = () => {
  return apiService.get(urls.FOLDER_ENDPOINT);
};

export type ArchiveFolderFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<IArchiveFolderResponse>>;
export interface IArchiveFolderResponse {}
export const archiveFolderApiCall: ArchiveFolderFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(
    `${urls.FOLDER_ENDPOINT}/archive`,
    {
      ids,
    },
    actionName
  );
};

export type RestoreFolderFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<IRestoreFolderResponse>>;
export interface IRestoreFolderResponse {}

export const restoreFolderApiCall: RestoreFolderFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(
    `${urls.FOLDER_ENDPOINT}/restore`,
    {
      ids,
    },
    actionName
  );
};
