import { TCalendarEvent } from "fieldpro-tools";
import moment from "moment";

import { IDSource } from "__utils__/utils";

export function computeNewEvent(event: TCalendarEvent): TCalendarEvent {
  const { start_time, end_time } = event;
  const isMidnight = moment.utc(start_time).format("HH:mm") === "00:00";
  const newEvent = {
    ...event,
    id: IDSource(),
    type: event.type ?? ("TASK" as any),
  };
  if (isMidnight) {
    newEvent.start_time = moment
      .utc(start_time)
      .add("1", "day")
      .add(6, "hours")
      .toDate();
    newEvent.end_time = moment
      .utc(end_time)
      .add("1", "day")
      .add(6, "hours")
      .toDate();
  }
  return newEvent;
}
