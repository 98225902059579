import { ITransformation as IBETransformation } from "fieldpro-tools/dist/src/types";

import { ITransformation } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

// TRANSFORMATIONS

export interface ICreateTransformationSuccessAction extends IAction {
  transformation: ITransformation;
}
export interface IUpdateTransformationSuccessAction extends IAction {
  transformation: ITransformation;
}
export interface IDeleteTransformationSuccessAction extends IAction {
  id: string;
}
export interface IArchiveTransformationsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreTransformationsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchTransformationsSuccessAction extends IAction {
  transformations: IBETransformation[];
}
export interface IRunTransformationJobSuccessAction extends IAction {
  success: boolean;
}
export interface IFetchJobHistorySuccessAction extends IAction {
  steps_overview: any[];
}

export interface IInitTransformationJobSuccessAction extends IAction {
  success: boolean;
}

/**
 * Create transformation begin action creator
 * This creates an action informing redux store that the creation process for a transformation has just began
 * @returns {Object}
 */
export function createTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for creating a transformation instructing that the creation process for a transformation has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a transformation
 * @param {Object} transformation Transformation object,
 * @returns {Object}
 */
export const createTransformationSuccessActionCreator = (
  transformation: ITransformation
): ICreateTransformationSuccessAction => {
  return {
    type: types.CREATE_TRANSFORMATION_SUCCESS,
    transformation,
  };
};

/**
 * Edit dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function updateTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for editing a dashboard instructing that the edition process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for editing a dashboard
 * @param {Object} dashboard Dashboard object,
 * @returns {Object}
 */
export function updateTransformationSuccessActionCreator(
  transformation: ITransformation
): IUpdateTransformationSuccessAction {
  return {
    type: types.UPDATE_TRANSFORMATION_SUCCESS,
    transformation,
  };
}

/**
 * Delete dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function deleteTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the creation process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a dashboard
 * @param {Object} dashboardId Dashboard object,
 * @returns {Object}
 */
export function deleteTransformationSuccessActionCreator(
  dashboardId: string
): IDeleteTransformationSuccessAction {
  return {
    type: types.DELETE_TRANSFORMATION_SUCCESS,
    id: dashboardId,
  };
}

/**
 * begin action when there is a request to fetch all transformations
 * @returns {Object}
 * */
export const fetchAllTransformationsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_BEGIN,
});

/**
 * Failure action for fetching all transformations. This action is dispatched when there is an error fetching all
 * transformations
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllTransformationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_FAILURE,
  error,
});

/**
 * Fetch all transformations action creator
 * @param {Array} transformations an array of dashboard objects
 * @returns {Object}
 * */
export const fetchAllTransformationsSuccessActionCreator = (
  transformations: IBETransformation[]
): IFetchTransformationsSuccessAction => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_SUCCESS,
  transformations,
});
/**
 * Run transformation job begin action creator
 * This creates an action informing redux store that the running process for a transformation job has just began
 * @returns {Object}
 */
export function runTransformationJobBeginActionCreator(): IBeginAction {
  return {
    type: types.RUN_TRANSFORMATION_JOB_BEGIN,
  };
}

/**
 * Failure Action for running a transformation job instructing that the running process for a transformation job has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runTransformationJobFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.RUN_TRANSFORMATION_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for running a transformation job
 * @param {Object} transformation Notification object,
 * @returns {Object}
 */
export const runTransformationJobSuccessActionCreator = (
  success: boolean
): IRunTransformationJobSuccessAction => {
  return {
    type: types.RUN_TRANSFORMATION_JOB_SUCCESS,
    success,
  };
};
/**
 * begin action when there is a request to fetch all transformation job history
 * @returns {Object}
 * */
export const fetchJobHistoryBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_JOB_HISTORY_BEGIN,
});

/**
 * Failure action for fetching all transformation job history. This action is dispatched when there is an error fetching all
 * transformation job history
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchJobHistoryFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_JOB_HISTORY_FAILURE,
  error,
});

/**
 * Fetch all transformation job history action creator
 * @param {Array} steps_overview an array of objects
 * @returns {Object}
 * */
export const fetchJobHistorySuccessActionCreator = (
  steps_overview: any[]
): IFetchJobHistorySuccessAction => ({
  type: types.FETCH_JOB_HISTORY_SUCCESS,
  steps_overview,
});

/**
 * Init transformation job begin action creator
 * This creates an action informing redux store that the running process for a transformation job has just began
 * @returns {Object}
 */
export function initTransformationJobBeginActionCreator(): IBeginAction {
  return {
    type: types.INIT_TRANSFORMATION_JOB_BEGIN,
  };
}

/**
 * Failure Action for trying to init a transfo job
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function initTransformationJobFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.INIT_TRANSFORMATION_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for initiating a transformation job
 * @param {Object} transformation Notification object,
 * @returns {Object}
 */
export const initTransformationJobSuccessActionCreator = (
  success: boolean
): IInitTransformationJobSuccessAction => {
  return {
    type: types.INIT_TRANSFORMATION_JOB_SUCCESS,
    success,
  };
};
