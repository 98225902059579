import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { TColumnType } from "../model";
import styles from "../styles";
import { useTranslations } from "./../../../hooks/useTranslations";

const useStyle = makeStyles(styles as any);

interface ICustomFilterRow {
  columnTypes: TColumnType[];
  totalCount: number;
  filteredCount: number;
  onClearFilters?: () => void;
  onFilterAlert?: () => void;
}

const CustomFilterRow: React.FunctionComponent<ICustomFilterRow> = ({
  columnTypes,
  totalCount,
  filteredCount,
  onClearFilters,
  onFilterAlert,
}) => {
  const classes = useStyle();
  const lang = useTranslations();

  return (
    <tr className={classes.FilterRows}>
      <td colSpan={columnTypes.length + 2}>
        <div className={classes.FirstColumnOnFilterTable}>
          <div className={classes.FirstColumnsOnFilterRows}>
            <FilterAltOutlinedIcon
              style={{ position: "sticky", left: "18px" }}
            />
            <span style={{ position: "sticky", left: "55px" }}>
              <span>{lang.components.table.filterMessage.startTitle} </span>
              <span>
                <b>{filteredCount} </b>
                {lang.components.table.filterMessage.middleTitle}
                <b> {totalCount} </b>
              </span>
              <span>{lang.components.table.filterMessage.endTitle}</span>
            </span>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "20px",
                position: "sticky",
                left: "600px",
              }}
            >
              <Button
                className={classes.ClearButton}
                variant="contained"
                onClick={onClearFilters}
              >
                <RefreshIcon />
                {lang.components.emptyTable.searchFilter.filter.clearButton.ROW}
              </Button>

              <div className={classes.FilterRowClose}>
                <CloseOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={onFilterAlert}
                />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

const RefreshIcon = () => {
  return (
    <span className="material-icons-outlined" style={{ marginRight: "8px " }}>
      refresh
    </span>
  );
};

export default CustomFilterRow;
