/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import {
  ITransformation as IBETransformation,
  TRANSFORMATION_JOB_PARAMETER,
} from "fieldpro-tools/dist/src/types";

import apiService from "api/apiService";
import { TWorkflowStepType } from "containers/workflows/models";
import { ITransformation, STEP_TYPE } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareTransformationForBackend } from "./utils";

export const TRANSFORMATIONS_ENDPOINT = `transformations`;

// TRANSFORMATIONS

export type TCreateTransformationFunc = (
  actionName: string,
  transformation: ITransformation
) => Promise<IServerResponse<ICreateTransformationResponse>>;
export interface ICreateTransformationResponse {
  id: string;
}

export type TUpdateTransformationFunc = (
  actionName: string,
  transformation: ITransformation
) => Promise<IServerResponse<IUpdateTransformationResponse>>;
export interface IUpdateTransformationResponse {}

export type TDeleteTransformationFunc = (
  actionName: string,
  transformationId: string
) => Promise<IServerResponse<IDeleteTransformationResponse>>;
export interface IDeleteTransformationResponse {}

export type TFetchTransformationsFunc = () => Promise<
  IServerResponse<IFetchTransformationsResponse>
>;
export interface IFetchTransformationsResponse {
  transformations: IBETransformation[];
}
export type TFetchJobHistoryFunc = (
  stepId: string,
  stepType: TWorkflowStepType
) => Promise<IServerResponse<IFetchJobHistoryResponse>>;
export interface IFetchJobHistoryResponse {
  steps_overview: any[];
}
export type TRunTransformationFunc = (
  actionName: string,
  transformation: ITransformation
) => Promise<IServerResponse<IRunTransformationResponse>>;

export interface IRunTransformationResponse {
  success: boolean;
}

export type TInitTransformationFunc = (
  actionName: string,
  transformationId: string,
  startDate: string,
  endDate: string
) => Promise<IServerResponse<IInitTransformationResponse>>;

export interface IInitTransformationResponse {
  success: boolean;
}

/**
 * Creates a new transformation
 * @param {Object} transformation Transformation details
 */
export const createTransformationApiCall: TCreateTransformationFunc = (
  actionName,
  transformation
) => {
  const payload = prepareTransformationForBackend(transformation);
  return apiService.post(`${TRANSFORMATIONS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted transformation
 * @param {Object} transformation Detail of the new transformation
 */
export const updateTransformationApiCall: TUpdateTransformationFunc = (
  actionName,
  transformation
) => {
  const payload = prepareTransformationForBackend(transformation);
  return apiService.patch(
    `${TRANSFORMATIONS_ENDPOINT}/${transformation.id}`,
    payload,
    actionName
  );
};

/**
 * Delete transformation API call makes a POST request to delete a single transformation
 * @param {String} transformation Id of the transformation to delete
 * @return {Promise}
 * */
export const deleteTransformationApiCall: TDeleteTransformationFunc = (
  actionName,
  transformationId
) => {
  return apiService.delete(
    `${TRANSFORMATIONS_ENDPOINT}/${transformationId}`,
    {},
    actionName
  );
};

/**
 * Api call to fetch all
 */
export const fetchTransformationsApiCall: TFetchTransformationsFunc = () => {
  return apiService.get(`${TRANSFORMATIONS_ENDPOINT}`);
};
/**
 * run a transformation job
 * @param {Object} transformation Transformation details
 */
export const runTransformationJobApiCall: TRunTransformationFunc = (
  actionName,
  transformation
) => {
  return apiService.post(
    `transformation/${transformation.id}/run`,
    {
      parameters: [
        // {
        //   key: TRANSFORMATION_JOB_PARAMETER.TERMINATION_TIME,
        //   value: transformation.termination_time,
        // },
        {
          key: TRANSFORMATION_JOB_PARAMETER.QUERY,
          value: transformation.query,
        },
      ],
    },
    actionName
  );
};
/**
 * Api call to fetch all
 */
export const fetchJobHistoryApiCall: TFetchJobHistoryFunc = (
  stepId,
  stepType
) => {
  const STEP_TYPE_TO_PATH = {
    [STEP_TYPE.NOTIFICATION]: "notifications",
    [STEP_TYPE.STREAM]: "streams",
    [STEP_TYPE.SCRIPT]: "scripts",
    [STEP_TYPE.TRANSFORMATION]: "transformations",
    [STEP_TYPE.INTEGRATION]: "integrations",
    [STEP_TYPE.HTTP_REQUEST]: "http_requests",
  };
  const stepPath = STEP_TYPE_TO_PATH[stepType];
  return apiService.get(`steps/${stepPath}/${stepId}/history`);
};
/**
 * init a transformation job (ex: historical load)
 * @param {Object} transformation Stream details
 */
export const initTransformationJobApiCall: TInitTransformationFunc = (
  actionName,
  transformationId,
  startDate,
  endDate
) => {
  return apiService.post(
    `transformation/${transformationId}/initjob`,
    {
      start_time: startDate,
      end_time: endDate,
    },
    actionName
  );
};
