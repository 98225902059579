import {
  ICondition as IBECondition,
  IOperation as IBEOperation,
} from "fieldpro-tools/dist/src/types";
import { IOperand } from "fieldpro-tools/src/types";
import _ from "lodash";

import { ICondition, IOperation, IQuestion } from "model/entities/Workflow";

export function prepareQuestionConditionsAndOperationsForBE(
  question: IQuestion
) {
  const newQuestion = { ...question };

  if (question.conditions) {
    newQuestion.conditions = prepareConditionsForBackend(
      removeLinkItems(question.conditions)
    );
  }

  if (question.operations) {
    newQuestion.operations = prepareOperationsForBackend(question.operations);
  }

  return newQuestion;
}

function prepareConditionsForBackend(conditions: ICondition[]): IBECondition[] {
  const newConditions: IBECondition[] = [];
  const processedConditions: IBECondition[] = [];
  const resultConditions: IBECondition[] = [];

  for (let i = 0; i < conditions.length; i++) {
    const currentCondition = conditions[i];
    const nextCondition = conditions[i + 1];

    if (currentCondition.nextOperator && nextCondition) {
      const linkCondition: IBECondition = {
        first: {
          result_id: currentCondition.id,
        },
        second: {
          result_id: nextCondition.id,
        },
        operator: currentCondition.nextOperator,
        id: _.size(conditions) + i + 1,
        priority: currentCondition.priority + nextCondition.priority,
      };
      delete currentCondition.nextOperator;
      resultConditions.push(linkCondition);
    }
    processedConditions.push(currentCondition);
  }

  newConditions.push(...processedConditions, ...resultConditions);
  return newConditions;
}

function prepareOperationsForBackend(operations: IOperation[]): IBEOperation[] {
  return operations.map((operation) => {
    delete operation.nextOperator;

    return operation;
  });
}

function removeLinkItems<
  T extends { first: IOperand; id: number; nextOperator?: string }
>(items: T[]): T[] {
  return items.filter((item) => {
    const isLinkItem = item.first.result_id != undefined;
    const referenceItem = items.find((i) => i.id === item.first.result_id);
    const remove = isLinkItem && !_.isEmpty(referenceItem?.nextOperator);
    return !remove;
  });
}
