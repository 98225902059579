import React, { useState } from "react";

import { Box, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import _ from "lodash";

import CustomButton, { BUTTON_COLOR } from "components/Buttons/CustomButton";
import CustomCheckBox from "components/SelectControls/CustomCheckBox";
import useTranslations from "hooks/useTranslations";
import { ACTION_TYPE } from "model/application/UIActionTypes";

import { TRowAction } from "../model";
import styles from "../styles";
import {
  checkIfActive,
  checkIfDeletePossible,
  isActionBlockedForRow,
  isContinueExecutionPossible,
  isResumeExecutionPossible,
  isStopExecutionPossible,
} from "./CustomTableRow.utils";

export interface ICustomTableActionsHeaderProps {
  classes: any;
  checkedRows: any[];
  data: any[];
  onSelectAll: (checked: boolean) => void;
  onEditItem?: TRowAction;
  onDeleteItem?: TRowAction;
  onLicenseItem?: (ids: string[]) => void;
  onUnLicenseItem?: (ids: string[]) => void;
  onArchiveItem?: TRowAction;
  onRestoreItem?: TRowAction;
  onRestoreVersion?: TRowAction;
  onViewItem?: TRowAction;
  onStopExecution?: TRowAction;
  onResumeExecution?: TRowAction;
  onContinueExecution?: TRowAction;
  onReplicateItem?: TRowAction;
  bulkActions?: any[];
  checkBoxStatus: number;
}

const CustomTableActionsHeader: React.FunctionComponent<
  ICustomTableActionsHeaderProps
> = ({
  classes,
  checkedRows,
  data,
  onEditItem,
  onArchiveItem,
  onDeleteItem,
  onLicenseItem,
  onUnLicenseItem,
  onReplicateItem,
  onRestoreItem,
  onRestoreVersion,
  onStopExecution,
  onResumeExecution,
  onContinueExecution,
  onViewItem,
  bulkActions,
  onSelectAll,
  checkBoxStatus,
}) => {
  const lang = useTranslations();
  const [isOpenSelectAllMenu, setIsOpenSelectAllMenu] = useState(false);

  const selectedItems =
    checkedRows && checkedRows.length > 0
      ? data.filter(
          (d) =>
            checkedRows.includes(d.id) ||
            checkedRows.includes(d._id) ||
            checkedRows.includes(d.item_id)
        )
      : [];
  const areSelectedRowsActive =
    selectedItems.length > 0 &&
    selectedItems.every(
      (item) => !isActionBlockedForRow(item) && checkIfActive(item)
    );
  const areSelectedRowsDeletable =
    selectedItems.length > 0 &&
    selectedItems.every(
      (item) => !isActionBlockedForRow(item) && checkIfDeletePossible(item)
    );
  const isStopPossible =
    selectedItems.length > 0 &&
    !isActionBlockedForRow(selectedItems[0]) &&
    isStopExecutionPossible(selectedItems[0]);
  const isResumePossible =
    selectedItems.length > 0 &&
    !isActionBlockedForRow(selectedItems[0]) &&
    isResumeExecutionPossible(selectedItems[0]);
  const isContinuePossible =
    selectedItems.length > 0 &&
    !isActionBlockedForRow(selectedItems[0]) &&
    isContinueExecutionPossible(selectedItems[0]);
  const singleRowAction = selectedItems && selectedItems.length === 1;

  // edit action handler
  const editActionHandler =
    bulkActions &&
    bulkActions.find(
      (action) =>
        action.actionType === ACTION_TYPE.EDIT_MULTIPLE &&
        !_.includes(action.label, "CSV")
    );
  const handleEditEvent: () => void = () => {
    /* Non Single Action means bulk actions  */
    if (singleRowAction) {
      selectedItems && onEditItem && onEditItem(selectedItems[0]);
    } else {
      editActionHandler && editActionHandler.action(selectedItems);
    }
  };

  // delete action handler
  const deleteActionHandler =
    bulkActions &&
    bulkActions.find(
      (action) =>
        action.actionType === ACTION_TYPE.DELETE_MULTIPLE &&
        !action.label.includes("CSV")
    );
  const handleDeleteEvent: () => void = () => {
    if (singleRowAction)
      selectedItems && onDeleteItem && onDeleteItem(selectedItems[0]);
    else {
      deleteActionHandler?.action(selectedItems);
    }
  };

  // archive action handler
  const archiveActionHandler =
    bulkActions &&
    bulkActions.find(
      (action) => action.actionType === ACTION_TYPE.ARCHIVE_MULTIPLE
    );
  const handleArchiveEvent: () => void = () => {
    if (singleRowAction)
      selectedItems && onArchiveItem && onArchiveItem(selectedItems[0]);
    else {
      archiveActionHandler && archiveActionHandler.action(selectedItems);
    }
  };

  // restore action handler
  const restoreActionHandler =
    bulkActions &&
    bulkActions.find(
      (action) =>
        action.actionType === ACTION_TYPE.RESTORE_MULTIPLE &&
        !action.label.includes("CSV")
    );
  const handleRestoreEvent: () => void = () => {
    if (singleRowAction)
      selectedItems && onRestoreItem && onRestoreItem(selectedItems[0]);
    else {
      restoreActionHandler && restoreActionHandler.action(selectedItems);
    }
  };

  const handleRestoreVersionEvent: () => void = () => {
    checkedRows && onRestoreVersion && onRestoreVersion(checkedRows[0]);
  };
  const handleViewEvent: () => void = () => {
    checkedRows && onViewItem && onViewItem(checkedRows[0]);
  };
  const handleStopExecutionEvent: () => void = () => {
    checkedRows && onStopExecution && onStopExecution(checkedRows[0]);
  };
  const handleResumeExecutionEvent: () => void = () => {
    checkedRows && onResumeExecution && onResumeExecution(checkedRows[0]);
  };
  const handleContinueExecutionEvent: () => void = () => {
    checkedRows && onContinueExecution && onContinueExecution(checkedRows[0]);
  };
  const handleReplicateExecutionEvent: () => void = () => {
    checkedRows && onReplicateItem && onReplicateItem(checkedRows[0]);
  };

  const checkedUnlicensedIds: string[] = [];
  checkedRows.map((checked) => {
    const unlicensed = data.find((dat) => dat._id === checked);
    if (unlicensed) {
      if (!unlicensed.licensed) checkedUnlicensedIds.push(checked);
    }
  });
  const handleLicensedEvent = () => {
    if (onLicenseItem && checkedUnlicensedIds.length)
      onLicenseItem(checkedUnlicensedIds);
  };

  const checkedLicensedIds: string[] = [];
  checkedRows.map((checked) => {
    const licensedRows = data.find((dat) => dat._id === checked);
    if (licensedRows) {
      if (licensedRows.licensed) checkedLicensedIds.push(checked);
    }
  });
  const handleUnLicensedEvent = () => {
    if (onUnLicenseItem && checkedLicensedIds.length)
      onUnLicenseItem(checkedLicensedIds);
  };
  return (
    <Box
      className={classes.SelectMenu}
      display="flex"
      alignItems="center"
      style={{ margin: "5px 5px 5px 16px", position: "relative" }}
    >
      <CustomCheckBox
        isIndeterminate={checkBoxStatus === 2}
        isChecked={checkBoxStatus === 1}
        onChecked={(val) => {
          onSelectAll(val);
        }}
      />
      <ArrowDropDownIcon
        onClick={() => setIsOpenSelectAllMenu(!isOpenSelectAllMenu)}
      />
      <div
        className={classes.selectAllMenu}
        style={{ display: isOpenSelectAllMenu ? "block" : "none" }}
      >
        <MenuItem
          onClick={() => {
            onSelectAll(true);
            setIsOpenSelectAllMenu(false);
          }}
          className={classes.SelectMenu}
        >
          {lang.genericTerms.selectAll}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectAll(false);
            setIsOpenSelectAllMenu(false);
          }}
          className={classes.SelectMenu}
        >
          {lang.genericTerms.selectNone}
        </MenuItem>
      </div>
      {/* </ArrowMenu> */}

      {checkedRows.length > 0 && (
        <div
          style={{
            margin: "0 16px 0 8px",
            display: "inline-block",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {checkedRows.length} selected
        </div>
      )}
      {areSelectedRowsActive && editActionHandler && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleEditEvent}
          data-toggle="tooltip"
          data-placement="top"
          title={"Edit"}
          isTableAction={false}
          color={BUTTON_COLOR.SUCCESS}
          type={ACTION_TYPE.EDIT}
        />
      )}
      {areSelectedRowsActive && archiveActionHandler && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleArchiveEvent}
          data-toggle="tooltip"
          data-placement="top"
          title={checkedRows.length > 1 ? "Bulk Archive" : "Archive"}
          isTableAction={false}
          color={BUTTON_COLOR.WARNING}
          type={ACTION_TYPE.ARCHIVE}
        />
      )}
      {areSelectedRowsDeletable && deleteActionHandler && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleDeleteEvent}
          data-toggle="tooltip"
          data-placement="top"
          isTableAction={false}
          title={checkedRows.length > 1 ? "Bulk Delete" : "Delete"}
          color={BUTTON_COLOR.ERROR}
          type={ACTION_TYPE.DELETE}
        />
      )}
      {areSelectedRowsActive &&
        onLicenseItem &&
        checkedUnlicensedIds.length > 0 && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleLicensedEvent}
            data-toggle="tooltip"
            hasALicense={false}
            data-placement="top"
            isTableAction={false}
            title={"License"}
            color={BUTTON_COLOR.SUCCESS}
            type={ACTION_TYPE.LICENSE}
          />
        )}
      {areSelectedRowsActive &&
        onUnLicenseItem &&
        checkedLicensedIds.length > 0 && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleUnLicensedEvent}
            data-toggle="tooltip"
            hasALicense={true}
            data-placement="top"
            isTableAction={false}
            title={"Unlicense"}
            color={BUTTON_COLOR.SUCCESS}
            type={ACTION_TYPE.LICENSE}
          />
        )}
      {singleRowAction && onViewItem && !isActionBlockedForRow && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleViewEvent}
          data-toggle="tooltip"
          data-placement="top"
          title="View"
          isTableAction={false}
          color={BUTTON_COLOR.SUCCESS}
          type={ACTION_TYPE.DETAIL}
        />
      )}
      {areSelectedRowsDeletable && restoreActionHandler && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleRestoreEvent}
          data-toggle="tooltip"
          data-placement="top"
          isTableAction={false}
          title={checkedRows.length > 1 ? "Bulk Restore" : "Restore"}
          color={BUTTON_COLOR.INFO}
          type={ACTION_TYPE.RESTORE}
        />
      )}
      {singleRowAction && onReplicateItem && areSelectedRowsActive && (
        <CustomButton
          className={classes.ActionButton}
          onClick={handleReplicateExecutionEvent}
          data-toggle="tooltip"
          data-placement="top"
          title="Replicate"
          isTableAction={false}
          color={BUTTON_COLOR.REPLICATE}
          type={ACTION_TYPE.REPLICATE}
        />
      )}
      {singleRowAction &&
        handleRestoreVersionEvent &&
        restoreActionHandler &&
        !isActionBlockedForRow && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleRestoreVersionEvent}
            data-toggle="tooltip"
            data-placement="top"
            isTableAction={false}
            title="RestoreVersion"
            color={BUTTON_COLOR.SUCCESS}
            type={ACTION_TYPE.RESTORE_VERSION}
          />
        )}
      {singleRowAction &&
        handleStopExecutionEvent &&
        isStopPossible &&
        !isActionBlockedForRow && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleStopExecutionEvent}
            data-toggle="tooltip"
            data-placement="top"
            isTableAction={false}
            title="stopExecution"
            color={BUTTON_COLOR.ERROR}
            type={ACTION_TYPE.STOP}
          />
        )}
      {singleRowAction &&
        handleResumeExecutionEvent &&
        isResumePossible &&
        !isActionBlockedForRow && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleResumeExecutionEvent}
            data-toggle="tooltip"
            data-placement="top"
            isTableAction={false}
            title="resumeExecution"
            color={BUTTON_COLOR.ERROR}
            type={ACTION_TYPE.RESUME}
          />
        )}
      {singleRowAction &&
        handleResumeExecutionEvent &&
        isResumePossible &&
        !isActionBlockedForRow && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleResumeExecutionEvent}
            data-toggle="tooltip"
            data-placement="top"
            isTableAction={false}
            title="resumeExecution"
            color={BUTTON_COLOR.ERROR}
            type={ACTION_TYPE.RESUME}
          />
        )}
      {singleRowAction &&
        handleContinueExecutionEvent &&
        isContinuePossible &&
        !isActionBlockedForRow && (
          <CustomButton
            className={classes.ActionButton}
            onClick={handleContinueExecutionEvent}
            data-toggle="tooltip"
            data-placement="top"
            isTableAction={false}
            title="ContinueExecution"
            color={BUTTON_COLOR.ERROR}
            type={ACTION_TYPE.CONTINUE}
          />
        )}
    </Box>
  );
};
export default withStyles(styles as any)(CustomTableActionsHeader);
