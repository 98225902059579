import React, { ReactNode } from "react";

import { Badge, Button, ButtonBaseProps } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArchiveIcon from "@material-ui/icons/Archive";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CloseIcon from "@material-ui/icons/Close";
import DuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FilterListIcon from "@material-ui/icons/FilterList";
import DownloadIcon from "@material-ui/icons/GetApp";
import HelpIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PaymentIcon from "@material-ui/icons/Payment";
import PlayArrow from "@material-ui/icons/PlayArrow";
import RefreshIcon from "@material-ui/icons/Refresh";
import RemoveIcon from "@material-ui/icons/Remove";
import DetailIcon from "@material-ui/icons/RemoveRedEye";
import RestoreVersionIcon from "@material-ui/icons/Restore";
import RestoreIcon from "@material-ui/icons/RestoreFromTrash";
import SaveIcon from "@material-ui/icons/Save";
import HideIcon from "@material-ui/icons/VisibilityOff";
import UploadIcon from "@mui/icons-material/FileUpload";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import classNames from "classnames";

import useTranslations from "hooks/useTranslations";

import { ACTION_TYPE } from "../../../model/application/UIActionTypes";
import styles from "../styles";

export enum BUTTON_TYPES_OUTSIDE_TABLE {
  CANCEL = "CANCEL",
  DEFAULT = "DEFAULT",
  ACTION = "ACTION",
  WARNING = "WARNING",
  ERROR = "ERROR",
  BACK = "BACK",
  LOGIN = "LOGIN",
  SAVE = "SAVE",
  SHOW_ACTIVITY = "SHOW_ACTIVITY",
  TIMELINE_ACTIVITY_DETAILS = "TIMELINE_ACTIVITY_DETAILS",
  SHOW_ARCHIVED_QUESTIONS_ATTRIBUTES = "SHOW_ARCHIVED_QUESTIONS_ATTRIBUTES",
}

export enum BUTTON_COLOR {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  LICENSE = "LICENSE",
  INFO = "INFO",
  ERROR = "ERROR",
  LOGIN = "LOGIN",
  REPLICATE = "REPLICATE",
  SECONDARY = "SECONDARY",
}

const useStyles = makeStyles(styles as any);

export interface ICustomButtonProps
  extends Omit<ButtonBaseProps, "type" | "lang"> {
  isTableAction?: boolean;
  isChipAction?: boolean;
  darkMode?: boolean;
  color?: BUTTON_COLOR;
  badgeContent?: number;
  type: BUTTON_TYPES_OUTSIDE_TABLE | ACTION_TYPE;
  text?: string;
  buttonContainer?: any;
  customIcon?: ReactNode;
  hasALicense?: boolean;
}

export const CustomButton: React.FunctionComponent<ICustomButtonProps> = (
  props: ICustomButtonProps
) => {
  const classes = useStyles();
  const lang = useTranslations();

  const buildButton = () => {
    const {
      type,
      darkMode,
      isTableAction,
      badgeContent,
      color,
      text,
      customIcon,
      className,
      hasALicense,
      isChipAction,
      ...rest
    } = props;

    const realColor = color ? color : BUTTON_COLOR.SUCCESS;
    if (!isTableAction) {
      switch (type) {
        case BUTTON_TYPES_OUTSIDE_TABLE.CANCEL: {
          return <Button disableElevation color="default" {...rest} />;
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.ACTION: {
          return (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              className={className}
              startIcon={customIcon}
              {...rest}
            />
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.LOGIN: {
          return (
            <Button
              disableElevation
              variant="contained"
              className={`${classes.NewLoginButton}`}
              {...rest}
            >
              <span className={`${classes.LoginButtonLabel}`}>{text}</span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.SHOW_ACTIVITY: {
          return (
            <Button
              disableElevation
              variant="contained"
              className={`${classes.ShowActivityButton}`}
              autoCapitalize={"false"}
              {...rest}
            >
              <span className={`${classes.ShowActivityButtonLabel}`}>
                {text}
              </span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.TIMELINE_ACTIVITY_DETAILS: {
          return (
            <Button
              disableElevation
              size="small"
              className={`${classes.ShowTimeLineActivityButton}`}
              endIcon={
                <ArrowRightAltIcon
                  className={`${classes.ShowTimeLineActivityLabel}`}
                />
              }
              {...rest}
            >
              <span className={`${classes.ShowTimeLineActivityLabel}`}>
                {text}
              </span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.SHOW_ARCHIVED_QUESTIONS_ATTRIBUTES: {
          return (
            <Button
              disableElevation
              size="small"
              className={`${classes.ShowArchivedQuestionsAttributesButton}`}
              {...rest}
            >
              <span className={`${classes.ShowTimeLineActivityLabel}`}>
                {text}
              </span>
            </Button>
          );
        }

        case BUTTON_TYPES_OUTSIDE_TABLE.ERROR:
          return (
            <Button
              disableElevation
              variant="contained"
              {...rest}
              className={classes.customErrorButton}
            />
          );

        case BUTTON_TYPES_OUTSIDE_TABLE.WARNING:
          return (
            <Button
              disableElevation
              variant="contained"
              {...rest}
              className={classes.customWarningButton}
            />
          );
        case BUTTON_TYPES_OUTSIDE_TABLE.DEFAULT: {
          return (
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              {...rest}
            />
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.BACK: {
          return (
            <Fab
              variant="circular"
              size="small"
              color="primary"
              aria-label="Back"
              className={classes.BackButton}
              {...rest}
            >
              <ArrowBackIcon aria-hidden={false} aria-label="ArrowBackIcon" />
            </Fab>
          );
        }
        case ACTION_TYPE.CREATE:
        case ACTION_TYPE.EDIT:
        case ACTION_TYPE.EDIT_MULTIPLE:
        case ACTION_TYPE.CHANGE_TAG:
        case ACTION_TYPE.DELETE:
        case ACTION_TYPE.ARCHIVE:
        case ACTION_TYPE.ARCHIVE_MULTIPLE:
        case ACTION_TYPE.DOWNLOAD:
        case ACTION_TYPE.REPLICATE:
        case ACTION_TYPE.RESTORE:
        case ACTION_TYPE.RESTORE_MULTIPLE:
        case ACTION_TYPE.RESTORE_VERSION:
        case ACTION_TYPE.STOP:
        case ACTION_TYPE.RESUME:
        case ACTION_TYPE.CONTINUE:
        case ACTION_TYPE.DETAIL:
        case ACTION_TYPE.LICENSE:
        case ACTION_TYPE.UPLOAD:
          return (
            <Button
              disableElevation
              // size="small"
              color="primary"
              aria-label={type}
              className={classNames(
                className,
                classes.CreateAndDownloadButton,
                classes.HeaderActionButton,
                `${classes[`${realColor}Color`]} `
              )}
              {...rest}
              title={text || getButtonText(type, lang, hasALicense)}
            >
              {customIcon ||
                selectActionButton(
                  type as ACTION_TYPE,
                  badgeContent,
                  hasALicense
                )}
              <span>
                &nbsp;
                {text || getButtonText(type, lang, hasALicense)}
              </span>
            </Button>
          );
        case ACTION_TYPE.REFRESH:
          return (
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label={type}
              style={{ margin: "4px" }}
              {...rest}
            >
              {selectActionButton(type as ACTION_TYPE, badgeContent)}
              &nbsp;
              {text || lang.genericTerms.reload}
            </Fab>
          );
        default: {
          return (
            <Fab
              size="small"
              color="primary"
              aria-label={type}
              className={classes.TableActionButton}
              {...rest}
            >
              {selectActionButton(type as ACTION_TYPE, badgeContent)}
            </Fab>
          );
        }
      }
    } else {
      // table action button
      if (type === ACTION_TYPE.DETAIL && !isChipAction) {
        return (
          <Button
            disableElevation
            className={classes.TableDetailActionButton}
            variant="contained"
            color="secondary"
            {...rest}
          >
            MORE
          </Button>
        );
      }
      if (type === ACTION_TYPE.STOP && !isChipAction) {
        return (
          <Button
            disableElevation
            className={classes.TableStopActionButton}
            variant="contained"
            {...rest}
          >
            STOP
          </Button>
        );
      } else {
        return (
          <Fab
            aria-label={type}
            className={`${darkMode ? classes.DarkButton : ""} ${
              classes.TableActionButton
            } ${classes[`${realColor}Color`]}`}
            {...rest}
          >
            {selectActionButton(type as ACTION_TYPE, badgeContent)}
          </Fab>
        );
      }
    }
  };

  if (props.isTableAction || props.type === BUTTON_TYPES_OUTSIDE_TABLE.LOGIN)
    return buildButton();
  return (
    <div
      className={`${
        props.buttonContainer ? props.buttonContainer : classes.ButtonContainer
      }`}
    >
      {buildButton()}
    </div>
  );
};

const selectActionButton = (
  actionType: ACTION_TYPE,
  badgeContent?: number,
  hasLicense?: boolean
) => {
  switch (actionType) {
    case ACTION_TYPE.FILTER:
      return <FilterListIcon aria-label="FilterListIcon" />;
    case ACTION_TYPE.EXPAND:
    case ACTION_TYPE.CREATE:
      return <AddIcon aria-label="AddIcon" />;
    case ACTION_TYPE.COLLAPSE:
      return <RemoveIcon aria-label="RemoveIcon" />;
    case ACTION_TYPE.RUN:
      return <PlayArrow aria-label="RunIcon" />;
    case ACTION_TYPE.REPLICATE:
      return <FileCopyIcon aria-label="FileCopyIcon" />;
    case ACTION_TYPE.EDIT_MULTIPLE:
    case ACTION_TYPE.EDIT:
      return <EditIcon aria-label="EditIcon" />;
    case ACTION_TYPE.CHANGE_TAG:
      return <PublishedWithChangesIcon aria-label="ChangeTagIcon" />;
    case ACTION_TYPE.LICENSE:
      return (
        <span className="material-icons-outlined" aria-label="LicensedIcon">
          {!hasLicense ? "person_add" : "person_add_disabled"}
        </span>
      );
    case ACTION_TYPE.ARCHIVE_MULTIPLE:
    case ACTION_TYPE.ARCHIVE:
      return <ArchiveIcon aria-label="ArchiveIcon" />;
    case ACTION_TYPE.RESTORE_MULTIPLE:
    case ACTION_TYPE.RESTORE:
      return <RestoreIcon aria-label="RestoreIcon" />;
    case ACTION_TYPE.DELETE_MULTIPLE:
    case ACTION_TYPE.DELETE:
      return <DeleteIcon aria-label="DeleteIcon" />;
    case ACTION_TYPE.RESTORE_VERSION:
      return <RestoreVersionIcon aria-label="RestoreVersionIcon" />;
    case ACTION_TYPE.DOWNLOAD:
      return <DownloadIcon aria-label="DownloadIcon" />;
    case ACTION_TYPE.UPLOAD:
      return <UploadIcon aria-label="UploadIcon" />;
    case ACTION_TYPE.SAVE:
      return <SaveIcon aria-label="SaveIcon" />;
    case ACTION_TYPE.REFRESH:
      return <RefreshIcon aria-label="RefreshIcon" />;
    case ACTION_TYPE.HELP:
      return <HelpIcon aria-label="HelpIcon" />;
    case ACTION_TYPE.DETAIL:
    case ACTION_TYPE.COLUMN_VISIBILITY:
      return <DetailIcon aria-label="DetailIcon" />;
    case ACTION_TYPE.HIDDEN:
      return <HideIcon aria-label="HideIcon" />;
    case ACTION_TYPE.DUPLICATE:
      return <DuplicateIcon aria-label="DuplicateIcon" />;
    case ACTION_TYPE.NOTIFICATION:
      return (
        <Badge
          badgeContent={badgeContent}
          color="primary"
          overlap="rectangular"
        >
          <NotificationsIcon aria-label="NotificationsIcon" />
        </Badge>
      );
    case ACTION_TYPE.STOP:
      return <HighlightOffIcon aria-label="StopIcon" />;
    case ACTION_TYPE.CONTINUE:
      return <PlayArrow aria-label="RunIcon" />;
    case ACTION_TYPE.RESUME:
      return <PlayArrow aria-label="RunIcon" />;
    case ACTION_TYPE.CLOSE:
      return <CloseIcon aria-label="CloseIcon" />;
    case ACTION_TYPE.STRIPE_PORTAL:
      return <PaymentIcon aria-label="PaymentIcon" />;
    default:
      return <div>UNKNOWN ACTION TYPE</div>;
  }
};
const getButtonText = (
  actionType: ACTION_TYPE,
  lang: any,
  hasLicense?: boolean
) => {
  switch (actionType) {
    case ACTION_TYPE.CREATE:
      return lang.genericTerms.create;
    case ACTION_TYPE.LICENSE:
      return !hasLicense
        ? lang.genericTerms.licensed
        : lang.genericTerms.unlicensed;
    case ACTION_TYPE.DOWNLOAD:
      return lang.genericTerms.download;
    case ACTION_TYPE.REFRESH:
      return lang.genericTerms.refresh;
    case ACTION_TYPE.EDIT_MULTIPLE:
    case ACTION_TYPE.EDIT:
      return lang.genericTerms.edit;
    case ACTION_TYPE.DELETE:
      return lang.genericTerms.delete;
    case ACTION_TYPE.RESTORE:
    case ACTION_TYPE.RESTORE_MULTIPLE:
      return lang.genericTerms.restore;
    case ACTION_TYPE.RESTORE_VERSION:
      return lang.genericTerms.restore;
    case ACTION_TYPE.REPLICATE:
      return lang.genericTerms.replicate;
    case ACTION_TYPE.ARCHIVE:
    case ACTION_TYPE.ARCHIVE_MULTIPLE:
      return lang.genericTerms.archive;
    case ACTION_TYPE.STOP:
      return lang.genericTerms.stop;
    case ACTION_TYPE.RESUME:
      return lang.actions.resume;
    case ACTION_TYPE.CONTINUE:
      return lang.actions.continue;
    case ACTION_TYPE.DETAIL:
      return lang.genericTerms.view;
    default:
      return "";
  }
};

export default CustomButton;
