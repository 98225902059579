/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { CSV_ARRAY_SEPARATOR } from "containers/lists/subcategories/items/parseCsvFunctions";
import { ICreateEditViewTeamFormStates } from "containers/teams/components/modals/CreateEditViewTeamForm";
import { IOption } from "model/application/components";
import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "model/entities/HierarchyDependency";
import {
  IGroupInTable,
  ITeamInTable,
  ITeamSelector,
} from "model/entities/Team";

import { TYPE_DISPLAY_NAME } from "./prepareActionsFunctions";

const prepareTeamsForCreateOrEdit = (
  attributes: ICreateEditViewTeamFormStates,
  ts: ITeamSelector[],
  displayView: string,
  metaHierarchy: IMetaHierarchyDependency,
  hierarchy: IHierarchyDependency[]
): any[] => {
  const allTeams: ITeamSelector[] = JSON.parse(JSON.stringify(ts));

  const {
    id,
    active,
    name,

    teams,
    dashboards,
  } = attributes;
  let { mobile_users, workflows, web_users } = attributes;
  let formattedTeams: Omit<ITeamSelector, "created_at" | "created_by">[] = [];
  if (!web_users) web_users = [];
  if (!mobile_users) mobile_users = [];
  if (!workflows) workflows = [];
  if (displayView === "team") {
    // if only 1 team
    const team: ITeamSelector = {
      id: id!,
      active,
      name,
      mobile_users,
      workflows,
      web_users,
      dashboards,
    };
    // add all the "levels" values
    Object.keys(attributes)
      .filter((att) => att.startsWith("level_"))
      .forEach((att) => (team[att] = attributes[att]));
    formattedTeams.push(team);
  } else {
    // case of several teams (a group)
    if (!teams) {
      throw new Error(
        "teams should not be empty in prepareTeamsForCreateOrEdit function"
      );
    }
    const oldName = ts[0][displayView];
    const workflowsToAdd = workflows.filter((e) => !e.undetermined);
    const mobileUsersToAdd = mobile_users.filter((e) => !e.undetermined);
    const webUsersToAdd = web_users.filter((e) => !e.undetermined);
    const dashboardsToAdd = dashboards.filter((e) => !e.undetermined);
    // if only a group of teams
    const teamsInitiallyInTheGroup = allTeams.filter(
      (t) => t[displayView] === oldName
    );
    // add all the selected teams to the list
    formattedTeams = teams!
      .map((t) => allTeams.find((tt) => tt.id === t.key)!)
      .map((fullTeam) => {
        const formattedTeam = {
          id: fullTeam.id,
          name: fullTeam.name,
          dashboards: fullTeam.dashboards,
          mobile_users: fullTeam.mobile_users,
          web_users: fullTeam.web_users,
          workflows: fullTeam.workflows,
          active: fullTeam.active,
        };
        Object.keys(fullTeam)
          .filter((att) => att.startsWith("level_"))
          .forEach((att) => (formattedTeam[att] = fullTeam[att]));
        formattedTeam[displayView] = attributes.name;
        return formattedTeam;
      });
    // check the teams who disappear from this group. We remove them from the group by setting "" to their level value
    const currentTeamIds = teams!.map((t) => t.key);
    const removedTeams = teamsInitiallyInTheGroup.filter(
      (t) => !currentTeamIds.includes(t.id)
    );
    formattedTeams = [
      ...new Set([
        ...formattedTeams,
        ...removedTeams.map((t) => {
          Object.keys(metaHierarchy).forEach((level_idx) => {
            if (
              metaHierarchy[level_idx].level_type_number >=
              metaHierarchy[displayView].level_type_number
            ) {
              t[level_idx] = "";
            }
          });
          return t;
        }),
      ]),
    ];
    // check the new teams in the group. We add them to the group by setting the value to the level
    const originalTeamIds = teamsInitiallyInTheGroup.map((t) => t.id);
    const newTeams = teams!
      .filter((t) => !originalTeamIds.includes(t.key))
      .map((t) => {
        const team = allTeams.find((tt) => tt.id === t.key)!;
        delete (team as any).client_id;
        delete (team as any).created_at;
        return team;
      });

    formattedTeams = [
      ...new Set([
        ...formattedTeams,
        ...newTeams.map((t) => {
          // first remove all the levels below this level
          Object.keys(metaHierarchy).forEach((level_idx) => {
            if (
              metaHierarchy[displayView].level_type_number <
              metaHierarchy[level_idx].level_type_number
            ) {
              t[level_idx] = "";
            }
          });
          t[displayView] = attributes.name;
          // set all levels above this level with the correct one according to the hierarchy
          const fathers = getFatherLevelValues(
            metaHierarchy[displayView].level_type_number,
            attributes.name,
            metaHierarchy,
            hierarchy,
            []
          );
          fathers.forEach((f) => {
            t[f.levelName] = f.levelValue;
          });
          return t;
        }),
      ]),
    ];
    // we go through all the teams edited, we add/remove the elements according to what is needed
    formattedTeams = formattedTeams.map((t) => {
      // if it is a team which has been removed, don't touch it
      if (removedTeams.map((rt) => rt.id).includes(t.id)) {
        return t;
      }
      // remove the element which are no longer in the list
      t.workflows = t.workflows.filter((elem) =>
        workflows.map((e) => e.key).includes(elem.key)
      );
      t.mobile_users = t.mobile_users.filter((elem) =>
        mobile_users.map((e) => e.key).includes(elem.key)
      );
      t.web_users = t.web_users.filter((elem) =>
        web_users.map((e) => e.key).includes(elem.key)
      );
      t.dashboards = t.dashboards.filter((elem) =>
        dashboards.map((e) => e.key).includes(elem.key)
      );
      // be sure all the selected elements are in the list
      const concatOptions = (opts1: IOption[], opts2: IOption[]) => {
        const res = opts1;
        opts2.forEach((o) => {
          if (!opts1.map((op) => op.key).includes(o.key)) {
            res.push(o);
          }
        });
        return res;
      };
      t.workflows = concatOptions(t.workflows, workflowsToAdd);
      t.mobile_users = concatOptions(t.mobile_users, mobileUsersToAdd);
      t.web_users = concatOptions(t.web_users, webUsersToAdd);
      t.dashboards = concatOptions(t.dashboards, dashboardsToAdd);
      return t;
    });
  }
  return formattedTeams.map((t) => {
    const formattedTeam = {
      ...t,
      mobile_users: t.mobile_users.map((e) => e.key),
      web_users: t.web_users.map((e) => e.key),
      workflows: t.workflows.map((e) => e.key),
      dashboards: t.dashboards.map((e) => e.key),
    };

    Object.keys(formattedTeam).forEach((att) => {
      if (formattedTeam[att] === null) delete formattedTeam[att];
    });

    delete formattedTeam["created_at"];

    return formattedTeam;
  });
};

const getFilteredTeams = (
  teams: ITeamSelector[],
  filterParams: any
): ITeamSelector[] => {
  let result: ITeamSelector[] = teams;
  if (!Object.keys(filterParams).includes(FILTER_TAG.TEAMS)) return teams;
  Object.keys(filterParams).forEach((att) => {
    if (att === "_teams") {
      const teamsSelected: string[] = filterParams[FILTER_TAG.TEAMS];
      result = teams.filter((t) => teamsSelected.some((id) => id === t.id));
    }
  });

  return result;
};

const getLevelTypeNumberPerLevelTypeName = (
  levelTypeName: string,
  metaHierarchy: IMetaHierarchyDependency
): number | undefined => {
  const r = Object.keys(metaHierarchy).find(
    (levelIdx) => metaHierarchy[levelIdx].level_type_name === levelTypeName
  )!;
  if (r) {
    return metaHierarchy[r].level_type_number;
  } else {
    return undefined;
  }
};

const getLevelTypeNamePerLevelTypeNumber = (
  levelTypeNumber: number,
  metaHierarchy: IMetaHierarchyDependency
): string | undefined => {
  const r = Object.keys(metaHierarchy).find(
    (levelIdx) => metaHierarchy[levelIdx].level_type_number === levelTypeNumber
  );
  if (r) {
    return metaHierarchy[r].level_type_name;
  } else return undefined;
};

const getFatherLevelValues = (
  levelNumber: number,
  levelValue: string,
  metaHierarchy: IMetaHierarchyDependency,
  hierarchy: IHierarchyDependency[],
  res: { levelName: string; levelValue: string }[]
): { levelName: string; levelValue: string }[] => {
  if (levelNumber === -1) return [];
  const fatherName = hierarchy.find((h) => h.level_name === levelValue)!
    .level_father!;
  if (!fatherName) return res;
  const levelNbOfFather = hierarchy.find(
    (h) => h.level_name === fatherName
  )!.level_type_number;
  const levelIdxOfFather = Object.keys(metaHierarchy).find(
    (levelIdx) => metaHierarchy[levelIdx].level_type_number === levelNbOfFather
  )!;
  res.push({ levelName: levelIdxOfFather, levelValue: fatherName });
  return getFatherLevelValues(
    levelNumber - 1,
    fatherName,
    metaHierarchy,
    hierarchy,
    res
  );
};

const getHeaderDataRows = (viewOptions: IOption[]) => {
  let rowHeader = ["Team ID", "Team Name"];
  // add levels and groups
  viewOptions
    .filter((o) => o.key !== "team")
    .forEach((o) => rowHeader.push(o.label));
  rowHeader = [
    ...rowHeader,
    "Mob user Ids",
    "Mob User Names",
    "Web User Ids",
    "Web User Names",
    "Workflow Ids",
    "Workflow Names",
    "Active",
  ];
  return rowHeader;
};

const getRowsAndCellsFromTeams = (
  teams: ITeamSelector[],
  viewOptions: IOption[]
) => {
  const result = [];
  result.push(getHeaderDataRows(viewOptions));
  teams.forEach((team) => {
    const workflowIds = `${team.workflows
      .map((w) => w.key)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const workflowNames = `${team.workflows
      .map((w) => w.label)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const mobileUserIds = `${team.mobile_users
      .map((u) => u.key)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const mobileUserNames = `${team.mobile_users
      .map((u) => u.label)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const webUserIds = `${team.web_users
      .map((u) => u.key)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const webUserNames = `${team.web_users
      .map((u) => u.label)
      .join(CSV_ARRAY_SEPARATOR)}`;
    let row = [team.id, team.name];
    viewOptions
      .filter((o) => o.key !== "team")
      .forEach((o) => row.push(team[o.key] ? team[o.key] : ""));
    row = [
      ...row,
      mobileUserIds,
      mobileUserNames,
      webUserIds,
      webUserNames,
      workflowIds,
      workflowNames,
      team.active ? "true" : "false",
    ];
    result.push(row);
  });
  return result;
};

const selectTeamsFromGroupSelection = (
  selectedGroup: ITeamInTable | IGroupInTable,
  teams: ITeamSelector[],
  displayView: string
) => {
  let selectedTeams = [];
  switch (displayView) {
    case "team": {
      selectedTeams = teams.filter(
        (t) => t.id === (selectedGroup as ITeamInTable).team_id
      );
      break;
    }
    default: {
      if (selectedGroup.name.includes("/!\\")) {
        selectedTeams = teams.filter(
          (t) => t[displayView] === "" || !t[displayView]
        );
      } else {
        selectedTeams = teams.filter(
          (t) => t[displayView] === selectedGroup.name
        );
      }
    }
  }
  return selectedTeams;
};

const getSelectedObjectsInTeams = (
  teams: ITeamSelector[],
  objectName: "dashboards" | "workflows" | "web_users" | "mobile_users"
): IOption[] => {
  // if no team in list, return empty array
  if (!teams[0] || !teams[0][objectName]) return [];
  // count the number of occurence for the objects in each teams, store it in a map
  const objCountMap = {};
  teams.forEach((t) => {
    t[objectName]
      .filter((e) => e)
      .forEach((o) => {
        if (!objCountMap[o.key]) {
          objCountMap[o.key] = { label: o.label, count: 1 };
        } else {
          objCountMap[o.key].count++;
        }
      });
  });
  // return the list of option. If the number of occurences of an object is not matching the number of team, set undetermined=true
  return Object.keys(objCountMap).map((id) => ({
    key: id,
    label: objCountMap[id].label,
    undetermined: objCountMap[id].count !== teams.length,
  }));
};

export const groupTeams = (
  teams: ITeamSelector[],
  displayView: string
): ICreateEditViewTeamFormStates => {
  let teamOrGroupName;
  const groups = {};
  if (teams[0]) {
    if (displayView === "team") {
      // case of a team
      teamOrGroupName = teams[0].name ? teams[0].name : "";
      // fill the different groups
      Object.keys(teams[0]).forEach((att) => {
        if (att.startsWith("level_")) {
          groups[att] = teams[0][att];
        }
      });
    } else {
      // case of a group
      const display = displayView.split("edit_").join("");
      teamOrGroupName = teams[0][display] ? teams[0][display] : "";
    }
  } else {
    teamOrGroupName = "";
  }
  return {
    ...(displayView === "team" && teams[0] ? { id: teams[0].id } : {}),
    name: teamOrGroupName,
    mobile_users: getSelectedObjectsInTeams(
      teams.filter((t) => t.active),
      "mobile_users"
    ),
    web_users: getSelectedObjectsInTeams(
      teams.filter((t) => t.active),
      "web_users"
    ),
    workflows: getSelectedObjectsInTeams(
      teams.filter((t) => t.active),
      "workflows"
    ),
    dashboards: getSelectedObjectsInTeams(
      teams.filter((t) => t.active),
      "dashboards"
    ),
    ...(displayView === "team" ? groups : {}),
    ...(displayView !== "team"
      ? { teams: teams.map((t) => ({ label: t.name, key: t.id })) }
      : {}),
  };
};

const getLevelIdxFromLevelName = (
  levelName: string,
  metaHierarchy: IMetaHierarchyDependency
) => {
  return Object.keys(metaHierarchy).find(
    (levelIdx) => metaHierarchy[levelIdx].level_type_name === levelName
  );
};

const getLowestLevelNb = (metaHierarchy: IMetaHierarchyDependency) => {
  let result = -1;
  Object.keys(metaHierarchy).forEach((h) => {
    if (result < metaHierarchy[h].level_type_number) {
      result = metaHierarchy[h].level_type_number;
    }
  });
  return result;
};

const isTeamDisplayView = (typeDisplayView: string) =>
  typeDisplayView === TYPE_DISPLAY_NAME.DISPLAY_TEAM;
const isLevelDisplayView = (typeDisplayView: string) =>
  typeDisplayView === TYPE_DISPLAY_NAME.DISPLAY_LEVEL;
const isGroupDisplayView = (typeDisplayView: string) =>
  typeDisplayView === TYPE_DISPLAY_NAME.DISPLAY_GROUP;

export {
  getFatherLevelValues,
  getFilteredTeams,
  getLevelIdxFromLevelName,
  getLevelTypeNamePerLevelTypeNumber,
  getLevelTypeNumberPerLevelTypeName,
  getLowestLevelNb,
  getRowsAndCellsFromTeams,
  getSelectedObjectsInTeams,
  isGroupDisplayView,
  isLevelDisplayView,
  isTeamDisplayView,
  prepareTeamsForCreateOrEdit,
  selectTeamsFromGroupSelection,
};
