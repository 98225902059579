import { ISkuDiscount } from "fieldpro-tools/src/types";

import { IAction } from "redux/store/model";

import { FetchDiscountsSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IDiscountsState {
  discounts: ISkuDiscount[];
  isFetchingDiscounts: boolean;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_DISCOUNTS_SUCCESS: {
      const { discounts } = action as FetchDiscountsSuccessAction;

      return {
        ...state,
        isFetchingDiscounts: false,
        discounts,
      };
    }

    case types.FETCH_DISCOUNTS_BEGIN:
      return {
        ...state,
        isFetchingDiscounts: true,
      };
    case types.FETCH_DISCOUNTS_FAILURE:
      return {
        ...state,
        isFetchingDiscounts: false,
      };
    default:
      return state;
  }
}
