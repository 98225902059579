import _ from "lodash";

import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import { ICustomField } from "model/application/DynamicObjects";
import { IActivityReport } from "model/entities/Workflow";

/**
 * "Flatten“ the activity report's custom field values, to match the mobile report format.
 *
 * Example:
 *  const customFieldValues = [{ key: "field1", value: "value1" }, { key: "field2", value: ["value2"] }, ...]
 *  -> { field1: "value1", field2: ["value2"], ... }
 */
const flattenActivityReport = (report?: IActivityReport) => {
  if (!report) {
    return null;
  }
  const customFieldValues = report.custom_field_values as ICustomField[];
  const customFieldValuesAsObject = _.reduce(
    customFieldValues,
    (memo, { key, value }) => {
      return { ...memo, [key]: value };
    },
    {}
  );
  return {
    ...report,
    ...customFieldValuesAsObject,
  };
};

export const flattenAugmentedActivityReport = (
  report?: IAugmentedActivityReport
) => {
  if (!report) {
    return null;
  }
  const customFieldValues = report.customFieldValues as ICustomField[];
  const customFieldValuesAsObject = _.reduce(
    customFieldValues,
    (memo, { key, value }) => {
      return { ...memo, [key]: value };
    },
    {}
  );
  return {
    ...report,
    ...customFieldValuesAsObject,
  };
};

export default flattenActivityReport;
