import FileDownloadIcon from "@mui/icons-material/FileDownload";

import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicDownloadIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <FileDownloadIcon />
    </SmallIconButton>
  );
};

export default BasicDownloadIcon;
