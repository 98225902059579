import { CircularProgress } from "@material-ui/core";

import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicLoadingIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <CircularProgress style={{ width: "24px", height: "24px" }} />
    </SmallIconButton>
  );
};

export default BasicLoadingIcon;
