import { useState } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";

import { List } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import { isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CustomDialogForm from "components/Dialog/CustomDialogForm";
import CustomSearch from "components/Search/CustomSearch";
import {
  getActionsAvailableSelector,
  getPrivilege,
} from "containers/authentication/redux/selector";
import { userCanViewClientTab } from "containers/clients/utils";
import { caseInsensitiveSortBy } from "containers/workflows/utils/caseInsensitiveSortBy";
import { getAppVersion } from "utils/utils";

const ClientMenu = ({
  onCloseMenu,
  selectedClient,
  onClientChange,
  clientOptions,
  onClickLabel,
  lang,
}) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [clientId, setClientId] = useState(selectedClient.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [tabMenu, setTabsMenu] = useState(false);

  const actionsAvailable = useSelector(getActionsAvailableSelector);
  const userRole = useSelector(getPrivilege);

  const label = lang.containers.clients.subCategories.clients.createEditModal;

  let menuItems = [
    {
      tabId: "main",
      label: label.section.mainTab,
    },
    {
      tabId: "dashboard",
      label: label.section.dashboardTab,
    },
    {
      tabId: "teams",
      label: label.section.teamsTab,
    },
    {
      tabId: "users",
      label: label.section.usersTab,
    },
    {
      tabId: "management",
      label: label.section.clientManagementTab,
    },
    // {
    //   tabId: "plans",
    //   label: label.section.automatedBillingTab,
    // },
    {
      tabId: "usage",
      label: label.section.usageTab,
    },
    {
      tabId: "developer",
      label: label.section.developerTab,
    },
    {
      tabId: "environment_variables",
      label: label.section.environmentVariablesTab,
    },
  ];

  menuItems = _.filter(menuItems, ({ tabId }) => {
    return userCanViewClientTab({
      tabId,
      client: selectedClient,
      role: userRole,
    });
  });

  const handleModalOpen = (tabmenu) => {
    setModalToggle(true);
    setTabsMenu(tabmenu);
    setClientId(selectedClient.id);
  };

  const handleModalClose = () => {
    setClientId(selectedClient.id);
    setModalToggle(false);
    onClickLabel();
  };

  const handleSelectNewClient = (clientId) => {
    setClientId(clientId);
  };

  const handleSearchChange = (termSearched) => {
    if (termSearched != null) {
      setSearchTerm(termSearched);
    }
  };

  const getClientAccordingSearchTerm = () => {
    const clientList = [];
    for (const option of clientOptions) {
      if (option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        clientList.push(option);
      }
    }
    return clientList;
  };

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <div /* ClickAwayListener needs a child that can receive a ref */>
        <Paper
          sx={{
            width: 240,
            maxWidth: "100%",
            backgroundColor: "#fefefe",
            position: "fixed",
            top: 70,
            left: 20,
          }}
        >
          <MenuList>
            <List
              subheader={
                <ListSubheader component="div" id="organization-setting">
                  {lang.mainLayout.navbar.menu.organisation}
                </ListSubheader>
              }
            >
              {(!isOptimetriksRole(selectedClient.profile) &&
                selectedClient.payments_overdue) ||
              !actionsAvailable.FETCH_CLIENT_SETTINGS
                ? null
                : _.map(menuItems, (item) => (
                    <Link
                      key={item.tabId}
                      to={`/clients/${selectedClient.id}?tabId=${item.tabId}`}
                      style={{ color: "#000" }}
                    >
                      <MenuItem onClick={onCloseMenu}>
                        <ListItemText>{item.label}</ListItemText>
                      </MenuItem>
                    </Link>
                  ))}

              <a
                href={"https://learn.fieldproapp.com/en/"}
                target="_blank"
                style={{ color: "#000" }}
                rel="noreferrer"
              >
                <MenuItem onClick={onCloseMenu}>
                  <ListItemText>{label.helpCenter.title}</ListItemText>
                </MenuItem>
              </a>
            </List>

            {(clientOptions || []).length > 1 ? (
              <List
                subheader={
                  <ListSubheader component="div" id="organization-setting">
                    {lang.mainLayout.navbar.menu.switchClient}
                  </ListSubheader>
                }
              >
                <MenuItem>
                  <ListItemText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        onClick={onCloseMenu}
                        style={{
                          whiteSpace: "normal",
                        }}
                      >
                        {selectedClient.name}
                      </div>
                      <div>
                        <a
                          data-testid="change-client-button"
                          style={{ textDecoration: "none", color: "#124e5d" }}
                          onClick={() => {
                            handleModalOpen(false);
                          }}
                        >
                          {lang.mainLayout.navbar.menu.changeClient}
                        </a>
                      </div>
                    </div>
                  </ListItemText>
                </MenuItem>
              </List>
            ) : null}

            <span
              style={{
                fontSize: "10px",
                letterSpacing: "0.42px",
                height: "12px",
                margin: "24px 29px 0",
              }}
            >
              App version: {getAppVersion()}
            </span>
          </MenuList>
        </Paper>

        {!tabMenu && (
          <CustomDialogForm
            isOpen={modalToggle}
            onClose={handleModalClose}
            onCancel={handleModalClose}
            title={lang.containers.clients.subCategories.clients.chooseClient}
            onConfirmAction={() => {
              onClientChange(clientId);
              setModalToggle(false);
              onCloseMenu();
            }}
            confirmBtnText={
              lang.mainLayout.navbar.menu.modal.changeClientButton
            }
            lang={lang}
            size={"sm"}
            alignTop
          >
            <div>
              <CustomSearch
                smallDisplay={false}
                label={lang.mainLayout.navbar.menu.modal.searchClient}
                onChange={handleSearchChange}
                fullWidth
              />
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: 400,
                  marginTop: "10px",
                }}
              >
                {searchTerm.length > 0
                  ? caseInsensitiveSortBy(
                      getClientAccordingSearchTerm(),
                      "label"
                    ).map((sectionId, key) => (
                      <ListItem
                        key={key}
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            clientId === sectionId.key ? "#e6eced" : undefined,
                        }}
                      >
                        <ListItemText
                          primary={sectionId.label}
                          key={sectionId.label}
                          onClick={() => handleSelectNewClient(sectionId.key)}
                        />
                      </ListItem>
                    ))
                  : caseInsensitiveSortBy(clientOptions, "label").map(
                      (sectionId, key) => (
                        <ListItem
                          key={key}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              clientId === sectionId.key
                                ? "#e6eced"
                                : undefined,
                          }}
                        >
                          <ListItemText
                            primary={sectionId.label}
                            key={sectionId.label}
                            onClick={() => handleSelectNewClient(sectionId.key)}
                          />
                        </ListItem>
                      )
                    )}
              </List>
            </div>
          </CustomDialogForm>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ClientMenu;
