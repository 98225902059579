import {
  IActivity as IBEActivity,
  IActivityReport as IBEActivityReport,
} from "fieldpro-tools";
import _ from "lodash";

import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import {
  IActivity,
  IActivityReportInTable,
  IQuestion,
} from "model/entities/Workflow";

import { resolveMetaExpressionInString } from "../../workflows/utils/metaResolver";

interface IResolveMEInActivityReport {
  questionsSchema: IQuestion[];
  report: IActivityReportInTable;
  activity?: IActivity;
}

export const resolveDefaultValuesMEInActiveReport = ({
  questionsSchema,
  report,
  activity,
}: IResolveMEInActivityReport) => {
  // resolve any default fields with metaexpression, so that the resolved answer is saved to db
  // even if user did not make any changes, ie when the report field is undefined
  const resolvedReport = { ...report };
  _.each<IQuestion>(questionsSchema, (customField) => {
    const tagKey = getTagKey(customField);
    const fieldTag = customField[tagKey];

    if (!resolvedReport[fieldTag] && customField.default_value) {
      resolvedReport[fieldTag] = resolveMetaExpressionInString(
        customField.default_value,
        {
          activity_report: report as IBEActivityReport,
          activity: activity as IBEActivity,
        }
      );
    }
  });
  return resolvedReport;
};

export default resolveDefaultValuesMEInActiveReport;
