import { useState } from "react";

import { Box } from "@material-ui/core";

import CircularLoaderComponent from "components/Progress/CustomCircularProgress";
import useTranslations from "hooks/useTranslations";

import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogContent,
  BasicDialogTitle,
} from "../BasicDialog";

interface ILoadingDialog {
  title?: string;
  onClose?: () => void;
  contentHeight?: number;
}
const LoadingDialog = ({
  title,
  onClose,
  contentHeight = 400,
}: ILoadingDialog) => {
  const _lang = useTranslations();
  const [open, setOpen] = useState(true);

  const onCloseWrapped = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <BasicDialog open={open} onClose={onClose} minWidth={800} maxWidth={800}>
      <BasicDialogTitle>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{title ?? ""}</span>
          <BasicCloseIcon onClick={onCloseWrapped} />
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent>
        <Box
          height={contentHeight}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularLoaderComponent />
        </Box>
      </BasicDialogContent>
    </BasicDialog>
  );
};

export default LoadingDialog;
