import { STEP_TYPE } from "fieldpro-tools";
import _ from "lodash";

import {
  COLUMN_TYPE,
  CUSTOM_FIELD_TYPE,
  IActivity,
  IWorkflow,
  REPORT_PREREQUISITE,
} from "model/entities/Workflow";

export const questionTypeToColumnType = (
  questionType: CUSTOM_FIELD_TYPE | undefined
): COLUMN_TYPE => {
  switch (questionType) {
    case CUSTOM_FIELD_TYPE.GPS:
      return COLUMN_TYPE.STRING; // should not be important... To check
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.COMPUTE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
      return COLUMN_TYPE.FLOAT;
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
    case CUSTOM_FIELD_TYPE.RATING:
      return COLUMN_TYPE.INTEGER;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      return COLUMN_TYPE.STRING;
    default:
      return COLUMN_TYPE.STRING;
  }
};

export const questionTypeToColumnTypeForConditionAndOperation = (
  questionType: CUSTOM_FIELD_TYPE | undefined
): COLUMN_TYPE => {
  switch (questionType) {
    case CUSTOM_FIELD_TYPE.GPS:
      return COLUMN_TYPE.STRING;
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.COMPUTE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
      return COLUMN_TYPE.FLOAT;
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
      return COLUMN_TYPE.INTEGER;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      return COLUMN_TYPE.BOOLEAN;
    default:
      return COLUMN_TYPE.STRING;
  }
};

export const attributeTypeToQuestionType = (
  attributeType: CUSTOM_FIELD_TYPE
): CUSTOM_FIELD_TYPE | undefined => {
  switch (attributeType) {
    case CUSTOM_FIELD_TYPE.BOOLEAN:
      return undefined;
    case CUSTOM_FIELD_TYPE.TEXT:
      return CUSTOM_FIELD_TYPE.TEXT;
    case CUSTOM_FIELD_TYPE.DECIMAL:
      return CUSTOM_FIELD_TYPE.DECIMAL;
    case CUSTOM_FIELD_TYPE.INTEGER:
      return CUSTOM_FIELD_TYPE.INTEGER;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
      return CUSTOM_FIELD_TYPE.SINGLE_CHOICE;
    case CUSTOM_FIELD_TYPE.PICTURE:
      return CUSTOM_FIELD_TYPE.PICTURE;
    case CUSTOM_FIELD_TYPE.GPS:
      return CUSTOM_FIELD_TYPE.GPS;
    case CUSTOM_FIELD_TYPE.SIGNATURE:
      return CUSTOM_FIELD_TYPE.SIGNATURE;
    case CUSTOM_FIELD_TYPE.DATE_PICKER:
      return CUSTOM_FIELD_TYPE.DATE_PICKER;
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      return CUSTOM_FIELD_TYPE.PHONE_NUMBER;
    default:
      return CUSTOM_FIELD_TYPE.TEXT;
  }
};

export const attributeTypeToColumnType = (
  attributeType: CUSTOM_FIELD_TYPE
): COLUMN_TYPE | undefined => {
  return questionTypeToColumnType(attributeTypeToQuestionType(attributeType));
};

export const attributeTypeToColumnTypeForConditionAndOperation = (
  attributeType: CUSTOM_FIELD_TYPE
): COLUMN_TYPE | undefined => {
  return questionTypeToColumnTypeForConditionAndOperation(
    attributeTypeToQuestionType(attributeType)
  );
};

export const activityNeedsCustomer = (activity?: IActivity) => {
  return activity?.report_prerequisite !== REPORT_PREREQUISITE.NO_NEED;
};

export const isCustomerListAttached = (activity?: IActivity) => {
  const isCustomerListAttached =
    activity?.questionnaire.questions.find(
      (q) => q.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST
    )?.list_id === "customer";
  return isCustomerListAttached;
};

interface IWorkflowNeedsCustomer {
  workflow: IWorkflow;
  activities: IActivity[];
}

export const workflowNeedsCustomer = ({
  workflow,
  activities,
}: IWorkflowNeedsCustomer) => {
  const activitySteps = _.filter(workflow.steps, { type: STEP_TYPE.ACTIVITY });
  const workflowActivities = _.compact(
    _.map(activitySteps, (step) => {
      return _.find(activities, { id: step.schema_id });
    })
  );
  return _.some(workflowActivities, activityNeedsCustomer);
};
export const workflowActivitiesNeedingCustomerList = ({
  workflow,
  activities,
}: IWorkflowNeedsCustomer) => {
  const activitySteps = _.filter(workflow.steps, { type: STEP_TYPE.ACTIVITY });
  const workflowActivities = _.compact(
    _.map(activitySteps, (step) => {
      return _.find(activities, { id: step.schema_id });
    })
  );
  return _.filter(workflowActivities, activityNeedsCustomer);
};
