import { IFolder } from "model/entities/Client";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

/**
 * Action creator return the type of action that informs reducer a folder is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createFolderBeginActionCreator(): IAction {
  return {
    type: types.CREATE_FOLDER_BEGIN,
  };
}

/**
 * FOLDER failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createFolderFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_FOLDER_FAILURE,
    error,
  };
}

interface ICreateFolderSuccessActionCreator extends IAction {
  folder: IFolder;
}

/**
 * FOLDER create success action is used to inform reducer of successful ajax request
 * @param {Object} folder Object with FOLDER information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createFolderSuccessActionCreator(
  folder: IFolder
): ICreateFolderSuccessActionCreator {
  return {
    type: types.CREATE_FOLDER_SUCCESS,
    folder,
  };
}

interface IUpdateFolderSuccessActionCreator extends IAction {
  folder: IFolder;
}

/**
 * FOLDER update success action is used to inform reducer of successful ajax request
 * @param {Object} folder Object with FOLDER information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateFolderSuccessActionCreator(
  folder: IFolder
): IUpdateFolderSuccessActionCreator {
  return {
    type: types.UPDATE_FOLDER_SUCCESS,
    folder,
  };
}

/**
 * FOLDER update begin action is used to inform reducer of beginning ajax request
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateFolderBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_FOLDER_BEGIN,
  };
}

/**
 * FOLDER update failure action is used to inform reducer of failed ajax request
 * @return {Object} Object with type of action for reducer to handle
 * @param {Object} error Object with error information
 * */
export function updateFolderFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_FOLDER_FAILURE,
    error,
  };
}
/**
 * Delete FOLDER action used to inform reducer of beginning ajax request to delete a FOLDER
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteFolderBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_FOLDER_BEGIN,
  };
}

interface IDeleteFolderSuccessActionCreator extends IAction {
  id: string;
}

/**
 * Delete FOLDER action used to inform reducer of successful ajax request to delete a FOLDER
 * @param {String} id Id of the FOLDER to delete
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteFolderSuccessActionCreator(
  id: string
): IDeleteFolderSuccessActionCreator {
  return {
    type: types.DELETE_FOLDER_SUCCESS,
    id,
  };
}

/**
 * Delete FOLDER action used to inform reducer of failed ajax request to delete a FOLDER
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteFolderFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_FOLDER_FAILURE,
    error,
  };
}

/**
 * Fetch FOLDER begin action, is used to dispatch action that triggers a 'loading' progress on the
 * redux store
 * @return {Object} Object with type of action
 * */
export function fetchFoldersBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_FOLDERS_BEGIN,
  };
}
/**
 * Fetch FOLDER failure action is dispatched when there was an error fetching all folders from the redux store
 * @param {Object} error Error info object
 * @return {Object} Object with type of action and error object
 * */
export function fetchFoldersFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_FOLDERS_FAILURE,
    error,
  };
}
interface IFetchFoldersSuccessActionCreator extends IAction {
  folders: IFolder[];
}

/**
 * Fetch all folder success action, which is dispatched when there was a successful retrieval of folders from the API
 * or redux store
 * @param {Array} folders An array of FOLDER objects
 * @return {Object} Object Object with type of action and the array of FOLDER objects
 * */
export function fetchFoldersSuccessActionCreator(
  folders: IFolder[]
): IFetchFoldersSuccessActionCreator {
  return {
    type: types.FETCH_FOLDERS_SUCCESS,
    folders,
  };
}

export interface IArchiveFolderSuccessAction extends IAction {
  ids: string[];
}
export function archiveFolderBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_FOLDER_BEGIN,
  };
}
export function archiveFolderFailureActionCreator(error: any): IActionError {
  return {
    type: types.ARCHIVE_FOLDER_FAILURE,
    error,
  };
}
export function archiveFolderSuccessActionCreator(
  foldersIds: string[]
): IArchiveFolderSuccessAction {
  return {
    type: types.ARCHIVE_FOLDER_SUCCESS,
    ids: foldersIds,
  };
}

export interface IRestoreFolderSuccessAction extends IAction {
  ids: string[];
}
export function restoreFolderBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_FOLDER_BEGIN,
  };
}
export function restoreFolderFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESTORE_FOLDER_FAILURE,
    error,
  };
}
export function restoreFolderSuccessActionCreator(
  ids: string[]
): IRestoreFolderSuccessAction {
  return {
    type: types.RESTORE_FOLDER_SUCCESS,
    ids,
  };
}
