import _ from "lodash";

import { IList, IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import { getCategoryTagKey } from "./getQuestionColumns";

export function getListCategories(
  list: IList,
  customField: IQuestion | IListSchema
) {
  const defaultSkuCategory = _.find(list.schema, {
    column_tag: "_category",
  });

  const categoryKey = getCategoryTagKey(customField);

  if (
    customField.hasOwnProperty(categoryKey) &&
    !_.isUndefined(customField[categoryKey])
  ) {
    const currentCategory = _.find(list.schema, {
      column_tag: customField[categoryKey],
    });
    if (currentCategory) {
      return _.compact(currentCategory.options);
    } else {
      if (list.id === "sku") {
        return _.compact(defaultSkuCategory?.options);
      } else {
        return [];
      }
    }
  } else {
    if (list.id === "sku") {
      return _.compact(defaultSkuCategory?.options);
    } else {
      return [];
    }
  }
}
