import Box from "@material-ui/core/Box";

import CellContent from "components/Table/Cell/CellContent";
import { TColumnType, TRowType } from "components/Table/model";
import SimpleTable from "components/Table/SimpleTable";

interface IMatrixSummaryView {
  rows: TRowType[];
  columns: TColumnType[];
}
function MatrixSummaryView({ columns, rows }: IMatrixSummaryView) {
  return (
    <Box>
      <SimpleTable columns={columns} rows={rows} CellContent={CellContent} />
    </Box>
  );
}

export default MatrixSummaryView;
