import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import styles from "./styles";

interface ICustomTooltipProps extends TooltipProps {}

const useStyles = makeStyles(styles as any);

export const CustomTooltip: React.FunctionComponent<ICustomTooltipProps> = ({
  title,
  disableFocusListener,
  disableHoverListener,
  placement,
  children,
  PopperProps,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: classes.Tooltip }}
      title={
        <React.Fragment>
          {<div dangerouslySetInnerHTML={{ __html: title as string }}></div>}
        </React.Fragment>
      }
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      placement={placement}
      PopperProps={PopperProps}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default CustomTooltip;
