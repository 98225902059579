import React, { useState } from "react";

import { Fab } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import HelpIcon from "@material-ui/icons/HelpOutline";
import MoreIcon from "@material-ui/icons/More";

import styles from "./styles";

const useStyles = makeStyles(styles as any);

interface ICustomTooltipWrapperProps extends Omit<TooltipProps, "title"> {
  errorMessage?: string;
  warningMessage?: string;
  title?: string;
  inCell?: boolean;
  cellOverflowed?: boolean;
}

export const CustomTooltipWrapper: React.FunctionComponent<
  ICustomTooltipWrapperProps
> = ({
  errorMessage,
  warningMessage,
  children,
  title,
  placement,
  inCell,
  cellOverflowed,
}) => {
  const classes = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getContent = () => {
    return (
      <div>
        <div
          className={
            (title || (errorMessage && errorMessage.length)) && !cellOverflowed
              ? classes.Content
              : ""
          }
        >
          {cellOverflowed ? (
            <div className={classes.CellContentOverflowed}>{children}</div>
          ) : (
            <div>{children}</div>
          )}
        </div>
        <div>
          <Fab
            className={
              inCell ? classes.TooltipButtonCell : classes.TooltipButton
            }
            onMouseEnter={(e) => {
              e.stopPropagation();
              setTooltipOpen(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setTooltipOpen(false);
            }}
            style={{ zIndex: 1 }}
          >
            {errorMessage && errorMessage.length && (
              <WarningIcon className={classes.TooltipButtonIconWithError} />
            )}
            {!inCell &&
              title &&
              (!errorMessage || !errorMessage.length) &&
              (!warningMessage || !warningMessage.length) && (
                <HelpIcon className={classes.TooltipButtonIcon} />
              )}
            {!inCell &&
              (!errorMessage || !errorMessage.length) &&
              warningMessage &&
              warningMessage.length && (
                <WarningIcon className={classes.TooltipButtonIconWithWarning} />
              )}
            {inCell &&
              title &&
              cellOverflowed &&
              (!errorMessage || !errorMessage.length) && (
                <MoreIcon className={classes.TooltipButtonIcon} />
              )}
          </Fab>
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        classes={{ tooltip: classes.Tooltip }}
        title={
          <>
            {<div dangerouslySetInnerHTML={{ __html: title as string }}></div>}
            {errorMessage && errorMessage.length && (
              <div className={classes.ErrorMessage}>{errorMessage}</div>
            )}
            {(!errorMessage || !errorMessage.length) &&
              warningMessage &&
              warningMessage.length && (
                <div className={classes.WarningMessage}>{warningMessage}</div>
              )}
          </>
        }
        placement={placement ? placement : "bottom-end"}
        onMouseLeave={() => setTooltipOpen(false)}
        open={tooltipOpen}
        disableFocusListener
        disableTouchListener
      >
        {getContent()}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CustomTooltipWrapper;
