import LoadingDialog from "components/Dialog/LoadingDialog";
import CustomerLoader from "containers/customers/subcategories/map/CustomerDialog/CustomerLoader";

import CustomerDialog from "./CustomerDialog";

interface ICustomerDialogConnected {
  customerId: string;
  onClose: () => void;
}

/**
 * "Connected" version of the CustomerDialog, i.e it fetches the customer from the back-end
 */
const CustomerDialogConnected = ({
  customerId,
  onClose,
}: ICustomerDialogConnected) => {
  return (
    <CustomerLoader
      customerId={customerId}
      render={({ loading, customer }) => {
        if (loading) {
          return <LoadingDialog onClose={onClose} />;
        }
        if (!customer) {
          return null;
        }
        return <CustomerDialog customer={customer} onClose={onClose} />;
      }}
    />
  );
};

export default CustomerDialogConnected;
