import { makeStyles } from "@material-ui/core";

import { White } from "assets/colors";

const styles = {
  GeoTrackingMarker: {
    backgroundSize: "100%",
    background:
      'no-repeat center url("https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2_hdpi.png")',
    width: "30px",
    height: "50px",
    paddingTop: "6px",
    cursor: "pointer",
  },
  GeoTrackingMarkerPosition: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "center",
    display: "block",
    color: White,
  },
} as const;

interface IGeoTrackingMarkerProps {
  position: number;
  onClick: () => void;
}

const useStyles = makeStyles(styles);

const GeoTrackingMarker: React.FunctionComponent<IGeoTrackingMarkerProps> = ({
  position,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.GeoTrackingMarker} onClick={onClick}>
      <span className={classes.GeoTrackingMarkerPosition}>{position}</span>
    </div>
  );
};

export default GeoTrackingMarker;
