import { useSelector } from "react-redux";

import { getLang } from "containers/authentication/redux/selector";
import * as lang from "lang";

export const useTranslations = () => {
  const locale = useSelector(getLang);
  return lang[locale];
};

export default useTranslations;
