import { DETAILED_ACTION_TYPE, DETAILED_OBJECT_CATEGORY } from "fieldpro-tools";
import _ from "lodash";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { getDetailedActionName } from "model/application/ActionCode";
import { IDashboard } from "model/entities/Dashboard";
import { ITeamSelector } from "model/entities/Team";

interface IRunQuery {
  dashboard: IDashboard;
  query: any;
  teams: ITeamSelector[];
  actionsAvailable: object;
  computeDashboard: any;
  callback?: () => any;
  degradedModeKpis?: string[];
}

// FIXME: change "any" to a better candidate
const runQuery = async ({
  dashboard,
  query,
  callback,
  actionsAvailable,
  teams,
  computeDashboard,
  degradedModeKpis,
}: IRunQuery) => {
  if (
    actionsAvailable.hasOwnProperty("ACCESS_ALL_RESOURCES") &&
    !actionsAvailable["ACCESS_ALL_RESOURCES"]
  ) {
    if (!query[FILTER_TAG.TEAMS] || query[FILTER_TAG.TEAMS].length === 0) {
      query[FILTER_TAG.TEAMS] = _.map(teams, "id");
    }
  }

  return computeDashboard(
    getDetailedActionName(
      DETAILED_ACTION_TYPE.RUN,
      DETAILED_OBJECT_CATEGORY.DASHBOARD
    ),
    dashboard,
    query,
    teams.length,
    callback,
    degradedModeKpis
  );
};

export default runQuery;
