import TLang from "model/application/Lang";

interface IGetAddVisitToolTip {
  lang: TLang;
  isManager: boolean;
  isUserSelected: boolean;
}
export function getAddVisitToolTip({
  isManager,
  isUserSelected,
  lang,
}: IGetAddVisitToolTip) {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;
  if (isUserSelected) return "";

  return isManager
    ? inputVisitLang.customMessage.disabledMessageNoUserSelected
    : inputVisitLang.customMessage.disabledMessage;
}
