import * as turf from "@turf/turf";
import { FeatureCollection } from "geojson";
import _ from "lodash";

export const getBboxSafe = (collection?: FeatureCollection) => {
  if (_.isEmpty(collection?.features)) {
    return null;
  }
  try {
    const bbox = turf.bbox(collection);
    if (_.includes(bbox, Infinity) || _.includes(bbox, -Infinity)) {
      return undefined;
    }
    return bbox;
  } catch (e) {
    console.warn(e);
  }
};
