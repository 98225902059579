import _, { ListIterateeCustom } from "lodash";

/**
 * will update the item in array if it exists else will push the item in array
 * @param array the array in which item needs to be updated
 * @param item  the item which needs to be updated
 * @param predicate the predicate to find the item in array
 * @param pushIfNotExists if true will push the item in array if it does not exists
 * @returns a new copy of the array with updated item
 */
export function updateItemInArray<T>(
  array: T[],
  item: T,
  predicate: ListIterateeCustom<T, boolean>,
  pushIfNotExists = true
) {
  const arrayCopy = _.clone(array);
  if (!_.isUndefined(_.find(arrayCopy, predicate))) {
    const index = _.findIndex(arrayCopy, predicate);
    arrayCopy[index] = item;
  } else if (pushIfNotExists) {
    arrayCopy.push(item);
  }
  return arrayCopy;
}
