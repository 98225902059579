/**
 * @notes: reducer reducer
 */

import { IJobScript } from "model/entities/Job";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareScriptsForFrontend } from "./utils";

export interface IJobScriptState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  isRunning: boolean;
  isFetchingJobHistory: boolean;
  selectedScript: IJobScript | null;
  scripts: IJobScript[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_SCRIPT_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_SCRIPT_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_SCRIPT_SUCCESS: {
      const { script } = action as actionCreators.ICreateScriptSuccessAction;

      return {
        ...state,
        isCreating: false,
        scripts: insertNewItemToArr(state.scripts, script),
      };
    }

    case types.UPDATE_SCRIPT_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_SCRIPT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_SCRIPT_SUCCESS: {
      const { script } = action as actionCreators.IUpdateScriptSuccessAction;

      const scripts = state.scripts.map((s) =>
        s.id === script.id ? script : s
      );

      return {
        ...state,
        isUpdating: false,
        scripts,
      };
    }

    case types.DELETE_SCRIPT_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_SCRIPT_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_SCRIPT_SUCCESS: {
      const { id } = action as actionCreators.IDeleteScriptSuccessAction;

      const scripts = state.scripts.filter((s) => s.id !== id);

      return {
        ...state,
        isDeleting: false,
        scripts,
      };
    }

    case types.FETCH_ALL_SCRIPTS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_SCRIPTS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_SCRIPTS_SUCCESS: {
      const { scripts } = action as actionCreators.IFetchScriptsSuccessAction;
      return {
        ...state,
        isFetchingAll: false,
        scripts: prepareScriptsForFrontend(scripts),
      };
    }
    case types.RUN_SCRIPT_JOB_BEGIN:
      return {
        ...state,
        isRunning: true,
      };

    case types.RUN_SCRIPT_JOB_FAILURE:
      return {
        ...state,
        isRunning: false,
        errors: updateErrorsList(state, action),
      };

    case types.RUN_SCRIPT_JOB_SUCCESS: {
      return {
        ...state,
        isRunning: false,
      };
    }
    default:
      return state;
  }
}
