import { push } from "react-router-redux";
import { Action } from "redux";

import INotification, {
  TypeNotification,
} from "model/application/Notification";

import * as types from "../actionTypes/appActionTypes";

/**
 * Query registered request action is dispatched when query to check if the current user
 * has accepted Terms and Conditions
 * @param {String} pathname Route name
 * */
export function redirectToPage(pathname: string) {
  push(pathname);
}

interface IFirstAppLoginAction extends Action {
  isFirstLogin: boolean;
}
interface IAppAddNotificatioAction extends Action {
  notification: INotification;
}
interface IAppRemoveNotificatioAction extends Action {
  id: string;
  typeNotification: TypeNotification;
}

/**
 * Action creator creating an action that is dispatched indicating this is the first
 * app login
 * @param {Boolean} isFirstLogin Boolean value indicating whether this is the first login
 * @return {Object}
 * @returns {Object} Action object
 */
export const firstAppLoginAction = (
  isFirstLogin: boolean
): IFirstAppLoginAction => ({
  type: types.APP_FIRST_LOGIN,
  isFirstLogin,
});

export const appAddNotification = (
  notification: INotification
): IAppAddNotificatioAction => ({
  type: types.APP_ADD_NOTIFICATION,
  notification,
});

export const appCleanNotification = (): Action => ({
  type: types.APP_CLEAN_NOTIFICATION,
});

export const appRemoveNotification = (
  id: string,
  typeNotification: TypeNotification
): IAppRemoveNotificatioAction => ({
  type: types.APP_REMOVE_NOTIFICATION,
  typeNotification,
  id,
});

export const openWarningModalActionCreator = (params: {
  title: string;
  description: string;
  onConfirm?: any;
}) => ({
  params,
  type: types.OPEN_WARNING_MODAL,
});

export const closeWarningModalActionCreator = () => ({
  type: types.CLOSE_WARNING_MODAL,
});
