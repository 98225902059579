import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";

import { GreyState } from "assets/colors";

interface ITablePaginationDescriptionProps {
  description: string;
  tooltip?: string;
}
const TablePaginationDescription = ({
  description,
  tooltip,
}: ITablePaginationDescriptionProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        padding: "0 10px",
      }}
    >
      <span>{description}</span>
      {tooltip && (
        <Tooltip title={tooltip} arrow placement="top-start">
          <HelpIcon
            style={{
              color: GreyState,
              width: "24px",
              height: "24px",
            }}
            data-testid={"tooltip-help-icon"}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default TablePaginationDescription;
