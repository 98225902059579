import _ from "lodash";

const getValueAsString = (value: any) => {
  if (_.isString(value)) {
    return value;
  }

  if (_.isNumber(value)) {
    return String(value);
  }

  if (!value) {
    return "";
  }

  let valueAsString;
  try {
    valueAsString = JSON.stringify(value);
    return valueAsString;
  } catch {
    return "";
  }
};

export default getValueAsString;
