export enum PICTURE_TYPE {
  PICTURE = "PICTURE",
  SIGNATURE = "SIGNATURE",
}
export enum PICTURE_SOURCE {
  WEB = "WEB",
  MOBILE = "MOBILE",
}
export enum PICTURE_ACTIONS {
  UPLOAD_FILE_FOR_ITEM = "UPLOAD_FILE_FOR_ITEM",
  UPLOAD_FILE_FOR_SUBMISSION = "UPLOAD_FILE_FOR_SUBMISSION",
}

export type TPictureBaseInformation = {
  id: string;
  url: string;
  tag: string;
  timestamp: string;
  size?: number;
  type?: PICTURE_TYPE;
  user_id: string;
  label?: string;
  sublabel?: string;
  source?: PICTURE_SOURCE;
  uniqueId?: number;
};

export type TReportPicture = TPictureBaseInformation & {
  action: PICTURE_ACTIONS.UPLOAD_FILE_FOR_SUBMISSION;
  activity_id: string;
  activity_report_id: string;
  workflow_report_id: string;
  workflow_id: string;
  submission_id: string;
  item_id?: string;
  step_id?: string;
};

export type TItemPicture = TPictureBaseInformation & {
  action: PICTURE_ACTIONS.UPLOAD_FILE_FOR_ITEM;
  item_name?: string;
  item_id: string;
  list_id: string;
};

export type TPictureBase = TPictureBaseInformation & {
  action?: undefined;
};

export type TPicture = TPictureBase | TReportPicture | TItemPicture;

export type TGalleryPicture = Pick<
  TPicture,
  "id" | "url" | "label" | "sublabel"
>;

export interface IItemPictureMetaData extends TItemPicture {
  listName?: string;
  itemName?: string;
  [importantsCustomerAttribute: string]:
    | string
    | number
    | boolean
    | undefined
    | object;
}

export interface IReportPictureMetaData extends TReportPicture {
  questionName: string;
  userName: string;
  submissionId: string;
  workflowName?: string;
  customerId?: string;
  customerName?: string;
  [importantsCustomerAttribute: string]:
    | string
    | number
    | boolean
    | undefined
    | object;
}

/* -------------------------------------------------------------------------- */
/*                                 type guards                                */
/* -------------------------------------------------------------------------- */
export function isListPicture(
  picture: TPictureBaseInformation
): picture is TItemPicture {
  return (
    (picture as TItemPicture).action === PICTURE_ACTIONS.UPLOAD_FILE_FOR_ITEM ||
    (picture as TItemPicture).list_id !== undefined ||
    (picture as TItemPicture).item_id !== undefined
  );
}

export function isReportPicture(
  picture: TPictureBaseInformation
): picture is TReportPicture {
  return (
    (picture as TReportPicture).action ===
      PICTURE_ACTIONS.UPLOAD_FILE_FOR_SUBMISSION ||
    (picture as TReportPicture).submission_id !== undefined ||
    (picture as TReportPicture).workflow_report_id !== undefined
  );
}

export function isReportPictureMetaDate(
  picture: TPicture
): picture is IReportPictureMetaData {
  return (
    (picture as IReportPictureMetaData).questionName !== undefined ||
    (picture as IReportPictureMetaData).userName !== undefined ||
    (picture as IReportPictureMetaData).submissionId !== undefined
  );
}

export function isItemPictureMetaDate(
  picture: TPicture
): picture is IItemPictureMetaData {
  return (
    (picture as IItemPictureMetaData).listName !== undefined ||
    (picture as IItemPictureMetaData).itemName !== undefined
  );
}

export default TPicture;
