import { IHierarchyDependency as IBEHierarchyDependency } from "fieldpro-tools/dist/src/types/clients";

import { IOption } from "model/application/components";
export type {
  IMetaHierarchyDependency,
  IMetaLevelType, // IHierarchyDependency,
} from "fieldpro-tools/dist/src/types/clients";

export type IHierarchyLevelOption = IOption;

export enum HierarchyDependencyColors {
  Green = "green",
  Yellow = "yellow",
}

export interface IHierarchyDependencyWithColor extends IBEHierarchyDependency {
  color: HierarchyDependencyColors;
}

export interface IHierarchyDependency extends IBEHierarchyDependency {
  removeBeforeBackEnd?: boolean;
}
