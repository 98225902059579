import React, { ReactNode } from "react";

import { KPI_TYPE } from "model/entities/Dashboard";

import { DATA_PDF_PRINTABLE_ATTRIBUTE } from "../Chart/Chart";

interface IPdfPrintableChartProps {
  width: number;
  type: KPI_TYPE;
  title?: string;
  children: ReactNode;
  style?: any;
}

const dynamicAttributes = (attribute: string, value: any) => {
  const opts = {};
  if (typeof value !== "undefined" && value !== null) {
    opts[attribute] = value;
    return opts;
  }
  return false;
};

export const PdfPrintableChartWrapper: React.FunctionComponent<
  IPdfPrintableChartProps
> = ({ children, width, type, title, style }) => {
  return (
    <span
      style={style}
      {...dynamicAttributes(`${DATA_PDF_PRINTABLE_ATTRIBUTE}type`, `${type}`)}
      {...dynamicAttributes(`${DATA_PDF_PRINTABLE_ATTRIBUTE}width`, `${width}`)}
      {...dynamicAttributes(`${DATA_PDF_PRINTABLE_ATTRIBUTE}title`, `${title}`)}
    >
      {children}
    </span>
  );
};

export default PdfPrintableChartWrapper;
