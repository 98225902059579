const DASHBOARDS_PATH_REGEX = /^\/dashboards(?:\/)?(?<subcategory>[^/]*)?/;
const WORKFLOWS_PATH_REGEX = /^\/workflows(?:\/)?(?<subcategory>[^/]*)?/;
const LISTS_PATH_REGEX = /^\/lists(?:\/)?(?<subcategory>[^/]*)?/;

export const getDashboardsSubcategory = (pathname?: string) => {
  const match = (pathname || "").match(DASHBOARDS_PATH_REGEX);
  // groups can be empty at index or if pathname does not match
  return match?.groups?.subcategory || "index";
};

export const getWorkflowsSubcategory = (pathname?: string) => {
  const match = (pathname || "").match(WORKFLOWS_PATH_REGEX);
  // groups can be empty at index or if pathname does not match
  return match?.groups?.subcategory || "index";
};

export const getListsSubcategory = (pathname?: string) => {
  const match = (pathname || "").match(LISTS_PATH_REGEX);
  // groups can be empty at index or if pathname does not match
  return match?.groups?.subcategory || "index";
};
