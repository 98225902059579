import { IFoldersStates } from "./reducer";
const initialState: IFoldersStates = {
  folders: [],
  isFetchingFolders: false,
  isUpdatingFolder: false,
  isDeletingFolder: false,
  isCreatingFolder: false,
  isArchivingFolder: false,
  isRestoringFolder: false,
  errors: [],
};

export default initialState;
