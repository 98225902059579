import { ReactNode } from "react";

import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import { Mustard90, PaleGray } from "assets/colors";
import { BasicArrowIcon } from "components/Dialog/BasicDialog";

const useStyle = makeStyles({
  detectionViewContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: PaleGray,
    padding: "5px",
    borderRadius: "8px",
    position: "relative",
  },

  iconDirection: {
    position: "absolute",
    background: "white",
    top: "50%",
    translateY: "-50%",
    zIndex: 100,
    "&.left": {
      left: 5,
    },
    "&.right": {
      right: 5,
    },
    "&:hover": {
      background: Mustard90,
      opacity: "0.8",
    },
  },
});

interface IPictureDetectionViewLayout {
  children: ReactNode | ReactNode[];
  onNextLoad?: () => void;
  onPrevious?: () => void;
}
const PictureDetectionViewLayout = ({
  children,
  onPrevious,
  onNextLoad,
}: IPictureDetectionViewLayout) => {
  const classes = useStyle();
  return (
    <Box className={classes.detectionViewContainer}>
      {children}
      {onPrevious && (
        <BasicArrowIcon
          onClick={onPrevious}
          direction="left"
          filled
          style={{ height: "40px", width: "40px" }}
          className={classNames(classes.iconDirection, "left")}
        />
      )}
      {onNextLoad && (
        <BasicArrowIcon
          onClick={onNextLoad}
          direction="right"
          filled
          style={{ height: "40px", width: "40px" }}
          className={classNames(classes.iconDirection, "right")}
        />
      )}
    </Box>
  );
};

export default PictureDetectionViewLayout;
