import {
  AlreadyExistsBusinessError,
  AlreadyLoggedInBusinessError,
  AuthenticationBusinessError,
  AuthenticationError,
  FreeTrialLimitReachedError,
  HasBeenDeletedBusinessError,
  InvalidOTPError,
  LicenseNotValidError,
  NotAllowedBusinessError,
  NotFoundBusinessError,
  NotUpToDateBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  QuotaExceededBusinessError,
  RunInBackgroundBusinessError,
  TeamChangedBusinessError,
  TimeoutBusinessError,
  ValidationBusinessError,
} from "fieldpro-tools/dist/src/tools/error/error";

const IGNORED_ERROR_CLASSES = [
  NotFoundBusinessError,
  // Quotas
  FreeTrialLimitReachedError,
  QuotaExceededBusinessError,
  // Login & License
  LicenseNotValidError,
  InvalidOTPError,
  AuthenticationError,
  AlreadyLoggedInBusinessError,
  // Background operations
  RunInBackgroundBusinessError,
  // Other business errors
  NotUpToDateBusinessError,
  TeamChangedBusinessError,
  NotAllowedBusinessError,
  TimeoutBusinessError,
  ValidationBusinessError,
  AlreadyExistsBusinessError,
  HasBeenDeletedBusinessError,
  AuthenticationBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  AlreadyLoggedInBusinessError,
];

/**
 * Returns true if we should skip capturing error with Sentry, false otherwise
 * WARNING: only works for handled errors.
 *
 * To ignore an error based on error message, add them to sentry/init.ts
 *
 * @param e Error-like object
 * @returns boolean
 */
const ignoreError = (e: unknown) => {
  // ignore error by class
  for (const errorClass of IGNORED_ERROR_CLASSES) {
    if (e instanceof errorClass) {
      return true;
    }
  }
  return false;
};

export default ignoreError;
