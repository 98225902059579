import { makeStyles, Table, TableBody } from "@material-ui/core";
import _ from "lodash";

import { OffWhiteLight } from "assets/colors";
import { TColumnType, TRowType } from "components/Table/model";

import SimpleCellContent from "./SimpleCellContent";
import SimpleTableHeader from "./SimpleTableHeader";
import SimpleTableRow, { TCellContent } from "./SimpleTableRow";

interface ISimpleTable {
  title?: string;
  rows: TRowType[];
  columns: TColumnType[];
  CellContent?: TCellContent;
}

const useStyles = makeStyles({
  SimpleTableContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  SimpleTableTitle: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 700,
    padding: "8px 0px",
    backgroundColor: OffWhiteLight,
    paddingLeft: "8px",
    paddingTop: "8px",
  },
  SimpleBodyContainer: {
    position: "relative", // Important, so the sticky elements are sticky relative to their container
    overflow: "auto",
    width: "100%",
  },
  SimpleTable: {
    backgroundColor: OffWhiteLight,
  },
});

const SimpleTable = ({
  title,
  columns,
  rows,
  CellContent = SimpleCellContent,
}: ISimpleTable) => {
  const classes = useStyles();

  if (_.isEmpty(rows) || _.isEmpty(columns) || !rows || !columns) {
    return null;
  }

  return (
    <div className={classes.SimpleTableContainer}>
      {title && <div className={classes.SimpleTableTitle}>{title}</div>}

      <div
        data-testid="simple-body-container"
        className={classes.SimpleBodyContainer}
      >
        <Table className={classes.SimpleTable}>
          <SimpleTableHeader columns={columns} />

          <TableBody>
            {_.map(rows, (row, index) => (
              <SimpleTableRow
                key={index}
                row={formatRowAsArray({ row, columns })}
                CellContent={CellContent}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

interface IFormatRowAsArray {
  row: TRowType;
  columns: TColumnType[];
}

const formatRowAsArray = ({ row, columns }: IFormatRowAsArray) => {
  const displayedColumns = _.filter(columns, ({ hidden }) => !hidden);
  return _.map(displayedColumns, ({ name, type, sticky }) => ({
    value: row[name],
    type,
    sticky,
  }));
};

export default SimpleTable;
