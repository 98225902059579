import { Box } from "@material-ui/core";
import _ from "lodash";

import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import MatrixViewer from "components/Matrix/MatrixViewer";

import { TMatrixData } from "./model";

interface IMatrixDialog {
  title?: string;
  matrixData?: TMatrixData;
  onClose?: () => void;
}

const MatrixDialog = ({ title, matrixData, onClose }: IMatrixDialog) => {
  if (!matrixData || _.isEmpty(matrixData?.rows)) {
    return null;
  }
  return (
    <BasicDialog
      open
      onClose={onClose}
      // NOTE: this prevents hover propagating to row
      // Otherwise the 1st sticky cell of the row becomes transparent (backgroundColor: "inherit") (see CustomTableRow.tsx)
      onMouseOver={(e) => e.stopPropagation()}
      onMouseOut={(e) => e.stopPropagation()}
      minWidth="512px"
    >
      <BasicDialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title}
          <BasicCloseIcon onClick={onClose} />
        </Box>
      </BasicDialogTitle>
      <BasicDialogContent>
        <Box padding="8px 0 32px">
          <MatrixViewer {...matrixData} />
        </Box>
      </BasicDialogContent>
    </BasicDialog>
  );
};

export default MatrixDialog;
