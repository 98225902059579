import { STEP_TYPE } from "fieldpro-tools";

import { IWorkflow } from "model/entities/Workflow";

import InputSelect from "../InputSelect";

/**
 * Second step (facultative, only if we are in a workflow report scope): step selection
 */
export interface IMetaExpressionWizardSecondStepProps {
  step?: string;
  shouldMarkError: any;
  labelToDisplay: any;
  currentWorkflow?: IWorkflow;
  onChange: (value: any, name: string) => void;
}

export const MetaExpressionWizardSecondStep: React.FunctionComponent<
  IMetaExpressionWizardSecondStepProps
> = ({ step, shouldMarkError, labelToDisplay, currentWorkflow, onChange }) => {
  const buildOptionsFromWorkflowSteps = () => {
    const options: any[] = [];
    if (!currentWorkflow || currentWorkflow.steps.length === 0) {
      return [];
    }

    currentWorkflow.steps
      .filter((ws) =>
        [
          STEP_TYPE.ACTIVITY,
          STEP_TYPE.EXPORT_ACTIVITY_REPORT_PDF,
          STEP_TYPE.HTTP_REQUEST,
          STEP_TYPE.TRANSFORMATION,
          STEP_TYPE.STREAM,
          STEP_TYPE.SCRIPT,
        ].includes(ws.type)
      )
      .forEach((ws) => {
        options.push({
          key: ws.id,
          label: ws.title,
        });
      });
    return options;
  };

  return (
    <div>
      <InputSelect
        required
        viewMode="CREATE"
        error={shouldMarkError("step")}
        name="step"
        lang={labelToDisplay.inputStep}
        options={buildOptionsFromWorkflowSteps()}
        value={step}
        onChange={onChange}
        //getAllTagsSuperior={getAllTagsSuperior}
      />
    </div>
  );
};
