/**
 * @notes: MetaExpression action types
 */
export const CREATE_META_EXPRESSION_BEGIN = "CREATE_META_EXPRESSION_BEGIN";
export const CREATE_META_EXPRESSION_SUCCESS = "CREATE_META_EXPRESSION_SUCCESS";
export const CREATE_META_EXPRESSION_FAILURE = "CREATE_META_EXPRESSION_FAILURE";

export const UPDATE_META_EXPRESSION_BEGIN = "UPDATE_META_EXPRESSION_BEGIN";
export const UPDATE_META_EXPRESSION_SUCCESS = "UPDATE_META_EXPRESSION_SUCCESS";
export const UPDATE_META_EXPRESSION_FAILURE = "UPDATE_META_EXPRESSION_FAILURE";

export const FETCH_ALL_META_EXPRESSIONS_BEGIN =
  "FETCH_ALL_META_EXPRESSIONS_BEGIN";
export const FETCH_ALL_META_EXPRESSIONS_SUCCESS =
  "FETCH_ALL_META_EXPRESSIONS_SUCCESS";
export const FETCH_ALL_META_EXPRESSIONS_FAILURE =
  "FETCH_ALL_META_EXPRESSIONS_FAILURE";

export const DELETE_META_EXPRESSION_BEGIN = "DELETE_META_EXPRESSION_BEGIN";
export const DELETE_META_EXPRESSION_SUCCESS = "DELETE_META_EXPRESSION_SUCCESS";
export const DELETE_META_EXPRESSION_FAILURE = "DELETE_META_EXPRESSION_FAILURE";

export const COMPUTE_META_EXPRESSION_BEGIN = "COMPUTE_META_EXPRESSION_BEGIN";
export const COMPUTE_META_EXPRESSION_SUCCESS =
  "COMPUTE_META_EXPRESSION_SUCCESS";
export const COMPUTE_META_EXPRESSION_FAILURE =
  "COMPUTE_META_EXPRESSION_FAILURE";

export const RESET_META_EXPRESSION_SUCCESS = "RESET_META_EXPRESSION_SUCCESS";
