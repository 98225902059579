import { isEmptyValue } from "utils/isEmptyValue";

import { IGPSStructure } from "../InputGPS";

export const isValidGPSPosition = (gpsValue?: IGPSStructure | null) => {
  if (!gpsValue) {
    return false;
  }

  const { lat, lng } = gpsValue;

  if (isEmptyValue(lat) || isEmptyValue(lng)) {
    // gpsValue.acc is not mandatory
    return false;
  }

  return true;
};
