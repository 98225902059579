import { createTheme, ThemeOptions } from "@material-ui/core";

import {
  Black,
  cancelColor,
  GreyDark,
  GreyLight,
  OffWhite,
  primaryColor,
  Red,
  secondaryColor,
} from "assets/colors";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    cancel: Palette["primary"];
    default: Palette["primary"];
    inputButton: Palette["primary"];
  }
  interface PaletteOptions {
    cancel: PaletteOptions["primary"];
    default: PaletteOptions["primary"];
    inputButton: PaletteOptions["primary"];
  }
}

// Only works in v5
// declare module "@mui/material/Button" {
//   interface ButtonPropsColorOverrides {
//     inputButton: true;
//   }
// }

export const appThemeOptions: ThemeOptions = {
  typography: {
    button: {
      textTransform: "unset",
    },
    fontSize: 16,
    fontFamily:
      '"BasierCircle","Lato","Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    cancel: {
      main: cancelColor,
      contrastText: "#fff",
    },
    inputButton: {
      main: GreyLight,
      contrastText: Black,
    },
    default: {
      main: GreyDark,
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: { color: Red },
    },
    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover": {
          "&:hover": {
            backgroundColor: OffWhite,
          },
        },
      },
    },
    // See https://v4.mui.com/customization/globals/#global-css
    MuiCssBaseline: {
      "@global": {
        // See https://developers.google.com/fonts/docs/material_icons
        ".material-icons,.material-icons-outlined": {
          /* Rules for sizing the icon. */
          "&.md-16": {
            fontSize: "16px",
          },
          "&.md-18": {
            fontSize: "18px",
          },
          "&.md-20": {
            fontSize: "20px",
          },
          "&.md-24": {
            fontSize: "24px",
          },
          "&.md-36": {
            fontSize: "36px",
          },
          "&.md-48": {
            fontSize: "48px",
          },
          /* Rules for using icons as black on a light background. */
          "&.md-dark": {
            color: "rgba(0, 0, 0, 0.54)",
          },
          "&.md-dark.md-inactive": {
            color: "rgba(0, 0, 0, 0.26)",
          },
          /* Rules for using icons as white on a dark background. */
          "&.md-light": {
            color: "rgba(255, 255, 255, 1)",
          },
          "&.md-light.md-inactive": {
            color: "rgba(255, 255, 255, 0.3)",
          },
        },
        "mapbox-search-listbox": {
          position: "absolute",
          zIndex: 1000000,
        },
      },
    },
  },
};

export const AppTheme = createTheme(appThemeOptions);
