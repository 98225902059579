import { File } from "filepond";

import { csv2array } from "utils/utils";

import { getLines } from "../readExcelFileUtils";
import {
  FileExtensions,
  IFileInformation,
  ILine,
  IOnError,
  IOnUpdateExcelOrCsvFileFromUploadResults,
} from "../UploadFileInput";
import { getExcelOrCsvFileContent } from "./utils";

export const ifAcceptedExtension = (
  file: File,
  fileExtensions: FileExtensions[]
) => {
  return fileExtensions.includes(file.fileExtension as FileExtensions);
};
export const getFileInformations = (file: File): IFileInformation => {
  return {
    fileExtension: file.fileExtension as FileExtensions,
    fileName: file.filename,
  };
};

export const getStructuredDatasByTypeFile = async (
  fileItem: File,
  data: string | Blob
) => {
  if (fileItem.fileExtension === FileExtensions.Excel) {
    const lines: ILine[] = await getLines(data as Blob);
    // Workaround, need to stringify all values
    const linesWithStringifiedValues = lines.map((l) => {
      return l.map((word) => (word ? word.toString() : ""));
    }) as ILine[];

    return linesWithStringifiedValues;
  }

  const lines = csv2array(data as string, ",") as ILine[];
  return lines;
};
export const updateExcelOrCsvFile = async (
  fileItems: File[],
  onUpdateFile: IOnUpdateExcelOrCsvFileFromUploadResults,
  onError: IOnError
) => {
  const acceptedExtensions = [FileExtensions.Excel, FileExtensions.Csv];
  try {
    const fileItem = fileItems[0];
    if (!fileItem || !fileItem.file) {
      onError("Invalid file");
    } else if (!ifAcceptedExtension(fileItem, acceptedExtensions)) {
      onError("Only Excel and Csv file supported");
    } else {
      // Get file content
      const fileContent = await getExcelOrCsvFileContent(fileItem);

      // Extract values from the content file and the extension
      const results = await getStructuredDatasByTypeFile(fileItem, fileContent);

      // get file informations like name or extension
      const fileInformations = getFileInformations(fileItem);

      // callback
      onUpdateFile(results, fileInformations);
    }
  } catch (error) {
    onError((error as any).message);
  }
};
