import React, { useEffect, useState } from "react";

import {
  BaseTextFieldProps,
  Box,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import { useDropzone } from "react-dropzone";

import {
  ErrorColor,
  GreyDark,
  GreyLight,
  InfoColor,
  SuccessColor,
} from "assets/colors";
import useTranslations from "hooks/useTranslations";
import { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import ImageURLModal, { getInvalidFileTypeError } from "./ImageURLModal";

const styles = (theme: Theme) =>
  ({
    InputImage: {
      marginBottom: "32px",
    },
    MuiButton: {
      "&.CustomInputButton": {
        backgroundColor: theme.palette.inputButton.main,
        color: theme.palette.inputButton.contrastText,
      },
    },
    imagePreview: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      "& img": {
        minWidth: (props: any) => props.previewMinWidth,
        minHeight: (props: any) => props.previewMinHeight,
        maxWidth: (props: any) => props.previewMaxWidth,
        maxHeight: (props: any) => props.previewMaxHeight,
      },
    },
    buttonText: {
      fontWeight: "bold",
      marginLeft: "4px",
      whiteSpace: "nowrap",
    },
    addImageUrlButton: {
      color: "#124e5d", // Green
    },
    addImageUrlText: {
      color: "#979797",
      fontWeight: 600,
      fontSize: "12px",
      marginTop: 8,
    },
    tooltipWrapper: {
      backgroundColor: "green",
    },
    previewContainer: {
      display: "flex",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "30px",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: "#979797",
      borderStyle: "dashed",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    isFocused: {
      borderColor: InfoColor,
    },
    isDragAccept: {
      borderColor: SuccessColor,
    },
    isDragReject: {
      borderColor: ErrorColor,
    },
    noImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: 4,
      height: (props: any) => props.previewMaxHeight,
      width: (props: any) => props.previewMaxWidth,
      color: GreyDark,
      backgroundColor: GreyLight,
    },
  } as const);

const useStyles = makeStyles(styles);

export interface IInputImagePropsBase
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  viewMode?: TViewMode;
  defaultValue?: string;
  name: string;
  lang: TInputAttributeLang;
  onChange: (value: any, name: string, isUrl?: boolean) => void;
  required?: boolean;
  previewMinWidth?: string | number;
  previewMinHeight?: string | number;
  previewMaxWidth?: string | number;
  previewMaxHeight?: string | number;
  error?: string;
  viewStacked?: boolean;
  canAddUrl?: boolean; // cannot be used with mimeTypes
  mimeTypes?: string[]; // list of allowed mimeTypes
}

export interface IInputImageProps
  extends IInputImagePropsBase,
    Omit<IInputBaseLayout, keyof IInputImagePropsBase> {}

type TOnAddSignature = (
  files: Array<File & { blob?: string }> | FileList | null
) => void;

export const InputImage: React.FunctionComponent<IInputImageProps> = ({
  viewMode = "CREATE",
  defaultValue,
  lang,
  name,
  error,
  onChange,
  required = false,
  previewMinWidth = 30,
  previewMinHeight = 30,
  previewMaxWidth = 300,
  previewMaxHeight = 225,
  canAddUrl = false,
  viewStacked = false,
  mimeTypes,
  ...rest
}) => {
  if (!_.isEmpty(mimeTypes) && canAddUrl) {
    throw new Error("Cannot use mimeTypes with canAddUrl currently");
  }

  const classes = useStyles({
    previewMinWidth,
    previewMinHeight,
    previewMaxWidth,
    previewMaxHeight,
  });

  const getDefaultUrl = (input: any): string | undefined => {
    if (input) {
      if (_.startsWith(input, "http")) {
        return input;
      } else if (input.value && input.value.blob) {
        return input.value.blob;
      }
    }
    return undefined;
  };

  const [fileUrl, setFileUrl] = useState(getDefaultUrl(defaultValue));
  const rootLang = useTranslations();
  const [errorText, setErrorText] = useState(error);
  useEffect(() => {
    setErrorText(error);
  }, [error]);

  const onAdd: TOnAddSignature = (files) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    if (_.isArray(mimeTypes)) {
      const fileType = file.type;
      if (!_.includes(mimeTypes, fileType)) {
        const error = getInvalidFileTypeError(mimeTypes, rootLang);
        setErrorText(error);
        return;
      }

      setErrorText("");
    }

    const fileUrl = URL.createObjectURL(file);
    if (_.startsWith(fileUrl, "blob"))
      (file as File & { blob: string }).blob = fileUrl;
    setFileUrl(fileUrl);
    onChange(file, name);
  };

  const onClear = () => {
    setFileUrl(undefined);
    onChange(null, name);
  };

  const onAddURL = (url: string) => {
    setFileUrl(url);
    onChange(url, name, true);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: onAdd,
      multiple: false,
      accept: _.isArray(mimeTypes) ? mimeTypes : "image/*",
    });

  if (_.includes(["VIEW", "EDIT"], viewMode)) {
    return (
      <InputBaseLayout
        {...rest}
        label={lang?.title}
        tooltip={lang?.tooltip}
        required={required}
        viewMode={viewMode}
        error={errorText}
        viewStacked={viewStacked}
      >
        <Box className={classes.imagePreview}>
          {fileUrl ? (
            <img src={fileUrl} alt={fileUrl} />
          ) : (
            <Box className={classes.noImage}>
              {rootLang.components.inputImage.noImageSelected}
            </Box>
          )}

          {viewMode === "EDIT" && (
            <>
              <Box display="flex" alignItems="flex-start">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginRight="16px"
                >
                  <AddImageButton onAdd={onAdd} fileUrl={fileUrl} name={name} />
                  {canAddUrl && (
                    <AddImageURLButton
                      onAddURL={onAddURL}
                      // setErrorText={setErrorText}
                      // mimeTypes={mimeTypes}
                    />
                  )}
                </Box>

                <RemoveImageButton onClear={onClear} />
              </Box>
            </>
          )}
        </Box>
      </InputBaseLayout>
    );
  }

  // "CREATE" viewMode
  return (
    <InputBaseLayout
      {...rest}
      label={lang?.title}
      tooltip={lang?.tooltip}
      required={required}
      viewMode={viewMode}
      error={errorText}
    >
      {!fileUrl ? (
        <section>
          <div
            {...getRootProps({
              className: classNames(classes.dropzone, {
                [classes.isFocused]: isFocused,
                [classes.isDragAccept]: isDragAccept,
                [classes.isDragReject]: isDragReject || error,
              }),
            })}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <AddImagePlaceholderButton />
              {canAddUrl && (
                <AddImageURLButton
                  onAddURL={onAddURL}
                  // setErrorText={setErrorText}
                  // mimeTypes={mimeTypes}
                />
              )}
            </Box>

            <input {...getInputProps()} />
          </div>
        </section>
      ) : (
        <Box className={classes.imagePreview}>
          <img src={fileUrl} alt={fileUrl} />

          <Box display="flex" alignItems="flex-start">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginRight="16px"
            >
              <AddImageButton onAdd={onAdd} fileUrl={fileUrl} name={name} />
              {canAddUrl && (
                <AddImageURLButton
                  onAddURL={onAddURL}
                  // setErrorText={setErrorText}
                  // mimeTypes={mimeTypes}
                />
              )}
            </Box>

            <RemoveImageButton onClear={onClear} />
          </Box>
        </Box>
      )}
    </InputBaseLayout>
  );
};

interface IAddImageURLButton {
  onAddURL: (url: string) => void;
  // setErrorText: (errorText: string) => void;
  // mimeTypes?: string[];
}

const AddImageURLButton = ({ onAddURL }: IAddImageURLButton) => {
  const classes = useStyles({});
  const lang = useTranslations();
  return (
    <span className={classes.addImageUrlText}>
      or{" "}
      <ImageURLModal
        onAddURL={onAddURL}
        // setErrorText={setErrorText}
        // mimeTypes={mimeTypes}
        trigger={
          <span className={classes.addImageUrlButton}>
            {lang.components.inputImage.addURL}
          </span>
        }
      />
    </span>
  );
};

const AddImagePlaceholderButton = ({ component }: any) => {
  const lang = useTranslations();
  const classes = useStyles({});
  return (
    <Button
      // @ts-ignore: custom color needs mui v5
      color="inherit"
      component={component}
      variant="contained"
      disableElevation
    >
      <span className="material-icons-outlined md-18">file_upload</span>
      <span className={classes.buttonText}>
        {lang.components.inputImage.add}
      </span>
    </Button>
  );
};

const AddImageButton = ({
  fileUrl,
  onAdd,
  name,
}: {
  fileUrl?: string;
  onAdd: TOnAddSignature;
  name: string;
}) => {
  return (
    <label htmlFor={name}>
      <AddImagePlaceholderButton component="span" />
      <input
        key={fileUrl} // hack to reset input when fileUrl changes
        id={name}
        name={name}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => onAdd(e.target.files)}
      />
    </label>
  );
};

const RemoveImageButton = ({ onClear }: { onClear: () => void }) => {
  const classes = useStyles({});
  const lang = useTranslations();
  return (
    <Button
      // @ts-ignore: custom color needs mui v5
      color="secondary"
      variant="contained"
      disableElevation
      onClick={onClear}
    >
      <span className="material-icons-outlined md-18">cancel</span>
      <span className={classes.buttonText}>
        {lang.components.inputImage.remove}
      </span>
    </Button>
  );
};

export default InputImage;
