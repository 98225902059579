import { LIST_SCOPE } from "fieldpro-tools";

import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { IList } from "model/entities/List";

interface IBuildLegendOptions {
  lang: TLang;
  customersList?: IList;
}

export function getMapLegendOptions({
  lang,
  customersList,
}: IBuildLegendOptions) {
  const showTeamsLegend =
    customersList?.scope === LIST_SCOPE.SINGLE_MOBILE_USER;
  const showOwnersLegend = [
    LIST_SCOPE.SINGLE_MOBILE_USER,
    LIST_SCOPE.SINGLE_TEAM,
  ].includes(customersList?.scope as LIST_SCOPE);
  const regionTag = customersList?.schema.find((s) => {
    return ["_region", "region"].includes(s.column_tag);
  });

  const legendOptions: IOption[] = [
    {
      key: "__CLEAR",
      label: lang.genericTerms.none,
    },
    {
      key: "_type",
      label: lang.components.maps.legend.type,
    },
    {
      key: "_visit_day",
      label: lang.components.filters.lastVisitDate,
    },
    {
      key: "_users",
      label: lang.components.filters.users,
    },
  ];

  if (showOwnersLegend) {
    legendOptions.push({
      key: "_owners",
      label: lang.components.filters.owners,
    });
  }
  if (showTeamsLegend) {
    legendOptions.push({
      key: "_teams",
      label: lang.components.filters.teams,
    });
  }
  if (regionTag) {
    legendOptions.push({
      key: regionTag.column_tag,
      label:
        lang.containers.clients.subCategories.clients.createEditModal
          .inputClientRegionManagement.title,
    });
  }

  return legendOptions;
}
