import { useState } from "react";

import { Box } from "@mui/material";
import _ from "lodash";

import { lightgray, OffWhite } from "assets/colors";
import MatrixDialog from "components/Dialog/MatrixDialog";
import { TMatrixData } from "components/Dialog/MatrixDialog/model";

export interface IMatrixCell {
  title?: string;
  matrixData?: TMatrixData;
}

const MatrixCell = ({ title, matrixData }: IMatrixCell) => {
  const [openModal, setOpenModal] = useState(false);

  if (_.isEmpty(matrixData?.rows)) {
    return null;
  }
  return (
    <>
      <Box
        width="50px"
        height="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: OffWhite, cursor: "pointer" }}
        onClick={() => setOpenModal(true)}
      >
        <span className="material-icons-outlined" style={{ color: lightgray }}>
          calendar_view_month
        </span>
      </Box>

      {openModal && (
        <MatrixDialog
          title={title}
          matrixData={matrixData}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default MatrixCell;
