import _ from "lodash";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export const getCSVFromDataRows = (dataRows: string[][]) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  dataRows.forEach((row) => {
    const line = row.map((cell) => formatCorrectlyCSVCell(cell)).join(",");
    csvContent += line + "\r\n";
  });
  return csvContent;
};

export const formatCorrectlyCSVCell = (input: string | undefined): string => {
  return `"${`${input || ""}`.split('"').join('""')}"`;
};

export const getCellFormattedValue = (input: any) => {
  if (input == null || input === "") {
    return undefined;
  }

  // remove double quotes at the beginning and at the last if exist
  if (_.isString(input)) {
    return input.toString().trim().replace(/^"/, "").replace(/"$/, "");
  }

  // keep numbers as is
  return input;
};

export const getExcelFromDataRows = async (dataRows: string[][]) => {
  const workbook = await XlsxPopulate.fromBlankAsync();

  for (let r = 0; r < dataRows.length; r++) {
    const row = dataRows[r];
    for (let c = 0; c < row.length; c++) {
      const value = getCellFormattedValue(row[c]);
      workbook
        .sheet("Sheet1")
        .row(r + 1)
        .cell(c + 1)
        .value(value)
        .style({
          // DO THE EXCEL NUMBER FORMATTING HERE
          // 1. keeps original value (e.g extra decimals can be recuperated)
          // 2. all native Excel customisations available to the user
          numberFormat: "#,###.##",
        });
    }
  }

  // Write to file.
  const blob = await workbook.outputAsync();
  return blob;
};

const downloadFile = (encodedUri: string, fileName: string) => {
  const link = document.createElement("a");
  link.setAttribute("class", "invisible_link");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  link.innerHTML = "Click Here to download";
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file named "my_data.csv".
  document.body.removeChild(link);
};

export const downloadCsvFile = (csv: string, fileName: string) => {
  const encodedUri = encodeURI(csv).replace(/#/g, "%23");
  downloadFile(encodedUri, fileName + ".csv");
};

export const downloadExcelFile = (excel: any, fileName: string) => {
  const encodedUri = window.URL.createObjectURL(excel);
  downloadFile(encodedUri, fileName + ".xlsx");
};

export const downloadExcelAndCsvFile = async (
  dataRows: string[][],
  fileName: string
) => {
  // get csv file
  const resultCsv = getCSVFromDataRows(dataRows);
  downloadCsvFile(resultCsv, fileName);
  // get excel file
  const resultsExcel = await getExcelFromDataRows(dataRows);
  downloadExcelFile(resultsExcel, fileName);
};
