import IRootState from "redux/store/model";

/**
 * Selected sub-category
 * customers
 */
export const getSelectedSubCategory = (state: IRootState) =>
  state.customers.subCategorySelected;

export const getAllLegends = (state: IRootState) => state.customers.allLegends;

export const getCustomerLocations = (state: IRootState) =>
  state.customers.customerLocations;
