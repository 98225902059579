import { cleanDirtyDate, getDefaultDateFormat } from "utils/dateUtils";

export interface IDateCell {
  date?: string | Date;
}

const DateCell = ({ date: dirtyDate }: IDateCell) => {
  const dateFormatted = cleanDirtyDate(dirtyDate, getDefaultDateFormat);
  if (!dateFormatted) return null;
  return <span>{dateFormatted}</span>;
};

export default DateCell;
