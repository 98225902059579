import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import _ from "lodash";

import { orange, primaryColor } from "assets/colors";
import useTranslations from "hooks/useTranslations";

import { computePercentage, formatMaxTitle } from "../UsageTab.utils";

interface IStyle {
  colored?: string;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      borderRadius: 15,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: (props: IStyle) =>
        props.colored ? props.colored : primaryColor,
    },
  })
)(LinearProgress);

const useStyles = makeStyles({
  Bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontWeight: 500,
    marginBottom: "8px",
  },
});

interface IQuota {
  max: number;
  current: number;
}

const Quota: React.FunctionComponent<IQuota> = ({ max, current }) => {
  const classes = useStyles();
  const normalise = (value: number) => ((value - 0) * 100) / (max - 0);
  const pourcentage = computePercentage(current, max);

  const lang = useTranslations();
  const langTab =
    lang.containers.clients.subCategories.clients.createEditModal.inputUsageTab;

  return (
    <Box>
      <div className={classes.Bar}>
        <span style={{ fontSize: "18px" }}>
          {langTab.title}
          &nbsp;
          <span style={{ fontWeight: 600 }}>{pourcentage}%</span>
        </span>

        <span style={{ fontSize: "16px" }}>
          {formatMaxTitle(_.toString(max))}
        </span>
      </div>

      <BorderLinearProgress
        variant="determinate"
        value={normalise(current)}
        colored={pourcentage >= 80 ? `${orange}` : `${primaryColor}`}
      />
    </Box>
  );
};

export default Quota;
