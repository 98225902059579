import {
  TimeoutBusinessError,
  ValidationBusinessError,
} from "fieldpro-tools/dist/src/tools/error/error";
import _ from "lodash";
import getErrorMessage from "log/getErrorMessage";
import qs from "qs";
export const shouldRunInBackground = (payload: Object | undefined): boolean => {
  if (payload) {
    return Object.values(payload).some((value) => checkValue(value));
  }
  return false;
};

const checkValue = (value: any): boolean => {
  if (Array.isArray(value)) {
    if (value.length > 500) {
      return true;
    } else {
      if (value.some((e) => checkValue(e))) {
        return true;
      }
    }
  } else if (typeof value === "object" && value !== null) {
    return shouldRunInBackground(value);
  }
  return false;
};

export const prepareQueryWithArrays = (query: any): string => {
  for (const q of Object.keys(query)) {
    if (_.isArray(query[q])) {
      query[q] = `${qs.stringify({ [q]: query[q] }, { arrayFormat: "comma" })}`;
      query[q] = `[${query[q].substring(`${q}=`.length)}]`;
    }
  }
  const prepareQuery = qs.stringify(query, {
    arrayFormat: "comma",
  });

  return prepareQuery;
};

/**
 * @param error  Error or string
 * @returns the same error when any conditions are respected, or return a new instance of error
 */
export const reComputeError = (error: any) => {
  const errorMessage = getErrorMessage(error);

  if (new RegExp(/Request failed with status code 422/i).test(errorMessage)) {
    return new ValidationBusinessError(
      error?.response?.data?.data?.error?.message || errorMessage
    );
  }

  if (new RegExp(/Request aborted/i).test(errorMessage)) {
    return new TimeoutBusinessError(errorMessage);
  }

  return error;
};
