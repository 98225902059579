import _ from "lodash";

import { TColumnType } from "components/Table/model";
import { getColumnTypeFromAttrType } from "containers/lists/subcategories/lists/ListItemsScreen.utils";
import { IListSchema } from "model/entities/List";
import { CUSTOM_FIELD_TYPE, IQuestion } from "model/entities/Workflow";

export function getCustomFieldColumns(
  customFields: IQuestion[] | IListSchema[]
): TColumnType[] {
  const nonAllowedCustomFields = _.filter(
    customFields,
    (q: IListSchema | IQuestion) =>
      !q.is_hidden && q.type != CUSTOM_FIELD_TYPE.PLAIN_TEXT
  );
  return _.map(nonAllowedCustomFields, function (q: any) {
    const tagKey = getTagKey(q);
    const nameKey = getNameKey(q);
    return {
      name: q[tagKey],
      label: q[nameKey],
      type: getColumnTypeFromAttrType(q.type),
    };
  });
}

export function isQuestion(
  customField: IQuestion | IListSchema
): customField is IQuestion {
  return "tag" in customField;
}

export function getTagKey(customField: IQuestion | IListSchema) {
  return isQuestion(customField) ? "tag" : "column_tag";
}

export function getCategoryTagKey(customField: IQuestion | IListSchema) {
  return isQuestion(customField)
    ? "category_question_tag"
    : "category_attribute_tag";
}

export function getNameKey(customField: IQuestion | IListSchema) {
  return isQuestion(customField) ? "question_text" : "column_name";
}

// TODO: why doesn't it work ?
// type IGetMatrixFieldsSchema = {
//   (customField: IQuestion): IQuestion[];
//   (customField: IListSchema): IListSchema[];
//   (customField: IQuestion | IListSchema): IQuestion[] | IListSchema[];
// };

export const getMatrixFieldsSchema /*: IGetMatrixFieldsSchema*/ = (
  customField: IQuestion | IListSchema
) => {
  if (isQuestion(customField)) {
    return customField.matrix?.typed_questions ?? [];
  }
  return customField.matrix?.typed_questions ?? [];
};
