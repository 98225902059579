import compose from "lodash/fp/compose";

import {
  HierarchyDependencyColors,
  IHierarchyDependency,
  IHierarchyDependencyWithColor,
  IHierarchyLevelOption,
} from "model/entities/HierarchyDependency";
import { ITeamSelector } from "model/entities/Team";

type ILevelByTeam = string[];
export const getLevelsByTeams = (
  teams: ITeamSelector[],
  levelOptions: IHierarchyLevelOption[]
): ILevelByTeam[] => {
  return teams.map((userTeam) => {
    return levelOptions.map((levelOption) => {
      return userTeam[levelOption.key];
    });
  });
};

export const addYellowColorToAllLevelsTiedToUserTeams =
  (levelsByTeams: ILevelByTeam[]) =>
  (hierarchyDependencies: IHierarchyDependencyWithColor[]) => {
    const hierarchyDependenciesWithYellowColor = [...hierarchyDependencies];
    levelsByTeams.forEach((levels) => {
      levels.forEach((level, i) => {
        const index = hierarchyDependenciesWithYellowColor.findIndex(
          (n) => n.level_name === level && n.level_type_number === i
        );
        if (index !== -1) {
          hierarchyDependenciesWithYellowColor[index] = {
            ...hierarchyDependenciesWithYellowColor[index],
            color: HierarchyDependencyColors.Yellow,
          };
        }
      });
    });

    return hierarchyDependenciesWithYellowColor;
  };

export const changeYellowToGreenForLevelsWithAllChildrenTied = (
  hierarchyDependencies: IHierarchyDependencyWithColor[]
) => {
  const hierarchyDependenciesWithColor = [...hierarchyDependencies];
  const maxLevel = Math.max(
    ...hierarchyDependencies.map((h) => h.level_type_number)
  );

  hierarchyDependenciesWithColor
    .filter((n) => n.color === HierarchyDependencyColors.Yellow)
    .forEach((n) => {
      let areAllChildrenUsed = true;
      let groupFathers: string[] = [n.level_name];

      for (let i = n.level_type_number; i < maxLevel; i++) {
        const newGroupFathers: string[] = [];
        hierarchyDependenciesWithColor
          .filter(
            (h) =>
              h.level_father &&
              groupFathers.includes(h.level_father) &&
              h.level_type_number === i + 1
          )
          .forEach((l) => {
            newGroupFathers.push(l.level_name);
            if (l.color !== HierarchyDependencyColors.Yellow) {
              areAllChildrenUsed = false;
            }
          });

        groupFathers = newGroupFathers;
        if (!areAllChildrenUsed) {
          break;
        }
      }

      if (areAllChildrenUsed) {
        n.color = HierarchyDependencyColors.Green;
      }
    });

  return hierarchyDependenciesWithColor;
};

const isColor = (input: IHierarchyDependencyWithColor) =>
  input &&
  (input.color === HierarchyDependencyColors.Green ||
    input.color === HierarchyDependencyColors.Yellow);

export const removeChildrenForLevelsWithNoColor = (
  hierarchyDependenciesWithColor: IHierarchyDependencyWithColor[]
) => {
  const maxLevel = Math.max(
    ...hierarchyDependenciesWithColor.map((h) => h.level_type_number)
  );

  const hierarchyDependenciesWithoutUnusedChildren: IHierarchyDependencyWithColor[] =
    [];
  for (let i = 0; i <= maxLevel; i++) {
    hierarchyDependenciesWithColor
      .filter((n) => n.level_type_number === i)
      .forEach((n) => {
        // If no group father, show level because it's the first level
        if (!n.level_father || isColor(n)) {
          hierarchyDependenciesWithoutUnusedChildren.push(n);
        } else {
          const father = hierarchyDependenciesWithColor.find(
            (h) => h.level_name === n.level_father
          );
          if (father && isColor(father)) {
            hierarchyDependenciesWithoutUnusedChildren.push(n);
          }
        }
      });
  }

  return hierarchyDependenciesWithoutUnusedChildren;
};

const getHierarchyLevelsWithColor = (
  userTeams: ITeamSelector[],
  levelOptions: IHierarchyLevelOption[],
  hierarchyDependencies: IHierarchyDependency[]
) => {
  const levelsByTeams = getLevelsByTeams(userTeams, levelOptions);

  const functions = [
    addYellowColorToAllLevelsTiedToUserTeams(levelsByTeams),
    changeYellowToGreenForLevelsWithAllChildrenTied,
    removeChildrenForLevelsWithNoColor,
  ];

  const result = compose(functions.reverse())(hierarchyDependencies);

  return result;
};

export default getHierarchyLevelsWithColor;
