import { FormHelperText } from "@material-ui/core";

import { AlertOrange } from "assets/colors";
import * as currLang from "lang";
import TLang from "model/application/Lang";

const DeactivatedMessage = (args: { lang?: TLang }) => {
  const { lang = currLang["en"] } = args;
  return (
    <FormHelperText
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "1.25",
        color: AlertOrange,
        padding: "0 12px",
      }}
    >
      <span>
        <span
          className="material-icons-outlined"
          style={{
            fontSize: "16px",
            verticalAlign: "top",
          }}
        >
          warning_amber
        </span>{" "}
        {lang.genericTerms.query.deactivated.description}
      </span>
    </FormHelperText>
  );
};

export default DeactivatedMessage;
