import { useEffect, useState } from "react";

import { MOMENT_TIMEZONES } from "fieldpro-tools";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import { clientTimeZoneToMomentTimeZone } from "containers/calendar/utils/clientTimeZoneToMomentTimeZone";
import { getSelectedClient } from "containers/clients/redux/selectors";

interface IUseMomentTimeZone {
  cleanTimeZoneOnUnmount?: boolean;
  /**
   * @timezone - Use the passed timezone instead of the client timezone (default behaviour)
   */
  timezone?: MOMENT_TIMEZONES;
  viewInLocalTimeZone?: boolean;
}
export const useMomentTimeZone = ({
  cleanTimeZoneOnUnmount,
  timezone,
  viewInLocalTimeZone,
}: IUseMomentTimeZone = {}) => {
  const client = useSelector(getSelectedClient);
  const [timeZone, setTimeZone] = useState(() => {
    if (viewInLocalTimeZone) return moment.tz.guess();
    if (timezone) return timezone;
    return clientTimeZoneToMomentTimeZone(client);
  });
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    return () => {
      if (cleanTimeZoneOnUnmount) {
        moment.tz.setDefault();
      }
    };
  }, [cleanTimeZoneOnUnmount]);

  useEffect(() => {
    moment.tz.setDefault(timeZone);
  }, [timeZone]);

  return { moment, setTimeZone, timeZone };
};
