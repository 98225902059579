import _ from "lodash";

import { getItemLabel } from "containers/lists/utils/getItemLabel";
import { CUSTOM_FIELD_TYPE, IList } from "model/entities/List";

import { IItemGalleryItem } from "../ItemGallery";

export function getItemGalleryFromList(
  list: IList,
  items = list.items
): IItemGalleryItem[] {
  let imageTag = "_image_url";

  if (list.id !== "sku") {
    const newImageTag = _.find(list.schema, {
      type: CUSTOM_FIELD_TYPE.PICTURE,
    })?.column_tag;
    if (newImageTag) imageTag = newImageTag;
  }

  return _.map(items, function (item) {
    return {
      id: item._id,
      label: getItemLabel(item),
      ...(imageTag &&
        item[imageTag] &&
        item[imageTag]["url"] && {
          imageURL: item[imageTag]["url"],
        }),
    };
  });
}
