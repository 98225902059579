import { IVisitEvent } from "fieldpro-tools";

import { IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";
import {
  IcreateEventsSuccessAction,
  IdeleteEventsSuccessAction,
  IfetchEventsSuccessAction,
  IupdateEventsSuccessAction,
  IUpdateSelectedFieldUserAction,
} from "./types";

export function fetchEventsSuccessAction(
  events: IVisitEvent[]
): IfetchEventsSuccessAction {
  return {
    type: types.FETCH_EVENTS_SUCCESS,
    events,
  };
}

export function fetchEventsBeginAction(): IBeginAction {
  return { type: types.FETCH_EVENTS_BEGIN };
}

export function fetchEventsFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_EVENTS_FAILURE,
    error,
  };
}

export function createEventsBeginAction(): IBeginAction {
  return { type: types.CREATE_EVENTS_BEGIN };
}

export function createEventsSuccessAction(
  events: IVisitEvent[]
): IcreateEventsSuccessAction {
  return {
    type: types.CREATE_EVENTS_SUCCESS,
    events,
  };
}

export function createEventsFailureAction(error: any): IActionError {
  return { type: types.CREATE_EVENTS_FAILURE, error };
}

export function deleteEventsBeginAction(): IBeginAction {
  return {
    type: types.DELETE_EVENTS_BEGIN,
  };
}

export function deleteEventsSuccessAction(
  ids: string[]
): IdeleteEventsSuccessAction {
  return {
    type: types.DELETE_EVENTS_SUCCESS,
    ids,
  };
}

export function deleteEventsFailureAction(error: any): IActionError {
  return {
    type: types.DELETE_EVENTS_FAILURE,
    error,
  };
}

export function updateEventsBeginAction(): IBeginAction {
  return { type: types.UPDATE_EVENTS_BEGIN };
}

export function updateEventsSuccessAction(
  events: IVisitEvent[],
  deleted_ids: string[]
): IupdateEventsSuccessAction {
  return { type: types.UPDATE_EVENTS_SUCCESS, events, deleted_ids };
}

export function updateEventsFailureAction(error: any): IActionError {
  return { type: types.UPDATE_EVENTS_FAILURE, error };
}
export function updateSelectedFieldUserAction(
  selectedFieldUsers: string[]
): IUpdateSelectedFieldUserAction {
  return {
    type: types.UPDATE_SELECTED_FIELD_USER,
    selectedFieldUsers,
  };
}
