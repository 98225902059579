import { useEffect, useState } from "react";

import _ from "lodash";

import InputSelect from "components/Input/InputSelect";
import { IInputSelect } from "components/Input/InputSelect/InputSelect";
import useTranslations from "hooks/useTranslations";

import {
  fetchAllTemplateClientsApiCall,
  TLightTemplateClients,
} from "./redux/api";

export interface IWorkspaceSelectorProps
  extends Pick<IInputSelect, "onChange" | "viewMode" | "disabled"> {
  selectedWorkspace: IInputSelect["value"];
}

function WorkspaceSelector({
  onChange,
  selectedWorkspace,
  viewMode,
  disabled,
}: IWorkspaceSelectorProps) {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;
  const [loading, setLoading] = useState(true);
  const [templateClients, setTemplateClients] = useState<TLightTemplateClients>(
    []
  );

  useEffect(() => {
    (async () => {
      if (loading) {
        const response = await fetchAllTemplateClientsApiCall();
        const allClients = response?.data?.data?.template_clients;
        if (!_.isEmpty(allClients)) {
          setTemplateClients(allClients);
        }
        setLoading(false);
      }
    })();
  }, [loading]);

  const getAvailableTemplates = () => {
    const templatesToOptions = _.map(templateClients, function (tc) {
      return {
        key: tc.dbname.split("workspace_template_")[1],
        label: tc.name,
      };
    });

    templatesToOptions.push({
      key: "TEMPLATE",
      label: label.inputTemplateType.options.newTemplate,
    });
    return templatesToOptions;
  };

  return (
    <InputSelect
      dataTestId="workspace-selector"
      viewMode={viewMode}
      lang={{
        title: label.inputTemplateType.customMessage.selectTemplate,
        tooltip: label.inputTemplateType.customMessage.selectTemplateTooltip,
      }}
      name={"workspace_type"}
      options={getAvailableTemplates()}
      onChange={onChange}
      disabled={viewMode === "EDIT" || disabled}
      value={loading ? "" : selectedWorkspace}
      placeholder={loading ? lang.genericTerms.loading : ""}
    />
  );
}

export default WorkspaceSelector;
