import { useEffect,useState } from "react";

const useRelativeOffset = (elementRef: React.RefObject<any>) => {
  const [offset, setOffset] = useState({
    offsetParent: null,
    offsetTop: 0,
    offsetLeft: 0,
  });

  useEffect(() => {
    const getOffset = () => {
      return {
        offsetParent: elementRef?.current?.offsetParent,
        offsetTop: elementRef?.current?.offsetTop || 0,
        offsetLeft: elementRef?.current?.offsetLeft || 0,
      };
    };

    if (elementRef.current) {
      setOffset(getOffset());
    }
  }, [elementRef]);

  return offset;
};

export default useRelativeOffset;
