import React from "react";

import { Box, Fade, makeStyles } from "@material-ui/core";

import BasicCloseIcon from "components/Dialog/BasicDialog/Icons/BasicCloseIcon";
import { GEO_TRACKING_CHART } from "containers/dashboards/components/GeoTrackingChart/GeoTrackingChart";

const styles = {
  container: {
    position: "absolute",
    height: "100%",
    background: "white",
    right: 0,
    top: 0,
    borderRadius: 0,
    overflowX: "hidden",
    transition: "0.3s",
    display: "grid",
    gridTemplateRows: "50px 1fr",
    zIndex: GEO_TRACKING_CHART.Z_INDEXES.PANEL,
  },
};

const useStyles = makeStyles(styles as any);

export interface ISidePanelProps {
  open?: boolean;
  handleCloseSidePanel: () => void;
  children?: React.ReactNode;
  title: string;
}
function Sidepanel({
  children,
  handleCloseSidePanel,
  open,
  title,
}: ISidePanelProps) {
  const classes = useStyles();

  return (
    <Fade in={open} timeout={200}>
      <Box className={classes.container} width={"40%"}>
        <Box
          height={"50px"}
          display={"grid"}
          gridTemplateColumns={"1fr 50px"}
          boxShadow={"0px 1px 3px rgba(18, 78, 93, 0.15)"}
          alignItems={"center"}
          fontSize={"20px"}
          fontWeight={"bold"}
          paddingLeft={"8px"}
        >
          <Box>{title}</Box>
          <Box data-testid="close-button" onClick={handleCloseSidePanel}>
            <BasicCloseIcon />
          </Box>
        </Box>

        {children}
      </Box>
    </Fade>
  );
}

export default Sidepanel;
