export default () => ({
  OptimetriksDashboardScreen: {
    // Useful for the GPS Tracking Dashboard or not ? Because it breaks the logs table (starts overflowing)
    // display: "grid",
    // gridTemplateRows: "auto 1fr",
    // height: "100%",
  },
  BackButtonContainer: {
    marginTop: "-15px",
    float: "left",
  },
  printableElement: {
    display: "flex",
  },
  TitleDashboard: {
    padding: "5px",
    float: "left",
    lineHeight: "1.2",
    color: "#2c2c2c",
    marginRight: "167px",
  },
});
