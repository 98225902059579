import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  gaugeLabel: {
    textAlign: "left",
    fontSize: "8px",
    height: "auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export const LegendRange: React.FC<{
  color: string;
  label: string;
  width: string | number;
  height: string | number;
}> = ({ color, label, height, width }) => {
  const classes = useStyles({});

  return (
    <Box display="grid" gridTemplateRows="1fr auto">
      <Box style={{ backgroundColor: color }} width={width} height={height} />
      <Box
        className={classes.gaugeLabel}
        width={width}
        height={45}
        paddingRight={"8px"}
      >
        {label}
      </Box>
    </Box>
  );
};
