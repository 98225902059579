import { useState } from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import {
  refreshClientStateAction,
  resumeSubscriptionAction,
} from "containers/clients/redux/actions";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useActions } from "hooks/useActions";
import useTranslations from "hooks/useTranslations";

function ResumeSubscriptionButton() {
  const lang = useTranslations();

  const langKey =
    lang.containers.clients.subCategories.clients.createEditModal
      .inputRestartSubscription;

  const clientsLang = lang.containers.clients.subCategories.clients;

  const resumeSubscriptionModal =
    clientsLang.clientForm.automatedBillingTab.planOverview
      .restartSubscriptionModal;

  const refreshClientState = useActions(refreshClientStateAction);
  const resumeSubscription = useActions(resumeSubscriptionAction);

  const selectedClient = useSelector(getSelectedClient);
  const [processing, setProcessing] = useState(false);
  const [openRestartSubscriptionDialog, setOpenRestartSubscriptionDialog] =
    useState(false);

  function handleClickResumeSubscriptionButton() {
    setOpenRestartSubscriptionDialog(true);
  }
  function handleCloseRestartSubscriptionDialog() {
    setOpenRestartSubscriptionDialog(false);
  }

  async function doAfterCanceling() {
    setProcessing(false);
    refreshClientState(selectedClient.id).catch((e: any) => {
      alert(
        `Error when trying to refresh the client ${selectedClient.id} state: ${e.message}`
      );
    });
  }

  async function handleConfirmRestart() {
    setOpenRestartSubscriptionDialog(false);
    setProcessing(true);

    resumeSubscription({
      clientId: selectedClient.id,
      onFinally: doAfterCanceling,
    }).catch((e: any) => {
      alert(
        `Error when trying to resume the client's subscription (clientId: ${selectedClient.id}). Error: ${e.message}`
      );
    });
  }

  return (
    <>
      <Button
        onClick={handleClickResumeSubscriptionButton}
        disableElevation
        variant="contained"
        color="secondary"
        disabled={processing}
        data-testid="resume-subscription-button"
      >
        {processing ? <CircularProgress size={25} /> : langKey.title}
      </Button>

      <Box data-testid="resume-subscription-confirmation-dialog">
        <ConfirmationDialog
          open={openRestartSubscriptionDialog}
          title={resumeSubscriptionModal.title || ""}
          onConfirm={handleConfirmRestart}
          onClose={handleCloseRestartSubscriptionDialog}
          confirmText="Restart"
          maxWidth={600}
        >
          <Box>{resumeSubscriptionModal.description}</Box>
        </ConfirmationDialog>
      </Box>
    </>
  );
}
export default ResumeSubscriptionButton;
