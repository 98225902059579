import { IStyles } from "model/application/styles";

import { brownishGrey, secondaryColor } from "../../assets/colors";

export default (): IStyles => ({
  DialogActions: {
    marginRight: "8px",
    marginBottom: "8px",
    // height: "100%",
    background: "inherit",
  },
  FilterComponent: {
    position: "absolute",
    top: 0,
    background: "white",
    height: "300px",
    width: "100%",
    padding: "10px",
    zIndex: 1001,
    boxShadow: "5px 10px 18px #888888",
  },
  DashboardToolbox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  DashboardTitle: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1000,
    pointerEvents: "none",
  },
  DashboardContent: {
    padding: 0,
  },
  DashboardCloseButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    background: secondaryColor,
    zIndex: 1001,
  },
  "& > div[role=dialog]": {
    borderRadius: "8px !important",
  },
  Dialog: {
    filter: "drop-shadow(0px 20px 28px rgba(18, 78, 93, 0.15))",
    borderRadius: "4px !important",
  },
  dialogTitle: {
    boxShadow: "inset 0px -1px 0px #E9EBED",
    color: "#2c2c2c",
  },
  "& > *": {
    fontSize: "16px !important",
  },
  Description: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: brownishGrey,
    whiteSpace: "pre-wrap",
  },
});
