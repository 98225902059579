import TLang from "model/application/Lang";

export const getSectionTitle = (breadcrumb: string, lang: TLang) => {
  if (breadcrumb === "edit") {
    return lang.actions.edit;
  }
  if (breadcrumb === "create") {
    return lang.actions.create;
  }
  return breadcrumb;
};

export const getUsersSectionTitle = (
  breadcrumb: any,
  targetCategory: string,
  lang: TLang,
  defaultText?: string
) => {
  if (targetCategory === "create") {
    return lang.containers.users.subCategories.mobileUsers.createEditModal
      .section?.breadcrumbTitle;
  }
  if (breadcrumb?.props?.children === "Webuser") {
    return lang.mainLayout.sidebar.webUsers;
  }
  if (breadcrumb?.props?.children === "Mobileuser") {
    return lang.mainLayout.sidebar.mobileUsers;
  }
  return defaultText;
};

export const getClientSectionTitle = (
  breadcrumb: string,
  targetCategory: string,
  lang: TLang
) => {
  if (targetCategory === "create") {
    return lang.containers.clients.subCategories.clients.createEditModal
      .createTitle;
  }
  if (targetCategory === "edit") {
    return lang.containers.clients.subCategories.clients.createEditModal
      .editTitle;
  }
  return breadcrumb;
};
