import { ReactNode, useRef } from "react";

import { makeStyles, TableCell, TableCellProps } from "@material-ui/core";
import classNames from "classnames";

import { OffWhiteLight } from "assets/colors";
import useRelativeOffset from "hooks/useRelativeOffset";

interface ISimpleTableCell extends TableCellProps {
  isHeaderCell?: boolean;
  isSticky?: boolean;
  children?: ReactNode;
}

const useStyles = makeStyles({
  SimpleCell: {
    padding: "8px 24px 8px 8px",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&.headerCell": {
      fontWeight: 600,
    },
    "&.sticky": {
      backgroundColor: OffWhiteLight,
      position: "sticky",
    },
  },
});

const SimpleTableCell = ({
  isHeaderCell,
  isSticky,
  children,
}: ISimpleTableCell) => {
  const classes = useStyles();
  const elementRef = useRef();
  const { offsetLeft } = useRelativeOffset(elementRef);
  return (
    <TableCell
      ref={elementRef}
      className={classNames(
        classes.SimpleCell,
        isHeaderCell && "headerCell",
        isSticky && "sticky"
      )}
      {...(isSticky && { style: { left: offsetLeft } })}
    >
      {children}
    </TableCell>
  );
};

export default SimpleTableCell;
