import useTranslations from "hooks/useTranslations";
import {
  IDashboard,
  IKPI,
  INivoConfiguration,
  KPI_TYPE,
} from "model/entities/Dashboard";

import CarouselChart from "../Carousel/CarouselChart";
import MatrixChart from "../Matrix/MatrixChart";
import PdfPrintableChartWrapper from "../Pdf/PdfPrintableChartWrapper";
import TableChart from "../Table/TableChart";
import Chart from "./Chart";
import { ChartDataUtils } from "./ChartDataUtils";
import ChartErrorBoundary from "./ChartErrorBoundary";
import CustomBarChart from "./CustomBarChart";
import CustomersMapChart from "./CustomersMapChart/CustomersMapChart";
import { getLegendFromChart } from "./CustomersMapChart/utils/getLegendFromChart";
import HeatmapChart from "./HeatmapChart";
import { HistogramChart } from "./HistogramChart";
import OptimetriksCalendar from "./OptimetriksCalendar";
import OptimetriksLineChart from "./OptimetriksLineChart";
import { OptimetriksPieChart } from "./OptimetriksPieChart";
import OptimetriksScatterPlotChart from "./OptimetriksScatterPlotChart";

interface IGenericChart {
  chart: IKPI;
  kpiType: KPI_TYPE;
  chartWithFormattedData: IKPI;
  configuration: INivoConfiguration;
  storeDataForDownload: any;
  dashboard: IDashboard;
  onDownloadExcel?: () => void;
}

const GenericChart = ({
  chart,
  kpiType: kpi,
  chartWithFormattedData,
  configuration,
  storeDataForDownload,
  dashboard,
  onDownloadExcel,
}: IGenericChart) => {
  let ch: any;
  const lang = useTranslations();
  const uid = ChartDataUtils.generateUid();

  const gridValues =
    Chart.settings[chart.type] && Chart.settings[chart.type].gridValues
      ? Chart.settings[chart.type].gridValues.lg
      : 12;

  const pdfElementWidth = chart.full_width ? 100 : (100 / 12) * gridValues;

  switch (kpi) {
    case KPI_TYPE.LINE_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.LINE_CHART}
          width={pdfElementWidth}
        >
          <OptimetriksLineChart
            uid={uid}
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.MULTIPLE_LINE_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.MULTIPLE_LINE_CHART}
          width={pdfElementWidth}
        >
          <OptimetriksLineChart
            uid={uid}
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.BAR_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.BAR_CHART}
          width={pdfElementWidth}
        >
          <CustomBarChart
            uid={uid}
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.BAR_CHART_HORIZONTAL: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.BAR_CHART_HORIZONTAL}
          width={pdfElementWidth}
        >
          <CustomBarChart
            uid={uid}
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.STACKED_BAR_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.STACKED_BAR_CHART}
          width={pdfElementWidth}
        >
          <CustomBarChart
            uid={uid}
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.HEATMAP_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.HEATMAP_CHART}
          width={pdfElementWidth}
        >
          <HeatmapChart
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
            lang={lang}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.CALENDAR_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.CALENDAR_CHART}
          width={100}
        >
          <OptimetriksCalendar
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.SCATTER_PLOT_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.SCATTER_PLOT_CHART}
          width={pdfElementWidth}
        >
          <OptimetriksScatterPlotChart
            chart={chartWithFormattedData}
            nivoConfiguration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.MATRIX: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.MATRIX}
          width={pdfElementWidth}
        >
          <MatrixChart
            data={chartWithFormattedData.data}
            onMatrixDataLoaded={storeDataForDownload}
            chart={chart}
            lang={lang}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.TABLE: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.TABLE}
          width={pdfElementWidth}
        >
          <TableChart
            dashboardId={dashboard.id}
            onDownloadExcel={onDownloadExcel}
            fullWidth={configuration.fullWidth}
            thresholdTotalRow={dashboard.threshold_total_row}
            chart={chartWithFormattedData}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.PIE_CHART: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.PIE_CHART}
          width={pdfElementWidth}
        >
          <OptimetriksPieChart
            chart={chartWithFormattedData}
            configuration={configuration}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    // case KPI_TYPE.TIMELINE: {
    //   ch = (
    //     <PdfPrintableChartWrapper
    //       title={chart.title}
    //       type={KPI_TYPE.TIMELINE}
    //       width={pdfElementWidth}
    //     >
    //       <TimelineSidePanel configuration={configuration} />
    //     </PdfPrintableChartWrapper>
    //   );
    //   break;
    // }
    case KPI_TYPE.CAROUSEL: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.CAROUSEL}
          width={pdfElementWidth}
        >
          <CarouselChart
            data={chartWithFormattedData.data}
            fullWidth={configuration.fullWidth}
            lang={lang}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.HISTOGRAM: {
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.HISTOGRAM}
          width={pdfElementWidth}
        >
          <HistogramChart
            chart={chartWithFormattedData}
            configuration={configuration}
            lang={lang}
          />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    case KPI_TYPE.CUSTOMERS_MAP: {
      const legend = getLegendFromChart(chart);
      ch = (
        <PdfPrintableChartWrapper
          title={chart.title}
          type={KPI_TYPE.CUSTOMERS_MAP}
          width={pdfElementWidth}
        >
          <CustomersMapChart chart={chartWithFormattedData} legend={legend} />
        </PdfPrintableChartWrapper>
      );
      break;
    }
    default: {
      ch = <p>No chart to display</p>;
    }
  }
  return <ChartErrorBoundary lang={lang}>{ch}</ChartErrorBoundary>;
};

export default GenericChart;
