import { Component } from "react";

import { Paper } from "@material-ui/core";

import { PERCENTAGE_KEY } from "./Chart";
import { ChartDataUtils, NUMBER_SEPARATOR } from "./ChartDataUtils";

interface IProps {
  slice?: any;
  id: any;
  indexValue?: any;
  value?: any;
  color?: string;
  unit?: string;
}

export class SliceTooltip extends Component<IProps> {
  props: IProps;

  constructor(props: IProps) {
    super(props);
    this.props = props;
  }

  getTooltipContent(points: any) {
    const { unit } = this.props;
    return points.map((point: any, index: any) => (
      <div
        key={point.id + index}
        style={{
          color: point.color,
          margin: "2px",
        }}
      >
        <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
          <span>
            {point.indexValue ? point.indexValue + " : " : point.id + " : "}
          </span>
          {ChartDataUtils.formatValue(point.value, NUMBER_SEPARATOR)}
          {unit}
          {point.indexValue && point.id && point.id !== "y" && point.id !== "x"
            ? ` - ${point.id}`
            : ""}
        </p>
      </div>
    ));
  }
  randomColor = () => {
    return (
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
        .toUpperCase()
    );
  };

  public render() {
    let points = [];
    const { slice, id, indexValue, value, color } = this.props;
    if (!slice) {
      points = [{ id, indexValue, color: color || "#fff", value }];
    } else {
      points = slice;
    }
    points = points.map((point: any) => {
      point.indexValue = String(point.indexValue);
      point.indexValue =
        point.indexValue &&
        !`${point.indexValue}`.startsWith("undefined") &&
        !(point.indexValue === "x" || point.indexValue === "y")
          ? point.indexValue
          : null;
      return point;
    });
    if (id === PERCENTAGE_KEY) {
      return <></>;
    }
    return (
      <div
        style={{
          padding: "4px",
        }}
      >
        {slice ? (
          <Paper elevation={3}>{this.getTooltipContent(points)}</Paper>
        ) : (
          <div>{this.getTooltipContent(points)}</div>
        )}
      </div>
    );
  }
}
export default SliceTooltip;
