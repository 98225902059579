import { ReactNode } from "react";

import { Box, Button, makeStyles } from "@material-ui/core";

import { Black } from "assets/colors";
import useTranslations from "hooks/useTranslations";

const useStyles = makeStyles({
  MainTitle: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
    color: Black,
    marginBottom: "24px",
  },
  FormWrapperButtons: {},
});

interface IFormWrapper {
  title?: string;
  discardText?: string;
  saveText?: string;
  onSave?: () => void;
  onDiscard?: () => void;
  disableSave?: boolean;
  children: ReactNode;
}

const FormWrapper = ({
  title,
  onDiscard,
  onSave,
  disableSave,
  children,
  discardText,
  saveText,
}: IFormWrapper) => {
  const classes = useStyles();
  const lang = useTranslations();
  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      marginBottom="-20px"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {title && <h3 className={classes.MainTitle}>{title}</h3>}

        <div className={classes.FormWrapperButtons}>
          {onDiscard && (
            <Button
              color="default"
              variant="text"
              disableElevation
              onClick={onDiscard}
            >
              {discardText || lang.genericTerms.discardChanges}
            </Button>
          )}

          {onSave && (
            <Button
              style={{ marginLeft: 16 }}
              color="secondary"
              variant="contained"
              disableElevation
              onClick={onSave}
              disabled={disableSave}
            >
              {saveText || lang.genericTerms.saveChanges}
            </Button>
          )}
        </div>
      </Box>

      {children}
    </Box>
  );
};

export default FormWrapper;
