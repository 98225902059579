/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import { IIntegration as IBEIntegration } from "fieldpro-tools";

import apiService from "api/apiService";
import { IIntegration } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareIntegrationForBackend } from "./utils";

export const INTEGRATIONS_ENDPOINT = `integrations`;

export type TCreateIntegrationFunc = (
  actionName: string,
  integration: IIntegration
) => Promise<IServerResponse<ICreateIntegrationResponse>>;
export interface ICreateIntegrationResponse {
  integration: IIntegration;
}

export type TUpdateIntegrationFunc = (
  actionName: string,
  integration: IIntegration
) => Promise<IServerResponse<IUpdateIntegrationResponse>>;
export interface IUpdateIntegrationResponse {}

export type TDeleteIntegrationFunc = (
  actionName: string,
  integrationId: string
) => Promise<IServerResponse<IDeleteIntegrationResponse>>;
export interface IDeleteIntegrationResponse {}

export type TFetchIntegrationsFunc = () => Promise<
  IServerResponse<IFetchIntegrationsResponse>
>;
export interface IFetchIntegrationsResponse {
  integrations: IBEIntegration[];
}
export type TRunIntegrationFunc = (
  actionName: string,
  integration: IIntegration
) => Promise<IServerResponse<IRunIntegrationResponse>>;

export interface IRunIntegrationResponse {
  success: boolean;
}
//api calls for integrations
/**
 * Creates a new integration
 * @param {Object} integration Integration details
 */
export const createIntegrationApiCall: TCreateIntegrationFunc = (
  actionName,
  integration
) => {
  const payload = prepareIntegrationForBackend(integration);
  return apiService.post(`/${INTEGRATIONS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted integration
 * @param {Object} integration Detail of the new integration
 */
export const updateIntegrationApiCall: TUpdateIntegrationFunc = (
  actionName,
  integration
) => {
  const payload = prepareIntegrationForBackend(integration);
  return apiService.patch(
    `/${INTEGRATIONS_ENDPOINT}/${integration.id}`,
    payload,
    actionName
  );
};

/**
 * Delete integration API call makes a POST request to delete a single integration
 * @param {String} integration Id of the integration to delete
 * @return {Promise}
 * */
export const deleteIntegrationApiCall: TDeleteIntegrationFunc = (
  actionName,
  integrationId
) => {
  return apiService.delete(
    `/${INTEGRATIONS_ENDPOINT}/${integrationId}`,
    {},
    actionName
  );
};

/**
 * Api call to fetch all
 */
export const fetchIntegrationsApiCall: TFetchIntegrationsFunc = () => {
  return apiService.get(`/${INTEGRATIONS_ENDPOINT}`);
};
