/**
 * @notes: reducer reducer
 */

import { ITransformation } from "model/entities/Job";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareTransformationsForFrontend } from "./utils";

export interface ITransformationState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  isRunning: boolean;
  isFetchingJobHistory: boolean;
  selectedTransformation: ITransformation | null;
  transformations: ITransformation[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_TRANSFORMATION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_TRANSFORMATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_TRANSFORMATION_SUCCESS: {
      const { transformation } =
        action as actionCreators.ICreateTransformationSuccessAction;

      return {
        ...state,
        isCreating: false,
        transformations: insertNewItemToArr(
          state.transformations,
          transformation
        ),
      };
    }
    case types.FETCH_JOB_HISTORY_BEGIN:
      return {
        ...state,
        isFetchingJobHistory: true,
      };

    case types.FETCH_JOB_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingJobHistory: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_JOB_HISTORY_SUCCESS: {
      return {
        ...state,
        isFetchingJobHistory: false,
      };
    }

    case types.UPDATE_TRANSFORMATION_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_TRANSFORMATION_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_TRANSFORMATION_SUCCESS: {
      const { transformation } =
        action as actionCreators.IUpdateTransformationSuccessAction;

      const transformations = state.transformations.map((t) =>
        t.id === transformation.id ? transformation : t
      );

      return {
        ...state,
        isUpdating: false,
        transformations,
      };
    }

    case types.DELETE_TRANSFORMATION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_TRANSFORMATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_TRANSFORMATION_SUCCESS: {
      const { id } =
        action as actionCreators.IDeleteTransformationSuccessAction;

      const transformations = state.transformations.filter((t) => t.id !== id);

      return {
        ...state,
        isDeleting: false,
        transformations,
      };
    }

    case types.FETCH_ALL_TRANSFORMATIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_TRANSFORMATIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_TRANSFORMATIONS_SUCCESS: {
      const { transformations } =
        action as actionCreators.IFetchTransformationsSuccessAction;
      return {
        ...state,
        isFetchingAll: false,
        transformations: prepareTransformationsForFrontend(transformations),
      };
    }

    default:
      return state;
  }
}
