/**
 * See official example: https://visgl.github.io/react-map-gl/examples/draw-polygon
 * Code adapted from: https://github.com/visgl/react-map-gl/tree/7.1-release/examples/draw-polygon
 * */

import { useState } from "react";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";
import _ from "lodash";
import type { ControlPosition, MapInstance } from "react-map-gl";
import { useControl } from "react-map-gl";
import { MapContextValue } from "react-map-gl/dist/esm/components/map";

type DrawControlProps = MapboxDraw.MapboxDrawOptions & {
  initialFeatures?: Feature[];
  defaultModeOptions?: { featureId: number | string };
  position?: ControlPosition;
  onCreate: (evt: MapboxDraw.DrawCreateEvent) => void;
  onUpdate: (evt: MapboxDraw.DrawUpdateEvent) => void;
  onDelete: (evt: MapboxDraw.DrawDeleteEvent) => void;
  onModeChange: (control: MapboxDraw) => void;
};

const DrawControl = (props: DrawControlProps) => {
  const {
    initialFeatures,
    onCreate,
    onUpdate,
    onDelete,
    onModeChange,
    position,
    defaultModeOptions,
    ...options
  } = props;

  const [control, setControl] = useState<MapboxDraw>();

  const onCreateControl = (options: MapboxDraw.MapboxDrawOptions) => {
    const control = new MapboxDraw({
      ...options,
      // Will crash if we don't provide the defaultModeOptions
      // So we call control.changeMode in onAdd (see below)
      ...(options.defaultMode === "direct_select"
        ? { defaultMode: "draw_polygon" }
        : undefined),
    });

    setControl(control);
    return control;
  };

  const onModeChangeWrapped = () => {
    control && onModeChange(control);
  };

  // const onChangeSelect = (event: any) => {
  //   console.log(">> onDrawSelectionChange", {
  //     event,
  //     controlMode: control?.getMode(),
  //     selected: control?.getSelectedIds(),
  //   });
  // };

  // Add mapbox-gl event listeners
  const onAdd = ({ map }: MapContextValue<MapInstance>) => {
    map.on("draw.create", onCreate);
    map.on("draw.update", onUpdate);
    map.on("draw.delete", onDelete);
    map.on("draw.modechange", onModeChangeWrapped);
    // map.on("draw.selectionchange", onChangeSelect);

    if (!control) {
      return;
    }

    if (initialFeatures && !_.isEmpty(initialFeatures)) {
      control.set({
        type: "FeatureCollection",
        features: initialFeatures,
      });
    }

    if (options.defaultMode === "direct_select") {
      if (!defaultModeOptions) {
        console.error("No defaultMode options but mode is direct_select");
        return;
      }

      try {
        control.changeMode(
          "direct_select",
          defaultModeOptions as { featureId: string } // hack, it needs to be a number to work (with Source's generateId)
        );
        // changeMode crashes when featureId is 0, or when two IDs are identical ?
        // But it works with all other IDs
        // To avoid this, we remove all feature.id or shape.id before passing to mapbox Layer
        // And we should avoid storing them in the DB to avoid confusion too
      } catch (e) {
        console.error(e);
      }
    }
  };

  // Clear mapbox-gl event listeners
  const onRemove = ({ map }: MapContextValue<MapInstance>) => {
    map.off("draw.create", onCreate);
    map.off("draw.update", onUpdate);
    map.off("draw.delete", onDelete);
    map.off("draw.modechange", onModeChangeWrapped);
    // map.off("draw.selectionchange", onChangeSelect);
  };

  useControl<MapboxDraw>(() => onCreateControl(options), onAdd, onRemove, {
    position,
  });

  return null;
};

export default DrawControl;
