import { useCallback, useState } from "react";

import { Box, Button } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import BodyAndDrawerLayout from "components/Layout/BodyAndDrawerLayout";
import { TCategoryType, TRowType } from "components/Table/model";
import { allListsSelector } from "containers/lists/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IList, IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import { IItemGalleryItem } from "./ItemGallery";
import MatrixEditorBody from "./MatrixEditorBody";
import MatrixEditorDrawer, {
  IMatrixAnswerForm,
  IMatrixRowState,
} from "./MatrixEditorDrawer";

export interface IMatrixEditorDialogProps {
  dataTestid?: string;
  open: boolean;
  list: IList;
  categories: TCategoryType[];
  rows: TRowType[];
  customFields: IQuestion[] | IListSchema[];
  customFieldMatrix: IQuestion | IListSchema;
  onClose: () => void;
  onClickViewSummary?: () => void;
  onValidate?: (
    selectedItem: IItemGalleryItem,
    answers: IMatrixAnswerForm
  ) => void;
  onItemRemove?: (item: IItemGalleryItem) => void;
}

function MatrixEditorDialog({
  dataTestid,
  open,
  categories,
  customFields,
  list,
  rows,
  onClose,
  onClickViewSummary,
  onValidate,
  onItemRemove,
  customFieldMatrix,
}: IMatrixEditorDialogProps) {
  const allLists = useSelector(allListsSelector);
  // TODO: merge selectedItem and editedItem ? Risk of confusion between the 2 states
  const [selectedItem, setSelectedItem] = useState<
    IItemGalleryItem | undefined
  >(undefined);

  const [editedItem, setEditedItem] = useState<IMatrixRowState | undefined>(
    undefined
  );
  const lang = useTranslations();
  const langKey = lang.components.inputMatrixEditor;
  const listLang = lang.containers.lists.subCategories;
  const handleItemClick = useCallback(
    (item: IItemGalleryItem) => {
      if (!item) {
        return;
      }

      setSelectedItem(item);
      setEditedItem({
        id: item?.id,
        name: item?.label || "",
        image: item?.imageURL,
        customFields: customFields || [],
        defaultAnswers: _.find(rows, function (row) {
          return _.values(row).includes(item.id);
        }),
      });
    },
    [customFields, rows]
  );

  const getTitleForFilledItems = () => {
    let subject = listLang.items.subject;
    if (rows.length > 1) subject = listLang.items.subjects;
    return formatString(
      langKey.createEditModal.section?.filledItemsCounter ?? "",
      [rows.length, subject]
    );
  };

  const handleItemRemove = (item: IItemGalleryItem) => {
    if (onItemRemove) onItemRemove(item);
  };

  const handleCancel = () => {
    setEditedItem(undefined);
  };

  const handleValidate = (answers: IMatrixAnswerForm) => {
    setEditedItem(undefined);

    if (selectedItem && onValidate) {
      // why not validate editedItem ?
      onValidate(selectedItem, answers);
    }
  };

  const handleClickViewSummary = () => {
    if (onClickViewSummary) onClickViewSummary();
  };

  // TODO: Isn't this the same thing ?
  const linkedList = _.find(allLists, {
    id: list.id,
  });
  // TODO: FP-6214 - make this scalable. Maybe resolve MEs with ITEM scope in the BE instead ?
  // TODO: Isn't it list.table_items ?
  const fullItem = _.find(linkedList?.items, { _id: selectedItem?.id });

  return (
    <BasicDialog
      data-testid={dataTestid}
      open={open}
      minWidth={"90vw"}
      minHeight={"90vh"}
      maxHeight={"90vh"}
    >
      <BasicDialogTitle>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            float: "left",
            columnGap: "16px",
            fontWeight: "bold",
          }}
        >
          {langKey.createEditModal.createTitle}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            float: "right",
            columnGap: "16px",
          }}
        >
          <Box>{getTitleForFilledItems()}</Box>
          <Box>
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              onClick={handleClickViewSummary}
              data-testid="view-summary-button"
            >
              {langKey.options.viewSummary}
            </Button>
          </Box>
          <Box onClick={onClose} data-testid="close-button">
            <BasicCloseIcon />
          </Box>
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent noOverflow style={{ userSelect: "none" }}>
        <BodyAndDrawerLayout height={"100vh"} minDrawerWidth={12}>
          <MatrixEditorBody
            list={list}
            categories={categories}
            rows={rows}
            onClickItem={handleItemClick}
            onRemoveItem={handleItemRemove}
            customFieldMatrix={customFieldMatrix}
          />
          <MatrixEditorDrawer
            key={editedItem?.id} // reset state when the item changes
            onCancel={handleCancel}
            onValidate={handleValidate}
            rowState={editedItem}
            item={fullItem} // for ME resolution
            list={list}
          />
        </BodyAndDrawerLayout>
      </BasicDialogContent>
    </BasicDialog>
  );
}

export default MatrixEditorDialog;
