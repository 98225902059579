import { INotificationState } from "./reducer";

const initialState: INotificationState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  isRunning: false,
  isFetchingJobHistory: false,
  selectedNotification: null,
  notifications: [],
  errors: [],
};

export default initialState;
