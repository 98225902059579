import { IHttpRequest } from "model/entities/Job";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all http_requests from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllHttpRequests = (state: IRootState) =>
  state.jobs.http_requests.http_requests;

/**
 * Selector to get all HttpRequests from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allHttpRequestsSelector = customSelectorCreator(
  [getAllHttpRequests],
  (allHttpRequests: IHttpRequest[]) => {
    // map all the http_requests
    return allHttpRequests;
  }
);

/**
 * Gets the status of fetching a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingAllHttpRequests = (state: IRootState) =>
  state.jobs.http_requests.isFetchingAll;

/**
 * Gets the status of creating a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingHttpRequest = (state: IRootState) =>
  state.jobs.http_requests.isCreating;

/**
 * Gets the status of updating a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingHttpRequest = (state: IRootState) =>
  state.jobs.http_requests.isUpdating;

/**
 * Gets the status of deleting a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingHttpRequest = (state: IRootState) =>
  state.jobs.http_requests.isDeleting;

/**
 * Gets the status of running a htp request
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRunningHttpRequest = (state: IRootState) =>
  state.jobs.http_requests.isRunning;
