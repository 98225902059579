import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { IDSource } from "__utils__/utils";

import { PDF_PRINTABLE_ELEMENT } from "../Chart/Chart";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface ILegend {
  label: string;
  color: string;
}

interface ILegendProps {
  legends: ILegend[];
}

export const Legend: React.FunctionComponent<ILegendProps> = ({ legends }) => {
  const classes = useStyles();
  const typeAttr = {};
  typeAttr[`${PDF_PRINTABLE_ELEMENT}`] = "legend";
  return (
    <div {...typeAttr}>
      {legends.map((legend: ILegend) => {
        return (
          <span
            className={classes.container}
            key={`${legend.label}-${IDSource()}`}
          >
            <div
              className={classes.square}
              style={{ backgroundColor: legend.color }}
            ></div>
            <span className={classes.label}>{legend.label}</span>
          </span>
        );
      })}
    </div>
  );
};

export default Legend;
