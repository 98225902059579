import { useState } from "react";

import { Button, makeStyles } from "@material-ui/core";
import { Redirect } from "react-router";

import { blue } from "assets/colors";
import { useTranslations } from "hooks";
import { LISTS_ROUTE } from "model/constants/routes";

const useStyles = makeStyles({
  CustomersMapBanner: {
    background: blue,
    alignItems: "center",
    color: "white",
    justifyContent: "center",
    fontSize: "small",
    verticalAlign: "middle",
    display: "flex",
    padding: "8px",
    position: "absolute",
    top: 0,
    left: -20,
    right: 0,
    zIndex: 2,
  },
  WarningButton: {
    marginLeft: "10px",
    whiteSpace: "nowrap" as const,
    flexShrink: 0,
  },
});

const CustomersMapBanner = () => {
  const lang = useTranslations();
  const classes = useStyles();

  const [redirectTo, setRedirectTo] = useState<string | null>();

  const onClick = () => {
    setRedirectTo(`${LISTS_ROUTE}/customer`);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div
      data-testid="customers-map-banner"
      className={classes.CustomersMapBanner}
    >
      <span>{lang.components.maps.noCustomerWarningBar.warningMessage}</span>

      <Button
        data-testid="go-to-customer"
        className={classes.WarningButton}
        variant="contained"
        color="default"
        onClick={onClick}
        size="small"
      >
        {lang.components.maps.noCustomerWarningBar.createCustomer}
      </Button>
    </div>
  );
};

export default CustomersMapBanner;
