import { addQueryParameters } from "fieldpro-tools";

import apiService from "api/apiService";
import { IQuery } from "containers/lists/utils";
import { IServerResponse } from "redux/actions/appActions";

import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";
import { ICustomerLegendFE } from "./types";

const MAP_ENDPOINT = "map";

/**
 * Fetch light customer points, without a limit.
 * Optimised in the back-end for large volumes (Customer bases can go up to 250k items).
 */
export type TFetchCustomersLayer = (query?: IQuery) => Promise<
  IServerResponse<{
    customers: ICustomerLocation[];
  }>
>;
export type TFetchCustomersLegend = (query?: {
  tag?: string;
  [key: string]: any;
}) => Promise<
  IServerResponse<{
    legends: ICustomerLegendFE;
  }>
>;

export type TFetchCustomerAttributeServerResponseData = {
  _id: string;
  name: string;
  [key: string]: any;
};
export type TFetchCustomersAttribute = (
  tag: string,
  query?: {
    [key: string]: any;
  }
) => Promise<
  IServerResponse<{
    value: Array<TFetchCustomerAttributeServerResponseData>;
  }>
>;

export const fetchCustomersLayerApiCall: TFetchCustomersLayer = async (
  query
) => {
  const finalUrl = addQueryParameters(
    `${MAP_ENDPOINT}/layers/customers`,
    query ?? {}
  );
  return apiService.get(finalUrl);
};
export const fetchCustomersLegendApiCall: TFetchCustomersLegend = async (
  query
) => {
  const finalUrl = addQueryParameters(`${MAP_ENDPOINT}/legends`, query ?? {});
  return apiService.get(finalUrl);
};
export const fetchCustomersAttributeApiCall: TFetchCustomersAttribute = async (
  tag,
  query
) => {
  const finalUrl = addQueryParameters(
    `${MAP_ENDPOINT}/customers/attribute/${tag}`,
    query ?? {}
  );
  return apiService.get(finalUrl);
};
