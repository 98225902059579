import { ITrigger } from "fieldpro-tools";

import { IAction } from "redux/store/model";
import {
  insertNewItemToArr,
  removeObjectFromArray,
  updateObjectInArray,
} from "utils/reducerUtils";

import {
  ICreateTriggerSuccessAction,
  IDeleteTriggerSuccessAction,
  IUpdateTriggerSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface ITriggerExpression {
  triggers: ITrigger[];
  isloading: boolean;
  isCreating: false;
  isDeleting: false;
  isUpdating: false;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_ALL_TRIGGERS_BEGIN:
      return {
        ...state,
        isloading: true,
      };
    case types.FETCH_ALL_TRIGGERS_FAILURE:
      return {
        ...state,
        isloading: false,
      };
    case types.FETCH_ALL_TRIGGERS_SUCCESS:
      const { triggers } = action as any;
      return {
        ...state,
        isloading: false,
        triggers,
      };

    case types.DELETE_TRIGGER_SUCCESS: {
      const { triggerId } = action as IDeleteTriggerSuccessAction;

      const triggers = removeObjectFromArray(state.triggers, triggerId);

      return {
        ...state,
        isDeleting: false,
        triggers,
      };
    }
    case types.CREATE_TRIGGER_BEGIN: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case types.CREATE_TRIGGER_FAILURE: {
      return {
        ...state,
        isCreating: false,
      };
    }

    case types.CREATE_TRIGGER_SUCCESS: {
      const { trigger } = action as ICreateTriggerSuccessAction;

      const triggers = insertNewItemToArr(state.triggers, trigger);
      return {
        ...state,
        isCreating: false,
        triggers,
      };
    }

    case types.UPDATE_TRIGGER_BEGIN: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case types.UPDATE_TRIGGER_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case types.UPDATE_TRIGGER_SUCCESS: {
      const { trigger } = action as IUpdateTriggerSuccessAction;
      const newAllTriggers = updateObjectInArray(state.triggers, trigger);

      return {
        ...state,
        isUpdating: false,
        triggers: newAllTriggers,
      };
    }
  }
  return state;
}
