import { IMetaExpressionInTable } from "model/entities/MetaExpression";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IMetaExpressionsState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isComputing: boolean;
  selectedMetaExpression: IMetaExpressionInTable | null;
  metaExpressions: IMetaExpressionInTable[];
  metaExpressionsSolved: { [metaExpr: string]: string };
  errors: any[];
}

/**
 * MetaExpressions reducer takes current state and action and returns a new state for the MetaExpression object in redux store
 * @param state initial state of MetaExpressions
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_META_EXPRESSION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_META_EXPRESSION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_META_EXPRESSION_SUCCESS: {
      const { metaExpression } =
        action as actionCreators.ICreateMetaExpressionSuccessAction;

      return {
        ...state,
        isCreating: false,
        metaExpressions: insertNewItemToArr(
          state.metaExpressions,
          metaExpression
        ),
      };
    }

    case types.UPDATE_META_EXPRESSION_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_META_EXPRESSION_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_META_EXPRESSION_SUCCESS: {
      const { metaExpression } =
        action as actionCreators.IUpdateMetaExpressionSuccessAction;

      const metaExpressions = state.metaExpressions.map((t) =>
        t.expression === metaExpression.expression ? metaExpression : t
      );

      return {
        ...state,
        isUpdating: false,
        metaExpressions,
      };
    }

    case types.DELETE_META_EXPRESSION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_META_EXPRESSION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_META_EXPRESSION_SUCCESS: {
      const { expression } =
        action as actionCreators.IDeleteMetaExpressionSuccessAction;

      const metaExpressions = state.metaExpressions.filter(
        (t) => t.expression !== expression
      );

      return {
        ...state,
        isDeleting: false,
        metaExpressions,
      };
    }

    case types.COMPUTE_META_EXPRESSION_BEGIN:
      return {
        ...state,
        isComputing: true,
      };

    case types.COMPUTE_META_EXPRESSION_FAILURE: {
      return {
        ...state,
        isComputing: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.COMPUTE_META_EXPRESSION_SUCCESS: {
      const { metaExpressionsSolved } =
        action as actionCreators.IComputeMetaExpressionSuccessAction;

      return {
        ...state,
        isComputing: false,
        metaExpressionsSolved: {
          ...state.metaExpressionsSolved,
          ...metaExpressionsSolved,
        },
      };
    }

    case types.RESET_META_EXPRESSION_SUCCESS: {
      return {
        ...state,
        isComputing: false,
        metaExpressionsSolved: {},
      };
    }

    case types.FETCH_ALL_META_EXPRESSIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_META_EXPRESSIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_META_EXPRESSIONS_SUCCESS: {
      const { meta_expressions } =
        action as actionCreators.IFetchMetaExpressionsSuccessAction;
      return {
        ...state,
        isFetchingAll: false,
        metaExpressions: meta_expressions,
      };
    }
    default:
      return state;
  }
}
