import { Box, makeStyles, MenuItem, MenuList } from "@material-ui/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import _ from "lodash";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { TColumnType } from "components/Table/model";

import style from "../Card/styles";

export interface IColumnSelectorMenuSection {
  isShow: boolean; //
  onClickItem: (id: string) => void;
  title: string;
  options: TColumnType[];
  onClickAll?: () => void;
  onDragEnd: (param: DropResult) => void;
}

const useStyle = makeStyles(style as any);

const ColumnSelectorMenuSection: React.FunctionComponent<
  IColumnSelectorMenuSection
> = ({ isShow, title, options, onClickAll, onDragEnd, onClickItem }) => {
  const classes = useStyle();
  const type = isShow ? "show" : "hide";

  return (
    <>
      {
        /*condition === true  &&*/ options.length > 0 && (
          <Box>
            <div className={classes.showColumnStyle}>
              <span
                style={{ color: "grey", fontSize: "12px", marginLeft: "10px" }}
              >
                {title}
              </span>
              {onClickAll && (
                <span
                  onClick={onClickAll}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "grey ",
                    fontSize: "12px ",
                    marginRight: "10px",
                  }}
                >
                  {isShow ? "Hide" : "Show"} All
                </span>
              )}
            </div>

            <DragDropContext onDragEnd={(params) => onDragEnd(params)}>
              <MenuList style={{ marginTop: "-5px" }} title={title}>
                <Droppable droppableId="droppable-1">
                  {(provided, _snapshot) => (
                    <div
                      ref={provided.innerRef}
                      // style={{
                      //   backgroundColor: snapshot.isDraggingOver
                      //     ? "blue"
                      //     : undefined,
                      // }}
                      {...provided.droppableProps}
                    >
                      {_.map(options, (option, i: number) => (
                        <Draggable
                          key={option.name}
                          draggableId={"draggable-" + option.name}
                          data-testid={"draggable-" + option.name}
                          index={i}
                        >
                          {(provided1, snapshot1) => (
                            <div
                              ref={provided1.innerRef}
                              {...provided1.draggableProps}
                              {...provided1.dragHandleProps}
                              style={{
                                ...provided1.draggableProps.style,
                                boxShadow: snapshot1.isDragging
                                  ? "0 0 .4rem #666"
                                  : "none",
                              }}
                            >
                              <MenuItem
                                key={option.name}
                                className={classes.showColumnStyle}
                              >
                                <IconLabel label={option.label} />
                                <span
                                  className="eyeContainer"
                                  onClick={() => onClickItem(option.name)}
                                  style={{ cursor: "pointer", display: "none" }}
                                  title={isShow ? "Hide" : "Show"}
                                >
                                  {type.toLowerCase() === "show" ? (
                                    <VisibilityOffOutlinedIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </span>
                              </MenuItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </MenuList>
            </DragDropContext>
          </Box>
        )
      }
    </>
  );
};

export const IconLabel = ({ label }: any) => {
  return (
    <div style={{ display: "flex", columnGap: "10px" }}>
      <DragIndicatorIcon />
      <span> {label}</span>
    </div>
  );
};

export default ColumnSelectorMenuSection;
