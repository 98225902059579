import { ReactNode } from "react";

import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  ContentContainer: {
    height: "100%",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    "&:not(.fullWidth)": {
      maxWidth: "900px",
      margin: "auto",
    },
  },
});

interface IContentContainer {
  fullWidth?: boolean;
  children?: ReactNode;
}

const ContentContainer = ({ fullWidth, children }: IContentContainer) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.ContentContainer, fullWidth && "fullWidth")}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
