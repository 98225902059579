import { useState } from "react";

import { Box, Button, Chip, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import _ from "lodash";

import { GreyMedium } from "assets/colors";
import { BasicCloseIcon } from "components/Dialog/BasicDialog";
import { IItemGalleryItem } from "components/Matrix/MatrixEditorDrawer/ItemGallery";
import MatrixEditorDialog from "components/Matrix/MatrixEditorDrawer/MatrixEditorDialog";
import { IMatrixAnswerForm } from "components/Matrix/MatrixEditorDrawer/MatrixEditorDrawer";
import { getItemGalleryFromList } from "components/Matrix/MatrixEditorDrawer/utils";
import {
  TABLE_COLUMN_TYPE,
  TCategoryType,
  TColumnType,
  TRowType,
} from "components/Table/model";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IList, IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "../InputEmptyContainer";
import MatrixSummaryView from "./MatrixSummaryView";
import { addItemToMatrixRows } from "./utils";
import { removeMatrixRow } from "./utils/removeMatrixRow";

const styles = {
  icon: {
    "& , span": {
      fontSize: "16px !important",
    },
    padding: "5px",
  },
};

const useStyles = makeStyles(styles);
export interface IInputMatrixProps extends IInputBaseLayout {
  list: IList;
  categories: TCategoryType[];
  rows: TRowType[];
  columns: TColumnType[];
  customFields: IQuestion[] | IListSchema[];
  name: string;
  onChange: (value: TRowType[], name: string) => void;
  customFieldMatrix: IQuestion | IListSchema;
}

function InputMatrix({
  categories,
  list,
  customFields,
  rows,
  columns,
  name,
  onChange,
  customFieldMatrix,
  ...rest
}: IInputMatrixProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [viewSummary, setViewSummary] = useState(true);
  const lang = useTranslations();
  const langKey = lang.components.inputMatrixEditor;
  const listLang = lang.containers.lists.subCategories;
  const summaryColumns = buildExtraColumns(columns);
  const items = getItemGalleryFromList(list);
  const classes = useStyles();

  function handleClickViewSummary() {
    setOpenDialog(false);
    setViewSummary(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
    setViewSummary(true);
  };

  function getTitleForFilledItems() {
    let subject = listLang.items.subject;
    if (rows.length > 1) subject = listLang.items.subjects;
    return formatString(
      langKey.createEditModal.section?.filledItemsCounter ?? "",
      [rows.length, subject]
    );
  }

  function buildExtraColumns(columns: TColumnType[]): TColumnType[] {
    let newColumns = [];
    if (list.id == "sku") {
      newColumns = _.cloneDeep(
        _.without(
          columns,
          _.find(columns, {
            name: "_name",
          })
        )
      );
    } else {
      newColumns = _.cloneDeep(columns);
    }

    newColumns.unshift({
      label: listLang.items.subject,
      name: "_sku_item_",
      type: TABLE_COLUMN_TYPE.SKU,
      sticky: true,
    });
    newColumns.push({
      label: "",
      name: "_item_action_",
      type: TABLE_COLUMN_TYPE.ACTION,
      sticky: true,
    });
    return newColumns as TColumnType[];
  }

  function formatRowsAsSummaryRows(newRows: TRowType[]) {
    const formattedRows = _.map(newRows, function (_row) {
      const row = { ..._row };
      const correspondingItem = _.find(items, function (item) {
        if (list.id === "sku") {
          return row._item_id === item.id;
        } else {
          return _.values(row).includes(item.id);
        }
      });
      if (list.id === "sku" && row["_name"]) {
        delete row["_name"];
      }
      return {
        ...row,
        ...(correspondingItem && {
          _sku_item_: {
            label: correspondingItem.label,
            image: correspondingItem.imageURL,
          },
          _item_action_: {
            icon: <BasicCloseIcon className={classes.icon} />,
            action: function () {
              handleItemRemove(correspondingItem);
            },
          },
        }),
      };
    });
    return formattedRows;
  }

  function handleValidate(
    selectedItem: IItemGalleryItem,
    answers: IMatrixAnswerForm
  ) {
    const newRows = addItemToMatrixRows(selectedItem, answers, list, rows);
    onChange(newRows, name);
  }

  function handleItemRemove(item: IItemGalleryItem) {
    onChange(removeMatrixRow(item, rows), name);
  }

  function openMatrixEditor() {
    setViewSummary(false);
    setOpenDialog(true);
  }

  return (
    <>
      <MatrixEditorDialog
        dataTestid="matrix-editor-dialog"
        open={openDialog}
        list={list}
        categories={categories}
        rows={rows}
        customFields={customFields}
        onClose={handleClose}
        onClickViewSummary={handleClickViewSummary}
        onValidate={handleValidate}
        onItemRemove={handleItemRemove}
        customFieldMatrix={customFieldMatrix}
      />

      <InputBaseLayout {...rest} viewMode="CREATE">
        <Box display={"flex"} flexDirection={"column"} gridRowGap="16px">
          {viewSummary && rows.length > 0 ? (
            <>
              <Box display={"flex"}>
                <Chip
                  label={getTitleForFilledItems()}
                  color="secondary"
                  style={{ height: "23px" }}
                />
              </Box>
              <MatrixSummaryView
                columns={summaryColumns}
                rows={formatRowsAsSummaryRows(rows)}
              />
            </>
          ) : null}
          <InputEmptyContainer
            style={{ borderWidth: "2px", borderColor: GreyMedium }}
          >
            <Button
              disableElevation
              variant="contained"
              onClick={openMatrixEditor}
              startIcon={<AddCircleIcon />}
            >
              {lang.components.inputMatrixEditor.title}
            </Button>
          </InputEmptyContainer>
        </Box>
      </InputBaseLayout>
    </>
  );
}

export default InputMatrix;
