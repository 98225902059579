import { Dispatch } from "redux";

import {
  setSelectedClientActionCreator,
  updateClientSuccessActionCreator,
} from "containers/clients/redux/actionCreators";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IHierarchyDependency } from "model/entities/HierarchyDependency";
import ITeam from "model/entities/Team";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../redux/actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import { getSelectedClient } from "../../clients/redux/selectors";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as notifyLevels from "../../notifications/actionLevels";
import * as notifyTypes from "../../notifications/actionTypes";
import { getLowestLevelNb } from "../utils";
import {
  archiveTeamsBeginAction,
  archiveTeamsFailureAction,
  archiveTeamsSuccessAction,
  assignMobileUsersToTeamsBeginAction,
  assignMobileUsersToTeamsFailureAction,
  assignMobileUsersToTeamsSuccessAction,
  assignWebUsersToTeamsBeginAction,
  assignWebUsersToTeamsFailureAction,
  assignWebUsersToTeamsSuccessAction,
  assignWorkflowsToTeamsBeginAction,
  assignWorkflowsToTeamsFailureAction,
  assignWorkflowsToTeamsSuccessAction,
  changeSubcategorySuccessActionCreator,
  createTeamBeginAction,
  createTeamFailureAction,
  createTeamsBeginAction,
  createTeamsFailureAction,
  createTeamsSuccessAction,
  createTeamSuccessAction,
  deleteTeamsBeginAction,
  deleteTeamsFailureAction,
  deleteTeamsSuccessAction,
  fetchTeamsForClientBeginAction,
  fetchTeamsForClientFailureAction,
  fetchTeamsForClientSuccessAction,
  restoreTeamsBeginAction,
  restoreTeamsFailureAction,
  restoreTeamsSuccessAction,
  selectTeamActionCreator,
  unAssignUsersFromTeamsBeginAction,
  unAssignUsersFromTeamsFailureAction,
  unAssignUsersFromTeamsSuccessAction,
  updateHierarchyBeginAction,
  updateHierarchyFailureAction,
  updateHierarchySuccessAction,
  updateTeamsBeginAction,
  updateTeamsFailureAction,
  updateTeamsSuccessAction,
} from "./actionCreators";
import {
  archiveTeamsApiCall,
  assignMobileUsersToTeamsApiCall,
  assignWebUsersToTeamsApiCall,
  assignWorkflowsToTeamsApiCall,
  createTeamApiCall,
  createTeamsApiCall,
  deleteTeamsApiCall,
  fetchTeamsForClientApiCall,
  restoreTeamsApiCall,
  unAssignUsersFromTeamsApiCall,
  updateHierarchyApiCall,
  updateTeamsApiCall,
} from "./api";
import { teamsByIdSelector } from "./selectors";

export interface ITeamsActions {
  fetchTeamsForClientAction: IFetchTeamsForClientActionFunc;
  createTeamAction: ICreateTeamActionFunc;
  createTeamsAction: ICreateTeamsActionFunc;
  editTeamsAction: IEditTeamsActionFunc;
  archiveTeamsAction: IArchiveTeamsActionFunc;
  restoreTeamsAction: IRestoreTeamsActionFunc;
  deleteTeamsAction: IDeleteTeamsActionFunc;
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
  updateHierarchyAction: IUpdateHierarchyActionFunc;
  assignMobileUsersToTeamsAction: IAssignMobileUsersToTeamsActionFunc;
  assignWebUsersToTeamsAction: IAssignWebUsersToTeamsActionFunc;
  unAssignUsersFromTeamsAction: IUnAssignUsersFromTeamsActionFunc;
  assignWorkflowsToTeamsAction: IAssignWorkflowsToTeamsActionFunc;
}

/**
 * Create Team Action dispatches action creators to redux store and makes api calls to create a team
 * @param {Object} team New team to create with its details
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
type ICreateTeamActionFunc = (
  actionName: string,
  team: ITeam
) => IDispatchAndGetState<void>;
export const createTeamAction: ICreateTeamActionFunc = (
  actionName: string,
  team: ITeam
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTeamBeginAction());

    return createTeamApiCall(actionName, team)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<{ id: string }>(
          serverResponse
        );
        const { id } = data;
        team.id = id;

        dispatch(ajaxSuccessAction());
        dispatch(createTeamSuccessAction(team));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TEAM,
              team.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTeamError",
          error,
          createTeamFailureAction,
          currLang
        );
      });
  };
};

/**
 * Create Teams Action dispatches action creators to redux store and makes api calls
 * @param {Array} teams Name of the teams
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
type ICreateTeamsActionFunc = (
  actionName: string,
  newTeams: ITeam[]
) => IDispatchAndGetState<any>;
export const createTeamsAction: ICreateTeamsActionFunc = (
  actionName: string,
  newTeams: ITeam[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTeamsBeginAction());

    // add the iid to the teams
    newTeams = newTeams.map((t, iid) => ({
      ...t,
      iid: iid + "",
    }));

    return new Promise((resolve, _reject) => {
      createTeamsApiCall(actionName, newTeams)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus<any>(serverResponse);
          const { teams } = data;

          newTeams = teams.map((t: any) => {
            const fullTeam: any = newTeams.filter(
              (tm: any) => tm.iid === t.iid
            )[0];
            delete fullTeam.iid;
            fullTeam.id = t.id;
            return fullTeam;
          });

          dispatch(ajaxSuccessAction());
          dispatch(createTeamsSuccessAction(newTeams));
          dispatch(
            showNotificationActionCreator(
              notifyTypes.NOTIFICATION_SUCCESS,
              notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.TEAM,
                newTeams.length,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateTeamsError",
            error,
            createTeamsFailureAction,
            currLang
          );
        });
    });
  };
};

type ISelectTeamActionFunc = (id: string) => any;
export const selectTeamAction: ISelectTeamActionFunc = (id: string) => {
  return (dispatch: Dispatch, getState: any) => {
    // get the team from Redux store if it exists
    const team = teamsByIdSelector(getState(), id);
    dispatch(selectTeamActionCreator(team));
  };
};

/**
 * Fetch all Teams Action dispatches action creators to redux store and makes api calls to fetch all teams
 * for a given client
 * @return {any} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IFetchTeamsForClientActionFunc = () => IDispatchAndGetState<void>;
export const fetchTeamsForClientAction: IFetchTeamsForClientActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTeamsForClientBeginAction());

    return fetchTeamsForClientApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        const { teams } = data;
        dispatch(ajaxSuccessAction());
        dispatch(fetchTeamsForClientSuccessAction(teams));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchTeamsForClientError",
          error,
          fetchTeamsForClientFailureAction,
          currLang
        );
      });
  };
};

/**
 * Delete Teams Action dispatches action creators to redux store and makes api calls to delete teams
 * @param {Array} ids Team ids to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IDeleteTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => IDispatchAndGetState<void>;
export const deleteTeamsAction: IDeleteTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteTeamsBeginAction());

    return deleteTeamsApiCall(actionName, ids)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteTeamsSuccessAction(ids));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.TEAM,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteTeamsError",
          error,
          deleteTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Archive Teams Action dispatches action creators to redux store and makes api calls to archive teams
 * @param {Array} ids Team ids to archive
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IArchiveTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => IDispatchAndGetState<void>;
export const archiveTeamsAction: IArchiveTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveTeamsBeginAction());

    return archiveTeamsApiCall(actionName, ids)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveTeamsSuccessAction(ids));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.TEAM,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveTeamsError",
          error,
          archiveTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Restore Teams Action dispatches action creators to redux store and makes api calls to restore teams
 * @param {Array} ids Team ids to restore
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IRestoreTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => IDispatchAndGetState<void>;
export const restoreTeamsAction: IRestoreTeamsActionFunc = (
  actionName: string,
  ids: string[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreTeamsBeginAction());

    return restoreTeamsApiCall(actionName, ids)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreTeamsSuccessAction(ids));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.TEAM
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreTeamsError",
          error,
          restoreTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Update Teams Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {Array} teams Array of teams to import
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IEditTeamsActionFunc = (
  actionName: string,
  teams: ITeam[]
) => IDispatchAndGetState<any>;
export const editTeamsAction: IEditTeamsActionFunc = (
  actionName: string,
  editedTeams: ITeam[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateTeamsBeginAction());

    return updateTeamsApiCall(actionName, editedTeams)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { teams } = data;
        dispatch(ajaxSuccessAction());
        dispatch(updateTeamsSuccessAction(teams));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TEAM,
              teams.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateTeamsError",
          error,
          updateTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Assign Mobile users to teams Action dispatches action creators to redux store and makes api calls to link mobile users to teams
 * @param {Array} links Detail of the links to add between mobile users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IAssignMobileUsersToTeamsActionFunc = (
  actionName: string,
  links: any
) => IDispatchAndGetState<void>;

export const assignMobileUsersToTeamsAction: IAssignMobileUsersToTeamsActionFunc =
  (actionName: string, links: any) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(assignMobileUsersToTeamsBeginAction());

      return assignMobileUsersToTeamsApiCall(actionName, links)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(assignMobileUsersToTeamsSuccessAction(links));
          dispatch(
            showNotificationActionCreator(
              notifyTypes.NOTIFICATION_SUCCESS,
              notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
              currLang.containers.teams.subCategories.teams.customNotifications
                .assignMobUserToTeams
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "AssignMobileUsersToTeamsError",
            error,
            assignMobileUsersToTeamsFailureAction,
            currLang
          );
        });
    };
  };

/**
 * Assign Web users to teams Action dispatches action creators to redux store and makes api calls to link mobile users to teams
 * @param {Array} links Detail of the links to add between web users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IAssignWebUsersToTeamsActionFunc = (
  actionName: string,
  links: any
) => IDispatchAndGetState<void>;
export const assignWebUsersToTeamsAction: IAssignWebUsersToTeamsActionFunc = (
  actionName: string,
  links: any
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(assignWebUsersToTeamsBeginAction());

    return assignWebUsersToTeamsApiCall(actionName, links)
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignWebUsersToTeamsSuccessAction(links));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .assignWebUserToTeams
          )
        );
      })
      .catch((error: any) => {
        treatErrorNotification(
          dispatch,
          "AssignWebUsersToTeamsError",
          error,
          assignWebUsersToTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Assign Workflows to teams Action dispatches action creators to redux store and makes api calls to link mobile users to teams
 * @param {Array} links Detail of the links to add between web users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IAssignWorkflowsToTeamsActionFunc = (
  actionName: string,
  links: any
) => IDispatchAndGetState<void>;
export const assignWorkflowsToTeamsAction: IAssignWorkflowsToTeamsActionFunc = (
  actionName: string,
  links: any
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(assignWorkflowsToTeamsBeginAction());

    return assignWorkflowsToTeamsApiCall(actionName, links)
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignWorkflowsToTeamsSuccessAction(links));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .assignWorkflowToTeams
          )
        );
      })
      .catch((error: any) => {
        treatErrorNotification(
          dispatch,
          "AssignWorkflowsToTeamsError",
          error,
          assignWorkflowsToTeamsFailureAction,
          currLang
        );
      });
  };
};

type IUpdateHierarchyActionFunc = (
  actionName: string,
  hierarchyDependencies: IHierarchyDependency[]
) => IDispatchAndGetState<void>;
export const updateHierarchyAction: IUpdateHierarchyActionFunc = (
  actionName: string,
  hierarchyDependencies: IHierarchyDependency[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    const client = getSelectedClient(getState());
    const cleanedHierarchyDepencies = hierarchyDependencies.filter(
      (hd) =>
        hd.level_type_number <=
        getLowestLevelNb(client.meta_hierarchy_dependencies)
    );
    dispatch(ajaxRequestAction());
    dispatch(updateHierarchyBeginAction());

    return updateHierarchyApiCall(
      actionName,
      cleanedHierarchyDepencies,
      client.id
    )
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateHierarchySuccessAction(data.teams));

        const clientModified = {
          ...client,
          hierarchy_dependencies: cleanedHierarchyDepencies,
        };

        // modify client and selectedClient
        dispatch(updateClientSuccessActionCreator(clientModified));
        dispatch(setSelectedClientActionCreator(clientModified));

        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .editHierarchy
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "updateHierarchyError",
          error,
          updateHierarchyFailureAction,
          currLang
        );
      });
  };
};

/**
 * UnAssign users from teams Action dispatches action creators to redux store and makes api calls to unlink users from teams
 * @param {Array} links Detail of the links to delete between users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IUnAssignUsersFromTeamsActionFunc = (
  actionName: string,
  type: "WEB_USER" | "MOBILE_USER",
  links: Array<{ team_id: string; user_id: string }>
) => IDispatchAndGetState<void>;
export const unAssignUsersFromTeamsAction: IUnAssignUsersFromTeamsActionFunc = (
  actionName: string,
  type: "WEB_USER" | "MOBILE_USER",
  links: Array<{ team_id: string; user_id: string }>
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(unAssignUsersFromTeamsBeginAction());

    return unAssignUsersFromTeamsApiCall(actionName, type, links)
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(unAssignUsersFromTeamsSuccessAction(type, links));
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            type == "WEB_USER"
              ? currLang.containers.teams.subCategories.teams
                  .customNotifications.unAssignWebUserFromTeams
              : currLang.containers.teams.subCategories.teams
                  .customNotifications.unAssignMobUserFromTeams
          )
        );
      })
      .catch((error: any) => {
        treatErrorNotification(
          dispatch,
          type == "WEB_USER"
            ? "UnAssignWebUsersFromTeamsError"
            : "UnAssignMobileUsersFromTeamsError",
          error,
          unAssignUsersFromTeamsFailureAction,
          currLang
        );
      });
  };
};

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
type IChangeSubcategoryActionFunc = (subcategory: string) => any;
export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory: string
) => {
  return (dispatch: Dispatch) => {
    return dispatch(changeSubcategorySuccessActionCreator(subcategory));
  };
};
