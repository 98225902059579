import { Tooltip } from "@material-ui/core";
import Icon from "@mui/icons-material/Info";

interface IInfoIcon {
  tooltip?: string;
  title?: string;
  classe?: string;
}
export const InfoIcon = ({ tooltip, title, classe }: IInfoIcon) => {
  const containerStyle = {
    cursor: "pointer",
    zIndex: 10,
  };
  return (
    <span data-testid="template-icon" style={containerStyle}>
      {/* Icon & tooltip message */}
      <Tooltip title={tooltip ?? ""} arrow placement="top">
        <Icon className={classe} />
      </Tooltip>

      {/* Title */}
      {title && <span>{title}</span>}
    </span>
  );
};
