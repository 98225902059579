import _ from "lodash";

import { TIMELINE_EVENT_TYPE, TTimelineData } from "model/entities/Dashboard";

export function splitByCheckInOut(events: TTimelineData[]) {
  const result = [];
  let currentChunk: TTimelineData[] = [];

  _.forEach(_.orderBy(events, "visit_id"), (event) => {
    if (event.action_code === TIMELINE_EVENT_TYPE.CHECK_IN) {
      currentChunk = [event];
    } else if (event.action_code === TIMELINE_EVENT_TYPE.CHECK_OUT) {
      currentChunk.push(event);
      result.push(currentChunk);
      currentChunk = [];
    } else {
      currentChunk.push(event);
    }
  });

  if (currentChunk.length > 0) {
    result.push(currentChunk);
  }

  return result;
}
