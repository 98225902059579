import { useState } from "react";

import {
  Box,
  ClickAwayListener,
  Fade,
  makeStyles,
  Paper,
} from "@material-ui/core";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import { useSelector } from "react-redux";

import { GreyDark, secondaryColor } from "assets/colors";
import { BasicCloseIcon } from "components/Dialog/BasicDialog";
import { makeColorTransparent } from "containers/calendar/utils/makeColorTransparent";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useTranslations } from "hooks";

import ControlButton from "./ControlMenu/ControlButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "hidden",
  },
  menuContainer: {
    borderRight: `solid 1.5px ${makeColorTransparent(GreyDark, 0.1)}`,
  },
  menuHeader: {
    height: "50px",
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15)",
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  closeIcon: {
    cursor: "pointer",
    height: 40,
    width: 40,
  },
  menuContent: {
    maxHeight: 500,
    overflowY: "auto",
  },
  controlBox: {
    paddingTop: theme.spacing(2),
    width: 50,
    display: "grid",
    gridTemplateRows: "auto 1fr 10px",
    justifyItems: "center",
    alignItems: "baseline",
  },
  controlButtonBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gridRowGap: "8px",
    textAlign: "center",
  },
  controlButtonTitle: {
    fontSize: 8,
  },
}));

export interface ICustomControlProps {
  TerritoriesMenu: React.ReactNode;
  LegendMenu: React.ReactNode;
}

function CustomControl({ LegendMenu, TerritoriesMenu }: ICustomControlProps) {
  const classes = useStyles();
  const client = useSelector(getSelectedClient);
  const activatedTerritory = client?.activate_territory;
  const lang = useTranslations();
  const [currentMenu, setCurrentMenu] = useState<
    "territories" | "legend" | undefined
  >(undefined);
  const [openMenu, setOpenMenu] = useState(false);

  function getOptionColor(
    option: typeof currentMenu,
    defaultColor: string = "black"
  ) {
    if (option === currentMenu && openMenu) {
      return secondaryColor;
    }
    return defaultColor;
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        // the user can pan the map while still having a view of the legends
        if (currentMenu !== "legend") {
          setOpenMenu(false);
        }
      }}
    >
      <Paper className={classes.paper}>
        {openMenu && (
          <Box className={classes.menuContainer}>
            <Box className={classes.menuHeader}>
              <Box flexGrow={2} fontSize={"18px"}>
                {currentMenu}
              </Box>
              <Box paddingRight={"2px"}>
                <Fade in={openMenu}>
                  <BasicCloseIcon
                    onClick={() => setOpenMenu(false)}
                    className={classes.closeIcon}
                  />
                </Fade>
              </Box>
            </Box>
            <Box className={classes.menuContent}>
              {currentMenu === "territories" && activatedTerritory && (
                <Fade in>
                  <Box>{TerritoriesMenu}</Box>
                </Fade>
              )}
              {currentMenu === "legend" && (
                <Fade in>
                  <Box>{LegendMenu}</Box>
                </Fade>
              )}
            </Box>
          </Box>
        )}
        <Box className={classes.controlBox}>
          <Box className={classes.controlButtonBox}>
            {activatedTerritory && (
              <Box data-testid="territories-button">
                <ControlButton
                  onClick={() => {
                    setCurrentMenu("territories");
                    setOpenMenu(true);
                  }}
                  icon={
                    <PolylineOutlinedIcon
                      htmlColor={getOptionColor("territories")}
                      style={{ transition: "0.3s" }}
                    />
                  }
                  buttonProps={{
                    style: {
                      boxShadow: "none",
                      width: 50,
                    },
                  }}
                />
                <Box
                  className={classes.controlButtonTitle}
                  color={getOptionColor("territories")}
                >
                  {lang.containers.lists.subCategories.territories.subject}
                </Box>
              </Box>
            )}

            <Box data-testid="legend-button">
              <Box>
                <ControlButton
                  onClick={() => {
                    setCurrentMenu("legend");
                    setOpenMenu(true);
                  }}
                  icon={
                    <LegendToggleOutlinedIcon
                      htmlColor={getOptionColor("legend")}
                      style={{ transition: "0.3s" }}
                    />
                  }
                  buttonProps={{
                    style: {
                      boxShadow: "none",
                      width: 50,
                    },
                  }}
                />
                <Box
                  className={classes.controlButtonTitle}
                  color={getOptionColor("legend")}
                >
                  {lang.components.maps.legend.title}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
}

export default CustomControl;
