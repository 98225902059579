import { useEffect, useState } from "react";

import { Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslations } from "hooks";

function CalendarViewToggleButton() {
  const lang = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const [view, setView] = useState<string>("calendar");

  useEffect(() => {
    const path = location.pathname;
    const view = path === "/calendar" ? "calendar" : "list";
    setView(view);
  }, [location]);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ) => {
    setView(newView);
    if (newView === "list") {
      history.push("/calendar/list");
    } else if (newView === "calendar") {
      history.push("/calendar");
    }
  };

  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal.inputEvent
      .customMessage;
  return (
    <ToggleButtonGroup value={view} exclusive onChange={handleViewChange}>
      <ToggleButton value="calendar">
        <Tooltip title={langKey.openCalendarView}>
          <CalendarMonthIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="list">
        <Tooltip title={langKey.openListView}>
          <ListAltIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default CalendarViewToggleButton;
