import _ from "lodash";

import {
  ICustomerDetailForGPSTracking,
  TTimelineData,
} from "model/entities/Dashboard";

export const sortVisitChunksByCustomerVisitOrder = (
  _timeLineActions: Array<Array<TTimelineData>>,
  _customersVisited: ICustomerDetailForGPSTracking[]
) => {
  let customersVisited = _.cloneDeep(_customersVisited);
  let timeLineActions = _.cloneDeep(_timeLineActions);

  return _(customersVisited)
    .map((customer) => {
      const customerVisits = _.find(timeLineActions, (events) => {
        const firstEvent = _.first(events);
        return firstEvent?.customer_id === customer.customer_id;
      });

      if (!customerVisits) return;

      timeLineActions = _.without(timeLineActions, customerVisits);
      customersVisited = _.without(customersVisited, customer);
      return customerVisits;
    })
    .compact()
    .value();
};
