import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import { computeEventsIndexes } from "./computeEventsIndexes";
import { IcomputeNewDraggedEvent } from "./computeNewDraggedEvent";

export type ComputeNewDraggedEventForDayOnly = ({
  dropResult,
  events,
  moment,
}: IcomputeNewDraggedEvent) => TCalendarEvent[];

export const computeNewDraggedEventForDayOnlyCalendar: ComputeNewDraggedEventForDayOnly =
  ({ dropResult, events, moment }) => {
    if (!dropResult.destination) return events;
    const { destination, draggableId, source } = dropResult;
    const draggedEvent = _.find(events, { id: draggableId });

    if (!draggedEvent) return events;

    const sourceDate = source.droppableId;
    const destinationDate = destination.droppableId;

    const eventsInSource = computeEventsIndexes(
      events.filter((event) => {
        const isSameDay = moment(event.start_time)
          .startOf("day")
          .isSame(moment(sourceDate).startOf("day"));

        return isSameDay && event.id !== draggedEvent.id;
      })
    );
    const eventsInDestination = computeEventsIndexes(
      events.filter((event) => {
        const isSameDay = moment(event.start_time)
          .startOf("day")
          .isSame(moment(destinationDate).startOf("day"));

        return isSameDay && event.id !== draggedEvent.id;
      })
    );

    const newEventObject = {
      ...draggedEvent,
      start_time: moment(destinationDate).toDate(),
      end_time: moment(destinationDate).toDate(),
      index: destination.index,
    };

    const eventsInDestinationWithNewEvent = computeEventsIndexes([
      ...eventsInDestination.slice(0, destination.index),
      newEventObject,
      ...eventsInDestination.slice(destination.index),
    ]);

    const newComputedEvents = [
      ...(sourceDate === destinationDate ? [] : eventsInSource),
      ...eventsInDestinationWithNewEvent,
    ];

    const allEvents = events.map((event) => {
      const computedEvent = _.find(newComputedEvents, { id: event.id });
      return computedEvent || event;
    });

    return allEvents;
  };
