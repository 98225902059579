import { LegendProps } from "@nivo/legends";

import {
  black,
  blue,
  bluegray,
  darkgray,
  darkteal,
  lightblue,
  lightbluegray,
  lightgray,
  lightpurple,
  lightred,
  orange,
  teal,
  yellow,
} from "assets/colors";
import { AXIS_TYPE, IKPI, KPI_TYPE } from "model/entities/Dashboard";

export const DATA_PDF_PRINTABLE_ATTRIBUTE = "data-pdf-printable-";
export const PDF_PRINTABLE_ELEMENT = "pdf-printable-element";
export const PERCENTAGE_KEY: string = "percentage";

export const titleHeightPx = 40;
const fullWidthGrid = { lg: 12, md: 12, sm: 12, xs: 12 };
const defaultGrid = { lg: 6, md: 6, sm: 12, xs: 12 };
const defaultHeight = "400px";
const defaultNumberInPalette = 10;
const defaultMarginTop = 10;
const defaultMarginRight = 20;
const defaultMarginBottom = 50;
const defaultMarginLeft = 60;
const defaultMargin = {
  top: defaultMarginTop,
  right: defaultMarginRight,
  bottom: defaultMarginBottom,
  left: defaultMarginLeft,
};

/**
 * Default chart settings
 */
const settings = {
  LINE_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      top: 10,
      bottom: 40,
      right: 30,
      left: 70,
    },
  },
  MULTIPLE_LINE_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      ...defaultMargin,
      ...{ top: 30, left: 70 },
    },
  },
  BAR_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      top: 40,
      bottom: 40,
      right: 60,
      left: 60,
    },
  },
  HISTOGRAM: {
    height: "350px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      ...defaultMargin,
    },
  },
  BAR_CHART_HORIZONTAL: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    overflowAutorised: true,
    overflowActivationCount: 20,
    margin: {
      ...defaultMargin,
      ...{ left: 120 },
    },
  },
  STACKED_BAR_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      ...defaultMargin,
      ...{ top: 120 },
    },
  },
  PIE_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    margin: {
      top: 70,
      right: 30,
      bottom: 70,
      left: 30,
    },
  },
  HEATMAP_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
    // TODO: compute margins based on axis labels' max width
    // (or pass it as prop ? Better if there are several charts on the same line and we want homogenous layout)
    margin: { top: 80, right: 32, bottom: 16, left: 140 },
  },
  CALENDAR_CHART: {
    height: "320px",
    fullScreenHeight: "320px",
    gridValues: defaultGrid,
  },
  SCATTER_PLOT_CHART: {
    height: "400px",
    fullScreenHeight: "400px",
    gridValues: defaultGrid,
  },
  TABLE: {
    height: "400px",
    fullScreenHeight: "calc(100vh - 250px)",
    gridValues: defaultGrid,
    overflowAutorised: true,
  },
  TIMELINE: {
    height: "600px",
    fullScreenHeight: "calc(100vh - 50px)",
    gridValues: defaultGrid,
    overflowAutorised: true,
  },
  MATRIX: {
    height: "400px",
    // fullScreenHeight: "calc(100vh - 50px)",
    gridValues: defaultGrid,
    overflowAutorised: true,
  },
};

const legendSymbolSize = 12;

const multiLegendSettings: ({
  dataFrom: "keys" | "indexes";
} & LegendProps)[] = [
  {
    dataFrom: "keys",
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: 0,
    translateY: 0,
    itemsSpacing: 1,
    itemDirection: "left-to-right",
    itemWidth: 1,
    itemHeight: 1,
    itemOpacity: 0,
    symbolSize: legendSymbolSize,
    symbolShape: "square",
    symbolBorderColor: "rgba(0, 0, 0, .5)",
  },
];

const defaultAxisBottom = (axisType: AXIS_TYPE) => {
  let axisBottom = {
    tickSize: 5,
    tickPadding: 10,
    legendOffset: 30,
  };
  axisBottom =
    axisType === AXIS_TYPE.TEXT
      ? Object.assign(axisBottom, { tickRotation: 0 })
      : axisBottom;
  return axisBottom;
};
const defaultAxisLeft = {
  tickSize: 0,
  tickPadding: 5,
  tickRotation: 0,
  legendOffset: -40,
};

const getChartHeight = (chartType: KPI_TYPE) => {
  const chart = settings[chartType];
  if (chart && chart.height) {
    if (typeof chart.height === "number") {
      return chart.height - titleHeightPx;
    } else {
      const str = chart.height.replace("px", "");
      return Number(str) - titleHeightPx;
    }
  }
};

const getColors = (chart: IKPI, index: number): string[] => {
  const chartType: KPI_TYPE = chart.type;
  const getColorsForLineChart = (index: number): string => {
    switch (index % 4) {
      case 0: {
        return teal;
      }
      case 1: {
        return lightblue;
      }
      case 2: {
        return blue;
      }
      case 3: {
        return lightpurple;
      }
      default: {
        return teal;
      }
    }
  };

  const getAllColors = () => [
    teal,
    lightred,
    blue,
    yellow,
    darkgray,
    lightpurple,
    lightblue,
    lightgray,
  ];

  const getExtendedColors = () => [
    teal,
    lightred,
    blue,
    yellow,
    lightbluegray,
    darkgray,
    lightpurple,
    darkteal,
    lightblue,
    orange,
    lightgray,
    bluegray,
  ];
  const getColorsForBarChart = () =>
    chart.heatmap_color ? [chart.heatmap_color] : [teal];
  const getColorsForStackedBarChart = () =>
    chart.heatmap_color ? [chart.heatmap_color] : getExtendedColors();
  const getColorsForHorizontalBarChart = () =>
    chart.heatmap_color ? [chart.heatmap_color] : getExtendedColors();
  const getColorsForMultipleLineChart = () => getAllColors();
  const getColorsForHeatmapChart = () =>
    chart.heatmap_color ? [chart.heatmap_color] : [lightgray, lightblue, teal];
  const getColorsForCalendarChart = () => [teal];
  const getColorsForScatterPlotChart: any = () => [blue, teal];
  const getColorsForHistogram = () => [teal];

  switch (chartType) {
    case KPI_TYPE.LINE_CHART: {
      return [getColorsForLineChart(index)];
    }
    case KPI_TYPE.BAR_CHART: {
      return getColorsForBarChart();
    }
    case KPI_TYPE.HISTOGRAM: {
      return getColorsForHistogram();
    }
    case KPI_TYPE.STACKED_BAR_CHART: {
      return getColorsForStackedBarChart();
    }
    case KPI_TYPE.MULTIPLE_LINE_CHART: {
      return getColorsForMultipleLineChart();
    }
    case KPI_TYPE.BAR_CHART_HORIZONTAL: {
      return getColorsForHorizontalBarChart();
    }
    case KPI_TYPE.HEATMAP_CHART: {
      return getColorsForHeatmapChart();
    }
    case KPI_TYPE.CALENDAR_CHART: {
      return getColorsForCalendarChart();
    }
    case KPI_TYPE.SCATTER_PLOT_CHART: {
      return getColorsForScatterPlotChart();
    }
    case KPI_TYPE.PIE_CHART: {
      return getExtendedColors();
    }
    default: {
      return [black];
    }
  }
};

export default {
  settings,
  fullWidthGrid,
  defaultGrid,
  defaultHeight,
  defaultNumberInPalette,
  defaultMargin,
  defaultAxisBottom,
  defaultAxisLeft,
  getColors,
  titleHeightPx,
  getChartHeight,
  multiLegendSettings,
};
