// According to https://docs.sentry.io/platforms/javascript/guides/react/
// Sentry initialization should be imported first
// eslint-disable-next-line simple-import-sort/imports
import initSentry from "log/sentry/init";

import "./assets/styles/css/bootstrap.css";

import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Loader from "components/Progress/CustomCircularProgress";
import Root from "containers/app/Root";
import configureStore from "redux/store/configureStore";
import { history } from "redux/store/history";

const { store, persistor } = configureStore();
// assign the target node or create a div element for testing purposes
const domTarget =
  document.getElementById("root") || document.createElement("div");

initSentry();

render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <Root />
        </CookiesProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  domTarget
);
