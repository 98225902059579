import { Box, Button } from "@material-ui/core";
import _ from "lodash";

import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

import BaseOptionsContainer, { IInputOption } from "../BaseOptionsContainer";
import { IBaseOptionsContainer } from "../BaseOptionsContainer/BaseOptionsContainer";
import ErrorMessage from "../ErrorMessage";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "../InputEmptyContainer";

export interface IInputMultipleOptionsBase<T>
  extends Pick<IBaseOptionsContainer<T>, "onChangeOptions"> {
  label?: string;
  tooltip?: string;
  viewMode?: TViewMode;
  options: IInputOption<T>[];
  placeholder?: string;
  addOptionText?: string;
  addOptionIcon?: JSX.Element | null;
  onOpenAddOption: () => void;
  onDeleteOption: (option: IInputOption<T>) => void;
  onClickOption?: (option: IInputOption<T>) => void;
  required?: boolean;
  draggable?: boolean;
  error?: string;
}
export interface IInputMultipleOptions<T>
  extends IInputMultipleOptionsBase<T>,
    Omit<IInputBaseLayout, keyof IInputMultipleOptionsBase<T>> {}

function InputMultipleOptions<IInputOption>({
  label,
  tooltip,
  viewMode = "CREATE",
  options = [],
  onChangeOptions,
  placeholder,
  addOptionText,
  addOptionIcon = DefaultAddIcon,
  onOpenAddOption,
  onClickOption,
  onDeleteOption,
  error,
  required,
  draggable,
  ...rest
}: IInputMultipleOptions<IInputOption>) {
  const lang = useTranslations();

  const AddOptionButton = () => (
    <Button onClick={onOpenAddOption} variant="contained" disableElevation>
      {addOptionIcon}
      {addOptionText || lang.components.inputMultipleCreate.options.addNew}
    </Button>
  );

  if (viewMode === "CREATE" && _.isEmpty(options)) {
    return (
      <InputBaseLayout
        {...rest}
        label={label}
        tooltip={tooltip}
        required={required}
        viewMode={viewMode}
        error={error}
      >
        <InputEmptyContainer>
          <AddOptionButton />
        </InputEmptyContainer>

        {error && <ErrorMessage error={error} />}
      </InputBaseLayout>
    );
  }

  return (
    <InputBaseLayout
      {...rest}
      label={label}
      tooltip={tooltip}
      required={required}
      viewMode={viewMode}
      error={error}
    >
      <Box display="flex" flexGrow="1" flexDirection="column">
        <BaseOptionsContainer
          options={options}
          onChangeOptions={onChangeOptions}
          clickable={viewMode !== "VIEW"}
          deletable={viewMode !== "VIEW"}
          draggable={viewMode !== "VIEW" && draggable}
          onClickOption={onClickOption}
          onDeleteOption={onDeleteOption}
          placeholder={placeholder}
          error={error}
        />

        {viewMode !== "VIEW" && (
          <Box marginTop="12px">
            <AddOptionButton />
          </Box>
        )}
      </Box>
    </InputBaseLayout>
  );
}

const DefaultAddIcon = (
  <span className="material-icons-outlined" style={{ marginRight: "4px" }}>
    add
  </span>
);

export default InputMultipleOptions;
