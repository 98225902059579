import _ from "lodash";
import { useSelector } from "react-redux";

import { FormFieldBackground } from "components/Forms/Form";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import { IInputMultipleSelectProps } from "components/Input/InputMultipleSelect/InputMultipleSelect";
import { allListsSelector } from "containers/lists/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

export interface IPredictionManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (
    value: IClient["detectable_lists"] | undefined,
    name: string
  ) => void;
}
function PredictionManagement({
  client,
  onChange,
  viewMode,
}: IPredictionManagement) {
  const lang = useTranslations();
  const clientLang = lang.containers.clients.subCategories.clients;
  const allLists = useSelector(allListsSelector);

  const listsWithCodeTag = _.filter(allLists, (list) => {
    const isValid = _.find(list.schema, (schemaItem) => {
      return (
        schemaItem.column_tag === "_code" || schemaItem.column_tag === "code"
      );
    });
    return isValid;
  });

  const listsAsOptions: IOption<string>[] = _.compact(
    _.map(listsWithCodeTag, (list) => {
      return {
        key: list["id"],
        label: list["name"],
      };
    })
  );

  const handleChangePredictionLists: IInputMultipleSelectProps["onChange"] = (
    opt,
    name
  ) => {
    const lists = !_.isEmpty(opt) ? _.map(opt, (o) => o.key) : [];
    onChange(lists, name);
  };
  const defaultValue = listsAsOptions.filter((l) =>
    client.detectable_lists?.includes(l.key)
  );

  return (
    <>
      <FormFieldBackground viewMode={viewMode || "EDIT"}>
        <InputMultipleSelect
          defaultValue={defaultValue}
          onChange={handleChangePredictionLists}
          viewMode={viewMode}
          options={listsAsOptions}
          lang={lang}
          langlabel={{
            title: clientLang.createEditModal.predictionManagement.title,
            placeholder: "",
            tooltip: clientLang.createEditModal.predictionManagement.tooltip,
          }}
          name="detectable_lists"
        />
      </FormFieldBackground>
    </>
  );
}

export default PredictionManagement;
