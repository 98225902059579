import { ICustomField } from "./getSchemaForMetaExpression";

export const getCustomFieldAttributesInSchema = (
  custom: string,
  schema: ICustomField[]
) => {
  if (!custom) {
    return;
  }
  return schema.find((e) => e.tag === custom);
};
