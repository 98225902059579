import { ITableOptimization } from "model/entities/TableOptimization";
import { IAction } from "redux/store/model";

import {
  insertNewItemToArr,
  updateErrorsList,
} from "../../../../utils/reducerUtils";
import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface ITableOptimizationState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  selectedTableOptimization: ITableOptimization | null;
  tableOptimizations: ITableOptimization[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_TABLEOPTIMIZATION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_TABLEOPTIMIZATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_TABLEOPTIMIZATION_SUCCESS: {
      const { tableOptimization } =
        action as actionCreators.ICreateTableOptimizationSuccessAction;

      return {
        ...state,
        isCreating: false,
        tableOptimizations: insertNewItemToArr(
          state.tableOptimizations,
          tableOptimization
        ),
      };
    }

    case types.DELETE_TABLEOPTIMIZATION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_TABLEOPTIMIZATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_TABLEOPTIMIZATION_SUCCESS: {
      const { id } =
        action as actionCreators.IDeleteTableOptimizationSuccessAction;

      const tableOptimizations = state.tableOptimizations.filter(
        (t) => t.id !== id
      );

      return {
        ...state,
        isDeleting: false,
        tableOptimizations,
      };
    }

    case types.FETCH_ALL_TABLEOPTIMIZATIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_TABLEOPTIMIZATIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_TABLEOPTIMIZATIONS_SUCCESS: {
      const { tableOptimizations: beTableOptimizations } =
        action as actionCreators.IFetchTableOptimizationsSuccessAction;
      return {
        ...state,
        isFetchingAll: false,
        tableOptimizations: beTableOptimizations,
      };
    }

    default:
      return state;
  }
}
