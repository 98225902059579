import _ from "lodash";
import capitalize from "lodash/capitalize";
import { createSelector } from "reselect";

import { IOption } from "model/application/components";
import { IClient, TLightClient } from "model/entities/Client";
import { IMetaLevelType } from "model/entities/HierarchyDependency";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../redux/selectors/appSelector";

/**
 * Get the state of fetching all deploymensts
 * @param {Object} state Redux Store State
 * @returns {Boolean} value whether the application is fetching clients
 */
export const getIsFetchingAllClients = (state: IRootState) =>
  state?.clients?.isFetchingAllClients;

export const getClientInvoices = (state: IRootState) => state.clients.invoices;
export const gethasMoreInvoice = (state: IRootState) =>
  state.clients.hasMoreInvoice;

/**
 * Gets the state of fetching a single client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingClient = (state: IRootState) =>
  state.clients.isFetchingClient;

/**
 * Gets the state of fetching business objects
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingEntities = (state: IRootState) =>
  state.clients.isFetchingClient ||
  state.clients.isFetchingAllClients ||
  state.dashboards.isFetchingAll ||
  state.lists.fetchingListsForClient ||
  state.teams.isFetchingTeamsForClient ||
  state.users.mobileUsers.fetchingAllUsers ||
  state.users.webUsers.fetchingAllUsers ||
  state.workflows.isFetchingAll;

/**
 * Gets the state of creating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsCreatingClient = (state: IRootState) =>
  state.clients.isCreatingClient;

/**
 * Gets the state of replicating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsReplicatingClient = (state: IRootState) =>
  state.clients.isReplicatingClient;

/**
 * Gets the state of updating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingClient = (state: IRootState) =>
  state.clients.isUpdatingClient;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingClient = (state: IRootState) =>
  state.clients.isDeletingClient;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsArchivingClient = (state: IRootState) =>
  state.clients.isArchivingClient;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringClient = (state: IRootState) =>
  state.clients.isRestoringClient;

/**
 * Gets the state of invalidation of cache for clients
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsClientCacheInvalidated = (state: IRootState) =>
  state.clients.invalidateCache;

/**
 * Gets the clients from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All clients
 */
const getAllClients = (state: IRootState) => state.clients.allClients;

/**
 * Gets the currently selected Client
 * @param {Object} state Redux store state
 * @returns {Object}
 */
export const getSelectedClient = (state: IRootState) =>
  state?.clients?.selectedClient;

/**
 * Gets all the clients as an object
 * @param {Object} state Redux Store state
 * @returns {Object} Clients by Id object
 */
const getClientsById = (state: IRootState) => state.clients.byId;

/**
 * Clients By Id selector. Gets the clients by Id object
 */
export const clientsByIdSelector = customSelectorCreator(
  getClientsById,
  (byId: { [id: string]: IClient }) => byId
);

export const getClientById = createSelector(
  [getClientsById, (state, clientId) => clientId],
  (clientsById, id) => clientsById[id]
);

/**
 * Clients selector
 * @returns {Array}
 */
export const allClientsSelector = createSelector(
  getAllClients,
  (allClients) => allClients
);

/**
 * Creates a selector for creating Select options for the Navbar component
 * This will return an array of objects with the label and value properties
 * @param {Object} state Redux state
 * @returns {Array}
 */
export const clientOptionsSelector = createSelector(
  allClientsSelector,
  (allClients: TLightClient[]) => {
    allClients = allClients.filter(
      (client) => !client.hasOwnProperty("active") || client.active
    );
    return allClients.map(({ id, name }) => {
      return {
        label: name,
        key: id,
      };
    });
  }
);

/**
 * Clients composes Selector Gets all the clients
 */
export const allClientsComposedSelector = customSelectorCreator(
  allClientsSelector,
  (clients: TLightClient[]) => {
    clients = clients.filter(
      (client) => !client.hasOwnProperty("active") || client.active
    );
    return clients;
  }
);

/**
 * Clients composes Selector Gets all the clients with archived clients
 */
export const allClientsComposedWithArchiveSelector = customSelectorCreator(
  allClientsSelector,
  (clients: TLightClient[]) => {
    return clients;
  }
);

export type TLevelOption = {
  key: string;
  label: string;
  type?: IMetaLevelType["label_type_type"];
  options?: IOption<string>[];
};

export const getLevelViewOptions = (state: IRootState) => {
  const client = state.clients.selectedClient;
  const options = [{ key: "team", label: "Team" }];

  _.keys(client.meta_hierarchy_dependencies).forEach((levelIdx) => {
    const level: IMetaLevelType = client.meta_hierarchy_dependencies[levelIdx];

    const levelOption: TLevelOption = {
      key: levelIdx,
      label: capitalize(level.level_type_name),
      type: level.label_type_type,
      options: _.map(level.label_type_options, ({ key, value }) => ({
        key,
        value,
        label: value, // adding label also
      })),
    };

    options.push(levelOption);
  });

  return options;
};

export const levelsSelector = customSelectorCreator(
  getLevelViewOptions,
  (levels) => levels
);
