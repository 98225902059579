import {
  TPivotInfo,
  TPivotOption,
} from "components/Dashboard/Matrix/MatrixChart";
import { IOption } from "model/application/components";
import { AGGREGATOR } from "model/entities/Dashboard";
import { clone } from "utils/utils";

import { TDataFormatted } from "../../Table/model";
import { MatrixData } from "./CustomMatrix";

export const prepareFullMatrix = (
  data: MatrixData,
  rowPivotInfo: TPivotInfo,
  columnPivotInfo: TPivotInfo,
  reverse?: boolean
): {
  fullMatrix: TDataFormatted[];
  nbOfExtraColumns: number;
  nbOfExtraRows: number;
} => {
  let fullMatrix, nbOfExtraColumns, nbOfExtraRows;
  if (!reverse) {
    const matrixCells = prepareMatrixCells(data, false);
    const headers = addExtraColumnsToMatrixCells(
      data,
      prepareColumnHeaders(matrixCells, columnPivotInfo, rowPivotInfo, false),
      rowPivotInfo,
      columnPivotInfo,
      true,
      false
    );
    nbOfExtraColumns =
      Object.keys(headers[0]).length - Object.keys(matrixCells[0]).length;
    nbOfExtraRows = headers.length;
    const body = addExtraColumnsToMatrixCells(
      data,
      matrixCells,
      rowPivotInfo,
      columnPivotInfo,
      false,
      false
    ).map((d) => {
      Object.keys(d).forEach((key) => {
        if (
          typeof d[key] === "string" &&
          !d[key].startsWith("_") &&
          (d[key] as string).includes("/")
        ) {
          d[key] = `${
            Math.round(
              (parseFloat((d[key] as string).split("/")[0]) /
                parseFloat((d[key] as string).split("/")[1])) *
                1000
            ) / 10
          } %`;
        }
      });
      return d;
    });
    fullMatrix = headers.concat(body);
  } else {
    const matrixCells = prepareMatrixCells(data, true);
    const headers = addExtraColumnsToMatrixCells(
      data,
      prepareColumnHeaders(matrixCells, columnPivotInfo, rowPivotInfo, true),
      rowPivotInfo,
      columnPivotInfo,
      true,
      true
    );
    nbOfExtraColumns =
      Object.keys(headers[0]).length - Object.keys(matrixCells[0]).length;
    nbOfExtraRows = headers.length;
    const body = addExtraColumnsToMatrixCells(
      data,
      matrixCells,
      rowPivotInfo,
      columnPivotInfo,
      false,
      true
    ).map((d) => {
      Object.keys(d).forEach((key) => {
        if (
          typeof d[key] === "string" &&
          !d[key].startsWith("_") &&
          (d[key] as string).includes("/")
        ) {
          d[key] = `${
            Math.round(
              (parseFloat((d[key] as string).split("/")[0]) /
                parseFloat((d[key] as string).split("/")[1])) *
                1000
            ) / 10
          } %`;
        }
      });
      return d;
    });
    fullMatrix = headers.concat(body);
  }
  return {
    fullMatrix,
    nbOfExtraColumns,
    nbOfExtraRows,
  };
};

export const prepareMatrixCells = (
  data: MatrixData,
  reversed: boolean
): TDataFormatted[] => {
  let dataFormatted: TDataFormatted[] = [];
  if (!reversed) {
    const columnNamesMap: { [something: string]: boolean } = {};
    data.forEach((d) => {
      if (d.column) columnNamesMap[d.column] = true;
    });
    const rowNamesMap: { [something: string]: boolean } = {};
    data.forEach((d) => (rowNamesMap[d.row] = true));
    const rowNames = Object.keys(rowNamesMap).map((e) => e);
    dataFormatted = rowNames.reduce((acc: any, rowName) => {
      const result: { [something: string]: any } = {};
      Object.keys(columnNamesMap).forEach((columnName) => {
        const find = data.find(
          (d) => d.row === rowName && d.column === columnName
        );
        if (find) {
          const value: { [something: string]: any } = find.data[0];
          Object.keys(value).forEach(
            (k) => (result[`${columnName}__${k}`] = value[k])
          );
        }
      });
      return acc.concat(result);
    }, []);
  } else {
    dataFormatted = Object.keys(data[0].data[0]).map((varname) => {
      const res = {};
      data.forEach((d) => (res[d.row] = d.data[0][varname]));
      return res;
    });
  }

  return dataFormatted;
};

export const prepareColumnHeaders = (
  data: TDataFormatted[],
  columnPivotInfo: TPivotInfo,
  rowPivotInfo: TPivotInfo,
  reversed: boolean
): TDataFormatted[] => {
  const additionnalRows: TDataFormatted[] = [];
  if (!reversed) {
    const highestAggLevel = findHighestAggLevel(columnPivotInfo);
    const aggregationNumber = -1;
    for (let i = highestAggLevel; i >= aggregationNumber; i--) {
      // {"2020-10-02T22:00:00.000Z__count1": 1,"2020-10-02T22:00:00.000Z__count2": 1}
      // => {"2020-10-02T22:00:00.000Z__count1":"Week 40", "2020-10-02T22:00:00.000Z__count2": ""}
      const newRow = {};
      if (i === -1) {
        Object.keys(data[0]).forEach((d) => {
          const opt = columnPivotInfo.options.find(
            (o) => o.value === d.split("__")[0]
          )!;
          newRow[d] = opt.alias ? opt.alias : opt.value;
        });
      } else {
        Object.keys(data[0]).forEach((d) => {
          newRow[d] = columnPivotInfo.options.find(
            (o) => o.value === d.split("__")[0]
          )![`level_${i}_value`];
        });
      }
      // remove the information that is repeted
      additionnalRows.push(
        Object.keys(newRow).reduce((acc, curr) => {
          if (!Object.values(acc).includes(newRow[curr])) {
            acc[curr] = newRow[curr];
          } else acc[curr] = "";
          return acc;
        }, {})
      );
    }
    // the last row before the values: contains the name of the cell name
    const lastRow = {};
    Object.keys(data[0]).forEach((d) => {
      lastRow[d] = d.split("__")[1];
    });
    additionnalRows.push(lastRow);
  } else {
    const emptyObj = {};
    rowPivotInfo?.options.forEach((opt) => {
      emptyObj[opt.value] = "";
    });
    const highestAggLevel = findHighestAggLevel(rowPivotInfo!);
    for (let i = highestAggLevel; i >= -1; i--) {
      const additionnalRow = emptyObj;
      const attrName = i === -1 ? "value" : `level_${i}_value`;
      rowPivotInfo!.options.forEach((opt, i) => {
        if (
          i === 0 ||
          rowPivotInfo!.options[i - 1][attrName] !== opt[attrName]
        ) {
          // new value. We write it
          additionnalRow[opt.value] = opt[attrName];
          if (attrName === "value") {
            if (opt["alias"]) {
              additionnalRow[opt.value] = opt["alias"];
            }
          }
        }
      });
      additionnalRows.push({ ...additionnalRow });
    }
  }
  return additionnalRows;
};

export const findHighestAggLevel = (pivotInfo: TPivotInfo): number => {
  return pivotInfo.levelOptions.reduce((acc, curr) => {
    const currLevelNb = parseInt(
      curr.key.split("level_").join("").split("_value").join("")
    );
    if (acc < currLevelNb) {
      acc = currLevelNb;
    }
    return acc;
  }, -1);
};

const findSelectedLevel = (pivotInfo: TPivotInfo): number => {
  let aggregationNumber = -1;
  if (pivotInfo.levelSelected !== "value") {
    aggregationNumber = parseInt(
      pivotInfo.levelSelected.split("level_").join("").split("_value").join("")
    );
  }
  return aggregationNumber;
};

export const addExtraColumnsToMatrixCells = (
  fullData: MatrixData,
  data: TDataFormatted[],
  rowPivotInfo: TPivotInfo,
  columnPivotInfo: TPivotInfo,
  topLeftEmptySquare: boolean,
  reversed: boolean
) => {
  const additionnalColumns = {};
  const pivotInfo = reversed ? columnPivotInfo : rowPivotInfo;
  const highestAggLevel = findHighestAggLevel(pivotInfo);
  const aggregationNumber = -1;
  for (let i = highestAggLevel; i > aggregationNumber; i--) {
    additionnalColumns[`_level_${i}`] = "";
  }
  additionnalColumns["_value"] = "";
  if (reversed) {
    additionnalColumns["_varname"] = "";
  }
  return data.map((d, i) => {
    const res = {
      ...additionnalColumns,
      ...d,
    };
    if (topLeftEmptySquare) {
      return res;
    } else {
      let idx = i;
      let nbOfVarName = 0;
      if (reversed) {
        nbOfVarName = Object.keys(fullData[0].data[0]).length;
        idx = Math.floor(i / nbOfVarName);
      }
      // the columns should have the values of the pivot
      Object.keys(pivotInfo.options[idx]).forEach((att) => {
        if (att.includes("level_") && att.includes("_value")) {
          // check if the value of this level aggregation was the same for the previous row.
          // (if it is the case, we don't need to write it)
          if (
            i === 0 ||
            pivotInfo.options[i - 1][att] !== pivotInfo.options[idx][att]
          ) {
            res[`_${att.split("_value")[0]}`] = pivotInfo.options[idx][att];
          }
        } else if (att === "value") {
          if (reversed && i % nbOfVarName !== 0) {
            res["_value"] = "";
          } else {
            if (pivotInfo.options[idx].alias) {
              res["_value"] = pivotInfo.options[idx].alias;
            } else {
              res["_value"] = pivotInfo.options[idx].value;
            }
          }
        }
      });
      if (reversed) {
        res["_varname"] = Object.keys(fullData[0].data[0])[i % nbOfVarName];
      }
      return res;
    }
  });
};

export const aggregatePivot = (pivot: TPivotInfo): TPivotInfo => {
  const selectedLevel = findSelectedLevel(pivot);
  if (selectedLevel === -1) {
    return pivot;
  } else {
    const delta = selectedLevel + 1;
    const levelOptions = pivot.levelOptions.reduce((acc, curr) => {
      if (curr.key !== "value") {
        const levelNb = parseInt(
          curr.key.split("level_")[1].split("_value")[0]
        );
        if (levelNb >= selectedLevel) {
          // this level should be added to the list of levelOptions.
          if (levelNb === 0) {
            acc.push({
              label: pivot.levelOptions.find(
                (o) => o.key === `level_${levelNb}_value`
              )?.label!,
              key: `value`,
            });
          } else {
            acc.push({
              label: pivot.levelOptions.find(
                (o) => o.key === `level_${levelNb}_value`
              )?.label!,
              key:
                levelNb - delta === -1
                  ? "value"
                  : `level_${levelNb - delta}_value`,
            });
          }
        }
      }
      return acc;
    }, [] as IOption[]);
    const options = pivot.options.reduce((acc, curr) => {
      const levelShifted = `level_${delta - 1}_value`;
      if (acc.find((e) => e.value === curr[levelShifted])) {
        // an option with the same value already exists. We don't add the option
      } else {
        const newOption = {} as TPivotOption;
        for (let i = 0; i < levelOptions.length; i++) {
          const opt = levelOptions[i];
          let levelNb = -1;
          if (opt.key !== "value") {
            levelNb = parseInt(opt.key.split("level_")[1].split("_value")[0]);
          }
          newOption[opt.key] = curr[`level_${levelNb + delta}_value`];
          newOption[
            opt.key === "value"
              ? "label"
              : `${opt.key.split("_value")[0]}_label`
          ] = curr[`level_${levelNb + delta}_label`];
        }
        acc.push(newOption);
      }
      return acc;
    }, [] as TPivotOption[]);
    return {
      ...pivot,
      levelOptions,
      options,
    };
  }
};

const getMappingOldNewLevel = (pivot: TPivotInfo) => {
  const result = {};
  const selectedLevel = findSelectedLevel(pivot);
  if (selectedLevel === -1) return undefined;
  pivot.options.forEach((o) => {
    result[o.value] = o[`level_${selectedLevel}_value`];
  });
  return result;
};

export const fillData = (data: MatrixData): MatrixData => {
  const emptyData = {};
  data.forEach((d) => {
    d.data.forEach((dd) => {
      Object.keys(dd).forEach((ddd) => (emptyData[ddd] = ""));
    });
  });
  return data.map((d) => {
    if (d.data.length === 0) {
      d.data = [emptyData];
    }
    return d;
  });
};

export const aggregateData = (
  data: MatrixData,
  columnPivot: TPivotInfo,
  rowPivot: TPivotInfo,
  aggregator: AGGREGATOR
): MatrixData => {
  // fill data cell blanks
  const emptyData = {};
  data.forEach((d) => {
    Object.keys(d).forEach((dd) => (emptyData[dd] = ""));
  });
  const columnMapping = getMappingOldNewLevel(columnPivot);
  const rowMapping = getMappingOldNewLevel(rowPivot);
  let groupedData = data;
  if (!columnMapping && !rowMapping) {
    // do nothing
  } else {
    // group every records in the correct data
    groupedData = data.reduce((acc: MatrixData, curr) => {
      const correctedCurr = {
        column: columnMapping ? columnMapping[curr.column] : curr.column,
        row: rowMapping ? rowMapping[curr.row] : curr.row,
        data: curr.data,
      };
      if (
        acc.map((e) => e.column).includes(correctedCurr.column) &&
        acc.map((e) => e.row).includes(correctedCurr.row)
      ) {
        // the cell is already defined. We append the data to the existing cell
        acc.forEach((e) => {
          if (
            e.column === correctedCurr.column &&
            e.row === correctedCurr.row
          ) {
            e.data.push(correctedCurr.data[0]);
          }
          return e;
        });
      } else {
        acc.push(correctedCurr);
      }
      return acc;
    }, []);
  }
  // do the aggregation calculation
  return groupedData.map((e) => {
    if (e.data.length === 1) {
      return e;
    }
    e.data = aggregateDataUtilsWithSeveralAggregator(e.data, aggregator);
    return e;
  });
};

export const aggregateDataUtilsWithSeveralAggregator = (
  data: any[],
  aggregator: AGGREGATOR
): any[] => {
  const initValue = clone(data[0]);
  const aggregateDatas = Object.keys(initValue).map((key) => {
    let aggregation = aggregator;
    Object.keys(AGGREGATOR).forEach((agg) => {
      if (key.startsWith(`[${agg}]`)) {
        aggregation = agg as AGGREGATOR;
      }
    });
    return aggregateDataUtils(
      data.map((d) => ({ [key]: d[key] })),
      aggregation
    );
  });
  return aggregateDatas.reduce((acc, curr, i) => {
    if (i === 0) return acc;
    const attrName = Object.keys(curr[0])[0];
    acc[0][attrName] = curr[0][attrName];
    return acc;
  }, clone(aggregateDatas[0]));
};

export const aggregateDataUtils = (
  data: any[],
  aggregator: AGGREGATOR
): any[] => {
  const initValue = clone(data[0]);
  switch (aggregator) {
    case AGGREGATOR.SUM: {
      return [
        data.reduce((acc, curr, i) => {
          if (i === 0) return acc;
          Object.keys(curr).forEach((key) => {
            if (curr[key] !== "")
              acc[key] =
                (acc[key] === "" ? 0 : parseFloat(acc[key])) + curr[key];
          });
          return acc;
        }, initValue),
      ];
    }
    case AGGREGATOR.MIN: {
      return [
        data.reduce((acc, curr, i) => {
          if (i === 0) return acc;
          Object.keys(curr).forEach((key) => {
            if (curr[key] !== "") {
              acc[key] =
                acc[key] === "" || curr[key] < acc[key] ? curr[key] : acc[key];
            }
          });
          return acc;
        }, initValue),
      ];
    }
    case AGGREGATOR.MAX: {
      return [
        data.reduce((acc, curr, i) => {
          if (i === 0) return acc;
          Object.keys(curr).forEach((key) => {
            if (curr[key] !== "") {
              acc[key] =
                acc[key] === "" || curr[key] > acc[key] ? curr[key] : acc[key];
            }
          });
          return acc;
        }, initValue),
      ];
    }
    case AGGREGATOR.MEAN: {
      const sum = aggregateDataUtils(data, AGGREGATOR.SUM);
      Object.keys(data[0]).forEach((key) => {
        data[0][key] = sum[0][key] === "" ? "" : sum[0][key] / data.length;
      });
      return [data[0]];
    }
    case AGGREGATOR.PERCENTAGE: {
      const sumNumerator = aggregateDataUtils(
        data.map((d) =>
          Object.keys(d).reduce((acc, curr) => {
            acc[curr] = d[curr] === "" ? "" : parseFloat(d[curr].split("/")[0]);
            return acc;
          }, {})
        ),
        AGGREGATOR.SUM
      );
      const sumDenominator = aggregateDataUtils(
        data.map((d) =>
          Object.keys(d).reduce((acc, curr) => {
            acc[curr] = d[curr] === "" ? "" : parseFloat(d[curr].split("/")[1]);
            return acc;
          }, {})
        ),
        AGGREGATOR.SUM
      );
      if (
        Object.values(sumNumerator[0])[0] === "" ||
        Object.values(sumDenominator[0])[0] === ""
      ) {
        return [initValue];
      }
      Object.keys(data[0]).forEach((key) => {
        data[0][key] = `${
          Math.round(
            (parseFloat(sumNumerator[0][key]) /
              parseFloat(sumDenominator[0][key])) *
              1000
          ) / 10
        } %`;
      });
      return [data[0]];
    }
    case AGGREGATOR.BOOLEAN: {
      return [
        data.reduce((acc, curr, i) => {
          if (i === 0) return acc;
          Object.keys(curr).forEach((key) => {
            acc[key] = curr[key] || acc[key] ? 1 : 0;
          });
          return acc;
        }, initValue),
      ];
    }
    case AGGREGATOR.COUNT:
    case AGGREGATOR.COUNT_DISTINCT:
    case AGGREGATOR.NONE: {
      return [initValue];
    }
    default:
      return data;
  }
};
