import { ReactNode } from "react";

import { Box, InputLabel, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { GreyDark } from "assets/colors";
import { isEmptyValue } from "utils/isEmptyValue";

interface IMetadata {
  key: string;
  label: string;
  value: string | ReactNode;
}
export interface IMetadataDataSection {
  icon?: ReactNode;
  metadata: IMetadata[];
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  matadataRoot: {
    display: "flex",
    gap: "10px",
    color: GreyDark,
  },

  metadataIconSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "20px",
  },
  iconBox: {
    display: "flex",
    alignItems: "start",
    marginTop: "-1px",
  },

  metadataInfoSection: {
    flexGrow: 1,
    padding: "2px",
  },

  metadataLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
});

export function MetaDataSection({ icon, metadata }: IMetadataDataSection) {
  const classes = useStyle();
  const definedMetadata = _.filter(
    metadata,
    (meta) => !isEmptyValue(meta.value)
  );

  if (_.size(definedMetadata) === 0) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {_.map(definedMetadata, (data, index) => {
        const { key, label, value } = data;
        return (
          <Box key={key} className={classes.matadataRoot}>
            <Box className={classes.metadataIconSection}>
              {icon && index === 0 && !isEmptyValue(value) && (
                <Box className={classes.iconBox}>{icon}</Box>
              )}
            </Box>

            <Box className={classes.metadataInfoSection}>
              {!isEmptyValue(value) ? (
                <Box
                  display={"grid"}
                  gridTemplateRows={"auto 1fr"}
                  alignItems={"start"}
                >
                  <Box>
                    <InputLabel className={classes.metadataLabel}>
                      {label}:
                    </InputLabel>
                  </Box>

                  <Box>
                    <Box
                      fontSize={"16px"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {value}
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
