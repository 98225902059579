/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "api/apiService";
import { ITableOptimization } from "model/entities/TableOptimization";
import { IServerResponse } from "redux/actions/appActions";

import { prepareTableOptimizationForBackend } from "./utils";

export const TABLEOPTIMIZATIONS_ENDPOINT = `table-optimizations`;

export type TCreateTableOptimizationFunc = (
  tableOptimization: ITableOptimization
) => Promise<IServerResponse<ICreateTableOptimizationResponse>>;
export interface ICreateTableOptimizationResponse {
  id: string;
}
export type TDeleteTableOptimizationFunc = (
  table_optimization_id: string
) => Promise<IServerResponse<IDeleteTableOptimizationResponse>>;
export interface IDeleteTableOptimizationResponse {}

export type TFetchTableOptimizationsFunc = () => Promise<
  IServerResponse<IFetchTableOptimizationsResponse>
>;
export interface IFetchTableOptimizationsResponse {
  table_optimizations: ITableOptimization[];
}

//api calls for tableOptimizations
/**
 * Creates a new tableOptimization
 * @param {Object} tableOptimization TableOptimization details
 * @param {string} client_id clientId
 */
export const createTableOptimizationApiCall: TCreateTableOptimizationFunc =
  async (tableOptimization) => {
    const payload = prepareTableOptimizationForBackend(tableOptimization);
    return apiService.post(`/${TABLEOPTIMIZATIONS_ENDPOINT}`, payload);
  };
/**
 * Delete tableOptimization API call makes a POST request to delete a single tableOptimization
 * @param {String} tableOptimization Id of the tableOptimization to delete
 * @param {string} client_id clientId
 * @return {Promise}
 * */
export const deleteTableOptimizationApiCall: TDeleteTableOptimizationFunc = (
  table_optimization_id
) => {
  return apiService.delete(
    `/${TABLEOPTIMIZATIONS_ENDPOINT}/${table_optimization_id}`
  );
};

/**
 * Api call to fetch all
 */
export const fetchTableOptimizationsApiCall: TFetchTableOptimizationsFunc =
  () => {
    return apiService.get(`/${TABLEOPTIMIZATIONS_ENDPOINT}`);
  };
