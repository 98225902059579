import _ from "lodash";

import IUser from "model/entities/User";
import { getRandomHexColor, stringToColor } from "utils/colors";
import getFullName from "utils/formatting/getFullName";

// TODO: pick colors from a palette, like for territories to avoid colors that are too close (among the users, and vs the map colors)
export const getUserColors = (data: any, users: IUser[]) => {
  const userColors: { [key: string]: string } = {};
  _.keys(data).forEach((uid) => {
    const user = _.find(users, { id: uid });
    if (user) {
      userColors[uid] = stringToColor(getFullName(user));
    } else {
      userColors[uid] = getRandomHexColor();
    }
  });
  return userColors;
};
