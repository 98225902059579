import { Avatar, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { IStyles } from "model/application/styles";
import { stringToColor } from "utils/colors";

function stringAvatar(name: string) {
  let initials = "";
  const parts = name.split(" ");
  if (parts[0] && parts[0][0]) {
    initials += parts[0][0];
  }
  if (parts[1] && parts[1][0]) {
    initials += parts[1][0];
  }

  return {
    style: {
      background: stringToColor(name),
      fontWeight: "700",
      width: "40px",
      height: "40px",
      padding: "25px",
    },
    children: `${initials}`,
  };
}

export interface IUserIcon extends React.ComponentProps<typeof Avatar> {
  name: string;
}

const styles: IStyles = {
  container: {
    cursor: "pointer",
  },
};

const useStyles = makeStyles(styles as any);

function UserIcon({ name, ...rest }: IUserIcon) {
  const classes = useStyles();
  const customProps = stringAvatar(name);
  const style = {
    ...customProps.style,
    ...(rest.style || {}),
  };
  return (
    <Avatar
      className={classes.container}
      component={"div"}
      data-testid="user-icon"
      {..._.omit(customProps, "style")}
      {..._.omit(rest, "style")}
      style={style}
    />
  );
}

export default UserIcon;
