import { Box } from "@material-ui/core";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import _ from "lodash";

import MenuButton, { ImportIcon } from "components/Buttons/MenuButton";
import { DrawAddIcon } from "components/Buttons/MenuButton/MenuButton";
import { useTranslations } from "hooks";

import {
  ITerritoryListControlMenuPros,
  TerritoryListControlMenu,
} from "./TerritoryListControlMenu";

interface ITerritoriesSideMenuProps
  extends Pick<ITerritoryListControlMenuPros, "onClickEditTerritory"> {
  onClickDownloadTerritories: () => void;
  onClickSeeList: () => void;
  onClickTerritoriesSettings: () => void;
  onClickAddTerritory: () => void;
  onClickImportTerritories: () => void;
}

function CustomersMapTerritoriesControlMenu({
  onClickAddTerritory,
  onClickDownloadTerritories,
  onClickImportTerritories,
  onClickSeeList,
  onClickTerritoriesSettings,
  onClickEditTerritory,
}: ITerritoriesSideMenuProps) {
  const lang = useTranslations();
  const langSubCategories = lang.containers.lists.subCategories;
  const createTerritoryModalOptions = [
    {
      label: langSubCategories.territories.createEditModal?.editTitle,
      onClick: onClickTerritoriesSettings,
      icon: <ModeEditOutlinedIcon style={{ marginRight: "8px" }} />,
    },

    {
      label: langSubCategories.items.createEditModal.section?.LISTVIEW,
      onClick: onClickSeeList,
      icon: <FormatListBulletedOutlinedIcon style={{ marginRight: "8px" }} />,
    },
    {
      label: lang.actions.download,
      onClick: onClickDownloadTerritories,
      icon: <FileDownloadOutlinedIcon style={{ marginRight: "8px" }} />,
    },
  ];

  const createEditModalOptions = _.compact([
    {
      label: langSubCategories.territories.inputCreateShape.menuOptions.draw,
      onClick: onClickAddTerritory,
      icon: <DrawAddIcon />,
    },
    {
      label: langSubCategories.territories.createMenu.importFile,
      onClick: onClickImportTerritories,
      icon: <ImportIcon />,
    },
  ]);

  return (
    <Box padding={2}>
      <Box display="grid" gridAutoFlow="column" gridColumnGap="8px">
        <MenuButton
          label={
            langSubCategories.territories.createTerritoryModal.inputMoreOptions
              .title
          }
          options={createTerritoryModalOptions}
          buttonProps={{
            variant: "outlined",
            endIcon: <MoreHorizIcon />,
          }}
        />
        <MenuButton
          label={
            langSubCategories.items.createEditModal.inputTerritories
              .createEditModal.createTitle
          }
          options={createEditModalOptions}
        />
      </Box>
      <Box paddingTop={2}>
        <Box fontWeight={500} fontSize={"14px"} paddingBottom={1}>
          {langSubCategories.territories.subject}
        </Box>
        <TerritoryListControlMenu onClickEditTerritory={onClickEditTerritory} />
      </Box>
    </Box>
  );
}

export default CustomersMapTerritoriesControlMenu;
