import IPicture from "model/entities/Picture";
import { IAction } from "redux/store/model";
import { updateErrorsList } from "utils/reducerUtils";

import { IFetchPicturesSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface PicturesState {
  allPictures: IPicture[];
  totalPictures: number;
  isFetchingPictures: boolean;
  errors: any[];
}

const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case types.FETCH_PICTURE_BEGIN:
    case types.DOWNLOAD_PICTURE_BEGIN:
      return {
        ...state,
        isFetchingPictures: true,
      };

    case types.FETCH_PICTURE_SUCCESS:
      const { totalPictures, pictures } = action as IFetchPicturesSuccessAction;
      return {
        ...state,
        totalPictures,
        allPictures: pictures,
        isFetchingPictures: false,
      };
    case types.DOWNLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        isFetchingPictures: false,
      };

    case types.FETCH_PICTURE_FAILURE:
    case types.DOWNLOAD_PICTURE_FAILURE:
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isFetchingPictures: false,
      };

    default:
      return state;
  }
};

export default reducer;
