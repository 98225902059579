export interface IAppState {
  isFirstLogin: boolean;
  notifications: any[];
  warningModal: {
    title: string;
    description: string;
    isOpen: boolean;
  };
}

const initialState: IAppState = {
  isFirstLogin: true,
  notifications: [],
  warningModal: {
    title: "",
    description: "",
    isOpen: false,
  },
};

export default initialState;
