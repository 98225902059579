import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";

import { IFilter } from "../../model/application/Filter";
import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "../../model/entities/HierarchyDependency";
import { ITeamSelector } from "../../model/entities/Team";
import AddFilterButton from "./AddFilterButton";
import { getNewFilters } from "./Filter.utils";
import FilterDropdown from "./FilterDropdown";
import FILTER_TYPE, { FILTER_TAG } from "./TypeFilter";

const styles = {
  PrimaryfiltersDropdownManager: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "8px",
    // "&:not(.noBottomMargin)": {
    //   marginBottom: "20px",
    // },
  },
};

const useStyles = makeStyles(styles as any);

export interface IPrimaryfiltersDropdownManagerProps {
  filters: IFilter[];
  allTeamsObject?: ITeamSelector[];
  hierarchy?: IHierarchyDependency[];
  metaHierarchy?: IMetaHierarchyDependency;
  onChangeFilters: (filters: IFilter[]) => void;
  noBottomMargin?: boolean;
  openOnMount?: boolean;
}

export const PrimaryfiltersDropdownManager: React.FunctionComponent<
  IPrimaryfiltersDropdownManagerProps
> = (props: IPrimaryfiltersDropdownManagerProps) => {
  const classes = useStyles();

  const {
    metaHierarchy,
    hierarchy,
    allTeamsObject,
    filters,
    onChangeFilters,
    noBottomMargin,
    openOnMount = true,
  } = props;

  const handleChangeFilter = (tag: string, value: any) => {
    // check scope to determine owners type (team or mobile user)
    const isTeamScope = !filters.some((f) => f.tag === FILTER_TAG.TEAMS);

    const newFilters = getNewFilters({
      filters,
      tag,
      value,
      isTeamScope,
      metaHierarchy,
      hierarchy,
      allTeamsObject,
    });

    onChangeFilters(newFilters);
  };

  const primaryFilters = filters.filter(
    (f) => f.isSecondaryFilter === false && f.tag !== FILTER_TAG.MORE_FILTER
  );
  let moreFilter = _.find(filters, (f) => f.tag === FILTER_TAG.MORE_FILTER);
  if (moreFilter) {
    //more filter should not show primary filters, they should already be displayed and fixed
    moreFilter = cleanMoreFilter(moreFilter, primaryFilters);
  }

  const filtersToDisplay = _.compact(
    filters.map((filter) => {
      //if the filter is neither a primary filter nor is it in the more filter options
      //that means the filter has been selected from the more filter dropdown, so we display it
      const moreFilterTags =
        moreFilter?.options?.map((option) => option.key) || [];
      if (
        filter.isSecondaryFilter === true &&
        !moreFilterTags.includes(filter.tag)
      ) {
        return filter;
      }
    })
  );

  return (
    <div
      className={classNames(
        classes.PrimaryfiltersDropdownManager,
        noBottomMargin && "noBottomMargin"
      )}
    >
      {/* display the primary filters all the time,they cant be removed */}
      {primaryFilters.map((filter) => {
        return (
          <FilterDropdown
            key={filter.tag}
            filter={filter}
            onChangeFilter={handleChangeFilter}
            withoutArrow={!hasArrow(filter)}
            openOnMount={false}
            dataTestId={`${filter.tag}_filter`}
          />
        );
      })}

      {filtersToDisplay.map((filter) => {
        const onDelete = () => handleChangeFilter(filter.tag, null);

        return (
          <FilterDropdown
            dataTestId={`${filter.tag}_filter`}
            key={filter.tag}
            filter={filter}
            onChangeFilter={handleChangeFilter}
            withoutArrow={!hasArrow(filter)}
            onDelete={onDelete}
            openOnMount={openOnMount && filter === _.last(filtersToDisplay)}
          />
        );
      })}

      {moreFilter && (
        <AddFilterButton
          filter={moreFilter}
          onChangeFilter={handleChangeFilter}
        />
      )}
    </div>
  );
};

const hasArrow = (filter: IFilter) => {
  if (filter.type === FILTER_TYPE.DATE && !filter.singleSelection) {
    return false;
  }
  return true;
};

export function cleanMoreFilter(
  filter: IFilter,
  primaryFilters: IFilter[]
): IFilter {
  if (filter.tag !== FILTER_TAG.MORE_FILTER) return filter;
  const cleanedFilter: IFilter<any> = _.cloneDeep(filter);
  const primaryFilterTags = _.map(primaryFilters, "tag");
  cleanedFilter.options = cleanedFilter.options?.filter((filter) => {
    return !primaryFilterTags.includes(filter.key);
  });
  return cleanedFilter;
}

export default PrimaryfiltersDropdownManager;
