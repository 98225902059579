import { addQueryParameters } from "fieldpro-tools";

import apiService from "api/apiService";
import { prepareListSchemaForBackend } from "containers/lists/redux/utils";
import { IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import {
  IGenericQueryToSendToBackend,
  IServerResponse,
} from "redux/actions/appActions";

import { ITerritoryBE } from "../model";

const TERRITORIES_ENDPOINT = "territories";

export type TFetchTerritoriesFunc = (
  query: IGenericQueryToSendToBackend
) => Promise<
  IServerResponse<{
    territories: any[];
    item_count: number;
  }>
>;
export const fetchTerritoriesApiCall: TFetchTerritoriesFunc = (query) => {
  const finalUrl = addQueryParameters(`${TERRITORIES_ENDPOINT}`, query);
  return apiService.get(finalUrl);
};

export type TFetchTerritoriesSchemaFunc = () => Promise<
  IServerResponse<{
    schema: any[];
  }>
>;

export const fetchTerritoriesSchemaApiCall: TFetchTerritoriesSchemaFunc =
  () => {
    return apiService.get(`${TERRITORIES_ENDPOINT}/schema`);
  };

export type TCreateTerritoriesFunc = (
  territories: ITerritoryBE[]
) => Promise<IServerResponse<any>>;

export const createTerritoriesApiCall: TCreateTerritoriesFunc = (
  territories
) => {
  return apiService.post(`${TERRITORIES_ENDPOINT}`, {
    territories,
  });
};

export type TDeleteTerritoriesFunc = (
  itemIds: string[]
) => Promise<IServerResponse<{}>>;

export const deleteTerritoriesApiCall: TDeleteTerritoriesFunc = (itemIds) => {
  return apiService.delete(`${TERRITORIES_ENDPOINT}`, {
    ids: itemIds,
  });
};

export type TUpdateTerritoriesFunc = (
  territories: IListItem[]
) => Promise<IServerResponse<any>>;

export const updateTerritoriesApiCall: TUpdateTerritoriesFunc = async (
  territories
) => {
  return apiService.patch(`${TERRITORIES_ENDPOINT}`, {
    territories,
  });
};

export type TUpdateTerritoriesSchemaFunc = (
  fieldSchemas: IListSchema[]
) => Promise<IServerResponse<any>>;

export const updateTerritoriesSchemaApiCall: TUpdateTerritoriesSchemaFunc = (
  fieldSchemas
) => {
  return apiService.patch(`${TERRITORIES_ENDPOINT}/schema`, {
    attributes: prepareListSchemaForBackend(
      JSON.parse(JSON.stringify(fieldSchemas))
    ),
  });
};
