import { LayerProps } from "react-map-gl";

const contrastGreen = "#00ED00";
const contrastGreenLight = "#C0FF45";
// const contrastPurple = "#983FF4";
// const contrastPink = "#F63CCA";

export const delimitationsFillLayer: LayerProps = {
  id: "delimitations-fill",
  type: "fill",
  paint: {
    "fill-color": contrastGreen,
    "fill-opacity": 0.8,
    // "fill-opacity": [
    //   "case",
    //   ["boolean", ["feature-state", "hidden"], false],
    //   0,
    //   ["case", ["boolean", ["feature-state", "hovered"], false], 0.3, 0.1],
    // ],
    // "fill-outline-color": ["get", "color"],
  },
};

export const delimitationsLineLayer: LayerProps = {
  id: "delimitations-line",
  type: "line",
  paint: {
    "line-color": contrastGreenLight,
    "line-opacity": 0.8,
    // "line-opacity": [
    //   "case",
    //   ["boolean", ["feature-state", "hidden"], false],
    //   0,
    //   ["case", ["boolean", ["feature-state", "hovered"], false], 0.3, 0.1],
    // ],
  },
};
