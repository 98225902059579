import React from "react";

import { Box, Button, Divider, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddCircle from "@material-ui/icons/AddCircle";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { CUSTOM_FIELD_TYPE, IKPI } from "fieldpro-tools";
import _ from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import SplitButton from "components/Buttons/SplitButton";
import CustomExpandableChip from "components/Chip/CustomExpandableChip";
import CustomDialogForm, { TSize } from "components/Dialog/CustomDialogForm";
import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import CustomTooltip from "components/Tooltip/CustomTooltip";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { IOption } from "model/application/components";
import { TInputAttributeLang, TLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import IRootState from "redux/store/model";
import { downloadCsvFile, getCSVFromDataRows } from "utils/exportUtils";
import { clone, isFromTemplate } from "utils/utils";

import CustomChip from "../../Chip/CustomChip";
import CustomDialogWarning from "../../Dialog/CustomDialogWarning";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "../InputEmptyContainer";
import styles from "../styles";

export interface ICustomInputMultipleCreatePropsBase {
  client: IClient;
  name: string;
  error?: string;
  defaultValue: any[];
  size?: TSize;
  father?: string;
  onChange: (elements: any[], name: string, father?: string) => void;
  isRemoveOptionPossible?: (elements: any[], keySelected: number) => boolean;
  getErrorMessages?: TValidateFunction;
  getWarningMessages?: TValidateFunction;
  createModalTitleFunction?: (element: any) => string;
  editModalTitleFunction?: (element: any) => string;
  restoreModalTitleFunction?: (element: any) => string;
  classes?: any;
  chipTitleTemplate: string;
  CreateEditModal: any;
  isCreation?: boolean;
  editOnly?: boolean;
  actionsDisabled?: boolean;
  lockedItems?: object[];
  additionnalProps?: object;
  lang: TLang;
  langlabel: TInputAttributeLang;
  onBulkImport?: (e: any) => void;
  BulkModal?: any;
  draggable?: boolean;
  expandableChip?: boolean;
  buildExpandableContent?: (e: any) => any;
  removeBorders?: boolean;
  defaultValueForNewElement?: any;
  duplicateFunc?: (e?: any) => any;
  showDownloadButton?: boolean;
  onDownloadListOptions?: (options: IOption[]) => void;
  RunKpiQuery?: (kpi: IKPI) => any;
  attributesTag?: string[];
  deprecatedItems?: any[];
  itemType?: "LIST_ATTRIBUTE" | "ACTIVITY_QUESTION" | "OPTION";
  viewMode?: TViewMode;
}
export interface ICustomInputMultipleCreateProps
  extends ICustomInputMultipleCreatePropsBase,
    Omit<IInputBaseLayout, keyof ICustomInputMultipleCreatePropsBase> {}

export interface ICustomInputMultipleCreateStates {
  elemIdx: number;
  elements: any[];
  deprecatedElements: any[];
  touchedElement?: any;
  touchedElementIndex?: number; // when creating a new element
  isCreateEditModalOpen: boolean;
  isWarningModalOpen: boolean;
  isErrorModalOpen: boolean;
  modalType: "create" | "edit" | "restore";
  keySelected: number;
  isCreation: boolean;
  isBulkOpen: boolean;
  isOnDrag: boolean;
  indexAdd: number;
  isShowArchivedOpen?: boolean;
}

export class CustomInputMultipleCreate extends React.Component<
  ICustomInputMultipleCreateProps,
  ICustomInputMultipleCreateStates
> {
  public static defaultProps = {
    lockedItems: [],
    draggable: false,
    isCreation: false,
    defaultValueForNewElement: {},
    showDownloadButton: false,
  };

  constructor(props: ICustomInputMultipleCreateProps) {
    super(props);
    let elemIdx = -1;
    if (props.lockedItems) elemIdx += props.lockedItems.length;
    if (props.defaultValue) elemIdx += props.defaultValue.length;
    let elements: any[] = [];
    let deprecatedElements: any[] = [];
    if (props.lockedItems) props.lockedItems.forEach((e) => elements.push(e));
    if (props.defaultValue) props.defaultValue.forEach((e) => elements.push(e));
    if (props.deprecatedItems)
      props.deprecatedItems.forEach((e) => deprecatedElements.push(e));
    elements = elements.sort((a, b) => a.index - b.index);
    deprecatedElements = deprecatedElements.sort((a, b) => a.index - b.index);

    this.state = {
      elemIdx,
      elements, //: this.prepareElements(elements),
      deprecatedElements,
      isCreateEditModalOpen: false,
      isWarningModalOpen: false,
      isErrorModalOpen: false,
      modalType: "create",
      keySelected: -1,
      isCreation: false,
      isBulkOpen: false,
      isOnDrag: false,
      touchedElement: undefined,
      indexAdd: 0,
      isShowArchivedOpen: false,
    };
  }

  prepareElements = (elements: any) => {
    return elements.map((e: any, idx: number) => {
      return {
        ...e,
        key: idx,
      };
    });
  };

  handleAddModalOpen = () => {
    const { elemIdx } = this.state;
    this.handleAddModalAtSpecificPositionOpen(elemIdx + 1);
  };

  handleAddModalAtSpecificPositionOpen = (index: number) => {
    // TODO
    const { defaultValueForNewElement /*, additionnalProps, father*/ } =
      this.props;
    //const { elements } = this.state;
    this.setState({
      indexAdd: index,
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        isCreateEditModalOpen: true,
        touchedElementIndex: index,
        touchedElement: { ...defaultValueForNewElement },
        modalType: "create",
        isCreation: true,
      };
    });
  };

  handleAddElement = () => {
    const { onChange, name, father } = this.props;
    const { elements, elemIdx, touchedElement, touchedElementIndex } =
      this.state;
    let newIdx = elemIdx + 1;

    if (touchedElementIndex != null) {
      // trying to insert an element in a specific place
      newIdx = touchedElementIndex;
    }
    elements.push({
      ...touchedElement,
      index: newIdx,
    });
    // remove "_error" attribute if no errors
    elements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });
    // remove "_warning" attribute if no warnings
    elements.forEach((e) => {
      if (_.isEmpty(e._warning)) {
        delete e._warning;
      }
    });
    const newElements = elements
      .sort((a, b) =>
        a.hasOwnProperty("index") && b.hasOwnProperty("index")
          ? a.index - b.index
          : 1
      )
      .map((e, index) => ({ ...e, index }));
    this.setState({
      elements: newElements,
      elemIdx: newIdx,
      touchedElementIndex: undefined,
    });

    onChange(newElements, name, father);
    this.handleCreateEditModalClose();
  };
  handleRestoreElement = () => {
    const { onChange, name, father } = this.props;
    const { elements, elemIdx, touchedElement, deprecatedElements } =
      this.state;

    const newIdx = elemIdx + 1;

    delete touchedElement?.deprecated;

    elements.push({
      ...touchedElement,
      index: newIdx,
    });

    elements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });

    elements.forEach((e) => {
      if (_.isEmpty(e._warning)) {
        delete e._warning;
      }
    });
    const newElements = elements
      .sort((a, b) =>
        a.hasOwnProperty("index") && b.hasOwnProperty("index")
          ? a.index - b.index
          : 1
      )
      .map((e, index) => ({ ...e, index }));
    let deprecated = [...deprecatedElements];
    deprecated = deprecated.filter((depEl) =>
      this.props.itemType === "ACTIVITY_QUESTION"
        ? depEl.tag !== touchedElement.tag
        : this.props.itemType === "LIST_ATTRIBUTE"
        ? depEl.column_tag !== touchedElement.column_tag
        : false
    );
    this.setState((prevState) => {
      return {
        ...prevState,
        elements: newElements,
        elemIdx: newIdx,
        deprecatedElements: [...deprecated],
        touchedElementIndex: undefined,
      };
    });

    onChange(newElements, name, father);
    this.handleCreateEditModalClose();
  };
  handleRestoreElementModalOpen = (e: any) => {
    const newIdx = this.state.elemIdx + 1;
    const elementClone = { ...e };
    this.setState((prevState) => {
      return {
        ...prevState,
        isCreateEditModalOpen: true,
        touchedElementIndex: newIdx,
        touchedElement: { ...elementClone },
        modalType: "restore",
        isCreation: false,
      };
    });
  };

  handleEditElement = () => {
    const { onChange, name, father } = this.props;
    const { elements, touchedElement } = this.state;
    const newElements: any[] = elements
      .map((c) => {
        if (c.index === touchedElement?.index) {
          return touchedElement;
        } else {
          return c;
        }
      })
      .filter((e) => e);
    // remove "_error" attribute if no errors
    newElements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });
    // remove "_warning" attribute if no warnings
    newElements.forEach((e) => {
      if (_.isEmpty(e._warning)) {
        delete e._warning;
      }
    });
    this.setState({
      elements: newElements,
    });
    onChange(newElements, name, father);
    this.handleCreateEditModalClose();
  };

  handleRemoveOption = (keySelected: number) => {
    const { elements } = this.state;
    const { langlabel, father, isRemoveOptionPossible } = this.props;
    if (isRemoveOptionPossible) {
      if (!isRemoveOptionPossible(elements, keySelected)) {
        this.setState({
          isErrorModalOpen: true,
        });
        return;
      }
    }
    if (langlabel.warningOnRemove) {
      // a warning message is setup => we display the confirmation modal
      this.setState({
        keySelected,
        isWarningModalOpen: true,
      });
    } else {
      // no warning message is setup => we remove directly the option
      const { onChange, name } = this.props;
      const { elements } = this.state;
      const newElements = elements.filter((opt) => opt.index !== keySelected);
      this.setState({
        elements: newElements,
        isWarningModalOpen: true,
      });

      onChange(newElements, name, father);
    }
  };
  handleRestoreOption = (keySelected: number) => {
    const { onChange, name, father } = this.props;
    const { elements, deprecatedElements, elemIdx } = this.state;
    const restoredOption = deprecatedElements.find(
      (el) => el.index === keySelected
    );
    const newIdx = elemIdx + 1;
    const restoredOptionClone = { ...restoredOption };
    delete restoredOptionClone.deprecated;
    elements.push({ ...restoredOptionClone, index: newIdx });
    let depOpts = [...this.state.deprecatedElements];
    depOpts = depOpts.filter((e) => e.index !== keySelected);
    this.setState({
      elements,
      deprecatedElements: depOpts,
      elemIdx: newIdx,
      touchedElementIndex: undefined,
    });

    onChange(elements, name, father);
  };

  handleConfirmRemoveOption = () => {
    const { onChange, name, father } = this.props;
    const { elements, keySelected } = this.state;
    const newElements = elements.filter((opt) => opt.index !== keySelected);
    this.setState({
      elements: newElements,
      isWarningModalOpen: false,
    });
    onChange(newElements, name, father);
  };

  handleEditModalOpen = (index: number) => {
    const { elements } = this.state;
    const { lockedItems = [] } = this.props;
    const touchedElement = elements.find((e) => e.index === index);

    if (touchedElement) {
      if (index < lockedItems.length) {
        touchedElement.isLockedElement = true;
      }
      this.setState({
        touchedElement,
        isCreateEditModalOpen: !this.state.isCreateEditModalOpen,
        modalType: "edit",
        isCreation: false,
      });
    }

    this.setState({
      indexAdd: index - 1,
    });
  };

  handleDuplicateModalOpen = (element: any) => {
    const { duplicateFunc } = this.props;
    if (duplicateFunc) duplicateFunc(element);
  };

  handleCreateEditModalClose = () => {
    this.setState({
      isCreateEditModalOpen: false,
      touchedElement: undefined,
    });
  };
  handleRunKpiQuery = () => {
    const { touchedElement } = this.state;
    const { RunKpiQuery } = this.props;
    if (RunKpiQuery) RunKpiQuery(touchedElement as IKPI);
  };

  reOrderDrag = (elements: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(elements);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((r, i) => ({
      ...r,
      index: i,
    }));
  };

  onDragEnd = (result: any) => {
    const { onChange, name, father } = this.props;

    if (!result.destination) {
      return;
    }

    let elements = this.reOrderDrag(
      this.state.elements,
      result.source.index,
      result.destination.index
    );

    elements = elements.map((e, idx) => ({ ...e, key: idx, index: idx }));

    this.setState({ elements, isOnDrag: false });
    onChange(elements, name, father);
  };

  handleDownloadCSV = () => {
    const { additionnalProps, itemType } = this.props;
    let schema: any[] = [];
    let name = "";
    if (
      additionnalProps &&
      (additionnalProps["schema"] || additionnalProps["originalActivity"])
    ) {
      schema =
        itemType === "LIST_ATTRIBUTE"
          ? additionnalProps["schema"]
          : additionnalProps["originalActivity"].questionnaire["questions"];
      name =
        itemType === "LIST_ATTRIBUTE"
          ? additionnalProps["list_name"]
          : additionnalProps["originalActivity"].name;
    }
    schema = schema.filter((s) => s.type !== CUSTOM_FIELD_TYPE.MATRIX_ON_LIST);
    schema = schema.map((s) => {
      return {
        column_type: s.type,
        column_name:
          itemType === "LIST_ATTRIBUTE" ? s.column_name : s.question_text,
        column_tag: itemType === "LIST_ATTRIBUTE" ? s.column_tag : s.tag,
        ...(s.options && {
          options: JSON.stringify(
            s.options.map((opt: any) => ({
              key: opt.key,
              label: opt.label,
            }))
          ),
        }),
      };
    });
    const datarows = [
      ["TYPES", "LABELS", "TAGS", "OPTIONS"],
      ...schema.map((sch: any) => [
        sch.column_type,
        sch.column_name,
        sch.column_tag,
        sch.options || "",
      ]),
    ];
    const resultCSV = getCSVFromDataRows(datarows);
    downloadCsvFile(
      resultCSV,
      itemType === "LIST_ATTRIBUTE" ? `${name}_attributes` : `${name}_questions`
    );
  };
  render() {
    const {
      draggable,
      classes,
      createModalTitleFunction,
      editModalTitleFunction,
      restoreModalTitleFunction,
      getErrorMessages,
      error,
      langlabel,
      onBulkImport,
      BulkModal,
      additionnalProps,
      editOnly,
      actionsDisabled: disableAction,
      lang,
      showDownloadButton,
      onDownloadListOptions,
      itemType,
      father,
      size,
      viewMode = "CREATE",
      RunKpiQuery,
      ...rest
    } = this.props;

    const {
      elements,
      deprecatedElements,
      isWarningModalOpen,
      isErrorModalOpen,
      isOnDrag,
      modalType,
      isCreateEditModalOpen,
      touchedElement,
      isShowArchivedOpen,
    } = this.state;

    const deprecated = deprecatedElements.filter((e) =>
      this.props.itemType === "ACTIVITY_QUESTION"
        ? !elements.map((el) => el.tag).includes(e.tag)
        : this.props.itemType === "LIST_ATTRIBUTE"
        ? !elements.map((el) => el.column_tag).includes(e.column_tag)
        : !elements.map((el) => el.key).includes(e.key)
    );

    const options =
      showDownloadButton && additionnalProps ? additionnalProps["options"] : [];

    let isDisabled = false;
    let isRunQueryDisabled = false;
    if (getErrorMessages) {
      const errorMessages = getErrorMessages({
        attributes: touchedElement ? clone(touchedElement) : {},
        additionnalProps,
        lang,
        viewMode: modalType === "create" ? "CREATE" : "EDIT",
      });

      isDisabled = !_.isEmpty(errorMessages);
      isRunQueryDisabled = errorMessages.hasOwnProperty("query");
    }

    // Texts
    const label = langlabel ? langlabel.title : "unknown label";
    const tooltip = langlabel ? langlabel.tooltip : undefined;

    const createButtonOptions = [];
    if (!editOnly && !disableAction) {
      createButtonOptions.push({
        label: (
          <>
            <DefaultAddIcon /> Add
          </>
        ),
        onClick: this.handleAddModalOpen,
        ariaLabel: "Add-Button",
      });
    }

    if (onBulkImport) {
      createButtonOptions.push({
        label: "Bulk",
        onClick: () => this.setState({ isBulkOpen: true }),
      });
    }
    return (
      <Box>
        <InputBaseLayout
          {...rest}
          label={label}
          tooltip={tooltip}
          required
          viewMode={viewMode}
          error={error}
        >
          {viewMode === "CREATE" && _.isEmpty(elements) ? (
            <InputEmptyContainer>
              <Box style={{ display: "flex", gap: "16px" }}>
                <SplitButton options={createButtonOptions} />
              </Box>
            </InputEmptyContainer>
          ) : (
            <Box
              display="flex"
              flexGrow="1"
              flexDirection="column"
              className={classes.ExpandableChipsContainer}
            >
              <Box
                marginLeft="auto"
                display="flex"
                flexDirection="row"
                style={{ gap: "4px" }}
              >
                {onBulkImport && (
                  <Button
                    onClick={() => this.setState({ isBulkOpen: true })}
                    style={{ minWidth: 0 }}
                  >
                    Bulk
                  </Button>
                )}
                {showDownloadButton && (
                  <IconButton
                    className={classes.SmallIconButton}
                    aria-label="download-Button"
                    onClick={() =>
                      onDownloadListOptions && onDownloadListOptions(options)
                    }
                  >
                    <GetAppRoundedIcon />
                  </IconButton>
                )}
                {editOnly || disableAction ? (
                  <></>
                ) : (
                  <IconButton
                    className={classes.SmallIconButton}
                    aria-label="Add-Button"
                    onClick={this.handleAddModalOpen}
                  >
                    <AddCircle />
                  </IconButton>
                )}
              </Box>

              <div
                className={classes.ChipContainer}
                style={{ paddingBottom: isOnDrag ? 40 : 4 }}
              >
                {draggable ? (
                  <DragDropContext
                    onDragStart={() => this.setState({ isOnDrag: true })}
                    onDragEnd={this.onDragEnd}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, _snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ width: "100%" }}
                        >
                          {_.map(elements, (element, idx) => {
                            if (!element._viewdetail) {
                              return (
                                <Draggable
                                  key={idx}
                                  draggableId={idx.toString()}
                                  index={idx}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {this.buildChip(element, idx)}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else return this.buildChip(element, idx);
                          })}

                          <div
                            key={`${deprecated.length}-${elements.length}`}
                            style={{ width: "100%" }}
                          >
                            {deprecated &&
                              deprecated.length > 0 &&
                              isShowArchivedOpen &&
                              deprecated.map((element, idx) => {
                                return this.buildChip(element, idx);
                              })}
                          </div>

                          {(!father ||
                            (deprecated && deprecated.length > 0)) && (
                            <>
                              <Divider />

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                paddingTop="12px"
                              >
                                {!father && showDownloadButton && (
                                  <CustomTooltip
                                    title={lang.modal.downloadCSV.tooltip}
                                  >
                                    <CustomButton
                                      onClick={this.handleDownloadCSV}
                                      disabled={false}
                                      type={BUTTON_TYPES_OUTSIDE_TABLE.DEFAULT}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          columnGap: 10,
                                        }}
                                      >
                                        <DownloadIcon />{" "}
                                        <span>
                                          {lang.modal.downloadCSV.title}
                                        </span>
                                      </span>
                                    </CustomButton>
                                  </CustomTooltip>
                                )}

                                {deprecated && deprecated.length > 0 && (
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        isShowArchivedOpen: !isShowArchivedOpen,
                                      });
                                    }}
                                    aria-label={"show-hide-archived"}
                                  >
                                    {isShowArchivedOpen
                                      ? itemType === "LIST_ATTRIBUTE"
                                        ? "Hide archived attributes"
                                        : itemType === "ACTIVITY_QUESTION"
                                        ? "Hide archived questions"
                                        : "Hide archived options"
                                      : itemType === "LIST_ATTRIBUTE"
                                      ? "Show archived attributes"
                                      : itemType === "ACTIVITY_QUESTION"
                                      ? "Show archived questions"
                                      : "Show archived options"}
                                  </Button>
                                )}
                              </Box>
                            </>
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <React.Fragment>
                    {elements.map((element, idx) =>
                      this.buildChip(element, idx)
                    )}
                  </React.Fragment>
                )}
              </div>
            </Box>
          )}
        </InputBaseLayout>

        {langlabel.warningOnRemove ? (
          <CustomDialogWarning
            isOpen={isWarningModalOpen}
            onClose={() => this.setState({ isWarningModalOpen: false })}
            onConfirmAction={this.handleConfirmRemoveOption}
            lang={{
              title: langlabel.warningOnRemove.title,
              description: langlabel.warningOnRemove.description,
            }}
            rootLang={lang}
          />
        ) : null}
        {langlabel.errorOnRemove ? (
          <CustomDialogWarning
            isOpen={isErrorModalOpen}
            onClose={() => this.setState({ isErrorModalOpen: false })}
            lang={{
              title: langlabel.errorOnRemove.title,
              description: langlabel.errorOnRemove.description,
            }}
            rootLang={lang}
          />
        ) : null}

        <CustomDialogForm
          isOpen={isCreateEditModalOpen}
          onClose={this.handleCreateEditModalClose}
          title={
            touchedElement
              ? modalType === "create"
                ? (createModalTitleFunction
                    ? createModalTitleFunction
                    : () => langlabel.createEditModal.createTitle)(
                    touchedElement
                  )
                : modalType === "restore"
                ? (restoreModalTitleFunction
                    ? restoreModalTitleFunction
                    : () => langlabel.createEditModal.createTitle)(
                    touchedElement
                  )
                : (editModalTitleFunction
                    ? editModalTitleFunction
                    : () => langlabel.createEditModal.editTitle)(touchedElement)
              : ""
          }
          onConfirmAction={
            modalType === "create"
              ? this.handleAddElement
              : modalType === "restore"
              ? this.handleRestoreElement
              : this.handleEditElement
          }
          OnRunKpiQueryAction={RunKpiQuery ? this.handleRunKpiQuery : undefined}
          confirmBtnText={`${
            modalType === "create"
              ? lang.modal.add
              : modalType === "restore"
              ? lang.modal.restore
              : lang.modal.save
          }`}
          runKpiQueryText={lang.modal.test}
          isDisabled={isDisabled}
          isRunQueryDisabled={isRunQueryDisabled}
          size={size}
          lang={lang}
        >
          {this.prepareCreateEditModalContent()}
        </CustomDialogForm>

        {onBulkImport && (
          <BulkModal
            isOpen={this.state.isBulkOpen}
            onCloseModal={() => this.setState({ isBulkOpen: false })}
            onConfirmModal={onBulkImport}
            lang={lang}
            langlabel={langlabel}
          />
        )}
      </Box>
    );
  }

  handleChangeToCompact = (element: any) => {
    const { onChange, name, father } = this.props;

    const newElements = this.state.elements.map((e, i) =>
      i === element.index ? { ...e, _viewdetail: false } : e
    );
    this.setState({
      elements: newElements,
    });
    onChange(newElements, name, father);
  };

  handleChangeToDetail = (element: any) => {
    const { onChange, name } = this.props;

    const newElements = this.state.elements.map((e, i) =>
      i === element.index ? { ...e, _viewdetail: true } : e
    );
    this.setState({
      elements: newElements,
    });
    onChange(newElements, name);
  };

  buildChip = (element: any, idx: number) => {
    const {
      expandableChip,
      chipTitleTemplate,
      buildExpandableContent,
      lockedItems = [],
      editOnly,
      actionsDisabled: disableAction,
      lang,
      viewMode,
      client,
    } = this.props;

    const fromTemplate = isFromTemplate(element, client);

    if (expandableChip) {
      const isDeprecated =
        element.hasOwnProperty("deprecated") &&
        (element.deprecated === true || element.deprecated !== 0);

      const onDel = () => {
        const lockedItemsChip =
          lockedItems &&
          element.column_tag &&
          lockedItems
            .map((i) => (i || {})["column_tag"])
            .includes(element.column_tag);

        if (
          (fromTemplate && this.props.name !== "kpis") || // there is an exception for...
          // ...dashboards: we can remove charts of a dashboard coming from a template
          editOnly ||
          lockedItemsChip ||
          isDeprecated ||
          disableAction
        ) {
          return undefined;
        }

        return this.handleRemoveOption;
      };

      return (
        <CustomExpandableChip
          key={`${element.id}-${idx}`}
          title={this.prepareChipTitle(chipTitleTemplate, element)}
          maxWidth={viewMode === "EDIT" ? "280px" : undefined}
          onAdd={
            isDeprecated || disableAction
              ? undefined
              : this.handleAddModalAtSpecificPositionOpen
          }
          onDelete={onDel()}
          onEdit={
            !isDeprecated && !disableAction
              ? this.handleEditModalOpen
              : undefined
          }
          onDuplicate={
            !isDeprecated ? this.handleDuplicateModalOpen : undefined
          }
          onRestore={
            isDeprecated ? this.handleRestoreElementModalOpen : undefined
          }
          onChangeToCompact={this.handleChangeToCompact}
          onChangeToDetail={this.handleChangeToDetail}
          buildExpandableContent={
            buildExpandableContent && buildExpandableContent(element)
              ? buildExpandableContent
              : undefined
          }
          element={element}
          lang={lang}
          isFromTemplate={fromTemplate}
        />
      );
    } else {
      const isDeprecated =
        element.hasOwnProperty("deprecated") &&
        (element.deprecated === true || element.deprecated !== 0);

      return (
        <CustomChip
          key={`${element.id}-${idx}`}
          index={element.hasOwnProperty("index") ? element.index : idx}
          value={element.hasOwnProperty("index") ? element.index : idx}
          label={this.prepareChipTitle(chipTitleTemplate, element)}
          onClick={
            fromTemplate || isDeprecated ? undefined : this.handleEditModalOpen
          }
          onAction={
            idx >= lockedItems.length &&
            !editOnly &&
            !isDeprecated &&
            !disableAction
              ? this.handleRemoveOption
              : isDeprecated
              ? this.handleRestoreOption
              : undefined
          }
          type={
            idx < lockedItems.length
              ? "locked"
              : isDeprecated
              ? "add"
              : "remove"
          }
        />
      );
    }
  };

  prepareChipTitle = (pattern: string, element: any) => {
    const { expandableChip } = this.props;
    let result = "";
    const splittedPattern = pattern.split("$");
    for (let i = 0; i < splittedPattern.length; i++) {
      if (i % 2 === 1) {
        // even idx: we replace the string per the value of the attribute
        result += element[splittedPattern[i]];
      } else {
        // odd idx: we display the string as it is
        result += splittedPattern[i];
      }
    }
    if (result.length > 30 && !expandableChip)
      result = result.substring(0, 27) + "...";
    return result;
  };

  handleChangeElement = (e: any) => {
    this.setState({
      touchedElement: e,
    });
  };

  prepareCreateEditModalContent = () => {
    const {
      CreateEditModal,
      additionnalProps,
      defaultValueForNewElement,
      lang,
      getWarningMessages,
      getErrorMessages,
    } = this.props;
    const { touchedElement, modalType, indexAdd } = this.state;
    const isLocked = touchedElement ? touchedElement.isLockedElement : false;
    if (touchedElement) delete touchedElement.isLockedElement;
    const elem = touchedElement ?? defaultValueForNewElement;

    const warningMessages = getWarningMessages
      ? getWarningMessages({
          attributes: elem,
          additionnalProps,
          lang,
          viewMode: modalType === "create" ? "CREATE" : "EDIT",
        })
      : false;

    const errorMessages = getErrorMessages
      ? getErrorMessages({
          attributes: elem,
          additionnalProps,
          lang,
          viewMode: modalType === "create" ? "CREATE" : "EDIT",
        })
      : false;
    // remove all the "real time error status" (ex: empty field, or non-alphanumeric), keep the additionnal error status
    if (elem._error || elem._warning) {
      elem._error &&
        Object.keys(elem._error).forEach((k) => {
          if (
            [
              lang.components.inputErrors.empty,
              lang.components.inputErrors.tooLong,
              lang.components.inputErrors.alphanumeric,
              lang.components.inputErrors.alreadyInUse,
              lang.components.inputErrors.templateTagsRequirePrefix,
            ].includes(elem._error[k])
          ) {
            delete elem._error[k];
          }
        });
      elem._warning = { ...warningMessages };
      if (elem._error) elem._error = { ...elem._error, ...errorMessages };
    } else {
      elem._error = errorMessages;
      elem._warning = warningMessages;
    }

    if (
      elem.hasOwnProperty("_error") &&
      (elem._error == null || Object.keys(elem._error).length === 0)
    ) {
      delete elem._error;
    }
    if (
      elem.hasOwnProperty("_warning") &&
      (elem._warning == null || Object.keys(elem._warning).length === 0)
    ) {
      delete elem._warning;
    }

    return (
      <CreateEditModal
        key={touchedElement ? touchedElement.index : "new"}
        isCreation={modalType === "create"}
        isLocked={isLocked}
        element={elem}
        modalType={modalType}
        additionnalProps={additionnalProps}
        onChange={this.handleChangeElement}
        lang={lang}
        numberIndex={indexAdd}
      />
    );
  };
}

const DefaultAddIcon = () => (
  <span className="material-icons-outlined" style={{ marginRight: "4px" }}>
    add
  </span>
);

function mapStateToProps(state: IRootState) {
  return {
    client: getSelectedClient(state),
  };
}

export default withStyles(styles as any)(
  connect(mapStateToProps)(CustomInputMultipleCreate)
);
