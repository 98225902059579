import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import { TCalendarFilterObject } from "../types";
interface GetEventsToDisplayParams {
  allowDisplayingEvents: boolean;
  filterQuery: TCalendarFilterObject;
  events: TCalendarEvent[];
  fullDayFilter: boolean | null; // true for full day only, false for non-full day only, null for both
}

export function getEventsToDisplay({
  allowDisplayingEvents,
  filterQuery,
  events,
  fullDayFilter,
}: GetEventsToDisplayParams): TCalendarEvent[] {
  if (!allowDisplayingEvents) return [];
  if (!_(filterQuery.STATUS).isEmpty()) {
    return _(events)
      .filter(
        (event) =>
          _(filterQuery.STATUS).includes(event.status) &&
          (fullDayFilter === null
            ? true
            : Boolean(event.full_day) === fullDayFilter)
      )
      .value();
  }
  //Just to be safe, deleted events are not returned by the backend
  return _(events)
    .filter(
      (e) =>
        e.status != CalendarEventStatus.DELETED &&
        (fullDayFilter === null ? true : Boolean(e.full_day) === fullDayFilter)
    )
    .value();
}
