import { INPUT_TEXT_TYPE } from "components/Input/InputText/InputText";
import { CUSTOM_FIELD_TYPE } from "model/entities/List";

export const getInputTextTypeFromAttributeType = (
  attrType: CUSTOM_FIELD_TYPE
): INPUT_TEXT_TYPE => {
  switch (attrType) {
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_TEXT:
      return INPUT_TEXT_TYPE.TEXT;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
    case CUSTOM_FIELD_TYPE.DECIMAL:
      return INPUT_TEXT_TYPE.DECIMAL;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
    case CUSTOM_FIELD_TYPE.INTEGER:
      return INPUT_TEXT_TYPE.INTEGER;
    case CUSTOM_FIELD_TYPE.BARCODE_SCANNER:
      return INPUT_TEXT_TYPE.TEXT;
    default:
      return INPUT_TEXT_TYPE.TEXT;
  }
};
