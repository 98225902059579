import { Black, White } from "assets/colors";
import { IStyles } from "model/application/styles";

export const styles: IStyles = {
  root: {
    display: "grid",
    gridRowGap: "16px",
  },
  header: {},
  title: {
    fontWeight: "600",
    fontSize: "24px",
    color: Black,
    display: "flex",
    gridColumnGap: "8px",
  },
  icon: {
    paddingRight: "8px",
  },
  addVisitDialogWrapper: {
    background: White,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    right: 0,
    zIndex: 6,
    overflowY: "scroll",
  },
};
