import { ReactNode } from "react";

import { Box, BoxProps, makeStyles } from "@material-ui/core";

interface INoOverflowColumn extends BoxProps {
  children: ReactNode;
}

const useStyles = makeStyles({
  NoOverflowColumn: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    height: "100%",
  },
});

const NoOverflowColumn = ({ children, ...otherProps }: INoOverflowColumn) => {
  const classes = useStyles();
  return (
    <Box className={classes.NoOverflowColumn} {...otherProps}>
      {children}
    </Box>
  );
};

export default NoOverflowColumn;
