import React from "react";

import InputText from "components/Input/InputText/InputText";

interface ICustomSearchProps {
  onChange: (value: string) => void;
  label?: string;
  fullWidth?: boolean;
}

export const CustomSearch: React.FunctionComponent<ICustomSearchProps> = ({
  onChange,
  label = "Search by Name",
  fullWidth,
}) => {
  /**
   * @param {Object} e Event object
   */
  const handleSearch = (value: string) => {
    if (value != null) {
      onChange(value);
    }
  };

  return (
    <InputText
      name={"search"}
      isSearchField={true}
      placeholder={label}
      label={label}
      onChange={handleSearch}
      fullWidth={fullWidth}
    />
  );
};

export default CustomSearch;
