import IRootState from "redux/store/model";

export const getDocuments = (state: IRootState) => state.documents.allDocuments;

export const getIsFetchingDocuments = (state: IRootState) =>
  state.documents.isFetchingDocuments;
export const getIsUpdatingDocument = (state: IRootState) =>
  state.documents.isUpdatingDocument;

export const getIsUploadingDocuments = (state: IRootState) =>
  state.documents.isUploadingDocuments;

export const getSelectedDocuments = (state: IRootState) =>
  state.documents.subCategorySelected;

export const getSelectedSubCategory = (state: IRootState) =>
  state.documents.subCategorySelected;
