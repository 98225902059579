import { Serie } from "@nivo/line";
import _ from "lodash";

/* -------------------------------------------------------------------------- */
/*      a final passthrough for the data before its sent to a line chart      */
/* -------------------------------------------------------------------------- */

export function processOptimetriksLineChartData(data: Serie[]): Serie[] {
  const clonedData = _.clone(data);

  //this step will correct the id of the data if it is empty
  //nivo charts dont seem to display a line chart if the id is empty
  _.forEach(clonedData, function (value, index) {
    if (_.isEmpty(value.id) && !_.isFinite(value.id)) {
      value.id = `label-${index}`;
    }
  });

  return clonedData;
}
