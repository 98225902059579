import { ReactNode } from "react";

import { Box } from "@material-ui/core";

import { OffWhite } from "assets/colors";

interface IFormSubFieldWrapper {
  children: ReactNode;
}

const FormSubFieldWrapper = ({ children }: IFormSubFieldWrapper) => {
  return (
    <Box
      style={{
        padding: "0px 8px",
        marginBottom: "16px",
        backgroundColor: OffWhite,
        borderRadius: "4px",
      }}
    >
      {children}
    </Box>
  );
};

export default FormSubFieldWrapper;
