import { useEffect } from "react";

import { IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";

import { IOperation } from "model/entities/Workflow";
import { resolveOperations } from "utils/expressions/operationSolver";
import { resolveMetaExpressionInOperation } from "utils/metaExpressions/metaExpressionResolution";

import InputText, {
  ICustomInputTextProps,
  INPUT_TEXT_TYPE,
} from "../InputText/InputText";

interface IInputCompute
  extends Omit<
    ICustomInputTextProps,
    "onChange" | "type" | "defaultValue" | "disabled"
  > {
  operations?: IOperation[];
  metaExpressionOptions: IMetaExpressionOptions;
  onResultChange: (result: number) => void; // function called when the result changes; e.g to update parent state
}

const InputCompute = ({
  operations,
  metaExpressionOptions,
  onResultChange,
  ...props
}: IInputCompute) => {
  const operationsWithoutMEs = _.map(operations, (operation) =>
    resolveMetaExpressionInOperation(operation, metaExpressionOptions)
  );

  const result = resolveOperations(operationsWithoutMEs);

  // The goal of this effect, is to update the parent form's state when the displayed value is updated
  useEffect(() => {
    onResultChange(result);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <InputText
      {...props}
      defaultValue={result}
      type={INPUT_TEXT_TYPE.TEXT}
      onChange={() => {}}
      disabled
    />
  );
};

export default InputCompute;
