import _ from "lodash";

import { IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";

interface IGetCrispSessionData {
  client?: IClient;
  user?: ISignedInUser;
}

export const getCrispSessionData = ({ client, user }: IGetCrispSessionData) => {
  const { isAuthenticated, email } = user || {};
  const { name, language, operations_owner, sales_owner, app_user_licenses } =
    client || {};

  const sessionData = {
    is_authenticated: !!isAuthenticated,
    ...(email && { user_email: email }),
    ...(name && { client_name: name }),
    ...(language && { client_language: language }),
    ...(operations_owner && { operations_owner }),
    ...(sales_owner && { sales_owner }),
    ...(_.isNumber(app_user_licenses) && { app_user_licenses }),
  };

  return sessionData;
};
