import { FormEvent, FunctionComponent } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import InputDate from "components/Input/InputDate/InputDate";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";
import TLang from "model/application/Lang";
import { DEFAULT_DATE_FILTER } from "model/entities/Client";

import { IFilter } from "../../../../model/application/Filter";
import { setDefaultMinDate } from "../../Filter.utils";
import hooks from "./DateFilter.hooks";
import FilterValidateButton from "./FilterValidateButton";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

interface IDateFilterAdditionalParams {
  displayClearOption?: boolean;
  clearOptionLabel?: string;
  displayNeverOption?: boolean;
  neverOptionLabel?: string;
}
export type TDateFilterType = IFilter<
  | { dateType?: DEFAULT_DATE_FILTER; startDate?: Date; endDate?: Date }
  | undefined,
  any,
  IDateFilterAdditionalParams
>;

export interface IDateFilter {
  filter: TDateFilterType;
  onOpenAdditionnalModal: () => void;
  onCloseAdditionnalModal: () => void;
  onChangeFilter: (name: string, value: any) => void;
}

export const DateFilter: FunctionComponent<IDateFilter> = (
  props: IDateFilter
) => {
  const classes = useStyles();

  const selectedClient = useSelector(getSelectedClient);
  const { go_back_days_filter } = selectedClient;

  const minDate = setDefaultMinDate(go_back_days_filter);
  const maxDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30); // set the max date to 1 month in the future

  const {
    onChangeFilter,
    filter,
    onCloseAdditionnalModal,
    onOpenAdditionnalModal,
  } = props;

  const { displayClearOption = false, displayNeverOption = false } =
    filter.additionalParams || {};

  const {
    dateType,
    setDateType,
    endDate,
    startDate,
    setEndDate,
    setSingleDay,
    setStartDate,
    refreshInputDate,
    refreshInputType,
  } = hooks.useChangeDate(filter.value);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const value =
      dateType === DEFAULT_DATE_FILTER.CLEAR
        ? undefined
        : {
            dateType: dateType,
            startDate: startDate,
            endDate: endDate,
          };

    onChangeFilter(filter.tag, value);
  };

  let dateLimit = {};
  if (filter.tag === FILTER_TAG.DATE) {
    dateLimit = {
      minDate,
      maxDate,
    };
  }
  const lang = useTranslations();

  return (
    <div className={classes.DropdownItem}>
      <form onSubmit={handleSubmit}>
        <RadioGroup
          data-testid="dateFilterRadioGroup"
          key={`type${refreshInputType}`}
          aria-label="dateFilterRadioGroup"
          name="dateFilter"
          value={dateType}
          onChange={(event) =>
            setDateType(event.target.value as DEFAULT_DATE_FILTER)
          }
        >
          {(filter.singleSelection
            ? [
                ...(displayClearOption ? [DEFAULT_DATE_FILTER.CLEAR] : []),
                ...(displayNeverOption ? [DEFAULT_DATE_FILTER.NULL] : []),
                DEFAULT_DATE_FILTER.TODAY,
                DEFAULT_DATE_FILTER.YESTERDAY,
              ]
            : [
                ...(displayClearOption ? [DEFAULT_DATE_FILTER.CLEAR] : []),
                ...(displayNeverOption ? [DEFAULT_DATE_FILTER.NULL] : []),
                DEFAULT_DATE_FILTER.TODAY,
                DEFAULT_DATE_FILTER.YESTERDAY,
                DEFAULT_DATE_FILTER.LAST_7_DAYS,
                DEFAULT_DATE_FILTER.THIS_WEEK,
                DEFAULT_DATE_FILTER.LAST_WEEK,
                DEFAULT_DATE_FILTER.LAST_30_DAYS,
                DEFAULT_DATE_FILTER.THIS_MONTH,
                DEFAULT_DATE_FILTER.LAST_MONTH,
              ]
          ).map((opt, index) => {
            return (
              <FormControlLabel
                data-testid={`dateFilterRadio${opt}`}
                key={index}
                label={getDateFilterLabel(opt, lang)}
                control={<Radio className={classes.RadioCustom} />}
                value={opt}
                className={classes.RadioButtonsCustom}
              />
            );
          })}
        </RadioGroup>
        {filter.singleSelection ? (
          <InputDate
            key={`${refreshInputDate}`}
            name={"date"}
            // lang={{ title: "Date" } as TInputAttributeLang}
            onChange={setSingleDay as any}
            onOpen={onOpenAdditionnalModal}
            onClose={onCloseAdditionnalModal}
            closeOnSelect={true}
            defaultValue={startDate}
            {...dateLimit}
          />
        ) : (
          <>
            <div>
              <InputDate
                key={`start${refreshInputDate}`}
                name={"startDate"}
                // lang={{ title: "Start date" } as TInputAttributeLang}
                onChange={setStartDate as any}
                onOpen={onOpenAdditionnalModal}
                onClose={onCloseAdditionnalModal}
                defaultValue={startDate}
                closeOnSelect={true}
                disabled={dateType === DEFAULT_DATE_FILTER.CLEAR}
                {...dateLimit}
              />
            </div>

            <div style={{ marginTop: "-35px" }}>
              <InputDate
                key={`end${refreshInputDate}`}
                name={"endDate"}
                // lang={{ title: "End Date" } as TInputAttributeLang}
                onChange={setEndDate as any}
                onOpen={onOpenAdditionnalModal}
                onClose={onCloseAdditionnalModal}
                defaultValue={endDate}
                closeOnSelect={true}
                disabled={dateType === DEFAULT_DATE_FILTER.CLEAR}
                {...dateLimit}
              />
            </div>
          </>
        )}

        <FilterValidateButton classes={classes} />
      </form>
    </div>
  );
};

export const getDateFilterLabel = (
  dateFilter: DEFAULT_DATE_FILTER,
  rootLang: TLang,
  options?: Pick<
    IDateFilterAdditionalParams,
    "clearOptionLabel" | "neverOptionLabel"
  >
) => {
  const { clearOptionLabel, neverOptionLabel } = options || {};
  if (!rootLang) {
    return "";
  }
  const langKey =
    rootLang.containers.clients.subCategories.clients.createEditModal
      .defaultDateFilter.options;

  switch (dateFilter) {
    case DEFAULT_DATE_FILTER.CLEAR:
      return clearOptionLabel ?? langKey["CLEAR"];
    case DEFAULT_DATE_FILTER.NULL:
      return neverOptionLabel ?? langKey["NULL"];
    default:
      return langKey[dateFilter];
  }
};

export default DateFilter;
