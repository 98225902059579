/**
 * @notes: API Service. This is a wrapper around axios and will make api calls to API. This is convenient as it allows
 * the application to interchange 3rd party libraries or even use a custom one, if the need arises, without need to change
 * all over codebase.
 */
import axios, { CancelTokenSource } from "axios";

import { API_BASE_URL } from "./endpoints";
import {
  prepareQueryWithArrays,
  reComputeError,
  shouldRunInBackground,
} from "./utils";

class ApiService {
  // fields
  cancelToken: CancelTokenSource & { url?: string };
  service: any;
  token: string;
  clientId: string;
  runInBackground: boolean;

  constructor() {
    this.cancelToken = axios.CancelToken.source();
    this.service = axios.create({
      baseURL: API_BASE_URL(),
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE",
      },
      timeout: 180000,
    });
    this.token = "";
    this.clientId = "";
    this.runInBackground = false;
    this.service.interceptors.request.use(this.handleRequestInterceptor);
    this.service.interceptors.response.use(
      this.handleResponseSuccess,
      this.handleResponseError
    );
  }

  setToken(token: string) {
    this.token = token;
  }

  tokenPreview = (token: string) => {
    return (token || "").slice(0, 12) + (token ? "..." : "");
  };

  setClientId(clientId: string) {
    this.clientId = clientId;
  }

  setRunInBackground(runInBackground: boolean) {
    this.runInBackground = runInBackground;
  }

  /**
   * Request interceptor config. This is called before any request is made to API.
   * @param {Object} config
   * @return {Object} config object for axios
   * */
  handleRequestInterceptor = (config: any) => {
    config.headers = {
      Auth: this.token,
    };
    if (this.clientId) {
      config.headers["client-id"] = this.clientId;
      config.headers["local-timestamp"] = new Date().toISOString();
    }
    if (config?.detailedActionName) {
      config.headers["detailed-action-code"] = config?.detailedActionName;
      config.headers["local-timestamp"] = new Date().toISOString();
    }
    if (this.runInBackground) {
      config.headers["run-in-background"] = "true";
      config.headers["local-timestamp"] = new Date().toISOString();
    }
    this.setRunInBackground(false);
    return config;
  };

  /**
   * Handles successful Response. This is used to handle the response accordingly
   * MUST return the response
   * @param {Object} response Response Object from request
   * @return {Object} Response*/
  // Reference https://github.com/axios/axios#response-schema
  handleResponseSuccess = (response: any) => {
    // extract the data block received from Smala API
    // this will contain the data we need for the application
    const { data } = response.data;

    return {
      ...response,
      data,
    };
  };

  /**
   * Handles response errors
   * @param {Object} error Error Object received from response
   * */
  // Reference https://github.com/axios/axios#handling-errors
  handleResponseError = (initialError: any) => {
    const error = reComputeError(initialError);
    const config = error.config;

    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    const backoff = new Promise<void>(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return axios(config);
    });
  };

  redirectTo(path: Location) {
    document.location = path;
  }

  multipartUpdate = (
    endpoint: string,
    files: any[],
    body: any = {},
    detailedActionName = "UNKNOWN"
  ) => {
    const formData = new FormData();
    files.forEach((f: any) => {
      const { file, fileName, value } = f;
      formData.append(fileName, file ?? value, fileName);
    });
    for (const att in body) {
      const attrType = typeof body[att];
      formData.set(
        att,
        attrType === "object" ? JSON.stringify(body[att]) : body[att]
      );
    }
    this.cancelToken = axios.CancelToken.source();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      cancelToken: this.cancelToken.token,
      detailedActionName,
    };
    return this.service.post(endpoint, formData, config);
  };

  /**
   * Perform POST requests to API
   * @param {String} url Url to make POST request
   * @param {Object} payload Payload Object data to POST to api
   * */
  post(
    url: string,
    payload?: any,
    detailedActionName = "UNKNOWN" as string,
    cancelToken?: string,
    runInBackground?: boolean
  ) {
    if (shouldRunInBackground(payload) || runInBackground) {
      this.setRunInBackground(true);
    }
    return this.service.post(url, payload, {
      detailedActionName,
      ...(cancelToken && { cancelToken }),
    });
  }

  /**
   * perform GET request
   * @param {String} url
   * */
  get(
    url: string,
    query = {} as any,
    cancelToken?: string,
    runInBackground?: boolean
  ) {
    if (runInBackground) {
      this.setRunInBackground(true);
    }
    return this.service.get(url, {
      params: query,
      paramsSerializer: (query: any) => prepareQueryWithArrays(query),
      cancelToken,
    });
  }

  /**
   * Patch HTTP verb,handles patch requests
   * @param {String} url
   * @param {Object} payload
   * */
  patch(
    url: string,
    payload?: any,
    detailedActionName = "UNKNOWN" as string,
    cancelToken?: string
  ) {
    if (shouldRunInBackground(payload)) {
      this.setRunInBackground(true);
    }
    return this.service.patch(url, payload, {
      detailedActionName,
      ...(cancelToken && { cancelToken }),
    });
  }

  /**
   * Delete HTTP verb, handles delete requests
   * @param {String} url Url to post the delete method to
   * @param {Object} payload [OPTIONAL] Payload to send with request
   */
  delete(
    url: string,
    payload = {} as any,
    detailedActionName = "UNKNOWN",
    cancelToken?: string
  ) {
    if (shouldRunInBackground(payload)) {
      this.setRunInBackground(true);
    }
    return this.service.delete(url, {
      data: {
        ...payload,
      },
      detailedActionName,
      ...(cancelToken && { cancelToken }),
    });
  }
}

export default new ApiService();
