import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

import styles from "./styles";

export interface ICustomSwitchControlProps {
  isChecked: boolean;
  onSwitch: (value: boolean, name: string) => void;
  onClick?: (event: any) => void;
  name: string;
  classes: any;
  disabled?: boolean;
}

interface ICustomSwitchControlStates {
  isChecked: boolean;
}

export class CustomSwitchControl extends Component<
  ICustomSwitchControlProps,
  ICustomSwitchControlStates
> {
  public static defaultProps = {
    name: "switchCtrl",
    disabled: false,
  };

  constructor(props: ICustomSwitchControlProps) {
    super(props);

    this.state = {
      isChecked: false,
    };
  }

  handleChange =
    (_name: string) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const { onSwitch, name } = this.props;
      this.setState({
        isChecked: checked,
      });

      if (onSwitch) {
        onSwitch(checked, name);
      }
    };

  static getDerivedStateFromProps(
    nextProps: ICustomSwitchControlProps,
    prevState: ICustomSwitchControlStates
  ) {
    const { isChecked } = nextProps;
    if (isChecked === prevState.isChecked) {
      return null;
    } else {
      return {
        isChecked,
      };
    }
  }

  render() {
    const { isChecked } = this.state;
    const {
      classes: { colorSwitchBase, colorChecked, colorBar },
      disabled,
      onClick,
    } = this.props;
    return (
      <Switch
        checked={isChecked}
        onChange={this.handleChange("switchCtrl")}
        onClick={onClick}
        value={"switchCtrl"}
        classes={{
          switchBase: colorSwitchBase,
          checked: colorChecked,
          track: colorBar,
        }}
        disabled={disabled}
      />
    );
  }
}

export default withStyles(styles)(CustomSwitchControl);
