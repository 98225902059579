import { useState } from "react";

import { Tooltip } from "@material-ui/core";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useSelector } from "react-redux";

import { defaultColor } from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";

interface IActivityReportShareLink {
  activityId: string;
  reportId: string;
}

const ActivityReportShareLink = ({
  activityId,
  reportId,
}: IActivityReportShareLink) => {
  const lang = useTranslations();
  const selectedClient = useSelector(getSelectedClient);
  const copyLinkButton =
    lang.containers.workflows.subCategories.activityReports.copyLinkButton;
  const [tooltipText, setTooltipText] = useState(copyLinkButton.tooltip);

  const onShare = async () => {
    navigator.clipboard.writeText(
      `${location.host}/workflows/activities/${activityId}/reports/${reportId}?client_id=${selectedClient.id}`
    );
    setTooltipText(copyLinkButton.confirmationMessage);
    setTimeout(() => {
      setTooltipText(
        lang.containers.workflows.subCategories.activityReports.copyLinkButton
          .tooltip
      );
    }, 3000);
  };

  return (
    <Tooltip title={tooltipText}>
      <span /** do NOT remove me, Tooltip passes down a ref */>
        <SmallIconButton onClick={onShare}>
          <InsertLinkIcon htmlColor={defaultColor} />
        </SmallIconButton>
      </span>
    </Tooltip>
  );
};
export default ActivityReportShareLink;
