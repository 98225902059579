import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import BasicCopyIcon from "components/Dialog/BasicDialog/Icons/BasicCopyIcon";
import { TSnackbarType } from "components/Snackbars/CustomSnackbar";
import { useTranslations } from "hooks";

const useStyles = makeStyles((theme) => ({
  iconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },

  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: theme.spacing(1),

    "&.title": {
      fontWeight: 600,
      fontSize: "16px",
    },
    "&.id": {
      fontSize: "14px",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      width: "100%",
      gap: "5px",
      marginBottom: theme.spacing(2),
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

interface IPictureName {
  name?: string;
  id?: string;
  onCopy?: (message: string, type: TSnackbarType) => void;
}
const PictureName = ({ name, id, onCopy }: IPictureName) => {
  const classes = useStyles();
  const lang = useTranslations();

  const handleCopyText = async () => {
    if (name) {
      try {
        await navigator.clipboard.writeText(name);
        onCopy?.(
          lang.notifications.successNotifications.copyInClipboardSuccess,
          "success"
        );
      } catch (error) {
        onCopy?.(
          lang.notifications.errorNotifications.copyInClipboardError,
          "error"
        );
      }
    }
  };

  return (
    <Box className={classes.root}>
      {name && (
        <Box className={classes.iconBox}>
          <Box className={classNames(classes.text, "title")} data-testid={name}>
            {name}
          </Box>
          {name && onCopy && (
            <BasicCopyIcon text={name} onClick={handleCopyText} />
          )}
        </Box>
      )}

      {id && (
        <Box className={classNames(classes.text, "id")} data-testid={id}>
          <span>
            {
              lang.containers.pictures.subCategories.pictures.createEditModal
                .pictureId.title
            }
            {": "}
          </span>
          {id}
        </Box>
      )}
    </Box>
  );
};

export default PictureName;
