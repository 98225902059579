import _ from "lodash";

import { ITerritory, ITerritoryBE, TTerritoryShape } from "../model";

// TODO 1: use same API schema as for items
// TODO 2: do not stringify _shape in API calls ?
// TODO 3: should be merged with prepareItem(s)ForBackend (or wrap it; but it needs refacto too)
export const formatTerritoryForBE = (
  territory: ITerritory
): ITerritoryBE | null => {
  const territoryToFormat: Partial<ITerritory> = {};

  // Remove keys starting with "_" (why ?)
  _.keys(territory).forEach((att) => {
    if (att.startsWith("_")) {
      // do nothing
    } else {
      territoryToFormat[att] = territory[att];
    }
  });

  return stringifyShape(territoryToFormat as ITerritory);
};

export const stringifyShape = (territory: ITerritory): ITerritoryBE | null => {
  let stringifiedShape: string;
  try {
    stringifiedShape = JSON.stringify(territory.shape);
  } catch {
    console.warn(`Unable to stringify shape for territory ${territory.id}`);
    return null;
  }
  return {
    ...territory,
    shape: stringifiedShape,
  };
};

export const formatTerritoryForFE = (
  territory: ITerritoryBE
): ITerritory | null => {
  let parsedShape: TTerritoryShape; // TODO: check territoryId is present here
  try {
    parsedShape = JSON.parse(territory.shape);
  } catch {
    console.warn(`Unable to parse shape for territory ${territory.id}`);
    return null;
  }
  return {
    ...territory,
    shape: parsedShape,
  };
};
