/**
 * @notes: Auth endpoints to use for authentication
 */
export const LOGIN_ENDPOINT = "web/users/login";
export const SIGNUP_ENDPOINT = "web/users/signup";
export const GOOGLE_LOGIN_ENDPOINT = "web/users/googlelogin";
export const LOGOUT_ENDPOINT = "web/users/logout";
export const RECOVER_PASSWORD_ENDPOINT = "web/users/lostpassword";
export const CHANGE_PASSWORD_ENDPOINT = "web/users/changepassword";
export const CHECK_UPDATE_ENDPOINT = "versions/check-update";
