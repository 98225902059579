import { Component } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";

import { withStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import clsx from "clsx";
import * as H from "history";
import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { isClientStatusExpired } from "containers/authentication/utils/clientStatus";
import * as placeActions from "containers/customers/redux/actions";
import { getSelectedSubCategory as getSelectedSubCategoryPlaces } from "containers/customers/redux/selectors";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import * as documentActions from "containers/documents/redux/actions";
import * as environmentActions from "containers/environments/redux/actions";
import { IFolder } from "containers/environments/SubcategoryManager";
import {
  dashboardFoldersSelector,
  environmentFoldersSelector,
} from "containers/folders/redux/selectors";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import * as teamActions from "containers/teams/redux/actions";
import { getSelectedSubCategory as getSelectedSubCategoryTeams } from "containers/teams/redux/selectors";
import * as userActions from "containers/users/redux/actions/userActions";
import { getSelectedSubCategory as getSelectedSubCategoryUsermgt } from "containers/users/redux/selectors/selectors";
import * as workflowActions from "containers/workflows/redux/actions";
import { workflowsComposedSelector } from "containers/workflows/redux/selectors";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { IClient, IFolder as IAppFolder } from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IWorkflow } from "model/entities/Workflow";
import IRootState from "redux/store/model";

import { getPrivilege } from "../../containers/authentication/redux/selector";
import {
  getSelectedClient,
  levelsSelector,
} from "../../containers/clients/redux/selectors";
import { getSelectedDocuments } from "../../containers/documents/redux/selectors";
import {
  getOnSubCategoryClick,
  getRoutes,
  getSelectedCategory,
  getSubCategories,
} from "../../utils/routes/getRoutes";
import SidebarItem from "./SidebarItem";
import styles from "./styles";

export interface ISidebarCategory {
  name: string;
  id: string;
}

export type TOnSubCategoryClick = (subcategory: string) => void;

export interface ISidebarProps {
  actionsAvailable: any;
  selectedSubCategory: any;
  client: IClient;
  dashboards: IDashboard[];
  dashboardFolders: IAppFolder[];
  environmentFolders: IFolder[];
  lists: IList[];
  workflows: IWorkflow[];
  levels: IOption[];
  userActions: userActions.IUserActions;
  documentActions: documentActions.IDocumentsActions;
  workflowActions: workflowActions.IWorkflowActions;
  teamActions: teamActions.ITeamsActions;
  environmentActions: environmentActions.IEnvironmentActions;
  placeActions: any;
  classes: any;
  disabledSidebar: boolean;
  location: any;
  history: H.History;
  currentRole: string;
  lang: TLang;
  stateSidebar?: (value: boolean) => void;
}

interface ISidebarStates {
  width: number;
  miniSidebar: boolean;
  open: boolean;
  intermediate?: boolean;
  currentId: string | null;
}

/**
 * Sidebar stateless component
 * This will render the component as a drawer on the left side of the screen
 * This gives access to the various dashboard screens through menu items
 */
export class Sidebar extends Component<ISidebarProps, ISidebarStates> {
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      width: window.innerWidth,
      miniSidebar: true,
      open: false,
      intermediate: false,
      currentId: null,
    };

    this.activeRoute.bind(this);
  }
  handleFreezeMenu = () => {
    const { intermediate } = this.state;
    const { stateSidebar } = this.props;
    this.setState({
      open: !intermediate,
      intermediate: !intermediate,
    });
    if (stateSidebar) stateSidebar(!intermediate);
  };

  handleToggle = (index: string) => {
    if (this.state.currentId === index) {
      return true;
    } else {
      this.setState({
        currentId: index,
      });
      return false;
    }
  };
  /**
   * Adds an active class to the route
   * @param {String} routeName the name of the current route being displayed
   * @returns {String} The class name to add to the route in the sidebar component
   * */
  activeRoute(routeName: string) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  lastClickedItem = (itemName: string) => {
    if (itemName === this.state.currentId) {
      return true;
    } else {
      return false;
    }
  };
  buildSidebarItem = (item: any, index: number, isOpen: boolean) => {
    const { disabledSidebar } = this.props;
    return (
      <SidebarItem
        history={this.props.history}
        key={index}
        lastClickedItem={this.lastClickedItem}
        activeRoute={this.activeRoute(item.path)}
        path={item.path}
        icon={item.icon}
        title={item.title}
        changeSubCategoryFunction={item.onSubCategoryClick}
        selectedSubCategory={item.selectedSubCategory}
        subCategories={item.subCategories}
        open={isOpen}
        disabled={disabledSidebar}
        onToggle={this.handleToggle}
      />
    );
  };

  /**
   * Creates navigation links for the sidebar Only create ALL Navigation links if the logged in user
   * is a Super Admin
   * @returns {Array}
   * */
  createNavigationLinks = (lang: TLang) => {
    const {
      client,
      actionsAvailable,
      selectedSubCategory,
      dashboards,
      dashboardFolders,
      environmentFolders,
      lists,
      levels,
      userActions,
      documentActions,
      teamActions,
      placeActions,
      environmentActions,
      currentRole,
      location,
    } = this.props;
    const { open } = this.state;

    let routes = getRoutes({
      actionsAvailable,
      client,
      lang,
    });

    routes = getSelectedCategory(location, selectedSubCategory, routes);

    routes = getOnSubCategoryClick(
      routes,
      userActions,
      documentActions,
      teamActions,
      environmentActions,
      placeActions
    );

    routes = getSubCategories(
      routes,
      lang,
      dashboards,
      levels,
      environmentFolders,
      client,
      lists,
      dashboardFolders,
      currentRole
    );

    return _.map(routes, (route, index) => {
      return this.buildSidebarItem(route, index, open);
    });
  };

  render() {
    const { client, classes, lang } = this.props;
    const { open, intermediate } = this.state;
    return (
      <Drawer
        onMouseEnter={() => {
          if (intermediate === false) {
            this.setState({ open: true });
          }
        }}
        onMouseLeave={() =>
          this.setState({ open: intermediate ? intermediate : false })
        }
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <PerfectScrollbar
          style={{
            marginTop: isClientStatusExpired(client) ? "110px" : "64px",
          }}
        >
          <List style={{ overflow: "hidden" }}>
            {this.createNavigationLinks(lang)}
          </List>
        </PerfectScrollbar>
        <div onClick={this.handleFreezeMenu} className={classes.freezeSidebar}>
          {open ? (
            intermediate ? (
              <div className={classes.wrapIconOpen}>
                <KeyboardArrowLeftIcon
                  className={classes.Icon}
                  style={{ marginRight: "10px" }}
                />
                {lang.genericTerms.collapse}
              </div>
            ) : (
              <div className={classes.wrapIconOpen}>
                <ChevronRightIcon
                  className={classes.Icon}
                  style={{ marginRight: "10px" }}
                />
                {lang.genericTerms.expand}
              </div>
            )
          ) : (
            <div className={classes.wrapIconOpen}>
              <ChevronRightIcon className={classes.Icon} />
            </div>
          )}
        </div>
      </Drawer>
    );
  }
}

/**
 * maps the state of the redux store to the LoginContainer props
 * @param {Object} state of redux store
 * @param {Object} ownProps LoginContainer properties
 * @returns {Object} new state of redux store
 */
function mapStateToProps(state: IRootState) {
  return {
    client: getSelectedClient(state),
    dashboards: dashboardsComposedSelector(state),
    dashboardFolders: dashboardFoldersSelector(state),
    environmentFolders: environmentFoldersSelector(state),
    lists: listsComposedSelector(state),
    workflows: workflowsComposedSelector(state),
    levels: levelsSelector(state),
    currentRole: getPrivilege(state),
    selectedSubCategory: {
      usermgt: getSelectedSubCategoryUsermgt(state),
      teams: getSelectedSubCategoryTeams(state),
      places: getSelectedSubCategoryPlaces(state),
      documents: getSelectedDocuments(state),
    },
  };
}

/**
 * maps dispatch actions to props in this container
 * component
 * @param {Object} dispatch
 * @returns {Object} actions object
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    workflowActions: bindActionCreators(workflowActions as any, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    environmentActions: bindActionCreators(environmentActions as any, dispatch),
    placeActions: bindActionCreators(placeActions, dispatch),
    documentActions: bindActionCreators(documentActions as any, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(Sidebar));
