import { Box, makeStyles } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";

import { Black, GreyDark } from "assets/colors";

const styles = {
  TimelineCardTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: Black,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  launchIcon: {
    fontSize: "18px !important",
    // transition: "transform 0.3s",
    transition: "0.1s",
    cursor: "pointer",
    "&:hover": {
      transform: "translate(1px,-1px)",
    },
  },
};

const useStyles = makeStyles(styles);

interface ITimelineCardTitle {
  title?: string;
  duration?: string;
  onClickIcon?: () => void;
}

const TimelineCardTitle = ({
  title,
  duration,
  onClickIcon,
}: ITimelineCardTitle) => {
  const classes = useStyles();

  return (
    <Box className={classes.TimelineCardTitle}>
      <Box display={"flex"} gridGap={"10px"} alignItems={"center"} flexGrow={1}>
        <span>{title}</span>

        {onClickIcon && (
          <span onClick={onClickIcon} style={{ marginTop: "6px" }}>
            <LaunchIcon className={classes.launchIcon} />
          </span>
        )}
      </Box>

      <Box
        fontSize={"16px"}
        flexGrow={0}
        color={GreyDark}
        fontWeight={"normal"}
      >
        {duration}
      </Box>
    </Box>
  );
};

export default TimelineCardTitle;
