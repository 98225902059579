import React, { useEffect } from "react";

import { useIntersectionObserver } from "hooks/useIntersectionObserver";

interface IBottomProps {
  callback?: () => void;
  threshold?: number;
}
// A component that will fire a callback when it is visible
// Used by being placed at the bottom of a scrollable container to implement infinite scrolling
const ScrollEdgeListener: React.FC<IBottomProps> = ({
  callback,
  threshold,
  children,
}) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: threshold ?? 0.5,
  });

  useEffect(() => {
    if (isIntersecting) {
      if (callback) {
        callback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return (
    <div ref={ref} data-testid="scroll-edge-listener">
      {children}
    </div>
  );
};

export default ScrollEdgeListener;
