/**
 * @notes: User Management Action types
 * There are always 3 types of AJAX actions for, begin, success and failure. For each action that requires a network call
 * we make these 3 actions available.
 */
export { LOGOUT_REQUEST_SUCCESS } from "../../../authentication/redux/actionTypes";
export const CREATE_MOBILE_USERS_BEGIN = "CREATE_MOBILE_USERS_BEGIN";
export const CREATE_MOBILE_USERS_SUCCESS = "CREATE_MOBILE_USERS_SUCCESS";
export const CREATE_MOBILE_USERS_FAILURE = "CREATE_MOBILE_USERS_FAILURE";

export const FETCH_MOBILE_USERS_BY_CLIENT_BEGIN =
  "FETCH_MOBILE_USERS_BY_CLIENT_BEGIN";
export const FETCH_MOBILE_USERS_BY_CLIENT_SUCCESS =
  "FETCH_MOBILE_USERS_BY_CLIENT_SUCCESS";
export const FETCH_MOBILE_USERS_BY_CLIENT_FAILURE =
  "FETCH_MOBILE_USERS_BY_CLIENT_FAILURE";

export const UPDATE_MOBILE_USERS_BEGIN = "UPDATE_MOBILE_USERS_BEGIN";
export const UPDATE_MOBILE_USERS_SUCCESS = "UPDATE_MOBILE_USERS_SUCCESS";
export const UPDATE_MOBILE_USERS_FAILURE = "UPDATE_MOBILE_USERS_FAILURE";

export const DELETE_MOBILE_USER_BEGIN = "DELETE_MOBILE_USER_BEGIN";
export const DELETE_MOBILE_USER_SUCCESS = "DELETE_MOBILE_USER_SUCCESS";
export const DELETE_MOBILE_USER_FAILURE = "DELETE_MOBILE_USER_FAILURE";

export const DELETE_MOBILE_USERS_BEGIN = "DELETE_MOBILE_USERS_BEGIN";
export const DELETE_MOBILE_USERS_SUCCESS = "DELETE_MOBILE_USERS_SUCCESS";
export const DELETE_MOBILE_USERS_FAILURE = "DELETE_MOBILE_USERS_FAILURE";

export const UNLICENSE_MOBILE_USER_BEGIN = "UNLICENSE_MOBILE_USER_BEGIN";
export const UNLICENSE_MOBILE_USER_FAILURE = "UNLICENSE_MOBILE_USER_FAILURE";
export const UNLICENSE_MOBILE_USER_SUCCESS = "UNLICENSE_MOBILE_USER_SUCCESS";

export const LICENSE_MOBILE_USER_BEGIN = "LICENSE_MOBILE_USER_BEGIN";
export const LICENSE_MOBILE_USER_FAILURE = "LICENSE_MOBILE_USER_FAILURE";
export const LICENSE_MOBILE_USER_SUCCESS = "LICENSE_MOBILE_USER_SUCCESS";

export const BULK_LICENSE_MOBILE_USERS_BEGIN =
  "BULK_LICENSE_MOBILE_USERS_BEGIN";
export const BULK_LICENSE_MOBILE_USERS_FAILURE =
  "BULK_LICENSE_MOBILE_USERS_FAILURE";
export const BULK_LICENSE_MOBILE_USERS_SUCCESS =
  "BULK_LICENSE_MOBILE_USERS_SUCCESS";

export const BULK_UNLICENSE_MOBILE_USERS_BEGIN =
  "BULK_UNLICENSE_MOBILE_USERS_BEGIN";
export const BULK_UNLICENSE_MOBILE_USERS_FAILURE =
  "BULK_UNLICENSE_MOBILE_USERS_FAILURE";
export const BULK_UNLICENSE_MOBILE_USERS_SUCCESS =
  "BULK_UNLICENSE_MOBILE_USERS_SUCCESS";

export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const UNLOCK_LOGIN = "UNLOCK_LOGIN";

export const CLEAR_DATA = "CLEAR_DATA";
