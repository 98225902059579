import { IDSource } from "fieldpro-tools";

import { IAction } from "redux/store/model";

import { insertNewItemToArr } from "../../utils/reducerUtils";
import { INotification, IShowNotificationActionCreator } from "./actionCreator";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface INotificationState {
  lastUpdated: Date | null;
  allNotifications: any[];
  recentNotification: INotification | {};
  byLevel: any;
  lastSuccessNotification: number;
}

/**
 * Update Notification by level. This will add this notification to the level it is meant for
 * @param {Object} byLevel byLevel object to update
 * @param {Object} notification Notificaiton object to add to group by level
 * @returns {Object} Object with the byLevel update
 */
const updateNotificationsByLevel = (byLevel: any, notification: any) => {
  // get the notification level to update if available, returns an array
  const levelToUpdate = byLevel[notification.level] || [];
  // update it
  const notificationLevelUpdate = insertNewItemToArr(
    levelToUpdate,
    notification
  );

  return {
    ...byLevel,
    [notification.level]: notificationLevelUpdate,
  };
};

/**
 * appReducer reducer takes current state and action and
 * returns a new state for the application object in the store
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function notificationReducer(
  state = initialState,
  action: IAction
) {
  switch (action.type) {
    case types.NOTIFICATION_SUCCESS:
    case types.NOTIFICATION_ERROR:
    case types.NOTIFICATION_INFO:
    case types.NOTIFICATION_WARN: {
      const { notification } = action as IShowNotificationActionCreator;
      const recentNotification = {
        id: IDSource(),
        timestamp: Date.now(),
        ...(notification || {}),
      };
      return {
        ...state,
        allNotifications: insertNewItemToArr(
          state.allNotifications,
          recentNotification
        ),
        recentNotification,
        byLevel: updateNotificationsByLevel(state.byLevel, notification),
        lastSuccessNotification:
          action.type === types.NOTIFICATION_SUCCESS
            ? Date.now()
            : state.lastSuccessNotification,
      };
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
