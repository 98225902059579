import {
  ASSIGNMENT_RULES,
  CONDITION_OPERATOR,
  IActivity as IBEActivity,
  IActivityReport as IBEActivityReport,
  ICondition as IBECondition,
  IConditionOperand as IBEConditionOperand,
  IMatrix as IBEMatrix,
  IOcrRegex as IBEOcrRegex,
  IOperation as IBEOperation,
  IOperationOperand as IBEOperationOperand,
  IQuestion as IBEQuestion,
  IQuestionnaire as IBEQuestionnaire,
  ISubmission_V2 as IBESubmission,
  IWorkflow as IBEWorkflow,
  IWorkflowStep as IBEWorkflowStep,
  REPORT_PREREQUISITE,
} from "fieldpro-tools/dist/src/types";

import { IOption, ITableRowContent } from "model/application/components";

import { STEP_TYPE } from "./Job";
export {
  COLUMN_TYPE,
  CONDITION_OPERATOR,
  CUSTOM_FIELD_TYPE,
  OPERATION_OPERATOR,
  REPORT_PREREQUISITE,
  STEP_SCOPE,
  STEP_STATUS,
  TASK_TYPE,
} from "fieldpro-tools/dist/src/types";
export type { ITaskDescriptiveText } from "fieldpro-tools/dist/src/types/lists";
export type {
  IChaining,
  IStepOverviewForFrontend as IStepOverview,
  IWorkflowReportWithStepOverviewForFrontend as IWorkflowReportWithStepOverview,
} from "fieldpro-tools/dist/src/types/workflows";

import {
  IStepOverviewForFrontend as IStepOverview,
  STEP_SCOPE,
} from "fieldpro-tools/dist/src/types/workflows";
import { OPERATION_OPERATOR } from "fieldpro-tools/src/types";

export interface IWorkflow extends Omit<IBEWorkflow, "steps"> {
  steps: IWorkflowStep[];
}

export interface IWorkflowStep extends IBEWorkflowStep {
  title?: string;
  description?: string;
  //TODO: remove this after staging merge
  skip_if_conditions_failed?: boolean;
}
export interface IOcrRegex extends IBEOcrRegex {}

export interface IEditingWorkflowPrimary {
  id: string;
  name: string;
  picture_url: string;
  is_submission_editable: boolean;
  edit_submission_offset: number;
  report_title_template: string;
  error: any;
}

export interface IWorkflowItemInCard {
  id: string;
  title: string;
  description?: string;
  scope?: STEP_SCOPE;
  assignment_rules?: ASSIGNMENT_RULES;
  type: STEP_TYPE | "ACTIVITY";
  validity_time?: number;
  is_editable?: boolean;
  report_prerequisite?: REPORT_PREREQUISITE;
}

export interface ISubmission extends IBESubmission {
  [attrname: string]: any;
}
export interface IActivityReport extends IBEActivityReport {
  [attrname: string]: any;
}

export interface IWorkflowVersion {
  id: string;
  created_at: string;
  created_by: string;
}

export interface IWorkflowInTable extends ITableRowContent {
  id: string;
  workflow_id: string;
  active: boolean;
  name: string;
}

export interface ISubmissionInTable extends ITableRowContent {
  _id: string;
  _completed_at: Date;
  [att: string]: any;
}

export interface IActivityReportInTable extends ITableRowContent {
  _id: string;
  _completed_at: Date;
  [att: string]: any;
}

export interface IWorkflowReportInTable extends ITableRowContent {
  status: string;
  created_at: string;
  edited_by: string;
  updated_at: string;
  isWorkflowReport: boolean;
  steps: IStepOverview[];
}

export interface IPredefinedActivity extends IActivity {
  language: string;
  sector: string;
  template_name: string;
  description: string;
}

export interface IPredefinedActivityWithId extends IPredefinedActivity {
  id: string;
}

export interface IOperationOperand extends IBEOperationOperand {
  matrix_question_tag?: string;
}

export interface IConditionOperand extends IBEConditionOperand {}

export interface IOperation extends IBEOperation {
  matrix_question_tag?: string;
  nextOperator?: OPERATION_OPERATOR;
}

export interface ICondition extends IBECondition {
  matrix_question_tag?: string;
  nextOperator?: CONDITION_OPERATOR;
}

export interface IQuestionMatrix extends Omit<IBEMatrix, "typed_questions"> {
  typed_questions: Array<IQuestion>;
}

export interface IQuestion
  extends Omit<
    IBEQuestion,
    "options" | "answer_text" | "matrix" | "conditions" | "index" | "operations"
  > {
  _locked?: boolean;
  _error?: { [key: string]: string };
  _warning?: { [key: string]: string };
  _viewdetail?: boolean;
  options?: Array<IOption>;
  matrix?: IQuestionMatrix;
  matrixQuestion?: string; // for sub-questions
  conditions?: ICondition[];
  operations?: IOperation[];
  answer_text?: string;
  index: number;
  is_duplicable?: boolean;
}

export interface IQuestionnaire extends Omit<IBEQuestionnaire, "questions"> {
  questions: Array<IQuestion>;
}

export interface IEditingWorkflowPrimary {
  id: string;
  name: string;
  picture_url: string;
  is_submission_editable: boolean;
  edit_submission_offset: number;
  report_title_template: string;
  error: any;
}

export interface IActivityInTable extends ITableRowContent {
  id: string;
  active: boolean;
  activity_id: string;
  listnb: number;
  name: string;
  report_count?: number;
}

export interface IActivity extends Omit<IBEActivity, "questionnaire"> {
  questionnaire: IQuestionnaire;
  _error?: any;
  deprecated_questions: IQuestion[];
}
