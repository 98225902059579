import { OffWhite } from "assets/colors";

const styles = {
  CellImageContainer: {
    width: "50px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: OffWhite,
    cursor: "pointer",
    overflow: "hidden",
  },
  CellImage: {
    minWidth: "50px",
    minHeight: "40px",
  },
};

export default styles;
