import { Feature, Polygon } from "geojson";
import _ from "lodash";

import { ITerritory, TTerritoryShape } from "containers/territories/model";

import { getFeatureColor } from "../subcategories/map/utils/styleUtils";

interface IGetFeatures {
  territories: ITerritory[];
}

/**
 * WARNING: this computation can be heavy, do not use in render
 */
const getMapFeatures = ({ territories }: IGetFeatures): TTerritoryShape[] => {
  return _.map(territories, getFeature);
};

export const getFeature = (territory: ITerritory, index = 0) => {
  return {
    ...(removeShapeId(territory.shape) as TTerritoryShape),
    properties: {
      territoryId: territory.id,
      territoryName: territory.name,
      color: getFeatureColor(index),
    },
  };
};

// Mapbox-gl-draw generates string IDs with non-numerical chars, which is problem for mapbox-gl Layers ?
// https://github.com/mapbox/mapbox-gl-draw/issues/787
// https://github.com/mapbox/mapbox-gl-js/issues/2716
export const removeShapeId = (shape?: Feature<Polygon>) => {
  return _.omit(shape, "id");
};

// const getFeatures = ({ territories }: IGetFeatures) => {
//   const features = _.map(territories, (t: any, idx: number) => {
//     const shape = JSON.parse(t.shape);
//     if (_.isArray(shape)) {
//       return null;
//     }
//     if (shape.type === "FeatureCollection") {
//       return _.map(shape.features, (feature: any, idxx: number) => ({
//         id: 10000 * idx + idxx,
//         ...feature,
//       }));
//     }
//     return {
//       id: 10000 * idx,
//       ...shape,
//     };
//   });
//   return _.compact(_.flatten(features));
// };

export default getMapFeatures;
