import _ from "lodash";

import { IOption } from "model/application/components";

/**
 * Returns an array of `IOption` objects based on the provided `listKeys` and `lang` parameters.
 * Each `IOption` object contains a `key`, `label`, and `description` property.
 * The `key` property is set to the current item in the `listKeys` array.
 * The `label` property is set to the value of the corresponding key in the `lang` object.
 * The `description` property is set to the value of the corresponding key with "_description" appended to it in the `lang` object, if it exists.
 * @param {Object} options - An object containing the `listKeys` and `lang` parameters.
 * @param {Array<string>} options.listKeys - An array of strings representing the keys to use for each `IOption` object.
 * @param {Object} options.lang - An object containing the language translations for the `label` and `description` properties.
 * @returns {Array<IOption<any>>} An array of `IOption` objects.
 */
export const getOptionsInputSelect = ({
  listKeys,
  lang,
}: {
  listKeys: string[];
  lang: { [key: string]: string };
}) => {
  const result: IOption<any>[] = _.map(listKeys, (key) => {
    const description = lang?.[`${key}_description`];
    return {
      key,
      // Fallback to key, because this functions is often used with translations from Lokalise
      // and sometimes they are out of sync, but we still want to display something
      label: lang?.[key] ?? key,
      ...(description && { description }),
    };
  });

  return result;
};
