/**
 * @notes: Route configurations. These are the routes used in the application. This will be used in the sidebar.
 * But in general will provide an easier access for what routes are in the application and can thus be used where needed
 */

import _ from "lodash";
import { orderBy as naturalOrderBy } from "natural-orderby";

import {
  ISidebarCategory,
  TOnSubCategoryClick,
} from "components/Sidebar/Sidebar";
import {
  isClientBillingPlan,
  isClientBillingType,
} from "containers/authentication/utils/clientStatus";
import * as placeActions from "containers/customers/redux/actions";
import getDisplayedFolders from "containers/dashboards/utils/getDisplayedFolders";
import * as documentActions from "containers/documents/redux/actions";
import * as environmentActions from "containers/environments/redux/actions";
import { IFolder } from "containers/environments/SubcategoryManager";
import * as teamActions from "containers/teams/redux/actions";
import * as userActions from "containers/users/redux/actions/userActions";
import {
  ACTION_TYPE,
  OBJECT_CATEGORY,
  OPTIMETRIKS_ROLES,
} from "model/application/ActionCode";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import {
  CALENDAR_ROUTE,
  CLIENTS_ROUTE,
  DASHBOARDS_ROUTE,
  DOCUMENTS_ROUTE,
  ENVIRONMENT_ROUTE,
  ERP_ROUTE,
  HOME_ROUTE,
  LISTS_ROUTE,
  PANORAMA_ROUTE,
  PICTURES_ROUTE,
  PLACES_ROUTE,
  TEAMS_ROUTE,
  USERS_ROUTE,
  WORKFLOWS_ROUTE,
} from "model/constants/routes";
import {
  BILLING_TYPE,
  IClient,
  IFolder as IAppFolder,
} from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";
import { IList } from "model/entities/List";

import {
  getDashboardsSubcategory,
  getListsSubcategory,
  getWorkflowsSubcategory,
} from "./getSubcategory";

interface IRoute {
  path: string;
  title: string;
  icon: string;
  subCategorySelected?: string;
  subCategories?: ISidebarCategory[];
  onSubCategoryClick?: TOnSubCategoryClick;
}

interface IGetRoutes {
  actionsAvailable: object;
  client: IClient;
  lang: TLang;
}
interface ISelectedSubCategory {
  usermgt: string;
  teams: string;
  places: string;
  documents: string;
  environment: string;
}

export const getRoutes = ({ actionsAvailable, client, lang }: IGetRoutes) => {
  const routes: IRoute[] = [];
  if (!actionsAvailable) {
    return routes;
  }

  routes.push({
    path: HOME_ROUTE,
    title: lang.mainLayout.sidebar.home,
    icon: "home",
  });

  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DASHBOARD}`]) {
    routes.push({
      path: DASHBOARDS_ROUTE,
      title: lang.mainLayout.sidebar.dashboards,
      icon: "dashboard",
    });
  }

  if (
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.MOB_USER}`] ||
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WEB_USER}`]
  ) {
    routes.push({
      path: USERS_ROUTE,
      title: lang.mainLayout.sidebar.users,
      icon: "person",
    });
  }

  if (
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.TEAM}`] &&
    !(
      isClientBillingType(client, BILLING_TYPE.AUTOMATED) &&
      isClientBillingPlan(client, "starter")
    )
  ) {
    routes.push({
      path: TEAMS_ROUTE,
      title: lang.mainLayout.sidebar.teams,
      icon: "group_work",
    });
  }

  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WORKFLOW}`]) {
    routes.push({
      path: WORKFLOWS_ROUTE,
      title: lang.mainLayout.sidebar.workflows,
      icon: "work",
    });
  }

  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.LIST}`]) {
    routes.push({
      path: LISTS_ROUTE,
      title: lang.mainLayout.sidebar.lists,
      icon: "list",
    });

    if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DASHBOARD}`]) {
      routes.push({
        path: PICTURES_ROUTE,
        title: lang.mainLayout.sidebar.pictures,
        icon: "photo_camera",
      });
    }
  }

  if (client.enable_visit_planning) {
    routes.push({
      path: CALENDAR_ROUTE,
      title: lang.containers.calendar.subCategories.calendar.subject,
      icon: "calendar",
    });
  }

  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.LIST}`]) {
    routes.push({
      path: PLACES_ROUTE,
      title: lang.mainLayout.sidebar.map,
      icon: "place",
    });
  }
  if (
    // TODO: add Order permissions to default roles and allow them to be edited from the web like other sections
    // actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.ORDER}`] && // TODO: where can we st this ?
    client.is_fieldpro_connect_enabled
  ) {
    routes.push({
      path: ERP_ROUTE,
      title: lang.mainLayout.sidebar.erp,
      icon: "erp",
    });

    client.erp_config?.type === "PANORAMA" &&
      routes.push({
        path: PANORAMA_ROUTE,
        title: lang.mainLayout.sidebar.panorama,
        icon: "panorama",
      });
  }

  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DOCUMENT}`]) {
    routes.push({
      path: DOCUMENTS_ROUTE,
      title: lang.mainLayout.sidebar.documents,
      icon: "assignment",
    });
  }

  if (
    isOptimetriksAdminRole(client?.profile) &&
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DASHBOARD}`] &&
    !isClientBillingPlan(client, "starter")
  ) {
    routes.push({
      path: ENVIRONMENT_ROUTE,
      title: lang.mainLayout.sidebar.environment,
      icon: "environment",
    });
  }

  if (actionsAvailable[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.CLIENT}`]) {
    routes.push({
      path: CLIENTS_ROUTE,
      title: lang.mainLayout.sidebar.clients,
      icon: "supervisor_account",
    });
  }

  return routes;
};

export const getSelectedCategory = (
  location: Location,
  selectedSubCategory: ISelectedSubCategory,
  routes: IRoute[]
): IRoute[] => {
  const newRoutes = _.map(routes, (route) => {
    switch (route.icon) {
      case "home":
      case "supervisor_account":
      case "erp":
      case "panorama":
      case "photo_camera":
        return {
          ...route,
          subCategorySelected: "index",
        };
      case "dashboard":
        return {
          ...route,
          subCategorySelected: getDashboardsSubcategory(location?.pathname),
        };
      case "calendar":
        return {
          ...route,
          subCategorySelected: "index",
        };
      case "person":
        return {
          ...route,
          subCategorySelected: selectedSubCategory.usermgt,
        };
      case "group_work":
        return {
          ...route,
          subCategorySelected: selectedSubCategory.teams,
        };
      case "work":
        return {
          ...route,
          subCategorySelected: getWorkflowsSubcategory(location?.pathname),
        };
      case "list":
        return {
          ...route,
          subCategorySelected: getListsSubcategory(location?.pathname),
        };
      case "place":
        return {
          ...route,
          subCategorySelected: selectedSubCategory.places,
        };
      case "assignment":
        return {
          ...route,
          subCategorySelected: selectedSubCategory.documents,
        };
      case "environment":
        return {
          ...route,
          subCategorySelected: selectedSubCategory.environment,
        };
      default:
        return route;
    }
  });

  return newRoutes;
};

export const getSubCategories = (
  routes: IRoute[],
  lang: TLang,
  dashboards: IDashboard[],
  levels: IOption[],
  environmentFolders: IFolder[],
  client: IClient,
  lists: IList[],
  dashboardFolders: IAppFolder[],
  currentRole: string
): IRoute[] => {
  const folderIds = _.uniq(
    dashboards
      .filter((d) => d.active)
      .filter((d) => ["WEB", "MOBILE_AND_WEB"].includes(d.type))
      .map((d) => d.folder || "default")
  );
  const fullFolders = _.filter(
    dashboardFolders,
    (f) => folderIds.includes(f.id) || folderIds.includes(f.name)
  );

  const displayedFolders = getDisplayedFolders({
    folders: fullFolders,
    client,
  });

  const sortedFullFolders = naturalOrderBy(displayedFolders, "name", "asc");
  let assignmentSubCategories;
  const subCategoriesWork: ISidebarCategory[] = [
    {
      name: lang.mainLayout.sidebar.summary,
      id: "index",
    },
    {
      name: lang.mainLayout.sidebar.activities,
      id: "activities",
    },
  ];

  const updatedRoutes = routes.map((route) => {
    switch (route.icon) {
      case "home": {
        return {
          ...route,
          subCategories: [],
        };
      }
      case "dashboard": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.summary,
              id: "index",
            },
            ...sortedFullFolders
              .filter((f) => f.active)
              .map((f) => ({ name: f.name, id: f.id })),
          ],
        };
      }
      case "person": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.mobileUsers,
              id: "mobileuser",
            },
            {
              name: lang.mainLayout.sidebar.webUsers,
              id: "webuser",
            },
          ],
        };
      }
      case "group_work": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.teams,
              id: "team",
            },
            ...levels.slice(1).map((l) => ({ name: l.label, id: l.key })),
          ],
        };
      }
      case "work": {
        if (!isClientBillingPlan(client, "starter")) {
          subCategoriesWork.push(
            {
              name: lang.mainLayout.sidebar.jobs,
              id: "jobs",
            },
            {
              name: lang.mainLayout.sidebar.triggers,
              id: "triggers",
            },
            {
              name: lang.mainLayout.sidebar.webhooks,
              id: "webhooks",
            }
          );
        }
        return {
          ...route,
          subCategories: subCategoriesWork,
        };
      }
      case "list": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.summary,
              id: "index",
            },
            ...lists
              .filter((l) => l.active && l.id !== "territories")
              .map((l) => ({ name: l.name, id: l.id })),
          ],
        };
      }
      case "calendar": {
        return {
          ...route,
          subCategories: [],
        };
      }
      case "place": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.map,
              id: "map",
            },
          ],
        };
      }
      case "erp": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.orders,
              id: "orders",
            },
            {
              name: "Stock",
              id: "stock",
            },
            {
              name: "Prices",
              id: "prices",
            },
            {
              name: "Discounts",
              id: "discounts",
            },
          ],
        };
      }
      case "panorama": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.orders,
              id: "orders",
            },
            // Temporary sub-menus for a demo
            {
              name: "Customers",
              id: "customers",
            },
            {
              name: "Customer Groups",
              id: "customer_groups",
            },
            {
              name: "Cust. Balances",
              id: "balances",
            },
            {
              name: "Products",
              id: "products",
            },
            {
              name: "Product Groups",
              id: "product_groups",
            },
            {
              name: "Prices",
              id: "prices",
            },
            {
              name: "Discounts",
              id: "discounts",
            },
            {
              name: "Order Lines",
              id: "lines",
            },
            {
              name: "Stock",
              id: "stock",
            },
            {
              name: "S.R.E.",
              id: "recommendations",
            },
            {
              name: "Distributors",
              id: "distributors",
            },
            {
              name: "Warehouses",
              id: "warehouses",
            },
          ],
        };
      }
      case "assignment": {
        assignmentSubCategories = [
          {
            name: lang.mainLayout.sidebar.clients,
            id: "index",
          },
          {
            name: "Optimetriks",
            id: "optimetriks",
          },
        ];
        return {
          ...route,
          subCategories: [
            OPTIMETRIKS_ROLES.SUPERADMIN as string,
            OPTIMETRIKS_ROLES.ADMIN as string,
          ].includes(currentRole)
            ? assignmentSubCategories
            : [{ name: lang.mainLayout.sidebar.clients, id: "index" }],
        };
      }
      case "environment": {
        return {
          ...route,
          subCategories: environmentFolders,
        };
      }
      case "supervisor_account":
      case "photo_camera": {
        return {
          ...route,
          subCategories: [
            {
              name: lang.mainLayout.sidebar.summary,
              id: "index",
            },
          ],
        };
      }
      default: {
        return { ...route, subCategories: [] };
      }
    }
  });

  return updatedRoutes;
};

type PlaceActionsType = typeof placeActions;

export const getOnSubCategoryClick = (
  routes: IRoute[],
  userActions: userActions.IUserActions,
  documentActions: documentActions.IDocumentsActions,
  teamActions: teamActions.ITeamsActions,
  environmentActions: environmentActions.IEnvironmentActions,
  placeActions: PlaceActionsType
): IRoute[] => {
  const updatedRoutes = routes.map((route) => {
    switch (route.icon) {
      case "home":
      case "dashboard":
      case "work":
      case "list":
      case "panorama":
      case "photo_camera":
      case "supervisor_account":
      case "erp":
      case "calendar":
        return {
          ...route,
          onSubCategoryClick: () => {},
        };

      case "person":
        return {
          ...route,
          onSubCategoryClick:
            userActions.changeSubcategoryAction as TOnSubCategoryClick,
        };
      case "group_work":
        return {
          ...route,
          onSubCategoryClick: teamActions.changeSubcategoryAction,
        };
      case "place":
        return {
          ...route,
          onSubCategoryClick: placeActions.changeSubcategoryAction,
        };
      case "assignment":
        return {
          ...route,
          onSubCategoryClick: documentActions.changeSubcategoryAction,
        };
      case "environment":
        return {
          ...route,
          onSubCategoryClick: environmentActions.changeSubcategoryAction,
        };
      default:
        return route;
    }
  });

  return updatedRoutes;
};
