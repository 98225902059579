import { useState } from "react";

import { Button, Fade, makeStyles, Toolbar } from "@material-ui/core";
import { useSelector } from "react-redux";

import { cancelledText, waitingText, White } from "assets/colors";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import { SUPPORT_ADRESSE_EMAIL } from "containers/authentication/utils/constants";
import { computePercentage } from "containers/clients/components/ClientForm/Tabs/UsageTab.utils";
import { getIsFetchingClient } from "containers/clients/redux/selectors";
import { useTranslations } from "hooks";

const useStyle = makeStyles({
  toolbar: {
    padding: "0",
    margin: "0",
    width: "100%",
    display: "flex",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    columnGap: "24px",
    background: (props: { color: string | undefined }) => props.color,
    color: White,
    height: "50px",
    minHeight: "50px",
  },
  storageButton: {
    background: `${White} !important`,
    color: (props: { color: string | undefined }) => props.color,
    borderRadius: "30px",
    height: "32px",
    minHeight: "32px",
    padding: "5px 10px",
  },
});

interface IStorageBanner {
  capacity_quota: number;
  capacity_used: number;
}

export const STORAGE_QUOTA_LIMIT = 100;

const getColorValue = (percent: number) => {
  const nearQuotaLimit = 0.8 * STORAGE_QUOTA_LIMIT;
  if (percent >= STORAGE_QUOTA_LIMIT) {
    return cancelledText;
  }

  if (nearQuotaLimit <= percent && percent < STORAGE_QUOTA_LIMIT) {
    return waitingText;
  }

  return undefined;
};

const StorageBanner = ({ capacity_used, capacity_quota }: IStorageBanner) => {
  const lang = useTranslations();
  const isFetchingClient = useSelector(getIsFetchingClient);
  const [open, setOpen] = useState(false);
  const percent = computePercentage(capacity_used, capacity_quota);
  const nearQuotaLimit = 0.8 * STORAGE_QUOTA_LIMIT;

  const color = getColorValue(percent);
  const classes = useStyle({
    color,
  });

  const currentStorageLang =
    lang.containers.clients.subCategories.clients.createEditModal.storage;
  const storageWarningMessageLang = currentStorageLang?.warningMessage;

  const getBannerTitle = () => {
    if (percent >= STORAGE_QUOTA_LIMIT) {
      return currentStorageLang?.createEditModal.storageAbove80perscent;
    } else if (percent >= nearQuotaLimit && percent < STORAGE_QUOTA_LIMIT) {
      return currentStorageLang?.createEditModal.storageSoon80perscent;
    } else {
      return undefined;
    }
  };

  if (percent < nearQuotaLimit || isFetchingClient) {
    return null;
  }

  return (
    <Fade in>
      <Toolbar className={classes.toolbar} data-testid="client-storage-banner">
        <span>{getBannerTitle()}</span>
        <Button className={classes.storageButton} onClick={() => setOpen(true)}>
          {currentStorageLang?.createEditModal.storageButton}
        </Button>

        <CustomDialogWarning
          isOpen={open}
          onClose={() => setOpen(false)}
          rootLang={lang}
          lang={{
            title: storageWarningMessageLang?.title,
          }}
        >
          <div style={{ marginTop: "-20px", zIndex: 1000 }}>
            <span>{storageWarningMessageLang?.description}</span>{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {SUPPORT_ADRESSE_EMAIL}
            </span>
          </div>
        </CustomDialogWarning>
      </Toolbar>
    </Fade>
  );
};

export default StorageBanner;
