import { IKPI } from "fieldpro-tools";

import { INivoConfiguration } from "model/entities/Dashboard";

import Chart from "../Chart";
import { ChartDataUtils } from "../ChartDataUtils";

export const HEATMAP_LABEL_HEIGHT = 50;
export const HEATMAP_LABEL_WIDTH = 50;

export const HEATMAP_LEGEND_WIDTH = 150;

export function getMinHeatmapDimensions(
  chart: IKPI,
  nivoConfiguration: INivoConfiguration,
  parentWidth: number
) {
  const columnCount = Math.max(ChartDataUtils.countXValues(chart), 1);
  const rowCount = Math.max(ChartDataUtils.countYValues(chart), 1);

  const labelHeight = HEATMAP_LABEL_HEIGHT;
  const labelWidth = HEATMAP_LABEL_WIDTH;

  const margins = Chart.settings.HEATMAP_CHART.margin;

  const height = labelHeight * rowCount + margins.top + margins.bottom;
  let width = labelWidth * columnCount + margins.right + margins.left;

  // If there are only a few columns, stretch it to full width
  // Otherwise, keep width deduced from the number of columns times the width of a cell
  if (nivoConfiguration?.fullWidth) {
    width = Math.max(parentWidth - (margins.right + margins.left), width);
  }

  return {
    height,
    width,
  };
}
