import { getOverlappingEvent, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";

import { formatString } from "lang/utils";
import TLang from "model/application/Lang";
import { isValidDateString } from "utils/dateUtils";

export function checkEventForErrors(
  newEvent: TCalendarEvent,
  events: TCalendarEvent[],
  line: number,
  lang: TLang
) {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit.customErrorMessages;

  const errors = {
    VISIT_CUSTOMER_ID_REQUIRED: () =>
      checkCustomerIdRequired(newEvent, inputVisitLang),
    START_TIME_REQUIRED: () => checkStartTimeRequired(newEvent, inputVisitLang),
    END_TIME_REQUIRED: () => checkEndTimeRequired(newEvent, inputVisitLang),
    OVERLAPPING_EVENT: () =>
      checkEvenIsOverlapping(newEvent, events, inputVisitLang),
    EMPTY_REQUIRED_KEYS: () =>
      checkForEmptyRequiredKeys(newEvent, inputVisitLang),
  };

  for (const [, check] of Object.entries(errors)) {
    const error = check();
    if (error) return formatString(inputVisitLang.errorInLine, [line, error]);
  }

  return null;
}

/* ----------------------------- ERROR FUNCTIONS ---------------------------- */
function checkCustomerIdRequired(
  newEvent: TCalendarEvent,
  inputVisitLang: any
): string | null {
  return _.isEmpty(newEvent.customer_id)
    ? inputVisitLang.customerIdRequired
    : null;
}

function checkStartTimeRequired(
  newEvent: TCalendarEvent,
  inputVisitLang: any
): string | null {
  return !isValidDateString(newEvent.start_time)
    ? inputVisitLang.startTimeRequired
    : null;
}

function checkEndTimeRequired(
  newEvent: TCalendarEvent,
  inputVisitLang: any
): string | null {
  return !isValidDateString(newEvent.end_time)
    ? inputVisitLang.endTimeRequired
    : null;
}
function checkEvenIsOverlapping(
  newEvent: TCalendarEvent,
  events: TCalendarEvent[],
  inputVisitLang: any
): string | null {
  if (
    !checkStartTimeRequired(newEvent, inputVisitLang) &&
    !checkEndTimeRequired(newEvent, inputVisitLang)
  ) {
    newEvent.start_time = moment(newEvent.start_time).toDate();
    newEvent.end_time = moment(newEvent.end_time).toDate();
    const overlappingEvent = getOverlappingEvent(
      _.filter(events, {
        assigned_to: newEvent.assigned_to,
      }),
      newEvent
    );
    if (overlappingEvent) {
      const eventIndex = events.indexOf(overlappingEvent);
      return formatString(inputVisitLang.eventOverlapError, [eventIndex + 2]);
    }
  }

  return null;
}

function checkForEmptyRequiredKeys(
  newEvent: TCalendarEvent,
  inputVisitLang: any
): string | null {
  const requiredKeys: Array<keyof TCalendarEvent> = [
    "start_time",
    "end_time",
    "assigned_to",
  ];
  const emptyKeys = _.filter(requiredKeys, (key) => {
    return newEvent[key] === "" || undefined;
  });
  if (!_.isEmpty(emptyKeys)) {
    return formatString(inputVisitLang.requiredField, [
      _.join(emptyKeys, ", "),
    ]);
  }
  return null;
}
