import {
  ICustomersMapKpiData,
  TKpiMapLegend,
  TKpiMapLegendType,
} from "fieldpro-tools";
import _ from "lodash";

import { TCustomerFeature } from "containers/customers/subcategories/map/CustomersMap/features/customers";
import { IKPI } from "model/entities/Dashboard";

import { getGaugeColor } from "./getGaugeColors";

export function getCustomerFeaturesFromChart(
  chart: IKPI<Array<ICustomersMapKpiData>>,
  legend?: TKpiMapLegend
): Array<TCustomerFeature> {
  const tag = legend?.tag || "";
  let numbers: Array<number> = [];
  let minValue: number = 0;
  let maxValue: number = 0;
  let color = "#0000";

  if (chart.legend?.type === TKpiMapLegendType.GRADIENT) {
    numbers = _.compact(_.map(chart.data, (d) => d[tag]));
    minValue = _.min(numbers) || 0;
    maxValue = _.max(numbers) || 0;
  }

  const features = _.map(chart.data, (data): TCustomerFeature => {
    if (legend?.type === TKpiMapLegendType.GRADIENT) {
      color = getGaugeColor(data[tag], minValue, maxValue);
    }
    if (legend?.type === TKpiMapLegendType.CATEGORIES) {
      const value = _.find(legend.values, { label: data[tag] });
      color = value?.color || color;
    }

    return {
      geometry: {
        coordinates: [data._location__lng, data._location__lat],
        type: "Point",
      },
      properties: {
        color: color,
        customerId: data._id,
      },
      type: "Feature",
    };
  });
  return features;
}
