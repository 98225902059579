import { Chip, ChipProps, makeStyles } from "@material-ui/core";

import * as colors from "assets/colors";

interface IDefaultChip extends ChipProps {
  highlight?: boolean;
}

const useStyles = makeStyles({
  // Configure Chip colors at the theme level (?)
  DefaultChip: (props: IDefaultChip) => ({
    ...(props.draggable && {
      cursor: "grab", // for draggable chips
    }),
    borderRadius: "4px",
    fontWeight: 500,
    fontSize: props.size === "small" ? "12px" : "16px",
    lineHeight: props.size === "small" ? "12px" : "20px",
    color: colors.Black,
    backgroundColor: colors.TealLight,
    "&.MuiChip-clickable:hover": {
      backgroundColor: colors.Teal,
    },
    ".MuiChip-deletable:focus": {
      backgroundColor: colors.Teal,
    },
    ...(props.highlight && {
      backgroundColor: `${colors.teal} !important`,
      color: colors.White,
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
  }),
});

const DefaultChip = (props: IDefaultChip) => {
  const classes = useStyles(props);
  return (
    <Chip
      className={classes.DefaultChip}
      clickable={!!props.onClick}
      title={props.title}
      {...props}
    />
  );
};

export default DefaultChip;
