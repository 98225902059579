import { OPTIMETRIKS_ROLES } from "model/application/ActionCode";
import { IWebUser } from "model/entities/User";
import { IAction, IActionError } from "redux/store/model";

import {
  convertArrayToObject,
  insertNewItemToArr,
  removeObjectFromArray,
  removeObjectFromHash,
  updateObjectInArray,
} from "../../../../utils/reducerUtils";
import {
  ICreateWebUsersActionSuccess,
  IDeleteWebUserActionSuccess,
  IDeleteWebUsersActionSuccess,
  IFetchWebUsersActionSuccess,
  IUpdateWebUserActionSuccess,
} from "../actionCreator/webUserActionCreator";
import * as types from "../actionTypes/webUserActionTypes";
import initialState from "./initialState";

export interface IWebUsersState {
  fetchingAllUsers: boolean;
  fetchingUser: boolean;
  creatingUsers: boolean;
  updatingUser: boolean;
  uploadingUserProfile: boolean;
  deletingUser: boolean;
  deletingUsers: boolean;
  lastUpdated: Date;
  user: IWebUser;
  didInvalidate: boolean;
  allUsers: IWebUser[];
  byId: { [id: string]: IWebUser };
  byEmail: {};
  errors: any[];
}

// TODO: use updateObjectInArray utility function for error actions to update the error actions instead of appending new ones
/**
 * webUsersReducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function webUsersReducer(
  state = initialState.webUsers,
  action: IAction
) {
  switch (action.type) {
    case types.CREATE_WEB_USERS_BEGIN:
      return Object.assign({}, state, {
        creatingUsers: true,
      });

    case types.CREATE_WEB_USERS_FAILURE: {
      const { type, error } = action as IActionError;
      return Object.assign({}, state, {
        creatingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...error,
          actionType: type,
        }),
      });
    }

    case types.CREATE_WEB_USERS_SUCCESS: {
      const { users } = action as ICreateWebUsersActionSuccess;

      let allUsers = state.allUsers;
      let byId = state.byId;
      (users as IWebUser[]).forEach((user) => {
        if (!byId[user.id]) {
          allUsers = insertNewItemToArr(allUsers, user);
          byId = Object.assign({}, byId, {
            [user.id]: {
              ...user,
            },
          });
        }
      });

      return {
        ...state,
        creatingUsers: false,
        allUsers,
        byId,
      };
    }

    case types.FETCH_WEB_USERS_BY_CLIENT_BEGIN:
      return {
        ...state,
        fetchingAllUsers: true,
        fetchingUser: false,
        creatingUser: false,
        updatingUser: false,
        deletingUser: false,
        uploadingUserProfile: false,
      };

    case types.FETCH_WEB_USERS_BY_CLIENT_FAILURE:
      return {
        ...state,
        fetchingAllUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.FETCH_WEB_USERS_BY_CLIENT_SUCCESS: {
      const { currentRight } = action as IFetchWebUsersActionSuccess;
      let { users } = action as IFetchWebUsersActionSuccess;
      // if the current user is not an optimetriks user, hide all the OPTIMETRIKS_ROLES.ADMIN users
      if (
        currentRight !== OPTIMETRIKS_ROLES.ADMIN &&
        currentRight !== OPTIMETRIKS_ROLES.SUPERADMIN
      ) {
        users = (users as IWebUser[]).filter(
          (u) => u.role !== OPTIMETRIKS_ROLES.ADMIN
        );
      }
      return {
        ...state,
        fetchingAllUsers: false,
        allUsers: users,
        byId: convertArrayToObject(state.byId, users, "id"),
      };
    }

    case types.UPDATE_WEB_USER_BEGIN:
      return {
        ...state,
        updatingUser: true,
      };

    case types.UPDATE_WEB_USER_SUCCESS: {
      const { users } = action as IUpdateWebUserActionSuccess;
      const byId = state.byId;
      let allUsers = state.allUsers;
      users.forEach((u) => {
        byId[u.id] = u;
        allUsers = allUsers.map((uu) => {
          if (uu.id === u.id) {
            return u;
          }
          return uu;
        });
        updateObjectInArray(allUsers, u);
      });

      return {
        ...state,
        updatingUser: false,
        allUsers,
        byId,
      };
    }

    case types.UPDATE_WEB_USER_FAILURE:
      return {
        ...state,
        updatingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_WEB_USER_BEGIN:
      return {
        ...state,
        deletingUser: true,
      };

    case types.DELETE_WEB_USER_SUCCESS: {
      const { id } = action as IDeleteWebUserActionSuccess;
      return {
        ...state,
        deletingUser: false,
        allUsers: removeObjectFromArray(state.allUsers, id),
        byId: removeObjectFromHash(state.byId, id),
      };
    }

    case types.DELETE_WEB_USER_FAILURE:
      return {
        ...state,
        deletingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_WEB_USERS_BEGIN:
      return {
        ...state,
        deletingUsers: true,
      };

    case types.DELETE_WEB_USERS_SUCCESS: {
      const { ids } = action as IDeleteWebUsersActionSuccess;
      const allUsers = state.allUsers.filter((u) => !ids.includes(u.id));
      return {
        ...state,
        deletingUsers: false,
        allUsers,
      };
    }

    case types.DELETE_WEB_USERS_FAILURE:
      return {
        ...state,
        deletingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    case types.UPLOAD_FILE_BEGIN: {
      return {
        ...state,
        uploadingUserProfile: true,
      };
    }
    case types.UPLOAD_FILE_FAILURE: {
      return {
        ...state,
        uploadingUserProfile: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    }
    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        uploadingUserProfile: false,
      };
    }
    case types.CLEAR_DATA: {
      return initialState.webUsers;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState.webUsers,
      };

    default:
      return state;
  }
}
