import { Box, makeStyles } from "@material-ui/core";
import { ICustomersMapKpiData, TKpiMapLegend } from "fieldpro-tools";
import _ from "lodash";

import { IKPI } from "model/entities/Dashboard";

import CustomersMapChartBaseMap from "./CustomersMapChartBaseMap";
import LegendContainer from "./LegendContainer";
import { getCustomerFeaturesFromChart } from "./utils/getCustomerFeaturesFromChart";

export interface ICustomersMapChartProps {
  chart: IKPI<Array<ICustomersMapKpiData>>;
  legend?: TKpiMapLegend;
}
const useStyles = makeStyles({
  wrapper: {
    position: "absolute",
    bottom: 10,
    right: "50%",
    backgroundColor: "white",
    transform: "translateX(50%)",
    padding: "6px 16px 0px 16px",
  },
});

function CustomersMapChart({ chart, legend }: ICustomersMapChartProps) {
  const customerFeatures = getCustomerFeaturesFromChart(chart, legend);
  const classes = useStyles();

  return (
    <CustomersMapChartBaseMap
      key={_.size(customerFeatures)}
      customerFeatures={customerFeatures}
    >
      <Box className={classes.wrapper}>
        <LegendContainer legend={legend} />
      </Box>
    </CustomersMapChartBaseMap>
  );
}

export default CustomersMapChart;
