/**
 * @notes: Integration action types
 */
export const CREATE_INTEGRATION_BEGIN = "CREATE_INTEGRATION_BEGIN";
export const CREATE_INTEGRATION_SUCCESS = "CREATE_INTEGRATION_SUCCESS";
export const CREATE_INTEGRATION_FAILURE = "CREATE_INTEGRATION_FAILURE";

export const UPDATE_INTEGRATION_BEGIN = "UPDATE_INTEGRATION_BEGIN";
export const UPDATE_INTEGRATION_SUCCESS = "UPDATE_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_FAILURE = "UPDATE_INTEGRATION_FAILURE";

export const FETCH_INTEGRATION_HISTORY_BEGIN =
  "FETCH_INTEGRATION_HISTORY_BEGIN";
export const FETCH_INTEGRATION_HISTORY_SUCCESS =
  "FETCH_INTEGRATION_HISTORY_SUCCESS";
export const FETCH_INTEGRATION_HISTORY_FAILURE =
  "FETCH_INTEGRATION_HISTORY_FAILURE";

export const FETCH_ALL_INTEGRATIONS_BEGIN = "FETCH_ALL_INTEGRATIONS_BEGIN";
export const FETCH_ALL_INTEGRATIONS_SUCCESS = "FETCH_ALL_INTEGRATIONS_SUCCESS";
export const FETCH_ALL_INTEGRATIONS_FAILURE = "FETCH_ALL_INTEGRATIONS_FAILURE";

export const DELETE_INTEGRATION_BEGIN = "DELETE_INTEGRATION_BEGIN";
export const DELETE_INTEGRATION_SUCCESS = "DELETE_INTEGRATION_SUCCESS";
export const DELETE_INTEGRATION_FAILURE = "DELETE_INTEGRATION_FAILURE";
