import { ReactNode } from "react";

import { Box, BoxProps, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";

import * as colors from "assets/colors";
import DefaultChip from "components/Chip/DefaultChip";
import DragAndDropContainer from "components/DragAndDrop/DragAndDropContainer";
import { DeleteIcon } from "components/Icon";
import InputPlaceholder from "components/Typography/InputPlaceholder";

import ErrorMessage from "../ErrorMessage";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";

export interface IInputOption<TValue = void> {
  key: string;
  label: string;
  value: TValue; // required by default, use IInputOption<T | undefined> if necessary to make it optional
  locked?: boolean; // prevent deletion at option level (e.g if some are deletable but not all of them)
  disabled?: boolean; // prevent click at option level
  highlight?: boolean; // highlight option
}

export interface IBaseOptionsContainer<T>
  extends Pick<IInputBaseLayout, "highlightContent"> {
  options: IInputOption<T>[];
  onChangeOptions?: (options: IInputOption<T>[]) => void;
  direction?: "horizontal" | "vertical";
  size?: "small" | "medium";
  clickable?: boolean;
  deletable?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  // NOTE: only works when draggable = false for now (but you can use vertical instead)
  wrappable?: boolean;
  onClickOption?: (option: IInputOption<T>) => void;
  onDeleteOption?: (option: IInputOption<T>) => void;
  placeholder?: string;
  error?: string;
}

const styles = {
  BaseOptionsContainer: ({
    disabled,
  }: Partial<IBaseOptionsContainer<any>>) => ({
    width: "100%",
    ...(disabled && {
      opacity: "0.5",
    }),
  }),

  container: ({ highlightContent }: Partial<IBaseOptionsContainer<any>>) => ({
    display: "flex",
    "&.outlined": {
      border: "1px solid #b8b8b8",
      borderRadius: "5px",

      padding: "6px 12px",
      minHeight: "46px", // useful when empty
    },
    "&.error": {
      borderColor: colors.Red,
    },
    ...(highlightContent && {
      backgroundColor: colors.BlueChalk,
      borderRadius: "5px",
      padding: "0px 4px",
      minHeight: "40px",
      maxHeight: "40px",
    }),
  }),
};
const useStyles = makeStyles(styles);

function BaseOptionsContainer<TOptionsValue>({
  options,
  onChangeOptions,
  onClickOption,
  onDeleteOption,
  placeholder,
  error,
  direction = "horizontal",
  size = "medium",
  // These are at the component level.
  // We can also specify locked = true at the option level to prevent deletion (see below)
  clickable = false,
  deletable = false,
  draggable = false,
  wrappable = true,
  highlightContent,
  disabled,
}: IBaseOptionsContainer<TOptionsValue>) {
  const classes = useStyles({ highlightContent, disabled });

  const onClick = (option: IInputOption<TOptionsValue>) =>
    clickable && onClickOption ? () => onClickOption(option) : undefined;

  const onDelete = (option: IInputOption<TOptionsValue>) =>
    deletable && !option.locked && onDeleteOption
      ? () => onDeleteOption(option)
      : undefined;

  const content = _.map(options, (option) => {
    return (
      <Box
        key={option.key} // NOTE: important for onReorderChips passed to DragAndDropContainer
        margin="4px 6px"
      >
        <DefaultChip
          data-testid={`option-${option.key}`}
          label={option.label}
          draggable={draggable}
          onClick={onClick(option)}
          onDelete={onDelete(option)}
          deleteIcon={
            <DeleteIcon
              data-testid={`delete-${option.key}`}
              style={{ marginLeft: "-6px", marginRight: "2px" }}
            />
          }
          disabled={option.disabled}
          size={size}
          style={{
            ...(highlightContent && {
              background: colors.tealLight,
              color: "white",
            }),
          }}
          highlight={option.highlight}
        />
      </Box>
    );
  });

  if (draggable && !onChangeOptions) {
    throw new Error("onChangeOptions is required when draggable is true");
  }

  const onReorderChips = (Chips: JSX.Element[]) => {
    if (!onChangeOptions) {
      return;
    }

    const reorderedOptions = _.map(Chips, (ChipElement) => {
      const option = _.find(options, {
        key: ChipElement.key,
      }) as IInputOption<TOptionsValue>;
      return option;
    });

    onChangeOptions(reorderedOptions);
  };

  const editable = clickable || deletable || draggable;

  return (
    <div className={classes.BaseOptionsContainer}>
      <Box
        className={classNames(
          classes.container,
          editable && "outlined",
          error && "error"
        )}
      >
        {draggable && !_.isEmpty(options) ? (
          <DragAndDropContainer
            direction={direction}
            onReorderChildren={onReorderChips}
            style={{ margin: "0 -6px" }}
          >
            {content}
          </DragAndDropContainer>
        ) : !editable && _.isEmpty(options) ? (
          <InputPlaceholder placeholder={placeholder} />
        ) : (
          <DefaultContainer wrappable={wrappable} style={{ margin: "0 -6px" }}>
            {content}
          </DefaultContainer>
        )}
      </Box>

      {error && <ErrorMessage error={error} />}
    </div>
  );
}

interface IDefaultContainer extends BoxProps {
  children: ReactNode;
  wrappable?: boolean;
}

const DefaultContainer = ({
  wrappable,
  children,
  ...otherProps
}: IDefaultContainer) => (
  <Box
    display="flex"
    flexDirection="row"
    flexWrap={wrappable ? "wrap" : "nowrap"}
    {...otherProps}
  >
    {children}
  </Box>
);

export default BaseOptionsContainer;
