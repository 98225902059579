import FileUploadIcon from "@mui/icons-material/FileUpload";

import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicUploadIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <FileUploadIcon />
    </SmallIconButton>
  );
};

export default BasicUploadIcon;
