import _ from "lodash";

import {
  isOptimetriksAdminRole,
  isOptimetriksRole,
} from "model/constants/profiles";
import { IClient, IFolder } from "model/entities/Client";

interface IGetDisplayedFolders {
  folders?: IFolder[];
  client?: IClient;
}

// TODO: apply this logic in the back-end
const getDisplayedFolders = ({ folders, client }: IGetDisplayedFolders) => {
  let displayedFolders = folders;

  const hideRecapFolder = !isOptimetriksRole(client?.profile);

  if (hideRecapFolder) {
    displayedFolders = _.reject(displayedFolders, { id: "recap" });
  }

  const hideHomeFolder =
    !isOptimetriksAdminRole(client?.profile) || client?.dbname !== "admin";

  // TODO: a bit hacky to use "name"
  if (hideHomeFolder) {
    displayedFolders = _.reject(displayedFolders, { name: "Home" });
  }

  return _.compact(displayedFolders);
};

export default getDisplayedFolders;
