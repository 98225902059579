import _ from "lodash";

import { getOptionsFromList } from "containers/workflows/subcategories/activities/utils";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { CUSTOM_FIELD_TYPE, IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

/*
EXPLANATION

The BE sends:
  {
    scol: "option1"
    mc: ["choice1", "choice2"]
  }

But what we need for the input is:
  {
    scol: [{ key: "option1", label: "Option 1"}]
    mc: [{ key: "choice1", label: "Choice 1"}, { key: "option2", label: "Choice 2"}]
  }

We also have to change the onChange to avoid storing Options in the formState (see handleInputChangeAndFlattenOptions)
 */

/**
 * Convert "choice" attributes from "options" to "flat"
 */
export const getOptionsAsAttributes = (type: CUSTOM_FIELD_TYPE, value: any) => {
  if (type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE) {
    return getOptionKey(value);
  }
  if (type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
    return getOptionKey(value);
  }
  if (type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE) {
    return _.map(value, getOptionKey);
  }
  if (type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST) {
    return _.map(value, getOptionKey);
  }
  if (type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST) {
    // TODO: SC, SCOL, MC, MCOL sub-attributes are broken
    // They are in "IOption" format, but the back-end accepts "flat"
    // -> Would be better to keep IOption format, to avoid extra hassle on the web...
    // -> Also, this would pave the way to having a uniform structure between IAugmentedReport and IListItem
    // -> However, if we send IOption's to BE, what does it send back ? Might have to refactor that as well...
    // (See getChoiceAttributesAsOptions)
    return value;
  }
  return value;
};

const getOptionKey = (option: IOption) => option?.key;
export const LINKED_OPTIONS_KEY = "_linked_options";
/**
 * Convert "choice" attributes from "flat" to "options"
 */
export const getChoiceAttributesAsOptions = (
  itemState: IListItem,
  attribute: IListSchema,
  list: IList | undefined,
  lang: TLang
) => {
  const CLEAR_OPTION = {
    key: "__CLEAR",
    label: lang.components.inputSelect.clear,
  };
  const linkedOptions = itemState[LINKED_OPTIONS_KEY];
  const { type, column_tag } = attribute;

  let value = itemState[column_tag];

  if (type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE) {
    const options = _.compact(attribute.options).concat([CLEAR_OPTION]);
    value = _.find(options, { key: value });
  }

  if (type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
    const options = getOptionsFromList(list).concat(
      [CLEAR_OPTION],
      linkedOptions
    );
    value = _.find(_.uniqBy(options, "key"), { key: value });
  }

  if (type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE) {
    const options = _.compact(attribute.options).concat([CLEAR_OPTION]);
    value = _.filter(options, (opt) => _.includes(value, opt?.key));
  }

  if (type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST) {
    const options = getOptionsFromList(list).concat(
      [CLEAR_OPTION],
      linkedOptions
    );
    value = _.filter(_.uniqBy(options, "key"), (opt) =>
      _.includes(value, opt?.key)
    );
  }

  return value;
};
