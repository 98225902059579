/**
 * Creates a notification action creator. This will be dispatched to the Redux store and allow for notification
 * messaging. This is used to create notifications for new user additions (web/app)
 * @param {Object} user User Object
 * @return {Object} Object with type of action and the userName
 * */

import { IAction } from "redux/store/model";

import { TNotificationActionLevel } from "./actionLevels";
import { TNotificationActionType } from "./actionTypes";

export interface INotification {
  id?: string;
  level: string;
  message: string;
  detailedMessage?: string;
}

export interface IShowNotificationActionCreator extends IAction {
  notification: INotification;
}

/**
 * IMPORTANT: In components, prefer `const showNotification = useActions(showNotificationAction);`
 *
 * Creates a "show notification" action
 * @param {String} type Notification type
 * @param {String} message Message to display in notification
 * @param {String} level string telling the level of notification to create
 * @returns {Object}
 */
export function showNotificationActionCreator(
  type: TNotificationActionType,
  level: TNotificationActionLevel,
  message: string,
  detailedMessage?: string
): IShowNotificationActionCreator {
  return {
    type,
    notification: {
      level,
      message,
      detailedMessage,
    },
  };
}
