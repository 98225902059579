import React, { useEffect, useState } from "react";

import {
  BaseTextFieldProps,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Black } from "assets/colors";
import { ImportIcon } from "components/Buttons/MenuButton";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "../InputEmptyContainer";
import { getExtensionFromFileName } from "../utils";
import FilePreview from "./FilePreview";

export interface IFileInput {
  url: string;
  filename?: string;
  type?: string;
}

export interface ICustomInputFilePropsBase
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  defaultValue?: IFileInput;
  name: string;
  lang: {
    title?: string;
    tooltip?: string;
  };
  onChange: (value: any, name: string) => void;
  required?: boolean;
  error?: string;
  viewMode?: TViewMode;
  viewStacked?: boolean;
}
export interface ICustomInputFileProps
  extends ICustomInputFilePropsBase,
    Omit<IInputBaseLayout, keyof ICustomInputFilePropsBase> {}

export type TFileWithURL = File & { url?: string };

export const InputFile: React.FunctionComponent<ICustomInputFileProps> = ({
  name,
  onChange,
  viewMode = "CREATE",
  viewStacked,
  lang,
  defaultValue,
  error,
  ...rest
}) => {
  const [file, setFile] = useState<TFileWithURL>();
  const [value, setValue] = useState<IFileInput | undefined>(defaultValue);
  const langKey = useTranslations();
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (file && file.url) {
      setValue({
        filename: file.name,
        url: file.url,
        type: getExtensionFromFileName(file.name),
      });
    }
  }, [file]);

  const handleChange = (event: any) => {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files?.length) {
      return;
    }
    const file = inputElement.files[0] as TFileWithURL;
    file.url = URL.createObjectURL(file);
    setFile(file);
    onChange(file, name);
  };

  const deleteFile = () => {
    setFile(undefined);
    setValue(undefined);
    onChange(null, name);
  };

  return (
    <InputBaseLayout
      {...rest}
      viewStacked={viewStacked}
      viewMode={viewMode}
      label={lang.title}
      tooltip={lang.tooltip}
      error={error}
    >
      {(viewMode === "CREATE" || viewMode === "EDIT") &&
      (!value || !value.url) ? (
        <InputEmptyContainer>
          <Button disableElevation component="label" variant="contained">
            <ImportIcon />
            {langKey.actions.upload}
            <input type="file" hidden onChange={handleChange} />
          </Button>
        </InputEmptyContainer>
      ) : null}

      {(viewMode === "CREATE" && value && value?.url) ||
      viewMode === "VIEW" ||
      (viewMode === "EDIT" && value && value?.url) ||
      viewMode === "INLINE" ? (
        <>
          <Box
            display={"flex"}
            flexDirection={viewStacked ? "column" : "row"}
            alignItems={"center"}
            alignContent={"center"}
            gridColumnGap={"16px"}
            width={"fit-content"}
            minWidth={"200px"}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <FilePreview
              description={value && value.type ? value.type : "-"}
              url={value ? value.url : undefined}
              viewStacked={viewStacked}
            />

            <Box
              display={"flex"}
              flexDirection="row"
              alignContent={"center"}
              alignItems={"center"}
              gridColumnGap={"40px"}
              justifyContent={"space-between"}
            >
              {
                <Title
                  filename={
                    value
                      ? value.filename
                        ? value.filename
                        : "-"
                      : "No file selected"
                  }
                />
              }

              {(viewMode === "CREATE" || viewMode === "EDIT") && value ? (
                <Box>
                  <DownloadButton url={value.url} />
                  <DeleteButton deleteFile={deleteFile} />
                </Box>
              ) : null}

              {viewMode === "VIEW" && value && viewStacked ? (
                <Box style={{ visibility: hovered ? "visible" : "hidden" }}>
                  <DownloadButton url={value.url} />
                </Box>
              ) : null}
            </Box>
          </Box>
        </>
      ) : null}
    </InputBaseLayout>
  );
};

export default InputFile;

const DownloadButton = ({ url }: { url: string }) => {
  return (
    <IconButton
      component={"a"}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      download
      style={{
        color: Black,
        fontSize: "16px !important",
      }}
    >
      <DownloadIcon />
    </IconButton>
  );
};

const DeleteButton = ({ deleteFile }: { deleteFile: any }) => {
  return (
    <IconButton style={{ color: Black }} onClick={deleteFile}>
      <span className="material-icons">delete</span>
    </IconButton>
  );
};

const Title = ({ filename }: { filename: string }) => {
  return (
    <div
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "250px",
      }}
    >
      <Tooltip title={filename} interactive placement="bottom">
        <Typography noWrap style={{ fontSize: "16px" }}>
          {filename}
        </Typography>
      </Tooltip>
    </div>
  );
};

export const DownloadIcon = () => (
  <span className="material-icons-outlined">file_download</span>
);
