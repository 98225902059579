import { useState } from "react";

import {
  Box,
  Button,
  ButtonProps,
  Fade,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import classNames from "classnames";
import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import InputLabel from "components/Input/InputLabel";
import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import ToggleSwitch from "components/Switch/ToggleSwitch";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { fetchCustomersAttributeAction } from "containers/customers/redux/actions";
import { getCustomerLocations } from "containers/customers/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { useActions, useTranslations } from "hooks";

import ControlMenuButton from "./ControlMenu/ControlMenuButton";

const useStyles = makeStyles({
  menuItem: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    width: "100%",
    gridColumnGap: "10px",
  },
  menuItemContainer: {
    height: "45px",
  },
  subMenuItemContainer: {
    marginLeft: "15px",
  },
});
export interface ILayerControlProps {
  displayGeoDelimAttributes: Record<string, boolean>;
  displayClusters: boolean;
  displayCustomers: boolean;
  displayTerritories: boolean;
  displayDeforestation: boolean;
  satelliteView: boolean;
  toggleSatelliteView: () => void;
  toggleDisplayCustomers: () => void;
  toggleDisplayTerritories: () => void;
  toggleDisplayClusters: () => void;
  toggleCustomerGeoAttribute: (attr: string) => void;
}
const LayerControl: React.FC<ILayerControlProps> = ({
  toggleSatelliteView,
  satelliteView,
  displayCustomers,
  displayTerritories,
  displayClusters,
  displayDeforestation,
  displayGeoDelimAttributes = {},
  toggleDisplayCustomers,
  toggleDisplayTerritories,
  toggleDisplayClusters,

  toggleCustomerGeoAttribute,
}) => {
  const classes = useStyles();

  const lang = useTranslations();

  const client = useSelector(getSelectedClient);
  const activatedTerritory = client?.activate_territory;
  const langKey =
    lang.containers.lists.subCategories.lists.createEditModal.layerControl
      .options;

  const enabledElements = [
    displayCustomers,
    activatedTerritory && displayTerritories,
    displayClusters,
    satelliteView,
  ].filter((element) => element);

  const fetchCustomersAttribute = useActions(fetchCustomersAttributeAction);

  const lists = useSelector(allListsSelector);
  const customerList = lists.find((list) => list.id === "customer");
  const geoAttributes = customerList?.schema.filter(
    (item) => item.type === CUSTOM_FIELD_TYPE.GEO_DELIMITATION
  );

  const [isFetchingAttribute, setIsFetchingAttribute] = useState(false);

  const handleToggleGeoAttribute = async (attributeTag: string) => {
    setIsFetchingAttribute(true);
    await fetchCustomersAttribute(attributeTag, {}, true);
    setIsFetchingAttribute(false);
  };

  const customerLocations = useSelector(getCustomerLocations);

  return (
    <ControlMenuButton
      menuProps={{
        transformOrigin: {
          vertical: -107,
          horizontal: -45,
        },
      }}
      icon={<LayersOutlinedIcon htmlColor="black" />}
      badgeContent={_.size(enabledElements)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <ToggleMenuItem
          defaultValue={satelliteView}
          onToggle={toggleSatelliteView}
          label={langKey.satellite}
          name="satellite"
        />
        {displayDeforestation && (
          <ToggleMenuItem
            defaultValue={displayDeforestation}
            onToggle={() => {}}
            label={langKey.deforestation}
            name="deforestation"
          />
        )}
        <ToggleMenuItem
          defaultValue={displayCustomers}
          onToggle={toggleDisplayCustomers}
          label={langKey.customers}
          name="customers"
        />
        {!_.isEmpty(geoAttributes) &&
          displayCustomers &&
          _.map(geoAttributes, (attr, idx) => {
            const value = displayGeoDelimAttributes[attr.column_tag] || false;

            const onClick: ButtonProps["onClick"] = (e) => {
              toggleCustomerGeoAttribute(attr.column_tag);
              e.preventDefault();
              e.stopPropagation();

              if (!value) {
                handleToggleGeoAttribute(attr.column_tag);
              }
            };

            return (
              <Fade in timeout={300 + idx * 10}>
                <MenuItem
                  className={classes.menuItemContainer}
                  component={Button}
                  key={attr.column_tag}
                  onClick={onClick}
                  disabled={!customerLocations}
                  style={{
                    opacity: customerLocations ? 1 : 0.5,
                  }}
                >
                  <Box
                    className={classNames(
                      classes.menuItem,
                      classes.subMenuItemContainer
                    )}
                  >
                    <InputLabel
                      title={langKey[attr.column_tag] ?? attr.column_name}
                    />
                    <ToggleSwitch
                      name={attr.column_tag}
                      size="small"
                      defaultChecked={value}
                      onChange={onClick}
                    />
                  </Box>
                </MenuItem>
              </Fade>
            );
          })}
        {activatedTerritory && (
          <ToggleMenuItem
            defaultValue={displayTerritories}
            onToggle={toggleDisplayTerritories}
            label={langKey.territories}
            name="territories"
          />
        )}
        <ToggleMenuItem
          defaultValue={displayClusters}
          onToggle={toggleDisplayClusters}
          label={langKey.clusters}
          name="clusters"
        />
      </Box>
      <SnackbarMessage
        key={String(isFetchingAttribute)}
        isFetchActionOngoing={isFetchingAttribute}
        lang={lang}
      />
    </ControlMenuButton>
  );
};

export default LayerControl;

interface IToggleMenuItemProps {
  onToggle: () => void;
  defaultValue: boolean;
  name?: string;
  label?: string;
}
const ToggleMenuItem: React.FC<IToggleMenuItemProps> = ({
  defaultValue,
  onToggle,
  label,
  name,
}) => {
  const classes = useStyles();
  return (
    <MenuItem
      className={classes.menuItemContainer}
      component={Button}
      onClick={(e) => {
        onToggle();
        e.stopPropagation();
      }}
    >
      <Box className={classes.menuItem}>
        <InputLabel
          title={label}
          style={{
            cursor: "pointer",
          }}
        />
        <ToggleSwitch
          name={name}
          size="small"
          defaultChecked={defaultValue}
          onChange={onToggle}
        />
      </Box>
    </MenuItem>
  );
};
