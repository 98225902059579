import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { getExceedsMaxLengthMessage } from "components/Input/utils";
import { IOption } from "model/application/components";
import {
  isAlphanumericalStartingWithLetter,
  isAlphanumericalStartingWithUnderscore,
  isAlphanumericalWithUnderscores,
  isLengthOverLimit,
} from "utils/utils";

export const getErrorMessageForOption: TValidateFunction<
  IOption,
  {
    defaultValues?: IOption[];
    options: IOption[];
    deprecatedOptions?: IOption[];
    allowWithUnderscore?: boolean;
  }
> = ({ attributes, lang, additionnalProps, viewMode = "EDIT" }) => {
  const { label, key, index } = attributes;
  const { defaultValues, options, deprecatedOptions, allowWithUnderscore } =
    additionnalProps;
  let initAttribute: IOption | undefined = undefined;
  if (defaultValues) {
    initAttribute = defaultValues?.find((e) => e.index === index);
  }

  const result: any = {};
  if (!allowWithUnderscore && !isAlphanumericalWithUnderscores(key)) {
    result["key"] = lang.components.inputErrors.alphanumeric;
  } else if (
    allowWithUnderscore &&
    isAlphanumericalStartingWithUnderscore(key)
  ) {
    // nothing
  } else {
    if (
      !(viewMode === "CREATE") &&
      isAlphanumericalStartingWithUnderscore(key) &&
      options.map((o: any) => o.key).includes(key)
    ) {
      // the option is a template, but only the label has been changed. Ok.
    } else if (
      !(viewMode === "CREATE") &&
      initAttribute &&
      isAlphanumericalStartingWithUnderscore(initAttribute.key) &&
      initAttribute.key !== key
    ) {
      // the option is a template, and the key has been changed. not Ok.
      result["key"] =
        lang.components.inputErrors.notPossibleToModifyBecauseTemplate;
    } else if (key?.[0] === "_") {
      result["key"] = lang.components.inputErrors.startWithLetter;
    } else if (!isAlphanumericalStartingWithLetter(key)) {
      result["key"] = lang.components.inputErrors.alphanumeric;
    }
  }
  if (!key || key.length === 0)
    result["key"] = lang.components.inputErrors.empty;

  if (viewMode === "CREATE" && isLengthOverLimit(key))
    result["key"] = getExceedsMaxLengthMessage(lang);

  if (!label || label.length === 0)
    result["label"] = lang.components.inputErrors.empty;

  if (label && (label.indexOf(",") + 1 || label.indexOf(":") + 1))
    result["label"] =
      lang.components.inputErrors.optionLabelForbiddenCharacters;

  if (
    options?.filter((o: IOption) => o.key === key).length >
    (viewMode === "CREATE" ? 0 : 1)
  ) {
    result["key"] = lang.components.inputErrors.alreadyInUse;
  }

  if (
    deprecatedOptions &&
    deprecatedOptions.map((o: IOption) => o.key).includes(key)
  ) {
    result["key"] = lang.components.inputErrors.inDeprecated;
  }

  if (Object.keys(result).length === 0) {
    return {};
  }
  return result;
};
