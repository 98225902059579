import { IUsersState } from "containers/users/redux/reducer/reducer";
import { IMobileUser,IWebUser } from "model/entities/User";

const initialState: IUsersState = {
  subCategorySelected: "mobileuser",
  mobileUsers: {
    fetchingAllUsers: false,
    creatingUsers: false,
    updatingUser: false,
    deletingUser: false,
    deletingUsers: false,
    isLicensingUser: false,
    isUnlicensingUser: false,
    isBulkLicensingUsers: false,
    isBulkUnlicensingUsers: false,
    lastUpdated: new Date(),
    user: {} as IMobileUser,
    didInvalidate: false,
    allUsers: [],
    mobile_teammates: [],
    byId: {},
    byCreatedBy: {},
    byPhone: {},
    errors: [],
  },
  webUsers: {
    fetchingAllUsers: false,
    fetchingUser: false,
    creatingUsers: false,
    updatingUser: false,
    deletingUser: false,
    deletingUsers: false,
    uploadingUserProfile: false,
    lastUpdated: new Date(),
    user: {} as IWebUser,
    didInvalidate: false,
    allUsers: [],
    byId: {},
    byEmail: {},
    errors: [],
  },
};

export default initialState;
