import { ComponentProps, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";

import { predefinedColors } from "assets/colors";
import { TPictureBaseInformation } from "model/entities/Picture";

import DetectionRect, { IDetectionRect } from "./DetectionRect";
import Image from "./Image";
import { IImageDetectionDataFE } from "./types";

type ImgProps = ComponentProps<"img">;

export interface IPictureDetectionViewProps
  extends Pick<IDetectionRect, "onHover" | "onHoverEnd" | "onClick"> {
  picture: TPictureBaseInformation;
  detections: IImageDetectionDataFE[];
  displayDetections?: boolean;
  imgProps?: Omit<ImgProps, "src">;
  activeDetectionBoxIds?: string[];
  isLoadingScanResults?: boolean;
}

function PictureDetectionView({
  onClick,
  onHover,
  onHoverEnd,
  activeDetectionBoxIds,
  displayDetections,
  detections,
  picture,
  imgProps,
  isLoadingScanResults,
}: IPictureDetectionViewProps) {
  const [dimensions, setDimensions] = useState<null | {
    height: number;
    width: number;
    naturalHeight: number;
    naturalWidth: number;
  }>();
  const boxes = _.flatten(_.map(detections, (d) => d.boxes));
  const imageRef = useRef<HTMLImageElement>(null);

  //We attribute for each item a color (outline and fills)
  const productColors: Record<
    string,
    {
      fill_color: string;
      stroke_color: string;
    }
  > = {};
  let colorIndex = 0;
  _.forEach(boxes, (box) => {
    const nameProduct = box.product?._name;
    if (nameProduct && !productColors[nameProduct]) {
      let color;
      if (colorIndex < predefinedColors.length) {
        color = predefinedColors[colorIndex];
      } else {
        color = generateColor(colorIndex - predefinedColors.length + 1);
      }
      productColors[nameProduct] = {
        fill_color: color.replace("rgb", "rgba").replace(")", ", 0.1)"), // Transparent colour for filling
        stroke_color: color, // Opaque colour for the outline
      };
      colorIndex++;
    }
  });

  const handleImageLoad: ImgProps["onLoad"] = () => {
    if (!imageRef.current) return;
    const { naturalHeight, naturalWidth, width, height } = imageRef.current;
    setDimensions({
      height,
      width,
      naturalHeight,
      naturalWidth,
    });
  };

  return (
    <Box
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Image
        src={picture?.url}
        imageRef={imageRef}
        onLoad={handleImageLoad}
        {...imgProps}
      />

      {dimensions && boxes && !isLoadingScanResults && (
        <svg
          style={{ position: "absolute", top: 0, left: 0 }}
          height={dimensions.height}
          width={dimensions.width}
          viewBox={`0 0 ${dimensions.naturalWidth} ${dimensions.naturalHeight}`}
          preserveAspectRatio="none"
        >
          <g>
            {boxes.map((box, index) => {
              const [x1, y1, x2, y2] = box.points;
              const nameProduct = box.product?._name;
              const { fill_color, stroke_color } = productColors[
                nameProduct
              ] || {
                fill_color: "rgba(0,0,0,0.1)",
                stroke_color: "#000000",
              };

              return (
                <DetectionRect
                  id={box.id.toString()}
                  key={index}
                  x={x1}
                  y={y1}
                  width={x2 - x1}
                  height={y2 - y1}
                  active={
                    displayDetections && activeDetectionBoxIds?.includes(box.id)
                  }
                  onHover={onHover}
                  onHoverEnd={onHoverEnd}
                  onClick={onClick}
                  fill={fill_color}
                  stroke={stroke_color}
                />
              );
            })}
          </g>
        </svg>
      )}
    </Box>
  );
}

export default PictureDetectionView;

//To generate colours when the presets are exhausted
function generateColor(index: number) {
  const variation = 50;
  const r = (index * variation) % 256;
  const g = (index * 2 * variation + 85) % 256;
  const b = (index * 3 * variation + 170) % 256;
  return `rgb(${r},${g},${b})`;
}
