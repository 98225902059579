import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { bool, func,node } from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomFullScreenDialog(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      fullWidth={true}
      fullScreen
      maxWidth="md"
      scroll="paper"
      style={{ left: "50px" }}
    >
      <DialogTitle />
      <DialogActions disableSpacing>
        <IconButton onClick={props.onClose} color="primary">
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}

CustomFullScreenDialog.propTypes = {
  children: node,
  open: bool,
  onClose: func,
};
