import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { BasicEditIcon } from "components/Dialog/BasicDialog";
import {
  getActionsAvailableSelector,
  getSignedInUser,
} from "containers/authentication/redux/selector";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { useTranslations } from "hooks";

interface IEditActivityReportButton {
  onClick?: () => void;
}

// viewMode === "VIEW"

const EditActivityReportButton = ({ onClick }: IEditActivityReportButton) => {
  const lang = useTranslations();
  const { EDIT_ACTIVITY_REPORT: editReportPermission } = useSelector(
    getActionsAvailableSelector
  );
  const mobileUsers = useSelector(allMobileUsersSelector);
  const userLogged = useSelector(getSignedInUser);

  if (!editReportPermission) {
    return null;
  }

  const getDisabledEditIconMessage = () => {
    const mobileUserLinked = _.find(mobileUsers, {
      email: userLogged.email,
    });

    if (mobileUserLinked) {
      return;
    }

    return lang.containers.workflows.subCategories.activityReports.detailDialog
      .disabledIconTooltip.editIcon;
  };

  const tooltip = getDisabledEditIconMessage();

  return (
    <Tooltip title={tooltip ?? ""}>
      <span /* DO NOT REMOVE, I am important because Tooltip passes a ref down + removing me breaks the button style */
      >
        <BasicEditIcon onClick={onClick} disabled={!!tooltip} />
      </span>
    </Tooltip>
  );
};

export default EditActivityReportButton;
