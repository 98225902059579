import { StyleSheet } from "@react-pdf/renderer";

import { darkgray, lightgray, lightred, teal } from "assets/colors";

const CARD_RADIUS: number = 5;

export const styles = StyleSheet.create({
  page: {
    padding: "8mm",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  headLine: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "8mm",
    marginBottom: "8mm",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
  },
  filters: {
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: 8,
  },
  inlineContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
  },
  text: {
    fontFamily: "BasierCircle",
    color: teal,
  },
  staticText: {
    fontFamily: "BasierCircle",
    color: darkgray,
  },
  warningText: {
    fontFamily: "BasierCircle",
    color: lightred,
    fontSize: 7,
  },
  cardContainer: {
    padding: "2mm",
  },
  card: {
    borderStyle: "solid",
    borderColor: lightgray,
    borderTopLeftRadius: CARD_RADIUS,
    borderTopRightRadius: CARD_RADIUS,
    borderBottomRightRadius: CARD_RADIUS,
    borderBottomLeftRadius: CARD_RADIUS,
    padding: "4mm",
    borderWidth: 1,
  },
  cardTitle: {
    fontFamily: "BasierCircle",
    color: darkgray,
    marginBottom: "2mm",
    fontSize: 8,
    textAlign: "left",
  },
  pageNumber: {
    fontSize: 8,
    color: darkgray,
  },
  footer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
