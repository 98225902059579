import * as Sentry from "@sentry/react";
import {
  ILogDetail,
  logError as _logError,
  logInfo as _logInfo,
  logWarning as _logWarning,
} from "fieldpro-tools";

import { IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";
import { getAppVersion } from "utils/utils";

import getErrorMessage from "./getErrorMessage";
import { getSentryExtras, getSentryTags, getSentryUser } from "./sentry";
import ignoreError from "./sentry/ignoreError";
import { formatUser } from "./user";

type ILogData = Omit<ILogDetail, "message"> & { message?: string };

interface IFieldProLogger {
  client?: IClient;
  user?: ISignedInUser;
  baseLogInfo?: typeof _logInfo;
  baseLogWarning?: typeof _logWarning;
  baseLogError?: typeof _logError;
  /** NOTE: this only works for handled errors. To ignore unhandled errors by message regex, see sentry/init.ts */
  sentryIgnoreError?: (e: unknown) => boolean;
}

/**
 * FieldProLogger wrapping Sentry and formatting logs for Loki
 *
 * Note: we pass explicit user, tags, and extra objects to Sentry calls to avoid concurrency issues with setUser, setTags, setExtra
 * Also more straightforward than a Sentry.withScope callback ?
 *
 * See https://github.com/getsentry/sentry-javascript/issues/4372#issuecomment-1008953218
 */
const FieldProLogger = ({
  client,
  user,
  baseLogInfo = _logInfo,
  baseLogWarning = _logWarning,
  baseLogError = _logError,
  sentryIgnoreError = ignoreError,
}: IFieldProLogger = {}) => {
  const logInfo = (message: string, data?: ILogData) => {
    const logDetail = getFullLogDetail(message, data);
    baseLogInfo(message, logDetail);
  };

  const logWarning = (message: string, data?: ILogData) => {
    const logDetail = getFullLogDetail(message, data);
    baseLogWarning(message, logDetail);

    const sentryUser = getSentryUser(user);
    const tags = getSentryTags(logDetail);
    const extra = getSentryExtras(logDetail);

    Sentry.captureMessage(message, {
      user: sentryUser,
      tags,
      extra,
      level: "warning",
    });
  };

  /**
   * @param error Error or string. It is recommend to pass error object if available because Sentry will log much more details (such as stack trace)
   * See https://docs.sentry.io/platforms/javascript/usage/#capturing-errors
   * @param data
   *
   *
   * NOTE about the message
   *
   * error.message (or just "error" if it is a string) and data.message can be different, but data.message takes precedence
   * This allows us to override default error message (e.g to add more info to it).
   *
   * In Loki, this will be the main (only?) message;
   * In Sentry, you will be able to see data.message in the "Additional data" section (but the transaction name will still be the original error)
   *
   */
  const logError = (error: unknown, data?: ILogData) => {
    const finalMessage = data?.message ?? getErrorMessage(error);

    const logDetail = getFullLogDetail(finalMessage, data);

    baseLogError(finalMessage, logDetail);

    if (sentryIgnoreError(error)) {
      return;
    }

    const sentryUser = getSentryUser(user);
    const tags = getSentryTags(logDetail);
    const extra = getSentryExtras(logDetail);

    Sentry.captureException(error, {
      user: sentryUser,
      tags,
      extra,
      level: "error",
    });
  };

  /**
   * @param message required message
   * @param data same as ILogDetail but with optional message
   * @returns data with message as fallback in case there was none
   */
  const getFullLogDetail = (message: string, data?: ILogData) => {
    const { id: client_id, name: client_name, dbname } = client || {};

    const logDetail = {
      message,
      client_id,
      client_name,
      dbname,
      frontend_version: getAppVersion(),
      ...formatUser(user),
      // NOTE: data overrides all other default fields
      ...data,
    };

    return logDetail;
  };

  return { logInfo, logWarning, logError };
};

export type TLogger = ReturnType<typeof FieldProLogger>;

/**
 * Default FieldPro logger, without any user nor client metadata
 */
export const defaultLogger = FieldProLogger();

export default FieldProLogger;
