import _ from "lodash";
import uniq from "lodash/uniq";
import moment from "moment";

import { ITableSorting } from "components/Table/CustomTable/CustomTable";
import {
  IArchiveActivitySuccessActionCreator,
  IArchiveWorkflowSuccessActionCreator,
  ICreateActivitySuccessActionCreator,
  ICreateWorkflowSuccessActionCreator,
  IDeleteActivityReportSuccessActionCreator,
  IDeleteActivitySuccessActionCreator,
  IDeleteWorkflowSuccessActionCreator,
  IEditQuestionTagSuccessAction,
  IFetchActivityReportsSuccessActionCreator,
  IFetchActivityVersionSchemaSuccessActionCreator,
  IFetchAllActivitiesSuccessActionCreator,
  IFetchAllWorkflowsSuccessActionCreator,
  IFetchAugmentedActivityReportsSuccessActionCreator,
  IFetchJobLogsSuccessActionCreator,
  IFetchWorkflowReportsSuccessActionCreator,
  IFetchWorkflowReportSuccessActionCreator,
  IReorderWorkflowSuccessActionCreator,
  IRestoreActivitySuccessActionCreator,
  IRestoreWorkflowSuccessActionCreator,
  IResumeWorkflowReportExecutionSuccessActionCreator,
  ISetActivityReportSortingAction,
  IUpdateActivityReportSuccessActionCreator,
  IUpdateActivitySuccessActionCreator,
  IUpdateWorkflowSuccessActionCreator,
} from "containers/workflows/redux/actionCreators";
import { STEP_TYPE } from "model/entities/Job";
import {
  IActivity,
  IActivityReport,
  IStepOverview,
  IWorkflow,
  IWorkflowReportWithStepOverview,
  STEP_STATUS,
} from "model/entities/Workflow";
import { IAction, IActionError } from "redux/store/model";
import { DATE_FORMAT_FULL_DATE } from "utils/constants";
import {
  convertArrayToObject,
  extractIdsFromArray,
  insertNewItemToArr,
  removeItem,
  removeObjectFromArray,
  removeObjectFromHash,
  updateErrorsList,
  updateObjectInArray,
} from "utils/reducerUtils";

import {
  ICreateActivityReportSuccessActionCreator,
  ICreateWorkflowReportSuccessActionCreator,
  IDeleteWorkflowReportSuccessActionCreator,
  IStopWorkflowReportExecutionSuccessActionCreator,
} from "./actionCreators";
import * as types from "./actionTypes";
import { IAugmentedActivityReport } from "./api";
import initialState from "./initialState";
import { updateActivityQuestionByTag } from "./utils";

export interface WorkflowsState {
  isAttaching: boolean;
  isChangingTag: boolean;
  isFetchingAll: boolean;
  isDeleting: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isCreatingWorkflowReport: boolean;
  isUpdatingWorkflowReport: boolean;
  isDeletingWorkflowReport: boolean;
  isCreatingActivityReport: boolean;
  isUpdatingActivityReport: boolean;
  isDeletingActivityReport: boolean;
  isDownloadingReportRawData: boolean;
  isFetchingActivities: boolean;
  isDeletingActivity: boolean;
  isCreatingActivity: boolean;
  isUpdatingActivity: boolean;
  isUploadingFile: boolean;
  allWorkflows: IWorkflow[];
  allActivities: IActivity[];
  allActivityReports: IActivityReport[];
  allAugmentedActivityReports: IAugmentedActivityReport[];
  isFetchingAugmentedActivityReports: boolean;
  activityReportSorting: ITableSorting;
  byId: { [id: string]: IWorkflow };
  allIds: string[];
  errors: any[];
  lastUpdated: Date;
  isFetchingWorkflowReports: boolean;
  isFetchingJobLogs: boolean;
  allWorkflowReports: IWorkflowReportWithStepOverview[];
  stepsOverview: IStepOverview[];
  totalReportCount: number;
  totalAugmentedReportCount: number;
  isFetchingActivityVersionSchema: boolean;
}

/**
 * Reducer for workflows, handles updating the state of the store in regards to workflows object
 * This will have an initial state that will be updated on every action that is dispatched. if the action
 * is not handled by this reducer, then the initial state will be returned.
 * @param {Object} state Redux store state
 * @param {Object} action Action object
 */
export default function workflowReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_WORKFLOW_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.CREATE_WORKFLOW_SUCCESS: {
      const { workflow, files } = action as ICreateWorkflowSuccessActionCreator;

      // first replace the url in the workflows
      for (const file of files) {
        const splits = file.file.split("/");
        if (splits[splits.length - 1] === workflow.picture_url) {
          workflow.picture_url = file.url;
        }
      }

      const newById = {
        ...state.byId,
        [workflow.id]: workflow,
      };
      const newAllWorkflows = insertNewItemToArr(state.allWorkflows, workflow);
      const ids = extractIdsFromArray(newAllWorkflows);
      return {
        ...state,
        isCreating: false,
        allWorkflows: newAllWorkflows,
        byId: newById,
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: Date.now(),
      };
    }

    case types.FETCH_ALL_WORKFLOWS_BEGIN:
      return {
        ...state,
        isFetchingAll: true,
        isUpdating: false,
        isArchiving: false,
        isCreating: false,
        isDeleting: false,
        isRestoring: false,
      };

    case types.FETCH_ALL_WORKFLOWS_FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_ALL_WORKFLOWS_SUCCESS: {
      const { workflows } = action as IFetchAllWorkflowsSuccessActionCreator;
      const ids = extractIdsFromArray(workflows);
      return {
        ...state,
        isFetchingAll: false,
        allWorkflows: workflows,
        byId: convertArrayToObject({}, workflows, "id"),
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_WORKFLOW_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_WORKFLOW_SUCCESS: {
      const { workflow, files } = action as IUpdateWorkflowSuccessActionCreator;

      // first replace the url in the workflows
      for (const file of files) {
        const splits = file.file.split("/");
        if (splits[splits.length - 1] === workflow.picture_url) {
          workflow.picture_url = file.url;
        }
      }

      const newById = {
        ...state.byId,
        [workflow.id]: workflow,
      };
      const newAllWorkflows = updateObjectInArray(state.allWorkflows, workflow);

      return {
        ...state,
        isUpdating: false,
        allWorkflows: newAllWorkflows,
        byId: newById,
      };
    }

    case types.REORDER_WORKFLOW_SUCCESS:
      const { workflowsIds } = action as IReorderWorkflowSuccessActionCreator;
      const cloneWorkflows = [...state.allWorkflows];

      const newAllWorkflows = _.map(cloneWorkflows, (workflow) => {
        if (_.includes(workflowsIds, workflow.id)) {
          const index = _.indexOf(workflowsIds, workflow.id);
          return {
            ...workflow,
            index,
          };
        }
        return workflow;
      });

      return {
        ...state,
        allWorkflows: _.isEmpty(workflowsIds)
          ? cloneWorkflows
          : newAllWorkflows,
      };

    case types.DELETE_WORKFLOW_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DELETE_WORKFLOW_SUCCESS: {
      const { workflowId } = action as IDeleteWorkflowSuccessActionCreator;
      const workflow = state.allWorkflows.find((w) => w.id === workflowId);
      const allWorkflows = removeObjectFromArray(
        state.allWorkflows,
        workflowId
      );

      const byId = removeObjectFromHash(state.byId, workflowId);
      const allIds = removeItem(state.allIds, workflow);

      return {
        ...state,
        isDeleting: false,
        allWorkflows,
        byId,
        allIds,
      };
    }

    case types.ARCHIVE_WORKFLOW_BEGIN:
      return {
        ...state,
        isArchiving: true,
      };

    case types.ARCHIVE_WORKFLOW_FAILURE:
      return {
        ...state,
        isArchiving: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.ARCHIVE_WORKFLOW_SUCCESS: {
      const { workflowId } = action as IArchiveWorkflowSuccessActionCreator;
      const workflow = state.byId[workflowId];
      workflow.active = false;

      const allWorkflows = updateObjectInArray(state.allWorkflows, workflow);
      const byId = {
        ...state.byId,
        [workflow.id]: workflow,
      };

      return {
        ...state,
        isArchiving: false,
        allWorkflows,
        byId,
      };
    }

    case types.RESTORE_WORKFLOW_BEGIN:
      return {
        ...state,
        isRestoring: true,
      };

    case types.RESTORE_WORKFLOW_FAILURE:
      return {
        ...state,
        isRestoring: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESTORE_WORKFLOW_SUCCESS: {
      const { workflowId } = action as IRestoreWorkflowSuccessActionCreator;
      const workflow = state.byId[workflowId];
      workflow.active = true;
      const allWorkflows = updateObjectInArray(state.allWorkflows, workflow);
      const byId = {
        ...state.byId,
        [workflow.id]: workflow,
      };

      return {
        ...state,
        isRestoring: false,
        allWorkflows,
        byId,
      };
    }
    case types.DELETE_WORKFLOW_REPORT_BEGIN:
      return {
        ...state,
        isDeletingWorkflowReport: true,
      };

    case types.DELETE_WORKFLOW_REPORT_FAILURE:
      return {
        ...state,
        isDeletingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };
    case types.DELETE_WORKFLOW_REPORT_SUCCESS: {
      const { workflowReportId } =
        action as IDeleteWorkflowReportSuccessActionCreator;
      return {
        ...state,
        allWorkflowReports: state.allWorkflowReports.filter(
          (wr) => wr.id !== workflowReportId
        ),
        isDeletingWorkflowReport: false,
      };
    }
    case types.ARCHIVE_ACTIVITY_BEGIN:
      return {
        ...state,
        isArchiving: true,
      };

    case types.ARCHIVE_ACTIVITY_FAILURE:
      return {
        ...state,
        isArchiving: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.ARCHIVE_ACTIVITY_SUCCESS: {
      const { activityId } = action as IArchiveActivitySuccessActionCreator;
      const activity = state.allActivities.find((act) => act.id === activityId);
      if (activity) {
        activity.active = false;
      }

      const allActivities = updateObjectInArray(state.allActivities, activity);

      return {
        ...state,
        isArchiving: false,
        allActivities,
      };
    }

    case types.RESTORE_ACTIVITY_BEGIN:
      return {
        ...state,
        isRestoring: true,
      };

    case types.RESTORE_ACTIVITY_FAILURE:
      return {
        ...state,
        isRestoring: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESTORE_ACTIVITY_SUCCESS: {
      const { activityId } = action as IRestoreActivitySuccessActionCreator;
      const activity = state.allActivities.find((act) => act.id === activityId);
      if (activity) {
        activity.active = true;
        const allActivities = updateObjectInArray(
          state.allActivities,
          activity
        );
        const byId = {
          ...state.byId,
          [activity.id]: activity,
        };

        return {
          ...state,
          isRestoring: false,
          allActivities,
          byId,
        };
      }
      return { ...state };
    }

    case types.DELETE_ACTIVITY_REPORT_BEGIN:
      return {
        ...state,
        isDeletingActivityReport: true,
      };

    case types.DELETE_ACTIVITY_REPORT_FAILURE:
      return {
        ...state,
        isDeletingActivityReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DELETE_ACTIVITY_REPORT_SUCCESS: {
      const { reportId } = action as IDeleteActivityReportSuccessActionCreator;
      return {
        ...state,
        allActivityReports: [
          ..._.filter(
            state.allActivityReports,
            (report) => report._id !== reportId
          ),
        ],
        allAugmentedActivityReports: [
          ..._.filter(
            state.allAugmentedActivityReports,
            (report) => report.metadata._id !== reportId
          ),
        ],
        isDeletingActivityReport: false,
      };
    }

    case types.FETCH_ACTIVITY_REPORTS_BEGIN:
      return {
        ...state,
      };

    case types.FETCH_ACTIVITY_REPORTS_FAILURE:
      return {
        ...state,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_ACTIVITY_REPORTS_SUCCESS: {
      const { reports, totalCount, appendMode } =
        action as IFetchActivityReportsSuccessActionCreator;

      let allActivityReports = reports;

      if (appendMode) {
        allActivityReports = _.reduce(
          state.allActivityReports,
          (sum, n) => {
            if (!sum.map((e) => e.metadata._id).includes(n.metadata._id)) {
              return [...sum, n];
            } else {
              return sum;
            }
          },
          reports
        );
      }

      return {
        ...state,
        allActivityReports,
        totalReportCount: totalCount,
      };
    }

    case types.FETCH_AUGMENTED_ACTIVITY_REPORTS_SUCCESS: {
      const { reports, totalCount, options } =
        action as IFetchAugmentedActivityReportsSuccessActionCreator;
      const { appendMode, clearExistingTableReports } = options ?? {};

      let allAugmentedActivityReports;
      if (clearExistingTableReports) {
        allAugmentedActivityReports = reports;
      } else {
        allAugmentedActivityReports = appendMode
          ? _.concat(state.allAugmentedActivityReports, reports)
          : reports;
      }

      return {
        ...state,
        allAugmentedActivityReports,
        totalAugmentedReportCount: totalCount,
        isFetchingAugmentedActivityReports: false,
      };
    }
    case types.FETCH_AUGMENTED_ACTIVITY_REPORTS_BEGIN: {
      return {
        ...state,
        isFetchingAugmentedActivityReports: true,
      };
    }
    case types.FETCH_AUGMENTED_ACTIVITY_REPORTS_FAILURE: {
      return {
        ...state,
        isFetchingAugmentedActivityReports: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };
    }
    case types.CREATE_ACTIVITY_REPORT_BEGIN:
      return {
        ...state,
        isCreatingActivityReport: true,
      };

    case types.CREATE_ACTIVITY_REPORT_FAILURE:
      return {
        ...state,
        isCreatingActivityReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.CREATE_ACTIVITY_REPORT_SUCCESS: {
      const { activity_report } =
        action as ICreateActivityReportSuccessActionCreator;
      let { allActivityReports } = state;

      allActivityReports = allActivityReports.map((report) => {
        if (report._id === activity_report._id) {
          return activity_report;
        }
        return report;
      });
      const wr = state.allWorkflowReports.find(
        (wr) => wr.id === activity_report.workflow_report_id
      );
      (wr as any).steps = [
        {
          id: activity_report.id,
          started_at: activity_report.started_at,
          status: STEP_STATUS.DONE,
          type: STEP_TYPE.ACTIVITY,
          workflow_id: activity_report.workflow_id,
          schema_id: activity_report.activity_id,
          step_id: activity_report.step_id,
          workflow_report_id: activity_report.workflow_report_id,
        },
      ];
      const newAllWorkflowReports = updateObjectInArray(
        state.allWorkflowReports,
        wr
      );
      return {
        ...state,
        isCreatingActivityReport: false,
        allActivityReports,
        allWorkflowReports: newAllWorkflowReports,
      };
    }
    case types.UPDATE_ACTIVITY_REPORT_BEGIN:
      return {
        ...state,
        isUpdatingActivityReport: true,
      };

    case types.UPDATE_ACTIVITY_REPORT_FAILURE:
      return {
        ...state,
        isUpdatingActivityReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_ACTIVITY_REPORT_SUCCESS: {
      const { activity_report } =
        action as IUpdateActivityReportSuccessActionCreator;

      const { allAugmentedActivityReports, allActivityReports } = state;
      return {
        ...state,
        isUpdatingActivityReport: false,
        allActivityReports: _.map(allActivityReports, (report) =>
          report.metadata._id === activity_report.metadata._id
            ? activity_report
            : report
        ),
        allAugmentedActivityReports: _.map(
          allAugmentedActivityReports,
          (report) =>
            report.metadata._id === activity_report.metadata._id
              ? activity_report
              : report
        ),
      };
    }

    case types.RESET_ACTIVITY_REPORT_SELECTION: {
      return {
        ...state,
        allActivityReports: [],
      };
    }

    case types.DOWNLOAD_REPORT_RAW_DATA_BEGIN:
      return {
        ...state,
        isDownloadingReportRawData: true,
      };

    case types.DOWNLOAD_REPORT_RAW_DATA_FAILURE:
      return {
        ...state,
        isDownloadingReportRawData: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DOWNLOAD_REPORT_RAW_DATA_SUCCESS: {
      return {
        ...state,
        isDownloadingReportRawData: false,
      };
    }
    case types.CREATE_WORKFLOW_REPORT_BEGIN:
      return {
        ...state,
        isCreatingWorkflowReport: true,
      };

    case types.CREATE_WORKFLOW_REPORT_FAILURE:
      return {
        ...state,
        isCreatingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.CREATE_WORKFLOW_REPORT_SUCCESS: {
      const { workflow_report } =
        action as ICreateWorkflowReportSuccessActionCreator;
      const newAllWfReports = insertNewItemToArr(
        state.allWorkflowReports,
        workflow_report
      );
      return {
        ...state,
        isCreatingWorkflowReport: false,
        allWorkflowReports: newAllWfReports,
      };
    }
    case types.UPDATE_WORKFLOW_REPORT_BEGIN:
      return {
        ...state,
        isUpdatingWorkflowReport: true,
      };

    case types.UPDATE_WORKFLOW_REPORT_FAILURE:
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_WORKFLOW_REPORT_SUCCESS: {
      const { workflow_report } =
        action as ICreateWorkflowReportSuccessActionCreator;
      const newAllWorkflowReports = updateObjectInArray(
        state.allWorkflowReports,
        workflow_report
      );
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        allWorkflowReports: newAllWorkflowReports,
      };
    }
    case types.UPLOAD_FILE_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
      };

    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploadingFile: false,
      };
    }

    case types.FETCH_WORKFLOW_REPORTS_BEGIN:
      return {
        ...state,
        isFetchingWorkflowReports: true,
      };

    case types.FETCH_WORKFLOW_REPORTS_FAILURE:
      return {
        ...state,
        isFetchingWorkflowReports: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_WORKFLOW_REPORTS_SUCCESS: {
      const { workflow_reports } =
        action as IFetchWorkflowReportsSuccessActionCreator;

      return {
        ...state,
        isFetchingWorkflowReports: false,
        allWorkflowReports: workflow_reports,
      };
    }

    case types.FETCH_WORKFLOW_REPORT_BEGIN:
      return {
        ...state,
        isFetchingWorkflowReport: true,
      };

    case types.FETCH_WORKFLOW_REPORT_FAILURE:
      return {
        ...state,
        isFetchingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_WORKFLOW_REPORT_SUCCESS: {
      const { workflow_report } =
        action as IFetchWorkflowReportSuccessActionCreator;

      let allWorkflowReports = state.allWorkflowReports;
      if (workflow_report) {
        const reportIndex = _.findIndex(state.allWorkflowReports, {
          id: workflow_report.id,
        });

        if (reportIndex === -1) {
          allWorkflowReports = [...allWorkflowReports, workflow_report];
        } else {
          allWorkflowReports = _.map(allWorkflowReports, (report) => {
            return report.id === workflow_report.id ? workflow_report : report;
          });
        }
      }

      return {
        ...state,
        isFetchingWorkflowReport: false,
        allWorkflowReports,
      };
    }

    case types.STOP_WORKFLOW_REPORT_EXECUTION_BEGIN:
      return {
        ...state,
        isUpdatingWorkflowReport: true,
      };

    case types.STOP_WORKFLOW_REPORT_EXECUTION_FAILURE:
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.STOP_WORKFLOW_REPORT_EXECUTION_SUCCESS: {
      const { workflow_report } =
        action as IStopWorkflowReportExecutionSuccessActionCreator;
      const newAllReports = state.allWorkflowReports.map((rep) => {
        if (rep.id === workflow_report.id) {
          return { ...rep, status: workflow_report.status };
        }
        return rep;
      });
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        allWorkflowReports: newAllReports,
      };
    }

    case types.RESUME_WORKFLOW_REPORT_EXECUTION_BEGIN:
      return {
        ...state,
        isUpdatingWorkflowReport: true,
      };

    case types.RESUME_WORKFLOW_REPORT_EXECUTION_FAILURE:
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESUME_WORKFLOW_REPORT_EXECUTION_SUCCESS: {
      const { workflow_report } =
        action as IResumeWorkflowReportExecutionSuccessActionCreator;
      const newAllReports = state.allWorkflowReports.map((rep) => {
        if (rep.id === workflow_report.id) {
          return { ...rep, status: workflow_report.status };
        }
        return rep;
      });
      return {
        ...state,
        isUpdatingWorkflowReport: false,
        allWorkflowReports: newAllReports,
      };
    }
    case types.FETCH_JOB_LOGS_BEGIN:
      return {
        ...state,
        isFetchingJobLogs: true,
      };

    case types.FETCH_JOB_LOGS_FAILURE:
      return {
        ...state,
        isFetchingJobLogs: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_JOB_LOGS_SUCCESS: {
      const { steps_overview } = action as IFetchJobLogsSuccessActionCreator;

      return {
        ...state,
        isFetchingJobLogs: false,
        stepsOverview: steps_overview,
      };
    }
    case types.FETCH_ACTIVITY_VERSION_SCHEMA_BEGIN:
      return {
        ...state,
        isFetchingActivityVersionSchema: true,
      };

    case types.FETCH_ACTIVITY_VERSION_SCHEMA_FAILURE:
      return {
        ...state,
        isFetchingActivityVersionSchema: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_ACTIVITY_VERSION_SCHEMA_SUCCESS: {
      const { activity } =
        action as IFetchActivityVersionSchemaSuccessActionCreator;

      return {
        ...state,
        isFetchingActivityVersionSchema: false,
        currentActivityReportVersionschema: activity,
      };
    }
    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.CREATE_ACTIVITY_BEGIN:
      return {
        ...state,
        isCreatingActivity: true,
      };

    case types.CREATE_ACTIVITY_FAILURE:
      return {
        ...state,
        isCreatingActivity: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.CREATE_ACTIVITY_SUCCESS: {
      const { activity } = action as ICreateActivitySuccessActionCreator;

      const newAllActivities = insertNewItemToArr(
        state.allActivities,
        activity
      );
      return {
        ...state,
        isCreatingActivity: false,
        allActivities: newAllActivities,
        lastUpdated: Date.now(),
      };
    }

    case types.FETCH_ALL_ACTIVITIES_BEGIN:
      return {
        ...state,
        isFetchingActivities: true,
        isUpdatingActivity: false,
        isCreatingActivity: false,
        isDeletingActivity: false,
      };

    case types.FETCH_ALL_ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetchingActivities: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_ALL_ACTIVITIES_SUCCESS: {
      const { activities } = action as IFetchAllActivitiesSuccessActionCreator;
      return {
        ...state,
        isFetchingActivities: false,
        allActivities: activities,
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_ACTIVITY_BEGIN:
      return {
        ...state,
        isUpdatingActivity: true,
      };

    case types.UPDATE_ACTIVITY_FAILURE:
      return {
        ...state,
        isUpdatingActivity: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_ACTIVITY_SUCCESS: {
      const { activity } = action as IUpdateActivitySuccessActionCreator;

      const newAllActivities = updateObjectInArray(
        state.allActivities,
        activity
      );

      return {
        ...state,
        isUpdatingActivity: false,
        allActivities: newAllActivities,
      };
    }

    case types.DELETE_ACTIVITY_BEGIN:
      return {
        ...state,
        isDeletingActivity: true,
      };

    case types.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        isDeletingActivity: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DELETE_ACTIVITY_SUCCESS: {
      const { activityId } = action as IDeleteActivitySuccessActionCreator;
      const allActivities = removeObjectFromArray(
        state.allActivities,
        activityId
      );

      return {
        ...state,
        isDeletingActivity: false,
        allActivities,
      };
    }

    case types.SET_ACTIVITY_REPORT_SORTING: {
      const { sorting } = action as ISetActivityReportSortingAction;
      return {
        ...state,
        activityReportSorting: sorting,
      };
    }

    case types.CHANGE_LIST_QUESTION_TAG_BEGIN: {
      return {
        ...state,
        isChangingTag: true,
      };
    }
    case types.CHANGE_LIST_QUESTION_TAG_FAILURE: {
      return {
        ...state,
        isChangingTag: false,
        errors: updateErrorsList(state, action),
      };
    }
    case types.CHANGE_LIST_QUESTION_TAG_SUCCESS: {
      const { params, activityId } = action as IEditQuestionTagSuccessAction;
      const { new_tag, old_tag, matrix_tag: parent } = params;

      let selectedActivity = _.find([...state.allActivities], {
        id: activityId,
      });

      if (selectedActivity) {
        selectedActivity = updateActivityQuestionByTag(
          selectedActivity,
          new_tag,
          old_tag,
          parent
        );
      }

      const newAllActivities = updateObjectInArray(
        state.allActivities,
        selectedActivity
      );

      return {
        ...state,
        allActivities: newAllActivities,
        isChangingTag: false,
      };
    }

    default:
      return state;
  }
}
