import { Box } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { allDashboardsSelector } from "containers/dashboards/redux/selectors";
import {
  ICustomerDetailForGPSTracking,
  IDashboard,
  IKPI,
  TTimelineData,
} from "model/entities/Dashboard";

import { IUserPositionData } from "../GeoTrackingChartContainer";
import TimelineDate from "./TimelineDate";
import TimelineViewer from "./TimelineViewer";

export interface ITimeLineData {
  timelineChart: IKPI<TTimelineData[]>;
  locationData: IUserPositionData;
  selectedUser?: string;
  timeSelected: Date;
  onOpenCustomerDialog: (customerId: string) => void;
  onRunQuery: (
    dashboard: IDashboard,
    query: any,
    callback?: any,
    degradedModeKpis?: string[]
  ) => Promise<any>;
  queryFilters: any;
  potentialUsers: string[];
  customersVisited: ICustomerDetailForGPSTracking[];
}

export interface ITimelineSidePanelProps {
  timelineData: ITimeLineData;
  timelineTitle: React.ReactNode;
}

const fullDayInMilliseconds = 24 * 60 * 60 * 1000;

function TimelineSidePanel({
  timelineData,
  timelineTitle,
}: ITimelineSidePanelProps) {
  const {
    timelineChart,
    potentialUsers,
    selectedUser,
    timeSelected,
    onOpenCustomerDialog,
    onRunQuery,
    customersVisited,
    queryFilters,
  } = timelineData;

  const dashboards = useSelector(allDashboardsSelector);

  function handleLowerDate() {
    const filters = {
      ...queryFilters,
      _date: {
        startDate: new Date(
          new Date(queryFilters._date.startDate).getTime() -
            fullDayInMilliseconds
        ),
        endDate: new Date(
          new Date(queryFilters._date.endDate).getTime() - fullDayInMilliseconds
        ),
      },
    };

    if (selectedUser) {
      filters.detailed_user = selectedUser;
    }

    const trackingDashboard = _.find(dashboards, { id: "_gps_tracking" });
    if (!trackingDashboard) {
      return;
    }

    onRunQuery(trackingDashboard, filters).catch((e: any) => {
      alert(
        `Error when trying to run onRunQuery (from handleLowerDate): ${e.message}`
      );
    });
  }

  function handleIncreaseDate() {
    const filters = {
      ...queryFilters,
      _date: {
        startDate: new Date(
          new Date(queryFilters._date.startDate).getTime() +
            fullDayInMilliseconds
        ),
        endDate: new Date(
          new Date(queryFilters._date.endDate).getTime() + fullDayInMilliseconds
        ),
      },
    };
    if (selectedUser) {
      filters.detailed_user = selectedUser;
    }

    const trackingDashboard = _.find(dashboards, { id: "_gps_tracking" });
    if (!trackingDashboard) {
      return;
    }

    onRunQuery(trackingDashboard, filters).catch((e: any) => {
      alert(
        `Error when trying to run onRunQuery (from handleIncreaseDate): ${e.message}`
      );
    });
  }

  return (
    <Box paddingTop={"8px"}>
      <Box display={"grid"} gridTemplateRows={"auto auto 1fr"}>
        {selectedUser ? (
          <>
            {timelineTitle}
            <TimelineDate
              date={(timeSelected && new Date(timeSelected)) || new Date()}
              onClickIncreaseDate={handleIncreaseDate}
              onClickLowerDate={handleLowerDate}
            />
            {_.includes(potentialUsers, selectedUser) ? (
              <TimelineViewer
                timelineChart={timelineChart}
                customersVisited={customersVisited}
                onOpenCustomerDialog={onOpenCustomerDialog}
              />
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default TimelineSidePanel;
