import _ from "lodash";

import { IOperation, OPERATION_OPERATOR } from "model/entities/Workflow";

/**
 * Compute the result of the operations. (MEs should have been resolved first)
 * @param operations
 * @returns numeric result
 */
export const resolveOperations = (operations: IOperation[]): number => {
  if (operations.length == 0) {
    return 0; // TODO: use a default value ?
  }

  const resultsIdsMap = {} as { [e: string]: number };

  const sortedOperations = [...operations].sort((a, b) => {
    return a.priority > b.priority ? 1 : -1;
  });

  _.forEach(sortedOperations, (c: IOperation) => {
    const firstOperand = c.first.result_id
      ? resultsIdsMap[c.first.result_id] ?? 0
      : c.first.value
      ? parseFloat(c.first.value)
      : 0;

    const secondOperand = c.second.result_id
      ? resultsIdsMap[c.second.result_id] ?? 0
      : c.second.value
      ? parseFloat(c.second.value)
      : 0;

    switch (c.operator) {
      case OPERATION_OPERATOR.ADD: {
        resultsIdsMap[c.id] = firstOperand + secondOperand;
        break;
      }
      case OPERATION_OPERATOR.SUBTRACT: {
        resultsIdsMap[c.id] = firstOperand - secondOperand;
        break;
      }
      case OPERATION_OPERATOR.DIVIDE: {
        resultsIdsMap[c.id] = firstOperand / secondOperand;
        break;
      }
      case OPERATION_OPERATOR.MULTIPLY: {
        resultsIdsMap[c.id] = firstOperand * secondOperand;
        break;
      }
    }
  });

  return resultsIdsMap[operations[operations.length - 1].id];
};
