/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "api/apiService";
import { formatObjectToKeyValuePairs } from "containers/workflows/utils/";
import { IStream, STEP_TYPE } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareStreamForBackend } from "./utils";

export const STREAMS_ENDPOINT = "streams";
export const STREAM_ENDPOINT = "stream";

// STREAMS

export type TCreateStreamFunc = (
  actionName: string,
  stream: IStream
) => Promise<IServerResponse<ICreateStreamResponse>>;
export interface ICreateStreamResponse {
  id: string;
}

export type TUpdateStreamFunc = (
  actionName: string,
  stream: IStream
) => Promise<IServerResponse<IUpdateStreamResponse>>;
export interface IUpdateStreamResponse {}

export type TDeleteStreamFunc = (
  actionName: string,
  stream_id: string
) => Promise<IServerResponse<IDeleteStreamResponse>>;
export interface IDeleteStreamResponse {}

export type TFetchStreamsFunc = () => Promise<
  IServerResponse<IFetchStreamsResponse>
>;
export interface IFetchStreamsResponse {
  streams: IStream[];
}

export type TUseStreamFunc = (
  id: string,
  table?: string,
  zip?: any,
  encoding?: string,
  lineLength?: number
) => Promise<IServerResponse<IUseStreamResponse>>;
export interface IUseStreamResponse {}

export type TRunStreamFunc = (
  actionName: string,
  stream: IStream
) => Promise<IServerResponse<IRunStreamResponse>>;
export interface IRunStreamResponse {
  success: boolean;
}

/**
 * Api call to fetch all
 */
export const fetchStreamsApiCall: TFetchStreamsFunc = () => {
  return apiService.get(`${STREAMS_ENDPOINT}`);
};

/**
 * Creates a new stream
 * @param {Object} stream stream details
 */
export const createStreamApiCall: TCreateStreamFunc = (actionName, stream) => {
  const payload = prepareStreamForBackend(stream);
  return apiService.post(`${STREAMS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted stream
 * @param {Object} stream Detail of the new stream
 */
export const updateStreamApiCall: TUpdateStreamFunc = (actionName, stream) => {
  const payload = prepareStreamForBackend(stream);
  return apiService.patch(
    `${STREAMS_ENDPOINT}/${stream.id}`,
    payload,
    actionName
  );
};

/**
 * Delete stream API call makes a POST request to delete a single stream
 * @param {String} stream Id of the stream to delete
 * @return {Promise}
 * */
export const deleteStreamApiCall: TDeleteStreamFunc = (
  actionName,
  stream_id
) => {
  return apiService.delete(`${STREAMS_ENDPOINT}/${stream_id}`, {}, actionName);
};

/**
 * Use stream API call makes a POST request to use a stream
 * @param {String} id Id of the stream to use
 * @param {String} table Name of the origin table
 * @param {String} zip Information of the zip
 * @param {Number} lineLength To overwrite the default max char per line
 * @return {Promise}
 * */
export const runStreamApiCall: TUseStreamFunc = (
  id,
  table,
  zip,
  encoding,
  lineLength
) => {
  if (table) {
    return apiService.post(`${STREAM_ENDPOINT}/${id}/run`, {
      parameters: [
        {
          key: "input",
          value: table,
        },
      ],
    });
  } else {
    return apiService.multipartUpdate(
      `${STREAM_ENDPOINT}/${id}/run`,
      [{ file: zip.file, fileName: zip.fileName }],
      {
        ...(encoding && { encoding }),
        ...(lineLength && { line_length: lineLength }),
      }
    );
  }
};
/**
 * Creates a new stream
 * @param {Object} stream Stream details
 */
export const runStreamJobApiCall: TRunStreamFunc = (actionName, stream) => {
  return apiService.post(
    `/step`,
    {
      job_type: STEP_TYPE.STREAM,
      job_schema_id: stream.id,
      parameters: formatObjectToKeyValuePairs(stream),
    },
    actionName
  );
};
