import { ITableOptimization } from "model/entities/TableOptimization";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../../redux/selectors/appSelector";

/**
 * Gets all tableOptimizations from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllTableOptimizations = (state: IRootState) =>
  state.environments.tableOptimizations.tableOptimizations;

/**
 * Selector to get all TableOptimizations from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allTableOptimizationsSelector = customSelectorCreator(
  [getAllTableOptimizations],
  (allTableOptimizations: ITableOptimization[]) => {
    // map all the tableOptimizations
    return allTableOptimizations;
  }
);

/**
 * Gets the status of fetching a table Optimization
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingAllTableOptimizations = (state: IRootState) =>
  state.environments.tableOptimizations.isFetchingAll;

/**
 * Gets the status of creating an table optimization
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingTableOptimization = (state: IRootState) =>
  state.environments.tableOptimizations.isCreating;

/**
 * Gets the status of deleting a tableOptimization
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingTableOptimization = (state: IRootState) =>
  state.environments.tableOptimizations.isDeleting;
