import _ from "lodash";

import BaseOptionsContainer, {
  IInputOption,
} from "components/Input/BaseOptionsContainer";

export interface IOptionsCell {
  options?: { key: string; label: string }[];
  size?: "small" | "medium";
}

const OptionsCell = ({ options, size }: IOptionsCell) => {
  if (!options || _.isEmpty(options)) {
    return null;
  }

  const nonEmptyValues = _.filter(options, ({ label }) => !!label);
  if (_.isEmpty(nonEmptyValues)) {
    return null;
  }

  return (
    <BaseOptionsContainer
      direction="horizontal"
      wrappable={false}
      size={size}
      options={options as IInputOption<unknown>[]}
    />
  );
};

export const getOptionsFromLabelList = (
  labelList: { key?: string; label: string }[]
) => {
  return _.map(_.compact(labelList), ({ key, label }, index) => ({
    key: key ?? String(index),
    label,
  }));
};

export default OptionsCell;
