import { useState } from "react";

import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles"; // For Material-UI v4
import { ACTION_INCLUDE_TYPE, IActionDetails } from "fieldpro-tools";
import _ from "lodash";

import InputMultipleSelect from "components/Input/InputMultipleSelect";
import { MAIN_DASHBOARD_LIMIT } from "containers/home/MainDashboards";
import { useFormState, useTranslations } from "hooks";
import { IOption } from "model/application/components";

interface IInputAccessRightsSpecificRules {
  actionName: string;
  sectionTitle: string;
  actionDetails?: IActionDetails;
  options: IOption<string>[];
  onChangeSpecificRules: (actionDetails: object, actionCode: string) => void;
}

const InputAccessRightsSpecificRules = (
  props: IInputAccessRightsSpecificRules
) => {
  const lang = useTranslations();
  const theme = useTheme();

  const {
    actionName,
    sectionTitle,
    actionDetails,
    options,
    onChangeSpecificRules,
  } = props;

  const EMPTY_ACTION_DETAILS: IActionDetails = {
    include_type: ACTION_INCLUDE_TYPE.INCLUDE_ALL_OBJECTS,
    ids_to_include_except: [],
    ids_to_exclude_except: [],
  } as IActionDetails;

  const formState = useFormState<IActionDetails>({
    initAttributes: actionDetails ?? EMPTY_ACTION_DETAILS,
  });

  const { handleInputChange, attributes } = formState;
  const {
    include_type: includeType,
    ids_to_exclude_except: idsToExcludeExcept,
    ids_to_include_except: idsToIncludeExcept,
  } = attributes;
  const [isListInclusion, setIsListInclusion] = useState<boolean>(
    includeType != ACTION_INCLUDE_TYPE.EXCLUDE_ALL_OBJECTS_EXCEPT
  );

  const rootLang =
    lang.containers.clients.subCategories.clients.createEditModal
      .inputAccessRightsSpecificRules.createEditModal;
  const inputIncludeTypeLang = rootLang.inputIncludeType.options;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: ACTION_INCLUDE_TYPE = event.target
      .value as ACTION_INCLUDE_TYPE;
    setIsListInclusion(
      newValue != ACTION_INCLUDE_TYPE.EXCLUDE_ALL_OBJECTS_EXCEPT
    );
    onChange(newValue, "include_type");
  };

  const handleSelectChange = (values: IOption<string>[]) => {
    const newValues = _.map(values, (m) => m.key);
    onChange(
      newValues,
      isListInclusion ? "ids_to_include_except" : "ids_to_exclude_except"
    );
  };

  const onChange = (value: any, name: string) => {
    handleInputChange(value, name);
    onChangeSpecificRules({ ...attributes, [name]: value }, actionName);
  };

  return (
    <Box marginLeft="20px" width="100%">
      <RadioGroup
        data-testid="form-radio"
        name="inputIncludeType"
        value={includeType}
        onChange={handleRadioChange}
        row
      >
        {[
          ACTION_INCLUDE_TYPE.INCLUDE_ALL_OBJECTS,
          ACTION_INCLUDE_TYPE.INCLUDE_ALL_OBJECTS_EXCEPT,
          ACTION_INCLUDE_TYPE.EXCLUDE_ALL_OBJECTS_EXCEPT,
        ].map((opt, index) => {
          return (
            <FormControlLabel
              data-testid={`dateIncludeTypeRadio${opt}`}
              key={index}
              label={inputIncludeTypeLang[opt] ?? ""}
              control={
                <Radio
                  size="small"
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "10px",
                    fontWeight: 100,
                    borderRadius: "10%",
                  }}
                />
              }
              value={opt}
              style={{
                marginBottom: "0px",
              }}
            />
          );
        })}
      </RadioGroup>

      {includeType != ACTION_INCLUDE_TYPE.INCLUDE_ALL_OBJECTS && (
        <InputMultipleSelect
          name="inputInclusionList"
          defaultValue={_.filter(options, (option) =>
            (isListInclusion
              ? idsToIncludeExcept ?? []
              : idsToExcludeExcept ?? []
            ).includes(option.value)
          )}
          onChange={handleSelectChange}
          options={options}
          viewMode={"CREATE"}
          lang={lang}
          langlabel={{
            title: `${rootLang.inputInclusionList.title} ${sectionTitle}`,
            tooltip: "",
          }}
          limit={MAIN_DASHBOARD_LIMIT}
          multipleSelection
        />
      )}
    </Box>
  );
};

export default InputAccessRightsSpecificRules;
