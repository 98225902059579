import { createSelector } from "reselect";

import IRootState from "redux/store/model";

export const getIsUpdatingWebhook = (state: IRootState) =>
  state.webhooks.isUpdating;
export const getIsDeletingWebhook = (state: IRootState) =>
  state.webhooks.isDeleting;
export const getIsCreatingWebhook = (state: IRootState) =>
  state.webhooks.isCreating;
export const getIsLoadingWebhooks = (state: IRootState) =>
  state.webhooks.isLoading;

export const getAllWebhooks = (state: IRootState) => state.webhooks.webhooks;
export const allWebhooksSelector = createSelector(
  getAllWebhooks,
  (allWebhooks) => allWebhooks
);
