import { TValidateFunction } from "components/Forms/CreateEditViewForm";

export const getErrorMessagesForEnvVariable: TValidateFunction<any> = ({
  attributes,
  lang,
  additionnalProps,
  viewMode,
}) => {
  const { name, value } = attributes;
  const { secrets } = additionnalProps;
  const result: any = {};
  if (!name || name.length === 0)
    result["name"] = lang.components.inputErrors.empty;
  if (!value || value.length === 0)
    result["value"] = lang.components.inputErrors.empty;
  if (viewMode === "CREATE" && secrets.map((m: any) => m.name).includes(name)) {
    result["name"] = lang.components.inputErrors.alreadyInUse;
  }
  if (Object.keys(result).length === 0) return {};
  return result;
};
