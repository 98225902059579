import * as d3 from "d3-format";

import { AXIS_TYPE, IKPI, KPI_TYPE } from "model/entities/Dashboard";

import { ChartDataUtils } from "./ChartDataUtils";

// Returns axis scale depending on its type
const getScale = (
  axisType: AXIS_TYPE,
  minValue?: number,
  maxValue?: number
) => {
  let scale;
  if (axisType === AXIS_TYPE.TIME) {
    scale = { type: "time" };
  } else if (axisType === AXIS_TYPE.TEXT) {
    scale = { type: "point" };
  } else {
    scale = { type: "linear" };
  }
  Object.assign(scale, { min: minValue, max: maxValue });
  return scale;
};

// Returns axis format depending on its type
const formatAxisValue = (
  value: any,
  axisType: AXIS_TYPE,
  formatPattern?: string,
  symbol?: string,
  chart?: IKPI
) => {
  if (axisType === AXIS_TYPE.TIME) {
    const pattern = formatPattern ? formatPattern : "mmm-dd";
    return ChartDataUtils.formatValue(value, pattern);
  } else if (
    chart &&
    chart.type != KPI_TYPE.BAR_CHART &&
    chart.value_format &&
    chart.value_format.enabled
  ) {
    return d3.format(chart.value_format.format)(value);
  } else {
    return `${ChartDataUtils.formatValue(value)} ${symbol ? symbol : ""}`;
  }
};
// Returns axis configuration depending on its type
const getAxisConfiguration = (axisType: AXIS_TYPE) => {
  let conf;
  switch (axisType) {
    case AXIS_TYPE.TIME: {
      conf = {
        format: "%b %d",
        tickValues: "every day",
      };
      break;
    }
    default: {
      conf = {};
    }
  }
  return conf;
};
export default { formatAxisValue, getScale, getAxisConfiguration };
