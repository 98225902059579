// LISTS

// select list
export const SELECT_LIST = "SELECT_LIST";

//create action types for lists
export const CREATE_LIST_BEGIN = "CREATE_LIST_BEGIN";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAILURE = "CREATE_LIST_FAILURE";

// action types for fetching lists for a single client
export const FETCH_LISTS_FOR_CLIENT_BEGIN = "FETCH_LISTS_FOR_CLIENT_BEGIN";
export const FETCH_LISTS_FOR_CLIENT_SUCCESS = "FETCH_LISTS_FOR_CLIENT_SUCCESS";
export const FETCH_LISTS_FOR_CLIENT_FAILURE = "FETCH_LISTS_FOR_CLIENT_FAILURE";

// action types for fetching a single list by id
export const FETCH_LIST_BY_ID_BEGIN = "FETCH_LIST_BY_ID_BEGIN";
export const FETCH_LIST_BY_ID_SUCCESS = "FETCH_LIST_BY_ID_SUCCESS";
export const FETCH_LIST_BY_ID_FAILURE = "FETCH_LIST_BY_ID_FAILURE";

// update action types for list
export const UPDATE_LIST_BEGIN = "UPDATE_LIST_BEGIN";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";
export const UPDATE_LIST_FAILURE = "UPDATE_LIST_FAILURE";

// delete action types for list
export const DELETE_LIST_BEGIN = "DELETE_LIST_BEGIN";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
export const DELETE_LIST_FAILURE = "DELETE_LIST_FAILURE";

// archive action types for list
export const ARCHIVE_LIST_BEGIN = "ARCHIVE_LIST_BEGIN";
export const ARCHIVE_LIST_SUCCESS = "ARCHIVE_LIST_SUCCESS";
export const ARCHIVE_LIST_FAILURE = "ARCHIVE_LIST_FAILURE";

// restore action types for list
export const RESTORE_LIST_BEGIN = "RESTORE_LIST_BEGIN";
export const RESTORE_LIST_SUCCESS = "RESTORE_LIST_SUCCESS";
export const RESTORE_LIST_FAILURE = "RESTORE_LIST_FAILURE";

// ITEMS

// upload a file (like a picture)
export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

// insert items action types for an object in a list
export const CREATE_ITEMS_BEGIN = "CREATE_ITEMS_BEGIN";
export const CREATE_ITEMS_FAILURE = "CREATE_ITEMS_FAILURE";
export const CREATE_ITEMS_SUCCESS = "CREATE_ITEMS_SUCCESS";

// action types for fetching objects for a given list
export const FETCH_ITEMS_BEGIN = "FETCH_ITEMS_BEGIN";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";

// action types for fetching one object for a given list
export const FETCH_ITEM_BEGIN = "FETCH_ITEM_BEGIN";

// delete action types for objects from a list
export const DELETE_ITEMS_BEGIN = "DELETE_ITEMS_BEGIN";
export const DELETE_ITEMS_SUCCESS = "DELETE_ITEMS_SUCCESS";
export const DELETE_ITEMS_FAILURE = "DELETE_ITEMS_FAILURE";

// archive action types for objects from a list
export const ARCHIVE_ITEMS_BEGIN = "ARCHIVE_ITEMS_BEGIN";
export const ARCHIVE_ITEMS_SUCCESS = "ARCHIVE_ITEMS_SUCCESS";
export const ARCHIVE_ITEMS_FAILURE = "ARCHIVE_ITEMS_FAILURE";

// restore action types for objects from a list
export const RESTORE_ITEMS_BEGIN = "RESTORE_ITEMS_BEGIN";
export const RESTORE_ITEMS_SUCCESS = "RESTORE_ITEMS_SUCCESS";
export const RESTORE_ITEMS_FAILURE = "RESTORE_ITEMS_FAILURE";

// update action types for object from a list
export const UPDATE_ITEMS_BEGIN = "UPDATE_ITEMS_BEGIN";
export const UPDATE_ITEMS_SUCCESS = "UPDATE_ITEMS_SUCCESS";
export const UPDATE_ITEMS_FAILURE = "UPDATE_ITEMS_FAILURE";

// assign items to owners
export const ASSIGN_ITEMS_BEGIN = "ASSIGN_ITEMS_BEGIN";
export const ASSIGN_ITEMS_SUCCESS = "ASSIGN_ITEMS_SUCCESS";
export const ASSIGN_ITEMS_FAILURE = "ASSIGN_ITEMS_FAILURE";

// unaassign items to owners
export const UNASSIGN_ITEMS_BEGIN = "UNASSIGN_ITEMS_BEGIN";
export const UNASSIGN_ITEMS_SUCCESS = "UNASSIGN_ITEMS_SUCCESS";
export const UNASSIGN_ITEMS_USING_LINKS = "UNASSIGN_ITEMS_USING_LINKS";
export const UNASSIGN_ITEMS_FAILURE = "UNASSIGN_ITEMS_FAILURE";

// download list items
export const DOWNLOAD_LIST_ITEM_BEGIN = "DOWNLOAD_LIST_ITEM_BEGIN";
export const DOWNLOAD_LIST_ITEM_SUCCESS = "DOWNLOAD_LIST_ITEM_SUCCESS";
export const DOWNLOAD_LIST_ITEM_FAILURE = "DOWNLOAD_LIST_ITEM_FAILURE";

// action types for fetching objects for a given list
export const FETCH_OPTIONS_BEGIN = "FETCH_OPTIONS_BEGIN";
export const FETCH_OPTIONS_SUCCESS = "FETCH_OPTIONS_SUCCESS";
export const FETCH_OPTIONS_FAILURE = "FETCH_OPTIONS_FAILURE";

// action types for fetching a customer's reports pictures
export const FETCH_CUSTOMER_REPORTS_PICTURES_BEGIN =
  "FETCH_CUSTOMER_REPORTS_PICTURES_BEGIN";
export const FETCH_CUSTOMER_REPORTS_PICTURES_SUCCESS =
  "FETCH_CUSTOMER_REPORTS_PICTURES_SUCCESS";
export const FETCH_CUSTOMER_REPORTS_PICTURES_FAILURE =
  "FETCH_CUSTOMER_REPORTS_PICTURES_FAILURE";

// change the filter for the display of the items
export const CHANGE_LIST_FILTER_SUCCESS = "CHANGE_LIST_FILTER_SUCCESS";

export const CHANGE_SUBCATEGORY_SELECTED_SUCCESS =
  "CHANGE_LIST_SUBCATEGORY_SELECTED_SUCCESS";

export const CLEAR_DATA = "CLEAR_DATA";

export const CHANGE_LIST_ATTRIBUTE_TAG_FAILURE =
  "CHANGE_LIST_ATTRIBUTE_TAG_FAILURE";
export const CHANGE_LIST_ATTRIBUTE_TAG_SUCCESS =
  "CHANGE_LIST_ATTRIBUTE_TAG_SUCCESS";
export const CHANGE_LIST_ATTRIBUTE_TAG_BEGIN =
  "CHANGE_LIST_ATTRIBUTE_TAG_BEGIN";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";

export const ADD_ITEMS_TO_LIST = "ADD_ITEMS_TO_LIST";
