import { useState } from "react";

import { Box, Tooltip } from "@material-ui/core";

import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import BasicLoadingIcon from "components/Dialog/BasicDialog/Icons/BasicLoadingIcon";
import BasicShareIcon from "components/Dialog/BasicDialog/Icons/BasicShareIcon";
import InputText from "components/Input/InputText/InputText";
import CustomSnackbar, {
  TSnackbarType,
} from "components/Snackbars/CustomSnackbar";
import NOTIFICATION_ACTION_LEVEL from "containers/notifications/actionLevels";
import { showNotificationAction } from "containers/notifications/actions";
import NOTIFICATION_ACTION_TYPE from "containers/notifications/actionTypes";
import { getActivityReportPdfUrlAction } from "containers/workflows/redux/actions";
import useActions from "hooks/useActions";
import useTranslations from "hooks/useTranslations";

interface IActivityReportShareButton {
  activityId: string;
  reportId: string;
}

const ActivityReportShareButton = ({
  activityId,
  reportId,
}: IActivityReportShareButton) => {
  const lang = useTranslations();
  const sharePdfLang =
    lang.containers.workflows.subCategories.activityReports.sharePdfButton;

  const [savedUrl, setSavedUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    type: TSnackbarType;
  }>();

  const showNotification = useActions(showNotificationAction);
  const getActivityReportPdfUrl = useActions(getActivityReportPdfUrlAction);

  const onShare = async () => {
    try {
      let pdfUrl = savedUrl;

      if (!pdfUrl) {
        setIsLoading(true);

        setSnackbar({
          message: sharePdfLang.loadingMessage,
          type: "loading",
        });

        pdfUrl = await getActivityReportPdfUrl({ activityId, reportId });
        setSavedUrl(pdfUrl);
      }

      try {
        await copyToClipBoard(pdfUrl);

        // Notif is a bit overwhelming, on top of modal..
        //   showNotification({
        //     type: NOTIFICATION_ACTION_TYPE.SUCCESS,
        //     level: NOTIFICATION_ACTION_LEVEL.SUCCESS,
        //     message:
        //       lang.notifications.successNotifications.copyInClipboardSuccess,
        //   })
      } catch (e) {
        // Do nothing, can error for example if tab is not focused
        // The user should still be able to copy link in the modal
        console.error(e);
      }

      setDialogOpen(true);
    } catch (e) {
      console.error(e);

      await showNotification({
        type: NOTIFICATION_ACTION_TYPE.ERROR,
        level: NOTIFICATION_ACTION_LEVEL.ERROR,
        message: lang.notifications.errorNotifications.copyInClipboardError,
      });
    }

    setSnackbar(undefined);
    setIsLoading(false);
  };

  const copyToClipBoard = async (text: string) => {
    return navigator.clipboard.writeText(text);
  };

  return (
    <>
      {isLoading ? (
        <BasicLoadingIcon data-testid="loading-icon" />
      ) : (
        <Tooltip title={sharePdfLang.tooltip}>
          <span /** do NOT remove me, Tooltip passes down a ref */>
            <BasicShareIcon onClick={onShare} />
          </span>
        </Tooltip>
      )}

      {snackbar && (
        <CustomSnackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar(undefined)}
        />
      )}

      {dialogOpen && (
        <CopyLinkDialog
          open
          onClose={() => setDialogOpen(false)}
          url={savedUrl}
        />
      )}
    </>
  );
};

interface ICopyLinkDialog {
  url?: string;
  open: boolean;
  onClose: () => void;
}

const CopyLinkDialog = ({ url, open, onClose }: ICopyLinkDialog) => {
  const lang = useTranslations();
  const sharePdfLang =
    lang.containers.workflows.subCategories.activityReports.sharePdfButton;

  return (
    <BasicDialog open={open} onClose={onClose} minWidth={600} maxWidth={600}>
      <BasicDialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <BasicCloseIcon onClick={onClose} />
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent>
        {sharePdfLang.modal.description}

        <InputText
          name="pdf-url"
          onChange={() => {}}
          defaultValue={url}
          autoFocus
          onFocusCapture={(event) => {
            (event.target as HTMLInputElement).select();
          }}
        />
      </BasicDialogContent>
    </BasicDialog>
  );
};

export default ActivityReportShareButton;
