import { moveArchivedDashboardsToDefaultFolderSuccessActionCreator } from "containers/dashboards/redux/actionCreators";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IFolder } from "model/entities/Client";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import { getLang } from "../../authentication/redux/selector";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import {
  archiveFolderBeginActionCreator,
  archiveFolderFailureActionCreator,
  archiveFolderSuccessActionCreator,
  createFolderBeginActionCreator,
  createFolderFailureActionCreator,
  createFolderSuccessActionCreator,
  deleteFolderBeginActionCreator,
  deleteFolderFailureActionCreator,
  deleteFolderSuccessActionCreator,
  fetchFoldersBeginActionCreator,
  fetchFoldersFailureActionCreator,
  fetchFoldersSuccessActionCreator,
  restoreFolderBeginActionCreator,
  restoreFolderFailureActionCreator,
  restoreFolderSuccessActionCreator,
  updateFolderBeginActionCreator,
  updateFolderFailureActionCreator,
  updateFolderSuccessActionCreator,
} from "./actionCreators";
import {
  archiveFolderApiCall,
  createFolderApiCall,
  deleteFolderApiCall,
  fetchFoldersApiCall,
  restoreFolderApiCall,
  TCreateFolderResponse,
  TFetchFoldersResponse,
  updateFolderApiCall,
} from "./api";

type ICreateFolderActionFunc = (
  actionName: string,
  newFolder: IFolder
) => IDispatchAndGetState<void>;
/**
 * Create Folder Action dispatches action creators to redux store and makes api calls to create a folder
 * @param {String} name Name of Folder
 * @param {String} description Description of folder
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export const createFolderAction: ICreateFolderActionFunc = (
  actionName: string,
  newFolder: IFolder
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createFolderBeginActionCreator());
    return new Promise(function (resolve, reject) {
      delete (newFolder as any)?.id;
      createFolderApiCall(actionName, newFolder)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TCreateFolderResponse>(
              serverResponse
            );
          const { id } = data;
          const folder = {
            ...newFolder,
            id,
          };

          dispatch(ajaxSuccessAction());
          dispatch(createFolderSuccessActionCreator(folder));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.FOLDER,
                newFolder.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateFolderError",
            error,
            createFolderFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

type IUpdateFolderActionFunc = (
  actionName: string,
  id: string,
  updatedFolder: IFolder
) => IDispatchAndGetState<void>;
/**
 * Updates a given Folder Action dispatches action creators to redux store and makes api calls to fetch all license
 * @param {String} id Id of folder to update
 * @param {Object} updatedFolder details of the updated folder
 * */
export const updateFolderAction: IUpdateFolderActionFunc = (
  actionName: string,
  id: string,
  updatedFolder: IFolder
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateFolderBeginActionCreator());
    const id = updatedFolder?.id;
    delete (updatedFolder as any)?.id;
    return new Promise(function (resolve, reject) {
      updateFolderApiCall(actionName, id, updatedFolder)
        .then((serverResponse) => {
          const res = extractDataAndCheckErrorStatus(serverResponse);
          const folder = {
            ...updatedFolder,
            id,
          };
          dispatch(ajaxSuccessAction());
          dispatch(updateFolderSuccessActionCreator(folder));
          dispatch(
            moveArchivedDashboardsToDefaultFolderSuccessActionCreator(
              res.archivedDashboardsIds
            )
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.FOLDER,
                updatedFolder.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateFolderError",
            error,
            updateFolderFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};
type IDeleteFolderActionFunc = (
  actionName: string,
  id: string,
  name: string
) => IDispatchAndGetState<void>;
/**
 * Delete Folder Action dispatches action creators to redux store and makes api calls to delete a client by id
 * @param {String} id Folder id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteFolderAction: IDeleteFolderActionFunc = (
  actionName: string,
  id: string,
  name: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteFolderBeginActionCreator());

    return deleteFolderApiCall(actionName, id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteFolderSuccessActionCreator(id));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.FOLDER,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteFolderError",
          error,
          deleteFolderFailureActionCreator,
          currLang
        );
      });
  };
};
type IFetchFoldersActionFunc = () => IDispatchAndGetState<IFolder[]>;
export type IArchiveFolderActionFunc = (
  actionName: string,
  foldersIds: string[]
) => IDispatchAndGetState<void>;
export type IRestoreFolderActionFunc = (
  actionName: string,
  foldersIds: string[]
) => IDispatchAndGetState<void>;
/**
 * Fetch all Clients Action dispatches action creators to redux store and makes api calls to fetch all
 * client accounts
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const fetchFoldersAction: IFetchFoldersActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchFoldersBeginActionCreator());
    return new Promise(function (resolve, reject) {
      fetchFoldersApiCall()
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TFetchFoldersResponse>(
              serverResponse
            );
          const { folders } = data;
          dispatch(ajaxSuccessAction());
          dispatch(fetchFoldersSuccessActionCreator(folders));
          resolve(folders);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllClientsError",
            error,
            fetchFoldersFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export const archiveFoldersAction: IArchiveFolderActionFunc = (
  actionName: string,
  foldersIds
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveFolderBeginActionCreator());

    return archiveFolderApiCall(actionName, foldersIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveFolderSuccessActionCreator(foldersIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.FOLDER
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveFolderError",
          error,
          archiveFolderFailureActionCreator,
          currLang
        );
      });
  };
};

export const restoreFoldersAction: IRestoreFolderActionFunc = (
  actionName: string,
  foldersIds
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreFolderBeginActionCreator());

    return restoreFolderApiCall(actionName, foldersIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreFolderSuccessActionCreator(foldersIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.FOLDER
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreFoldersError",
          error,
          restoreFolderFailureActionCreator,
          currLang
        );
      });
  };
};

export interface IFoldersActions {
  fetchFoldersAction: IFetchFoldersActionFunc;
  createFolderAction: ICreateFolderActionFunc;
  updateFolderAction: IUpdateFolderActionFunc;
  deleteFolderAction: IDeleteFolderActionFunc;
  archiveFoldersAction: IArchiveFolderActionFunc;
  restoreFoldersAction: IRestoreFolderActionFunc;
}

const folderActions: IFoldersActions = {
  fetchFoldersAction,
  createFolderAction,
  updateFolderAction,
  deleteFolderAction,
  archiveFoldersAction,
  restoreFoldersAction,
};

export default folderActions;
