import React, { useState } from "react";

import { Divider, Fab, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ITableRowContent } from "model/application/components";
import TLang from "model/application/Lang";

import styles from "../styles";
const useStyles = makeStyles(styles as any);

export interface IMoreButtonOptions {
  additionnalInfos?: any;
  disabled?: (items: any[], additionnalInfos: any) => boolean;
  action: (id?: string) => void;
  value?: string;
  checked?: boolean;
  label: string;
}

export interface ICustomMoreButtonProps {
  options: IMoreButtonOptions[];
  selectedItems?: ITableRowContent[];
  onAction?: (action: (id?: string) => void) => void;
  onSelectDeselectOption?: (
    value: string | undefined,
    checked: boolean
  ) => void;
  icon?: any;
  isTableAction?: boolean;
  isHelpButton?: boolean;
  lang?: TLang;
}

export const CustomMoreButton: React.FunctionComponent<
  ICustomMoreButtonProps
> = ({
  options,
  selectedItems,
  onAction,
  onSelectDeselectOption,
  icon,
  isTableAction,
  isHelpButton,
  lang,
}) => {
  // bind the style
  const classes = useStyles();
  // declare state attributes
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleSelect = (option: IMoreButtonOptions) => () => {
    // if option has attribute "checked"
    if (option.hasOwnProperty("checked") && onSelectDeselectOption) {
      onSelectDeselectOption(option.value, !option.checked);
    }
    // if option has attribute "action"
    if (option.hasOwnProperty("action")) {
      if (onAction != null) {
        onAction(option.action);
      } else {
        option.action();
      }
    }
    handleClose();
  };

  const realIcon = icon ? icon : <MoreVertIcon aria-label="more-icon-label" />;
  return (
    <div
      className={`${
        isTableAction
          ? classes.ButtonContainerNoPadding
          : classes.ButtonContainer
      }`}
    >
      <Fab
        variant={isHelpButton ? "extended" : "circular"}
        size="small"
        color="primary"
        aria-label={"More"}
        style={isHelpButton ? { margin: "4px" } : {}}
        className={
          isTableAction ? classes.TableActionButton : classes.ActionButton
        }
        onClick={handleClick}
      >
        {realIcon}
        {lang && isHelpButton ? lang.genericTerms.help : ""}
      </Fab>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow:
              isHelpButton ||
              (icon &&
                (icon.props["aria-label"] === "AccountCircle" ||
                  icon.props.src))
                ? "visible"
                : "scroll",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((option, idx) =>
          (idx === 1 || idx === 2 || idx === 5) &&
          icon &&
          (icon.props["aria-label"] === "AccountCircle" || icon.props.src) ? (
            [
              <MenuItem
                className={classes.MenuItemHelpAndIdButton}
                key={`option.label-${idx}`}
                onClick={handleSelect(option)}
                disabled={
                  option.disabled
                    ? option.disabled(
                        selectedItems ?? [],
                        option.additionnalInfos
                      )
                    : false
                }
              >
                {option.label}
                {idx === 1 ? (
                  <ErrorOutlineIcon className={classes.ErrorOutlineIcon} />
                ) : idx === 5 ? (
                  <LogoutIcon style={{ fontSize: "1rem", margin: "5px" }} />
                ) : (
                  ""
                )}
              </MenuItem>,
              ...(idx === 2 ? [<Divider key={idx} />] : []),
            ]
          ) : (
            <MenuItem
              key={`option.label-${idx}`}
              className={
                isHelpButton ||
                (icon &&
                  (icon.props["aria-label"] === "AccountCircle" ||
                    icon.props.src))
                  ? classes.MenuItemHelpAndIdButton
                  : ""
              }
              onClick={handleSelect(option)}
              disabled={
                option.disabled
                  ? option.disabled(
                      selectedItems ?? [],
                      option.additionnalInfos
                    )
                  : false
              }
            >
              {option.hasOwnProperty("checked") ? (
                option.checked ? (
                  <CheckIcon aria-label="CheckIcon" />
                ) : null
              ) : null}
              {idx === 0 &&
              icon &&
              (icon.props["aria-label"] === "AccountCircle" ||
                icon.props.src) ? (
                <strong>{option.label}</strong>
              ) : (
                option.label
              )}
              {isHelpButton ? (
                <LaunchIcon style={{ fontSize: "1rem", margin: "5px" }} />
              ) : (
                ""
              )}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default CustomMoreButton;
