import { ReactNode } from "react";

import { makeStyles, Paper } from "@material-ui/core";

const styles = {
  FormSectionBackground: {
    padding: "8px 15px 20px",
    marginBottom: "24px",
  },
};

const useStyles = makeStyles(styles);

interface IFormSectionBackground {
  children?: ReactNode;
}

const FormSectionBackground = ({ children }: IFormSectionBackground) => {
  const classes = useStyles();
  return (
    <Paper className={classes.FormSectionBackground} elevation={0}>
      {children}
    </Paper>
  );
};

export default FormSectionBackground;
