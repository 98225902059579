//////// ACTIONS VISIBILITY FUNCTIONS ////////

import { IGroupInTable,ITeamInTable } from "model/entities/Team";

/**
 * The bulk delete button is deactivated when:
 * - none teams are selected
 */
const isDeleteTeamsDisabled = (
  teamsSelected: ITeamInTable[] | IGroupInTable[]
) => {
  if (teamsSelected.length === 0) {
    return true;
  }
  return false;
};

/**
 * The bulk archive button is deactivated when:
 * - none teams are selected
 */
const isArchiveTeamsDisabled = (
  teamsSelected: ITeamInTable[] | IGroupInTable[]
) => {
  if (teamsSelected.length === 0) {
    return true;
  }
  return false;
};

/**
 * The bulk restore button is deactivated when:
 * - none teams are selected
 */
const isRestoreTeamsDisabled = (
  teamsSelected: ITeamInTable[] | IGroupInTable[]
) => {
  if (teamsSelected.length === 0) {
    return true;
  }
  return false;
};

export {
  isArchiveTeamsDisabled,
  isDeleteTeamsDisabled,
  isRestoreTeamsDisabled,
};
