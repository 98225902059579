import React, { ComponentProps, JSXElementConstructor, useState } from "react";

import _ from "lodash";

import ScrollEdgeListener from "components/ScrollEdgeListener";

type TDynamicallyRenderedMenuList<T extends JSXElementConstructor<any>> = {
  buildMenuItemFunction: (index: number) => React.ReactNode;
  listSize: number;
  maxItems: number;
  Component: T;
} & ComponentProps<T>;

/* ----------------------- DynamicallyRenderedMenuList ----------------------- */
// A very fast and efficient way to render a large list of items in a scrollable container.
function DynamicallyRenderedMenuList<T>({
  Component,
  buildMenuItemFunction,
  listSize,
  maxItems,
  ...props
}: TDynamicallyRenderedMenuList<
  T extends React.JSXElementConstructor<any> ? T : any
>) {
  const [itemsToShow, setItemsToShow] = useState(Math.min(listSize, maxItems));

  return (
    <Component {...props}>
      {_.times(itemsToShow, (index) => buildMenuItemFunction(index))}
      <ScrollEdgeListener
        callback={() => {
          if (itemsToShow < listSize) {
            setItemsToShow(Math.min(itemsToShow + maxItems, listSize));
          }
        }}
      />
    </Component>
  );
}

export default DynamicallyRenderedMenuList;
