import { combineReducers } from "redux";

import http_requests, { IHttpRequestState } from "./http_requests/reducer";
import initialState from "./initialState";
import integrations, { IIntegrationState } from "./integrations/reducer";
import notifications, { INotificationState } from "./notifications/reducer";
import scripts, { IJobScriptState } from "./scripts/reducer";
import streams, { IStreamState } from "./streams/reducer";
import transformations, {
  ITransformationState,
} from "./transformations/reducer";

export interface JobsState {
  transformations: ITransformationState;
  notifications: INotificationState;
  scripts: IJobScriptState;
  streams: IStreamState;
  integrations: IIntegrationState;
  http_requests: IHttpRequestState;
  errors: any;
}

function errors(state = initialState) {
  return state;
}

export default combineReducers({
  notifications,
  http_requests,
  transformations,
  integrations,
  streams,
  scripts,
  errors,
});
