import { useRef } from "react";

import { Box } from "@material-ui/core";
import QRcode from "qrcode.react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Black,
  darkGraySemiTransparent,
  defaultBackground,
  White,
} from "assets/colors";
import { BasicDialog } from "components/Dialog/BasicDialog";
import { getSelectedClient } from "containers/clients/redux/selectors";

import optimetriks_logo from "../../../../assets/images/svg/fieldProLogoWithName.svg";

const ItemQRCodeDialog = () => {
  const params = useParams();

  const { item_id, item_name } = params as any;
  const selectedClient = useSelector(getSelectedClient);
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    gap: "32px",
    top: "206px",
    background: White,
    borderRadius: "4px",
  };

  const headerStyle = {
    width: "100%",
    marginTop: "-20px",
    display: "flex",
    flexDirection: "column",
  };

  const bodyStyle = {
    width: "100%",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  };

  const modalRef = useRef(null);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: darkGraySemiTransparent,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BasicDialog
        open
        minWidth={"366px"}
        maxWidth={"366px"}
        maxHeight={"540px"}
        minHeight={"540px"}
      >
        <div ref={modalRef}>
          <Box style={boxStyle as any}>
            {/* HEADER  */}
            <div style={headerStyle as any}>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <span>
                    <img
                      key={selectedClient.id}
                      src={optimetriks_logo}
                      alt="logo optimetriks"
                      style={{
                        width: "120px",
                        height: "120px",
                      }}
                    />
                  </span>
                </div>
                {selectedClient.logo && (
                  <div style={{ width: "20%" }}>
                    <span
                      style={{
                        fontSize: "28px",
                        fontWeight: 500,
                        lineHeight: "32px",
                      }}
                    >
                      |
                    </span>
                  </div>
                )}
                <div style={{ width: "40%", textAlign: "end" }}>
                  <span>
                    {selectedClient?.logo && (
                      <img
                        key={selectedClient.id}
                        alt="logo client"
                        src={selectedClient.logo}
                        style={{
                          width: "60px",
                          height: "60px",
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  fontSize: "24px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 800,
                  lineHeight: "32px",
                  color: "#2c2c2c",
                  background: "#fff",
                  textAlign: "left",
                  marginTop: "-20px",
                }}
              >
                {item_name}
              </div>
            </div>

            {/* BODY */}
            <div style={bodyStyle as any}>
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    borderRadius: "10px",
                    border: `10px solid ${Black}`,
                    marginTop: "5px",
                    marginRight: "30px",
                    marginLeft: "30px",
                    textAlign: "center",
                  }}
                >
                  <QRcode
                    id={item_id}
                    value={item_id}
                    size={220}
                    includeMargin={true}
                    color={Black}
                    data-testid="myQr"
                  />
                </div>
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                  color: Black,
                  background: defaultBackground,
                  padding: "5px",
                  textAlign: "left",
                }}
              >
                {item_id}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                  color: Black,
                  background: defaultBackground,
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                fieldproapp.com
              </div>
            </div>
          </Box>
        </div>
      </BasicDialog>
    </div>
  );
};

export default ItemQRCodeDialog;
