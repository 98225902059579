import { OPTIMETRIKS_ROLES } from "fieldpro-tools";
import _ from "lodash";

const OPTIMETRIKS_ADMIN_ROLES = [
  OPTIMETRIKS_ROLES.ADMIN,
  OPTIMETRIKS_ROLES.SUPERADMIN,
];

const OPTIMETRIKS_ROLES_ARRAY = _.values(OPTIMETRIKS_ROLES);

export const isOptimetriksAdminRole = (role?: string) => {
  return _.includes(OPTIMETRIKS_ADMIN_ROLES, role);
};

export const isOptimetriksRole = (role?: string) => {
  return _.includes(OPTIMETRIKS_ROLES_ARRAY, role);
};

export const isClientAdminRole = (role?: string) => {
  return role === "admin";
};
