import { Component } from "react";

import { Box } from "@material-ui/core";
import {
  ACTION_CODE,
  getActionCodesForCategory,
  IActionDetails,
} from "fieldpro-tools";
import _ from "lodash";

import { Black } from "assets/colors";
import { getErrorFromAttribute } from "components/Input/InputMultipleCreate/InputMultipleCreate";
import ToggleSwitch from "components/Switch/ToggleSwitch";
import {
  convertAccessRightProfileArrayToBoolean,
  convertAccessRightProfilesToOption,
} from "containers/clients/components/ClientForm/Tabs/UsersTab/utils/convertAccessRightProfilToOption";
import { ACTION_TYPE, OBJECT_CATEGORY } from "model/application/ActionCode";
import TLang, { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import { IAccessRightProfile } from "model/entities/Client";

import InputText from "../../../../../../components/Input/InputText/InputText";
import DisplayProfileActions from "./DisplayProfileActions";

const ACCESS_RIGHT_OBJECTS = [
  OBJECT_CATEGORY.MOB_USER,
  OBJECT_CATEGORY.WEB_USER,
  OBJECT_CATEGORY.TEAM,
  OBJECT_CATEGORY.WORKFLOW,
  OBJECT_CATEGORY.ACTIVITY_REPORT,
  OBJECT_CATEGORY.LIST,
  OBJECT_CATEGORY.ITEM,
  OBJECT_CATEGORY.DASHBOARD,
  OBJECT_CATEGORY.DOCUMENT,
  OBJECT_CATEGORY.TERRITORY,
  OBJECT_CATEGORY.CLIENT_SETTINGS,
  OBJECT_CATEGORY.CALENDAR_EVENT,
  OBJECT_CATEGORY.QUERY,
];

export interface IUserProfileFormModalProps {
  onChange: (e: IAccessRightProfile) => void;
  element: IAccessRightProfile;
  lang: TLang;
  role: string;
  viewMode: TViewMode;
}

class UserProfileFormModal extends Component<
  IUserProfileFormModalProps,
  IAccessRightProfile
> {
  constructor(props: IUserProfileFormModalProps) {
    super(props);
    const { element } = props;
    this.state = {
      ...element,
      actions: convertAccessRightProfileArrayToBoolean(element.actions), // Make sure that all actions are boolean,
      action_details: element.action_details,
    };
  }

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  handleInputChange = (value: any, name: string) => {
    const { onChange } = this.props;
    this.setState((oldState) => {
      let newState = oldState;
      if (name === "name") {
        newState = { ...oldState, name: value as string };
      }
      if (name === "access_all_resources") {
        newState = { ...oldState, access_all_resources: value as boolean };
      }
      if (name === "FETCH_CLIENT_SETTINGS") {
        const oldActions = oldState.actions;
        const newActions = {
          ...oldActions,
          [ACTION_CODE.FETCH_CLIENT_SETTINGS]: value as boolean,
        };
        newState = { ...oldState, actions: newActions };
      }
      onChange(newState);
      return newState;
    });
  };

  /**
   * Handles the switch control component
   * @param {Boolean} isOn Boolean value indicating whether the switch is on or off
   */
  handleSwitchChanged = (value: any, name: string) => {
    const { actions } = this.state;

    const actionsClone = {
      ...actions,
      [name]: !_.isEmpty(value),
      [`${name}S`]: !_.isEmpty(value),
    };

    //Unchecked all access right profiles actions if the FETCH action is false
    if (_.startsWith(name, ACTION_TYPE.FETCH) && _.isEmpty(value)) {
      // if turn off the fetch, turn off all the actions of this catagory
      const category = _.split(name, `${ACTION_TYPE.FETCH}_`)[1];
      for (const action in actionsClone) {
        if (action.includes(category)) {
          actionsClone[action] = false;
        }
      }
    }

    const newState = { ...this.state, actions: actionsClone };
    this.props.onChange(newState);
    this.setState(newState);
  };

  handleSpecificRulesChanged = (value: IActionDetails, name: string) => {
    const { action_details } = this.state;
    const actionDetails = { ...action_details }; //Clone action details
    actionDetails[name] = value;
    const newState = { ...this.state, action_details: actionDetails };
    this.props.onChange(newState);
    this.setState(newState);
  };

  render() {
    const { name, access_all_resources } = this.state;
    const { lang, role, viewMode } = this.props;

    const labelToDisplay =
      lang.containers.clients.subCategories.clients.createEditModal.inputProfile
        .createEditModal;

    const clientManagementLang =
      lang.containers.clients.subCategories.clients.createEditModal
        .clientManagement;

    return (
      <Box paddingBottom="24px">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: "100px",
            alignItems: "center",
          }}
        >
          {/* NAME  */}
          <InputText
            error={getErrorFromAttribute(this.state, "name")}
            name="name"
            lang={labelToDisplay.inputName}
            onChange={this.handleInputChange}
            defaultValue={name}
            required={true}
            debounceDuration={0}
            disabled={viewMode === "EDIT" ? true : false}
          />

          {/* ACCESS RIGHTS */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "7px",
            }}
          >
            <AccessRightToggle
              name="access_all_resources"
              title={labelToDisplay.inputAccessAllResources.title}
              onChange={(e: any) =>
                this.handleInputChange(e.target.checked, "access_all_resources")
              }
              defaultChecked={access_all_resources}
            />
          </div>
        </div>

        {_.map(ACCESS_RIGHT_OBJECTS, (accessRight) => {
          return this.buildAccessRightsForCategory(
            accessRight,
            role,
            clientManagementLang
          );
        })}
      </Box>
    );
  }

  buildAccessRightsForCategory = (
    category: OBJECT_CATEGORY,
    role: string,
    lang: TInputAttributeLang
  ) => {
    const getActionCodes = (category: OBJECT_CATEGORY) => {
      let actionsCode = getActionCodesForCategory(category);
      if (!isOptimetriksAdminRole(role)) {
        actionsCode = _.without(
          actionsCode,
          ACTION_CODE.CREATE_DASHBOARD,
          ACTION_CODE.EDIT_DASHBOARD
        );
      }
      return actionsCode;
    };
    const getActionDetails = (
      actionsCode: string[],
      actionDetails?: object
    ) => {
      if (!actionDetails) return [];
      return Object.keys(actionDetails)
        .filter((key) => actionsCode.includes(key))
        .reduce((acc, key) => {
          acc[key] = actionDetails[key];
          return acc;
        }, {});
    };

    const { actions, action_details } = this.state;
    const categoryInDisplay: string = lang.options[category] ?? "";
    const actionsCode = getActionCodes(category);
    const actionDetails = getActionDetails(actionsCode, action_details);

    const actionsFormatted = {
      ...this.state.actions,
      ...convertAccessRightProfilesToOption(actions),
    };

    const isActionCodeFetchable = (actionCode: string) => {
      if (!_.startsWith(actionCode, ACTION_TYPE.FETCH))
        return !!actionsFormatted[actionCode];

      const valueWithoutS = _.isArray(actionsFormatted[actionCode])
        ? actionsFormatted[actionCode]
        : undefined;

      const valueWithS = _.isArray(actionsFormatted[actionCode + "S"])
        ? actionsFormatted[actionCode + "S"]
        : undefined;

      const isActionCodeRight = () => {
        return (
          (_.isArray(valueWithoutS) && _.size(valueWithoutS)) ||
          (_.isArray(valueWithS) && _.size(valueWithS))
        );
      };
      if (isActionCodeRight()) return true;
      return false;
    };

    const getParentChildOptions = () => {
      if (category === OBJECT_CATEGORY.QUERY) {
        return {
          parent: _.find(
            actionsCode,
            (act) => !_.startsWith(act, ACTION_TYPE.FETCH)
          ),
          children: [],
          isExtend: true,
        };
      }

      return {
        parent: _.find(actionsCode, (act) =>
          _.startsWith(act, ACTION_TYPE.FETCH)
        ),
        children: _.filter(
          actionsCode,
          (act) => !_.startsWith(act, ACTION_TYPE.FETCH)
        ),
        isExtend: isActionCodeFetchable(`${ACTION_TYPE.FETCH}_${category}`),
      };
    };

    const { parent, children, isExtend } = getParentChildOptions();

    return (
      <DisplayProfileActions
        key={category}
        fatherOptionIsChecked={isExtend}
        childOptions={children}
        fatherOption={parent ?? ""}
        defaultStatus={actionsFormatted as object}
        sectionTitle={categoryInDisplay}
        onChange={this.handleSwitchChanged}
        onChangeSpecificRules={this.handleSpecificRulesChanged}
        category={category}
        actionDetails={actionDetails}
      />
    );
  };
}

interface IAccessRightToggle {
  title: string;
  name: string;
  onChange: (value: any, name: string) => void;
  defaultChecked?: boolean;
}
const AccessRightToggle = (props: IAccessRightToggle) => {
  const { defaultChecked, onChange, name, title } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "10px",
      }}
    >
      <span style={{ fontSize: "16px", color: Black, fontWeight: 500 }}>
        {title}
      </span>
      <ToggleSwitch
        name={name}
        onChange={(e) => onChange(e, name)}
        defaultChecked={defaultChecked ?? false}
        size="small"
      />
    </div>
  );
};

export default UserProfileFormModal;
