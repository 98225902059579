import { Box } from "@material-ui/core";
import dateFormat from "dateformat";

import { BasicArrowIcon } from "components/Dialog/BasicDialog";

export interface ITimelineDate {
  date: Date;
  onClickLowerDate: (currentDate: Date) => void;
  onClickIncreaseDate: (currentDate: Date) => void;
}
function TimelineDate({
  date,
  onClickIncreaseDate,
  onClickLowerDate,
}: ITimelineDate) {
  function handleLeftClick() {
    onClickLowerDate(date);
  }
  function handleRightClick() {
    onClickIncreaseDate(date);
  }

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr auto"}
      alignItems={"center"}
      data-testid="timeline-date"
    >
      <Box fontWeight={"bold"} fontSize={"20px"}>
        {dateFormat(date, "dddd, d mmmm, yyyy")}
      </Box>
      <Box display={"flex"} flexDirection={"row"} gridGap={"16px"}>
        <BasicArrowIcon direction="left" onClick={handleLeftClick} />
        <BasicArrowIcon direction="right" onClick={handleRightClick} />
      </Box>
    </Box>
  );
}

export default TimelineDate;
