import React from "react";

import ImportOptionsDialog from "components/Dialog/ImportOptionsDialog";
import OptionForm from "components/Forms/OptionForm/OptionForm";
import { getErrorMessageForOption } from "components/Forms/OptionForm/utils/getErrorMessageForOption";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleCreate, {
  getErrorFromAttribute,
} from "components/Input/InputMultipleCreate/InputMultipleCreate";
import InputSelect from "components/Input/InputSelect/InputSelect";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

import InputText from "../../../../components/Input/InputText/InputText";
import { getLevelOptions } from "./CreateEditMetaLevelModal.utils";

export interface IMetaLevel {
  active: boolean;
  index: number;
  level_name: string;
  is_hierarchy: boolean;
  father?: string;
  label_type?: "TEXT" | "SINGLE_CHOICE";
  label_options?: Array<IOption>;
}

export interface ICreateEditMetaLevelProps {
  onChange: (e: IMetaLevel) => void;
  additionnalProps: {
    otherElements: Array<IMetaLevel>;
  };
  element: IMetaLevel;
  lang: TLang;
  modalType: "create" | "edit";
}

class CreateEditMetaLevelModal extends React.Component<
  ICreateEditMetaLevelProps,
  IMetaLevel
> {
  constructor(props: ICreateEditMetaLevelProps) {
    super(props);
    const { element } = props;
    this.state = element;
  }

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  handleInputChange = (value: any, attrName: string) => {
    let newState = {
      ...this.state,
      [attrName]: value,
    };
    if (attrName === "active" && !value) {
      newState = {
        active: false,
        index: this.state.index,
        level_name: "_",
        is_hierarchy: false,
      };
    }
    this.setState(newState);
    this.props.onChange(newState);
  };

  handleChangeOptions = (options: IOption[]) => {
    const newState = {
      ...this.state,
      label_options: options,
    };
    this.setState(newState);
    this.props.onChange(newState);
  };

  buildMetaLevelConfigs = () => {
    const { level_name, is_hierarchy } = this.state;
    const { lang } = this.props;

    return (
      <>
        <InputText
          error={getErrorFromAttribute(this.state, "level_name")}
          name="level_name"
          lang={
            lang.containers.clients.subCategories.clients.createEditModal
              ?.inputLevels.createEditModal?.inputLevelName
          }
          defaultValue={level_name}
          onChange={this.handleInputChange}
          required={true}
        />
        <InputBoolean
          name="is_hierarchy"
          lang={
            lang.containers.clients.subCategories.clients.createEditModal
              ?.inputLevels.createEditModal?.inputIsHierarchy
          }
          defaultValue={is_hierarchy}
          onChange={this.handleInputChange}
        />
        {is_hierarchy
          ? this.buildLevelParameters()
          : this.buildLabelParameters()}
      </>
    );
  };

  buildLevelParameters = () => {
    const { father, level_name } = this.state;
    const {
      lang,
      additionnalProps: { otherElements },
    } = this.props;

    const label = lang.containers.clients.subCategories.clients.createEditModal;

    return (
      <>
        <InputSelect
          lang={label.inputLevels.createEditModal.inputLevelFather}
          name={"father"}
          value={father}
          options={getLevelOptions(otherElements, level_name)}
          onChange={this.handleInputChange}
        />
      </>
    );
  };

  buildLabelParameters = () => {
    const { label_type, label_options } = this.state;
    const { lang } = this.props;

    const label = lang.containers.clients.subCategories.clients.createEditModal;

    return (
      <>
        <InputSelect
          lang={label.inputLevels.createEditModal.inputLabelType}
          name={"label_type"}
          value={label_type}
          options={getOptionsInputSelect({
            listKeys: ["TEXT", "SINGLE_CHOICE"],
            lang: label.inputSelect.options,
          })}
          onChange={this.handleInputChange}
        />
        {label_type === "SINGLE_CHOICE" ? (
          <InputMultipleCreate
            key={Date.now()}
            name={"label_options"}
            createModalTitleFunction={() =>
              lang.modal.createEditOption.createTitle
            }
            editModalTitleFunction={() => lang.modal.createEditOption.editTitle}
            getErrorMessages={getErrorMessageForOption}
            additionnalProps={{ options: label_options ?? [] }}
            error={getErrorFromAttribute(this.state, "label_options")}
            langlabel={label.inputLevels.createEditModal.inputOptions}
            onChange={this.handleChangeOptions}
            defaultValue={label_options ?? []}
            CreateEditModal={OptionForm}
            chipTitleTemplate={"$label$"}
            lang={lang}
            BulkModal={ImportOptionsDialog}
            onBulkImport={(values) =>
              this.handleInputChange(values, "label_options")
            }
          />
        ) : null}
      </>
    );
  };

  render() {
    const { active } = this.state;
    const { lang } = this.props;

    const label = lang.containers.clients.subCategories.clients.createEditModal;

    return (
      <>
        <InputBoolean
          name="active"
          lang={label.inputLevels.createEditModal?.inputActive}
          defaultValue={active}
          onChange={this.handleInputChange}
        />
        {active ? this.buildMetaLevelConfigs() : <></>}
      </>
    );
  }
}

export default CreateEditMetaLevelModal;
