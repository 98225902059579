import { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";

import PopoverWithArrow from "components/Popover/PopoverWithArrow";
import { TColumnType } from "components/Table/model";
import { useTranslations } from "hooks/useTranslations";

import style from "../Card/styles";
import ColumnSelectorMenu from "./ColumnSelectorMenu";

const useStyle = makeStyles(style as any);

export interface IColumnSelector {
  columnTypes: TColumnType[];
  onChangeColumnTypes?: (
    showsColumns: TColumnType[],
    hidesColumns: TColumnType[]
  ) => void;
}

const ColumnSelector: React.FunctionComponent<IColumnSelector> = ({
  columnTypes,
  onChangeColumnTypes,
}) => {
  const classes = useStyle();
  const lang = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={onOpen} className={classes.ColumnButton}>
        <ColumnIcon />
        {lang.components.columns.showHideFilter.title}
      </Button>

      <PopoverWithArrow
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        hPosition={"right"}
        onClose={onClose}
      >
        <ColumnSelectorMenu
          columnTypes={columnTypes}
          onChangeColumnTypes={onChangeColumnTypes}
        />
      </PopoverWithArrow>
    </>
  );
};

const ColumnIcon = () => (
  <span className="material-icons-outlined" style={{ marginRight: "4px" }}>
    visibility
  </span>
);

export default ColumnSelector;
