import { Feature, Point } from "geojson";
import _ from "lodash";
import { MapboxMap } from "react-map-gl";
import useSupercluster from "use-supercluster";

import {
  IClusterProperties,
  MAP_SETTINGS,
} from "containers/customers/subcategories/map/CustomersMap/CustomersMap";
import { TCustomerFeature } from "containers/customers/subcategories/map/CustomersMap/features/customers";
import getColorForCluster from "containers/customers/utils/getColorForCluster";

import ClusterMarker from "./ClusterMarker";

export type TClusterFeature = Feature<Point, IClusterProperties>;

interface IClusterLayer {
  points: TCustomerFeature[];
  clustersRadius?: number;
  zoom: number;
  maxZoom: number;
  map?: MapboxMap;
}

const ClusterLayer = ({
  points,
  clustersRadius = 50,
  zoom,
  maxZoom,
  map,
}: IClusterLayer) => {
  const bounds = map?.getBounds().toArray().flat() as [
    number,
    number,
    number,
    number
  ];

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom,
    bounds,
    options: {
      minClusterSize: 0,
      radius: clustersRadius,
      // minPoints: 0, // does not work ? See "fake" cluster implem below
      // maxZoom: CLUSTERS_MAX_ZOOM // NOTE: this caps the getClusterExpansionZoom in handleClusterClicked too ? So we switch layers programmatically below
    },
  });

  if (zoom >= maxZoom) {
    return null;
  }

  const Clusters = _.map(clusters, (cluster) => {
    let finalCluster: TClusterFeature;
    let clusterPoints;

    if ((cluster as TClusterFeature)?.properties?.cluster) {
      finalCluster = cluster as TClusterFeature;
      clusterPoints = supercluster.getLeaves(cluster.id, Infinity);
      // "fake" cluster with single points
    } else {
      finalCluster = {
        ...cluster,
        id: cluster.properties.customerId, // used as key below
        properties: {
          ...cluster.properties,
          cluster: false,
          point_count: 1,
          cluster_id: NaN, // TODO: should not be used in this case
        },
      };
      clusterPoints = [finalCluster];
    }

    const clusterPointCount = finalCluster.properties.point_count;
    return (
      <ClusterMarker
        key={finalCluster.id}
        cluster={finalCluster}
        onClusterClick={() =>
          handleClusterClicked(finalCluster, supercluster, map)
        }
        size={clustersRadius * (1 + clusterPointCount / points.length)}
        pieChartColors={getColorForCluster(clusterPoints)}
      />
    );
  });

  return <div id="customer-clusters">{_.compact(Clusters)}</div>;
};

const handleClusterClicked = (
  cluster: TClusterFeature,
  supercluster: any,
  map?: MapboxMap
) => {
  const [longitude, latitude] = cluster.geometry.coordinates;
  const { cluster: isCluster, cluster_id } = cluster.properties;

  const expansionZoom = isCluster
    ? supercluster.getClusterExpansionZoom(cluster_id)
    : MAP_SETTINGS.THRESHOLDS.PINS;

  const zoom = Math.min(expansionZoom, 20);

  map?.flyTo({ zoom, center: [longitude, latitude] });
};

export default ClusterLayer;
