import React, { useEffect, useRef, useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Remove from "@material-ui/icons/Remove";
import Timeline from "@material-ui/icons/Timeline";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKey from "@material-ui/icons/VpnKey";
import classNames from "classnames";
import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import CustomButton, { BUTTON_COLOR } from "components/Buttons/CustomButton";
import { InfoIcon } from "components/Icon/InfoIcon";
import CustomTypography from "components/Typography/CustomTypography";
import TLang from "model/application/Lang";
import { ACTION_TYPE } from "model/application/UIActionTypes";

import styles from "./styles";
import { getIconFromType } from "./utils";

export interface ICustomExpandableChipProps {
  title: string;
  onDuplicate?: (element: any) => void;
  onEdit?: (elementKey: number) => void;
  onDelete?: (element: any) => void;
  onAdd?: (element: any) => void;
  onChangeToDetail?: (element: any) => void;
  onChangeToCompact?: (element: any) => void;
  onRestore?: (element: any) => void;
  buildExpandableContent: (element?: any) => any;
  element: any;
  classes: any;
  lang: TLang;
  maxWidth?: string;
  isFromTemplate?: boolean;
}

export interface ICustomExpandableChipStates {
  hovered: boolean;
}

export class CustomExpandableChip extends React.Component<
  ICustomExpandableChipProps,
  ICustomExpandableChipStates
> {
  public static defaultProps = {
    buildExpandableContent: () => undefined,
  };
  constructor(props: ICustomExpandableChipProps) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  switchToDetailView = () => {
    const { onChangeToDetail, element } = this.props;
    if (onChangeToDetail) onChangeToDetail(element);
  };

  switchToCompactView = () => {
    const { onChangeToCompact, element } = this.props;
    if (onChangeToCompact) onChangeToCompact(element);
  };

  displayButtons = () => {
    const {
      onDuplicate,
      onRestore,
      onEdit,
      onDelete,
      onAdd,
      buildExpandableContent,
      element,
      classes,
    } = this.props;
    return (
      <div
        className={
          element._viewdetail
            ? classes.ActionBoxDetailView
            : classes.ActionBoxCompactView
        }
      >
        {onDelete ? (
          <CustomButton
            isTableAction={true}
            darkMode={true}
            type={ACTION_TYPE.DELETE}
            color={BUTTON_COLOR.SUCCESS}
            onClick={() => onDelete(element.index)}
          />
        ) : (
          <></>
        )}
        {onEdit ? (
          <CustomButton
            isTableAction={true}
            darkMode={true}
            type={ACTION_TYPE.EDIT}
            color={BUTTON_COLOR.SUCCESS}
            onClick={() => onEdit(element.index)}
          />
        ) : (
          <></>
        )}
        {onDuplicate && (element || {}).question_group ? (
          <CustomButton
            isTableAction={true}
            darkMode={true}
            type={ACTION_TYPE.DUPLICATE}
            color={BUTTON_COLOR.SUCCESS}
            onClick={() => onDuplicate(element)}
          />
        ) : (
          <></>
        )}
        {onRestore ? (
          <CustomButton
            isTableAction={true}
            darkMode={true}
            type={ACTION_TYPE.RESTORE}
            color={BUTTON_COLOR.SUCCESS}
            onClick={() => onRestore(element)}
          />
        ) : (
          <></>
        )}
        {buildExpandableContent(element) ? (
          <CustomButton
            isTableAction={true}
            isChipAction={true}
            darkMode={true}
            type={element._viewdetail ? ACTION_TYPE.HIDDEN : ACTION_TYPE.DETAIL}
            color={BUTTON_COLOR.SUCCESS}
            onClick={
              element._viewdetail
                ? this.switchToCompactView
                : this.switchToDetailView
            }
          />
        ) : (
          <></>
        )}
        {onAdd && !element._viewdetail ? (
          <div className={classes.AddChipButton}>
            <div className={classes.AddChipCircle}></div>
            <CustomButton
              isTableAction={true}
              darkMode={true}
              type={ACTION_TYPE.CREATE}
              color={BUTTON_COLOR.SUCCESS}
              onClick={() => onAdd(element.index)}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  render() {
    const { element } = this.props;
    if (!element._viewdetail) return this.renderCompactView();
    else return this.renderDetailView();
  }

  buildChipTitle = () => {
    const { classes, title, element, isFromTemplate, lang } = this.props;
    const Icon: any = getIconFromType(element.type);
    const titleClassName = `${classes.ChipTitle} ${
      element.required ? classes.ChipTitleRequired : ""
    }`;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <div
          style={{
            flex: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          {Icon ? <Icon className={classes.IconInChip} /> : ""}

          <CustomTypography
            className={titleClassName}
            component="div"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ChipTitle
              title={title}
              maxWidth={this.props.maxWidth}
              element={element}
            />
          </CustomTypography>

          {isFromTemplate && (
            <span
              style={{
                zIndex: 10,
                paddingTop: "5px",
              }}
            >
              <InfoIcon
                tooltip={lang.genericTerms.content.templateField}
                classe={classes.InfoIcon}
              />
            </span>
          )}
        </div>
        {element.is_unique || element.query || element.is_hidden ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "4px 0",
            }}
          >
            <Remove
              style={{
                transform: "rotate(90deg)",
              }}
              className={classes.InfoIcon}
            />
            {element.is_unique ? <VpnKey className={classes.InfoIcon} /> : null}
            {element.query ? <Timeline className={classes.InfoIcon} /> : null}
            {element.is_hidden ? (
              <VisibilityOff className={classes.InfoIcon} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  renderCompactView = () => {
    const { classes, element } = this.props;
    const { hovered } = this.state;
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={classNames(
          classes.ExpandableChip,
          element.deprecated && "deprecated",
          !_.isEmpty(element?._error) && classes.Error
        )}
      >
        {this.buildChipTitle()}
        {hovered ? this.displayButtons() : null}
      </div>
    );
  };

  renderDetailView = () => {
    const { classes, buildExpandableContent, element } = this.props;
    return (
      <>
        {element.type !== CUSTOM_FIELD_TYPE.MATRIX_ON_LIST ? (
          <div
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className={`${classes.ExpandableChip} ${
              classes.ExpandableWithChip
            } ${classes.DetailView} ${
              !_.isEmpty(element?._error) ? classes.Error : ""
            }`}
          >
            {this.buildChipTitle()}
            {this.displayButtons()}
            {buildExpandableContent(element)}
          </div>
        ) : (
          <div
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className={`${classes.ExpandableChip} ${classes.DetailView} ${
              !_.isEmpty(element?._error) ? classes.Error : ""
            }`}
          >
            {this.buildChipTitle()}
            {this.displayButtons()}
            {buildExpandableContent(element)}
          </div>
        )}
      </>
    );
  };
}

const ChipTitle = ({
  title,
  element,
  maxWidth,
}: {
  title: string;
  maxWidth?: string;
  element: any;
}) => {
  const titleSpanRef = useRef(null);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    const span = titleSpanRef.current;
    if (!span) return;
    if (
      _.toNumber(span["offsetWidth"]) >= _.toNumber(_.split(maxWidth, "px")[0])
    ) {
      setTooltip(true);
    }
  }, [maxWidth]);

  return (
    <div
      style={{
        width: maxWidth,
        display: "flex",
      }}
    >
      <span>{element.hidden ? "(" : ""}</span>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        className="span-title"
        ref={titleSpanRef}
      >
        {maxWidth && tooltip ? (
          <Tooltip title={title}>
            <span>{title}</span>
          </Tooltip>
        ) : (
          title
        )}
      </span>

      <span>{element.conditions ? "*" : ""}</span>
      <span>{element.hidden ? ")" : ""}</span>
    </div>
  );
};

export default withStyles(styles as any)(CustomExpandableChip);
