import { createSelector } from "reselect";

import { getSignedInUser } from "containers/authentication/redux/selector";
import { teamsComposedSelector } from "containers/teams/redux/selectors";
import * as lang from "lang";
import {
  DASHBOARD_SCOPE,
  DASHBOARD_TYPE,
  IDashboard,
} from "model/entities/Dashboard";
import { ITeamSelector } from "model/entities/Team";
import { ISignedInUser } from "model/entities/User";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../redux/selectors/appSelector";
import { translateDashboards } from "../utils/translateDashboards";

/**
 * Gets the state of fetching dashboards
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingAllDashboards = (state: IRootState) =>
  state.dashboards.isFetchingAll;

/**
 * Gets the state of computing dashboards
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsComputingDashboards = (state: IRootState) =>
  state.dashboards.isComputing;

/**
 * Gets the state of computing kpi
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsComputingKpi = (state: IRootState) =>
  state.dashboards.isComputingKpi;

/**
 * Gets the xcomputing kpi data
 * @param {Object} state Redux store state
 * @returns {Any}
 */
export const getComputedKpiResult = (state: IRootState) =>
  state.dashboards.computedKpiResult;

/**
 * Gets the status of creating a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingDashboard = (state: IRootState) =>
  state.dashboards.isCreating;

/**
 * Gets the status of updating a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingDashboard = (state: IRootState) =>
  state.dashboards.isUpdating;

/**
 * Gets the status of deleting a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingDashboard = (state: IRootState) =>
  state.dashboards.isDeleting;

/**
 * Gets the status of archiving a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingDashboard = (state: IRootState) =>
  state.dashboards.isArchiving;

/**
 * Gets the status of restoring a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringDashboard = (state: IRootState) =>
  state.dashboards.isRestoring;

/**
 * Gets the currenly viewing dashboard
 * @param {Object} state
 * @returns {String}
 */
export const getSelectedDashboard = (state: IRootState) =>
  state.dashboards.selectedDashboard;

/**
 * Gets all dashboards from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllDashboards = (state: IRootState) =>
  translateDashboards(state.dashboards.dashboards, lang[state.auth.lang]);

/**
 * Gets frequently viewed dashboards Ids
 */
export const getFrequentlyViewedDashboards = (state: IRootState) =>
  state.dashboards.frequentlyViewedIds;

/**
 * Selector to get all Dashboards from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allDashboardsSelector = customSelectorCreator(
  [getAllDashboards],
  (allDashboards: IDashboard[]) => {
    // map all the dashboards
    return allDashboards;
  }
);

export const dashboardsComposedSelector = createSelector(
  [getAllDashboards, teamsComposedSelector, getSignedInUser],
  (dashboards, teams: ITeamSelector[], user: ISignedInUser): IDashboard[] => {
    return dashboards
      .filter(
        (dashboard) =>
          dashboard.type !== DASHBOARD_TYPE.MOBILE ||
          user.email.includes("@optimetriks.com") ||
          user.email.includes("@fieldproapp.com")
      )
      .map((dashboard) => {
        dashboard.active =
          !dashboard.hasOwnProperty("active") ||
          dashboard.active === undefined ||
          dashboard.active;

        // link teams to dashboards
        dashboard.teams = [];
        teams.forEach((team: ITeamSelector) => {
          if (
            dashboard.teams &&
            team.dashboards.map((o) => o.key).includes(dashboard.id)
          ) {
            dashboard.teams.push(team.id);
          }
        });

        return dashboard;
      });
  }
);

export const dashboardsWithTeamScopeComposedSelector = createSelector(
  [dashboardsComposedSelector],
  (dashboards) => dashboards.filter((d) => d.scope === DASHBOARD_SCOPE.TEAM)
);
