import { IQuerySettings } from "fieldpro-tools";

import { IClient } from "model/entities/Client";
import { canLeaderVerifyQuery } from "utils/utils";

export const getQueryMetadata = (
  attribute: object & IQuerySettings,
  client: IClient,
  role: string,
  onInput?: (value: any, name: string) => void
): {
  deactivated?: boolean;
  verified?: boolean;
  shouldVerify?: boolean;
  onVerify?: () => void;
} => {
  const { verified, disabled } = attribute;
  const canVerify = canLeaderVerifyQuery(client, role);

  const handleQueryVerification = () => {
    onInput?.(!verified, "verified");
  };

  const getVerificationObject = () => {
    if (canVerify) {
      return {
        onVerify: handleQueryVerification,
        shouldVerify: !verified,
      };
    }
    return { deactivated: disabled };
  };

  return {
    verified: attribute["verified"],
    ...getVerificationObject(),
  };
};
