// import { RENDER_STATE } from "react-map-gl-draw";

// const colorPalette = [...Array(30).keys()].map(() => getRandomHexColor());

const COLOR_PALETTE = [
  "#512656",
  "#2cf70e",
  "#249010",
  "#7076f4",
  "#224602",
  "#9917be",
  "#a21585",
  "#dadf10",
  "#6b63b1",
  "#2fff29",
  "#7a15c0",
  "#56ea39",
  "#e84aee",
  "#890d53",
  "#ebab24",
  "#2f5f10",
  "#b53234",
  "#df2d03",
  "#d805be",
  "#a4384a",
  "#e28d8e",
  "#2a90f3",
  "#b3e43c",
  "#d2381d",
  "#33bc5b",
  "#4ebbb0",
  "#d1a096",
  "#d857a1",
  "#db9e8a",
  "#d91ef6",
];

export const getFeatureColor = (index: number) => COLOR_PALETTE[index % 30];

// interface IGetEditHandleStyle {
//   feature: Feature;
//   state: any; // TODO
// }

// export function getEditHandleStyle({
//   feature: _feature,
//   state,
// }: IGetEditHandleStyle) {
//   switch (state) {
//     case RENDER_STATE.SELECTED:
//     case RENDER_STATE.HOVERED:
//     case RENDER_STATE.UNCOMMITTED:
//       return {
//         fill: "rgb(251, 176, 59)",
//         fillOpacity: 1,
//         stroke: "rgb(255, 255, 255)",
//         strokeWidth: 2,
//         r: 7,
//       };

//     default:
//       return {
//         fill: "rgb(251, 176, 59)",
//         fillOpacity: 1,
//         stroke: "rgb(255, 255, 255)",
//         strokeWidth: 2,
//         r: 5,
//       };
//   }
// }

// const SELECTION_STYLE = {
//   stroke: "rgb(251, 176, 59)",
//   strokeWidth: 2,
//   fill: "rgb(251, 176, 59)",
//   fillOpacity: 0.3,
//   strokeDasharray: "4,2",
// };

// const getDefaultStyle = (color: string) => ({
//   stroke: color,
//   strokeWidth: 2,
//   fill: color,
//   fillOpacity: 0.1,
// });

// interface IGetFeatureStyle {
//   feature: Feature;
//   index: number;
//   state: any; // TODO
// }

// export const getFeatureStyle = ({
//   disableHover,
// }: {
//   disableHover?: boolean;
// }) => {
//   return (args: IGetFeatureStyle) => {
//     const { feature: _feature, index, state } = args || {};
//     const color = getFeatureColor(index);
//     switch (state) {
//       case RENDER_STATE.SELECTED:
//       case RENDER_STATE.UNCOMMITTED:
//       case RENDER_STATE.CLOSING: {
//         return SELECTION_STYLE;
//       }
//       case RENDER_STATE.HOVERED: {
//         return disableHover ? getDefaultStyle(color) : SELECTION_STYLE;
//       }
//       default: {
//         return getDefaultStyle(color);
//       }
//     }
//   };
// };
