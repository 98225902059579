import { INotification as IBENotification } from "fieldpro-tools/dist/src";
import _ from "lodash";

import { INotification } from "model/entities/Job";
import { clone } from "utils/utils";

const prepareNotificationForBackend = (
  originalNotification: INotification
): IBENotification => {
  const notification = JSON.parse(JSON.stringify(originalNotification));
  Object.keys(notification).forEach((att) => {
    if (notification[att] === "") delete notification[att];
  });

  if (notification.queries) {
    notification.queries = notification.queries.map((q: any) => {
      delete q.index;
      return q;
    });
  }

  delete notification.id;
  delete (notification as any).job_type;
  return notification;
};
const prepareNotificationsForFrontend = (
  originalNotifications: IBENotification[]
): INotification[] => {
  const notifications = clone(originalNotifications);
  const frontNotifications: INotification[] = [];
  notifications.forEach((notification: IBENotification) => {
    const recipients = _.concat(
      _.compact(notification.web_users || []),
      _.compact(notification.recipients || [])
    );
    delete notification.web_users;
    notification.recipients = _.compact(
      _.map(recipients, (recipient) => {
        if (recipient.hasOwnProperty("key")) return _.get(recipient, "key");
        return recipient;
      })
    );
    frontNotifications.push(notification);
  });
  return frontNotifications;
};

export { prepareNotificationForBackend, prepareNotificationsForFrontend };
