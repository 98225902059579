import { ReactNode } from "react";

import { FormSection } from "components/Forms/Form";
import { TViewMode } from "model/application/modal/CreateEditModal";

interface IClientFormWrapper {
  children: ReactNode;
  viewMode: TViewMode;
  label: string;
  onDiscard?: () => void;
  onCreate?: () => void;
}
export const ClientFormWrapper = ({
  viewMode,
  label,
  onCreate,
  onDiscard,
  children,
}: IClientFormWrapper) => {
  if (viewMode === "CREATE") {
    return (
      <FormSection
        saveText={label}
        onSave={onCreate}
        onDiscard={onDiscard}
        isCreation
      >
        {children}
      </FormSection>
    );
  }

  return <>{children}</>;
};
