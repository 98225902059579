/**
 * @notes: Notification action types
 */
export const CREATE_NOTIFICATION_BEGIN = "CREATE_NOTIFICATION_BEGIN";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE = "CREATE_NOTIFICATION_FAILURE";

export const UPDATE_NOTIFICATION_BEGIN = "UPDATE_NOTIFICATION_BEGIN";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

export const FETCH_NOTIFICATION_HISTORY_BEGIN =
  "FETCH_NOTIFICATION_HISTORY_BEGIN";
export const FETCH_NOTIFICATION_HISTORY_SUCCESS =
  "FETCH_NOTIFICATION_HISTORY_SUCCESS";
export const FETCH_NOTIFICATION_HISTORY_FAILURE =
  "FETCH_NOTIFICATION_HISTORY_FAILURE";

export const FETCH_ALL_NOTIFICATIONS_BEGIN = "FETCH_ALL_NOTIFICATIONS_BEGIN";
export const FETCH_ALL_NOTIFICATIONS_SUCCESS =
  "FETCH_ALL_NOTIFICATIONS_SUCCESS";
export const FETCH_ALL_NOTIFICATIONS_FAILURE =
  "FETCH_ALL_NOTIFICATIONS_FAILURE";

export const DELETE_NOTIFICATION_BEGIN = "DELETE_NOTIFICATION_BEGIN";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

export const RUN_NOTIFICATION_JOB_BEGIN = "RUN_NOTIFICATION_JOB_BEGIN";
export const RUN_NOTIFICATION_JOB_SUCCESS = "RUN_NOTIFICATION_JOB_SUCCESS";
export const RUN_NOTIFICATION_JOB_FAILURE = "RUN_NOTIFICATION_JOB_FAILURE";
