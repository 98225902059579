import { Box, Button } from "@material-ui/core";

import ExpressionChip from "components/Chip/ExpressionChip";
import InputBaseLayout from "components/Input/InputBaseLayout";
import { IInputBaseLayout } from "components/Input/InputBaseLayout/InputBaseLayout";
import useTranslations from "hooks/useTranslations";

export enum SHAPE_EDIT_MODE {
  EDIT = "EDIT",
  DRAW = "DRAW",
}

interface IInputEditShape extends IInputBaseLayout {
  editMode: SHAPE_EDIT_MODE;
  onEditShape?: () => void;
  onResetShape: () => void;
  required?: boolean;
}

const InputEditShape = ({
  editMode,
  onResetShape,
  onEditShape,
  required,
  ...rest
}: IInputEditShape) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.territories.inputEditShape;
  const title =
    editMode === SHAPE_EDIT_MODE.DRAW
      ? langKey.drawMode.title
      : langKey.editMode.title;

  return (
    <InputBaseLayout
      {...rest}
      label={title}
      viewMode="CREATE"
      required={required}
    >
      {editMode === SHAPE_EDIT_MODE.DRAW ? (
        <ExpressionChip placeholder={langKey.drawMode.description} />
      ) : (
        <Box display="flex" flexDirection="row" gridGap="24px">
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            disabled={!onEditShape}
            onClick={onEditShape}
          >
            {langKey.editMode.editButton}
          </Button>

          <Button variant="outlined" disableElevation onClick={onResetShape}>
            {langKey.editMode.resetButton}
          </Button>
        </Box>
      )}
    </InputBaseLayout>
  );
};

export default InputEditShape;
