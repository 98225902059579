import {
  ErrorColor,
  InfoColor,
  SuccessColor,
  warningColor,
} from "assets/colors";

export default () => ({
  success: {
    backgroundColor: SuccessColor,
  },
  error: {
    backgroundColor: ErrorColor,
  },
  info: {
    backgroundColor: InfoColor,
  },
  warning: {
    backgroundColor: warningColor,
  },
  icon: {
    fontSize: 20,
    marginLeft: "-10px",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  dotLoaderComponent: {
    transform: "scale(0.5)",
    width: "50px",
    height: "10px",
    marginLeft: "-20px",
  },
  notistack: {
    "& .notiStackItemsuccess": {
      color: "#124e5d",
      backgroundColor: "#fff",
      borderLeft: "5px solid #124e5d",
      boderLeftRadius: "1px",
      borderRadius: "1px 2px 2px 1px",
    },
    "& .notiStackItemerror": {
      color: "#d32f2f",
      backgroundColor: "#fff",
      borderLeft: "5px solid #d32f2f",
      boderLeftRadius: "1px",
      borderRadius: "1px 2px 2px 1px",
    },
    "& .notiStackItemwarning": {
      color: "#ff9800",
      backgroundColor: "#fff",
      borderLeft: "5px solid #ff9800",
      boderLeftRadius: "1px",
      borderRadius: "1px 2px 2px 1px",
    },
    "& .notiStackIteminfo": {
      color: "#2196f3",
      backgroundColor: "#fff",
      borderLeft: "5px solid #2196f3",
      boderLeftRadius: "1px",
      borderRadius: "1px 2px 2px 1px",
    },
    "& .notiStackItemdefault": {
      color: "rgb(50, 50, 50)",
      backgroundColor: "#fff",
      borderLeft: "5px solid rgb(50, 50, 50)",
      boderLeftRadius: "1px",
      borderRadius: "1px 2px 2px 1px",
    },
  },
});
