export interface IAjaxState {
  isFetching: boolean;
  callsInProgress: number;
  mustLogOut: boolean;
  errors: any[];
}

const initialState: IAjaxState = {
  isFetching: false,
  callsInProgress: 0,
  mustLogOut: false,
  errors: [],
};

export default initialState;
