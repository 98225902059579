import { useEffect, useRef, useState } from "react";

import { GeoJSONSourceOptions } from "mapbox-gl";
import { Layer, LngLatBoundsLike, Map, MapboxMap, Source } from "react-map-gl";

import Pin from "assets/images/png/pin.png";
import { MAP_SETTINGS } from "containers/customers/subcategories/map/CustomersMap/CustomersMap";
import { TCustomerFeature } from "containers/customers/subcategories/map/CustomersMap/features/customers";
import {
  CUSTOMER_PIN_ID,
  customersCircleLayer,
  customersSymbolLayer,
} from "containers/customers/subcategories/map/CustomersMap/layers/customers";
import { getBboxSafe } from "containers/customers/utils/getBboxSafe";
import { useMapImage } from "hooks/useMapImage";

const THRESHOLDS = {
  CIRCLES: {
    MIN: 0,
    MAX: 5,
  },
  SYMBOLS: {
    MIN: 5,
    MAX: 23,
  },
};

export interface ICustomersMapChartBaseMap {
  customerFeatures: Array<TCustomerFeature>;
  children?: React.ReactNode;
}

function CustomersMapChartBaseMap({
  customerFeatures,
  children,
}: ICustomersMapChartBaseMap) {
  const [features, setFeatures] = useState<TCustomerFeature[] | undefined>(
    undefined
  );
  const [zoom, setZoom] = useState<number>(0);
  const mapRef = useRef<any>();
  const map: MapboxMap | undefined = mapRef?.current?.getMap
    ? mapRef?.current?.getMap()
    : undefined;

  const [initFitBoundsDone, setInitFitBoundsDone] = useState(false);

  useMapImage({
    map,
    path: Pin,
    name: CUSTOMER_PIN_ID,
    sdf: true,
  });

  useEffect(() => {
    if (!initFitBoundsDone && map && features) {
      fitBoundsToCustomerFeatures(map, features);
      setInitFitBoundsDone(true);
    }
  }, [features, initFitBoundsDone, map]);

  const initialViewState = {
    latitude: 12,
    longitude: 12,
    zoom: 5,
    width: MAP_SETTINGS.WIDTH,
    height: MAP_SETTINGS.HEIGHT,
  };

  const featuresCollection: GeoJSONSourceOptions["data"] = {
    type: "FeatureCollection",
    features: features || [],
  };

  return (
    <Map
      ref={mapRef}
      initialViewState={initialViewState}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API}
      mapStyle={`mapbox://styles/smala/${MAP_SETTINGS.STYLE.DEFAULT}`}
      style={{
        position: "relative",
      }}
      onZoom={() => setZoom(map?.getZoom() || 0)}
      onLoad={() => {
        if (!features) {
          setFeatures(customerFeatures);
        }
      }}
    >
      <Source
        id={MAP_SETTINGS.SOURCES.CUSTOMERS_PINS}
        type="geojson"
        generateId
        buffer={0}
        data={featuresCollection}
      >
        <Layer
          {...customersSymbolLayer(1.5)}
          minzoom={THRESHOLDS.SYMBOLS.MIN}
          maxzoom={THRESHOLDS.SYMBOLS.MAX}
        />
      </Source>

      <Source
        id={MAP_SETTINGS.SOURCES.CUSTOMERS}
        type="geojson"
        generateId // For hovers
        buffer={0} // https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/#adjusting-the-buffer
        data={featuresCollection}
      >
        <Layer
          {...customersCircleLayer(zoom)}
          minzoom={THRESHOLDS.CIRCLES.MIN}
          maxzoom={THRESHOLDS.CIRCLES.MAX}
        />
      </Source>

      {children}
    </Map>
  );
}

export default CustomersMapChartBaseMap;

function fitBoundsToCustomerFeatures(
  map: MapboxMap | undefined,
  customerFeatures: Array<TCustomerFeature>
) {
  const bbox = getBboxSafe({
    type: "FeatureCollection",
    features: customerFeatures,
  });

  if (map && bbox) {
    try {
      map.fitBounds(bbox as LngLatBoundsLike, { padding: 30 });
    } catch (e) {
      console.error(e);
    }
  }
}
