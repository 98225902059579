import { combineReducers } from "redux";

import { IAction } from "redux/store/model";

import * as types from "./actionTypes";
import dbSchema, { IDbSchemaState } from "./dbSchema/reducer";
import initialState from "./initialState";
import tableOptimizations, {
  ITableOptimizationState,
} from "./tableOptimizations/reducer";

export interface IEnvironmentState {
  subCategorySelected: "index" | "tableOptimization";
  dbSchema: IDbSchemaState;
  tableOptimizations: ITableOptimizationState;
}

export function reducer(
  state = initialState.subCategorySelected,
  action: IAction
) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as any;
      return subcategory;
    }
    default:
      return state;
  }
}

export default combineReducers({
  subCategorySelected: reducer,
  dbSchema,
  tableOptimizations,
});
