import { IChangeSubcategorySuccessAction } from "containers/dashboards/redux/actionCreators";

import * as types from "./actionTypes";

/**
 * @param {Object} subcategory Can be either the summary or a dashboard
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string
): IChangeSubcategorySuccessAction {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
