import * as muiColors from "@mui/material/colors";
import _ from "lodash";

import * as colors from "assets/colors";
import TLang from "model/application/Lang";

import { ICustomerLegendFE } from "./types";

export function prepareLegendForFE(legend: ICustomerLegendFE, lang: TLang) {
  legend.categories.forEach((cat) => {
    if (cat.key === null) {
      cat.key = "null";
    }
  });
  setLegendColors(legend);
  translateLegendLabels(legend, lang);
  return legend;
}
export function setLegendColors(legend: ICustomerLegendFE) {
  const c = [
    colors.tealLight,
    colors.yellow,
    colors.lightpurple,
    colors.blue,
    colors.BurntOrange,
    colors.GreyDark,
  ];

  const extraColors = _.without(
    _.flatMap(_.values(muiColors), _.values),
    ...c,
    colors.darkgray
  );
  legend.categories.forEach((cat, index) => {
    if (
      !_.isEmpty(
        _.intersection([cat.label, cat.key], ["no_name", "no_owner", "null"])
      )
    ) {
      cat.color = colors.darkgray;
    } else {
      cat.color = index < c.length ? c[index] : _.sample(extraColors);
    }
  });
  return legend;
}

export function translateLegendLabels(legend: ICustomerLegendFE, lang: TLang) {
  switch (legend.tag) {
    case "_users":
      const cat = legend.categories.find((c) => c.label === "no_name");
      if (cat) cat.label = lang.components.maps.legend.unknownUser;
      break;
    case "_teams":
      const catTeam = legend.categories.find((c) => c.label === "no_name");
      if (catTeam) catTeam.label = lang.genericTerms.none;
      break;
    case "_owners":
      const catOwner = legend.categories.find((c) => c.label === "no_owner");
      if (catOwner) catOwner.label = lang.genericTerms.none;
      break;
    default:
      const catType = legend.categories.find((c) => c.key === "null");
      if (catType) catType.label = lang.genericTerms.none;
      break;
  }
}
