import { IFolder } from "model/entities/Client";
import { IAction } from "redux/store/model";

import {
  insertNewItemToArr,
  removeObjectFromArray,
  updateObjectInArray,
} from "../../../utils/reducerUtils";
import {
  IArchiveFolderSuccessAction,
  IRestoreFolderSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
export interface IFoldersStates {
  folders: IFolder[];
  isFetchingFolders: boolean;
  isUpdatingFolder: boolean;
  isDeletingFolder: boolean;
  isCreatingFolder: boolean;
  isArchivingFolder: boolean;
  isRestoringFolder: boolean;
  errors: any[];
}
/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_FOLDER_BEGIN:
      return {
        ...state,
        isCreatingFolder: true,
      };

    case types.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        isCreatingFolder: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.CREATE_FOLDER_SUCCESS: {
      const { folder } = action as any;
      return {
        ...state,
        isCreatingFolder: false,
        folders: insertNewItemToArr(state.folders, { ...folder, active: true }),
      };
    }
    case types.UPDATE_FOLDER_BEGIN:
      return {
        ...state,
        isUpdatingFolder: true,
      };

    case types.UPDATE_FOLDER_FAILURE:
      return {
        ...state,
        isUpdatingFolder: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    case types.UPDATE_FOLDER_SUCCESS: {
      return {
        ...state,
        isUpdatingFolder: false,
        folders: updateObjectInArray(state.folders, (action as any).folder),
      };
    }
    case types.ARCHIVE_FOLDER_BEGIN:
      return {
        ...state,
        isArchivingFolder: true,
      };
    case types.ARCHIVE_FOLDER_FAILURE:
      return {
        ...state,
        isArchivingFolder: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    case types.ARCHIVE_FOLDER_SUCCESS: {
      const { ids } = action as IArchiveFolderSuccessAction;

      const folders = state.folders.map((d) => {
        if (ids.includes(d.id)) {
          d.active = false;
        }
        return d;
      });

      return {
        ...state,
        isArchivingFolder: false,
        folders,
      };
    }

    case types.RESTORE_FOLDER_BEGIN:
      return {
        ...state,
        isRestoringFolder: true,
      };
    case types.RESTORE_FOLDER_FAILURE:
      return {
        ...state,
        isRestoringFolder: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    case types.RESTORE_FOLDER_SUCCESS: {
      const { ids } = action as IRestoreFolderSuccessAction;

      const folders = state.folders.map((d) => {
        if (ids.includes(d.id)) {
          d.active = true;
        }
        return d;
      });

      return {
        ...state,
        isRestoringFolder: false,
        folders,
      };
    }
    case types.DELETE_FOLDER_BEGIN:
      return {
        ...state,
        isDeletingFolder: true,
      };
    case types.DELETE_FOLDER_FAILURE:
      return {
        ...state,
        isDeletingFolder: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    case types.DELETE_FOLDER_SUCCESS: {
      const { id } = action as any;
      return {
        ...state,
        isDeletingFolder: false,
        folders: removeObjectFromArray(state.folders, id),
      };
    }
    case types.FETCH_FOLDERS_BEGIN:
      return {
        ...state,
        isFetchingFolders: true,
        isUpdatingFolder: false,
        isDeletingFolder: false,
        isCreatingFolder: false,
        isArchivingFolder: false,
        isRestoringFolder: false,
      };

    case types.FETCH_FOLDERS_FAILURE:
      return {
        ...state,
        isFetchingFolders: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };
    case types.FETCH_FOLDERS_SUCCESS: {
      const folders: IFolder[] = (action as any).folders as IFolder[];
      return {
        ...state,
        isFetchingFolders: false,
        folders,
      };
    }

    default:
      return state;
  }
}
