import TLang from "model/application/Lang";

export function getDurationFromDates(
  start: Date,
  end: Date,
  lang: TLang,
  separator: string = " "
) {
  const diff = Math.ceil(Math.abs(start.getTime() - end.getTime()) / 60000);
  let str = "";
  const days = Math.floor(diff / 24 / 60);
  const hours = Math.floor((diff / 60) % 24);
  const minutes = Math.floor(diff % 60);
  if (days > 0)
    days === 1
      ? (str += `${days}${lang.genericTerms.period.day}${separator}`)
      : (str += `${days}${lang.genericTerms.periods.day}${separator}`);
  if (hours > 0)
    hours === 1
      ? (str += `${hours}${lang.genericTerms.period.hour}${separator}`)
      : (str += `${hours}${lang.genericTerms.periods.hour}${separator}`);
  if (minutes > 0)
    minutes === 1
      ? (str += `${minutes}${lang.genericTerms.periodShort.min}`)
      : (str += `${minutes}${lang.genericTerms.periodsShort.min}`);
  return str;
}
