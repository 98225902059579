import { ACTION_CODE, ACTION_TYPE } from "model/application/ActionCode";

const getActionName = (actionCode: string, sectionTitle?: String) => {
  if (actionCode.startsWith(ACTION_TYPE.CREATE)) {
    return `Create ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.DELETE)) {
    return `Delete ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.FETCH)) {
    return `Fetch ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.ARCHIVE)) {
    return `Archive ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.RESTORE)) {
    return `Restore ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.VERIFY)) {
    return "Verify";
  } else if (actionCode.startsWith("QUERY_ACTIONS")) {
    return "Actions";
  } else if (actionCode.startsWith(ACTION_TYPE.EDIT)) {
    if (actionCode === "EDIT_TEAMS_HIERARCHY") {
      return "Edit team hierarchy";
    }
    return `Edit ${sectionTitle ?? ""}`;
  } else if (actionCode.startsWith(ACTION_TYPE.ADVANCED_ACTION)) {
    if (actionCode === ACTION_CODE.EDIT_CLIENT_SETTINGS) {
      return "Advanced actions";
    }
    return "Advanced actions (like bulk)";
  } else if (actionCode === "VIEW_DEFAULT_DASHBOARDS") {
    return "View default reports";
  } else if (actionCode === "GET_USER_REPORT") {
    return "Get user report"; // legacy
  } else if (actionCode === "EXPORT_CLIENT_DATA") {
    return "Download raw data";
  } else if (actionCode === "RESET_PASSWORD") {
    return "Reset Password";
  } else if (actionCode === "UNLOCK_LOGIN") {
    return "Unlock Login";
  } else {
    return "Unknown action :" + actionCode;
  }
};

export { getActionName };
