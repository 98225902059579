import { useState } from "react";

import { Avatar, Box, IconButton, makeStyles } from "@material-ui/core";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { OffWhite } from "assets/colors";

import styles from "./styles";
const useStyles = makeStyles(styles as any);

interface IFilePreviewProps {
  description: string;
  url?: string;
  viewStacked?: boolean;
}
function FilePreview({
  description,
  url,
  viewStacked = false,
}: IFilePreviewProps) {
  const classes = useStyles();
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <Box
      onMouseEnter={function () {
        setShowOverlay(true);
      }}
      onMouseLeave={function () {
        setShowOverlay(false);
      }}
      style={{
        ...(viewStacked && {
          height: 90,
          borderRadius: 4,
          width: "100%",
          background: OffWhite,
        }),
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Box
        className={classes.overlay}
        style={{
          visibility: showOverlay ? "visible" : "hidden",
          opacity: showOverlay ? "0.5" : "0",
        }}
      >
        <IconButton component={"a"} href={url} target="_blank" centerRipple>
          <Avatar className={classes.overlayButton}>
            <CloseFullscreenIcon />
          </Avatar>
        </IconButton>
      </Box>

      <Box className={classes.container}>
        <InsertDriveFileIcon className={classes.icon} />
        <Box className={classes.description}>{description}</Box>
      </Box>
    </Box>
  );
}

export default FilePreview;
