import { MatrixData } from "components/Matrix/CustomMatrix/CustomMatrix";
import { findHighestAggLevel } from "components/Matrix/CustomMatrix/utils";
import { TABLE_AGGREGATOR } from "components/Table/model";
import { IOption } from "model/application/components";
import { IKPI, PIVOT_TYPE } from "model/entities/Dashboard";

import { TPivotInfo, TPivotOption } from "./MatrixChart";

export const reorderPivotOptions = (pivot: TPivotInfo): TPivotOption[] => {
  const highestLevel = findHighestAggLevel(pivot);
  let options = pivot.options;
  for (let i = highestLevel; i >= 0; i--) {
    const map = {};
    options.forEach((opt) => {
      map[opt[`level_${i}_value`]] = map[opt[`level_${i}_value`]]
        ? map[opt[`level_${i}_value`]].concat([opt])
        : [opt];
    });
    options = Object.keys(map).reduce((acc, curr) => {
      return acc.concat(map[curr]);
    }, []);
  }
  return options;
};

export const initPivotInfo = (
  chart: IKPI,
  rowOrColumn: "row" | "column",
  singleQuery: boolean
): TPivotInfo => {
  let pivotType: PIVOT_TYPE = PIVOT_TYPE.NONE;
  let customPivotOptions: any[] = [];
  if (rowOrColumn === "row") {
    if (chart.row_pivot_type) pivotType = chart.row_pivot_type;
    if (chart.data.row_possibilities)
      customPivotOptions = chart.data.row_possibilities;
  } else {
    if (chart.column_pivot_type) pivotType = chart.column_pivot_type;
    if (chart.data.column_possibilities)
      customPivotOptions = chart.data.column_possibilities;
  }
  switch (pivotType) {
    case PIVOT_TYPE.DATE:
      return initDatePivotInfo(customPivotOptions);
    case PIVOT_TYPE.TEAM:
      return initTeamPivotInfo(customPivotOptions);
    case PIVOT_TYPE.MOBILE_USER:
      return initMobUserPivotInfo(customPivotOptions);
    case PIVOT_TYPE.CUSTOM:
      return initCustomPivotInfo(customPivotOptions);
    case PIVOT_TYPE.NONE: {
      if (singleQuery) return initCustomPivotInfo(customPivotOptions);
      return initEmptyPivotInfo();
    }
  }
};

export const getLevelOptionsFromPivotOptions = (
  pivotOptions: TPivotOption[]
): IOption[] => {
  if (pivotOptions.length === 0) return [];
  return Object.keys(pivotOptions[0])
    .filter((e) => e.endsWith("label"))
    .map((e) => ({
      key: e.replace("label", "value"),
      label: pivotOptions[0][e],
    }));
};

const initCustomPivotInfo = (pivotOptions: TPivotOption[]): TPivotInfo => ({
  levelSelected: "value",
  levelOptions: getLevelOptionsFromPivotOptions(pivotOptions),
  options: pivotOptions,
  type: PIVOT_TYPE.CUSTOM,
});

const initEmptyPivotInfo = (): TPivotInfo => ({
  levelSelected: "value",
  levelOptions: [{ key: "value", label: "_none" }],
  options: [{ value: "emptypivot", label: "_none" }],
  type: PIVOT_TYPE.NONE,
});

const initDatePivotInfo = (pivotOptions: TPivotOption[]): TPivotInfo => ({
  levelSelected: "value",
  levelOptions: getLevelOptionsFromPivotOptions(pivotOptions),
  options: pivotOptions,
  type: PIVOT_TYPE.DATE,
});

const initMobUserPivotInfo = (pivotOptions: TPivotOption[]): TPivotInfo => ({
  levelSelected: "value",
  levelOptions: getLevelOptionsFromPivotOptions(pivotOptions),
  options: pivotOptions,
  type: PIVOT_TYPE.MOBILE_USER,
});

const initTeamPivotInfo = (pivotOptions: TPivotOption[]): TPivotInfo => ({
  levelSelected: "value",
  levelOptions: getLevelOptionsFromPivotOptions(pivotOptions),
  options: pivotOptions,
  type: PIVOT_TYPE.TEAM,
});

export const aggregateByRowOrColumn = (
  variableName: "row" | "column",
  data: MatrixData,
  columnPossibilities: TPivotOption[],
  rowPossibilities: TPivotOption[],
  level: string,
  pivotOptions: TPivotOption[],
  emptyCell: any,
  aggregator?: TABLE_AGGREGATOR
): MatrixData => {
  const mapValueOccurences: any = {};
  const otherVarN: "row" | "column" =
    variableName === "column" ? "row" : "column";

  // if the level selected is the smallest level (the "value" of the TPivotOption), don't aggregate values
  if (!pivotOptions[0] || pivotOptions[0].label === level) return data;
  data.forEach((curr) => {
    const aggregatedVal = `${getPivotName(
      columnPossibilities,
      rowPossibilities,
      curr[variableName],
      variableName,
      level
    )}`;
    const valAlreadyInMap =
      mapValueOccurences[`${aggregatedVal}__${curr[otherVarN]}`];
    if (valAlreadyInMap) {
      const obj = curr.data[0] ? curr.data[0] : emptyCell;
      const sum = Object.keys(obj).reduce((acc2: any, curr2: string) => {
        acc2[curr2] = obj[curr2] + valAlreadyInMap.sum[curr2];
        return acc2;
      }, {});
      const min = Object.keys(obj).reduce((acc2: any, curr2: string) => {
        acc2[curr2] =
          obj[curr2] < valAlreadyInMap.min[curr2]
            ? obj[curr2]
            : valAlreadyInMap.min[curr2];
        return acc2;
      }, {});
      const max = Object.keys(obj).reduce((acc2: any, curr2: string) => {
        acc2[curr2] =
          obj[curr2] > valAlreadyInMap.max[curr2]
            ? obj[curr2]
            : valAlreadyInMap.max[curr2];
        return acc2;
      }, {});
      const pres = Object.keys(obj).reduce((acc2: any, curr2: string) => {
        acc2[curr2] = obj[curr2] || valAlreadyInMap.pres[curr2] ? 1 : 0;
        return acc2;
      }, {});
      mapValueOccurences[`${aggregatedVal}__${curr[otherVarN]}`] = {
        nb: valAlreadyInMap.nb + 1,
        min,
        max,
        sum,
        pres,
      };
    } else {
      const pres = Object.keys(emptyCell).reduce((acc2: any, curr2: string) => {
        acc2[curr2] = curr.data[0] && curr.data[0][curr2] ? 1 : 0;
        return acc2;
      }, {});
      mapValueOccurences[`${aggregatedVal}__${curr[otherVarN]}`] = {
        nb: 1,
        min: curr.data[0] ? curr.data[0] : emptyCell,
        max: curr.data[0] ? curr.data[0] : emptyCell,
        sum: curr.data[0] ? curr.data[0] : emptyCell,
        pres,
      };
    }
  });
  return Object.keys(mapValueOccurences).map((k: string) => {
    const varValue = k.split("__")[0];
    const otherVarValue = k.split("__")[1];
    return {
      column: "",
      row: "",
      [variableName]: varValue,
      [otherVarN]: otherVarValue,
      data: [
        Object.keys(mapValueOccurences[k].sum).reduce(
          (acc: { [something: string]: number }, curr) => {
            switch (aggregator) {
              case TABLE_AGGREGATOR.MEAN: {
                acc[curr] =
                  mapValueOccurences[k].sum[curr] / mapValueOccurences[k].nb;
                break;
              }
              case TABLE_AGGREGATOR.MIN: {
                acc[curr] = mapValueOccurences[k].min[curr];
                break;
              }
              case TABLE_AGGREGATOR.MAX: {
                acc[curr] = mapValueOccurences[k].max[curr];
                break;
              }
              case TABLE_AGGREGATOR.BOOLEAN: {
                acc[curr] = mapValueOccurences[k].pres[curr];
                break;
              }
              default: {
                acc[curr] = mapValueOccurences[k].sum[curr];
              }
            }
            return acc;
          },
          {}
        ),
      ],
    };
  });
};

const getPivotName = (
  columnPossibilities: TPivotOption[],
  rowPossibilities: TPivotOption[],
  pivotValue: string,
  rowOrColumn: "row" | "column",
  levelType: string
): string => {
  const pivotPossibilities =
    rowOrColumn === "row" ? rowPossibilities : columnPossibilities;
  const fullPivot = pivotPossibilities!.find((p) => p.value === pivotValue);
  if (fullPivot) {
    return fullPivot[levelType];
  } else return "unknown";
};
