/**
 * @notes: Action Types for Group management
 */

//create action types for teams
export const CREATE_TEAM_BEGIN = "CREATE_TEAM_BEGIN";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";
export const CREATE_TEAMS_BEGIN = "CREATE_TEAMS_BEGIN";
export const CREATE_TEAMS_SUCCESS = "CREATE_TEAMS_SUCCESS";
export const CREATE_TEAMS_FAILURE = "CREATE_TEAMS_FAILURE";

// action types for fetching teams for a single client
export const FETCH_TEAMS_FOR_CLIENT_BEGIN = "FETCH_TEAMS_FOR_CLIENT_BEGIN";
export const FETCH_TEAMS_FOR_CLIENT_SUCCESS = "FETCH_TEAMS_FOR_CLIENT_SUCCESS";
export const FETCH_TEAMS_FOR_CLIENT_FAILURE = "FETCH_TEAMS_FOR_CLIENT_FAILURE";

// update action types for teams
export const UPDATE_TEAMS_BEGIN = "UPDATE_TEAMS_BEGIN";
export const UPDATE_TEAMS_SUCCESS = "UPDATE_TEAMS_SUCCESS";
export const UPDATE_TEAMS_FAILURE = "UPDATE_TEAMS_FAILURE";

// delete action types for teams
export const DELETE_TEAMS_BEGIN = "DELETE_TEAMS_BEGIN";
export const DELETE_TEAMS_SUCCESS = "DELETE_TEAMS_SUCCESS";
export const DELETE_TEAMS_FAILURE = "DELETE_TEAMS_FAILURE";

// archive action types for teams
export const ARCHIVE_TEAMS_BEGIN = "ARCHIVE_TEAMS_BEGIN";
export const ARCHIVE_TEAMS_SUCCESS = "ARCHIVE_TEAMS_SUCCESS";
export const ARCHIVE_TEAMS_FAILURE = "ARCHIVE_TEAMS_FAILURE";

// restore action types for teams
export const RESTORE_TEAMS_BEGIN = "RESTORE_TEAMS_BEGIN";
export const RESTORE_TEAMS_SUCCESS = "RESTORE_TEAMS_SUCCESS";
export const RESTORE_TEAMS_FAILURE = "RESTORE_TEAMS_FAILURE";

export const REMOVE_USERS_FROM_ALL_TEAMS_SUCCESS =
  "REMOVE_USERS_FROM_ALL_TEAMS_SUCCESS";
export const REMOVE_WORKFLOWS_FROM_ALL_TEAMS_SUCCESS =
  "REMOVE_WORKFLOWS_FROM_ALL_TEAMS_SUCCESS";
export const REMOVE_ACTIVITIES_FROM_ALL_TEAMS_SUCCESS =
  "REMOVE_ACTIVITIES_FROM_ALL_TEAMS_SUCCESS";

// assign mobile users to teams
export const ASSIGN_MOBILE_USERS_TO_TEAMS_BEGIN =
  "ASSIGN_MOBILE_USERS_TO_TEAMS_BEGIN";
export const ASSIGN_MOBILE_USERS_TO_TEAMS_FAILURE =
  "ASSIGN_MOBILE_USERS_TO_TEAMS_FAILURE";
export const ASSIGN_MOBILE_USERS_TO_TEAMS_SUCCESS =
  "ASSIGN_MOBILE_USERS_TO_TEAMS_SUCCESS";

// assign web users to teams
export const ASSIGN_WEB_USERS_TO_TEAMS_BEGIN =
  "ASSIGN_WEB_USERS_TO_TEAMS_BEGIN";
export const ASSIGN_WEB_USERS_TO_TEAMS_FAILURE =
  "ASSIGN_WEB_USERS_TO_TEAMS_FAILURE";
export const ASSIGN_WEB_USERS_TO_TEAMS_SUCCESS =
  "ASSIGN_WEB_USERS_TO_TEAMS_SUCCESS";

// assign workflows to teams
export const ASSIGN_WORKFLOWS_TO_TEAMS_BEGIN =
  "ASSIGN_WORKFLOWS_TO_TEAMS_BEGIN";
export const ASSIGN_WORKFLOWS_TO_TEAMS_FAILURE =
  "ASSIGN_WORKFLOWS_TO_TEAMS_FAILURE";
export const ASSIGN_WORKFLOWS_TO_TEAMS_SUCCESS =
  "ASSIGN_WORKFLOWS_TO_TEAMS_SUCCESS";

// unassign users from teams
export const UNASSIGN_USERS_FROM_TEAMS_BEGIN =
  "UNASSIGN_USERS_FROM_TEAMS_BEGIN";
export const UNASSIGN_USERS_FROM_TEAMS_FAILURE =
  "UNASSIGN_USERS_FROM_TEAMS_FAILURE";
export const UNASSIGN_USERS_FROM_TEAMS_SUCCESS =
  "UNASSIGN_USERS_FROM_TEAMS_SUCCESS";

export const UPDATE_HIERARCHY_BEGIN = "UPDATE_HIERARCHY_BEGIN";
export const UPDATE_HIERARCHY_FAILURE = "UPDATE_HIERARCHY_FAILURE";
export const UPDATE_HIERARCHY_SUCCESS = "UPDATE_HIERARCHY_SUCCESS";

export const SELECT_TEAM = "SELECT_TEAM";

export const CLEAR_DATA = "CLEAR_DATA";

export const CHANGE_SUBCATEGORY_SELECTED_SUCCESS =
  "CHANGE_TEAM_SUBCATEGORY_SELECTED_SUCCESS";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";
