import { IPricesTable } from "fieldpro-tools";
import _ from "lodash";

import { IAction } from "redux/store/model";

import { FetchPricesSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IErpPrice extends IPricesTable {}

export interface IPricesState {
  prices: IErpPrice[];
  count: number;
  isFetchingPrices: boolean;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_PRICES_SUCCESS: {
      const { prices, count } = action as FetchPricesSuccessAction;

      const newPrices = _.concat(state.prices ?? [], prices);

      return {
        ...state,
        isFetchingPrices: false,
        prices: newPrices,
        count: count,
      };
    }

    case types.FETCH_PRICES_BEGIN:
      return {
        ...state,
        isFetchingPrices: true,
      };
    case types.FETCH_PRICES_FAILURE:
      return {
        ...state,
        isFetchingPrices: false,
      };
    default:
      return state;
  }
}
