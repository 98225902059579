import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";

interface IHandleExactSearch {
  rows: any[];
  searchTerm?: string;
  columnTypes: TColumnType[];
}

// TODO: add tests
/**
 * Handles exact search and returns the items with an exact match
 * */
const handleExactSearch = ({
  rows,
  searchTerm,
  columnTypes,
}: IHandleExactSearch) => {
  if (!searchTerm) {
    return rows;
  }

  const result = [];
  for (const row of rows) {
    for (const columnType of columnTypes) {
      const attr = columnType.name;
      if (
        attr === "#" ||
        /*attr === "id" || attr === "_id" ||*/ attr === "active"
      ) {
        // seach does not apply for id column
        continue;
      }
      if (!columnType.type) columnType.type = TABLE_COLUMN_TYPE.TEXT;
      if (
        [TABLE_COLUMN_TYPE.PICTURE, TABLE_COLUMN_TYPE.GPS].includes(
          columnType.type
        )
      ) {
        // search does not apply for picture or gps column
        continue;
      }
      if (typeof row[columnType.name] === "string") {
        if (
          row[columnType.name]
            .toLowerCase()
            .includes(searchTerm!.toLowerCase()) ||
          row[columnType.name]
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .includes(searchTerm!.toLowerCase())
        ) {
          result.push(row);
          break;
        }
      }
    }
  }

  return result;
};

export default handleExactSearch;
