import { useState } from "react";

import { Box, Button, makeStyles } from "@material-ui/core";

import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

import FormSectionBackground from "./FormSectionBackground";
import FormSectionHeader from "./FormSectionHeader";

interface IRenderProps {
  viewMode: TViewMode;
}

interface IFormSectionBase {
  editText?: string;
  discardText?: string;
  deleteText?: string;
  saveText?: string;
  onSave?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onDiscard?: () => void;
  disableSave?: boolean;
  paperTitle?: string;
  paperDescription?: string;
  disableEdit?: boolean;
  viewOnly?: boolean;
}
interface IFormSectionEdit extends IFormSectionBase {
  isCreation?: false;
  children: ({ viewMode }: IRenderProps) => React.ReactNode;
}

interface IFormSectionCreate extends IFormSectionBase {
  isCreation: true;
  children: React.ReactNode;
}

export type TFormSection = IFormSectionCreate | IFormSectionEdit;

const useStyles = makeStyles({
  FormSectionButtons: {
    marginTop: "32px",
  },
});

export const FORM_CONTENT_MAX_WIDTH = 1100;

const FormSection = (props: TFormSection) => {
  const lang = useTranslations();

  const {
    editText = lang.genericTerms.editDetails,
    discardText = lang.genericTerms.discardChanges,
    deleteText = lang.genericTerms.delete,
    saveText = lang.genericTerms.saveChanges,
    isCreation = false,
    onSave,
    onEdit,
    onDelete,
    onDiscard,
    disableSave,
    disableEdit,
    viewOnly = false,
  } = props;

  const classes = useStyles();
  const [randomId, setRandomId] = useState(Math.random());
  const [viewMode, setViewMode] = useState<TViewMode>(
    isCreation ? "CREATE" : "VIEW"
  );

  const onClickEdit = () => {
    if (onEdit) {
      onEdit();
    }
    setViewMode("EDIT");
  };

  const onClickSave = () => {
    if (onSave) {
      onSave();
    }
    if (viewMode === "EDIT") {
      setViewMode("VIEW");
    }
  };

  const onClickDiscard = () => {
    if (onDiscard) {
      onDiscard();
    }
    if (viewMode === "EDIT") {
      setViewMode("VIEW");
    }

    // Hack to reset inputs when discarding changes
    setRandomId(Math.random());
  };

  let content;
  if (props.isCreation) {
    content = props.children;
  } else {
    content = props.children({ viewMode });
  }

  return (
    <FormSectionBackground
      key={randomId} // Hack to reset inputs when discarding changes
    >
      {(props.paperTitle || props.paperDescription) && (
        <FormSectionHeader
          title={props.paperTitle}
          description={props.paperDescription}
        />
      )}

      <Box maxWidth={`${FORM_CONTENT_MAX_WIDTH}px`}>
        {content}

        {viewMode === "VIEW" && !viewOnly && (
          <div className={classes.FormSectionButtons}>
            <Button
              color="default"
              variant="contained"
              disableElevation
              onClick={onClickEdit}
              disabled={disableEdit}
            >
              {editText}
            </Button>

            {onDelete && (
              <Button
                style={{ marginLeft: 16 }}
                color="default"
                variant="outlined"
                disableElevation
                onClick={onDelete}
              >
                {deleteText}
              </Button>
            )}
          </div>
        )}

        {viewMode === "EDIT" && !viewOnly && (
          <div className={classes.FormSectionButtons}>
            <Button
              color="default"
              variant="outlined"
              disableElevation
              onClick={onClickDiscard}
            >
              {discardText}
            </Button>

            <Button
              style={{ marginLeft: 16 }}
              color="secondary"
              variant="contained"
              disableElevation
              onClick={onClickSave}
              disabled={!!disableSave}
            >
              {saveText}
            </Button>
          </div>
        )}
      </Box>
    </FormSectionBackground>
  );
};

export default FormSection;
