import React from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";

import { GreyDark } from "assets/colors";

const LegendLabel: React.FC<{ label?: string }> = ({ children, label }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        fontSize={12}
        color={GreyDark}
        fontWeight={500}
        textAlign={"center"}
        paddingBottom={"2px"}
      >
        {_.upperFirst(_.lowerCase(label))}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default LegendLabel;
