import { Skeleton } from "@mui/material";
import omit from "lodash/omit";

import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";

export const getRowId = (rowItem: any): string => {
  return rowItem._id ? rowItem._id : rowItem.id;
};

export const checkIfDeletePossible = (rowItem: any): boolean => {
  let isDeletePossible = false;
  if (!rowItem.hasOwnProperty("_active")) {
    if (!rowItem.hasOwnProperty("active")) {
      // no attribute _active nor active => we consider the item can be deleted
      isDeletePossible = true;
    } else {
      isDeletePossible = !rowItem.active;
    }
  } else {
    isDeletePossible = !rowItem._active;
  }
  return isDeletePossible;
};
export const isStopExecutionPossible = (rowItem: any): boolean => {
  return rowItem.hasOwnProperty("status") && rowItem.status === "ONGOING";
};
export const isContinueExecutionPossible = (rowItem: any): boolean => {
  if (rowItem.hasOwnProperty("initiated_by")) return true; // a bit hacky... This concerns the screen "ASSIGNED TO ME"
  return rowItem.hasOwnProperty("status") && rowItem.status === "ONGOING"; // This concerns the screen "WORKFLOW"
};
export const isResumeExecutionPossible = (rowItem: any): boolean => {
  return (
    rowItem.hasOwnProperty("status") &&
    (rowItem.status === "CANCELLED" || rowItem.status === "FAILURE")
  ); // This concerns the screen "WORKFLOW"
};
export const isActionBlockedForRow = (rowItem: any): boolean => {
  return rowItem.hasOwnProperty("_block_actions") && rowItem._block_actions;
};
export const checkIfActive = (rowItem: any): boolean => {
  let isActive = true;
  if (rowItem.hasOwnProperty("_active") || rowItem.hasOwnProperty("active")) {
    isActive = rowItem.active ?? rowItem._active;
  }
  return isActive;
};

/**
 * Cleans the row item and returns the row item without unwanted data
 * @private
 * @param {Object} item Row item object
 * @returns {Object} cleaned row item with the filtered out key value pairs
 */
export const cleanedRowItem = (
  item: any,
  attrToHide: string[] | undefined,
  columnTypes: TColumnType[]
) => {
  if (!attrToHide)
    attrToHide = ["_id", "_active", "_is_loading", "_block_actions"];
  const isLoading = item._is_loading;
  const result: any = {};
  const re = new RegExp("^__.*$"); // All the attributes starting by "__" should not be shown

  // the id of the item is either "_id" or "id". If there is an attribute "_id", then the id is "_id"
  let cleanId = false;
  if (!item.hasOwnProperty("_id")) {
    cleanId = true;
  }

  // the "active" property of the item is either "_active" or "active". If there is an attribute "_active",
  // then the active is "_active"
  let cleanActive = false;
  if (!item.hasOwnProperty("_active")) {
    cleanActive = true;
  }

  // walk through item object and filter out key-value pairs not to be displayed
  for (const prop in item) {
    // if not match, this is the data we want to display
    if (attrToHide && !attrToHide.includes(prop) && !re.test(prop)) {
      if (
        prop === "id" &&
        cleanId &&
        !columnTypes.map((c) => c.name).includes("id")
      ) {
        // clean "id" attribute if it is a real id (except if explicitelly defined in the columns)
      } else if (
        prop === "active" &&
        cleanActive &&
        !columnTypes.map((c) => c.name).includes("active")
      ) {
        // clean "active" attribute if it is the real property (except if explicitelly defined in the columns)
      } else {
        result[prop] = item[prop];
      }
    }
  }

  if (isLoading) {
    return Object.keys(omit(result, "select")).map((att, i) => {
      let val = "";
      let component = null;

      if (i === 0) {
        val = "Loading...";
        component = <Skeleton height={30} />;
      }
      return {
        columnName: att,
        value: val,
        columnType: "TEXT",
        component,
      };
    });
  }

  // return an array of objects containing the name of the attribute, the value, and the type of the attribute
  const newColumnType = columnTypes.map((ct) => ({
    columnName: ct.name,
    value: getValue(ct, result),
    columnType: ct.type ? ct.type : "TEXT",
  }));
  return newColumnType;
};

const getValue = (ct: TColumnType, result: any) => {
  if (
    ct.type === TABLE_COLUMN_TYPE.BOOLEAN ||
    ct.type === TABLE_COLUMN_TYPE.SWITCH
  ) {
    return result[ct.name] === "TRUE" || result[ct.name] === true;
  }

  return result[ct.name];
};
