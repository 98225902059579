import { ListsState } from "./reducer";

const initialState: ListsState = {
  fetchingListsForClient: false,
  fetchingObjectsForList: false,
  isFetchingList: false,
  isUpdatingList: false,
  isDeletingList: false,
  isArchivingList: false,
  isRestoringList: false,
  isCreatingList: false,
  isFetchingItems: false,
  isFetchingOneItem: false,
  isUpdatingItems: false,
  isArchivingItems: false,
  isRestoringItems: false,
  isDeletingItems: false,
  isCreatingItems: false,
  isUpdatingItem: false,
  isArchivingItem: false,
  isRestoringItem: false,
  isDeletingItem: false,
  isCreatingItem: false,
  isUploadingFile: false,
  isAssigningItems: false,
  isUnassigningItems: false,
  isFindDuplicatesItems: false,
  isChangingTag: false,
  isDownloadingFile: false,
  lastUpdated: undefined,
  allLists: [],
  selectedList: undefined,
  isFetchingCustomerReportsPictures: false,
  selectedCustomerPictures: [],
  byId: {},
  allIds: [],
  errors: [],
};

export default initialState;
