import { Box, Button } from "@material-ui/core";
import { diffInDays } from "fieldpro-tools";

import {
  getActivePlanId,
  getClientPlan,
  hasDefaultPaymentMethod,
  isClientBillingType,
  isClientStatus,
} from "containers/authentication/utils/clientStatus";
import useOpenStripeCheckout from "hooks/useOpenStripeCheckout";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { BILLING_TYPE, CLIENT_STATUS, IClient } from "model/entities/Client";

import StripePortalButton from "../StripePortalButton";

interface IUpgradeNudge {
  client: IClient;
}

const UpgradeNudge = ({ client }: IUpgradeNudge) => {
  const lang = useTranslations();
  const trialLang =
    lang.containers.clients.subCategories.clients.createEditModal.trial
      .customMessage;

  const plan = getClientPlan(client);
  const clientHasCanceledProPlan =
    plan?.id === "pro" && plan?.status === "canceled";

  const openStripeCheckout = useOpenStripeCheckout({
    clientId: client.id,
    withTrial: !clientHasCanceledProPlan,
  });

  // const [openPricingTable, setOpenPricingTable] = useState(false);

  if (
    !isClientBillingType(client, BILLING_TYPE.AUTOMATED) ||
    !isClientStatus(client, [CLIENT_STATUS.TRIAL, CLIENT_STATUS.STARTER])
  ) {
    return null;
  }

  // This means the client tried pro and decided to downgrade at some point
  if (
    isClientStatus(client, CLIENT_STATUS.STARTER) &&
    clientHasCanceledProPlan
  ) {
    return (
      <Button
        color="secondary"
        variant="contained"
        disableElevation
        onClick={openStripeCheckout}
      >
        {lang.actions.upgrade}
      </Button>
    );
  }

  if (getActivePlanId(client) !== "pro") {
    return (
      <Button
        color="secondary"
        variant="contained"
        disableElevation
        onClick={openStripeCheckout}
      >
        {trialLang.tryPro}
      </Button>
    );
  }

  if (hasDefaultPaymentMethod(client)) {
    return null;
  }

  // NOTE: use Stripe's subscription.trial_end if this is inaccurate.
  let daysLeft;
  const nextBillingDateStr = client.billing?.license_plan?.next_billing_date;
  if (nextBillingDateStr) {
    daysLeft = Math.max(
      0,
      diffInDays(new Date(), new Date(nextBillingDateStr))
    );
  }

  return (
    <Box display="flex" alignItems="center" flexShrink={0} gridGap={"16px"}>
      {daysLeft && (
        <Box
          width={"max-content"}
          dangerouslySetInnerHTML={{
            __html: formatString(trialLang.daysLeft, [
              `${daysLeft > 0 ? daysLeft : 0}`,
            ]),
          }}
        />
      )}

      <StripePortalButton
        clientId={client.id}
        text={trialLang.addPaymentMethod}
      />
    </Box>
  );
};

export default UpgradeNudge;
