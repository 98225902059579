import { ReactNode } from "react";

import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import { BlueChalk } from "assets/colors";
import CustomTooltip from "components/Tooltip/CustomTooltip";
import { TViewMode } from "model/application/modal/CreateEditModal";

import ErrorMessage from "../ErrorMessage";
import InputLabel from "../InputLabel/InputLabel";

export type ExtraProps = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof IInputBaseLayout
>;

export interface IInputBaseLayout {
  dataTestId?: string;
  label?: string;
  tooltip?: string;
  error?: string;
  required?: boolean;
  viewMode?: TViewMode;
  children?: React.ReactNode;
  viewStacked?: boolean;
  role?: string;
  htmlFor?: string;
  labelIcons?: ReactNode[];
  highlightContent?: boolean;
  style?: React.CSSProperties;
  disabledReason?: string;
  disabled?: boolean;
}

const LABEL_WIDTH = "320px"; // Arbitrary, but most labels should not wrap or wrap nicely
const MARGIN_RIGHT = "24px";

const useStyles = makeStyles({
  InputBaseLayout: ({ viewMode, viewStacked }: IInputBaseLayout) => ({
    display: "flex",
    flexGrow: 1,

    flexBasis: 0, // equal width items,
    "&:not(.INLINE)": {
      flexDirection: "column",
      padding:
        viewStacked || viewMode === "CREATE" ? "8px 0px 24px 0px" : "8px 0px",
      "& label": {
        marginBottom: 0, // to remove bootstrap.css styling
      },
    },
  }),
  LabelAndInputContainer: ({ viewStacked }: IInputBaseLayout) => ({
    display: "flex",
    "&.CREATE": {
      flexDirection: "column",
    },
    "&.VIEW": {
      flexDirection: viewStacked ? "column" : "initial",
      alignItems: viewStacked ? "initial" : "center",
    },
  }),
  LabelContainer: ({ viewStacked }: IInputBaseLayout) => ({
    flexShrink: 0,
    "&:not(.CREATE)": {
      width: LABEL_WIDTH,
      marginRight: MARGIN_RIGHT,
    },
    "&.CREATE": {
      marginBottom: "12px",
    },
    "&.VIEW": {
      marginBottom: viewStacked ? "12px" : "initial",
    },
    "&:not(.VIEW)": {
      ...(viewStacked && {
        width: LABEL_WIDTH,
        marginRight: MARGIN_RIGHT,
      }),
    },
  }),
  InputContainer: ({ viewStacked, disabled }: IInputBaseLayout) => ({
    display: "flex",
    flexGrow: 1,

    flexDirection: "column",
    "&:not(.VIEW)": {
      opacity: disabled ? `0.5 !important` : undefined,
    },

    "&:not(.CREATE)": {
      width: viewStacked
        ? "initial"
        : `calc(100%  - ${LABEL_WIDTH} - ${MARGIN_RIGHT})`,
    },
  }),
  ErrorContainer: ({ viewStacked }: IInputBaseLayout) => ({
    "&:not(.CREATE)": {
      marginLeft: viewStacked
        ? "initial"
        : `calc(${LABEL_WIDTH} + ${MARGIN_RIGHT})`,
    },
  }),
  highlighted: ({ highlightContent }: IInputBaseLayout) => ({
    ...(highlightContent && {
      backgroundColor: BlueChalk,
      borderRadius: "5px",
      padding: "4px",
      minHeight: "40px",
      display: "grid",
      alignItems: "center",
      alignContent: "center",
    }),
  }),
});

const InputBaseLayout = ({
  dataTestId,
  htmlFor,
  label,
  tooltip,
  children,
  error,
  viewMode = "CREATE",
  required,
  viewStacked = false,
  labelIcons,
  role,
  highlightContent,
  style,
  disabledReason,
  disabled,
}: IInputBaseLayout) => {
  const classes = useStyles({
    viewMode,
    viewStacked,
    highlightContent,
    disabled,
  });

  if (viewMode === "INLINE") {
    return (
      <Box
        data-testid={dataTestId}
        className={classNames(classes.InputBaseLayout, viewMode)}
        role={role}
        style={style}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box
      data-testid={dataTestId}
      className={classNames(classes.InputBaseLayout, viewMode)}
      role={role}
      style={style}
    >
      <Box className={classNames(classes.LabelAndInputContainer, viewMode)}>
        <Box className={classNames(classes.LabelContainer, viewMode)}>
          <InputLabel
            title={label}
            tooltip={tooltip}
            required={required}
            viewMode={viewMode}
            htmlFor={htmlFor}
            icons={labelIcons}
          />
        </Box>

        <Box
          className={classNames(
            classes.InputContainer,
            viewMode,
            highlightContent ? classes.highlighted : ""
          )}
        >
          {disabledReason ? (
            <CustomTooltip
              title={disabledReason}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    flip: { enabled: false },
                    offset: {
                      enabled: true,
                      offset: "-100px, -20px",
                    },
                  },
                },
              }}
              placement={"top"}
            >
              {<div>{children}</div>}
            </CustomTooltip>
          ) : (
            children
          )}
        </Box>
      </Box>

      <Box className={classNames(classes.ErrorContainer, viewMode)}>
        {error && <ErrorMessage error={error} />}
      </Box>
    </Box>
  );
};

export default InputBaseLayout;
