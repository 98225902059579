export const CREATE_WEB_USERS_BEGIN = "CREATE_WEB_USERS_BEGIN";
export const CREATE_WEB_USERS_SUCCESS = "CREATE_WEB_USERS_SUCCESS";
export const CREATE_WEB_USERS_FAILURE = "CREATE_WEB_USERS_FAILURE";

export const UPDATE_WEB_USER_BEGIN = "UPDATE_WEB_USER_BEGIN";
export const UPDATE_WEB_USER_SUCCESS = "UPDATE_WEB_USER_SUCCESS";
export const UPDATE_WEB_USER_FAILURE = "UPDATE_WEB_USER_FAILURE";

export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const FETCH_WEB_USERS_BY_CLIENT_BEGIN =
  "FETCH_WEB_USERS_BY_CLIENT_BEGIN";
export const FETCH_WEB_USERS_BY_CLIENT_SUCCESS =
  "FETCH_WEB_USERS_BY_CLIENT_SUCCESS";
export const FETCH_WEB_USERS_BY_CLIENT_FAILURE =
  "FETCH_WEB_USERS_BY_CLIENT_FAILURE";

export const DELETE_WEB_USER_BEGIN = "DELETE_WEB_USER_BEGIN";
export const DELETE_WEB_USER_SUCCESS = "DELETE_WEB_USER_SUCCESS";
export const DELETE_WEB_USER_FAILURE = "DELETE_WEB_USER_FAILURE";

export const DELETE_WEB_USERS_BEGIN = "DELETE_WEB_USERS_BEGIN";
export const DELETE_WEB_USERS_SUCCESS = "DELETE_WEB_USERS_SUCCESS";
export const DELETE_WEB_USERS_FAILURE = "DELETE_WEB_USERS_FAILURE";

export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CLEAR_DATA = "CLEAR_DATA";
export { LOGOUT_REQUEST_SUCCESS } from "../../../authentication/redux/actionTypes";
