import React from "react";

import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import Warning from "@material-ui/icons/Warning";
import _ from "lodash";

import { IDSource } from "__utils__/utils";
import { warningColor } from "assets/colors";
import IAppNotification, {
  LevelNotification,
} from "model/application/Notification";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "600px",
    top: "65px",
    right: "10px",
    padding: "10px 10px",
    overflow: "auto",
    maxHeight: "300px",
    zIndex: 99999999999999,
  },
  blocIcon: {
    textAlign: "center",
    alignSelf: "center",
  },
  warningIcon: {
    color: warningColor,
    fontSize: "30px",
  },
  labelNotification: {
    padding: "10px 10px",
  },
});

interface IAppNotificationsProps {
  appNotifications: IAppNotification[];
  onClickAway: () => void;
}

const AppNotifications: React.FunctionComponent<IAppNotificationsProps> = ({
  appNotifications,
  onClickAway,
}) => {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper className={classes.root}>
        <List>
          {_.map<React.ReactNode>(appNotifications, (n: IAppNotification) => (
            <ListItem alignItems="flex-start" key={n.id}>
              {n.level === LevelNotification.Warning && (
                <ListItemAvatar className={classes.blocIcon}>
                  <Warning className={classes.warningIcon} />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={n.label}
                className={classes.labelNotification}
              />
            </ListItem>
          )).reduce(
            (prev, curr) => [prev, <hr key={IDSource()} />, curr] as any
          )}
        </List>
      </Paper>
    </ClickAwayListener>
  );
};

export default AppNotifications;
