import { makeStyles } from "@material-ui/core";

import CustomFullScreenDialog from "../CustomFullScreenDialog";

interface IPictureDialog {
  url?: string;
  open?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  PictureDialog: {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "calc(100vh - 100px)",
    maxWidth: "calc(100vw - 100px)",
    transform: "translate(-50%, -50%)",
  },
});

const PictureDialog = ({ url, open, onClose }: IPictureDialog) => {
  const classes = useStyles();
  if (!url) {
    return null;
  }
  return (
    <CustomFullScreenDialog open={open} onClose={onClose}>
      <img className={classes.PictureDialog} src={url} alt={url} />
    </CustomFullScreenDialog>
  );
};

export default PictureDialog;
