import _ from "lodash";

import { META_EXPRESSION_REGEX } from "components/Input/InputMetaExpression/InputMetaExpressionField";
import TLang from "model/application/Lang";
import { TExpression } from "model/entities/Expression";
import { formatOperandForChip } from "utils/expressions";

import { getOperatorMap } from "../getOperatorMap";

interface IOperatorPart {
  type: "OPERATOR";
  value: string;
}

interface IChipPart {
  type: "CHIP";
  value: string;
}

export type TFormattedExpression = (string | IOperatorPart | IChipPart)[];

interface IFormatExpression {
  expression: TExpression;
  expressionsArray: TExpression[];
}

const formatExpression = ({
  expression,
  expressionsArray,
}: IFormatExpression): TFormattedExpression => {
  if (_.isEmpty(expression)) {
    return [];
  }
  const leftHandPart = formatOperand(expression.first, expressionsArray);
  const rightHandPart = formatOperand(expression.second, expressionsArray);
  const operatorPart = formatOperator(expression.operator);

  return _.compact([...leftHandPart, operatorPart, ...rightHandPart]);
};

const formatOperand = (
  operand: any,
  expressionsArray: TExpression[]
): TFormattedExpression => {
  if (!operand.hasOwnProperty("result_id")) {
    const label = formatOperandForChip(operand, [], undefined, undefined);
    return _.compact([formatChip(label)]);
  } else {
    const innerExpression = expressionsArray.filter(
      (expression) => expression.id === operand.result_id
    )[0];

    const innerFormatted = formatExpression({
      expression: innerExpression,
      expressionsArray,
    });
    return ["(", ...innerFormatted, ")"];
  }
};

export const formatChip = (label?: string): IChipPart | null => {
  if (!label) {
    return null;
  }
  return {
    type: "CHIP",
    value: label,
  };
};

const formatOperator = (operator: string): IOperatorPart | null => {
  if (!operator) {
    return null;
  }
  return {
    type: "OPERATOR",
    value: operator,
  };
};

interface IReplaceOperators {
  formattedExpression: TFormattedExpression;
  lang: TLang;
}

export const replaceOperators = ({
  formattedExpression,
  lang,
}: IReplaceOperators) => {
  const operatorMap = getOperatorMap(lang);
  return _.map(formattedExpression, (part) => {
    if (_.isObject(part) && part?.type === "OPERATOR") {
      return {
        ...part,
        value: operatorMap[part.value],
      };
    }
    return part;
  });
};

interface IReplaceMetaExpressions {
  formattedExpression: TFormattedExpression;
  metaExpressions: { title: string; expression: string }[];
}

export const replaceMetaExpressions = ({
  formattedExpression,
  metaExpressions,
}: IReplaceMetaExpressions) => {
  return _.map(formattedExpression, (part) => {
    if (
      _.isObject(part) &&
      part?.type === "CHIP" &&
      (part?.value || "").match(META_EXPRESSION_REGEX)
    ) {
      const expression = part.value
        .replace(/\$\{/g, "")
        .replace(/\}/g, "")
        .replace(/\$\^/g, "")
        .replace(/\^/g, "");
      const relatedMeta = _.find(
        metaExpressions,
        (me) => me.expression === expression
      );

      return {
        ...part,
        value: relatedMeta?.title || part.value,
      };
    }
    return part;
  });
};

export default formatExpression;
