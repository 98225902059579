import { ComponentProps } from "react";

import { CircleLayer, Layer, SymbolLayer } from "react-map-gl";

import { White } from "assets/colors";

export const CUSTOMER_CIRCLES_LAYER_ID = "customer-circles-layer";
export const CUSTOMER_PINS_LAYER_ID = "customer-pins-layer";

export const customersCircleLayer = (radius: number) =>
  ({
    id: CUSTOMER_CIRCLES_LAYER_ID,
    type: "circle",
    paint: {
      "circle-color": ["get", "color"],
      "circle-radius": radius,
      "circle-stroke-width": Math.min(radius / 3, 2),
      "circle-stroke-color": White,
      // "circle-radius": {
      //   // TODO: adjust to real-world data (+ proper interpolation ?)
      //   stops: [
      //     [8, 1],
      //     [15, 10],
      //     [20, 15],
      //     [25.5, 40],
      //   ],
      // },
    },
  } as CircleLayer);

export const CUSTOMER_PIN_ID = "customer-pin";
export const DELIM_PATTERN_ID = "lines";

/**
 * Arbitrary const, to resize pin icon when it's too big/small by default
 * (compared to circle layer for which size param is the exact radius)
 */
const PIN_SCALER = 0.2;

// See https://docs.mapbox.com/help/troubleshooting/using-recolorable-images-in-mapbox-maps/
// Beware: https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/#symbol-layers
export const customersSymbolLayer = (
  size: number,
  allowOverlap: boolean = true
) =>
  ({
    id: CUSTOMER_PINS_LAYER_ID,
    type: "symbol",
    layout: {
      "icon-image": CUSTOMER_PIN_ID,
      "icon-size": size * PIN_SCALER,
      "icon-allow-overlap": !!allowOverlap,
    },
    paint: {
      "icon-color": ["get", "color"],
      // Does not achieve what we want, see:
      // https://github.com/mapbox/mapbox-gl-js/issues/11504 and https://github.com/mapbox/mapbox-gl-js/issues/4709
      // "icon-halo-color": "rgba(255, 255, 255, 1)",
      // "icon-halo-width": 5,
      // "icon-halo-blur": 0,
    },
  } as SymbolLayer);

export const customerGeoDelimLineLayerStyle: ComponentProps<typeof Layer> = {
  id: "customers-geo-delimitations-line",
  type: "line",
  paint: {
    "line-color": ["get", "color"],
    "line-width": 2,
    "line-opacity": [
      "case",
      ["boolean", ["feature-state", "hidden"], false],
      0,
      ["case", ["boolean", ["feature-state", "hovered"], false], 0.3, 0.1],
    ],
  },
  minzoom: 0,
  maxzoom: 22,
};

export const customerGeoDelimFillLayerStyle: ComponentProps<typeof Layer> = {
  id: "customers-geo-delimitations-fill",
  type: "fill",
  paint: {
    "fill-color": ["get", "color"],
    "fill-opacity": [
      "case",
      ["boolean", ["feature-state", "hidden"], false],
      0,
      ["case", ["boolean", ["feature-state", "hovered"], false], 0.5, 0.4],
    ],
  },
  minzoom: 0,
  maxzoom: 22,
};

export const customerGeoLelimLinePatternLayerStyle: ComponentProps<
  typeof Layer
> = {
  id: "customers-geo-delimitations-line-pattern",
  type: "fill",
  paint: {
    "fill-pattern": DELIM_PATTERN_ID,
    "fill-opacity": 0.2,
  },
};
