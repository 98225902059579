import { Box, makeStyles } from "@material-ui/core";

export interface IBody extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

const styles = {
  body: {
    width: "100%",
    padding: "8px",
    paddingRight: "16px",
    overflowY: "auto",
    position: "relative",
  },
};

const useStyles = makeStyles(styles as any);

function Body({ children, ...rest }: IBody) {
  const classes = useStyles();

  return (
    <Box {...rest} className={classes.body} data-testid="body">
      {children}
    </Box>
  );
}

export default Body;
