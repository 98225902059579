import React, { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  GreyDark,
  GreyLight,
  secondaryColor,
  StrokeLowEnphasis,
} from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { IBoardProps } from "../Board";
import StackedBoard from "../StackedBoard";
import { computeNewDraggedEventForDayOnlyCalendar } from "../utils/computeNewDraggedEventForDayOnlyCalendar";
import { generateWorkingDates } from "../utils/dateStepper";
import {
  BOX_HEIGHT,
  getCalendarNumberOfDays,
  ICalendarProps,
} from "../WeeklyCalendar";

interface ICalendarStylesProps {
  width: string;
  height: string | number;
  daysCount: number;
  minWidth?: string;
  maxWidth?: string;
  draggable?: boolean;
}

const useStyles = makeStyles(() => ({
  container: ({
    width,
    maxWidth,
    minWidth,
    height,
    daysCount,
  }: ICalendarStylesProps) => ({
    height,
    overflowY: "scroll",
    overflowX: "hidden",
    width: width,
    background: "white",
    display: "grid",
    gridTemplateColumns: `repeat(${daysCount},1fr)`,
    gridcolumnGap: "0px",
    boxShadow: `inset 0px 5px 3px -3px ${GreyLight}`,
    paddingTop: "0.2px",
    maxWidth,
    minWidth,
  }),
  boardContainer: ({
    width,
    daysCount,
    minWidth,
    maxWidth,
  }: ICalendarStylesProps) => ({
    width: `calc(${width} / ${daysCount})`,
    minWidth: minWidth,
    maxWidth: maxWidth,
  }),
  dialogHeader: ({ width }: ICalendarStylesProps) => ({
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15)",
    background: "white",
    width: `calc(${width} - 8px)`,
    display: "grid",
  }),
  box: {
    borderBottom: `2px solid ${StrokeLowEnphasis}`,
    height: `${BOX_HEIGHT}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    background: "white",
    color: GreyDark,
    fontSize: "13px",
    paddingTop: "8px",
  },
}));

export interface IDayOnlyCalendarProps
  extends Pick<
      ICalendarProps,
      | "defaultEvents"
      | "startDate"
      | "width"
      | "minWidth"
      | "maxWidth"
      | "height"
      | "draggable"
      | "onChange"
      | "activeBoardItems"
      | "eventPlaceHolders"
    >,
    Pick<
      IBoardProps,
      | "eventPlaceHolders"
      | "activeBoardItems"
      | "onClickApproveEvent"
      | "onClickDeclineEvent"
      | "onClickDeleteEvent"
      | "onClickTimeSlot"
      | "onEditEvent"
    > {}
const WeeklyCalendarDayOnlyView: React.FC<IDayOnlyCalendarProps> = ({
  defaultEvents,
  onClickApproveEvent,
  onClickDeclineEvent,
  onClickDeleteEvent,
  onClickTimeSlot,
  eventPlaceHolders,
  activeBoardItems,
  onEditEvent,
  onChange,
  startDate,
  maxWidth,
  minWidth,
  width = "auto",
  draggable,
  height = "calc(100vh - 230px)",
}) => {
  const [events, setEvents] = useState<TCalendarEvent[]>(defaultEvents);

  const client = useSelector(getSelectedClient);
  const { moment, timeZone } = useMomentTimeZone();
  const DAYS_COUNT = getCalendarNumberOfDays(client);

  const classes = useStyles({
    width,
    daysCount: DAYS_COUNT,
    minWidth,
    maxWidth,
    draggable,
    height,
  });
  const dates = generateWorkingDates(
    moment(startDate).toDate(),
    DAYS_COUNT,
    moment
  );

  const handleDragEnd: OnDragEndResponder = (result) => {
    const newEvents = computeNewDraggedEventForDayOnlyCalendar({
      dropResult: result,
      events: defaultEvents,
      moment,
    });

    if (!_.isEqual(events, newEvents)) {
      setEvents(newEvents);
      onChange && onChange(newEvents);
    }
  };

  useEffect(() => {
    setEvents(defaultEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultEvents)]);

  return (
    <Box>
      <Box
        className={classes.dialogHeader}
        key={JSON.stringify(dates) + timeZone}
      >
        <Box>{/* Empty space */}</Box>
        <Box
          display={"grid"}
          gridAutoFlow={"column"}
          gridTemplateColumns={`repeat(${DAYS_COUNT}, calc((${width} / ${DAYS_COUNT}) - 2px))`}
          boxShadow={"0px -7px 9px 1px grey"}
        >
          {_.map(dates, (date) => (
            <Box
              key={date}
              textAlign={"center"}
              paddingTop={"6px"}
              paddingBottom={"6px"}
              fontWeight={"500"}
              color={moment().isSame(date, "day") ? secondaryColor : undefined}
              borderBottom={
                moment().isSame(date, "day") ? "2px solid" : undefined
              }
            >
              {moment(date).format("ddd D")}
            </Box>
          ))}
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Box className={classes.container} key={DAYS_COUNT}>
          {_.map(dates, (date) => {
            const matchingEvents = events.filter((event) => {
              const isSameDay = moment(event.start_time)
                .startOf("day")
                .isSame(moment(date).startOf("day"));

              return isSameDay;
            });
            const matchingPlaceHolders = eventPlaceHolders?.filter((event) => {
              const isSameDay = moment(event.start_time)
                .startOf("day")
                .isSame(moment(date).startOf("day"));

              return isSameDay;
            });
            return (
              <StackedBoard
                key={date}
                onClickDeleteEvent={onClickDeleteEvent}
                onClickApproveEvent={onClickApproveEvent}
                onClickDeclineEvent={onClickDeclineEvent}
                onClickTimeSlot={onClickTimeSlot}
                onEditEvent={onEditEvent}
                boardId={date.toString()}
                events={matchingEvents}
                activeBoardItems={activeBoardItems}
                eventPlaceHolders={matchingPlaceHolders}
              />
            );
          })}
        </Box>
      </DragDropContext>
    </Box>
  );
};

export default WeeklyCalendarDayOnlyView;
