import { Feature, Point } from "geojson";
import _ from "lodash";

import { PRIMARY_COLOR_PROD } from "assets/colors";
import { ICustomerLegendFE } from "containers/customers/redux/types";

import { ICustomerLocation } from "../CustomersMap";

export type TCustomerFeature = Feature<Point, ICustomerProperties>;

export interface ICustomerProperties {
  customerId: string;
  color: string;
}

export interface IColorsByCustomer {
  [customerId: string]: {
    id: string;
    name: string;
    categoryKey: string;
  };
}

export const getCustomerFeatures = (
  customerLocations: ICustomerLocation[] | undefined,
  colorsByCustomer: IColorsByCustomer,
  selectedLegend: ICustomerLegendFE | undefined
) => {
  return _.map(customerLocations, (customer) =>
    getCustomerFeature(customer, colorsByCustomer, selectedLegend)
  );
};

// The backend could send one (or several chunks of) GeoJSON data directly.
// Could even be loaded from a file URL: https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/#store-geojson-at-url
const getCustomerFeature = (
  customer: ICustomerLocation,
  colorsByCustomer: IColorsByCustomer,
  selectedLegend: ICustomerLegendFE | undefined
) => {
  const customerCategoryKey = colorsByCustomer[customer._id]?.categoryKey;
  const legendCategory = selectedLegend?.categories.find(
    (c) =>
      c.key === (customerCategoryKey === null ? "null" : customerCategoryKey)
  );
  const color = legendCategory?.color || PRIMARY_COLOR_PROD;
  return {
    type: "Feature",
    properties: {
      customerId: customer._id,
      color,
    },
    geometry: {
      type: "Point",
      coordinates: [customer._lng, customer._lat],
    },
  } as TCustomerFeature;
};
