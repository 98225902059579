import _ from "lodash";
import { Dispatch } from "redux";

import { logoutAction } from "containers/authentication/redux/actions";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import { BUSINESS_ERROR } from "model/application/Api";
import TLang from "model/application/Lang";
import {
  closeWarningModalActionCreator,
  openWarningModalActionCreator,
} from "redux/actionCreators/appActionCreator";

import { ajaxErrorAction } from "../actionCreators/ajaxActionCreator";
import {
  // BusinessError,
  AlreadyExistsBusinessError,
  AuthenticationBusinessError,
  FreeTrialLimitReachedError,
  MockAxiosError,
  NotAllowedBusinessError,
  NotFoundBusinessError,
  NotUpToDateBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  QuotaExceededBusinessError,
  RunInBackgroundBusinessError,
  TErrorDetails,
  TimeoutError,
  ValidationBusinessError,
} from "./actionErrors";

export type TreatErrorNotificationFunc = (
  dispatch: Dispatch,
  actionName: string,
  error: any,
  failureActionCreatorFunction: ((error: any, objectId?: string) => any) | null, // sometimes we don't need to dispatch a failure action
  lang: TLang,
  autologout?: boolean,
  objectId?: string
) => void;

export const treatErrorNotification: TreatErrorNotificationFunc = (
  dispatch,
  actionName,
  error,
  failureActionCreatorFunction,
  lang,
  autologout = true,
  objectId
) => {
  // Special case for errors we don't want to catch in tests
  if (error instanceof MockAxiosError) {
    throw error;
  }

  /**
  const message = getErrorMessage(error, lang);

  let detailedMessage = `Error in action ${actionName}: `;
  if (error.message) {
    detailedMessage = `${detailedMessage}${error.message}${error?.response?.data?.data?.error?.message}`;
  }
  */

  const message = getErrorMessage(actionName, error, lang);
  const detailedMessage = getDetailedErrorMessage(error);

  if (
    (error.errorStatus === BUSINESS_ERROR.AUTHENTICATION ||
      _.includes(error?.message, "client with id") ||
      _.includes(error?.message, "not having access to any client")) &&
    autologout
  ) {
    logoutAction()(dispatch);
    return;
  }

  dispatch(ajaxErrorAction(error, actionName));

  if (failureActionCreatorFunction) {
    dispatch(failureActionCreatorFunction(error, objectId));
  }

  let notificationAction = showNotificationActionCreator(
    notificationTypes.NOTIFICATION_ERROR,
    levels.NOTIFICATION_LEVEL_ERROR,
    message,
    detailedMessage
  );

  if (error.errorStatus === BUSINESS_ERROR.RUN_IN_BACKGROUND) {
    notificationAction = showNotificationActionCreator(
      notificationTypes.NOTIFICATION_SUCCESS,
      levels.NOTIFICATION_LEVEL_SUCCESS,
      message
    );
  }

  dispatch(notificationAction);
};

const getErrorMessage = (actionName: string, error: any, lang: TLang) => {
  if (error.errorStatus === BUSINESS_ERROR.RUN_IN_BACKGROUND) {
    return lang.actions.runInBackgroundMessage;
  }
  // error can be an applicative or a business error.
  const isBusiness = error.hasOwnProperty("errorStatus");
  if (isBusiness) {
    return `${actionName} action failed` ?? error.message;
  }
  if (!error.response) {
    return lang.notifications.errorNotifications.networkError;
  }
  if (error.response.status === 422) {
    return lang.notifications.errorNotifications.validationError;
  }
  return lang.notifications.errorNotifications.internalError;
};

const getDetailedErrorMessage = (error: any) => {
  const baseMessage = error.message ?? "";

  const details = error?.response?.data?.data?.error.message;
  const debugMessage = _.isEmpty(details)
    ? ``
    : `\n\n---\nDebug info: ${JSON.stringify(details)}`;

  return `${baseMessage}${debugMessage}`.trim();
};

interface IData<T> {
  code_response: BUSINESS_ERROR;
  data: T;
  error_message: string;
}

export interface IServerResponse<T> {
  data: IData<T>;
}

export interface IOpenWarningModalParams {
  title: string;
  description: string;
  onConfirm?: any;
}

export type TOpenWarningModalFunc = (params: IOpenWarningModalParams) => any;
export const openWarningModal: TOpenWarningModalFunc = (params) => {
  return (dispatch: Dispatch) => {
    dispatch(openWarningModalActionCreator({ ...params }));
  };
};

export const closeWarningModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(closeWarningModalActionCreator());
  };
};

export const extractDataAndCheckErrorStatus = <T extends TErrorDetails>(
  serverResponse: IServerResponse<T>
): T => {
  // const data = serverResponse && serverResponse.data ? serverResponse.data : serverResponse
  const { data } = serverResponse;
  if (!data.code_response) {
    throw new Error("Server response do not have any 'code_response'");
  } else {
    switch (data.code_response) {
      case BUSINESS_ERROR.SUCCESS: {
        return data.data;
      }
      case BUSINESS_ERROR.RUN_IN_BACKGROUND: {
        throw new RunInBackgroundBusinessError();
      }
      case BUSINESS_ERROR.ALREADY_EXISTS: {
        throw new AlreadyExistsBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.AUTHENTICATION: {
        throw new AuthenticationBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.UNIQUE_FIELD_VIOLATION:
      case BUSINESS_ERROR.NOT_ALLOWED: {
        throw new NotAllowedBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.NOT_FOUND: {
        throw new NotFoundBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.NOT_UP_TO_DATE: {
        throw new NotUpToDateBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.PARTIAL_SUCCESS: {
        throw new PartialFailureBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.QUOTA_EXCEEDED: {
        throw new QuotaExceededBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.PAYLOAD_TOO_LARGE: {
        throw new PayloadTooLargeBusinessError(data.data);
      }
      case BUSINESS_ERROR.VALIDATION_ERROR: {
        throw new ValidationBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.FREE_TRIAL_LIMIT_REACHED: {
        throw new FreeTrialLimitReachedError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.TIMEOUT: {
        throw new TimeoutError(data.error_message, data.data);
      }
      default: {
        throw new Error(
          "Server response do not have any 'code_response' recognized"
        );
      }
    }
  }
};

// this is a copy of what is done in the backend. Maybe it's better to move it in fieldpro-tools one day,
// but let's leave it as such for now, as we might change the shape of this object in the near future.
export interface IGenericQueryToSendToBackend {
  filters: {
    [key: string]: Array<string> | number | string | boolean | undefined;
    _active?: boolean;
    _id?: Array<string> | string;
    _updated_at?: string;
    _updated_by?: string;
    _created_at?: string;
    _created_by?: string;
  }; // contains all the filters decided by the frontend. Can be any shape.
  limit?: number; // max number of objects to return
  updated_after?: Date; // the date after which we want the _updated_at prop to be
  updated_before?: Date; // the date before which we want the _updated_at prop to be
  start_after?: string; // the id of the item from which want to continue the fetch (for pagination)
  offset?: number; // offset number
  teams?: string[]; // array of team ids
  only_duplicated?: string; // to return only the objects that have duplicates for a specific attribute
  order_by?: string;
  order?: "asc" | "desc" | "";
  with_count?: boolean; // if you want to return the count or not
  include_linked_options?: boolean;
  search?: string;
  detail?: boolean;
}

export interface IAdditionalQueryOption {
  eraseExisting?: boolean;
  skipStoreUpdate?: boolean;
  skipBackendCall?: boolean;
  storeAsTableItems?: boolean;
  clearExistingTableItems?: boolean;
}
