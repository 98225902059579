import * as types from "../actionTypes/userActionTypes";

/**
 * @param {Object} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export const changeSubcategorySuccessActionCreator = (
  subcategory: "mobileuser" | "webuser"
) => {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
};
