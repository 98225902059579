import {
  CalendarEventType,
  IDSource,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";

import { ILine } from "components/Input/LegacyInput/UploadFileInput/UploadFileInput";
import TLang from "model/application/Lang";

import { checkEventForErrors } from "./checkEventForErrors";
const keys: Array<keyof TCalendarEvent> = [
  "description",
  "start_time",
  "end_time",
  "assigned_to",
  "customer_id",
];
export function getCsvBodyTemplate() {
  const mockVisit = {
    description: "Description",
    start_time: moment({
      hour: 10,
      minute: 0,
    }).format(),
    end_time: moment({
      hour: 11,
      minute: 0,
    }).format(),
    assigned_to: IDSource(),
    customer_id: IDSource(),
  };
  const values = _.map(_.values(mockVisit), _.toString);
  return _.join(keys, ",").concat("\n").concat(_.join(values, ","));
}

export function parseEventsCSV(lines: ILine[], lang: TLang) {
  const errors: string[] = [];
  const data: IVisitEvent[] = [];
  //Parse and add events
  _.forEach(_.tail(lines), (line, index) => {
    const event = _.zipObject(lines[0], line) as any as TCalendarEvent;
    const error = checkEventForErrors(event, data, index + 2, lang);
    if (error) {
      errors.push(error);
      return;
    }
    event.start_time = moment(event.start_time).toDate();
    event.end_time = moment(event.end_time).toDate();
    event.title = "";
    event.type = CalendarEventType.VISIT;
    data.push(event as IVisitEvent);
  });
  if (!_.isEmpty(errors)) {
    return { err: "\n" + errors.join("\n") };
  }
  return _.compact(data);
}
