import _ from "lodash";

import ToggleSwitch from "components/Switch/ToggleSwitch";

export interface IBooleanCell {
  value?: boolean;
}

const BooleanCell = ({ value }: IBooleanCell) => {
  let v = value;
  if ((value as any) === "TRUE") v = true;
  if ((value as any) === "FALSE") v = false;
  if (!_.isBoolean(v)) {
    return null;
  }
  return <ToggleSwitch defaultChecked={v} size="small" disabled />;
};

export default BooleanCell;
