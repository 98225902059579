import { IHttpRequest as IBEHttpRequest } from "fieldpro-tools";

import { IHttpRequest } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface ICreateHttpRequestSuccessAction extends IAction {
  httpRequest: IHttpRequest;
}
export interface IUpdateHttpRequestSuccessAction extends IAction {
  httpRequest: IHttpRequest;
}
export interface IRunHttpRequestJobSuccessAction extends IAction {
  success: boolean;
}
export interface IDeleteHttpRequestSuccessAction extends IAction {
  id: string;
}
export interface IArchiveHttpRequestsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreHttpRequestsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchHttpRequestsSuccessAction extends IAction {
  allHttpRequests: IBEHttpRequest[];
}

/**
 * Create HttpRequest begin action creator
 * This creates an action informing redux store that the creation process for an HttpRequest has just began
 * @returns {Object}
 */
export function createHttpRequestBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_HTTP_REQUEST_BEGIN,
  };
}

/**
 * Failure Action for creating aN HttpRequest instructing that the creation process for a HttpRequest has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createHttpRequestFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_HTTP_REQUEST_FAILURE,
    error,
  };
}

/**
 * Success action for creating a HttpRequest
 * @param {Object} HttpRequest HttpRequest object,
 * @returns {Object}
 */
export const createHttpRequestSuccessActionCreator = (
  httpRequest: IHttpRequest
): ICreateHttpRequestSuccessAction => {
  return {
    type: types.CREATE_HTTP_REQUEST_SUCCESS,
    httpRequest,
  };
};

/**
 * Edit HttpRequest begin action creator
 * This creates an action informing redux store that the creation process for a HttpRequest has just began
 * @returns {Object}
 */
export function updateHttpRequestBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_HTTP_REQUEST_BEGIN,
  };
}

/**
 * Failure Action for editing a HttpRequest instructing that the edition process for a HttpRequest has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateHttpRequestFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_HTTP_REQUEST_FAILURE,
    error,
  };
}

/**
 * Success action for editing a HttpRequest
 * @param {Object} HttpRequest HttpRequest object,
 * @returns {Object}
 */
export function updateHttpRequestSuccessActionCreator(
  httpRequest: IHttpRequest
): IUpdateHttpRequestSuccessAction {
  return {
    type: types.UPDATE_HTTP_REQUEST_SUCCESS,
    httpRequest,
  };
}

/**
 * Delete HttpRequest begin action creator
 * This creates an action informing redux store that the creation process for a HttpRequest has just began
 * @returns {Object}
 */
export function deleteHttpRequestBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_HTTP_REQUEST_BEGIN,
  };
}

/**
 * Failure Action for creating a HttpRequest instructing that the creation process for a HttpRequest has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteHttpRequestFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_HTTP_REQUEST_FAILURE,
    error,
  };
}

/**
 * Success action for creating a HttpRequest
 * @param {Object} HttpRequestId HttpRequest object,
 * @returns {Object}
 */
export function deleteHttpRequestSuccessActionCreator(
  HttpRequestId: string
): IDeleteHttpRequestSuccessAction {
  return {
    type: types.DELETE_HTTP_REQUEST_SUCCESS,
    id: HttpRequestId,
  };
}

/**
 * begin action when there is a request to fetch all allHttpRequests
 * @returns {Object}
 * */
export const fetchAllHttpRequestsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_HTTP_REQUESTS_BEGIN,
});

/**
 * Failure action for fetching all allHttpRequests. This action is dispatched when there is an error fetching all
 * allHttpRequests
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllHttpRequestsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_HTTP_REQUESTS_FAILURE,
  error,
});

/**
 * Fetch all allHttpRequests action creator
 * @param {Array} allHttpRequests an array of emailGeneration objects
 * @returns {Object}
 * */
export const fetchAllHttpRequestsSuccessActionCreator = (
  allHttpRequests: IBEHttpRequest[]
): IFetchHttpRequestsSuccessAction => ({
  type: types.FETCH_ALL_HTTP_REQUESTS_SUCCESS,
  allHttpRequests,
});
/**
 * Run HttpRequest job begin action creator
 * This creates an action informing redux store that the running process for a HttpRequest job has just began
 * @returns {Object}
 */
export function runHttpRequestJobBeginActionCreator(): IBeginAction {
  return {
    type: types.RUN_HTTP_REQUEST_JOB_BEGIN,
  };
}

/**
 * Failure Action for running a HttpRequest job instructing that the running process for a HttpRequest job has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runHttpRequestJobFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.RUN_HTTP_REQUEST_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for running a HttpRequest job
 * @param {Object} HttpRequest HttpRequest object,
 * @returns {Object}
 */
export const runHttpRequestJobSuccessActionCreator = (
  success: boolean
): IRunHttpRequestJobSuccessAction => {
  return {
    type: types.RUN_HTTP_REQUEST_JOB_SUCCESS,
    success,
  };
};
