import React from "react";

import { Box } from "@material-ui/core";
import { TKpiCategoriesLegendValue } from "fieldpro-tools";

import { LegendRange } from "./LegendRange";

export type TGaugeClassesToColorMap = Record<string, string>;

export interface ICategoriesLegend {
  classes: Array<TKpiCategoriesLegendValue>;
}
const CategoriesLegend: React.FC<ICategoriesLegend> = ({ classes }) => {
  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      data-testid="gradient-legend"
      gridTemplateColumns={`repeat(${classes.length}, 1fr)`}
    >
      {classes.map((c) => (
        <LegendRange
          key={c.label}
          color={c.color}
          height={15}
          width={"auto"}
          label={c.label}
        />
      ))}
    </Box>
  );
};

export default CategoriesLegend;
