import React, { useLayoutEffect } from "react";

// see https://github.com/facebook/create-react-app/pull/2187/files
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/vi";

import { LANGUAGE } from "fieldpro-tools";
import moment from "moment";
import { useSelector } from "react-redux";

import { getSelectedClient } from "containers/clients/redux/selectors";

const MomentLocaleContext: React.FC<{}> = ({ children }) => {
  const selectedClient = useSelector(getSelectedClient);

  const setLocale = () => {
    moment.locale(
      CLIENT_LANG_TO_MOMENT_LOCALE_MAP[selectedClient?.language || LANGUAGE.en]
    );
  };

  useLayoutEffect(() => {
    setLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient?.id, selectedClient?.language]);

  setLocale();

  return <>{children}</>;
};

export default MomentLocaleContext;

const CLIENT_LANG_TO_MOMENT_LOCALE_MAP: Record<LANGUAGE | "vi", string> = {
  en: "en-gb",
  fr: "fr",
  es: "es",
  vi: "vi",
};
