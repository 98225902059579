import { USER_TYPE } from "fieldpro-tools";

import { ISignedInUser } from "model/entities/User";
import { KeysOfUnion } from "utils/types";

export const formatUser = (user?: ISignedInUser) => {
  if (!user) {
    return;
  }

  const { id, name, email } = user;
  return {
    user_type: USER_TYPE.WEB,
    user_id: id,
    user_name: name,
    user_email: email,
  };
};

export const LOG_USER_KEYS: KeysOfUnion<ReturnType<typeof formatUser>>[] = [
  "user_id",
  "user_name",
  "user_type",
  "user_email",
];
