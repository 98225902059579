import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from "@mui/icons-material/Cached";

import { Black } from "assets/colors";
import useTranslations from "hooks/useTranslations";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "../Buttons/CustomButton";

const useStyles = makeStyles({
  FallbackScreen: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "15px",
  },
  ReloadIcon: {
    width: 80,
    height: 80,
    color: Black,
  },
  ReloadMessage: {
    textAlign: "center",
    fontSize: 20,
  },
  ReloadButtonContainer: {
    marginBottom: 36,
  },
});

const NewVersionAvailableScreen = () => {
  const classes = useStyles();
  const lang = useTranslations();
  return (
    <div
      className={classes.FallbackScreen}
      data-testid="new-version-available-screen"
    >
      <CachedIcon
        className={classes.ReloadIcon}
        fontSize="large"
        style={{
          fontSize: "80px",
        }}
      />

      <h2 className={classes.ReloadMessage}>
        {lang.components.errors.chunkErrorTitle}
        <br />
        {lang.components.errors.chunkErrorDescription}
      </h2>

      <div className={classes.ReloadButtonContainer}>
        <CustomButton
          type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
          onClick={() => {
            window.location.reload();
          }}
        >
          {lang.actions.reload}
        </CustomButton>
      </div>
    </div>
  );
};

export default NewVersionAvailableScreen;
