import _ from "lodash";

import { getActionName } from "containers/clients/components/modals/utils";

export const convertAccessRightProfilesToOption = (actions: {
  [s: string]: boolean;
}) => {
  const actionChanged = {};
  _.map(Object.keys(actions), (act) => {
    if (actions[act] === true) {
      actionChanged[act] = [{ key: act, label: getActionName(act) }];
    }
  });

  return actionChanged;
};

export const convertAccessRightProfileArrayToBoolean = (actions: {
  [s: string]: any; //can take Boolean/Array
}) => {
  const actionChanged = {};
  _.map(Object.keys(actions), (act) => {
    if (_.isArray(actions[act])) {
      if (_.isEmpty(actions[act])) {
        actionChanged[act] = false;
      } else {
        actionChanged[act] = true;
      }
    } else {
      actionChanged[act] = actions[act];
    }
  });

  return actionChanged;
};
