import { Box } from "@material-ui/core";

import UserIcon from "components/Icon/UserIcon";
import IUser from "model/entities/User";
export interface IUserInfoCard {
  user: IUser;
}
function UserInfoCard({ user }: IUserInfoCard) {
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"auto 1fr"}
      gridGap={"8px"}
      padding={"8px"}
      paddingLeft={"0px"}
      alignItems={"center"}
    >
      <Box>
        <UserIcon name={user.first_name + " " + user.last_name} />
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <Box fontWeight={"bold"} fontSize={"16px"}>
          {user.first_name} {user.last_name}
        </Box>
        <Box fontSize={"12px"}>{(user as any)?.phone || ""}</Box>
      </Box>
    </Box>
  );
}

export default UserInfoCard;
