import { ITableOptimizationState } from "./reducer";

const initialState: ITableOptimizationState = {
  isFetchingAll: false,
  isCreating: false,
  isDeleting: false,
  selectedTableOptimization: null,
  tableOptimizations: [],
  errors: [],
};

export default initialState;
