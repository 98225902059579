/**
 * @notes: State of the authentication container
 */

import { IAuthenticationState } from "containers/authentication/redux/reducer";

const initialState: IAuthenticationState = {
  loggingOut: false,
  retryWait: false,
  smalaToken: null,
  attempts: 0,
  isPasswordRecovering: false,
  user: {
    id: "",
    isAuthenticated: false,
    email: "",
    keepSignedIn: true,
    picture: undefined,
  },
  loginError: false,
  errorMessage: "",
  errors: [],
  availableActions: {},
  lastCallToCheckVersion: new Date(0),
  isUsingLastVersion: true,
  lang: "en",
  SigningUp: false,
  screenMessages: {
    displaySwitchToWebMessage: {
      lastShown: null,
      show: false,
    },
  },
};

export default initialState;
