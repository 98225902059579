import { IStream } from "model/entities/Job";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all stream from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllStreams = (state: IRootState) => state.jobs.streams.streams;

/**
 * Selector to get all Streams from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allStreamsSelector = customSelectorCreator(
  [getAllStreams],
  (allStreams: IStream[]) => {
    // map all the streams
    return allStreams;
  }
);

/**
 * Gets the status of creating a stream
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingStream = (state: IRootState) =>
  state.jobs.streams.isCreating;

/**
 * Gets the status of updating a stream
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingStream = (state: IRootState) =>
  state.jobs.streams.isUpdating;

/**
 * Gets the status of deleting a stream
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingStream = (state: IRootState) =>
  state.jobs.streams.isDeleting;

/**
 * Gets the status of running a stream job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRunningStream = (state: IRootState) =>
  state.jobs.streams.isRunning;
