import { IMetaExpressionInTable } from "model/entities/MetaExpression";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface ICreateMetaExpressionSuccessAction extends IAction {
  metaExpression: IMetaExpressionInTable;
}
export interface IUpdateMetaExpressionSuccessAction extends IAction {
  metaExpression: IMetaExpressionInTable;
}
export interface IDeleteMetaExpressionSuccessAction extends IAction {
  expression: string;
}
export interface IComputeMetaExpressionSuccessAction extends IAction {
  metaExpressionsSolved: { [metaExpr: string]: string };
}
export interface IArchiveMetaExpressionsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreMetaExpressionsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchMetaExpressionsSuccessAction extends IAction {
  meta_expressions: IMetaExpressionInTable[];
}

/**
 * Create metaExpression begin action creator
 * This creates an action informing redux store that the creation process for an metaExpression has just began
 * @returns {Object}
 */
export function createMetaExpressionBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_META_EXPRESSION_BEGIN,
  };
}

/**
 * Failure Action for creating a metaExpression instructing that the creation process for a metaExpression has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createMetaExpressionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_META_EXPRESSION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a metaExpression
 * @param {Object} metaExpression MetaExpression object,
 * @returns {Object}
 */
export const createMetaExpressionSuccessActionCreator = (
  metaExpression: IMetaExpressionInTable
): ICreateMetaExpressionSuccessAction => {
  return {
    type: types.CREATE_META_EXPRESSION_SUCCESS,
    metaExpression,
  };
};

/**
 * Edit MetaExpression begin action creator
 * This creates an action informing redux store that the creation process for a metaExpression has just began
 * @returns {Object}
 */
export function updateMetaExpressionBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_META_EXPRESSION_BEGIN,
  };
}

/**
 * Failure Action for editing a metaExpression instructing that the edition process for a metaExpression has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateMetaExpressionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_META_EXPRESSION_FAILURE,
    error,
  };
}

/**
 * Success action for editing a metaExpression
 * @param {Object} metaExpression MetaExpression object,
 * @returns {Object}
 */
export function updateMetaExpressionSuccessActionCreator(
  metaExpression: IMetaExpressionInTable
): IUpdateMetaExpressionSuccessAction {
  return {
    type: types.UPDATE_META_EXPRESSION_SUCCESS,
    metaExpression,
  };
}

/**
 * Delete metaExpression begin action creator
 * This creates an action informing redux store that the creation process for a metaExpression has just began
 * @returns {Object}
 */
export function deleteMetaExpressionBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_META_EXPRESSION_BEGIN,
  };
}

/**
 * Failure Action for creating a metaExpression instructing that the creation process for a metaExpression has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteMetaExpressionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_META_EXPRESSION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a metaExpression
 * @param {Object} metaExpressionId MetaExpression object,
 * @returns {Object}
 */
export function deleteMetaExpressionSuccessActionCreator(
  metaExpressionId: string
): IDeleteMetaExpressionSuccessAction {
  return {
    type: types.DELETE_META_EXPRESSION_SUCCESS,
    expression: metaExpressionId,
  };
}

/**
 * Compute metaExpression begin action creator
 * This creates an action informing redux store that the creation process for a metaExpression has just began
 * @returns {Object}
 */
export function computeMetaExpressionBeginActionCreator(): IBeginAction {
  return {
    type: types.COMPUTE_META_EXPRESSION_BEGIN,
  };
}

/**
 * Failure Action for computing a metaExpression
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function computeMetaExpressionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.COMPUTE_META_EXPRESSION_FAILURE,
    error,
  };
}

/**
 * Success action for computing a metaExpression
 * @param {Object} metaExpressionId MetaExpression object,
 * @returns {Object}
 */
export function computeMetaExpressionSuccessActionCreator(metaExpressionsSolved: {
  [metaExpr: string]: string;
}): IComputeMetaExpressionSuccessAction {
  return {
    type: types.COMPUTE_META_EXPRESSION_SUCCESS,
    metaExpressionsSolved,
  };
}

/**
 * begin action when there is a request to fetch all allMetaExpressions
 * @returns {Object}
 * */
export const fetchAllMetaExpressionsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_META_EXPRESSIONS_BEGIN,
});

/**
 * Failure action for fetching all allMetaExpressions. This action is dispatched when there is an error fetching all
 * allMetaExpressions
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllMetaExpressionsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_META_EXPRESSIONS_FAILURE,
  error,
});

/**
 * Fetch all allMetaExpressions action creator
 * @param {Array} allMetaExpressions an array of MetaExpression objects
 * @returns {Object}
 * */
export const fetchAllMetaExpressionsSuccessActionCreator = (
  meta_expressions: IMetaExpressionInTable[]
): IFetchMetaExpressionsSuccessAction => ({
  type: types.FETCH_ALL_META_EXPRESSIONS_SUCCESS,
  meta_expressions,
});

export const resetMetaExpressionSuccessActionCreator = () => ({
  type: types.RESET_META_EXPRESSION_SUCCESS,
});
