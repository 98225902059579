import _ from "lodash";

import { TRowType } from "components/Table/model";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

export function getItemsInRows(rows: TRowType[], list: IList): IListItem[] {
  return _.compact(
    _.map(rows, function (row) {
      return _.find(list.items, function (item) {
        if (row.hasOwnProperty("_item_id")) {
          return row._item_id === item._id;
        } else {
          return _.values(row).includes(item._id);
        }
      });
    })
  );
}
