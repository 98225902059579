import { STEP_TYPE } from "fieldpro-tools";

import {
  buildDateFilter,
  buildMultipleChoiceFilter,
} from "components/Filter/prepareFiltersUtils";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { getLangObject } from "lang/utils";
import { IFilter } from "model/application/Filter";
import { IActivity, IWorkflow } from "model/entities/Workflow";

export const prepareFilters = (
  activities: IActivity[],
  workflows: IWorkflow[],
  params: any
): IFilter[] => {
  const lang = getLangObject();

  const filters: IFilter[] = [];
  filters.push(buildDateFilter(lang.components.filters.date, FILTER_TAG.DATE));
  filters.push(
    buildMultipleChoiceFilter(
      workflows
        .filter(
          (w) =>
            w.active &&
            w.attached_lists &&
            w.attached_lists.includes("customer")
        )
        .map((w) => ({
          label: w.name,
          key: w.id,
        })),
      lang.components.filters.workflows,
      FILTER_TAG.WORKFLOWS
    )
  );
  if (params[FILTER_TAG.WORKFLOWS]) {
    const filteredWfs = params[FILTER_TAG.WORKFLOWS];
    const fullFilteredWfs = workflows.filter((w) => filteredWfs.includes(w.id));
    const linkedActivities: string[] = [];
    fullFilteredWfs.forEach((w) => {
      w.steps.forEach((s) => {
        if (s.type === STEP_TYPE.ACTIVITY) {
          linkedActivities.push(s.schema_id);
        }
      });
    });
    filters.push(
      buildMultipleChoiceFilter(
        activities
          .filter((a) => linkedActivities.includes(a.id))
          .map((a) => ({
            label: a.name,
            key: a.id,
          })),
        lang.components.filters.activities,
        FILTER_TAG.ACTIVITIES
      )
    );
  }
  return filters.map((f) => {
    if (params[f.tag]) {
      f.value = params[f.tag];
    }
    return f;
  });
};
