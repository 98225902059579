import _ from "lodash";

import { ITerritory } from "containers/territories/model";
import { IListSchema } from "model/entities/List";
import { IAction } from "redux/store/model";
import { updateErrorsList } from "utils/reducerUtils";

import { formatTerritoryForFE } from "../utils/formatTerritory";
import {
  ICreateTerritoriesSuccessAction,
  IFetchTerritoriesSchemaSuccessAction,
  IFetchTerritoriesSuccessAction,
  ISetFilterTerritoriesQuery,
  IUpdateTerritoriesSchemaSuccessAction,
  IUpdateTerritoriesSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface TerritoryState {
  isFetchingOneTerritoryItem: boolean;
  isUpdatingTerritoryItem: boolean;
  isArchivingTerritoryItem: boolean;
  isRestoringTerritoryItem: boolean;
  isCreatingTerritoryItem: boolean;

  isFetchingTerritoriesItems: boolean;
  isUpdatingTerritoriesItems: boolean;
  isDeletingTerritoriesItems: boolean;
  isCreatingTerritoriesItems: boolean;
  isAssigningTerritoriesItems: boolean;
  isUnassigningTerritoriesItems: boolean;

  isFetchingTerritoryList: boolean;
  isFetchingTerritorySchema: boolean;
  isUpdatingTerritorySchema: boolean;

  territoriesSchema: IListSchema[];
  territories: ITerritory[];
  item_count?: number;
  errors: any[];
  filterQuery?: any;
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_TERRITORIES_BEGIN: {
      return {
        ...state,
        isFetchingTerritoriesItems: true,
      };
    }
    case types.SET_FILTER_QUERY: {
      const newQuery = (action as ISetFilterTerritoriesQuery).newQuery;
      return {
        ...state,
        filterQuery: newQuery,
      };
    }
    case types.FETCH_TERRITORIES_SUCCESS: {
      const territories = (action as IFetchTerritoriesSuccessAction)
        .territories;
      const item_count = (action as IFetchTerritoriesSuccessAction).item_count;
      const query = (action as IFetchTerritoriesSuccessAction).query;
      return {
        ...state,
        territories,
        item_count,
        filterQuery: _.isUndefined(query) ? {} : query,
        isFetchingTerritoriesItems: false,
      };
    }
    case types.FETCH_TERRITORIES_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isFetchingTerritoriesItems: false,
      };
    }

    case types.FETCH_TERRITORIES_SCHEMA_BEGIN: {
      return {
        ...state,
        isFetchingTerritorySchema: true,
      };
    }
    case types.FETCH_TERRITORIES_SCHEMA_SUCCESS: {
      const schema = (action as IFetchTerritoriesSchemaSuccessAction).schema;

      return {
        ...state,
        territoriesSchema: schema,
        isFetchingTerritorySchema: false,
      };
    }
    case types.FETCH_TERRITORIES_SCHEMA_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isFetchingTerritorySchema: false,
      };
    }

    case types.CREATE_TERRITORIES_BEGIN: {
      return {
        ...state,
        isCreatingTerritoriesItems: true,
      };
    }
    case types.CREATE_TERRITORIES_SUCCESS: {
      const { territories } = action as ICreateTerritoriesSuccessAction;
      return {
        ...state,
        territories: [...state.territories, ...territories],
        isCreatingTerritoriesItems: false,
      };
    }
    case types.CREATE_TERRITORIES_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isCreatingTerritoriesItems: false,
      };
    }
    case types.UPDATE_TERRITORIES_BEGIN: {
      return {
        ...state,
        isUpdatingTerritoriesItems: true,
      };
    }
    case types.UPDATE_TERRITORIES_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isUpdatingTerritoriesItems: false,
      };
    }
    case types.UPDATE_TERRITORIES_SUCCESS: {
      const { territories } = action as IUpdateTerritoriesSuccessAction;
      return {
        ...state,
        territories: _.map(state.territories, (territory) => {
          const updatedTerritory = _.find(territories, { id: territory.id });
          return updatedTerritory
            ? formatTerritoryForFE(updatedTerritory)
            : territory;
        }),
        isUpdatingTerritoriesItems: false,
      };
    }

    case types.UPDATE_TERRITORIES_SCHEMA_BEGIN: {
      return {
        ...state,
        isUpdatingTerritorySchema: true,
      };
    }
    case types.UPDATE_TERRITORIES_SCHEMA_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isUpdatingTerritorySchema: false,
      };
    }
    case types.UPDATE_TERRITORIES_SCHEMA_SUCCESS: {
      const { attributes } = action as IUpdateTerritoriesSchemaSuccessAction;
      return {
        ...state,
        territoriesSchema: attributes,
        isUpdatingTerritorySchema: false,
      };
    }

    case types.DELETE_TERRITORIES_BEGIN: {
      return {
        ...state,
        isDeletingTerritoriesItems: true,
      };
    }
    case types.DELETE_TERRITORIES_FAILURE: {
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isDeletingTerritoriesItems: false,
      };
    }
    case types.DELETE_TERRITORIES_SUCCESS: {
      return {
        ...state,
        isDeletingTerritoriesItems: false,
      };
    }
    default: {
      return state;
    }
  }
}
