import React from "react";

import CustomDialogForm from "components/Dialog/CustomDialogForm";
import { validateOption } from "components/Forms/OptionForm/utils";
import UploadFileInput from "components/Input/LegacyInput/UploadFileInput";
import { IOnUpdateExcelOrCsvFileFromUploadResults } from "components/Input/LegacyInput/UploadFileInput/UploadFileInput";
import CustomTypography from "components/Typography/CustomTypography";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";

const getCsvTemplate = (headerLine: string) => {
  let template = "data:text/csv;charset=utf-8,";
  template += `${headerLine}\r\n`;
  for (let i = 0; i < 8; i++) {
    template += `row_${i}_column_2,row_${i}_column_3\n`;
  }
  return template;
};

export interface IImportOptionsDialogProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onConfirmModal: (options: IOption[]) => void;
  lang: TLang;
  langlabel: any;
  headerLine?: string;
  confirmBtnText?: string;
  dialogTitle?: string;
}

const ImportOptionsDialog: React.FunctionComponent<
  IImportOptionsDialogProps
> = ({
  isOpen,
  onCloseModal,
  onConfirmModal,
  confirmBtnText,
  dialogTitle,
  lang,
  langlabel,
  headerLine = "tag,label",
}) => {
  const [validFile, setValidFile] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [values, setValues] = React.useState<IOption[]>([]);

  const csvTemplate = React.useMemo(
    () => getCsvTemplate(headerLine),
    [headerLine]
  );

  const onUpdateFile: IOnUpdateExcelOrCsvFileFromUploadResults = (lines) => {
    const [header, ...allOptions] = lines;

    if (header.join(",") !== headerLine) {
      setValidFile(false);
      setError(`The first line should contain ${headerLine}`);
      return;
    }

    const newOptions = [];

    for (let i = 0; i < allOptions.length; i++) {
      const [key, label] = allOptions[i];
      const isError = validateOption(key, label);
      if (isError.key || isError.label) {
        setValidFile(false);
        setError(`error on line ${i + 1}`);
        return;
      }

      newOptions.push({
        key,
        label,
        index: i,
      });
    }

    setValues(newOptions);
    setValidFile(true);
    setError(undefined);
  };

  return (
    <CustomDialogForm
      isOpen={isOpen}
      onClose={() => {
        onCloseModal();
        setValidFile(false);
        setError(undefined);
      }}
      title={dialogTitle ?? lang.components.dialog.bulkAddOptionsFromCSV.title} // FIXME: change that
      langlabel={langlabel}
      onConfirmAction={() => onConfirmModal(values)}
      confirmBtnText={confirmBtnText ?? lang.modal.add}
      isDisabled={!validFile || !!error}
      lang={lang}
    >
      <UploadFileInput
        lang={lang}
        description={
          <CustomTypography>
            {lang.components.dialog.bulkAddOptionsFromCSV.description}{" "}
            <a
              className="link"
              download="template_import_options.csv"
              href={csvTemplate}
            >
              link
            </a>
            .
          </CustomTypography>
        }
        onUpdateFile={onUpdateFile}
        onError={(error) => {
          onCloseModal();
          setValidFile(true);
          setError(error);
        }}
        isErrorFile={!!(!validFile && error)}
        errorMessageFile={error}
      />
    </CustomDialogForm>
  );
};

export default ImportOptionsDialog;
