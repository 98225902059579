import { ComponentType } from "react";

import { makeStyles, TableRow } from "@material-ui/core";
import _ from "lodash";

import { ICellContentProps } from "components/Table/Cell/CellContent";
import { TCellType } from "components/Table/model";

import SimpleTableCell from "./SimpleTableCell";

export type TCellContent = ComponentType<ICellContentProps>;

interface ISimpleTableRow {
  row: TCellType[];
  CellContent: TCellContent;
}

const useStyles = makeStyles({
  SimpleTableRow: {},
});

const SimpleTableRow = ({ row, CellContent }: ISimpleTableRow) => {
  const classes = useStyles();
  if (!row || _.isEmpty(row)) {
    return null;
  }
  return (
    <TableRow className={classes.SimpleTableRow}>
      {_.map(row, (cell, index) => (
        <SimpleTableCell key={index} isSticky={cell.sticky}>
          <CellContent type={cell.type} value={cell.value} />
        </SimpleTableCell>
      ))}
    </TableRow>
  );
};

export default SimpleTableRow;
