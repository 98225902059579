import IRootState from "redux/store/model";

/**
 * Gets all transformations from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const getAllTransformations = (state: IRootState) => {
  return state.jobs.transformations.transformations;
};

/**
 * Gets the status of fetching transformations
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const getIsFetchingTransformations = (state: IRootState) =>
  state.jobs.transformations.isFetchingAll;

/**
 * Gets the status of creating a transformation
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingTransformation = (state: IRootState) =>
  state.jobs.transformations.isCreating;

/**
 * Gets the status of updating a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingTransformation = (state: IRootState) =>
  state.jobs.transformations.isUpdating;

/**
 * Gets the status of deleting a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingTransformation = (state: IRootState) =>
  state.jobs.transformations.isDeleting;

/**
 * Gets the status of running a transformation job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRunningTransformation = (state: IRootState) =>
  state.jobs.transformations.isRunning;
/**
 * Get the status of fetching transformation job history
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingTransformationHistory = (state: IRootState) =>
  state.jobs.transformations.isFetchingJobHistory;
