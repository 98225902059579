import _ from "lodash";

import { IOption } from "model/application/components";

interface IGetDefaultOwners {
  owners: string[];
  options: IOption<string>[];
}

export const getDefaultOwners = ({ owners, options }: IGetDefaultOwners) => {
  const matchingOptions = _.map(owners, (owner) =>
    _.find(options, { key: owner })
  );
  return _.compact(matchingOptions);
};
