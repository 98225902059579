import { PureComponent } from "react";

import { alpha, withStyles } from "@material-ui/core/styles";
import { HeatMapCanvas } from "@nivo/heatmap";
import { AnchoredContinuousColorsLegendProps } from "@nivo/legends";
import _ from "lodash";

import { Background, Black, Green, White } from "assets/colors";
import TLang from "model/application/Lang";
import { IKPI, INivoConfiguration } from "model/entities/Dashboard";

import styles from "../../styles";
import Axes from "../Axes";
import Chart from "../Chart";
import { linearInterpolate } from "../Colors";
import { getMinHeatmapDimensions } from "../utils/getMinHeatmapDimentions";

interface IHeatmapChartProps {
  chart: IKPI<any>;
  nivoConfiguration: INivoConfiguration;
  lang: TLang;
}

const PDF_MAX_COLUMNS = 16;
const PDF_MAX_ROWS = 50;

export const formatNumber = (num: any) => {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "k";
  } else {
    return num.toFixed(1);
  }
};

export class HeatmapChart extends PureComponent<IHeatmapChartProps> {
  constructor(props: IHeatmapChartProps) {
    super(props);
    this.state = {
      chart: {},
      nivoConfiguration: {},
    };
  }

  render() {
    const { chart, nivoConfiguration } = this.props;
    const formattedData = this.formatData(chart.data);

    let xAxis = Chart.defaultAxisBottom(nivoConfiguration.axeXType);
    xAxis = nivoConfiguration.tickValues
      ? Object.assign(xAxis, {
          tickValues: nivoConfiguration.tickValues,
        })
      : xAxis;

    const legends: Omit<
      AnchoredContinuousColorsLegendProps,
      "scale" | "containerWidth" | "containerHeight"
    >[] = [
      {
        anchor: "bottom-left",
        translateX: 0,
        translateY: 30,
        length: 400,
        thickness: 8,
        direction: "row",
        tickPosition: "after",
        tickSize: 3,
        tickSpacing: 4,
        tickOverlap: false,
        titleAlign: "start",
        titleOffset: 4,
      },
    ];

    const { height, width } = getMinHeatmapDimensions(
      chart,
      nivoConfiguration,
      // this is an approximation, we should get bounding box of chart
      window.innerWidth
    );

    const minValue = Math.min(
      ..._.map(chart?.data, (d) => Math.min(..._.map(d.data, "y")))
    );

    const maxValue = Math.max(
      ..._.map(chart?.data, (d) => Math.max(..._.map(d.data, "y")))
    );

    const getCoef = (value: number | null) => {
      value = value ?? 0;
      const coef = (value - minValue) / (maxValue - minValue);
      // always return valid number
      return _.isNaN(coef) ? 0 : coef;
    };

    // TODO: FIX TYPES
    let colors: any;
    // let colors:
    //   | ContinuousColorScaleConfig
    //   | ((
    //       cell: Omit<
    //         ComputedCell<DefaultHeatMapDatum>,
    //         "borderColor" | "color" | "opacity" | "labelTextColor"
    //       >
    //     ) => string)
    //   | undefined;

    if (chart.heatmap_color && chart.heatmap_color != "default") {
      colors = {
        type: "sequential" as const,
        scheme: nivoConfiguration.colors[0],
        divergeAt: 0.4,
        steps: 5,
      };

      if (chart.min_gradient_scale != undefined) {
        colors["minValue"] = chart.min_gradient_scale;
      }

      if (chart.max_gradient_scale) {
        colors["maxValue"] = chart.max_gradient_scale;
      }
    } else {
      colors = (cell: any) => {
        const coef = getCoef(cell.value);
        const color = linearInterpolate(Green, Background, coef);
        return color;
      };
    }

    if (chart.value_format && chart.value_format.enabled) {
      legends["tickFormat"] = chart.value_format?.format;
    }

    const axisTop = Object.assign(xAxis, {
      tickRotation: -90,
      /* istanbul ignore next */
      format: (value: string) => {
        /* istanbul ignore next */
        return truncateAxisLabel(
          Axes.formatAxisValue(value, nivoConfiguration.axeXType),
          AXIS_TOP_MAX_LENGTH
        );
      },
    });

    return (
      <>
        <HeatMapCanvas
          data={formattedData}
          // Layout
          height={height}
          width={width}
          margin={Chart.settings[chart.type].margin}
          axisTop={axisTop}
          axisLeft={{
            format: (v) => truncateAxisLabel(v, AXIS_LEFT_MAX_LENGTH),
          }}
          axisRight={null}
          axisBottom={null}
          legends={legends}
          // Cell
          hoverTarget="cell"
          inactiveOpacity={0.5}
          // Colors
          colors={colors}
          emptyColor={Background}
          borderWidth={1}
          borderColor={White}
          theme={{
            text: {
              fontFamily: "BasierCircle",
            },
            labels: {
              text: {
                fontSize: 14,
                fontFamily: "BasierCircle",
                fontWeight: 400,
              },
            },
          }}
          labelTextColor={(args) => {
            const coef = getCoef(args.value);
            // const color = linearInterpolate(White, Black, coef);
            const color = coef > 0.5 ? White : alpha(Black, 0.8);
            return color;
          }}
          // Formatting
          {...(chart?.value_format?.enabled && {
            valueFormat: chart.value_format?.format,
            label: (e: any) => e.formattedValue ?? "",
          })}
          // Options
          isInteractive
          animate={false}
          tooltip={(e: any) => {
            const originalY = e.cell.data.originalY;
            return (
              <div
                style={{
                  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
                  padding: "5px",
                  backgroundColor: "white",
                }}
              >
                <strong>{originalY}</strong>
              </div>
            );
          }}
        />

        {(_.size(formattedData) > PDF_MAX_ROWS ||
          _.size(formattedData[0]?.data) > PDF_MAX_COLUMNS) && (
          <span
            style={{
              display: "none",
            }}
            id={`${chart.title}-warning`}
          >
            {this.props.lang.rawExports.pdf?.["chartWarning"] ?? ""}
          </span>
        )}
      </>
    );
  }
  formatData = (data?: IKPI<any>["data"]) => {
    return _.map(data, ({ label: _label, ...d }) => {
      d.data = d.data.map((item: any) => {
        return {
          ...item,
          y: formatNumber(item.y),
          originalY: item.y,
        };
      });
      return d;
    });
  };
}

const AXIS_TOP_MAX_LENGTH = 9;
const AXIS_LEFT_MAX_LENGTH = 16;

const truncateAxisLabel = (label: string, maxLength: number) => {
  label = label ?? "";
  return (
    label.substring(0, maxLength) + (label.length > maxLength ? "..." : "")
  );
};

export default withStyles(styles)(HeatmapChart);
