import { isOptimetriksRole, STEP_TYPE } from "fieldpro-tools";
import _ from "lodash";

interface IUserCanViewJobs {
  jobType: STEP_TYPE;
  availableActions?: { [key: string]: boolean };
  availableJobs?: STEP_TYPE[];
  role: string;
}

export const userCanViewJobs = ({
  jobType,
  availableActions,
  availableJobs,
  role,
}: IUserCanViewJobs) => {
  if (isOptimetriksRole(role)) {
    return true;
  }

  if (!availableActions?.FETCH_WORKFLOW) {
    return false;
  }

  if (!_.includes(availableJobs, jobType)) {
    return false;
  }

  return true;
};
