/**
 * @notes: Action creator for User Management container component. These create actions to be dispatched
 * to the redux store. These dispatched actions are objects with the type of action and the payload
 * These are pure functions that return the same expected output for the same number of inputs.
 * They simply return the action type and the object payload
 */
import { IMobileUser as IBEMobileUser } from "fieldpro-tools/dist/src/types/users";

import { IMobileUser } from "model/entities/User";
import { IAction,IActionError, IBeginAction } from "redux/store/model";

import * as types from "../actionTypes/mobileUserActionTypes";

/**
 * Add app users Request begin Action
 * This will create an action of type CREATE_MOBILE_USERS_BEGIN that will be used to trigger a loading progress on the
 * view and signal that an AJAX request is being made
 * @returns {Object} Object Payload with the type of action to update the state of the Store
 * */
export function createMobileUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_MOBILE_USERS_BEGIN,
  };
}

/**
 * Add app users Request failure action. This is dispatched to the Redux Store with the information
 * of the error raised during the Adding an app user process
 * @param {Object} error Error Object received from API
 * @returns {Object} Payload object with the type of action and the Payload information
 * */
export function createMobileUsersFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_MOBILE_USERS_FAILURE,
    error,
  };
}

export interface ICreateMobileUsersSuccessActionCreator extends IAction {
  users: IMobileUser[];
}

/**
 * Add app users request success action is dispatched to the redux store when we have successfully
 * added new app users. This will be used to update the state of the store with the payload
 * information received from the API. the object received is a data object with the id of the newly created user
 * @param {Object} users newly created User Object
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function createMobileUsersSuccessActionCreator(
  users: IMobileUser[]
): ICreateMobileUsersSuccessActionCreator {
  return {
    type: types.CREATE_MOBILE_USERS_SUCCESS,
    users,
  };
}

/**
 * Fetch App User by client request action.
 * @returns {Object} type of action that will be handled by REDUX store
 * */
export function fetchMobileUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_MOBILE_USERS_BY_CLIENT_BEGIN,
  };
}

export interface IFetchMobileUsersSuccessActionCreator extends IAction {
  users: IBEMobileUser[];
  mobile_teammates: any[];
}

/**
 * Fetch app user by client success action
 * @param {Object} user Application user
 * @returns {Object} Object with type of action and app user
 */
export function fetchMobileUsersSuccessActionCreator(
  users: IBEMobileUser[],
  mobile_teammates: any[]
): IFetchMobileUsersSuccessActionCreator {
  return {
    type: types.FETCH_MOBILE_USERS_BY_CLIENT_SUCCESS,
    users,
    mobile_teammates,
  };
}

/**
 * Fetch app user by client failure action
 * @param {Object} error Error object with details as
 * @returns {Object} Object with type of action and error object
 */
export function fetchMobileUsersFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_MOBILE_USERS_BY_CLIENT_FAILURE,
    error,
  };
}

/**
 * Update app user begin action to trigger loading progress
 * @returns {Object} Type of action
 * */
export function updateMobileUserBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_MOBILE_USERS_BEGIN,
  };
}

export interface IUpdateMobileUserSuccessActionCreator extends IAction {
  mobileUsers: IMobileUser[];
}

/**
 * Update app users success action,
 * @param {Object} mobileUsers
 * @returns {Object} type of action and app user object
 * */
export function updateMobileUserSuccessActionCreator(
  mobileUsers: IMobileUser[]
): IUpdateMobileUserSuccessActionCreator {
  return {
    type: types.UPDATE_MOBILE_USERS_SUCCESS,
    mobileUsers,
  };
}

/**
 * Update app user failure action
 * @param {Object} error
 * @return {Object} Type of object and error object
 * */
export function updateMobileUserFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_MOBILE_USERS_FAILURE,
    error,
  };
}

/**
 * Delete app user begin action, triggers a loading progress
 * @return {Object} Object with type of action
 * */
export function deleteMobileUserBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_MOBILE_USER_BEGIN,
  };
}

export interface IDeleteMobileUserSuccessActionCreator extends IAction {
  id: string;
}

/**
 * Delete app user success action
 * @param {String} id mobileUser id
 * @return {Object} Object with type of action and Boolean flag
 * */
export function deleteMobileUserSuccessActionCreator(
  id: string
): IDeleteMobileUserSuccessActionCreator {
  return {
    type: types.DELETE_MOBILE_USER_SUCCESS,
    id,
  };
}

/**
 * Delete app user failure action dispatched when an error occurs
 * while deleting an app user
 * @param {Object} error Error Object information
 * @return {Object} Object with type of action and error object
 * */
export function deleteMobileUserFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_MOBILE_USER_FAILURE,
    error,
  };
}

/**
 * Delete app users begin action, triggers a loading progress
 * @return {Object} Object with type of action
 * */
export function deleteMobileUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_MOBILE_USERS_BEGIN,
  };
}

export interface IDeleteMobileUsersSuccessActionCreator extends IAction {
  ids: string[];
}

/**
 * Delete app users success action
 * @param {String} ids mobileUsers ids
 * @return {Object} Object with type of action and Boolean flag
 * */
export function deleteMobileUsersSuccessActionCreator(
  ids: string[]
): IDeleteMobileUsersSuccessActionCreator {
  return {
    type: types.DELETE_MOBILE_USERS_SUCCESS,
    ids,
  };
}

/**
 * Delete app users failure action dispatched when an error occurs
 * while deleting app users
 * @param {Object} error Error Object information
 * @return {Object} Object with type of action and error object
 * */
export function deleteMobileUsersFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_MOBILE_USERS_FAILURE,
    error,
  };
}

/**
 * License app user action creator creates an action to be handles by reducer.
 * Action creator is to inform application there is an app user being linked to current
 * client context
 * @returns {Object}
 * */
export const licenseMobileUserBeginActionCreator = (): IBeginAction => ({
  type: types.LICENSE_MOBILE_USER_BEGIN,
});

/**
 * License app user action creator creates an action to be handles by reducer
 * On failure to link an app user to the client account,
 * @param {Object} error Error object encountered from linking user to client account
 * @returns {Object}
 * */
export const licenseMobileUserFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.LICENSE_MOBILE_USER_FAILURE,
  error,
});

export interface ILicenseMobileUserSuccessActionCreator extends IAction {
  user: IMobileUser;
}

/**
 * License app user action creator creates an action to be handles by reducer
 * Success action that is dispatched when there is successfull linking of an app user to a client account
 * @returns {Object}
 * */
export const licenseMobileUserSuccessActionCreator = (
  user: IMobileUser
): ILicenseMobileUserSuccessActionCreator => ({
  type: types.LICENSE_MOBILE_USER_SUCCESS,
  user,
});

/**
 * License app user action creator creates an action to be handles by reducer.
 * Action creator is to inform application there is an app user being linked to current
 * client context
 * @returns {Object}
 * */
export const unlicenseMobileUserBeginActionCreator = (): IBeginAction => ({
  type: types.UNLICENSE_MOBILE_USER_BEGIN,
});

/**
 * License app user action creator creates an action to be handles by reducer
 * On failure to link an app user to the client account,
 * @param {Object} error Error object encountered from linking user to client account
 * @returns {Object}
 * */
export const unlicenseMobileUserFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.UNLICENSE_MOBILE_USER_FAILURE,
  error,
});

export interface IUnlicenseMobileUserSuccessActionCreator extends IAction {
  user: IMobileUser;
}

/**
 * License app user action creator creates an action to be handles by reducer
 * Success action that is dispatched when there is successfull linking of an app user to a client account
 * @returns {Object}
 * */
export const unlicenseMobileUserSuccessActionCreator = (
  user: IMobileUser
): IUnlicenseMobileUserSuccessActionCreator => ({
  type: types.UNLICENSE_MOBILE_USER_SUCCESS,
  user,
});

/**
 * Bulk License app user action creator creates an action to be handles by reducer.
 * Action creator is to inform application there is an app user being linked to current
 * client context
 * @returns {Object}
 * */
export const bulkLicenseMobileUsersBeginActionCreator = (): IBeginAction => ({
  type: types.BULK_LICENSE_MOBILE_USERS_BEGIN,
});

/**
 * Bulk License app user action creator creates an action to be handles by reducer
 * On failure to link an app user to the client account,
 * @param {Object} error Error object encountered from linking user to client account
 * @returns {Object}
 * */
export const bulkLicenseMobileUsersFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.BULK_LICENSE_MOBILE_USERS_FAILURE,
  error,
});

export interface IBulkLicenseMobileUsersSuccessActionCreator extends IAction {
  userIds: string[];
}

/**
 * Bulk License app user action creator creates an action to be handles by reducer
 * Success action that is dispatched when there is successfull linking of an app user to a client account
 * @returns {Object}
 * */
export const bulkLicenseMobileUsersSuccessActionCreator = (
  userIds: string[]
): IBulkLicenseMobileUsersSuccessActionCreator => ({
  type: types.BULK_LICENSE_MOBILE_USERS_SUCCESS,
  userIds,
});

/**
 * Bulk Unlicense app users action creator creates an action to be handles by reducer.
 * Action creator is to inform application there is an app user being linked to current
 * client context
 * @returns {Object}
 * */
export const bulkUnlicenseMobileUsersBeginActionCreator = (): IBeginAction => ({
  type: types.BULK_UNLICENSE_MOBILE_USERS_BEGIN,
});

/**
 * Bulk License app users action creator creates an action to be handles by reducer
 * On failure to link an app user to the client account,
 * @param {Object} error Error object encountered from linking user to client account
 * @returns {Object}
 * */
export const bulkUnlicenseMobileUsersFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.BULK_UNLICENSE_MOBILE_USERS_FAILURE,
  error,
});

export interface IBulkUnlicenseMobileUsersSuccessActionCreator extends IAction {
  userIds: string[];
}

/**
 * Bulk Unlicense app users action creator creates an action to be handles by reducer
 * Success action that is dispatched when there is successfull linking of an app user to a client account
 * @returns {Object}
 * */
export const bulkUnlicenseMobileUsersSuccessActionCreator = (
  userIds: string[]
): IBulkUnlicenseMobileUsersSuccessActionCreator => ({
  type: types.BULK_UNLICENSE_MOBILE_USERS_SUCCESS,
  userIds,
});

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function uploadFileBeginAction(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function uploadFileSuccessAction(): IAction {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function uploadFileFailureAction(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearMobileUserDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

/**
 * Unlock login failure action.
 * @param {Object} error Error object information received
 * @return {Object} Type of action and error object
 * */
export function unlockLoginFailureActionCreator(error: any): IActionError {
  return {
    type: types.UNLOCK_LOGIN,
    error,
  };
}

export interface ICreateMobileUsersActionSuccess extends IAction {
  users: IMobileUser[];
}
export interface IFetchMobileUsersActionSuccess extends IAction {
  users: IMobileUser[];
  mobile_teammates: any[];
  currentRight: string;
}
export interface IUpdateMobileUserActionSuccess extends IAction {
  user: IMobileUser;
}
export interface IDeleteMobileUserActionSuccess extends IAction {
  id: string;
}
export interface IDeleteMobileUsersActionSuccess extends IAction {
  ids: string[];
}
export interface ILicenseMobileUserActionSuccess extends IAction {
  user: IMobileUser;
}
export interface IBulkLicenseMobileUsersActionSuccess extends IAction {
  userIds: string[];
}
