import { Documentstate } from "./reducer";

const initialState: Documentstate = {
  isFetchingDocuments: false,
  isUploadingDocuments: false,
  isDeletingDocuments: false,
  isUpdatingDocument: false,
  allDocuments: [],
  errors: [],
  subCategorySelected: "index",
};

export default initialState;
