import { createStyles } from "@material-ui/core";

export default () =>
  createStyles({
    param: { marginBottom: "5px" },
    titleParam: { fontWeight: 700 },
    buttons: {
      position: "absolute",
      right: "35px",
      padding: 0,
      display: "flex",
    },
    CarouselChart: {
      overflow: "auto",
      height: "300px",
    },
    CarouselFullWidth: {
      overflow: "auto",
      height: "calc(100vh - 130px)",
    },
    gridItem: { padding: "12px 22px !important" },
    blocImage: {
      padding: 8,
      boxShadow: "1px 2px 6px grey",
    },
    blocLegend: { textAlign: "center", fontWeight: 700 },
    image: {
      width: "100%",
    },
    imageWrapper: {
      width: "33%",
      display: "inline-block",
      padding: 8,
    },
  });
