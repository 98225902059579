import { createContext } from "react";

import { defaultLogger } from "log/FieldProLogger";
import { array, element, func, node, object, oneOfType } from "prop-types";

import * as appActions from "redux/actions/appActions";

/**
 * Signed in User context, default value is the user object expected from the Redux Store
 */
export const UserContext = createContext({
  id: "",
  name: "",
  email: "",
  isAuthenticated: false,
  isSaAdmin: false,
  isAdmin: false,
  roles: [],
  created_at: "",
});

export const LangContext = createContext({});

export const WarningModalContext = createContext({
  openWarningModal: appActions.openWarningModal,
  closeWarningModal: appActions.closeWarningModal,
});

export const LoggerContext = createContext(defaultLogger);

/**
 * AppProviders, These will be used to pass down props through context from the various Providers in the application
 * @param {Object} props destructured props
 */
export const AppProviders = ({
  children,
  user,
  warningModal,
  logger,
  lang,
}) => {
  return (
    <LoggerContext.Provider value={logger}>
      <UserContext.Provider value={user}>
        <LangContext.Provider value={lang}>
          <WarningModalContext.Provider value={warningModal}>
            {children}
          </WarningModalContext.Provider>
        </LangContext.Provider>
      </UserContext.Provider>
    </LoggerContext.Provider>
  );
};

/**
 * Application consumer
 */
export const AppConsumer = ({ children }) => {
  return (
    <UserContext.Consumer>
      {(user) => (
        <WarningModalContext.Consumer>
          {(warningModal) => (
            <LangContext.Consumer>
              {(lang) => children(user, lang, warningModal)}
            </LangContext.Consumer>
          )}
        </WarningModalContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

AppProviders.propTypes = {
  children: oneOfType([array, node, element]),
  user: object,
  warningModal: object,
  lang: object,
  logger: object,
};

AppConsumer.propTypes = {
  children: func,
};
