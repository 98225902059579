import React from "react";

import { Box, Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import _ from "lodash";
import { Link } from "react-router-dom";

import { White } from "assets/colors";
import CustomCardTable from "components/Card/CustomCardTable";
import { DownloadIcon } from "components/Input/InputFile/InputFile";
import { WarningModalContext } from "containers/app/AppProviders";
import { downloadPictures } from "containers/pictures/utils";
import TLang from "model/application/Lang";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import {
  AXIS_TYPE,
  IDashboard,
  IKPI,
  IKPIData,
  INivoConfiguration,
  KPI_TYPE,
} from "model/entities/Dashboard";
import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "model/entities/HierarchyDependency";
import { ITeamSelector } from "model/entities/Team";
import IUser from "model/entities/User";
import {
  downloadExcelAndCsvFile,
  downloadExcelFile,
  getExcelFromDataRows,
} from "utils/exportUtils";
import { clone } from "utils/utils";

import { KPI_TABLE_LIMIT } from "../Table/TableChart";
import Chart from "./Chart";
import {
  ChartDataUtils,
  DATE_DAY_FORMAT,
  DATE_TIME_FORMAT,
} from "./ChartDataUtils";
import GenericChart from "./GenericChart";
import styles from "./styles";
import TimeoutChart from "./TimeoutChart";

export interface IChartContainerProps {
  classes?: any;
  users: IUser[];
  hierarchy?: IHierarchyDependency[];
  meta_hierarchy?: IMetaHierarchyDependency;
  teams?: ITeamSelector[];
  chart: IKPI;
  index: number;
  startDate?: Date;
  endDate?: Date;
  singleTable?: boolean;
  lang: TLang;
  insideGrid?: boolean;
  formatTitle?: () => JSX.Element;
  downloadDashboard: () => any;
  onPreview?: boolean;
  dashboard: IDashboard;
}

interface IChartContainerState {
  dataForDownload: any[][];
}

export class ChartContainer extends React.Component<
  IChartContainerProps,
  IChartContainerState
> {
  constructor(props: IChartContainerProps) {
    super(props);
    this.state = {
      dataForDownload: [],
    };
  }

  public static defaultProps = {
    singleTable: false,
    insideGrid: true,
    teams: [],
  };

  enableYAxis = (chart: IKPI) => {
    if (!chart) return false;
    const data = ChartDataUtils.is3DChart(chart)
      ? (chart.data as IKPIData[])?.[0].data
      : chart.data;
    return data ? (data as any[]).length > 7 : false;
  };

  configuration = (chart: IKPI, index: number): INivoConfiguration => {
    const colors = Chart.getColors(chart, index);
    return {
      colors,
      curve: "monotoneX",
      enableArea: chart.is_filled ? true : false,
      direction:
        chart.type === KPI_TYPE.BAR_CHART_HORIZONTAL
          ? "horizontal"
          : "vertical",
      axeXType: chart.x_axis_type ? chart.x_axis_type : AXIS_TYPE.TEXT,
      xFormat: "MMM-DD",
      fullWidth: chart.full_width ? true : false,
      forceSquare: false,
      labelTextColor: White,
      withHeaders: true,
    };
  };

  getGridValues = (chart: IKPI, configuration: INivoConfiguration) => {
    if (configuration && configuration.fullWidth) {
      return Chart.fullWidthGrid;
    }
    if (
      configuration &&
      this.props.onPreview &&
      chart.type === KPI_TYPE.CUSTOMERS_MAP
    ) {
      return Chart.fullWidthGrid;
    }
    if (chart.type && Chart.settings[chart.type]) {
      return Chart.settings[chart.type].gridValues;
    }
    return Chart.defaultGrid;
  };

  getHeight = (
    chart: IKPI,
    configuration: INivoConfiguration,
    insideGrid: boolean = true
  ) => {
    if (
      configuration &&
      configuration.fullWidth &&
      chart.type &&
      Chart.settings[chart.type]
    ) {
      return Chart.settings[chart.type].fullScreenHeight;
    } else if (chart.type && Chart.settings[chart.type]) {
      if (chart.type === KPI_TYPE.TIMELINE && !insideGrid) {
        return "calc(100vh - 300px)";
      } else return Chart.settings[chart.type].height;
    }
    return Chart.defaultHeight;
  };

  formatData = (c: IKPI, startDate?: Date, endDate?: Date): IKPI => {
    if (c.data.error && c.data.error === "timeout") {
      return c;
    }
    const { teams, users, meta_hierarchy: metaHierarchy } = this.props;
    if (c.type === KPI_TYPE.MATRIX) {
      return ChartDataUtils.formatMatrix(c, {
        metaHierarchy,
        teams,
        users,
        startDate,
        endDate,
      });
    }
    const chart: IKPI = clone(c);
    try {
      if (
        chart.data &&
        Array.isArray(chart.data) &&
        chart.type === KPI_TYPE.TABLE
      ) {
        chart.data = ChartDataUtils.formatTable(chart);
      } else if (chart.data) {
        if (chart.type === KPI_TYPE.LINE_CHART) {
          // simple line chart does not exist. Convert it to the format of multiple line chart
          chart.data = [{ label: "", data: chart.data as any[] }];
          chart.x_axis_type = AXIS_TYPE.TIME;
        }
        if (chart.type === KPI_TYPE.MULTIPLE_LINE_CHART) {
          if (!chart.grouped_mode) chart.x_axis_type = AXIS_TYPE.TIME;
        }
        if (chart.x_axis_type === AXIS_TYPE.TIME) {
          const dataAggregated = ChartDataUtils.getAggregatedTimeData(
            chart,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            startDate!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            endDate!
          );
          if (
            (chart.data[0] && chart.data[0].data) ||
            //avoiding assuming the data is 2D if its empty for the case of heatmap (causes crash for the heatmap)
            chart.type === KPI_TYPE.HEATMAP_CHART
          ) {
            chart.data = ChartDataUtils.aggregate3DTimeData(
              chart,
              dataAggregated
            );
          } else {
            chart.data = ChartDataUtils.aggregate2DTimeData(
              chart,
              dataAggregated
            );
          }
        } else {
          if (ChartDataUtils.is3DChart(chart)) {
            chart.data = ChartDataUtils.format3DData(chart);
          }
        }
      }
      if (
        ![KPI_TYPE.SCORECARD, KPI_TYPE.GAUGE, KPI_TYPE.TABLE].includes(
          chart.type
        )
      ) {
        // data should be an array
        if (
          !Array.isArray(chart.data) ||
          (chart.data[0] !== undefined &&
            typeof chart.data[0] !== "object" &&
            chart.data[0] !== 0)
        ) {
          this.context.openWarningModal({
            title: `Error in chart ${chart.title}`,
            description: `Something is wrong with the data you received: ${JSON.stringify(
              chart.data
            )}.
              Please read the documentation on how to write your queries: https://confluence.external-share.com/content/abe6c051-edf2-41a4-a8af-3897801e3bd7#Insights-Howtowriteyourqueries.`,
          });
          chart.data = [];
        }
      }
      return chart;
    } catch (e) {
      if (chart.type === KPI_TYPE.TABLE) {
        chart.data = {
          rows: [["No data found"]],
          columns: ["data"],
          columnTypes: [
            {
              label: "data",
              name: "data",
            },
          ],
        };
        return chart;
      }
      this.context.openWarningModal({
        title: `Error in chart ${chart.title}`,
        description: `Something is wrong with the data you received: ${JSON.stringify(
          chart.data
        )}.
          Please read the documentation on how to write your queries: https://confluence.external-share.com/content/abe6c051-edf2-41a4-a8af-3897801e3bd7#Insights-Howtowriteyourqueries.`,
      });
      chart.data = [];
      return chart;
    }
  };

  downloadData = async () => {
    const { chart, startDate, endDate, lang } = this.props;
    let dataRows: any[] = [];

    const chartData = chart.data;
    if (chart.type === KPI_TYPE.MATRIX) {
      dataRows = this.state.dataForDownload;
    } else if (chart.type === KPI_TYPE.TABLE) {
      const chartWithFormattedData = this.formatData(chart, startDate, endDate);
      dataRows = ChartDataUtils.getDownloadFormatForTable(
        chartWithFormattedData.data
      );
    } else if (chart.type === KPI_TYPE.CAROUSEL) {
      downloadPictures(chart.data).catch((e: any) => {
        alert(`Error when trying to download pictures: ${e.message}`);
      });
    } else if (!chartData[0] || !chart) {
      this.context.openWarningModal({
        description: "no data to download for this graph",
      });
      return;
    } else {
      if (ChartDataUtils.is3DChart(chart)) {
        const header = ["x"];
        const kpiData = chartData as IKPIData[];
        kpiData.forEach((d) => header.push(d.label));
        const rows = kpiData.length;

        if (kpiData[0] && kpiData[0].data) {
          for (let i = 0; i < rows; i++) {
            const cols = kpiData[i].data.length;
            let row = Array.from({ length: rows });

            for (let j = 0; j < cols; j++) {
              // Keep number values as-is, we do the formatting in downloadExcelAndCsvFile
              // based on format (CSV, Excel, ...)
              const y = kpiData[i].data[j].y;
              const x =
                chart.x_axis_type === AXIS_TYPE.TIME
                  ? ChartDataUtils.formatValue(
                      kpiData[i].data[j].x,
                      DATE_DAY_FORMAT
                    )
                  : kpiData[i].data[j].x;

              let dataRowIndex = dataRows.findIndex((r) => r[0] === x);
              if (dataRowIndex === -1) {
                const newRow = Array.from({ length: rows });
                newRow[0] = x;
                dataRows.push(newRow);
                dataRowIndex = dataRows.length - 1;
              }
              row = dataRows[dataRowIndex];
              const headerIndex = header.findIndex((h) => {
                return h === kpiData[i].label;
              });
              row[headerIndex] = y;
            }
          }

          dataRows.unshift(header);

          downloadExcelAndCsvFile(dataRows, `${chart.title}`).catch(
            (e: any) => {
              alert(
                `Error when trying to download excel and csv: ${e.message}`
              );
            }
          );
        } else {
          this.context.openWarningModal({
            description: "no data to download for this graph",
          });
        }
        return;
      } else {
        // 1 level of data.
        const kpiData = chartData as IKPIData[];
        dataRows = [
          Object.keys(kpiData[0]),
          ...kpiData.map((d) => {
            return Object.values(d).map((value) => {
              return ChartDataUtils.formatValue(
                value,
                chart.type === KPI_TYPE.TIMELINE
                  ? DATE_TIME_FORMAT
                  : DATE_DAY_FORMAT
              );
            });
          }),
        ];
      }

      const formattedChart = this.formatData(chart, startDate, endDate);

      if (formattedChart.data?.columns && formattedChart.data?.rows) {
        dataRows = [
          formattedChart.data?.columns,
          ...(formattedChart.data?.rows || {}),
        ];
      } else if (
        ![
          KPI_TYPE.CALENDAR_CHART,
          KPI_TYPE.BAR_CHART,
          KPI_TYPE.BAR_CHART_HORIZONTAL,
          KPI_TYPE.TIMELINE,
          KPI_TYPE.PIE_CHART,
        ].includes(chart.type)
      ) {
        dataRows = [
          Object.keys(formattedChart.data?.[0].data[0]),
          ..._.map(formattedChart.data?.[0].data, (d) => Object.values(d)),
        ];
      }
    }

    const { title, description } =
      lang.containers.dashboards.subCategories.dashboards.createEditModal
        .inputChart.createEditModal.downloadProcess;

    if (chart.type === KPI_TYPE.TABLE) {
      const dbTotalRows = chart["db_total_rows"];
      if (dbTotalRows <= KPI_TABLE_LIMIT) {
        // get excel file
        const resultsExcel = await getExcelFromDataRows(dataRows);
        downloadExcelFile(resultsExcel, `${chart.title}`);
      } else {
        this.props.downloadDashboard();
        this.context.openWarningModal({
          title,
          description,
        });
      }
      return;
    }

    if (chart.type !== KPI_TYPE.CAROUSEL) {
      downloadExcelAndCsvFile(dataRows, `${chart.title}`).catch((e: any) => {
        alert(`Error when trying to download excel and csv: ${e.message}`);
      });
    }
  };

  storeDataForDownload = (dataForDownload: any[][]) => {
    this.setState({ dataForDownload });
  };

  getContainerClass(chart: IKPI, classes: any) {
    if (chart.type === KPI_TYPE.TIMELINE) {
      return classes.chartContainerWithVerticalOverflow;
    }
    const xCount = ChartDataUtils.countXValues(chart);
    let activateOverflow =
      Chart.settings[chart.type] &&
      Chart.settings[chart.type].overflowActivationCount;
    const yCount =
      activateOverflow && !isNaN(activateOverflow) && activateOverflow > 0
        ? ChartDataUtils.countYValues(chart)
        : 0;
    if (
      Chart.settings[chart.type] &&
      Chart.settings[chart.type].overflowActivationCount
    ) {
      activateOverflow =
        xCount > Chart.settings[chart.type].overflowActivationCount ||
        yCount > Chart.settings[chart.type].overflowActivationCount;
    }

    let result =
      activateOverflow &&
      (Chart.settings[chart.type].overflowAutorised ||
        Chart.settings[chart.type].overflowActivationCount > 0)
        ? classes.chartContainerWithOverflow
        : classes.chartContainer;

    if (chart.type === KPI_TYPE.HEATMAP_CHART) {
      result = classes.chartContainerWithOverflow;
    }

    if (
      chart.type === KPI_TYPE.MULTIPLE_LINE_CHART ||
      chart.type === KPI_TYPE.STACKED_BAR_CHART
    ) {
      result = [
        result,
        chart.type === KPI_TYPE.MULTIPLE_LINE_CHART && chart.grouped_mode
          ? classes.withCompactLegend
          : classes.withLegend,
      ].join(" ");
    }
    return result;
  }

  render() {
    const {
      classes,
      index,
      chart,
      startDate,
      endDate,
      singleTable,
      insideGrid,
      formatTitle,
      downloadDashboard,
      lang,
      onPreview,
    } = this.props;

    // Uncomment in order to generate mocked data
    // if (chart.type === KPI_TYPE.STACKED_BAR_CHART) {
    //   chart.data = ChartMockedDataUtils.getChartDataByType(chart);
    // }

    if (!chart || !chart.data) return <></>;

    const chartWithFormattedData = this.formatData(chart, startDate, endDate);
    const configuration = this.configuration(chartWithFormattedData, index);
    const ticks = ChartDataUtils.getTickValues(
      chartWithFormattedData,
      configuration
    );
    if (ticks && ticks.length > 0) {
      configuration.tickValues = ticks;
    }
    configuration.enableYAxis = [
      KPI_TYPE.BAR_CHART_HORIZONTAL,
      KPI_TYPE.BAR_CHART,
    ].includes(chart.type)
      ? chart.hasOwnProperty("display_y_axis")
        ? chart.display_y_axis
        : true
      : this.enableYAxis(chartWithFormattedData);
    configuration.enableXAxis = [
      KPI_TYPE.BAR_CHART_HORIZONTAL,
      KPI_TYPE.BAR_CHART,
    ].includes(chart.type)
      ? chart.hasOwnProperty("display_x_axis")
        ? chart.display_x_axis
        : true
      : true;
    const gridValues = this.getGridValues(chart, configuration);

    // NOTE: is this legacy ?
    if (singleTable) {
      const formatData = (rows: any) => {
        return rows.map((row: any) => {
          const data = {};
          chartWithFormattedData.data.columns.forEach((c: any, i: number) => {
            data[c] = row[i];
          });
          return data;
        });
      };

      const formattedData = formatData(chartWithFormattedData.data.rows);

      return (
        <CustomCardTable
          columnTypes={chartWithFormattedData.data.columnTypes}
          data={formattedData}
          isTableChart
          showSyntheticRow
          actions={[
            {
              action: this.downloadData,
              label: "download",
              actionType: ACTION_TYPE.DOWNLOAD,
            },
          ]}
        />
      );
    }

    const chartToDisplay = (
      <Paper
        style={{
          height: this.getHeight(chart, configuration, insideGrid),
          overflow: "auto",
        }}
        elevation={0}
      >
        <div
          className={classes.container}
          style={{
            height: this.getHeight(chart, configuration, insideGrid),
            // maxHeight: "none",
            // maxWidth: "none",
          }}
        >
          {!onPreview &&
            buildChartHeader(
              chart,
              classes,
              this.downloadData,
              lang,
              formatTitle
            )}

          <Box
            id={"chart" + chart.tag}
            className={this.getContainerClass(chartWithFormattedData, classes)}
            style={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            {_.keys(chart)
              .filter((key) => key === "type")
              .map((key, index) => {
                if (chart.data.error && chart.data.error === "timeout") {
                  return (
                    <TimeoutChart
                      key={index}
                      chartType={chart.type}
                      downloadDashboard={downloadDashboard}
                    />
                  );
                }

                return (
                  <GenericChart
                    key={chart.tag}
                    chart={chart}
                    kpiType={chart[key]}
                    chartWithFormattedData={chartWithFormattedData}
                    configuration={configuration}
                    storeDataForDownload={this.storeDataForDownload}
                    dashboard={this.props.dashboard}
                    onDownloadExcel={
                      chart.data.length ? this.downloadData : undefined
                    }
                  />
                );
              })}
          </Box>
        </div>
      </Paper>
    );

    if (insideGrid) {
      return (
        <Grid
          key={chart.tag}
          item
          lg={gridValues.lg}
          md={gridValues.md}
          sm={gridValues.sm}
          xs={gridValues.xs}
        >
          {chartToDisplay}
        </Grid>
      );
    } else {
      return chartToDisplay;
    }
  }
}

const buildChartHeader = (
  chart: IKPI,
  classes: any,
  downloadData: any,
  lang: TLang,
  formatTitle?: any
) => {
  /**
   * Builds the download button of the chart, the title, the "timeout" content if need be.
   */
  if (
    !(chart.data.error && chart.data.error === "timeout") &&
    chart.data.length
  ) {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        alignItems={"center"}
        flexGrow={0}
        style={!chart.description ? { padding: "7px" } : undefined}
      >
        <Box flexGrow={1}>
          {formatTitle ? null : (
            <span className={classes.title}>{chart.title}</span>
          )}
          {formatTitle ? formatTitle() : null}
        </Box>
        <Box flexGrow={0}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gridGap={"8px"}
            alignItems={"center"}
          >
            {chart.type === KPI_TYPE.TIMELINE ||
            chart.type === KPI_TYPE.TABLE ? null : (
              <>
                <Box flexGrow={1}>
                  {chart.type !== KPI_TYPE.CAROUSEL &&
                  !(chart.data.error && chart.data.error === "timeout") ? (
                    <Link
                      to="#"
                      className={classes.download}
                      onClick={downloadData}
                    >
                      <DownloadIcon />
                      <span className={classes.downloadText}>
                        {lang.actions.downloadExcel}
                      </span>
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className={classes.download}
                      onClick={downloadData}
                    >
                      <DownloadIcon />
                      <span className={classes.downloadText}>
                        {lang.actions.downloadZip}
                      </span>
                    </Link>
                  )}
                </Box>
                {chart.description && (
                  <Box flexGrow={0}>
                    <div className={classes.chartCardRight}>
                      <Tooltip title={chart.description}>
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return formatTitle ? (
    formatTitle()
  ) : (
    <div className={classes.chartCard}>
      <div className={classes.chartCardLeft}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{chart.title}</span>
        </div>
      </div>

      {chart.description && (
        <div className={classes.chartCardRight}>
          <Tooltip title={chart.description} style={{ marginTop: "3px" }}>
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

ChartContainer.contextType = WarningModalContext;

export default withStyles(styles)(ChartContainer);
