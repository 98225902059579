import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";
import { LngLat } from "mapbox-gl";

import useTranslations from "hooks/useTranslations";

import InputText, { INPUT_TEXT_TYPE } from "../InputText/InputText";
import { IGPSStructure } from "./InputGPS";

// See https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
// 6 deciman places is already way more than the precision we can really offer (~11cm)
export const DEFAULT_GPS_PRECISION = 6;

interface ICoordinatesFormProps {
  name: string;
  coordinates?: IGPSStructure;
  onChange: (value: [lng: number, lat: number], name: string) => void;
}

function CoordinatesForm({
  coordinates,
  onChange,
  name,
}: ICoordinatesFormProps) {
  const lang = useTranslations();
  const langKey = lang.components.inputGPSButton;
  const [errors, setErrors] = useState({});
  const [lng, setLng] = useState<number | undefined>(undefined);
  const [lat, setLat] = useState<number | undefined>(undefined);

  useEffect(() => {
    setLng(coordinates?.lng);
    setLat(coordinates?.lat);
  }, [coordinates]);

  useEffect(() => {
    try {
      setErrors({});
      if (lat && lng) {
        const ll = new LngLat(lng, lat);
        onChange([ll.lat, ll.lng], name);
      }
    } catch (error) {
      //handle error
      if (lat && (lat < -90 || lat > 90)) {
        setErrors({
          ...errors,
          latitude: langKey.customErrorMessages.wrongLatitude,
        });
      }
      if (lng && (lng < -180 || lng > 180)) {
        setErrors({
          ...errors,
          longitude: langKey.customErrorMessages.wrongLongitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lng, lat, name]);

  function handleChangeLng(value: number) {
    setLng(_.round(value, DEFAULT_GPS_PRECISION));
  }
  function handleChangeLat(value: number) {
    setLat(_.round(value, DEFAULT_GPS_PRECISION));
  }

  return (
    <>
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        gridColumnGap={"32px"}
        data-testid="coordinates-form"
      >
        <Box data-testid="latitude-input">
          <InputText
            defaultValue={lat}
            onChange={handleChangeLat}
            name="latitude"
            placeholder={_.upperFirst(langKey.options.latitude)}
            type={INPUT_TEXT_TYPE.DECIMAL}
            error={errors["latitude"]}
          />
        </Box>

        <Box data-testid="longitude-input">
          <InputText
            defaultValue={lng}
            onChange={handleChangeLng}
            name="longitude"
            placeholder={_.upperFirst(langKey.options.longitude)}
            type={INPUT_TEXT_TYPE.DECIMAL}
            error={errors["longitude"]}
          />
        </Box>
      </Box>
    </>
  );
}

export default CoordinatesForm;
