import * as d3 from "d3";
import { TKpiMapLegend, TKpiMapLegendType } from "fieldpro-tools";

import CategoriesLegend from "./CategoriesLegend";
import GradientLegend from "./GradientLegend";
import LegendLabel from "./LegendLabel";
import { GaugeColorRanges } from "./utils/getGaugeColors";

interface ILegendContainerProps {
  legend?: TKpiMapLegend;
}

function LegendContainer({ legend }: ILegendContainerProps) {
  if (!legend) return null;

  switch (legend.type) {
    case TKpiMapLegendType.GRADIENT:
      return (
        <LegendLabel label={legend.label}>
          <GradientLegend
            colorPalette={GaugeColorRanges.YELLOW_TO_RED}
            minRange={legend.min}
            maxRange={legend.max}
            gaugeWidth={50}
            labelFormatter={(value) => {
              if (legend.valueFormater) {
                return d3.format(legend.valueFormater)(value);
              }
              return value.toString();
            }}
          />
        </LegendLabel>
      );

    case TKpiMapLegendType.CATEGORIES:
      return (
        <LegendLabel label={legend.label}>
          <CategoriesLegend classes={legend.values} />
        </LegendLabel>
      );

    default:
      return null;
  }
}

export default LegendContainer;
