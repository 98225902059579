export const FETCH_ALL_WEBHOOKS_BEGIN = "FETCH_ALL_WEBHOOKS_BEGIN";
export const FETCH_ALL_WEBHOOKS_SUCCESS = "FETCH_ALL_WEBHOOKS_SUCCESS";
export const FETCH_ALL_WEBHOOKS_FAILURE = "FETCH_ALL_WEBHOOKS_FAILURE";
export const DELETE_WEBHOOK_BEGIN = "DELETE_WEBHOOK_BEGIN";
export const DELETE_WEBHOOK_SUCCESS = "DELETE_WEBHOOK_SUCCESS";
export const DELETE_WEBHOOK_FAILURE = "DELETE_WEBHOOK_FAILURE";
export const UPDATE_WEBHOOK_BEGIN = "UPDATE_WEBHOOK_BEGIN";
export const UPDATE_WEBHOOK_SUCCESS = "UPDATE_WEBHOOK_SUCCESS";
export const UPDATE_WEBHOOK_FAILURE = "UPDATE_WEBHOOK_FAILURE";
export const CREATE_WEBHOOK_BEGIN = "CREATE_WEBHOOK_BEGIN";
export const CREATE_WEBHOOK_SUCCESS = "CREATE_WEBHOOK_SUCCESS";
export const CREATE_WEBHOOK_FAILURE = "CREATE_WEBHOOK_FAILURE";
