import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  item: {
    padding: "0px",
    margin: "0px",
  },
});

interface IPictureSkeletonLoaderProps {}

export const PictureSkeletonLoader: React.FunctionComponent<
  IPictureSkeletonLoaderProps
> = () => {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {Array.from({ length: 7 }).map((_, index) => {
          return (
            <Skeleton
              key={index}
              variant="rect"
              width={index === 0 ? 100 : 80}
              height={20}
              style={{ marginRight: "10px" }}
            />
          );
        })}
      </div>

      {Array.from({ length: 2 }).map((_, indexA) => {
        return (
          <div key={indexA} style={{ margin: 0 }}>
            <Grid container className={classes.item} spacing={3}>
              {Array.from({ length: 4 }).map((_, indexB) => {
                return (
                  <Grid
                    key={`${indexA}-${indexB}`}
                    item
                    className={classes.item}
                    xs={3}
                  >
                    <Skeleton height={500} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default PictureSkeletonLoader;
