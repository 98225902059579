import { useState } from "react";

import { Box } from "@material-ui/core";
import { Moment } from "moment";

import { secondaryColor } from "assets/colors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { computeCurrentTimeIndicatorPosition } from "./reapeatingEventsUtils";

interface ITimeIndicatorProps {
  minEventStartTime: Moment;
}
function TimeIndicator({ minEventStartTime }: ITimeIndicatorProps) {
  const { moment } = useMomentTimeZone();

  const [position, setPosition] = useState(
    computeCurrentTimeIndicatorPosition(moment, minEventStartTime.toDate())
  );

  setInterval(() => {
    setPosition(
      computeCurrentTimeIndicatorPosition(moment, minEventStartTime.toDate())
    );
  }, 60000);

  return (
    <Box
      style={{
        top: position,
        position: "absolute",
        width: "100%",
        zIndex: 5,
      }}
    >
      <YellowLine />
    </Box>
  );
}

export default TimeIndicator;

const YellowLine = () => {
  return (
    <Box
      style={{
        position: "relative",
        height: "3px",
        width: "100%",
        backgroundColor: secondaryColor,
      }}
      data-testid="yellow-line"
    >
      <Box
        style={{
          position: "absolute",
          top: "-4px",
          left: "0px",
          width: "10px",
          height: "10px",
          backgroundColor: secondaryColor,
          borderRadius: "50%",
        }}
      />
    </Box>
  );
};
