import { IMetaLevel } from "./CreateEditMetaLevelModal";

export const getLevelOptions = (elements: IMetaLevel[], levelName: string) => {
  const currentElement = elements.find((e) => e.level_name === levelName);
  return (
    elements
      .filter((e) => e.is_hierarchy) // must be a hierarchy
      .filter(
        // all levels already taken cannot be taken a second time
        (e) => {
          const res =
            !elements.find((ee) => {
              const ress = ee.father === e.level_name;
              return ress;
            }) || e.level_name === currentElement?.father;
          return res;
        }
      )
      // cannot call his dad
      .filter((e) => e.father !== levelName)
      // cannot call himself as dad
      .filter((e) => e.level_name !== levelName)
      .map((e) => ({
        key: e.level_name,
        label: e.level_name,
      }))
      .concat([
        {
          key: "none",
          label: "No father",
        },
      ])
  );
};
