import { Button, makeStyles } from "@material-ui/core";

import { useTranslations } from "hooks/useTranslations";

type TCoordinates = {
  lat?: string;
  lng?: string;
};

export interface IGPSCell {
  coordinates: TCoordinates;
  onClick: (coordinates: TCoordinates) => void;
}

const useStyles = makeStyles((theme) => ({
  GPSCell: {
    color: theme.palette.secondary.main,
  },
}));

const GPSCell = ({ coordinates, onClick }: IGPSCell) => {
  const lang = useTranslations();
  const classes = useStyles();
  const { lat = "", lng = "" } = coordinates || {};

  const validCoordinates = !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));
  if (!validCoordinates) {
    return null;
  }

  return (
    <Button className={classes.GPSCell} onClick={() => onClick({ lat, lng })}>
      {lang.components.table.cell.gps}
    </Button>
  );
};

export default GPSCell;
