/**
 * NOTE: configureStore.prod and configureStore.staging are identical ?
 * and configureStore.dev should be called configureStore.local (it has support for redux-devtools)
 */
if (process.env.REACT_APP_REDUX_DEVTOOLS) {
  module.exports = require("./configureStore.dev");
} else if (
  ["production", "productiondirect"].includes(process.env.REACT_APP_ENV)
) {
  module.exports = require("./configureStore.prod");
} else if (["staging", "stagingdirect"].includes(process.env.REACT_APP_ENV)) {
  module.exports = require("./configureStore.staging");
} else {
  module.exports = require("./configureStore.dev");
}
