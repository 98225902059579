import { Box, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { White } from "assets/colors";
import NoOverflowColumn from "components/Layout/NoOverflowColumn";
import { Tab, Tabs } from "components/Tab";
import { getActionsAvailableSelector } from "containers/authentication/redux/selector";
import { getIsFetchingEntities } from "containers/clients/redux/selectors";
import { computeDashboardAction } from "containers/dashboards/redux/actions";
import {
  getIsComputingDashboards,
  getIsComputingKpi,
} from "containers/dashboards/redux/selectors";
import OptimetriksDashboardScreen from "containers/dashboards/subcategories/OptimetriksDashboardScreen";
import runQuery from "containers/dashboards/utils/runQuery";
import { allTableOptimizationsSelector } from "containers/environments/redux/tableOptimizations/selectors";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import { activeTeamsComposedSelector } from "containers/teams/redux/selectors";
import { allMobileUsersComposedSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { workflowSimplifiedSelector } from "containers/workflows/redux/selectors";
import useActions from "hooks/useActions";
import useTranslations from "hooks/useTranslations";
import { IDashboard } from "model/entities/Dashboard";

import HelveticaBold from "./HelveticaBold";

const useStyle = makeStyles({
  titleStyle: {
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "150%",
    backgroundColor: White,
    padding: "12px",
    borderRadius: "4px 4px 0 0",
  },
  tabsStyle: {
    backgroundColor: White,
    padding: "0 12px",
    borderRadius: "0 0 4px 4px",
  },
});

export const MAIN_DASHBOARD_LIMIT = 6;

interface DashboardTabsProps {
  dashboard?: IDashboard;
  dashboards?: IDashboard[];
  setTabId: (tabId: string) => void;
}
const MainDashboards = ({
  dashboard,
  dashboards,
  setTabId,
}: DashboardTabsProps) => {
  const classes = useStyle();
  const isFetchAllDashboardsLoading = useSelector(getIsFetchingEntities);
  const isComputing = useSelector(getIsComputingDashboards);
  const isComputingKpi = useSelector(getIsComputingKpi);
  const lists = useSelector(listsComposedSelector);
  const teams = useSelector(activeTeamsComposedSelector);
  const allTableOptimizations = useSelector(allTableOptimizationsSelector);
  const workflowsSimplfied = useSelector(workflowSimplifiedSelector);
  const mobileUsers = useSelector(allMobileUsersComposedSelector);
  const actionsAvailable = useSelector(getActionsAvailableSelector);

  const lang = useTranslations();

  const computeDashboardActionFunc = useActions(computeDashboardAction);

  const onRunQuery = (
    dashboard: IDashboard,
    query: any,
    callback?: () => any,
    degradedModeKpis?: string[]
  ) => {
    return runQuery({
      dashboard,
      query,
      callback,
      actionsAvailable,
      teams,
      degradedModeKpis,
      computeDashboard: computeDashboardActionFunc,
    });
  };

  const isLoading =
    isFetchAllDashboardsLoading || isComputing || isComputingKpi;

  return (
    <NoOverflowColumn>
      <Typography variant="h5" className={classes.titleStyle}>
        <HelveticaBold>{lang.containers.home.overview}</HelveticaBold>
      </Typography>

      {dashboard && (
        <Tabs
          onChangeSelectedTab={(tabId) => setTabId(tabId)}
          className={classes.tabsStyle}
        >
          {_.map(dashboards, (dashboard: IDashboard) => (
            <Tab label={dashboard.title} id={dashboard.id} key={dashboard.id}>
              <Box
                padding="12px" /** compensate for negative margin in OptimetriksDashboardScreen's Grid */
                marginBottom="32px"
              >
                <OptimetriksDashboardScreen
                  dashboard={dashboard}
                  isLoading={isLoading}
                  onRunQuery={onRunQuery}
                  lists={lists}
                  teams={teams}
                  allTableOptimizations={allTableOptimizations}
                  users={mobileUsers}
                  workflows={workflowsSimplfied}
                  filterButtonsOnly
                />
              </Box>
            </Tab>
          ))}
        </Tabs>
      )}
    </NoOverflowColumn>
  );
};

export default MainDashboards;
