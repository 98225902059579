import { DashboardsState } from "./reducer";

const initialState: DashboardsState = {
  subCategorySelected: "index",
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  isComputingKpi: false,
  isDownloadKpi: false,
  computedKpiResult: null,
  selectedDashboard: null,
  dashboards: [],
  frequentlyViewedIds: [],
  errors: [],
};

export default initialState;
