import { IQuery } from "containers/lists/utils";
import { ITerritory, ITerritoryBE } from "containers/territories/model";
import { IListSchema } from "model/entities/List";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export function fetchTerritorriesBeginAction(): IBeginAction {
  return {
    type: types.FETCH_TERRITORIES_BEGIN,
  };
}

export interface IFetchTerritoriesSuccessAction extends IAction {
  territories: ITerritory[];
  item_count: number;
  query: IQuery;
}

export function fetchTerritoriesSuccessAction(
  territories: ITerritory[],
  item_count: number,
  query: IQuery
): IFetchTerritoriesSuccessAction {
  return {
    type: types.FETCH_TERRITORIES_SUCCESS,
    territories,
    item_count,
    query,
  };
}

export interface ISetFilterTerritoriesQuery extends IAction {
  newQuery: object;
}

export function setFilterTerritoriesQuery(
  newQuery: object
): ISetFilterTerritoriesQuery {
  return {
    type: types.SET_FILTER_QUERY,
    newQuery,
  };
}

export function fetchTerritoriesFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_TERRITORIES_FAILURE,
    error,
  };
}

export function fetchTerritorriesSchemaBeginAction(): IBeginAction {
  return {
    type: types.FETCH_TERRITORIES_SCHEMA_BEGIN,
  };
}

export interface IFetchTerritoriesSchemaSuccessAction extends IAction {
  schema: IListSchema[];
}

export function fetchTerritoriesSchemaSuccessAction(
  schema: IListSchema[]
): IFetchTerritoriesSchemaSuccessAction {
  return {
    type: types.FETCH_TERRITORIES_SCHEMA_SUCCESS,
    schema,
  };
}

export function fetchTerritoriesSchemaFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_TERRITORIES_SCHEMA_FAILURE,
    error,
  };
}

export function createTerritoriesBeginAction(): IBeginAction {
  return { type: types.CREATE_TERRITORIES_BEGIN };
}

export interface ICreateTerritoriesSuccessAction extends IAction {
  territories: ITerritory[];
}

export function createTerritoriesSuccessAction(
  territories: ITerritory[]
): ICreateTerritoriesSuccessAction {
  return {
    type: types.CREATE_TERRITORIES_SUCCESS,
    territories,
  };
}

export function createTerritoriesFailureAction(error: any): IActionError {
  return { type: types.CREATE_TERRITORIES_FAILURE, error };
}

export function deleteTerritoriesBeginAction(): IBeginAction {
  return {
    type: types.DELETE_TERRITORIES_BEGIN,
  };
}

export interface IDeleteTerritoriesSuccessAction extends IAction {
  ids: string[];
}
export function deleteTerritoriesSuccessAction(
  ids: string[]
): IDeleteTerritoriesSuccessAction {
  return {
    type: types.DELETE_TERRITORIES_SUCCESS,
    ids,
  };
}

export function deleteTerritoriesFailureAction(error: any): IActionError {
  return {
    type: types.DELETE_TERRITORIES_FAILURE,
    error,
  };
}

export function updateTerritoriesBeginAction(): IBeginAction {
  return { type: types.UPDATE_TERRITORIES_BEGIN };
}

export interface IUpdateTerritoriesSuccessAction extends IAction {
  territories: ITerritoryBE[];
}
export function updateTerritoriesSuccessAction(
  territories: ITerritoryBE[]
): IUpdateTerritoriesSuccessAction {
  return { type: types.UPDATE_TERRITORIES_SUCCESS, territories };
}

export function updateTerritoriesFailureAction(error: any): IActionError {
  return { type: types.UPDATE_TERRITORIES_FAILURE, error };
}

export function updateTerritoriesSchemaBeginAction(): IBeginAction {
  return { type: types.UPDATE_TERRITORIES_SCHEMA_BEGIN };
}

export interface IUpdateTerritoriesSchemaSuccessAction extends IAction {
  attributes: IListSchema[];
}
export function updateTerritoriesSchemaSuccessAction(
  attributes: IListSchema[]
): IUpdateTerritoriesSchemaSuccessAction {
  return { type: types.UPDATE_TERRITORIES_SCHEMA_SUCCESS, attributes };
}

export function updateTerritoriesSchemaFailureAction(error: any): IActionError {
  return { type: types.UPDATE_TERRITORIES_SCHEMA_FAILURE, error };
}
