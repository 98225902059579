import _ from "lodash";

import { WORKSPACE_TYPES } from "containers/clients/model";
import { IClient } from "model/entities/Client";

export const isWorkspaceTemplate = (client?: IClient) => {
  if (!client) {
    return false;
  }
  const { workspace_type, dbname } = client;
  return (
    (workspace_type === WORKSPACE_TYPES.TEMPLATE &&
      _.startsWith(dbname, "workspace_template")) ||
    dbname === "admin" // admin client should be treated as a teamplate
  );
};
