import _ from "lodash";
import { useSelector } from "react-redux";

import InputCustomField from "components/Input/InputCustomField/InputCustomField";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputText from "components/Input/InputText/InputText";
import { allListsSelector } from "containers/lists/redux/selectors";
import { getAllowedOwnerOptions } from "containers/lists/utils";
import { teamsSelector } from "containers/teams/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { useTranslations } from "hooks";
import { IFormState } from "hooks/useFormState";
import { IOption } from "model/application/components";
import { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  CUSTOM_FIELD_TYPE,
  IList,
  IListSchema,
  LIST_SCOPE,
} from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { isEmptyValue } from "utils/isEmptyValue";

import { shouldMarkError } from "../../lists/utils/shouldMark";
import {
  getChoiceAttributesAsOptions,
  getOptionsAsAttributes,
} from "./utils/formatting";
import { getDefaultValueForOwners, getOwnersLang } from "./utils/owners";

const DEBOUNCE_DURATION = 300; // TODO remove it completely?;

interface IItemForm {
  viewMode: TViewMode;
  list: IList;
  formState: IFormState<IListItem>;
}

const ItemForm = ({ viewMode, formState, list }: IItemForm) => {
  const lang = useTranslations();

  const lists = useSelector(allListsSelector);
  const mobileUsers = useSelector(allMobileUsersSelector);
  const teams = useSelector(teamsSelector);

  const listScope = list?.scope;
  const schema = list?.schema;

  const ownersLang = getOwnersLang(listScope, lang);

  const ownerOptions = getAllowedOwnerOptions(listScope, mobileUsers, teams);

  const itemState = formState.attributes;

  /**
   * See EXPLANATION in ./utils/formatting.ts
   */
  const handleInputChangeAndFlattenOptions = (value: any, name: string) => {
    const attribute = _.find(schema, { column_tag: name });
    if (!attribute) {
      console.error(
        `Attribute ${name} not found in list schema (id: ${list.id})`
      );
      return;
    }
    const { type } = attribute;
    const flattenedValue = getOptionsAsAttributes(type, value);

    return formState.handleInputChange(flattenedValue, name);
  };

  const handleChangeOwners = (
    selectedOwners: IOption<string>[],
    name: string
  ) => {
    const ownersKeys = _.map(selectedOwners, "key");
    formState.handleInputChange(ownersKeys, name);
  };

  // TODO: hack because we want all inputs to stay "vertical" when we edit the item
  const inputViewMode = viewMode === "VIEW" ? "VIEW" : "CREATE";

  return (
    <div>
      {!(viewMode === "CREATE") && (
        <InputText
          name={"id"}
          viewMode="CREATE"
          highlightContent={viewMode === "VIEW"}
          viewStacked={viewMode === "VIEW"}
          lang={
            lang.containers.lists.subCategories.items.createEditModal.inputId
          }
          onChange={formState.handleInputChange}
          defaultValue={itemState._id}
          disabled={true}
          replaceSpaces
          lowercase
          debounceDuration={DEBOUNCE_DURATION}
        />
      )}

      {schema
        .filter((attribute) =>
          viewMode === "VIEW"
            ? !isEmptyValue(itemState[attribute.column_tag])
            : true
        )
        .map((attribute: IListSchema) => {
          const list = _.find(lists, { id: attribute?.list_id });
          const answer = getChoiceAttributesAsOptions(
            itemState,
            attribute,
            list,
            lang
          );

          if (
            viewMode === "VIEW" &&
            isEmptyValue(answer) &&
            attribute.type != CUSTOM_FIELD_TYPE.PLAIN_TEXT
          ) {
            return null;
          }

          return (
            <InputCustomField
              key={attribute.column_tag}
              viewMode={inputViewMode}
              // hack to skip required check's in the item form (See FP-7600)
              customField={{ ...attribute, required: false }}
              formState={{
                ...formState,
                // hack to be able to handle differences with report format for SC, MC, SCOL, MCOL
                handleInputChange: handleInputChangeAndFlattenOptions,
              }}
              list={list}
              answer={answer}
              debounceDuration={DEBOUNCE_DURATION}
              extraProps={{
                [CUSTOM_FIELD_TYPE.PICTURE]: {
                  canAddUrl: true,
                },
              }}
            />
          );
        })}

      {listScope !== LIST_SCOPE.GLOBAL ? (
        <InputMultipleSelect
          name="_owners"
          viewMode={inputViewMode}
          highlightContent={viewMode === "VIEW"}
          viewStacked={viewMode === "VIEW"}
          langlabel={ownersLang as TInputAttributeLang}
          error={shouldMarkError("_owners")}
          options={ownerOptions}
          multipleSelection={
            listScope === LIST_SCOPE.TEAM ||
            listScope === LIST_SCOPE.MOBILE_USER
          }
          defaultValue={getDefaultValueForOwners(
            itemState["_owners"] as string[],
            ownerOptions
          )}
          onChange={handleChangeOwners}
          lang={lang}
        />
      ) : null}
    </div>
  );
};

export default ItemForm;
