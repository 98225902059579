import type { FillLayer, LineLayer } from "react-map-gl";

export const territoriesFillLayer: FillLayer = {
  id: "territories-fill",
  type: "fill",
  paint: {
    "fill-color": ["get", "color"],
    "fill-opacity": [
      "case",
      ["boolean", ["feature-state", "hidden"], false],
      0,
      ["case", ["boolean", ["feature-state", "hovered"], false], 0.3, 0.1],
    ],
    "fill-outline-color": ["get", "color"],
  },
};

export const territoriesLineLayer: LineLayer = {
  id: "territories-line",
  type: "line",

  /* *
   * Issue: when hovering a territory, the boundaries are sometimes below other polygons
   * Sadly this solution does not work... https://github.com/mapbox/mapbox-gl-js/issues/9303
   * => we need to create a separate "hovered" layer
   */
  // layout: {
  //   "line-sort-key": [
  //     "case",
  //     ["boolean", ["feature-state", "hovered"], false],
  //     1,
  //     0,
  //   ],
  // },

  paint: {
    "line-color": ["get", "color"],
    "line-width": [
      "case",
      ["boolean", ["feature-state", "hidden"], false],
      0,
      ["case", ["boolean", ["feature-state", "hovered"], false], 2, 1],
    ],
  },
};
