import { IIntegration as IBEIntegration } from "fieldpro-tools";

import { IIntegration } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface ICreateIntegrationSuccessAction extends IAction {
  integration: IIntegration;
}
export interface IUpdateIntegrationSuccessAction extends IAction {
  integration: IIntegration;
}
export interface IDeleteIntegrationSuccessAction extends IAction {
  id: string;
}
export interface IArchiveIntegrationsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreIntegrationsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchIntegrationsSuccessAction extends IAction {
  allIntegrations: IBEIntegration[];
}

/**
 * Create integration begin action creator
 * This creates an action informing redux store that the creation process for an integration has just began
 * @returns {Object}
 */
export function createIntegrationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_INTEGRATION_BEGIN,
  };
}

/**
 * Failure Action for creating aN integration instructing that the creation process for a integration has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createIntegrationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_INTEGRATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a integration
 * @param {Object} integration Integration object,
 * @returns {Object}
 */
export const createIntegrationSuccessActionCreator = (
  integration: IIntegration
): ICreateIntegrationSuccessAction => {
  return {
    type: types.CREATE_INTEGRATION_SUCCESS,
    integration,
  };
};

/**
 * Edit Integration begin action creator
 * This creates an action informing redux store that the creation process for a integration has just began
 * @returns {Object}
 */
export function updateIntegrationBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_INTEGRATION_BEGIN,
  };
}

/**
 * Failure Action for editing a integration instructing that the edition process for a integration has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateIntegrationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_INTEGRATION_FAILURE,
    error,
  };
}

/**
 * Success action for editing a integration
 * @param {Object} integration Integration object,
 * @returns {Object}
 */
export function updateIntegrationSuccessActionCreator(
  integration: IIntegration
): IUpdateIntegrationSuccessAction {
  return {
    type: types.UPDATE_INTEGRATION_SUCCESS,
    integration,
  };
}

/**
 * Delete integration begin action creator
 * This creates an action informing redux store that the creation process for a integration has just began
 * @returns {Object}
 */
export function deleteIntegrationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_INTEGRATION_BEGIN,
  };
}

/**
 * Failure Action for creating a integration instructing that the creation process for a integration has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteIntegrationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_INTEGRATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a integration
 * @param {Object} integrationId Integration object,
 * @returns {Object}
 */
export function deleteIntegrationSuccessActionCreator(
  integrationId: string
): IDeleteIntegrationSuccessAction {
  return {
    type: types.DELETE_INTEGRATION_SUCCESS,
    id: integrationId,
  };
}

/**
 * begin action when there is a request to fetch all allIntegrations
 * @returns {Object}
 * */
export const fetchAllIntegrationsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_INTEGRATIONS_BEGIN,
});

/**
 * Failure action for fetching all allIntegrations. This action is dispatched when there is an error fetching all
 * allIntegrations
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllIntegrationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_INTEGRATIONS_FAILURE,
  error,
});

/**
 * Fetch all allIntegrations action creator
 * @param {Array} allIntegrations an array of emailGeneration objects
 * @returns {Object}
 * */
export const fetchAllIntegrationsSuccessActionCreator = (
  allIntegrations: IBEIntegration[]
): IFetchIntegrationsSuccessAction => ({
  type: types.FETCH_ALL_INTEGRATIONS_SUCCESS,
  allIntegrations,
});
