import { Component } from "react";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import TLang from "model/application/Lang";

import CustomDialogForm from "./CustomDialogForm";
import CustomDialogWarning from "./CustomDialogWarning";

export interface ICustomDialogCreateEditProps {
  onEditElement?: (attributes: any) => void;
  onCreateElement?: (attributes: any) => void;
  CreateEditModal: any; // TODO: correct with a more precised type
  createModalTitle?: string;
  editModalTitle?: string;
  attributes: object;
  additionnalProps: object;
  newElement?: any;
  isCreateOpen?: boolean;
  isEditOpen?: boolean;
  isViewOnly?: boolean;
  onClose: () => void;
  onUploadFile?: any;
  validate: TValidateFunction;
  size?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  saveWorkInProgress?: boolean;
  confirmOnLeaving?: boolean;
  closeOnSave?: boolean;
  passwordProtection?: boolean;
  createButtonLabel?: string;
  editButtonLabel?: string;
  lang: TLang;
  nameExist?: string[];
  numberIndex?: number;
  hideCloseButton?: boolean;
}

interface ICustomDialogCreateEditState {
  attributes: any;
  resumeWorkIt: number;
  isWarningModalOpen: boolean;
  isLockModalOpen: boolean;
  warning?: string;
}

/**
 * @legacy use BasicDialog (or variations such as ConfirmationDialog, LoadingDialog, ...) instead
 */
export class CustomDialogCreateEdit extends Component<
  ICustomDialogCreateEditProps,
  ICustomDialogCreateEditState
> {
  public static defaultProps = {
    newElement: {},
  };

  constructor(props: ICustomDialogCreateEditProps) {
    super(props);
    this.state = {
      attributes: props.isCreateOpen ? props.newElement : props.attributes,
      resumeWorkIt: 0,
      isWarningModalOpen: false,
      isLockModalOpen: false,
      warning: "",
    };
  }

  handleConfirmAction = () => {
    const { passwordProtection } = this.props;
    const {
      attributes: { is_protected },
    } = this.state;

    if (passwordProtection || is_protected) {
      this.setState({
        isLockModalOpen: true,
      });
    } else {
      this.confirmCreateEdit();
    }
  };

  confirmCreateEdit = () => {
    const { isCreateOpen } = this.props;
    if (isCreateOpen) {
      this.handleConfirmCreateElement();
    } else {
      this.handleConfirmEditElement();
    }
  };
  /**
   * Handles confirmation of creating a new element
   */
  handleConfirmCreateElement = () => {
    const { attributes } = this.state;
    const { onCreateElement } = this.props;

    if (onCreateElement) onCreateElement(attributes);
    this.handleConfirmClose();
  };

  /**
   * Handles confirmation of editing an existing element
   */
  handleConfirmEditElement = () => {
    const { attributes } = this.state;
    const { onEditElement, closeOnSave } = this.props;

    if (onEditElement) {
      onEditElement(attributes);
    }
    if (closeOnSave) {
      this.handleConfirmClose();
    }
  };

  handleClose = () => {
    const { onClose, confirmOnLeaving, newElement } = this.props;
    if (!confirmOnLeaving) {
      this.setState({
        attributes: newElement,
      });
      onClose();
    } else {
      this.setState({ isWarningModalOpen: true });
    }
  };

  handleConfirmClose = () => {
    const { newElement } = this.props;
    this.setState({
      attributes: newElement,
      isWarningModalOpen: false,
    });
    this.props.onClose();
  };

  prepareTitle = (pattern: string, element: any) => {
    let result = "";
    const splittedPattern = pattern.split("$");
    for (let i = 0; i < splittedPattern.length; i++) {
      if (i % 2 === 1) {
        // even idx: we replace the string per the value of the attribute
        result += element[splittedPattern[i]];
      } else {
        // odd idx: we display the string as it is
        result += splittedPattern[i];
      }
    }
    return result;
  };

  handleChangeAttributeValues = (
    attrName: string,
    attrValue: any,
    shouldRerender: boolean = false
  ) => {
    const { attributes, resumeWorkIt } = this.state;
    let _warning;

    const { nameExist } = this.props;
    if (attributes[attrName] !== attrValue) {
      attributes[attrName] = attrValue;
    }

    if (nameExist?.includes(attributes[attrName])) {
      _warning = "exist";
    } else {
      _warning = "";
    }

    this.setState({
      attributes,
      warning: _warning,
      resumeWorkIt: shouldRerender ? resumeWorkIt + 1 : resumeWorkIt,
    });
  };

  render() {
    const { attributes, isWarningModalOpen, isLockModalOpen } = this.state;
    const {
      isCreateOpen,
      isEditOpen,
      createModalTitle,
      editModalTitle,
      isViewOnly,
      size,
      saveWorkInProgress,
      validate,
      additionnalProps,
      createButtonLabel,
      editButtonLabel,
      lang,
      hideCloseButton,
    } = this.props;

    const errors = validate({
      attributes,
      additionnalProps,
      lang,
      viewMode: isCreateOpen ? "CREATE" : "EDIT",
    });

    const title = isCreateOpen
      ? this.prepareTitle(createModalTitle ? createModalTitle : "", attributes)
      : this.prepareTitle(editModalTitle ? editModalTitle : "", attributes);

    return (
      <div>
        <CustomDialogForm
          title={lang.modal.lockModal.title}
          description={lang.modal.lockModal.description}
          isOpen={isLockModalOpen}
          onClose={() => this.setState({ isLockModalOpen: false })}
          lang={lang}
          onConfirmAction={this.confirmCreateEdit}
        >
          <InputText
            name={"sensible_data_password"}
            type={INPUT_TEXT_TYPE.PASSWORD}
            showPasswordButton={true}
            onChange={(value: any, name: string) =>
              this.handleChangeAttributeValues(name, value)
            }
            required={true}
          />
        </CustomDialogForm>
        <CustomDialogWarning
          isOpen={isWarningModalOpen}
          onClose={() => this.setState({ isWarningModalOpen: false })}
          onConfirmAction={this.handleConfirmClose}
          lang={{
            title: lang.modal.confirmCloseModal.title,
            description: lang.modal.confirmCloseModal.description,
          }}
          rootLang={lang}
        />

        <CustomDialogForm
          size={size}
          isOpen={isCreateOpen || isEditOpen}
          onClose={this.handleClose}
          title={title}
          onConfirmAction={isViewOnly ? undefined : this.handleConfirmAction}
          confirmBtnText={
            isCreateOpen
              ? createButtonLabel
                ? createButtonLabel
                : lang.modal.create
              : editButtonLabel
              ? editButtonLabel
              : lang.modal.save
          }
          isDisabled={Object.keys(errors ? errors : {}).some((x) => errors[x])}
          onDownloadWIP={
            saveWorkInProgress ? this.handleDownloadWIP : undefined
          }
          onUploadWIP={
            saveWorkInProgress && isCreateOpen
              ? this.handleUploadWIP
              : undefined
          }
          lang={lang}
          hideCancelButton={hideCloseButton}
        >
          {this.prepareAttributes(errors)}
        </CustomDialogForm>
      </div>
    );
  }

  handleDownloadWIP = () => {
    let jsonContent = "data:text/json;charset=utf-8,";
    jsonContent += JSON.stringify(this.state.attributes, null, 2);
    const encodedUri = encodeURI(jsonContent).replace(/#/g, "%23");
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("class", "invisible_link");
    const fileName = "workInProgress.json";
    link.setAttribute("download", fileName);
    link.innerHTML = "Click Here to download";
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "workInProgress.json".
    document.body.removeChild(link);
  };

  handleUploadWIP = (file: any) => {
    for (const att in file) {
      this.handleChangeAttributeValues(att, file[att]);
    }
    this.setState({ resumeWorkIt: this.state.resumeWorkIt + 1 });
  };

  prepareAttributes = (errors: any[]) => {
    const {
      CreateEditModal,
      isCreateOpen,
      isViewOnly,
      onUploadFile,
      newElement,
      additionnalProps,
      lang,
      numberIndex,
    } = this.props;
    const { attributes, resumeWorkIt, warning } = this.state;
    return (
      <CreateEditModal
        key={resumeWorkIt}
        attributes={isCreateOpen ? newElement : attributes}
        additionnalProps={additionnalProps}
        onChangeAttributeValues={this.handleChangeAttributeValues}
        onUploadFile={onUploadFile}
        errors={errors}
        isCreation={isCreateOpen}
        isViewOnly={isViewOnly}
        lang={lang}
        warning={warning}
        numberIndex={numberIndex}
      />
    );
  };
}

export default CustomDialogCreateEdit;
