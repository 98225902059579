import { Component } from "react";

import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import { LANGUAGE, STEP_TYPE } from "fieldpro-tools/dist/src/types";
import _ from "lodash";
import getErrorMessage from "log/getErrorMessage";
import { SnackbarProvider } from "notistack";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux";
import { bindActionCreators, Dispatch } from "redux";

import AppNotifications from "components/AppNotifications";
import ChangePasswordDialog from "components/Dialog/ChangePasswordDialog";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import Navbar from "components/NavBar/Navbar";
import StorageBanner, {
  STORAGE_QUOTA_LIMIT,
} from "components/NavBar/StorageBanner";
import Sidebar from "components/Sidebar/Sidebar";
import { WarningModalContext } from "containers/app/AppProviders";
import * as authActions from "containers/authentication/redux/actions";
import { changeLanguage } from "containers/authentication/redux/actions";
import {
  getIsLoggingOut,
  getLang,
  getPrivilege,
  getSignedInUser,
  tokenSelector,
} from "containers/authentication/redux/selector";
import { computePercentage } from "containers/clients/components/ClientForm/Tabs/UsageTab.utils";
import * as clientActions from "containers/clients/redux/actions";
import {
  changeSelectedClientAction,
  fetchClientAction,
} from "containers/clients/redux/actions";
import {
  allClientsSelector,
  clientOptionsSelector,
  getIsFetchingAllClients,
  getSelectedClient,
} from "containers/clients/redux/selectors";
import { fetchAllDashboardsAction } from "containers/dashboards/redux/actions";
import { fetchDocumentsForClientAction } from "containers/documents/redux/actions";
import { fetchAllTableOptimizationsAction } from "containers/environments/redux/tableOptimizations/actions";
import { fetchFoldersAction } from "containers/folders/redux/actions";
import { fetchListsForClientAction } from "containers/lists/redux/actions";
import NotificationsContainer from "containers/notifications/NotificationsContainer";
import { allNotificationsSelector } from "containers/notifications/selectors";
import { fetchTeamsForClientAction } from "containers/teams/redux/actions";
import {
  fetchTerritoriesAction,
  fetchTerritoriesSchemaAction,
} from "containers/territories/redux/actions";
import { fetchMobileUsersAction } from "containers/users/redux/actions/mobileUserActions";
import { fetchWebUsersAction } from "containers/users/redux/actions/webUserActions";
import {
  fetchActivitiesByClientAction,
  fetchWorkflowsByClientAction,
} from "containers/workflows/redux/actions";
import { fetchAllMetaExpressionsAction } from "containers/workflows/redux/meta_expressions/actions";
import { fetchTriggersAction } from "containers/workflows/redux/triggers/actions";
import { fetchAllHttpRequestsAction } from "containers/workflows/subcategories/jobs/redux/http_requests/actions";
import { fetchAllIntegrationsAction } from "containers/workflows/subcategories/jobs/redux/integrations/actions";
import { fetchAllNotificationsAction } from "containers/workflows/subcategories/jobs/redux/notifications/actions";
import { fetchAllScriptsAction } from "containers/workflows/subcategories/jobs/redux/scripts/actions";
import { fetchAllStreamsAction } from "containers/workflows/subcategories/jobs/redux/streams/actions";
import { fetchAllTransformationsAction } from "containers/workflows/subcategories/jobs/redux/transformations/actions";
import * as alllang from "lang";
import { getActionsAvailable } from "model/application/ActionCode";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { IClient, TLightClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";
import { appCleanNotification } from "redux/actionCreators/appActionCreator";
import * as actions from "redux/actions/appActions";
import {
  getAppNotifications,
  getWarningModalParamsSelector,
} from "redux/selectors/appSelector";
import IRootState from "redux/store/model";
import { userCanViewJobs } from "utils/permissions/userCanViewJobs";

import ContentContainer from "./ContentContainer";
import styles from "./styles";

interface IMainLayoutProps extends RouteComponentProps {
  children: any;
  fullWidth?: boolean;
  actions: any;
  authActions: any;
  clientActions: any;
  redirectActions?: any;
  changeSelectedClient: (id: string) => void;
  fetchClient: (clientId: string) => void;
  fetchMobileUsers: (clientId: string) => void;
  fetchWebUsers: (clientId: string) => void;
  fetchFolders: (clientId: string) => void;
  fetchWorkflows: (clientId: string) => void;
  fetchTriggers: (clientId: string) => void;
  fetchActivities: (clientId: string) => void;
  fetchTeams: (clientId: string) => void;
  fetchLists: (clientId: string) => void;
  fetchTerritories: typeof fetchTerritoriesAction;
  fetchTerritoriesSchema: () => void;
  fetchDashboards: (clientId: string) => void;
  fetchTransformations: (clientId: string) => void;
  fetchNotifications: (clientId: string) => void;
  fetchHttpRequests: (clientId: string) => void;
  fetchIntegrations: (clientId: string) => void;
  fetchScripts: (clientId: string) => void;
  fetchStreams: (clientId: string) => void;
  fetchTableOptimizations: (clientId: string) => void;
  fetchDocuments: (clientId: string) => void;
  fetchMetaExpressions: (clientId: string) => void;
  changeLanguage: (lang: string) => void;
  cleanNotification: () => void;
  setActionsAvailable: (actions: any) => void;
  notifications: any[];
  appNotifications: any[];
  token: string | null;
  selectedClient: IClient;
  actionsAvailable: any;
  isLoggingOut: boolean;
  location: any;
  history: any;
  cookies: any;
  clients: TLightClient[];
  clientOptions: IOption[];
  isFetchingClients: boolean;
  refreshClient: () => void;
  route: string;
  privilege: string;
  lang: string;
  classes: any;
  warningModalParams: IWarningModalParams;
  signedInUser: ISignedInUser;
}

interface IMainLayoutStates {
  token: string;
  isLoggingOut: boolean;
  selectedClient: IClient | TLightClient;
  isWarningOpen: boolean;
  // change password modal
  isChangePasswordOpen: boolean;
  // lang
  currLang: TLang;
  disabledSidebar: boolean;
  showAppNotifications: boolean;
  lang: string;
  sidebar: boolean;
  shouldChangePassword?: boolean;
}

interface IWarningModalParams {
  description: string;
  title: string;
  isOpen: boolean;
  onConfirm?: () => any;
}

/**
 * Think of the Main Layout as the HOC of the application. The function of this layout is to allow
 * wrapping the routes in a nested function. This, in this case, is used for the entire application
 * This layout has access to the Redux Store
 * */
export class MainLayout extends Component<IMainLayoutProps, IMainLayoutStates> {
  constructor(props: IMainLayoutProps) {
    super(props);

    this.state = {
      token: "",
      isLoggingOut: false,
      selectedClient: props.clients[0],
      isChangePasswordOpen: false,
      isWarningOpen: false,
      // lang
      currLang: alllang[props.lang],
      disabledSidebar: true,
      showAppNotifications: false,
      lang: props.lang,
      sidebar: false,
    };
  }

  /**
   * Handle logout function. This logs out the current user from the dashboard
   * */
  handleLogout = () => {
    const { authActions, cookies, token } = this.props;

    authActions.logoutAction(token);
    cookies.set("token", null);
  };

  /**
   * Handle open change password modal. This will open the modal to change password
   */
  openChangePassword = () => {
    this.setState({
      isChangePasswordOpen: true,
    });
  };

  /**
   * Handle close change password modal.
   */
  closeChangePassword = () => {
    this.setState({
      isChangePasswordOpen: false,
    });
  };

  confirmChangePassword = (attributes: {
    newPassword: string;
    oldPassword: string;
  }) => {
    const { authActions } = this.props;
    authActions.changePasswordAction(
      attributes.newPassword,
      attributes.oldPassword
    );
    this.closeChangePassword();
  };

  handleGetUserDB = () => {
    const { clientActions, selectedClient } = this.props;
    clientActions.getDBAccessAction(selectedClient.id).then((client: any) => {
      this.context.openWarningModal({
        title: `User DB Credentials`,
        description: `client id: ${selectedClient.id} ==> username: ${client.username}, password: ${client.password}`,
      });
    });
  };

  /**
   * Fetch all the data related to a client
   * @param {String} clientId id of client
   */
  initialiseClientData = async (clientId: string) => {
    const {
      fetchClient,
      fetchDashboards,
      fetchTransformations,
      fetchNotifications,
      fetchHttpRequests,
      fetchIntegrations,
      fetchScripts,
      fetchStreams,
      fetchTableOptimizations,
      fetchLists,
      fetchTerritories,
      fetchTerritoriesSchema,
      fetchWorkflows,
      fetchTriggers,
      fetchFolders,
      fetchActivities,
      fetchTeams,
      fetchMobileUsers,
      fetchWebUsers,
      cleanNotification,
      fetchDocuments,
      fetchMetaExpressions,
      selectedClient,
      privilege,
    } = this.props;

    this.setState({ disabledSidebar: true });

    cleanNotification();

    const profiles = selectedClient.access_right_profiles;
    const actions: any = getActionsAvailable(profiles, selectedClient.profile);

    const userCanViewJobsOfType = (jobType: STEP_TYPE) => {
      return userCanViewJobs({
        jobType,
        availableActions: actions,
        availableJobs: selectedClient.available_jobs,
        role: privilege,
      });
    };

    try {
      await fetchClient(clientId);

      this.handleLangChange(selectedClient.language || LANGUAGE.en);
      const promiseArr = [];

      this.props.setActionsAvailable(actions);

      if (actions.ADVANCED_ACTION_CLIENT) {
        promiseArr.push(fetchTableOptimizations(clientId));
      }

      if (actions.FETCH_DASHBOARD) {
        promiseArr.push(fetchDashboards(clientId));
      }

      if (userCanViewJobsOfType(STEP_TYPE.NOTIFICATION)) {
        promiseArr.push(fetchNotifications(clientId));
      }
      if (userCanViewJobsOfType(STEP_TYPE.HTTP_REQUEST)) {
        promiseArr.push(fetchHttpRequests(clientId));
      }

      if (userCanViewJobsOfType(STEP_TYPE.INTEGRATION)) {
        promiseArr.push(fetchIntegrations(clientId));
      }

      if (userCanViewJobsOfType(STEP_TYPE.TRANSFORMATION)) {
        promiseArr.push(fetchTransformations(clientId));
      }

      if (userCanViewJobsOfType(STEP_TYPE.STREAM)) {
        promiseArr.push(fetchStreams(clientId));
      }

      if (userCanViewJobsOfType(STEP_TYPE.SCRIPT)) {
        promiseArr.push(fetchScripts(clientId));
      }

      // fetch table optimizations from the given client
      //if (actions.ADVANCED_ACTION_CLIENT)
      //  promiseArr.push(fetchTableOptimizations(selectedClient));
      // fetch users from the given client
      if (actions.FETCH_MOB_USER) promiseArr.push(fetchMobileUsers(clientId));
      // fetch users from the given client
      if (actions.FETCH_WEB_USER) promiseArr.push(fetchWebUsers(clientId));
      // fetch lists for the given client
      if (actions.FETCH_LIST) promiseArr.push(fetchLists(clientId));

      if (
        actions.FETCH_TERRITORY &&
        this.props.selectedClient.activate_territory
      ) {
        promiseArr.push(
          fetchTerritories({
            filters: {},
            with_count: true,
          })
        );
        promiseArr.push(fetchTerritoriesSchema());
      }
      // fetch workflows for the given client
      if (actions.FETCH_WORKFLOW) promiseArr.push(fetchWorkflows(clientId));

      promiseArr.push(fetchMetaExpressions(clientId));
      // fetch workflows for the given client
      if (actions.FETCH_WORKFLOW) promiseArr.push(fetchActivities(clientId));
      if (actions.FETCH_WORKFLOW) promiseArr.push(fetchTriggers(clientId));

      // fetch teams for the given client
      if (actions.FETCH_TEAM) promiseArr.push(fetchTeams(clientId));
      // fetch documents for the given client
      if (actions.FETCH_DOCUMENT) promiseArr.push(fetchDocuments(clientId));
      if (actions.FETCH_DASHBOARD) promiseArr.push(fetchFolders(clientId));

      await Promise.all([promiseArr]);

      this.setState({ disabledSidebar: false });
    } catch (e) {
      const message = getErrorMessage(e);
      // Already handled with treatErrorNotification, so no alert
      console.error(
        `Error when trying to fetch the client data for ${clientId}: ${message}`
      );
    }
  };

  handleLangChange = (lang: LANGUAGE) => {
    const { changeLanguage } = this.props;
    changeLanguage(lang.toLowerCase());
  };

  currentlySidebarState = (value: boolean) => {
    this.setState({
      sidebar: value,
    });
  };

  /**
   * Make any necessary updates when we receive props. These props will ideally come from the Redux Store
   * @param {Object} nextProps Next Properties received from the Redux Store state using the mapStateToProps function
   * */
  static getDerivedStateFromProps(
    nextProps: IMainLayoutProps,
    prevState: IMainLayoutStates
  ) {
    const { token, isLoggingOut, lang } = nextProps;

    if (
      token !== prevState.token ||
      isLoggingOut !== prevState.isLoggingOut ||
      lang !== prevState.lang
    ) {
      return {
        token,
        isLoggingOut,
        currLang: alllang[lang],
        lang,
      };
    } else {
      return null;
    }
  }

  redirectToClientForm = (selectedClientId: string) => {
    if (_.includes(location.pathname, "/clients/")) {
      const locationSplitted = _.split(location.pathname, "/");
      const clientIndex = _.indexOf(locationSplitted, "clients");
      locationSplitted[clientIndex + 1] = selectedClientId;

      let url = locationSplitted.join("/");
      if (!_.isEmpty(location.search)) {
        url += location.search;
      }
      this.props.redirectActions?.push(url);
    }
  };

  render() {
    const {
      isChangePasswordOpen,
      currLang,
      disabledSidebar,
      showAppNotifications,
    } = this.state;

    const {
      notifications,
      location,
      history,
      clientOptions,
      children,
      actionsAvailable,
      selectedClient,
      changeSelectedClient,
      refreshClient,
      privilege,
      appNotifications,
      signedInUser,
      classes,
      fullWidth = false,
    } = this.props;

    const { capacity_quota, capacity_used } = selectedClient;
    const isTakingStorageIntoAccount =
      computePercentage(capacity_used, capacity_quota) >=
      0.8 * STORAGE_QUOTA_LIMIT;

    return (
      <>
        <SnackbarProvider style={{ pointerEvents: "all" }}>
          <NotificationsContainer lang={currLang} />
          {!_.includes(window.location.pathname, "/scanner/") && (
            <Sidebar
              actionsAvailable={actionsAvailable}
              history={history}
              location={location}
              lang={currLang}
              disabledSidebar={disabledSidebar}
              stateSidebar={this.currentlySidebarState}
            />
          )}
          <Navbar
            privilege={privilege}
            location={location}
            history={history}
            notificationItems={notifications}
            onLogoutClick={this.handleLogout}
            onOpenChangePasswordClick={this.openChangePassword}
            onGetUserDBClick={this.handleGetUserDB}
            onClientChange={(selectedClientId: string) => {
              changeSelectedClient(selectedClientId);
              this.redirectToClientForm(selectedClientId);
            }}
            selectedClient={selectedClient}
            actionsAvailable={actionsAvailable}
            clientOptions={clientOptions}
            refreshClient={refreshClient}
            appNotifications={appNotifications}
            showAppNotification={() =>
              this.setState({ showAppNotifications: !showAppNotifications })
            }
            signedInUser={signedInUser}
            lang={currLang}
          />
          {showAppNotifications && appNotifications.length > 0 && (
            <AppNotifications
              appNotifications={appNotifications}
              onClickAway={() => this.setState({ showAppNotifications: false })}
            />
          )}

          <div>
            <StorageBanner
              capacity_quota={capacity_quota}
              capacity_used={capacity_used}
            />
            <div
              className={classNames(
                classes.MainContainer,
                this.state.sidebar && "withSidebar",
                isTakingStorageIntoAccount && "takingStorageIntoAccount"
              )}
            >
              <ContentContainer fullWidth={fullWidth}>
                {children}
              </ContentContainer>
            </div>
          </div>
        </SnackbarProvider>
        {this.displayWarningModal()}
        {this.displayChangePasswordModal(isChangePasswordOpen)}
      </>
    );
  }

  displayWarningModal = () => {
    const {
      warningModalParams: { description, title, isOpen, onConfirm },
    } = this.props;
    const { currLang } = this.state;
    let formattedTitle = title;
    if (!title) {
      if (onConfirm) {
        formattedTitle = currLang.modal.confirmCloseModal.title;
      } else {
        formattedTitle = currLang.modal.warningTitle;
      }
    }
    return (
      <CustomDialogWarning
        isOpen={isOpen}
        onConfirmAction={() => {
          if (onConfirm) onConfirm();
          this.props.actions.closeWarningModal();
        }}
        rootLang={this.state.currLang}
        lang={{
          title: formattedTitle,
          description,
        }}
        onClose={() => {
          this.props.actions.closeWarningModal();
        }}
        singleButton={!onConfirm}
      />
    );
  };

  displayChangePasswordModal = (display: boolean) => {
    return (
      <ChangePasswordDialog
        open={display}
        onClose={this.closeChangePassword}
        onEdit={this.confirmChangePassword}
      />
    );
  };

  componentDidMount() {
    const { selectedClient } = this.props;
    if (selectedClient.id) {
      this.initialiseClientData(selectedClient.id);
    }
  }

  /**
   * When the component is mounted, we want to update the document class by toggling the navigation class
   * @param prevProps Previous props before the update
   * @param prevState Previous state before the update of the Component
   * */
  componentDidUpdate(prevProps: IMainLayoutProps) {
    const { selectedClient } = this.props;
    if (
      window.innerWidth < 993 &&
      prevProps.history.location.pathname !== prevProps.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }

    if (prevProps.selectedClient.id !== selectedClient.id) {
      this.initialiseClientData(selectedClient.id);
    }
  }
}

/**
 * maps the state of the redux store to the DashboardContainer props
 * @param {Object} state of redux store
 * @param {Object} ownProps DashboardContainer properties
 * @returns {Object} new state of redux store
 */
function mapStateToProps(state: IRootState) {
  return {
    token: tokenSelector(state),
    privilege: getPrivilege(state),
    clients: allClientsSelector(state),
    clientOptions: clientOptionsSelector(state),
    isLoggingOut: getIsLoggingOut(state),
    notifications: allNotificationsSelector(state),
    appNotifications: getAppNotifications(state),
    warningModalParams: getWarningModalParamsSelector(state),
    isFetchingClients: getIsFetchingAllClients(state),
    selectedClient: getSelectedClient(state),
    signedInUser: getSignedInUser(state),
    lang: getLang(state),
  };
}

/**
 * maps dispatch actions to props in this container
 * component
 * @param {Object} dispatch
 * @returns {Object} actions object
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    fetchTeams: bindActionCreators(fetchTeamsForClientAction, dispatch),
    fetchLists: bindActionCreators(fetchListsForClientAction, dispatch),
    fetchTerritories: bindActionCreators(fetchTerritoriesAction, dispatch),
    fetchTerritoriesSchema: bindActionCreators(
      fetchTerritoriesSchemaAction,
      dispatch
    ),
    fetchTriggers: bindActionCreators(fetchTriggersAction, dispatch),
    fetchWebUsers: bindActionCreators(fetchWebUsersAction, dispatch),
    fetchMobileUsers: bindActionCreators(fetchMobileUsersAction, dispatch),
    fetchFolders: bindActionCreators(fetchFoldersAction, dispatch),
    fetchTableOptimizations: bindActionCreators(
      fetchAllTableOptimizationsAction,
      dispatch
    ),
    fetchWorkflows: bindActionCreators(fetchWorkflowsByClientAction, dispatch),
    fetchActivities: bindActionCreators(
      fetchActivitiesByClientAction,
      dispatch
    ),
    fetchDashboards: bindActionCreators(fetchAllDashboardsAction, dispatch),
    fetchNotifications: bindActionCreators(
      fetchAllNotificationsAction,
      dispatch
    ),
    fetchHttpRequests: bindActionCreators(fetchAllHttpRequestsAction, dispatch),
    fetchIntegrations: bindActionCreators(fetchAllIntegrationsAction, dispatch),
    fetchTransformations: bindActionCreators(
      fetchAllTransformationsAction,
      dispatch
    ),
    fetchStreams: bindActionCreators(fetchAllStreamsAction, dispatch),
    fetchScripts: bindActionCreators(fetchAllScriptsAction, dispatch),
    setActionsAvailable: bindActionCreators(
      authActions.setActionsAvailableAction,
      dispatch
    ),
    changeSelectedClient: bindActionCreators(
      changeSelectedClientAction,
      dispatch
    ),
    fetchClient: bindActionCreators(fetchClientAction, dispatch),
    fetchDocuments: bindActionCreators(fetchDocumentsForClientAction, dispatch),
    fetchMetaExpressions: bindActionCreators(
      fetchAllMetaExpressionsAction,
      dispatch
    ),
    changeLanguage: bindActionCreators(changeLanguage, dispatch),
    cleanNotification: () => dispatch(appCleanNotification()),
    redirectActions: bindActionCreators(routerActions, dispatch),
  };
}

MainLayout.contextType = WarningModalContext;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles as any)(MainLayout))
);
