import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

const isDebug = () => {
  if (process.env.REACT_APP_REDUX_DEVTOOLS) {
    return true;
  }

  const env = process.env.REACT_APP_ENV;
  if (
    env === "production" ||
    env === "staging" ||
    env === "productiondirect" ||
    env === "stagingdirect"
  ) {
    return false;
  } else if (env === "develop") {
    return true;
  }
};

// Persist config
// ref: https://github.com/rt2zz/redux-persist
export const persistConfig = {
  key: "root",
  storage,
  debug: isDebug(),
  stateReconciler: autoMergeLevel2,
  // "lists" container data is not persisted, because the partial_data
  // behaviour to fetch items is not clean when reloading the page
  // Also, list items can be very heavy and exceed chrome's default quota
  blacklist: [
    "notify",
    "modal",
    "routing",
    "ajax",
    "lists",
    "orders",
    "erpOrders",
    "prices",
    "customers",
  ],
};
