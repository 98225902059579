import { IDispatchAndGetState } from "redux/store/model";

import { showNotificationActionCreator } from "./actionCreator";
import { TNotificationActionLevel } from "./actionLevels";
import { TNotificationActionType } from "./actionTypes";

interface IShowNotificationAction {
  type: TNotificationActionType;
  level: TNotificationActionLevel;
  message: string;
  detailedMessage?: string;
}

/**
 * Instantly display a notification in the web-app
 * (not to be confused with createNotificationAction in (...)/jobs/redux/notifications)
 * */
export function showNotificationAction({
  type,
  level,
  message,
  detailedMessage,
}: IShowNotificationAction): IDispatchAndGetState<void> {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        const notificationAction = showNotificationActionCreator(
          type,
          level,
          message,
          detailedMessage
        );

        dispatch(notificationAction);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };
}
