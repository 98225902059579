import React, { useState } from "react";

import { Box, Button, makeStyles } from "@material-ui/core";
import _ from "lodash";

import {
  Background,
  brownishGrey,
  White,
  YellowSecondary,
} from "assets/colors";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import FormSectionHeader from "components/Forms/Form/Section/FormSectionHeader";
import { SUPPORT_ADRESSE_EMAIL } from "containers/authentication/utils/constants";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";

import { computePercentage, formatMaxTitle } from "./UsageTab.utils";
import Quota from "./UsageTab/Quota";

export interface IUsageTab {
  maxValue: number;
  currentValue: number;
  minValue?: 0;
}

const useStyles = makeStyles({
  Paragraph: {
    fontWeight: 500,
    letterSpacing: "-0.25px",
    width: "720px",
    fontSize: "16px",
    marginBottom: "17px",
  },

  StorageBox: {
    background: Background,
    marginBottom: "45px",
    fontWeight: 500,
    padding: "10px",
    lineHeight: "18px",
  },
});

const UsageTab: React.FunctionComponent<IUsageTab> = ({
  maxValue,
  currentValue,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const lang = useTranslations();

  const langTab = lang.containers.clients.subCategories.clients.createEditModal;

  const textWithVariable = (label: string, text: string) =>
    formatString(label, [text]);

  const percentage = computePercentage(currentValue, maxValue);

  return (
    <Box color={brownishGrey}>
      {langTab.title && <FormSectionHeader title={langTab.title} />}

      <p className={classes.Paragraph}>
        {langTab.description &&
          textWithVariable(
            langTab.description,
            formatMaxTitle(_.toString(maxValue))
          )}
      </p>

      <div className={classes.StorageBox}>
        <span style={{ marginRight: "48px" }}>
          {textWithVariable(
            langTab.inputUsageTab.selectModal.title,
            _.toString(percentage)
          )}
        </span>
        <Button
          style={{ background: YellowSecondary, color: White }}
          onClick={() => setOpenModal(true)}
        >
          {langTab.inputUsageTab.selectModal.description}
        </Button>
      </div>

      <Quota max={maxValue} current={currentValue} />

      {openModal && (
        <CustomDialogWarning
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          rootLang={lang}
          lang={{
            title: langTab.inputUsageTab.warningMessage.title,
            description: (
              <div>
                <span>{langTab.inputUsageTab.warningMessage.description}</span>
                <span style={{ color: YellowSecondary }}>
                  {SUPPORT_ADRESSE_EMAIL}
                </span>
              </div>
            ),
          }}
          singleButton
        />
      )}
    </Box>
  );
};

export default UsageTab;
