import { INotification } from "model/entities/Job";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareNotificationsForFrontend } from "./utils";

export interface INotificationState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  isRunning: false;
  isFetchingJobHistory: boolean;
  selectedNotification: INotification | null;
  notifications: INotification[];
  errors: any[];
}

/**
 * Notifications reducer takes current state and action and returns a new state for the Notification object in redux store
 * @param state initial state of Notifications
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_NOTIFICATION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_NOTIFICATION_SUCCESS: {
      const { notification } =
        action as actionCreators.ICreateNotificationSuccessAction;

      return {
        ...state,
        isCreating: false,
        notifications: insertNewItemToArr(state.notifications, notification),
      };
    }

    case types.UPDATE_NOTIFICATION_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_NOTIFICATION_SUCCESS: {
      const { notification } =
        action as actionCreators.IUpdateNotificationSuccessAction;

      const notifications = state.notifications.map((t) =>
        t.id === notification.id ? notification : t
      );

      return {
        ...state,
        isUpdating: false,
        notifications,
      };
    }

    case types.DELETE_NOTIFICATION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_NOTIFICATION_SUCCESS: {
      const { id } = action as actionCreators.IDeleteNotificationSuccessAction;

      const notifications = state.notifications.filter((t) => t.id !== id);

      return {
        ...state,
        isDeleting: false,
        notifications,
      };
    }

    case types.FETCH_ALL_NOTIFICATIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_NOTIFICATIONS_SUCCESS: {
      const { allNotifications: beEmailGenerations } =
        action as actionCreators.IFetchNotificationsSuccessAction;
      const notifications = prepareNotificationsForFrontend(beEmailGenerations);
      return {
        ...state,
        isFetchingAll: false,
        notifications,
      };
    }
    case types.RUN_NOTIFICATION_JOB_BEGIN:
      return {
        ...state,
        isRunning: true,
      };

    case types.RUN_NOTIFICATION_JOB_FAILURE:
      return {
        ...state,
        isRunning: false,
        errors: updateErrorsList(state, action),
      };

    case types.RUN_NOTIFICATION_JOB_SUCCESS: {
      return {
        ...state,
        isRunning: false,
      };
    }

    default:
      return state;
  }
}
