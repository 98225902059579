import React, { useState } from "react";

import { InputAdornment, InputBase } from "@material-ui/core";
import { BoxProps, makeStyles } from "@material-ui/core/";
import SearchIcon from "@mui/icons-material/Search";

import * as colors from "assets/colors";

const styles = {
  InputSearch: {
    border: `2px solid ${colors.GreyDark}`,
    borderRadius: "4px",
    padding: "6px 10px 6px 16px", // 6px instead of 8px to take border into account; total height must be 36px;
    color: colors.Black,
    // fontWeight: 500,

    "& input": {
      padding: 0,
      fontSize: "16px",
      lineHeight: "20px",
      height: "20px",
      "&::placeholder": {
        color: colors.GreyDark,
        opacity: 1,
      },
    },
  },
};

const useStyles = makeStyles(styles);

export interface IInputSearchPropsBase {
  name?: string;
  defaultValue?: string;
  onChange: (value: any, name: string) => void;
  onEnter?: (value: any, name: string) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  dataTestId?: string;
}
export interface IInputSearchProps
  extends IInputSearchPropsBase,
    Omit<BoxProps, keyof IInputSearchPropsBase> {}

export const InputSearch: React.FunctionComponent<IInputSearchProps> = ({
  name = "search",
  onChange,
  placeholder = "Search",
  required = false,
  disabled = false,
  fullWidth = true,
  defaultValue = "",
  dataTestId,
  onEnter,
}: IInputSearchProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: any) => {
    const value: string = event.target.value;
    setValue(value);
    if (onChange) {
      onChange(value, name);
    }
  };
  const handleEnter = (event: any) => {
    if (onEnter && event.keyCode == 13) {
      const value: string = event.target.value;
      setValue(value);
      if (onEnter) {
        onEnter(value, name);
      }
    }
  };

  return (
    <InputBase
      className={classes.InputSearch}
      onChange={handleChange}
      onKeyDown={handleEnter}
      id={name}
      data-testid={dataTestId || name}
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth ? fullWidth : false}
      margin="none"
      endAdornment={
        <InputAdornment position="end" style={{ marginLeft: "0px" }}>
          <SearchIcon style={{ color: colors.GreyDark }} />
        </InputAdornment>
      }
    />
  );
};

export default InputSearch;
