import React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

import styles from "./styles";

interface IFullScreenLoaderProps {
  title?: string;
  message?: string;
}

const useStyles = makeStyles(styles as any);

export const FullScreenLoader: React.FunctionComponent<
  IFullScreenLoaderProps
> = ({
  message = "We are getting everything ready for you",
  title = "Please wait...",
}) => {
  const classes = useStyles();

  return (
    <div className={classes.LoadingContainerFullscreen}>
      <div className={classes.LoadingSubcontainerFullscreen}>
        <div className={classes.LoadingTitleFullscreen}>{title}</div>
        <div className={classes.LoadingTextFullscreen}>{message}</div>
        <div className={classes.loaderWrapper}>
          <CircularProgress color={"inherit"} />
        </div>
      </div>
    </div>
  );
};

FullScreenLoader.defaultProps = {
  message: "We are getting everything ready for you",
  title: "Please wait...",
};

export default FullScreenLoader;
