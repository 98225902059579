import { IIntegration } from "model/entities/Job";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all integrations from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllIntegrations = (state: IRootState) =>
  state.jobs.integrations.integrations;

/**
 * Selector to get all Integrations from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allIntegrationsSelector = customSelectorCreator(
  [getAllIntegrations],
  (allIntegrations: IIntegration[]) => {
    // map all the integrations
    return allIntegrations;
  }
);

/**
 * Gets the status of fetching an integration
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingAllIntegrations = (state: IRootState) =>
  state.jobs.integrations.isFetchingAll;

/**
 * Gets the status of creating an integration
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingIntegration = (state: IRootState) =>
  state.jobs.integrations.isCreating;

/**
 * Gets the status of updating an integration
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingIntegration = (state: IRootState) =>
  state.jobs.integrations.isUpdating;

/**
 * Gets the status of deleting an integration
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingIntegration = (state: IRootState) =>
  state.jobs.integrations.isDeleting;
