import React, { useState } from "react";

import { Box, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import { Background, GreyLight, orange, yellow } from "assets/colors";

import { IItemGalleryItem } from "./ItemGallery";

export interface IItemGalleryCard {
  label: string;
  pictureURL?: string;
  selected?: boolean;
  item: IItemGalleryItem;
  onRemoveItem?: (item: IItemGalleryItem) => void;
  onClick?: (item: IItemGalleryItem) => void | undefined;
}

const height = "225px";
const width = "217px";

const styles = {
  deleteIcon: {
    position: "absolute",
    top: "8px",
    right: "8px",
    color: orange,
    cursor: "pointer",
    transition: "visibility 0.1s ease, opacity 0.1s ease !important",
    visibility: "hidden",
    opacity: "0",
    "$container:hover > &": {
      visibility: "visible",
      opacity: "1",
    },
  },
  container: ({ selected }: { selected: boolean }) => ({
    width: width,
    height: height,
    display: "grid",
    gridTemplateRows: `calc(${height} - 76px) 76px`,
    ...(selected && {
      border: `solid ${yellow} 3px`,
    }),
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
  }),
  labelArea: ({ selected }: { selected: boolean }) => ({
    display: "flex",
    paddingLeft: "16px",
    alignItems: "center",
    transition: "0.3s",
    ...(selected && {
      background: `rgba(254, 189, 85,0.25)`,
    }),
  }),
  label: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: `calc(${width} - 16px)`,
    fontSize: "16px",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  icon: {
    fontSize: "70px !important",
    color: GreyLight,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};

const useStyles = makeStyles(styles as any);

function ItemGalleryCard({
  item,
  label,
  pictureURL,
  selected = false,
  onRemoveItem,
  onClick,
}: IItemGalleryCard) {
  const classes = useStyles({ selected });

  const [imageError, setImageError] = useState(false);

  function handleDeleteIconClick(event: any) {
    event.stopPropagation();
    if (onRemoveItem) {
      onRemoveItem(item);
    }
  }

  function handleClick() {
    if (onClick) {
      onClick(item);
    }
  }

  return (
    <Paper
      className={classes.container}
      elevation={1}
      onClick={handleClick}
      data-testid="item-gallery-card"
    >
      <Box style={{ background: Background }}>
        <Box className={classes.iconContainer}>
          {/* TODO: create a reusable <ImageWithFallback/> with the imageError state */}
          {pictureURL && !imageError ? (
            <img
              src={pictureURL}
              className={classes.image}
              data-testid="image"
              onError={() => setImageError(true)}
            />
          ) : (
            <ImageOutlinedIcon
              className={classes.icon}
              data-testid="no-image"
            />
          )}
        </Box>
      </Box>
      <Box className={classes.labelArea}>
        <Tooltip title={label}>
          <Typography className={classes.label}>{label}</Typography>
        </Tooltip>
      </Box>
      {onRemoveItem ? (
        <DeleteIcon
          className={classes.deleteIcon}
          onClick={handleDeleteIconClick}
          data-testid="delete-button"
        />
      ) : null}
    </Paper>
  );
}

export default React.memo(ItemGalleryCard);
