import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import {
  CLIENT_CATEGORY,
  IAccessRightProfile,
  IClient,
} from "model/entities/Client";

export const getErrorMessagesForClient: TValidateFunction<Partial<IClient>> = ({
  attributes,
  lang,
  additionnalProps,
}) => {
  const {
    name = "",
    app_user_licenses = 0,
    magic_number,
    is_gps_tracking_enabled,
    geo_checkin_distance,
    geo_checkout_distance,
    region = "",
    country = "",
    sales_owner = "",
    operations_owner = "",
    sector = "",
    category = "",
    mobile_login_modes,
  } = attributes;

  const result = {};

  if (name.length === 0) {
    result["name"] = lang.components.inputErrors.empty;
  }
  if (category.length === 0) {
    result["category"] = lang.components.inputErrors.empty;
  }

  const partnerAndSaasFields = [
    { name: "region", value: region },
    { name: "country", value: country },
    ...(!additionnalProps.isCreation
      ? [
          { name: "sales_owner", value: sales_owner },
          { name: "operations_owner", value: operations_owner },
        ]
      : []),
  ];

  if (category === CLIENT_CATEGORY.PARTNER) {
    partnerAndSaasFields.forEach(({ name, value }) => {
      if (value.length === 0) {
        result[name] = lang.components.inputErrors.empty;
      }
    });
  }
  if (
    category === CLIENT_CATEGORY.SAAS ||
    category === CLIENT_CATEGORY.SAAS_PROJECT
  ) {
    partnerAndSaasFields.forEach(({ name, value }) => {
      if (value.length === 0) {
        result[name] = lang.components.inputErrors.empty;
      }
      if (sector.length === 0)
        result["sector"] = lang.components.inputErrors.empty;
    });
  }

  if (!app_user_licenses || app_user_licenses === 0) {
    result["app_user_licenses"] = lang.components.inputErrors.empty;
  }

  if (
    magic_number &&
    magic_number.length > 0 &&
    !magic_number.match(/[0-9]{6}/)
  ) {
    result["magic_number"] = lang.components.inputErrors.wrongFormat;
  }
  if (is_gps_tracking_enabled && !geo_checkin_distance) {
    result["geo_checkin_distance"] = lang.components.inputErrors.empty;
  }
  if (!mobile_login_modes || mobile_login_modes.length === 0) {
    result["mobile_login_modes"] = lang.components.inputErrors.empty;
  }
  if (is_gps_tracking_enabled && !geo_checkout_distance) {
    result["geo_checkout_distance"] = lang.components.inputErrors.empty;
  }
  if (Object.keys(result).length === 0) {
    return {};
  }

  return result;
};

export const getErrorMessagesForProfile: TValidateFunction<
  IAccessRightProfile,
  { profiles: IAccessRightProfile[] }
> = ({ attributes, additionnalProps, lang, viewMode }) => {
  const { name } = attributes;
  const { profiles } = additionnalProps;

  const result = {};

  if (!name || name.length === 0) {
    result["name"] = lang.components.inputErrors.empty;
  }
  if (viewMode === "CREATE") {
    if (profiles.map((p) => p.name).includes(name)) {
      result["name"] = lang.components.inputErrors.alreadyInUse;
    }
  }
  if (Object.keys(result).length === 0) {
    return {};
  }
  return result;
};

export const getErrorMessagesForDuplicateClient: TValidateFunction = ({
  attributes,
  lang,
  additionnalProps,
}) => {
  const { name } = attributes;
  const { clients } = additionnalProps;

  const result = {};

  if (!name || name.length === 0) {
    result["name"] = lang.components.inputErrors.empty;
  }
  if (clients.map((client: IClient) => client.name).includes(name)) {
    result["name"] = lang.components.inputErrors.alreadyInUse;
  }
  if (Object.keys(result).length === 0) {
    return {};
  }
  return result;
};
