/**
 * Store configurations for the development environment. This is used when running the application in dev mode
 * This will add middleware that the store will use in the development environment. DO NOT, import this directly
 * when configuring the store with the root component for the application
 */
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";

import persistedReducer from "redux/reducers/rootReducer";

import { productionMiddleware } from "../middleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Configures the application store
 * */
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...productionMiddleware))
  );

  // TODO: what is this for ? does it still work ?
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/rootReducer", () => {
      const nextRootReducer = require("../reducers/rootReducer").default;
      store.replaceReducer(persistedReducer, nextRootReducer);
    });
  }

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
