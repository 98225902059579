import { IMobileUser as IBEMobileUser } from "fieldpro-tools/dist/src/types/users";

import apiService from "api/apiService";
import { prepareMobileUserForBackend } from "containers/users/redux/utils";
import { IMobileUser } from "model/entities/User";
import { IServerResponse } from "redux/actions/appActions";

export const APP_USERS_ENDPOINT = "/mobileusers";
export const APP_USER_ENDPOINT = "/mobileuser";

export type TCreateMobileUsersFunc = (
  actionName: string,
  users: IMobileUser[]
) => Promise<IServerResponse<{ id: string; iid: string }[]>>;
export const createMobileUsersApiCall: TCreateMobileUsersFunc = (
  actionName,
  users
) => {
  return apiService.post(
    `${APP_USERS_ENDPOINT}`,
    {
      users: users.map((user) => prepareMobileUserForBackend(user)),
    },
    actionName
  );
};

export type TFetchMobileUsersFunc = () => Promise<
  IServerResponse<{ users: IBEMobileUser[]; mobile_teammates: any[] }>
>;
export const fetchMobileUsersApiCall: TFetchMobileUsersFunc = () => {
  return apiService.get(`${APP_USERS_ENDPOINT}`);
};

export type TDeleteMobileUserFunc = (
  actionName: string,
  userId: string
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const removeMobileUserFromClientApiCall: TDeleteMobileUserFunc = (
  actionName,
  userId: string
) => {
  return apiService.delete(
    `${APP_USER_ENDPOINT}/${userId}`,
    undefined,
    actionName
  );
};

export type TDeleteMobileUsersFunc = (
  actionName: string,
  userIds: string[]
) => Promise<IServerResponse<{}>>;
export const removeMobileUsersFromClientApiCall: TDeleteMobileUsersFunc = (
  actionName: string,
  userIds: string[]
) => {
  return apiService.delete(
    `${APP_USERS_ENDPOINT}`,
    {
      ids: userIds,
    },
    actionName
  );
};

export type TUpdateMobileUserFunc = (
  actionName: string,
  mobileUser: IMobileUser
) => Promise<IServerResponse<{ success: boolean }>>;
export const updateMobileUserApiCall: TUpdateMobileUserFunc = (
  actionName: string,
  mobileUser: IMobileUser
) => {
  // remove id from the body
  const preparedMobileUser = prepareMobileUserForBackend(mobileUser);
  const { id: _id, ...body } = preparedMobileUser;
  return apiService.patch(
    `${APP_USER_ENDPOINT}/${mobileUser.id}`,
    body,
    actionName
  );
};

export type TUpdateMobileUsersFunc = (
  actionName: string,
  mobileUsers: IMobileUser[]
) => Promise<IServerResponse<{ success: boolean }>>;
export const updateMobileUsersApiCall: TUpdateMobileUsersFunc = (
  actionName,
  mobileUsers: IMobileUser[]
) => {
  const body = {
    users: mobileUsers.map((u) => prepareMobileUserForBackend(u)),
  };
  return apiService.patch(`${APP_USERS_ENDPOINT}`, body, actionName);
};

export type TLicenseMobileUserFunc = (
  actionName: string,
  userId: string
) => Promise<IServerResponse<{ success: boolean }>>;
export const licenseMobileUserApiCall: TLicenseMobileUserFunc = (
  actionName,
  userId: string
) => {
  return apiService.post(
    `/license`,
    {
      user_id: userId,
    },
    actionName
  );
};

export type TUnLicenseMobileUserFunc = (
  actionName: string,
  userId: string
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const unlicenseMobileUserApiCall: TUnLicenseMobileUserFunc = (
  actionName,
  userId: string
) => {
  return apiService.delete(`/license/${userId}`, undefined, actionName);
};

export type TBulkLicenseMobileUserFunc = (
  actionName: string,
  userIds: string[]
) => Promise<IServerResponse<{ success: boolean }>>;
export const bulkLicenseMobileUsersApiCall: TBulkLicenseMobileUserFunc = (
  actionName,
  userIds: string[]
) => {
  return apiService.post(
    `/licenses`,
    {
      mobile_users: userIds,
    },
    actionName
  );
};

export type TBulkUnLicenseMobileUserFunc = (
  actionName: string,
  userIds: string[]
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const bulkUnlicenseMobileUsersApiCall: TBulkUnLicenseMobileUserFunc = (
  actionName,
  userIds: string[]
) => {
  return apiService.delete(
    `/licenses`,
    {
      mobile_users: userIds,
    },
    actionName
  );
};

export type TUploadFileFunc = (
  actionName: string,
  files: any[],
  fileMetas: any
) => Promise<
  IServerResponse<{
    uploaded: boolean;
    files: { filename: string; url: string }[];
  }>
>;
export const uploadFileApiCall: TUploadFileFunc = (
  actionName: string,
  files: any[],
  fileMetas: any
) => {
  return apiService.multipartUpdate(
    `/files`,
    files,
    {
      file_metas: fileMetas,
    },
    actionName
  );
};

export type TUnlockLoginFunc = (
  actionName: string,
  mobileUserId: string
) => Promise<IServerResponse<{ [s: string]: string }>>;
export const unlockLoginApiCall: TUnlockLoginFunc = (
  actionName,
  mobileUserId
) => {
  return apiService.post(`/mobileuser/${mobileUserId}/unlock`, {}, actionName);
};

export type TUnlockUniqueLoginFunc = (
  mobileUserId: string
) => Promise<IServerResponse<{ [s: string]: string }>>;
export const unlockUniqueLoginApiCall: TUnlockUniqueLoginFunc = (
  mobileUserId
) => {
  return apiService.post(`/mobileuser/${mobileUserId}/unique_unlock`);
};
