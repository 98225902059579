import { IDocument } from "model/entities/Document";
import { IAction, IActionError } from "redux/store/model";

import {
  IChangeFolderSuccessActionCreator,
  IFetchDocumentsSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface Documentstate {
  isFetchingDocuments: boolean;
  isUploadingDocuments: boolean;
  isUpdatingDocument: boolean;
  isDeletingDocuments: boolean;
  allDocuments: IDocument[];
  errors: any[];
  subCategorySelected: string;
}

const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case types.FETCH_DOCUMENTS_BEGIN:
      return {
        ...state,
        isFetchingDocuments: true,
      };
    case types.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        allDocuments: (action as IFetchDocumentsSuccessAction).documents,
        isFetchingDocuments: false,
      };
    case types.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFetchingDocuments: false,
        errors: (action as IActionError).error,
      };
    case types.UPLOAD_DOCUMENTS_BEGIN:
      return {
        ...state,
        isUploadingDocuments: true,
      };
    case types.UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isUploadingDocuments: false,
      };
    case types.UPLOAD_DOCUMENTS_FAILURE:
      return {
        ...state,
        isUploadingDocuments: false,
        errors: (action as IActionError).error,
      };

    case types.DELETE_DOCUMENTS_BEGIN:
      return {
        ...state,
        isDeletingDocuments: true,
      };
    case types.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isDeletingDocuments: false,
      };
    case types.DELETE_DOCUMENTS_FAILURE:
      return {
        ...state,
        isDeletingDocuments: false,
        errors: (action as IActionError).error,
      };
    case types.CHANGE_FOLDER_SELECTED_SUCCESS: {
      const { folderSelected } = action as IChangeFolderSuccessActionCreator;
      return {
        ...state,
        folderSelected: folderSelected,
      };
    }
    case types.UPDATE_DOCUMENT_BEGIN: {
      return {
        ...state,
        isUpdatingDocument: true,
      };
    }
    case types.UPDATE_DOCUMENT_FAILURE: {
      return {
        ...state,
        isUpdatingDocument: false,
        errors: (action as IActionError).error,
      };
    }
    case types.UPDATE_DOCUMENT_SUCCESS: {
      const { document } = action as any;
      const allDocuments = state.allDocuments.map((d) => {
        if (d.id === document.id) {
          return {
            ...d,
            name: document.name,
            folder: document.folder,
            scope: document.scope,
            ...(document.teams && { teams: document.teams }),
          };
        }
        return d;
      });
      return {
        ...state,
        isUpdatingDocument: false,
        allDocuments,
      };
    }
    default:
      return state;
  }
};

export default reducer;
