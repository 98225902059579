import React from "react";

import _ from "lodash";

import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";

const getOptionByKey = (key: string, options?: IOption[]) =>
  _.find(options, { key });

const getOptionByValue = (value: any, options?: IOption[]) =>
  _.find(options, (option) => {
    if (_.has(option, "value")) {
      return value === option.value;
    }
    return value === option.key;
  });

export const useHooks = (filter: IFilter) => {
  const [checkedOptions, setCheckedOptions] = React.useState<any[]>(
    _.map(filter.value, (value) => getOptionByValue(value, filter.options))
  );
  const isChecked = (key: string): boolean => !!_.find(checkedOptions, { key });

  const sortedOptions = _.sortBy(
    filter.options,
    (option) => !isChecked(option.key)
  );
  const [options, setOptions] = React.useState<IOption[]>(sortedOptions);

  const handleCheckboxChange = (optionKey: any, checked: boolean) => {
    const currentOption = getOptionByKey(optionKey, options);

    //if checkbox is selected
    if (checked) {
      if (filter.hasOwnProperty("singleSelection") && filter.singleSelection) {
        setCheckedOptions([currentOption]);
      } else {
        setCheckedOptions([...checkedOptions, currentOption]);
      }
    } else {
      //remove the unselected filter
      setCheckedOptions(_.reject(checkedOptions, { key: optionKey }));
    }
  };

  //search
  const onChangeSearch = (search: string) => {
    if (search.length > 0) {
      setOptions(
        filter.options!.filter((o) => {
          return o.label.toLowerCase().search(search.toLowerCase()) !== -1;
        })
      );
    } else {
      setOptions(filter.options!);
    }
  };

  return {
    checkedOptions,
    onChangeSearch,
    handleCheckboxChange,
    options,
    setCheckedOptions,
  };
};
