import { Box } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useOpenStripePortal from "hooks/useOpenStripePortal";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";

export interface IUpdatePaymentDetailsWarningModal {
  handleCloseDialog?: () => void;
}

function UpdatePaymentDetailsModal({
  handleCloseDialog,
}: IUpdatePaymentDetailsWarningModal) {
  const client = useSelector(getSelectedClient);
  const openStripePortal = useOpenStripePortal(client.id);

  const lang = useTranslations();
  const modalLang =
    lang.containers.clients.subCategories.clients.createEditModal;

  const subscriptionLang = modalLang.subscription;

  const modalDescription = formatString(
    subscriptionLang.customMessage.pleaseUpdatePaymentBeforeX,
    // TODO: this is not correct ? This is the date of the next invoice, not to date when Stripe will update the invoice/subscription statuses
    [moment(client?.billing?.license_plan?.next_billing_date)]
  );

  const confirmBtnText = modalLang.inputUpdatePaymentDetails.title;
  const modalTitle = subscriptionLang.customMessage.paymentFailed;

  return (
    <Box data-testid="update-payment-details-modal">
      <CustomDialogWarning
        isOpen
        rootLang={lang}
        confirmBtnText={confirmBtnText}
        onClose={handleCloseDialog}
        onConfirmAction={openStripePortal}
        lang={{
          description: modalDescription,
          title: modalTitle,
        }}
      />
    </Box>
  );
}

export default UpdatePaymentDetailsModal;
