import SmallIconButton from "components/Buttons/SmallIconButton";

const BasicScannerIcon = (props: any) => {
  return (
    <SmallIconButton {...props}>
      <span className="material-icons-outlined">qr_code_scanner</span>
    </SmallIconButton>
  );
};

export default BasicScannerIcon;
