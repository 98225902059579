import { useMemo } from "react";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

// See https://react-redux.js.org/api/hooks#recipe-useactions
// Best to use useDispatch instead^, but for a quick replacement
// of mapDispatchToProps + bindActionCreators this will do
// ^ https://github.com/facebook/create-react-app/issues/6880#issuecomment-488158024
/**
 * @deprecated LEGACY - DO NOT USE, prefer "useActions"
 */
export function useActionsLegacy(actions: any, deps?: any) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

type FnType = (...args: any) => any;
export type TDeeplyNestedReturnType<T extends FnType> =
  ReturnType<T> extends FnType
    ? TDeeplyNestedReturnType<ReturnType<T>>
    : ReturnType<T>;

export function useActions<T extends FnType>(
  arg: T
): (...arg: Parameters<T>) => TDeeplyNestedReturnType<T>;

export function useActions<T extends Array<any>>(
  arg: T
): {
  [K in keyof T]: (...arg: Parameters<T[K]>) => TDeeplyNestedReturnType<T[K]>;
};

/**
 * NEW - This is a more type safe version of useActions
 *
 * Still, it is best to use useDispatch whenever possible
 * See https://github.com/facebook/create-react-app/issues/6880#issuecomment-488158024
 */
export function useActions(actions: any, deps?: any) {
  return useActionsLegacy(actions, deps);
}

export default useActions;
