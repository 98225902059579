import { Box, makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles);
export interface ISignatureCell {
  link?: string;
  onClick?: () => void;
}

const SignatureCell = ({ link, onClick }: ISignatureCell) => {
  const classes = useStyles();
  if (!link) {
    return null;
  }
  return (
    <Box className={classes.CellImageContainer} onClick={onClick}>
      <img className={classes.CellImage} src={link} loading="lazy" />
      {/* Replace img tag with placeholder below if performance becomes an issue */}
      {/* <span className="material-icons-outlined" style={{ color: lightgray }}>
        draw
      </span> */}
    </Box>
  );
};

export default SignatureCell;
