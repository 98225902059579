import { useContext, useEffect, useState } from "react";

import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import {
  BasicArrowIcon,
  BasicCloseIcon,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import BasicDeleteIcon from "components/Dialog/BasicDialog/Icons/BasicDeleteIcon";
import { LoggerContext } from "containers/app/AppProviders";
import { getSignedInUser } from "containers/authentication/redux/selector";
import EditActivityReportButton from "containers/workflows/components/EditActivityReportButton";
import {
  createActivityReportAction,
  createWorkflowReportAction,
  fetchWorkflowReportAction,
  updateActivityReportAction,
  updateWorkflowReportAction,
  uploadActivityReportOrWorkflowFileAction,
} from "containers/workflows/redux/actions";
import {
  allMetaExpressionsSolvedSelector,
  getIsComputingMetaExpression,
} from "containers/workflows/redux/meta_expressions/selectors";
import {
  getAllActivities,
  workflowReportByIdSelector,
} from "containers/workflows/redux/selectors";
import useActions, { useActionsLegacy } from "hooks/useActions";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IActivity,
  IActivityReport,
  IActivityReportInTable,
  IQuestion,
  IWorkflow,
  IWorkflowReportWithStepOverview,
  IWorkflowStep,
  STEP_STATUS,
} from "model/entities/Workflow";
import IRootState from "redux/store/model";
import { getNextStepActivity } from "utils/resolveChaining/nextStep";

import {
  filterQuestionsOnSolvedConditions,
  getErrorMessagesForActivityReport,
} from "../../reports/utils";
import { isReviewActivity } from "../../workflows/utils/reviewActivityUtils";
import convertActivityReport from "../utils/convertActivityReport";
import { handleConfirmCreateActivityReport } from "../utils/handleCreateActivityReport";
import handleEditActivityReport from "../utils/handleEditActivityReport";
import resolveDefaultValuesMEInActiveReport from "../utils/resolveDefaultValuesMEInActivityReport";
import ActivityReportDialogInnerContent from "./ActivityReportDialogInnerContent";
import ActivityReportReviewedDialogInnerContent from "./ActivityReportReviewedDialogInnerContent";
import ActivityReportShareButton from "./ActivityReportShareButton";
import ActivityReportShareLink from "./ActivityReportShareLink";

interface IActivityReportDialogOuterContent {
  initViewMode?: TViewMode;
  viewMode: TViewMode;
  setViewMode: (viewMode: TViewMode) => void;
  report: IActivityReportInTable;
  open?: boolean;
  workflow: IWorkflow;
  onPrev?: () => void;
  onNext?: () => void;
  onClose: () => void;
  onDelete?: (report: IActivityReport) => void;
  onSave?: (report: IActivityReport) => void;
  activity: IActivity;
  stepId?: string;
  stepReviewed?: IWorkflowStep;
  disableEdition?: boolean;
}

/**
 * The purpose of this component is to be able to add a key={report._id} on it
 * (and not on the ActivityReportDialog which would cause the dialog component to blink on prev/next arrows)
 */
const ActivityReportDialogOuterContent = ({
  initViewMode,
  viewMode,
  setViewMode,
  report,
  onPrev,
  onNext,
  onClose,
  onSave = () => {},
  onDelete,
  activity,
  workflow,
  stepId,
  stepReviewed,
  disableEdition,
}: IActivityReportDialogOuterContent) => {
  const lang = useTranslations();
  const logger = useContext(LoggerContext);
  const [formQuestions, setFormQuestions] = useState<IQuestion[] | undefined>(
    activity?.questionnaire?.questions
  );
  const [loading, setLoading] = useState(false);

  const isMetaComputing = useSelector(getIsComputingMetaExpression);
  const [fetchWorkflowReport] = useActions([fetchWorkflowReportAction]);
  const activities = useSelector(getAllActivities);
  const signedInUser = useSelector(getSignedInUser);
  const mapMetaExpressionSolved = useSelector(allMetaExpressionsSolvedSelector);

  const workflowReport = useSelector((state: IRootState) => {
    return workflowReportByIdSelector(state, report._workflow_report_id);
  });

  const { stepId: nextStepId } = getNextStepActivity(
    workflowReport ??
      ({
        step_overview: [
          {
            step_status: STEP_STATUS.DONE,
            step_id: stepId,
          },
        ],
      } as IWorkflowReportWithStepOverview),
    workflow,
    activities
  );

  const nextStepScope = workflow.steps.find((s) => s.id === nextStepId)?.scope;

  const formState = useFormState<IActivityReportInTable>({
    initAttributes: report,
    validate: ({ attributes }) =>
      getErrorMessagesForActivityReport({
        attributes,
        additionnalProps: {
          workflow,
          activity,
          formQuestions,
          nextStepScope,
        },
        lang,
      }),
    validateDependencies: [formQuestions],
  });

  const { attributes: reportState, filesToUpload, resetChanges } = formState;

  // TODO: use useActions and fix type issues related to updateWorkflowReport, updateActivityReport, ...
  const [
    updateWorkflowReport,
    updateActivityReport,
    uploadActivityReportFile,
    createWorkflowReport,
    createActivityReport,
  ] = useActionsLegacy([
    updateWorkflowReportAction,
    updateActivityReportAction,
    uploadActivityReportOrWorkflowFileAction,
    createWorkflowReportAction,
    createActivityReportAction,
  ]);

  function updateFormQuestions() {
    let formQuestions = activity?.questionnaire?.questions;

    if (viewMode != "VIEW") {
      formQuestions = filterQuestionsOnSolvedConditions(
        _.cloneDeep(activity?.questionnaire?.questions),
        mapMetaExpressionSolved
      );
    }

    setFormQuestions(formQuestions);
  }

  useEffect(() => {
    if (viewMode === "VIEW") {
      return;
    }

    if (!workflow && !activity) {
      return;
    }

    if (!workflowReport) {
      setLoading(true);

      fetchWorkflowReport(workflow.id, report._workflow_report_id)
        .catch((e) => {
          logger.logError(e);
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    report._id,
    report._workflow_report_id,
    viewMode,
    workflow?.id,
    activity?.id,
    workflowReport?.id,
    // one of these is causing a render loop
    // fetchWorkflowReport,
    // setLoading,
    // logger,
  ]);

  useEffect(() => {
    updateFormQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMetaExpressionSolved, viewMode]);

  const handleCancel = () => {
    resetChanges();

    if (initViewMode === "VIEW") {
      setViewMode("VIEW");
    } else {
      onClose();
    }
  };

  const handleSave = async () => {
    if (!_.isEmpty(formState.errors)) {
      // eslint-disable-next-line no-console
      console.error("The form contains errors:", formState.errors);
    }

    const mobileReport = convertActivityReport({
      questionsSchema: activity?.questionnaire?.questions,
      report: resolveDefaultValuesMEInActiveReport({
        questionsSchema: activity?.questionnaire?.questions,
        report: reportState,
        activity,
      }),
    }) as IActivityReportInTable;

    if (viewMode === "EDIT") {
      handleEditActivityReport({
        workflow,
        workflowReport,
        signedInUser,
        lang,
        activity,
        reportState,
        filesToUpload,
        updateWorkflowReport,
        updateActivityReport,
        uploadActivityReportFile,
      });
      onSave(mobileReport as any);
    } else if (viewMode === "CREATE") {
      handleConfirmCreateActivityReport({
        activities: activities,
        filesToUpload,
        reportInTable: reportState,
        mobileReport,
        createActivityReport,
        createWorkflowReport,
        selectedActivity: activity,
        selectedWorkflow: workflow,
        signedInUser,
        updateWorkflowReport,
        uploadActivityReportFile,
        selectedWorkflowReport: workflowReport,
        stepId,
      });

      onSave(mobileReport as any);
    }

    if (initViewMode === "VIEW") {
      setViewMode("VIEW");
    } else {
      onClose();
    }
  };

  const displayedName = report.displayed_name || report._displayed_name;

  const isReview = isReviewActivity(activity.type);

  return (
    <>
      <BasicDialogTitle>
        <Box style={{ display: "flex", flexDirection: "row", float: "left" }}>
          {viewMode === "VIEW" && (
            <Box marginRight="24px">
              <BasicArrowIcon
                filled
                direction="up"
                style={{ marginRight: "20px" }}
                disabled={!onPrev}
                onClick={onPrev}
              />

              <BasicArrowIcon
                filled
                direction="down"
                disabled={!onNext}
                onClick={onNext}
              />
            </Box>
          )}

          {displayedName && (
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                maxWidth: "500px",
              }}
              noWrap
            >
              {displayedName}
            </Typography>
          )}
        </Box>

        <Box
          style={{
            float: "right",
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          <ActivityReportShareLink
            activityId={activity?.id}
            reportId={report?._id}
          />
          <ActivityReportShareButton
            activityId={activity?.id}
            reportId={report?._id}
          />

          {!disableEdition && viewMode === "VIEW" && (
            <EditActivityReportButton onClick={() => setViewMode("EDIT")} />
          )}

          {onDelete && <BasicDeleteIcon onClick={onDelete} />}

          <BasicCloseIcon onClick={onClose} />
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent noOverflow>
        {!isReview ? (
          <ActivityReportDialogInnerContent
            workflow={workflow}
            activity={activity}
            viewMode={viewMode}
            formState={formState}
            nextStepScope={nextStepScope}
            stepId={stepId}
            formQuestions={formQuestions || []}
          />
        ) : (
          <ActivityReportReviewedDialogInnerContent
            viewMode={viewMode}
            workflow={workflow}
            currentReport={report}
            stepReviewed={stepReviewed}
            currentActivity={activity}
            formState={formState}
          />
        )}
      </BasicDialogContent>

      {viewMode === "EDIT" || viewMode === "CREATE" ? (
        <BasicDialogActions>
          <Box style={{ float: "right" }}>
            <Grid container spacing={2}>
              <Grid item>
                <CustomButton
                  onClick={handleCancel}
                  type={BUTTON_TYPES_OUTSIDE_TABLE.CANCEL}
                >
                  {lang.modal.cancel}
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  onClick={handleSave}
                  disabled={
                    loading || isMetaComputing || !_.isEmpty(formState.errors)
                  }
                  type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
                >
                  {loading || isMetaComputing ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="2px 19px"
                    >
                      <CircularProgress size={22} />
                    </Box>
                  ) : (
                    lang.modal.confirm
                  )}
                </CustomButton>
              </Grid>
            </Grid>
          </Box>
        </BasicDialogActions>
      ) : null}
    </>
  );
};

export default ActivityReportDialogOuterContent;
