import { IDispatchAndGetState } from "redux/store/model";

import { changeSubcategorySuccessActionCreator } from "../actionCreator/userActionCreator";

export type IChangeSubcategoryActionFunc = (
  subcategory: "mobileuser" | "webuser"
) => IDispatchAndGetState<void>;

export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeSubcategorySuccessActionCreator(subcategory))
    );
  };
};

export interface IUserActions {
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
}
