export const CREATE_EVENTS_BEGIN = "CREATE_EVENTS_BEGIN";
export const CREATE_EVENTS_FAILURE = "CREATE_EVENTS_FAILURE";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";

export const FETCH_EVENTS_BEGIN = "FETCH_EVENTS_BEGIN";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const FETCH_EVENTS_SCHEMA_BEGIN = "FETCH_EVENTS_SCHEMA_BEGIN";
export const FETCH_EVENTS_SCHEMA_SUCCESS = "FETCH_EVENTS_SCHEMA_SUCCESS";
export const FETCH_EVENTS_SCHEMA_FAILURE = "FETCH_EVENTS_SCHEMA_FAILURE";

export const DELETE_EVENTS_BEGIN = "DELETE_EVENTS_BEGIN";
export const DELETE_EVENTS_SUCCESS = "DELETE_EVENTS_SUCCESS";
export const DELETE_EVENTS_FAILURE = "DELETE_EVENTS_FAILURE";

export const UPDATE_EVENTS_BEGIN = "UPDATE_EVENTS_BEGIN";
export const UPDATE_EVENTS_SUCCESS = "UPDATE_EVENTS_SUCCESS";
export const UPDATE_EVENTS_FAILURE = "UPDATE_EVENTS_FAILURE";

export const UPDATE_SELECTED_FIELD_USER = "UPDATE_SELECTED_FIELD_USER";
