import React from "react";

import "filepond/dist/filepond.min.css";

import { Box, Button, DialogContentText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FilePond } from "react-filepond";

import TLang, { TInputAttributeLang } from "model/application/Lang";

import CustomTooltip from "../Tooltip/CustomTooltip";
import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "./BasicDialog";
import BasicDownloadIcon from "./BasicDialog/Icons/BasicDownloadIcon";
import BasicUploadIcon from "./BasicDialog/Icons/BasicUploadIcon";
import CustomDialogWarning from "./CustomDialogWarning";
import styles from "./styles";

export type TSize = false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;

export interface ICustomDialogFormProps {
  children?: any;
  title: string;
  langlabel?: { [att: string]: TInputAttributeLang };
  description?: string;
  actionsDescription?: string;
  isOpen?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirmAction?: () => void;
  OnRunKpiQueryAction?: () => void;
  confirmBtnText?: string;
  runKpiQueryText?: string;
  isDisabled?: boolean;
  isRunQueryDisabled?: boolean;
  alignTop?: boolean;
  size: TSize;
  onDownloadWIP?: () => void;
  onUploadWIP?: (file: any) => void;
  lang: TLang;
  hideCancelButton?: boolean;
}

interface ICustomDialogFormState {
  isUploadOpen: boolean;
  isWarningModalOpen: boolean;
}

/**
 * Component for displaying a dialog with a form (for add/edit objects)
 */
export class CustomDialogForm extends React.Component<
  ICustomDialogFormProps,
  ICustomDialogFormState
> {
  public static defaultProps = {
    size: "md" as TSize,
    saveWorkInProgress: false,
    onCancel: () => true,
    isOpen: false,
    isDisabled: false,
    confirmBtnText: "Confirm",
    runKpiQueryText: "Run",
  };

  constructor(props: ICustomDialogFormProps) {
    super(props);
    this.state = {
      isUploadOpen: false,
      isWarningModalOpen: false,
    };
  }

  handleCancel = () => {
    const { onClose, onCancel } = this.props;
    if (onCancel) onCancel();
    onClose();
  };

  handleConfirmAction = () => {
    const { onConfirmAction, langlabel } = this.props;
    if (langlabel?.warningOnConfirm) {
      this.setState({
        isWarningModalOpen: true,
      });
    } else if (onConfirmAction) {
      onConfirmAction();
    }
  };

  handleTestKpiAction = () => {
    const { OnRunKpiQueryAction } = this.props;
    this.setState({
      isWarningModalOpen: false,
    });
    if (OnRunKpiQueryAction) OnRunKpiQueryAction();
  };

  handleConfirmAfterWarning = () => {
    const { onConfirmAction } = this.props;
    this.setState({
      isWarningModalOpen: false,
    });
    if (onConfirmAction) onConfirmAction();
  };

  render() {
    const {
      title,
      isOpen,
      onClose,
      onConfirmAction,
      OnRunKpiQueryAction,
      confirmBtnText,
      runKpiQueryText,
      description,
      actionsDescription,
      children,
      isDisabled,
      isRunQueryDisabled,
      alignTop,
      onDownloadWIP,
      onUploadWIP,
      size,
      langlabel,
      lang,
      hideCancelButton,
    } = this.props;
    const { isUploadOpen, isWarningModalOpen } = this.state;

    return (
      <div>
        <BasicDialog
          style={{ paddingLeft: "60px" }}
          fullWidth
          fullScreen={size === "xl"}
          open={!!isOpen}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
          size={size}
          alignTop={alignTop}
        >
          <BasicDialogTitle id="form-dialog-title">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "10px",
              }}
            >
              <span style={{ float: "left" }}>{title}</span>

              <Box style={{ float: "right" }}>
                {onDownloadWIP ? (
                  <CustomTooltip title={lang.modal.downloadWIP}>
                    <BasicDownloadIcon onClick={onDownloadWIP} filled={false} />
                  </CustomTooltip>
                ) : null}
                {onUploadWIP ? (
                  <CustomTooltip title={lang.modal.uploadWIP}>
                    <BasicUploadIcon
                      onClick={() => this.setState({ isUploadOpen: true })}
                    />
                  </CustomTooltip>
                ) : null}

                {!hideCancelButton ? (
                  <BasicCloseIcon onClick={onClose} />
                ) : null}
              </Box>
            </Box>
          </BasicDialogTitle>
          <BasicDialogContent>
            {description && (
              <DialogContentText>{description}</DialogContentText>
            )}
            {children}
          </BasicDialogContent>

          <BasicDialogActions
            style={size === "xl" ? { justifyContent: "flex-start" } : {}}
          >
            <span style={{ textAlign: "left", flexGrow: 1 }}>
              {actionsDescription}
            </span>

            {!hideCancelButton && (
              <Button
                onClick={this.handleCancel}
                variant="outlined"
                color="secondary"
                disableElevation
              >
                {lang.modal.cancel}
              </Button>
            )}

            {OnRunKpiQueryAction ? (
              <Button
                onClick={this.handleTestKpiAction}
                disabled={isRunQueryDisabled}
                color="secondary"
                variant="contained"
                disableElevation
                style={{ padding: "8px, 16px, 8px, 16px ", fontWeight: "700" }}
              >
                {runKpiQueryText}
              </Button>
            ) : null}
            {onConfirmAction ? (
              <Button
                data-testid="custom-dialog-form-confirm"
                onClick={this.handleConfirmAction}
                disabled={isDisabled}
                color="secondary"
                variant="contained"
                disableElevation
                style={{ padding: "8px, 16px, 8px, 16px ", fontWeight: "700" }}
              >
                {confirmBtnText}
              </Button>
            ) : null}
          </BasicDialogActions>

          <CustomDialogForm
            isOpen={isUploadOpen}
            title={lang.modal.loadWIPModalTitle}
            onConfirmAction={() => true}
            onClose={() => this.setState({ isUploadOpen: false })}
            confirmBtnText={lang.modal.upload}
            lang={lang}
          >
            <FilePond //ref={ref => this.pond = ref}
              allowMultiple={false}
              labelIdle={lang.modal.loadWIPModalDescription}
              onupdatefiles={(fileItems) => {
                if (fileItems[0]) {
                  this.handleFileInput(fileItems[0].file);
                }
              }}
            ></FilePond>
          </CustomDialogForm>
        </BasicDialog>

        {langlabel?.warningOnConfirm ? (
          <CustomDialogWarning
            isOpen={isWarningModalOpen}
            onClose={() => this.setState({ isWarningModalOpen: false })}
            onConfirmAction={this.handleConfirmAfterWarning}
            lang={{
              title: langlabel.warningOnConfirm.title,
              description: langlabel.warningOnConfirm.description,
            }}
            rootLang={lang}
          />
        ) : null}
      </div>
    );
  }

  handleFileInput = (resumeWorkFile: any) => {
    const { onUploadWIP } = this.props;
    // create blob url
    const blobUrl = window.URL.createObjectURL(resumeWorkFile);
    fetch(blobUrl)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        if (onUploadWIP) onUploadWIP(JSON.parse(data));
        this.setState({ isUploadOpen: false });
      })
      .catch(() => this.setState({ isUploadOpen: false }));
  };
}

export default withStyles(styles as any)(CustomDialogForm);
