/**
 * UI Action types
 */
export enum ACTION_TYPE {
  CREATE = "CREATE",
  DELETE = "DELETE",
  ARCHIVE = "ARCHIVE",
  RESTORE = "RESTORE",
  CONTINUE = "CONTINUE",
  RESTORE_VERSION = "RESTORE_VERSION",
  EDIT = "EDIT",
  LICENSE = "LICENSE",
  UNLICENSE = "UNLICENSE",
  DETAIL = "DETAIL",
  CREATE_MULTIPLE = "CREATE_MULTIPLE",
  DELETE_MULTIPLE = "DELETE_MULTIPLE",
  ARCHIVE_MULTIPLE = "ARCHIVE_MULTIPLE",
  RESTORE_MULTIPLE = "RESTORE_MULTIPLE",
  EDIT_MULTIPLE = "EDIT_MULTIPLE",
  DOWNLOAD = "DOWNLOAD",
  RUN = "RUN",
  SAVE = "SAVE",
  FILTER = "FILTER",
  COLUMN_VISIBILITY = "COLUMN_VISIBILITY",
  REFRESH = "REFRESH",
  HELP = "HELP",
  OTHER = "OTHER",
  HIDDEN = "HIDDEN",
  DUPLICATE = "DUPLICATE",
  NOTIFICATION = "NOTIFICATION",
  CLOSE = "CLOSE",
  EXTRA_ACTION = "EXTRA_ACTION",
  EXPAND = "EXPAND",
  COLLAPSE = "COLLAPSE",
  STRIPE_PORTAL = "STRIPE_PORTAL",
  STOP = "STOP",
  RESUME = "RESUME",
  REPLICATE = "REPLICATE",
  CHANGE_TAG = "CHANGE_TAG",
  UPLOAD = "UPLOAD",
  UNASSIGN = "UNASSIGN",
  CUSTOM = "CUSTOM",
}
