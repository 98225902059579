import { useLocation } from "react-router-dom";

// A custom hook that returns an object of search params
function useSearchParams() {
  // Get the location object from useLocation hook
  const location = useLocation();

  // Create a URLSearchParams object from the location.search string
  const searchParams = new URLSearchParams(location.search);

  // Convert the searchParams object to a plain object
  const params = {};
  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  // Return the params object
  return [params];
}
export default useSearchParams;
