// TERRITORY

// select list
export const SELECT_TERRITORY = "SELECT_TERRITORY";

// insert items action types for an object in a list
export const CREATE_TERRITORIES_BEGIN = "CREATE_TERRITORIES_BEGIN";
export const CREATE_TERRITORIES_FAILURE = "CREATE_TERRITORIES_FAILURE";
export const CREATE_TERRITORIES_SUCCESS = "CREATE_TERRITORIES_SUCCESS";
export const RESTORE_MODAL = "RESTORE_MODAL";

// action types for fetching objects for a given list
export const FETCH_TERRITORIES_BEGIN = "FETCH_TERRITORIES_BEGIN";
export const FETCH_TERRITORIES_SUCCESS = "FETCH_TERRITORIES_SUCCESS";
export const FETCH_TERRITORIES_FAILURE = "FETCH_TERRITORIES_FAILURE";

// action types for fetching objects for a given list
export const FETCH_TERRITORIES_SCHEMA_BEGIN = "FETCH_TERRITORIES_SCHEMA_BEGIN";
export const FETCH_TERRITORIES_SCHEMA_SUCCESS =
  "FETCH_TERRITORIES_SCHEMA_SUCCESS";
export const FETCH_TERRITORIES_SCHEMA_FAILURE =
  "FETCH_TERRITORIES_SCHEMA_FAILURE";

// delete action types for objects from a list
export const DELETE_TERRITORIES_BEGIN = "DELETE_TERRITORIES_BEGIN";
export const DELETE_TERRITORIES_SUCCESS = "DELETE_TERRITORIES_SUCCESS";
export const DELETE_TERRITORIES_FAILURE = "DELETE_TERRITORIES_FAILURE";

// archive action types for objects from a list
export const ARCHIVE_TERRITORIES_BEGIN = "ARCHIVE_TERRITORIES_BEGIN";
export const ARCHIVE_TERRITORIES_SUCCESS = "ARCHIVE_TERRITORIES_SUCCESS";
export const ARCHIVE_TERRITORIES_FAILURE = "ARCHIVE_TERRITORIES_FAILURE";

// restore action types for objects from a list
export const RESTORE_TERRITORIES_BEGIN = "RESTORE_TERRITORIES_BEGIN";
export const RESTORE_TERRITORIES_SUCCESS = "RESTORE_TERRITORIES_SUCCESS";
export const RESTORE_TERRITORIES_FAILURE = "RESTORE_TERRITORIES_FAILURE";

// update action types for object from a list
export const UPDATE_TERRITORIES_BEGIN = "UPDATE_TERRITORIES_BEGIN";
export const UPDATE_TERRITORIES_SUCCESS = "UPDATE_TERRITORIES_SUCCESS";
export const UPDATE_TERRITORIES_FAILURE = "UPDATE_TERRITORIES_FAILURE";

// update action types for object from a list
export const UPDATE_TERRITORIES_SCHEMA_BEGIN =
  "UPDATE_TERRITORIES_SCHEMA_BEGIN";
export const UPDATE_TERRITORIES_SCHEMA_SUCCESS =
  "UPDATE_TERRITORIES_SCHEMA_SUCCESS";
export const UPDATE_TERRITORIES_SCHEMA_FAILURE =
  "UPDATE_TERRITORIES_SCHEMA_FAILURE";

// assign items to owners
export const ASSIGN_TERRITORIES_BEGIN = "ASSIGN_TERRITORIES_BEGIN";
export const ASSIGN_TERRITORIES_SUCCESS = "ASSIGN_TERRITORIES_SUCCESS";
export const ASSIGN_TERRITORIES_FAILURE = "ASSIGN_TERRITORIES_FAILURE";

// unaassign items to owners
export const UNASSIGN_TERRITORIES_BEGIN = "UNASSIGN_TERRITORIES_BEGIN";
export const UNASSIGN_TERRITORIES_SUCCESS = "UNASSIGN_TERRITORIES_SUCCESS";
export const UNASSIGN_TERRITORIES_FAILURE = "UNASSIGN_TERRITORIES_FAILURE";

export const SET_FILTER_QUERY = "SET_FILTER_QUERY";
