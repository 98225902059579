import { ILogDetail } from "fieldpro-tools";
import _ from "lodash";
import { LOG_USER_KEYS } from "log/user";

import { SENTRY_TAG_KEYS } from "./tags";

export const getSentryExtras = (logDetail?: ILogDetail) => {
  const flattenedLogDetail = {
    ..._.omit(logDetail, "data"),
    ...logDetail?.data,
  };
  return _.omit(
    flattenedLogDetail,
    // Custom keys we chose as Sentry tags
    SENTRY_TAG_KEYS,
    // Keys from ILogDetail that we pass to Sentry via the Sentry.User object (see getSentryUser helper)
    LOG_USER_KEYS
  );
};
