import {
  CircularProgress,
  CircularProgressProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { secondaryColor } from "assets/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    top: {
      color: secondaryColor,
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

function CustomSpinner(props: CircularProgressProps) {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="circular-loader">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}
export default CustomSpinner;
