import _ from "lodash";

const safeStringify = (value?: unknown) => {
  if (_.isNil(value)) {
    return "";
  }

  if (_.isString(value)) {
    return value;
  }

  try {
    // JSON.stringify(undefined) -> undefined
    return JSON.stringify(value) ?? "";
  } catch (e) {
    console.error(
      `Unable to stringify value (type: ${typeof value}): '${value}'`
    );
    return "";
  }
};

export default safeStringify;
