import { CUSTOM_FIELD_TYPE, META_EXPRESSION_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { IOption } from "model/application/components";

import { FieldTypes, getFieldsByType } from "./fieldOptions";

// See docs for the list of available MEs
// https://optimetriks.atlassian.net/wiki/spaces/TG/pages/599720619/MetaExpression
const getFieldTagOptions = (
  scope?: META_EXPRESSION_SCOPE,
  subscope?: META_EXPRESSION_SCOPE,
  mobileUserRoles?: IOption[],
  type?: CUSTOM_FIELD_TYPE
) => {
  if (subscope) {
    switch (scope) {
      case META_EXPRESSION_SCOPE.ACTIVITY_REPORT: {
        return _.map(
          getFieldsByType(FieldTypes.ACTIVITY_REPORT_FIELDS, type),
          (fieldId) => ({
            key: fieldId,
            label: fieldId,
          })
        );
      }
      default: {
        return _.map(
          getFieldsByType(FieldTypes.JOB_FIELDS, type),
          (fieldId) => ({
            key: fieldId,
            label: fieldId,
          })
        );
      }
    }
  }
  switch (scope) {
    case META_EXPRESSION_SCOPE.CLIENT:
      return _.map(
        getFieldsByType(FieldTypes.CLIENT_FIELDS, type),
        (fieldId) => ({
          key: fieldId,
          label: fieldId,
        })
      );
    case META_EXPRESSION_SCOPE.USER:
      return _.map(
        getFieldsByType(FieldTypes.USER_FIELDS, type),
        (fieldId) => ({
          key: fieldId,
          label: fieldId,
        })
      ).filter((o) => {
        if (
          mobileUserRoles &&
          mobileUserRoles.length === 0 &&
          o.key === "_role"
        ) {
          return false;
        }
        return true;
      });

    case META_EXPRESSION_SCOPE.CUSTOMER:
    case META_EXPRESSION_SCOPE.ITEM:
    case META_EXPRESSION_SCOPE.ITEM_CELL:
      return _.map(
        getFieldsByType(FieldTypes.ITEM_FIELDS, type),
        (fieldId) => ({
          key: fieldId,
          label: fieldId,
        })
      );
    case META_EXPRESSION_SCOPE.ACTIVITY_REPORT:
      return _.map(
        getFieldsByType(FieldTypes.ACTIVITY_REPORT_FIELDS, type),
        (fieldId) => ({
          key: fieldId,
          label: fieldId,
        })
      );
    case META_EXPRESSION_SCOPE.WORKFLOW_REPORT:
      return _.map(
        getFieldsByType(FieldTypes.WORKFLOW_REPORT_FIELDS, type),
        (fieldId) => ({
          key: fieldId,
          label: fieldId,
        })
      );
    default:
      return [];
  }
};

export default getFieldTagOptions;
