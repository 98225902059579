import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

import useTranslations from "hooks/useTranslations";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "../Buttons/CustomButton";

const useStyles = makeStyles({
  FallbackScreen: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ErrorIcon: {
    width: 80,
    height: 80,
    color: "#ff5722",
    marginTop: 30,
  },
  ErrorMessage: {
    textAlign: "center",
    marginTop: 36,
  },
  ReloadButtonContainer: {
    marginTop: 48,
    marginBottom: 36,
  },
});

/**
 * @notes: Error Boundary Fallback UI
 * Refer to https://reactjs.org/docs/error-boundaries.html
 */
const FallbackScreen = () => {
  // An appropriate error message
  // render a custom fallback UI
  const classes = useStyles();
  const lang = useTranslations();
  return (
    <div className={classes.FallbackScreen}>
      <ErrorIcon className={classes.ErrorIcon} />

      <h2 className={classes.ErrorMessage}>
        {lang.components.errors.somethingWrong}
        <br />
        {lang.components.errors.tryAgain}
      </h2>

      <div className={classes.ReloadButtonContainer}>
        <CustomButton
          type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
          onClick={() => {
            window.location.reload();
          }}
        >
          {lang.actions.reload}
        </CustomButton>
      </div>
    </div>
  );
};

export default FallbackScreen;
