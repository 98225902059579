import { OBJECT_CATEGORY } from "fieldpro-tools";
import { useSelector } from "react-redux";

import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { teamsSelector } from "containers/teams/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allWebUsersSelector } from "containers/users/redux/selectors/webUsersSelector";
import { allWorkflowsSelector } from "containers/workflows/redux/selectors";
import { IOption } from "model/application/components";
import { IDashboard } from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import ITeam from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";
import { IWorkflow } from "model/entities/Workflow";

import { splitAndSortClientDashboards } from "../../DashboardTab";

export type ISelectorResults = {
  mobileUsers: IMobileUser[];
  webUsers: IWebUser[];
  teams: ITeam[];
  workflows: IWorkflow[];
  lists: IList[];
  dashboards: IDashboard[];
};

const useOptionsOfCategory = (category: OBJECT_CATEGORY) => {
  const mobileUsers: IMobileUser[] = useSelector(allMobileUsersSelector);
  const webUsers: IWebUser[] = useSelector(allWebUsersSelector);
  const teams: ITeam[] = useSelector(teamsSelector);
  const workflows: IWorkflow[] = useSelector(allWorkflowsSelector);
  // const reports = useSelector(allWorkflowReportsSelector);
  const lists: IList[] = useSelector(allListsSelector);
  // const items = useSelector() // not sure what to use here
  const dashboards: IDashboard[] = useSelector(dashboardsComposedSelector);
  // const documents = useSelector()
  // const territories = useSelector(selector)
  // const allCalendarEvents
  // const allClientSettings

  const selectorResults: ISelectorResults = {
    mobileUsers,
    webUsers,
    teams,
    workflows,
    lists,
    dashboards,
  };
  const options = getOptionsOfCategory(category, selectorResults);
  return { options };
};

export const getOptionsOfCategory = (
  category: OBJECT_CATEGORY,
  selectorResults: ISelectorResults
): IOption<string>[] => {
  switch (category) {
    case OBJECT_CATEGORY.MOB_USER:
      const mobileUsers = selectorResults.mobileUsers ?? [];
      const mobileUserOptions = mobileUsers.map((mobileUser) => {
        return {
          key: mobileUser.id,
          label: `${mobileUser.first_name} ${mobileUser.last_name}`,
          value: mobileUser.id,
        };
      });
      return mobileUserOptions;
    case OBJECT_CATEGORY.WEB_USER:
      const webUsers = selectorResults.webUsers ?? [];
      const webUserOptions = webUsers.map((mobileUser) => {
        return {
          key: mobileUser.id,
          label: `${mobileUser.first_name} ${mobileUser.last_name}`,
          value: mobileUser.id,
        };
      });
      return webUserOptions;
    case OBJECT_CATEGORY.TEAM:
      const teams = selectorResults.teams ?? [];
      const teamOptions = teams.map((team) => {
        return {
          key: team.id,
          label: team.name,
          value: team.id,
        };
      });
      return teamOptions;
    case OBJECT_CATEGORY.WORKFLOW:
      const workflows = selectorResults.workflows ?? [];
      const workflowOptions = workflows.map((workflow) => {
        return {
          key: workflow.id,
          label: workflow.name,
          value: workflow.id,
        };
      });
      return workflowOptions;
    case OBJECT_CATEGORY.REPORT:
      // const workflowReportOptions = reports
      //   .map((workflowReport) => {
      //     return {
      //       key: workflowReport.id,
      //       label: workflowReport.name,
      //       value: workflowReport.id,
      //     };
      //   });
      // return workflowReportOptions;
      return [];
    case OBJECT_CATEGORY.LIST:
      const lists = selectorResults.lists ?? [];
      const listOptions = lists.map((list) => {
        return {
          key: list.id,
          label: list.name,
          value: list.id,
        };
      });
      return listOptions;
    case OBJECT_CATEGORY.ITEM:
      return [];
    case OBJECT_CATEGORY.DASHBOARD:
      const dashboards = selectorResults.dashboards ?? [];
      const allDashboards = splitAndSortClientDashboards(dashboards);
      const dashboardOptions = allDashboards
        .filter((d) => d.active)
        .map((dashboard) => {
          return {
            key: dashboard.id,
            label: dashboard.title ?? dashboard.name,
            value: dashboard.id,
          };
        });
      return dashboardOptions;
    case OBJECT_CATEGORY.DOCUMENT:
      return [];
    case OBJECT_CATEGORY.TERRITORY:
      return [];
    case OBJECT_CATEGORY.CALENDAR_EVENT:
      return [];
    case OBJECT_CATEGORY.CLIENT_SETTINGS:
      return [];
    default:
      return [];
  }
};

export default useOptionsOfCategory;
