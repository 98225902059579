import React from "react";

import { MOBILE_LOGIN_MODE } from "fieldpro-tools";
import _ from "lodash";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputText from "components/Input/InputText/InputText";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { IWebUser } from "model/entities/User";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

import InputAccessRightProfiles from "./InputAccessRightProfiles";
import InputMobileUserRoles from "./InputMobileUserRoles";
import InputWebUserRoles from "./InputWebUserRoles";

export interface IUsersTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onSetClientLive: any;
  selectedClientWebUsers: IWebUser[];
  onInputChange: any;
  onChangeProfiles: any;
  onChangeMobileRoles: any;
  onChangeWebRoles: any;
  onSelectChange: any;
  shouldMarkError: any;
  handleChangeMobileLoginModes: (value: IOption<MOBILE_LOGIN_MODE>[]) => void;
}

export const UsersTab: React.FunctionComponent<IUsersTabProps> = ({
  viewMode = "CREATE",
  access_right_profiles,
  mobile_user_business_roles,
  web_user_business_roles,
  unique_log_in,
  phone_regex,
  daily_status_query,
  magic_number,
  selectedClientWebUsers,
  onInputChange,
  onChangeProfiles,
  onSelectChange,
  onChangeMobileRoles,
  onChangeWebRoles,
  shouldMarkError,
  mobile_login_modes,
  handleChangeMobileLoginModes,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputAccessRightProfiles
          viewMode={viewMode}
          profiles={access_right_profiles}
          selectedClientWebUsers={selectedClientWebUsers}
          onChangeProfiles={onChangeProfiles}
        />
      </FormFieldBackground>

      <InputMobileUserRoles
        viewMode={viewMode}
        roles={mobile_user_business_roles ?? []}
        onChangeMobileRoles={onChangeMobileRoles}
      />

      <InputWebUserRoles
        viewMode={viewMode}
        roles={web_user_business_roles ?? []}
        onChangeWebRoles={onChangeWebRoles}
      />

      <InputBoolean
        viewMode={viewMode}
        defaultValue={unique_log_in}
        lang={label.inputUniqueLogin}
        name={"unique_log_in"}
        onChange={onInputChange}
      />

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={phone_regex}
          name="phone_regex"
          lang={label.inputPhoneRegex}
          onChange={onInputChange}
          aria-label="PhoneRegexInputText"
          required={false}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={daily_status_query}
          name="daily_status_query"
          lang={label.inputDailyStatusQuery}
          onChange={onInputChange}
          aria-label="DailyStatusInputText"
          required={false}
        />
      </FormFieldBackground>

      <InputText
        viewMode={viewMode}
        defaultValue={magic_number}
        name="magic_number"
        error={shouldMarkError("magic_number")}
        lang={label.inputMagicNumber}
        onChange={onSelectChange}
        required={false}
      />
      <InputMultipleSelect
        viewMode={viewMode}
        name={"mobile_login_modes"}
        defaultValue={_.map(mobile_login_modes ?? [], (j) => ({
          key: j,
          label: j,
        }))}
        options={getOptionsInputSelect({
          listKeys: Object.keys(MOBILE_LOGIN_MODE),
          lang: label.inputMobileLoginModes.options,
        })}
        onChange={
          handleChangeMobileLoginModes as (
            optSelected: IOption<string>[],
            name: string,
            uncheckOptions?: IOption<string>[],
            undeterminedOptions?: IOption<string>[]
          ) => void
        }
        error={shouldMarkError("mobile_login_modes")}
        multipleSelection={true}
        langlabel={label.inputMobileLoginModes}
        lang={lang}
        required={true}
      />
    </>
  );
};

export default UsersTab;
