import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import classNames from "classnames";

import { GreyDark, OffWhiteLight } from "assets/colors";

interface IFormSubSection {
  title?: string;
  isCollapsible?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  FormSubSection: {
    "&::before": {
      content: "none",
    },
    "&.default": {
      marginBottom: "32px",
    },
    "&.accordion": {
      boxShadow: "none",
      margin: "0 -16px 16px",
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: OffWhiteLight,
    },
  },
  title: {
    color: GreyDark,
    fontWeight: 600,
    // fontSize: "20px",
    // lineHeight: "24px",
    fontSize: "20px",
    lineHeight: "24px",
    margin: 0,
    "&.default": {
      marginTop: "8px",
      marginBottom: "16px",
    },
  },
});

const FormSubSection = ({
  title,
  isCollapsible = false,
  children,
}: IFormSubSection) => {
  const classes = useStyles();

  if (!isCollapsible) {
    return (
      <div className={classNames(classes.FormSubSection, "default")}>
        {title && (
          <h4 className={classNames(classes.title, "default")}>{title}</h4>
        )}
        {children}
      </div>
    );
  }
  return (
    <Accordion className={classNames(classes.FormSubSection, "accordion")}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h4 className={classes.title}>{title}</h4>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FormSubSection;
