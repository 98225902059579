import React, { useEffect, useRef } from "react";

import PlaceIcon from "@material-ui/icons/Place";
import {
  Map,
  MapRef,
  Marker,
  MarkerDragEvent,
  NavigationControl,
} from "react-map-gl";
import { useSelector } from "react-redux";

import { getSelectedClient } from "containers/clients/redux/selectors";
import { areFiniteCoordinates } from "utils/geojson/coordinates";

import { getMapRegion } from "./getMapRegion";

export type TMapGLProps = React.ComponentProps<typeof Map>;
export type TMarkerProps = React.ComponentProps<typeof Marker>;
export type TOnDragEndFunction = NonNullable<TMarkerProps["onDragEnd"]>;

export type TViewPort = TMapGLProps;
export interface ILngLat {
  lng: number;
  lat: number;
  acc?: number; // not needed here
}

export interface IMapWithMarker extends TMapGLProps {
  markerPosition: ILngLat | undefined;
  onMarkerDragEnd?: (coordinates: ILngLat) => void;
}

const INIT_COORDS = { lat: 12, lng: 12 };

function MapWithMarker({
  markerPosition = INIT_COORDS,
  onMarkerDragEnd,
}: IMapWithMarker) {
  const mapRef = useRef<MapRef | null>(null);
  const client = useSelector(getSelectedClient);
  const initialViewState = {
    zoom: markerPosition === INIT_COORDS ? 2 : 8,
    longitude: markerPosition.lng,
    latitude: markerPosition.lat,
    bearing: 0,
    pitch: 0,
  };

  function handleMarkerDragEnd(event: MarkerDragEvent) {
    if (onMarkerDragEnd) {
      onMarkerDragEnd(event.lngLat);
    }
  }

  useEffect(() => {
    if (!mapRef?.current?.flyTo) {
      return;
    }
    mapRef?.current?.flyTo({
      center: [markerPosition.lng ?? 0, markerPosition.lat ?? 0],
      duration: 1000,
    });
  }, [markerPosition]);

  return (
    <Map
      ref={mapRef}
      initialViewState={initialViewState}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API}
      mapStyle={"mapbox://styles/smala/ck3el600w480q1cnsgbgq9i14"}
      worldview={getMapRegion(client)}
    >
      <NavigationControl position="top-left" />

      {areFiniteCoordinates(markerPosition) && (
        <Marker
          latitude={markerPosition.lat}
          longitude={markerPosition.lng}
          draggable={!!onMarkerDragEnd}
          onDragEnd={handleMarkerDragEnd}
          anchor="bottom"
        >
          <PlaceIcon
            style={{
              width: 50,
              height: 50,
              cursor: "pointer",
            }}
          />
        </Marker>
      )}
    </Map>
  );
}

export default MapWithMarker;
