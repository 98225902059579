import React from "react";

import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MessageIcon from "@material-ui/icons/Message";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import clsx from "clsx";
import dateFormat from "dateformat";
import { INote } from "fieldpro-tools";

interface INoteCard {
  note: INote;
  isLast: boolean;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timelineOppositeContent: {
    flex: 0.3,
    width: "100%",
    padding: "12px 6px",
  },
  timelineContent: {
    width: "400px",
  },
  title: {
    height: "24px",
    fontFamily: "BasierCircle",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
  },
  content: {
    height: "80px",
    margin: "10px 0 0",
    fontFamily: "BasierCircle",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.25px",
    color: "#6c6c6c",
  },
  time: {
    padding: "10px 8px 10px 0",
    fontFamily: "BasierCircle",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "normal",
    color: "#2c2c2c",
  },
  timelineDot: {
    backgroundColor: "#FFEED4",
  },
  timelineConnector: {
    backgroundColor: "#FFEED4",
    width: "3px",
  },

  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardContent: {
    padding: "5px 5px 0px 5px",
  },
}));
export default function NoteCard({ note, isLast }: INoteCard) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isCollapsible, setIsCollapsible] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (note.content && note.content.length > 236) {
      setIsCollapsible(true);
    }
  }, [note.content]);
  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.timelineOppositeContent}>
        <span className={classes.time}>
          {note.updated_at
            ? dateFormat(note.updated_at, "dd mmmm yyyy", true)
            : ""}
        </span>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot className={classes.timelineDot}>
          <MessageIcon color="secondary" />
        </TimelineDot>
        {isLast ? null : (
          <TimelineConnector className={classes.timelineConnector} />
        )}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Card>
          <CardContent className={classes.cardContent}>
            <h1 className={classes.title}>{note.title}</h1>
            {(!isCollapsible || (isCollapsible && !expanded)) && (
              <span className={classes.content}>
                {isCollapsible && !expanded
                  ? note.content.substring(0, 235) + "..."
                  : note.content}
              </span>
            )}
          </CardContent>
          {isCollapsible && expanded && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent className={classes.cardContent}>
                <span className={classes.content}>{note.content}</span>
              </CardContent>
            </Collapse>
          )}
          {isCollapsible && (
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          )}
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}
