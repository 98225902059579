import { Box } from "@material-ui/core";

import { GreyDark } from "assets/colors";

interface IFormSectionHeader {
  title?: string;
  description?: string;
}

const FormSectionHeader = ({ title, description }: IFormSectionHeader) => {
  return (
    <Box fontSize={16} marginBottom={3} marginTop={2}>
      {title && (
        <div style={{ marginBottom: "5px", fontWeight: 600 }}>{title}</div>
      )}
      {description && (
        <div style={{ marginTop: "5px", fontWeight: 500, color: GreyDark }}>
          {description}
        </div>
      )}
    </Box>
  );
};

export default FormSectionHeader;
