import { makeStyles, TableHead, TableRow } from "@material-ui/core";
import _ from "lodash";

import { OffWhiteLight } from "assets/colors";
import { TColumnType } from "components/Table/model";

import SimpleTableCell from "./SimpleTableCell";

interface ISimpleTableHeader {
  columns: TColumnType[];
}

const useStyles = makeStyles({
  SimpleTableHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: OffWhiteLight,
  },
});

const SimpleTableHeader = ({ columns }: ISimpleTableHeader) => {
  const classes = useStyles();

  const displayedColumns = _.filter(columns, ({ hidden }) => !hidden);

  if (_.isEmpty(displayedColumns) || !displayedColumns) {
    return null;
  }

  return (
    <TableHead className={classes.SimpleTableHeader}>
      <TableRow>
        {_.map(displayedColumns, (column) => (
          <SimpleTableCell
            key={column.name}
            isHeaderCell
            isSticky={column.sticky}
          >
            {column.label}
          </SimpleTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SimpleTableHeader;
