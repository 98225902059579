import { IErpOrdersState } from "./reducer";

const initialState: IErpOrdersState = {
  orders: [],
  isLoading: false,
  isUpdating: false,
  count: 0,
};

export default initialState;
