import Moment from "moment";

import { IClient } from "model/entities/Client";

import { clientTimeZoneToMomentTimeZone } from "./clientTimeZoneToMomentTimeZone";

interface IGetBoardSlotDateTimeString {
  day: string;
  time: string;
  moment: typeof Moment;
  client: IClient;
}
export function getBoardSlotDateTimeString({
  day,
  time,
  moment,
  client,
}: IGetBoardSlotDateTimeString) {
  const momentTimezone = clientTimeZoneToMomentTimeZone(client);
  const momentDate = moment.tz(
    `${day}T${moment(time, "hA").format("HH:mm")}`,
    momentTimezone
  );
  const dateFormatted = momentDate.format("YYYY-MM-DDTHH:mm:ssZ");
  return {
    dateFormatted,
    momentDate,
  };
}
