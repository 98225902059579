/**
 * @notes: Script action types
 */
export const CREATE_SCRIPT_BEGIN = "CREATE_SCRIPT_BEGIN";
export const CREATE_SCRIPT_SUCCESS = "CREATE_SCRIPT_SUCCESS";
export const CREATE_SCRIPT_FAILURE = "CREATE_SCRIPT_FAILURE";

export const UPDATE_SCRIPT_BEGIN = "UPDATE_SCRIPT_BEGIN";
export const UPDATE_SCRIPT_SUCCESS = "UPDATE_SCRIPT_SUCCESS";
export const UPDATE_SCRIPT_FAILURE = "UPDATE_SCRIPT_FAILURE";

export const FETCH_SCRIPT_HISTORY_BEGIN = "FETCH_SCRIPT_HISTORY_BEGIN";
export const FETCH_SCRIPT_HISTORY_SUCCESS = "FETCH_SCRIPT_HISTORY_SUCCESS";
export const FETCH_SCRIPT_HISTORY_FAILURE = "FETCH_SCRIPT_HISTORY_FAILURE";

export const FETCH_ALL_SCRIPTS_BEGIN = "FETCH_ALL_SCRIPTS_BEGIN";
export const FETCH_ALL_SCRIPTS_SUCCESS = "FETCH_ALL_SCRIPTS_SUCCESS";
export const FETCH_ALL_SCRIPTS_FAILURE = "FETCH_ALL_SCRIPTS_FAILURE";

export const DELETE_SCRIPT_BEGIN = "DELETE_SCRIPT_BEGIN";
export const DELETE_SCRIPT_SUCCESS = "DELETE_SCRIPT_SUCCESS";
export const DELETE_SCRIPT_FAILURE = "DELETE_SCRIPT_FAILURE";

export const USE_SCRIPT_BEGIN = "USE_SCRIPT_BEGIN";
export const USE_SCRIPT_SUCCESS = "USE_SCRIPT_SUCCESS";
export const USE_SCRIPT_FAILURE = "USE_SCRIPT_FAILURE";

export const RUN_SCRIPT_JOB_BEGIN = "RUN_SCRIPT_JOB_BEGIN";
export const RUN_SCRIPT_JOB_SUCCESS = "RUN_SCRIPT_JOB_SUCCESS";
export const RUN_SCRIPT_JOB_FAILURE = "RUN_SCRIPT_JOB_FAILURE";

export const CLEAR_DATA = "CLEAR_DATA";
