import { computeTime } from "fieldpro-tools";

export const getAbsoluteDateFromDate = (date: Date) => {
  return [
    {
      period: "YEAR",
      value: date.getFullYear(),
    },
    {
      period: "MONTH",
      value: date.getMonth(),
    },
    {
      period: "DAY",
      value: date.getDay(),
    },
    {
      period: "HOUR",
      value: date.getHours(),
    },
  ];
};

export const computeTerminaisonTime = (
  now: Date,
  absolute: Array<{ value: number; period: string }>,
  relative: Array<{ value: number; period: string }>
): string => {
  const transposedDate = computeTime(now, absolute, relative, true);
  const localDate = transposedDate.toLocaleString();
  return localDate;
};

export const getWeekNb = (date: Date) => {
  //find the year of the entered date
  const oneJan = new Date(date.getFullYear(), 0, 1);
  // calculating number of days in given year before the given date
  const numberOfDays = Math.floor(
    (date.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)
  );
  // adding 1 since to current date and returns value starting from 0
  const result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
  return result;
};
