import { Component } from "react";

import TLang from "model/application/Lang";

import CustomSnackbar from "./CustomSnackbar";
interface ISnackbarMessageProps {
  isCreateActionOngoing?: boolean;
  isEditActionOngoing?: boolean;
  isUploadActionOngoing?: boolean;
  isArchiveActionOngoing?: boolean;
  isDeleteActionOngoing?: boolean;
  isRestoreActionOngoing?: boolean;
  isFetchActionOngoing?: boolean;
  isLicenseActionOngoing?: boolean;
  isUnlicenseActionOngoing?: boolean;
  isUploadingFileOngoing?: boolean;
  isOtherActionOngoing?: boolean;
  isRecoveringPasswordActionOngoing?: boolean;
  lang: TLang;
}

interface ISnackbarMessageState {
  isCreateActionOngoing: boolean;
  isEditActionOngoing: boolean;
  isUploadActionOngoing: boolean;
  isArchiveActionOngoing: boolean;
  isDeleteActionOngoing: boolean;
  isRestoreActionOngoing: boolean;
  isFetchActionOngoing: boolean;
  isLicenseActionOngoing: boolean;
  isUnlicenseActionOngoing: boolean;
  isUploadingFileOngoing: boolean;
  isOtherActionOngoing: boolean;
  isRecoveringPasswordActionOngoing: boolean;
}

export class SnackbarMessage extends Component<
  ISnackbarMessageProps,
  ISnackbarMessageState
> {
  constructor(props: ISnackbarMessageProps) {
    super(props);
  }

  render() {
    const {
      isCreateActionOngoing,
      isEditActionOngoing,
      isUploadActionOngoing,
      isArchiveActionOngoing,
      isDeleteActionOngoing,
      isRestoreActionOngoing,
      isFetchActionOngoing,
      isLicenseActionOngoing,
      isUnlicenseActionOngoing,
      isUploadingFileOngoing,
      isOtherActionOngoing,
      isRecoveringPasswordActionOngoing,
      lang,
    } = this.props;

    let message = "";
    if (isCreateActionOngoing) {
      message = lang.notifications.inProgressNotifications.create;
    } else if (isEditActionOngoing) {
      message = lang.notifications.inProgressNotifications.edit;
    } else if (isUploadActionOngoing) {
      message = lang.notifications.inProgressNotifications.upload;
    } else if (isArchiveActionOngoing) {
      message = lang.notifications.inProgressNotifications.archive;
    } else if (isDeleteActionOngoing) {
      message = lang.notifications.inProgressNotifications.delete;
    } else if (isRestoreActionOngoing) {
      message = lang.notifications.inProgressNotifications.restore;
    } else if (isFetchActionOngoing) {
      message = lang.notifications.inProgressNotifications.fetch;
    } else if (isLicenseActionOngoing) {
      message = lang.notifications.inProgressNotifications.license;
    } else if (isUnlicenseActionOngoing) {
      message = lang.notifications.inProgressNotifications.unlicense;
    } else if (isUploadingFileOngoing) {
      message = lang.notifications.inProgressNotifications.upload;
    } else if (isOtherActionOngoing) {
      message = lang.notifications.inProgressNotifications.otherAction;
    } else if (isRecoveringPasswordActionOngoing) {
      message = lang.notifications.inProgressNotifications.resettingPassword;
    }

    if (!message) {
      return null;
    }

    return (
      <CustomSnackbar message={message} type={"loading"} onClose={() => {}} />
    );
  }
}

export default SnackbarMessage;
