import moment from "moment";
import { createSelector } from "reselect";

import { IMobileUser } from "model/entities/User";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../../redux/selectors/appSelector";
import { DATE_FORMAT } from "../../../../utils/constants";

/**
 * Get app Users
 * @param {Object} state State of Redux store
 */
const getAllMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.allUsers;

/**
 * Fetching users selector. This will return True to either fetching app
 * users
 */
export const getIsFetchingAllMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.fetchingAllUsers;

/** selector to fetch state of adding app users*/
export const getIsCreatingMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.creatingUsers;

/** Updating user selector. will either return True for whether we are updating a web
 * or app user
 */
export const getIsUpdatingMobileUser = (state: IRootState) =>
  state.users.mobileUsers.updatingUser;

/** Selector to get the status of deleting a user */
export const getIsDeletingMobileUser = (state: IRootState) =>
  state.users.mobileUsers.deletingUser;

/** Selector to get the status of deleting users */
export const getIsDeletingMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.deletingUsers;

/** Selector to get the status of licensing user */
export const getIsLicensingMobileUser = (state: IRootState) =>
  state.users.mobileUsers.isLicensingUser;

/** Selector to get the status of licensing users */
export const getIsLicensingMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.isBulkLicensingUsers;

/** Selector to get the status of unlicensing user */
export const getIsUnlicensingMobileUser = (state: IRootState) =>
  state.users.mobileUsers.isUnlicensingUser;

/** Selector to get the status of unlicensing users */
export const getIsUnlicensingMobileUsers = (state: IRootState) =>
  state.users.mobileUsers.isBulkUnlicensingUsers;

/** Selector to getting all the app users*/
export const allMobileUsersSelector = createSelector(
  getAllMobileUsers,
  (mobileUsers) => mobileUsers
);
/** Selector to get all mobile users roles */
export const allMobileUsersRolesSelector = createSelector(
  getAllMobileUsers,
  (mobileUsers) => {
    const roles = mobileUsers.map((user) => user.role);
    return [...roles];
  }
);
/**
 * Get app mobile User teammates
 * @param {Object} state State of Redux store
 */
export const getMobileUserTeammates = (state: IRootState) => {
  return state.users.mobileUsers.mobile_teammates;
};

/**
 * Formats the app users to be displayed in a Table. This is removing unused objects from each user
 */
export const allMobileUsersComposedSelector = customSelectorCreator(
  allMobileUsersSelector,
  (mobileUsers: IMobileUser[]): IMobileUser[] => {
    return mobileUsers.map((mobileUser) => {
      return {
        ...mobileUser,
        created_on: moment(mobileUser.created_at).format(DATE_FORMAT),
      };
    });
  }
);

/**
 * Get app users by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getMobileUsersById = (state: IRootState) => state.users.mobileUsers.byId;

/**
 * Selector to get the app users by id
 */
export const mobileUsersByIdSelector = createSelector(
  getMobileUsersById,
  (byId) => byId
);

// selector getting all the licensed users
export const licensedUsersComposedSelector = createSelector(
  getAllMobileUsers,
  (mobileUsers) => mobileUsers.filter((usr) => usr.licensed)
);
