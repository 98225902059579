import React, { Component } from "react";

import { TableCell, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import styles from "../styles";

export interface ICustomTableCellProps {
  classes: any;
  key?: string | number;
  style?: any;
  content?: any;
  rowItem?: any;
  onCellClick: (rowItem: any) => void;
}

interface ICustomTableCellStates {
  cellOverflowed: boolean;
}

/**
 * TableCell component. A Table cell
 * @param {Object} Props passed in to Table cell
 */
class CustomTableCell extends Component<
  ICustomTableCellProps,
  ICustomTableCellStates
> {
  ref: any;
  constructor(props: ICustomTableCellProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      cellOverflowed: false,
    };
  }

  componentDidMount = () => {
    this.updateCellOverflowed();
  };

  onMouseOver = () => {
    this.updateCellOverflowed();
  };

  updateCellOverflowed = () => {
    const { current } = this.ref || {};
    const cellOverflowed = current?.offsetWidth < current.scrollWidth;
    this.setState({ cellOverflowed });
  };

  /**
   * On Click event callback. this tracks click event and returns event to passed in props
   * */
  handleCellClick = () => {
    const { onCellClick, rowItem } = this.props;
    if (
      !rowItem.hasOwnProperty("isClickDisabled") ||
      !rowItem.isClickDisabled
    ) {
      return onCellClick(rowItem);
    }
  };

  render() {
    const { style, key, content, classes } = this.props;
    const { cellOverflowed } = this.state;
    return (
      <Tooltip
        classes={{ tooltip: classes.TableCellTooltip }}
        title={cellOverflowed ? content : ""}
        placement="bottom"
      >
        <TableCell
          key={key}
          className={classes.TableCell}
          style={style}
          onClick={this.handleCellClick}
          onMouseOver={this.onMouseOver}
          ref={this.ref}
        >
          {content}
        </TableCell>
      </Tooltip>
    );
  }
}

export default withStyles(styles as any)(CustomTableCell);
