import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { GaugeColorRanges, getGaugeColor } from "./utils/getGaugeColors";

export interface IGradientLegendProps {
  minRange: number;
  maxRange: number;
  labelFormatter?: (value: number) => string;
  reverse?: boolean;
  gaugeWidth?: number;
  gaugeHeight?: number;
  colorPalette?: GaugeColorRanges;
}

interface IStyleProps
  extends Pick<IGradientLegendProps, "gaugeWidth" | "gaugeHeight"> {}
const useStyles = makeStyles({
  gauge: {
    height: (props: IStyleProps) => props.gaugeHeight,
    width: (props: IStyleProps) => props.gaugeWidth,
  },
  gaugeLabel: {
    textAlign: "left",
    fontSize: "8px",
    height: "auto",
    width: (props: IStyleProps) => `${props.gaugeWidth}px`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

function GradientLegend({
  maxRange,
  minRange,
  reverse,
  labelFormatter,
  gaugeHeight = 10,
  colorPalette,
}: IGradientLegendProps) {
  const classes = useStyles({ gaugeWidth: 100, gaugeHeight });
  if (reverse) {
    const temp = maxRange;
    maxRange = minRange;
    minRange = temp;
  }

  const range = maxRange - minRange;
  const secondRange = Math.floor(minRange + range * (1 / 3));
  const thirdRange = Math.floor(minRange + range * (2 / 3));
  const minColor = getGaugeColor(minRange, minRange, maxRange, colorPalette);
  const maxColor = getGaugeColor(maxRange, minRange, maxRange, colorPalette);

  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      width={400}
      data-testid="gradient-legend"
    >
      <Box display="grid" gridTemplateRows="1fr auto">
        <Box
          className={classes.gauge}
          style={{
            background: `linear-gradient(90deg, ${minColor} 0%, ${maxColor} 100%)`,
            width: "100%",
          }}
        />
        <Box
          className={classes.gaugeLabel}
          style={{
            width: "100%",
          }}
          display={"grid"}
          justifyItems={"center"}
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        >
          <Box justifySelf={"left"}>
            {labelFormatter ? labelFormatter(minRange) : minRange}
          </Box>
          <Box justifySelf={"left"}>
            {labelFormatter ? labelFormatter(secondRange) : secondRange}
          </Box>
          <Box justifySelf={"right"}>
            {labelFormatter ? labelFormatter(thirdRange) : thirdRange}
          </Box>
          <Box justifySelf={"right"}>
            {labelFormatter ? labelFormatter(maxRange) : maxRange}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GradientLegend;
