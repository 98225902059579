import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import { getLocationValue } from "containers/lists/utils";
import { IOption } from "model/application/components";
import { IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import {
  cleanDirtyDate,
  getDefaultDateFormat,
  getDefaultTimeFormat,
} from "utils/dateUtils";

/**
 * Format Item attribute for exports (Excel file).
 * Returns a "string" version of what we see in the table (i.e MC chips are converted to comma-separated labels),
 * This not very usable for a technical person (since we don't include option keys for SC, SCOL, MC, ...) but it's clearer for most clients.
 */
export function formatAttribute(
  item: IListItem,
  attr: string,
  schema: IListSchema
) {
  switch (schema.type) {
    case CUSTOM_FIELD_TYPE.UPLOAD_FILE:
    case CUSTOM_FIELD_TYPE.SIGNATURE:
    case CUSTOM_FIELD_TYPE.PICTURE: {
      return item[attr]?.url || item[attr] || "";
    }
    case CUSTOM_FIELD_TYPE.GPS: {
      return getLocationValue(item, attr);
    }
    case CUSTOM_FIELD_TYPE.DATE_PICKER: {
      return formatAttributeDate(item[attr]);
    }
    case CUSTOM_FIELD_TYPE.TIME_PICKER: {
      return formatAttributeTime(item[attr]);
    }
    case CUSTOM_FIELD_TYPE.GEO_DELIMITATION: {
      try {
        return JSON.stringify(item[attr]);
      } catch (error) {
        return console.error(error);
      }
    }
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE: {
      const selectedOption = _.find(
        schema.options,
        (k) => item[attr] === k.key
      );
      const options = _.compact([selectedOption]);
      return formatOptions(options);
    }
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE: {
      const selectedOptions = _.filter(schema.options, (option) =>
        _.includes(item[attr], option.key)
      );

      return formatOptions(selectedOptions);
    }
    // TODO: if we want to display labels for ON_LIST fields, we need the list.schema !
    // case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST: {
    // }
    // case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
    // }
    default: {
      return _.toString(item[attr]) || "";
    }
  }
}

/**
 * Date is formatted according to same format as DateCell in table
 */
export function formatAttributeDate(date: string) {
  return cleanDirtyDate(date, getDefaultDateFormat);
}

/**
 * Time is formatted according to same format as TimeCell in table
 */
export function formatAttributeTime(date: string) {
  return cleanDirtyDate(date, getDefaultTimeFormat);
}

const formatOptions = (options: IOption<string>[]) => {
  return _.map(options, "label").join(", ");
};
