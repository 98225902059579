import { FunctionComponent, useState } from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import { IMetaExpressionOptions, SOURCE } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import BasicLaunchIcon from "components/Dialog/BasicDialog/Icons/BasicLaunchIcon";
import DialogPlaceHolder from "components/Dialog/DialogPlaceHolder";
import InputCustomField from "components/Input/InputCustomField/InputCustomField";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputMultipleSelectOnList from "components/Input/InputMultipleSelectOnList";
import { CustomerDialogConnected } from "containers/customers/subcategories/map/CustomerDialog";
import { teamsComposedSelector } from "containers/teams/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { IFormState } from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { CUSTOM_FIELD_TYPE, IList } from "model/entities/List";
import {
  IActivity,
  IActivityReportInTable,
  STEP_SCOPE,
} from "model/entities/Workflow";
import { activityNeedsCustomer } from "utils/businessUtils";
import { isEmptyValue } from "utils/isEmptyValue";

import { CUSTOMER_FIELD_KEY } from "../reports/utils";
import { getOptionsFromList } from "./utils";

interface IActivityReportForm {
  report: IActivityReportInTable;
  activity: IActivity;
  nextStepScope?: STEP_SCOPE;
  lists: IList[];
  viewMode: TViewMode;
  formState: IFormState<IActivityReportInTable>;
  onUpdateGeneralState?: (attribute: string, value: unknown) => void;
  metaExpressionOptions: IMetaExpressionOptions;
}

/**
 * will generate a form for an activity report
 * @param report an activity report
 * @param activity the source activity from which the report was generated
 * @param lists
 * @returns
 */
const ActivityReportForm: FunctionComponent<IActivityReportForm> = ({
  report,
  activity,
  lists,
  nextStepScope,
  viewMode,
  onUpdateGeneralState,
  formState,
  metaExpressionOptions,
}) => {
  const lang = useTranslations();
  const displayViewMode = viewMode === "EDIT" ? "CREATE" : viewMode;
  // const selectedClient = useSelector(getSelectedClient);
  // const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);
  // const linkedMobileUser = mobileUsers.find(
  //  (mu) => mu.email && mu.email === signedInUser.id
  //);

  const teams = useSelector(teamsComposedSelector);

  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const onCloseCustomerDialog = () => {
    setOpenCustomerModal(false);
  };

  const emptyReportMessage =
    lang.containers.workflows.subCategories.activityReports.detailDialog.empty;

  const reportForm = _.compact(
    _.map(activity.questionnaire.questions, (question) => {
      const answer =
        displayViewMode !== "VIEW"
          ? report[question.tag] ?? question.default_value
          : report[question.tag];

      if (
        (displayViewMode === "VIEW" &&
          isEmptyValue(answer) &&
          question.type !== CUSTOM_FIELD_TYPE.PLAIN_TEXT) ||
        (report._completed_source === SOURCE.MOBILE &&
          ["VIEW", "EDIT"].includes(viewMode) &&
          question.is_hidden)
      ) {
        return null;
      }

      const listId = question?.list_id;
      const list = listId ? _.find(lists, { id: listId }) : undefined;
      return (
        <InputCustomField
          key={question.tag}
          customField={question}
          answer={answer}
          list={list}
          viewMode={displayViewMode}
          formState={formState}
          onUpdateGeneralState={onUpdateGeneralState}
          metaExpressionOptions={metaExpressionOptions}
        />
      );
    })
  );

  const renderScopeSelection = () => {
    if (!nextStepScope || nextStepScope === STEP_SCOPE.GLOBAL) return null;
    let options: IOption<string>[] = [];
    if (
      nextStepScope === STEP_SCOPE.MOBILE_USER ||
      nextStepScope === STEP_SCOPE.SINGLE_MOBILE_USER
    ) {
      options = mobileUsers.map((u, idx) => ({
        key: u.id,
        label: `${u.first_name} ${u.last_name}`,
        index: idx,
      }));
    } else {
      options = teams.map((t, idx) => ({
        key: t.id,
        label: `${t.name}`,
        index: idx,
      }));
    }
    let multipleSelection = false;
    if (
      nextStepScope === STEP_SCOPE.MOBILE_USER ||
      nextStepScope === STEP_SCOPE.SINGLE_TEAM
    )
      multipleSelection = true;
    return (
      <>
        <InputMultipleSelect
          key={"_owners"}
          name="_owners"
          langlabel={{
            title:
              lang.containers.workflows.subCategories.activities.questions
                .assignSelection,
            tooltip: "",
          }}
          multipleSelection={multipleSelection}
          viewMode={displayViewMode}
          viewStacked
          options={options}
          onChange={(value: { key: string }[], name: string) => {
            formState.handleInputChange(
              value.map((e) => e.key),
              name
            );
          }}
          defaultValue={options.filter((o) =>
            formState.attributes._owners?.includes(o.key) ? true : false
          )}
          lang={lang}
          required={true}
        />
      </>
    );
  };

  const customerSelectionForm = () => {
    const options = getOptionsFromList(_.find(lists, { id: "customer" }));
    const customerId = report[CUSTOMER_FIELD_KEY];
    if (customerId && !_.find(options, { key: customerId })) {
      options.push({
        key: customerId,
        label: report.customer_name,
      });
    }

    const title =
      lang.containers.workflows.subCategories.activities.questions
        .customerSelection;

    return (
      <>
        <InputMultipleSelectOnList
          listId="customer"
          key={CUSTOMER_FIELD_KEY}
          name={CUSTOMER_FIELD_KEY}
          required
          viewStacked
          multipleSelection={false}
          highlightContent={displayViewMode === "VIEW"}
          viewMode={displayViewMode}
          options={options}
          defaultValue={_.filter(options, { key: customerId })}
          onChange={(value: IOption[], name: string) => {
            // NOTE: We don't handle this SCOL as the others in the state, we store the ID as string directly
            const customerId = value?.[0]?.key;
            formState.handleInputChange(customerId, name);
          }}
          langlabel={{ title, tooltip: "" }}
          labelIcons={[
            customerId && (
              <BasicLaunchIcon
                key="launch" // will log warning and fail test without this key
                onClick={() => setOpenCustomerModal(true)}
              />
            ),
          ]}
        />

        {openCustomerModal ? (
          <CustomerDialogConnected
            customerId={customerId}
            onClose={onCloseCustomerDialog}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      {reportForm.length > 0 ? (
        <>
          {activityNeedsCustomer(activity) && <>{customerSelectionForm()}</>}
          {reportForm}
          {renderScopeSelection()}
        </>
      ) : (
        <DialogPlaceHolder
          placeholderIcon={<DescriptionIcon />}
          placeholderText={emptyReportMessage}
        />
      )}
    </>
  );
};
export default ActivityReportForm;
