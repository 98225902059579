import _ from "lodash";
import { useSelector } from "react-redux";

import { allListsSelector } from "containers/lists/redux/selectors";
import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import {
  allActivitiesSelector,
  allWorkflowsSelector,
} from "containers/workflows/redux/selectors";
import ActivityReportDialog from "containers/workflows/subcategories/activities/ActivityReportDialog";
import { IActivityReportDialog } from "containers/workflows/subcategories/activities/ActivityReportDialog/ActivityReportDialog";
import { getActivityColumnTypes } from "containers/workflows/subcategories/activities/utils/activityReports";
import { formatAugmentedActivityReport } from "containers/workflows/subcategories/activities/utils/activityReports/formatAugmentedActivityReport";
import useTranslations from "hooks/useTranslations";

export interface ITimelineReportViewerProps
  extends Pick<IActivityReportDialog, "onClose"> {
  report: IAugmentedActivityReport;
}

function TimelineReportViewer({ report, onClose }: ITimelineReportViewerProps) {
  const lang = useTranslations();

  // TODO: get by ID directly from the store ?
  const activities = useSelector(allActivitiesSelector);
  const workflows = useSelector(allWorkflowsSelector);

  const lists = useSelector(allListsSelector);

  const activityId = report.metadata._activity_id;
  const workflowId = report.metadata._workflow_id;

  const workflow = _.find(workflows, { id: workflowId });
  const activity = _.find(activities, { id: activityId });
  const columnTypes = activity ? getActivityColumnTypes(activity, lang) : null;

  if (!activity || !workflow || !report || _.isNull(columnTypes)) {
    return null;
  }

  const formattedReport = formatAugmentedActivityReport(
    report,
    false,
    columnTypes,
    activity.questionnaire.questions,
    lists,
    lang
  );

  return (
    <ActivityReportDialog
      open
      onClose={onClose}
      initViewMode={"VIEW"}
      activity={activity}
      report={formattedReport}
      workflow={workflow}
      disableEdition
    />
  );
}

export default TimelineReportViewer;
