import { DEFAULT_ROLES, isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";

import { IClient, TLightClient } from "model/entities/Client";

import { ClientSettingsTabs } from "./components/ClientForm/ClientForm";

export const getWorkspaceTypeOptions = (clients: TLightClient[]): string[] => {
  return _.compact([
    "CUSTOM",
    "TEMPLATE",
    ...clients
      .map((c) => c.dbname)
      .filter((e) => e && e.startsWith("workspace_template_"))
      .map((e) => e.split("workspace_template_")[1].toUpperCase()),
  ]);
};

interface IUserCanViewClientTab {
  tabId?: ClientSettingsTabs;
  role?: string;
  client?: IClient;
}

export const userCanViewClientTab = ({
  tabId,
  client,
  role,
}: IUserCanViewClientTab) => {
  if (
    role === DEFAULT_ROLES.FREE_TRIAL ||
    client?.profile === DEFAULT_ROLES.FREE_TRIAL
  ) {
    return _.includes(
      [
        ClientSettingsTabs.BILLING,
        ClientSettingsTabs.USAGE,
        // ClientSettingsTabs.PLANS,
      ],
      tabId
    );
  }

  if (
    _.includes(
      [
        ClientSettingsTabs.DEVELOPER,
        ClientSettingsTabs.BILLING,
        ClientSettingsTabs.MANAGEMENT,
        ClientSettingsTabs.ADDON,
        ClientSettingsTabs.ENVIRONMENT_VARIABLES,
      ],
      tabId
    )
  ) {
    return isOptimetriksRole(role);
  }

  return true;
};
