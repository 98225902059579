import React, { ReactNode } from "react";

interface IKeyUpAndDownEvent {
  onKeyUp?: () => void;
  onKeyDown?: () => void;
  children: ReactNode;
}

const KeyUpAndDownEvent: React.FunctionComponent<IKeyUpAndDownEvent> = ({
  onKeyUp,
  onKeyDown,
  children,
}) => {
  const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // ^ "ArrowUp"; < "ArrowLeft" ; > "ArrowRight"
    if (event.code === "ArrowUp" && onKeyUp) {
      onKeyUp();
    }

    if (event.code === "ArrowDown" && onKeyDown) {
      onKeyDown();
    }
  };

  return (
    <div tabIndex={0} onKeyDown={keyDownHandler}>
      {children}
    </div>
  );
};

export default KeyUpAndDownEvent;
