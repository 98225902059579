import { useCallback, useEffect, useRef, useState } from "react";

import { Box, Chip, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import _ from "lodash";

import { DarkTeal, MediumTeal } from "assets/colors";
import PopoverWithArrow from "components/Popover/PopoverWithArrow";
import { useTranslations } from "hooks/useTranslations";
import { IFilter } from "model/application/Filter";

import { getFormattedValue } from "./Filter.utils";
import DateFilter from "./FilterModals/components/DateFilter";
import HierarchyFilter from "./FilterModals/components/HierarchyFilter";
import MultipleChoiceFilter from "./FilterModals/components/MultipleChoiceFilter";
import MultipleChoiceOnListFilter from "./FilterModals/components/MultipleChoiceOnListFilter";
import NumberFilter from "./FilterModals/components/NumberFilter";
import SingleChoiceFilter from "./FilterModals/components/SingleChoiceFilter";
import TextFilter from "./FilterModals/components/TextFilter";
import FILTER_TYPE from "./TypeFilter";

interface IFilterDropdownStyles extends Pick<IFilter, "styles"> {}
const useStyles = makeStyles({
  FilterDropdown: ({ styles }: IFilterDropdownStyles) => ({
    backgroundColor: styles?.bgColor || MediumTeal,
    "&:focus": {
      backgroundColor: MediumTeal,
    },
    "&:hover": {
      backgroundColor: DarkTeal,
    },
    borderRadius: "4px",
    padding: "6px",
    color: styles?.textColor || "white",
    fontWeight: 500,
    ...(styles?.dropdownStyles || {}),
  }),
  columnName: {
    fontFamily: "BasierCircle",
    fontWeight: "bold",
  },
  columnValue: {
    fontFamily: "BasierCircle",
  },
} as const);

export interface IFilterDropdown {
  filter: IFilter;
  onChangeFilter: (tag: string, value: any) => void;
  onDelete?: (args?: any) => void;
  withoutArrow?: boolean;
  openOnMount?: boolean;
  dataTestId?: string;
  listId?: string;
}

const FilterDropdown = ({
  filter,
  onChangeFilter,
  onDelete,
  openOnMount = true,
  dataTestId,
}: IFilterDropdown) => {
  const classes = useStyles({
    styles: filter.styles,
  });
  const lang = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClickAwayEnabled, setIsClickAwayEnabled] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const chipRef = useRef(null);

  // Open modal on mount (when chipRef gets defined)
  useEffect(() => {
    if (openOnMount && chipRef.current) {
      setAnchorEl(chipRef.current);
    }
  }, [openOnMount]);

  const onOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (currentFilterValue: any) => {
    // If the DatePicker is open, the click away closes the dropdown as well.
    // => We block the click away behavior of the dropdown as long as the datepicker modal is open.
    if (!isClickAwayEnabled) {
      return;
    }

    // closing the modal with an empty value is equivalent to removing the filter
    if (!isDefined(currentFilterValue) && onDelete) {
      onDelete();
    }

    setAnchorEl(null);
  };

  const onChangeFilterAndClose = (tag: string, value: any) => {
    onChangeFilter(tag, value);
    onClose(value);
  };

  const blockClickAway = useCallback(() => {
    setIsClickAwayEnabled(() => false);
  }, []);

  const restoreClickAway = useCallback(() => {
    setIsClickAwayEnabled(() => true);
  }, []);

  const filterFormattedValue = getFormattedValue(filter, lang);
  const FilterModalContent = getFilterModalContent(filter.type);

  // trick to hide filter immediately (no visual glitches when setting value to an empty value)
  if (isDeleted) {
    return null;
  }

  return (
    <>
      <Chip
        data-testid={dataTestId}
        ref={chipRef}
        className={classes.FilterDropdown}
        label={
          <Box
            display={"grid"}
            alignContent={"center"}
            alignItems={"center"}
            gridAutoFlow={"column"}
          >
            <Box>
              <span className={classes.columnName}>{filter.label}</span>
              <span className={classes.columnValue}>
                {filterFormattedValue ? ": " : ""}
                {filterFormattedValue}
              </span>
            </Box>
            {filter?.styles?.showArrow && <KeyboardArrowDownIcon />}
          </Box>
        }
        onClick={onOpen}
        onDelete={
          onDelete
            ? (...args: any[]) => {
                setIsDeleted(true);
                onDelete(...args);
              }
            : undefined
        }
        deleteIcon={<DeleteIcon />}
      />

      <PopoverWithArrow
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => onClose(filter.value)}
        // withoutArrow={withoutArrow}
      >
        <FilterModalContent
          onOpenAdditionnalModal={blockClickAway}
          listId={filter.listId ?? ""}
          onCloseAdditionnalModal={restoreClickAway}
          filter={filter}
          onChangeFilter={onChangeFilterAndClose}
          lang={lang}
        />
      </PopoverWithArrow>
    </>
  );
};

const isDefined = (value: any) => {
  if (!value) {
    return false;
  }
  return !_.isEmpty(value);
};

const getFilterModalContent = (filterType: FILTER_TYPE) => {
  switch (filterType) {
    case FILTER_TYPE.DATE: {
      return DateFilter;
    }
    case FILTER_TYPE.HIERARCHY: {
      return HierarchyFilter;
    }
    case FILTER_TYPE.MULTIPLE_CHOICE: {
      return MultipleChoiceFilter;
    }
    case FILTER_TYPE.BOOLEAN: {
      return MultipleChoiceFilter;
    }
    case FILTER_TYPE.SINGLE_CHOICE: {
      return SingleChoiceFilter;
    }
    case FILTER_TYPE.NUMBER: {
      return NumberFilter;
    }
    case FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST:
      return MultipleChoiceOnListFilter;
    default: {
      return TextFilter;
    }
  }
};

export const DeleteIcon = ({ onClick }: { onClick?: any }) => (
  <span
    className="material-icons-outlined"
    style={{ fontSize: "20px" }}
    onClick={onClick}
  >
    clear
  </span>
);

export default FilterDropdown;
