import React from "react";

import "filepond/dist/filepond.min.css";

import { FilePond } from "react-filepond";

import TLang from "model/application/Lang";

import { updateBlobFile } from "./utils/updateBlobFile";
import { updateExcelOrCsvFile } from "./utils/updateExcelOrCsvFile";

export enum FileExtensions {
  Excel = "xlsx",
  Csv = "csv",
  Json = "json",
  Text = "txt",
  Doc = "doc",
  Docx = "docx",
  Pdf = "pdf",
  Jpg = "jpg",
  Png = "png",
  Mp4 = "mp4",
  Avi = "avi",
  Zip = "zip",
}

export interface IFileInformation {
  fileExtension: FileExtensions;
  fileName: string;
}

export type ILine = string[];
export type IOnUpdateBlobFileFromUploadResults = (
  results: Blob[],
  fileInformations: IFileInformation[]
) => void;
export type IOnUpdateExcelOrCsvFileFromUploadResults = (
  results: ILine[],
  fileInformations: IFileInformation
) => void;
export type IOnError = (error: string) => void;

interface IUploadFileInputProps {
  description: string | React.ReactElement;
  labelInput?: string;
  smallDisplay?: boolean;
  onUpdateFile:
    | IOnUpdateExcelOrCsvFileFromUploadResults
    | IOnUpdateBlobFileFromUploadResults;
  isErrorFile: boolean;
  allowMultiple?: boolean;
  onlyExcelOrCsv?: boolean;
  errorMessageFile?: string;
  lang: TLang;
  onError: IOnError;
}

const UploadFileInput: React.FunctionComponent<IUploadFileInputProps> = ({
  description,
  labelInput,
  smallDisplay,
  onUpdateFile,
  isErrorFile = false,
  allowMultiple = false,
  errorMessageFile,
  onlyExcelOrCsv = true,
  onError,
  lang,
}) => {
  if (!labelInput && lang) labelInput = lang.modal.uploadCSVMessage.message;

  const buildFilePond = () => {
    return (
      <FilePond
        allowMultiple={allowMultiple}
        labelIdle={labelInput}
        onupdatefiles={(fileItems) => {
          if (onlyExcelOrCsv) {
            updateExcelOrCsvFile(
              fileItems,
              onUpdateFile as IOnUpdateExcelOrCsvFileFromUploadResults,
              onError
            ).catch((e: any) => {
              alert(`Error when trying to update csv file: ${e.message}`);
            });
          } else {
            updateBlobFile(
              fileItems,
              onUpdateFile as IOnUpdateBlobFileFromUploadResults,
              onError
            ).catch((e: any) => {
              alert(`Error when trying to upload blob file: ${e.message}`);
            });
          }
        }}
      />
    );
  };

  const buildError = () => {
    return (
      isErrorFile && (
        <div
          className="errorMessage"
          style={{ color: "red", whiteSpace: "pre-wrap" }}
        >{`${lang.modal.uploadCSVMessage.errorMessage}${errorMessageFile}`}</div>
      )
    );
  };

  return (
    <React.Fragment>
      {smallDisplay ? (
        <div
          style={{
            position: "absolute",
            right: 40,
            top: 20,
            width: 180,
          }}
        >
          {description}
          {buildFilePond()}
          {buildError()}
        </div>
      ) : (
        <>
          {description}
          {buildFilePond()}
          {buildError()}
        </>
      )}
    </React.Fragment>
  );
};

export default UploadFileInput;
