import _ from "lodash";

import { CUSTOM_FIELD_TYPE, IList } from "model/entities/List";

export const getGeoDelimitationCollection = (
  customerList: IList | undefined
) => {
  const delimitationAttribute = _.find(customerList?.schema, {
    type: CUSTOM_FIELD_TYPE.GEO_DELIMITATION,
  });

  const featureCollections = _.compact(
    _.map(customerList?.items, delimitationAttribute?.column_tag)
  );

  const delimitationFeatures = _.compact(
    _.map(featureCollections, (collection) => collection?.features?.[0])
  );

  const delimitationsFeatureCollection = {
    type: "FeatureCollection" as const,
    features: delimitationFeatures,
  };

  return delimitationsFeatureCollection;
};
