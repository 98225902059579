import { File } from "filepond";

import {
  FileExtensions,
  IFileInformation,
  IOnError,
  IOnUpdateBlobFileFromUploadResults,
} from "../UploadFileInput";
import {
  getFileInformations,
  ifAcceptedExtension,
} from "./updateExcelOrCsvFile";
const getBlobContent = async (fileItem: File) => {
  const blobUrl = window.URL.createObjectURL(fileItem.file);
  const response = await fetch(blobUrl);
  return response.blob();
};

export const updateBlobFile = async (
  fileItems: File[],
  onUpdateFile: IOnUpdateBlobFileFromUploadResults,
  onError: IOnError
) => {
  const acceptedExtensions = [
    FileExtensions.Doc,
    FileExtensions.Docx,
    FileExtensions.Excel,
    FileExtensions.Pdf,
    FileExtensions.Jpg,
    FileExtensions.Png,
    FileExtensions.Mp4,
    FileExtensions.Avi,
    FileExtensions.Zip,
    "jpeg" as FileExtensions,
  ];

  try {
    const results: Blob[] = [];
    const infos: IFileInformation[] = [];
    for (let i = 0; i < fileItems.length; i++) {
      const fileItem = fileItems[i];
      if (!fileItem || !fileItem.file) {
        onError("Invalid file");
        break;
      } else if (!ifAcceptedExtension(fileItem, acceptedExtensions)) {
        onError("File not supported");
        break;
      } else {
        // get file informations like name or extension
        const fileInformations = getFileInformations(fileItem);
        infos.push(fileInformations);

        const result = await getBlobContent(fileItem);
        results.push(result);
      }
      // callback
      onUpdateFile(results, infos);
    }
  } catch (error) {
    onError((error as any).message);
  }
};
