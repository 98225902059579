import { File } from "filepond";

import { FileExtensions } from "../UploadFileInput";

export const getExcelOrCsvFileContent = async (fileItem: File) => {
  const blobUrl = window.URL.createObjectURL(fileItem.file);
  const response = await fetch(blobUrl);

  if (fileItem.fileExtension === FileExtensions.Excel) {
    return response.blob();
  }

  return response.text();
};
