import { useState } from "react";

import { Button } from "@material-ui/core";

import useTranslations from "hooks/useTranslations";

import UpdatePaymentDetailsModal from "./UpdatePaymentDetailsModal";

function UpdatePaymentDetailsButton() {
  const lang = useTranslations();
  const [openUpdatePaymentModal, setOpenUpdatePaymentModal] = useState(false);

  function handleOpenDialog() {
    setOpenUpdatePaymentModal(true);
  }

  function defaultHandleCloseDialog() {
    setOpenUpdatePaymentModal(false);
  }

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        disableElevation
        onClick={handleOpenDialog}
      >
        {
          lang.containers.clients.subCategories.clients.createEditModal
            .inputUpdatePaymentDetails.title
        }
      </Button>

      {openUpdatePaymentModal && (
        <UpdatePaymentDetailsModal
          handleCloseDialog={defaultHandleCloseDialog}
        />
      )}
    </>
  );
}

export default UpdatePaymentDetailsButton;
