import _ from "lodash";

import {
  getMatrixFieldsSchema,
  getTagKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

interface IConvertMatrixValue {
  customField: IListSchema | IQuestion;
  value: any;
  subElementsCallback?: ({
    customField,
    value,
  }: {
    customField: any;
    value: any;
  }) => any;
  simpleMatrixFormat?: boolean;
}

export const convertMatrixValue = ({
  customField,
  value,
  subElementsCallback,
  simpleMatrixFormat,
}: IConvertMatrixValue) => {
  if (simpleMatrixFormat && value?.rows) {
    return value?.rows.reduce((acc: any, item: any) => {
      const { _item_id, ...rest } = item;
      acc[_item_id] = rest;
      return acc;
    }, {} as Record<string, any>);
  }

  const subCustomFields = getMatrixFieldsSchema(customField);
  const tagKey = getTagKey(
    subCustomFields.length > 0 ? subCustomFields[0] : customField
  );
  const matrix_questions_tags = _.map(subCustomFields, (m) => m[tagKey]);

  if (!value?.rows) {
    return {
      headers: [],
    };
  }

  const headers = _.map(value?.rows, (row: any) => {
    return {
      tag: row._item_id,
      typed_answers: _.filter(Object.keys(row), (key) =>
        _.includes(matrix_questions_tags, key)
      ).map((tag: string) => {
        const q = _.find(subCustomFields, (tp) => tp[tagKey] === tag);
        if (!subElementsCallback) return undefined;
        return {
          key: tag,
          value: subElementsCallback({
            customField: q as IListSchema | IQuestion,
            value: row[q?.[tagKey]],
          }),
        };
      }),
    };
  });

  return { headers };
};
