import { BadgeProps } from "@material-ui/core";

/**
 * All the props defined here are used
 * in the Tabs component wrapping the Tab
 */
interface ITab {
  id: string;
  label: string;
  children: React.ReactNode;
  className?: string;
  badgeContent?: string;
  hidden?: boolean;
  boxShadow?: boolean;
  overflow?: string;
  height?: string | number; // useful to set full height tab content
  badgeType?: "error" | "warning" | "info";
  style?: BadgeProps["style"];
}

/**
 * The props passed to Tab are not used directly by the component,
 * but by Tabs component wrapping the Tab
 */
const Tab = ({ children }: ITab) => {
  return <>{children}</>;
};

export default Tab;
