import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";

import { TIMELINE_EVENT_TYPE } from "model/entities/Dashboard";

export function getActionIcon(action: TIMELINE_EVENT_TYPE) {
  switch (action) {
    case TIMELINE_EVENT_TYPE.CHECK_IN:
      return <EditLocationOutlinedIcon />;
    case TIMELINE_EVENT_TYPE.CHECK_OUT:
      return <LocationOffOutlinedIcon />;
    case TIMELINE_EVENT_TYPE.CREATE_SUBMISSION:
    case TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT:
      return <AssignmentOutlinedIcon />;
  }
}
