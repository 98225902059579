import _ from "lodash";

import { IFormState } from "hooks/useFormState";

import { IMatrixAnswerForm } from "../MatrixEditorDrawer";
import { IUploadMatrixFilesResult } from "./uploadMatrixFiles";

interface IMapUploadedFilesToFormAnswers {
  formState: IFormState<IMatrixAnswerForm>;
  uploadedFiles: IUploadMatrixFilesResult | undefined | null;
}

export const mapUploadedFilesToFormAnswers = ({
  formState,
  uploadedFiles,
}: IMapUploadedFilesToFormAnswers) => {
  if (
    _.isEmpty(formState.filesToUpload) ||
    uploadedFiles?.uploaded !== true ||
    _.isEmpty(uploadedFiles?.urls)
  ) {
    return formState.attributes;
  }

  const attributesCopy = _.cloneDeep(formState.attributes);

  _.forEach(uploadedFiles.urls, (upload) => {
    const { file, upload_id } = upload;
    const attValue = file.split("/")[file.split("/").length - 1];

    _.forEach(attributesCopy, (answer, question) => {
      if (answer != attValue) {
        return;
      }
      attributesCopy[question] = { upload_id, url: upload?.url };
      formState.handleInputChange({ upload_id, url: upload?.url }, question);
    });
  });

  return attributesCopy;
};
