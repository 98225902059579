import _ from "lodash";

import { IItemGalleryItem } from "components/Matrix/MatrixEditorDrawer/ItemGallery";
import { TRowType } from "components/Table/model";

export function removeMatrixRow(item: IItemGalleryItem, rows: TRowType[]) {
  return _.filter(rows, function (row) {
    return !(
      _.isEqual(_.get(row, "_item_id"), item.id) ||
      _.isEqual(_.get(row, "_id"), item.id)
    );
  });
}
