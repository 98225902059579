import _ from "lodash";

import { CALLED_FROM } from "components/Input/InputMetaExpression/MetaExpressionWizard";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IHttpRequest,
  IIntegration,
  IJobScript,
  INotification,
  IStream,
  ITransformation,
  STEP_TYPE,
} from "model/entities/Job";

export interface IJobConfig<IJobElement, IJobConfigAdditionnalProps> {
  attributes: IJobElement;
  additionnalProps: IJobConfigAdditionnalProps;
  onChange: (
    attrName: string,
    attrValue: IJobElement[keyof IJobElement]
  ) => void;
  errors: any;
  viewMode?: TViewMode;
  disabled?: boolean;
  calledFrom?: CALLED_FROM | undefined;
}

export type TAnyJob =
  | (IStream & { job_type: STEP_TYPE.STREAM })
  | (INotification & { job_type: STEP_TYPE.NOTIFICATION })
  | (ITransformation & { job_type: STEP_TYPE.TRANSFORMATION })
  | (IIntegration & { job_type: STEP_TYPE.INTEGRATION })
  | (IJobScript & { job_type: STEP_TYPE.SCRIPT })
  | (IHttpRequest & { job_type: STEP_TYPE.HTTP_REQUEST });

export const getJobsFromType = (jobs: TAnyJob[], type: STEP_TYPE) => {
  return _.filter(jobs, { job_type: type });
};
