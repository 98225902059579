import { CrispClass } from "crisp-sdk-web";
import {
  getNormalizedWorkspaceSegment,
  isOptimetriksRole,
} from "fieldpro-tools";
import _ from "lodash";

import { IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";

import { getCrispSessionData } from "./getCrispSessionData";

interface ISetCripsSessionData {
  client?: IClient;
  user?: ISignedInUser;
  crispClient: CrispClass;
}

const setCrispSessionData = ({
  client,
  user,
  crispClient,
}: ISetCripsSessionData) => {
  try {
    // User information
    if (user?.email) {
      // /!\ cannot send "" since email is validated by Crisp
      crispClient.user.setEmail(user.email);
    }

    if (user?.name) {
      crispClient.user.setNickname(user.name);
    }

    // Client session data
    if (client) {
      const sessionData = getCrispSessionData({
        client: client,
        user: user,
      });
      crispClient.session.setData(sessionData);

      // Segments don't really make sense for internal roles
      if (isOptimetriksRole(client.profile)) {
        return;
      }

      const workspaceSegment = getNormalizedWorkspaceSegment(client);
      crispClient.session.setSegments(_.compact([workspaceSegment]), false);
    }
  } catch (e) {
    console.error("Failed to set Crisp metadata", e);
  }
};

export default setCrispSessionData;
