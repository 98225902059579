import { LabelDisplayedRowsArgs } from "@material-ui/core";

import { formatString } from "lang/utils";
import TLang from "model/application/Lang";

interface ILabelTableRowsRange {
  info: LabelDisplayedRowsArgs;
  lang: TLang;
}
export function labelTableRowsRange({ info, lang }: ILabelTableRowsRange) {
  const { count, from, to } = info;

  return formatString(lang.genericTerms.rangeAtoBofC, [from, "-", to, count]);
}
