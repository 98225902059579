import _ from "lodash";
import uniq from "lodash/uniq";

import {
  IArchiveTeamsSuccessAction,
  IAssignMobileUsersToTeamsSuccessAction,
  IAssignWebUsersToTeamsSuccessAction,
  IAssignWorkflowsToTeamsSuccessAction,
  IChangeSubcategorySuccessActionCreator,
  ICreateTeamsSuccessAction,
  ICreateTeamSuccessAction,
  IDeleteTeamsSuccessAction,
  IFetchTeamsForClientSuccessAction,
  IRemoveWorkflowsFromAllTeamsSuccessAction,
  IRestoreTeamsSuccessAction,
  ISelectTeamActionCreator,
  IUnAssignUsersFromTeamsSuccessAction,
  IUpdateHierarchySuccessAction,
  IUpdateTeamsSuccessAction,
} from "containers/teams/redux/actionCreators";
import ITeam from "model/entities/Team";
import { IAction } from "redux/store/model";

import {
  extractIdsFromArray,
  insertNewItemToArr,
  updateErrorsList,
  updateObjectInArray,
} from "../../../utils/reducerUtils";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface TeamsState {
  subCategorySelected: string;
  isFetchingTeamsForClient: boolean;
  isAssigningMobileUsersToTeams: boolean;
  isUnAssigningUsersFromTeams: boolean;
  isAssigningWebUsersToTeams: boolean;
  isAssigningWorkflowsToTeams: boolean;
  isCreatingTeam: boolean;
  isCreatingTeams: boolean;
  isUpdatingTeam: boolean;
  isDeletingTeams: boolean;
  isArchivingTeams: boolean;
  isRestoringTeams: boolean;
  allTeams: ITeam[];
  selectedTeam?: ITeam;
  allIds: string[];
  errors: any[];
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_TEAM_BEGIN:
      return {
        ...state,
        isCreatingTeam: true,
      };

    case types.CREATE_TEAM_SUCCESS: {
      const { team } = action as ICreateTeamSuccessAction;

      return {
        ...state,
        isCreatingTeam: false,
        selectedTeam: team,
        allTeams: insertNewItemToArr(state.allTeams, team),
        allIds: uniq(state.allIds.concat(team.id)),
      };
    }

    case types.CREATE_TEAM_FAILURE: {
      return {
        ...state,
        isCreatingTeam: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.CREATE_TEAMS_BEGIN:
      return {
        ...state,
        isCreatingTeams: true,
      };

    case types.CREATE_TEAMS_SUCCESS: {
      const { teams } = action as ICreateTeamsSuccessAction;

      const allTeams = state.allTeams.concat(
        (teams as ITeam[]).map((t) => ({
          ...t,
          mobile_users: [],
          web_users: [],
          workflows: [],
          dashboards: [],
        }))
      );

      return {
        ...state,
        isCreatingTeams: false,
        allTeams,
      };
    }

    case types.CREATE_TEAMS_FAILURE: {
      return {
        ...state,
        isCreatingTeams: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_TEAMS_FOR_CLIENT_BEGIN:
      return Object.assign({}, state, {
        isFetchingTeamsForClient: true,
        selectedTeam: null,
        isAddingUsersToTeam: false,
        isAddingWorkflowsToTeam: false,
        isRemovingWorkflowsFromTeam: false,
        isCreatingTeam: false,
        isUpdatingTeam: false,
        isDeletingTeam: false,
      });

    case types.FETCH_TEAMS_FOR_CLIENT_FAILURE: {
      return {
        ...state,
        isFetchingTeamsForClient: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_TEAMS_FOR_CLIENT_SUCCESS: {
      const { teams } = action as IFetchTeamsForClientSuccessAction;
      const ids = extractIdsFromArray(teams);

      return {
        ...state,
        isFetchingTeamsForClient: false,
        allTeams: teams,
        selectedTeam: null,
        allIds: uniq(state.allIds.concat(ids)),
      };
    }

    case types.UPDATE_TEAMS_BEGIN:
      return {
        ...state,
        isUpdatingTeam: true,
      };

    case types.UPDATE_TEAMS_SUCCESS: {
      const { teams } = action as IUpdateTeamsSuccessAction;

      const newAllTeams = state.allTeams.map((team) => {
        const updatedTeam = (teams as ITeam[]).filter(
          (t) => team.id === t.id
        )[0];
        if (updatedTeam) {
          return updatedTeam;
        }
        return team;
      });

      return {
        ...state,
        isUpdatingTeam: false,
        allTeams: newAllTeams,
      };
    }

    case types.UPDATE_TEAMS_FAILURE:
      return {
        ...state,
        isUpdatingTeam: false,
        errors: updateErrorsList(state, action),
      };

    case types.DELETE_TEAMS_BEGIN:
      return {
        ...state,
        isDeletingTeams: true,
      };

    case types.DELETE_TEAMS_FAILURE: {
      return {
        ...state,
        isDeletingTeams: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_TEAMS_SUCCESS: {
      const { ids } = action as IDeleteTeamsSuccessAction;

      const allTeams = state.allTeams.filter((t) => !ids.includes(t.id));

      return {
        ...state,
        isDeletingTeams: false,
        allTeams,
        selectedTeam: null,
      };
    }

    case types.ARCHIVE_TEAMS_BEGIN:
      return {
        ...state,
        isArchivingTeams: true,
      };

    case types.ARCHIVE_TEAMS_FAILURE: {
      return {
        ...state,
        isArchivingTeams: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.ARCHIVE_TEAMS_SUCCESS: {
      const { ids } = action as IArchiveTeamsSuccessAction;

      const allTeams = state.allTeams.map((t) => {
        if (ids.includes(t.id)) {
          t.active = false;
        }
        return t;
      });

      return {
        ...state,
        isArchivingTeams: false,
        allTeams,
        selectedTeam: null,
      };
    }

    case types.RESTORE_TEAMS_BEGIN:
      return {
        ...state,
        isRestoringTeams: true,
      };

    case types.RESTORE_TEAMS_FAILURE: {
      return {
        ...state,
        isRestoringTeams: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.RESTORE_TEAMS_SUCCESS: {
      const { ids } = action as IRestoreTeamsSuccessAction;

      const allTeams = state.allTeams.map((t) => {
        if (ids.includes(t.id)) {
          t.active = true;
        }
        return t;
      });

      return {
        ...state,
        isRestoringTeams: false,
        allTeams,
        selectedTeam: null,
      };
    }

    case types.REMOVE_WORKFLOWS_FROM_ALL_TEAMS_SUCCESS: {
      const { workflows } = action as IRemoveWorkflowsFromAllTeamsSuccessAction;

      let newAllTeams = state.allTeams;
      if (!state.allTeams) {
        // no team for this client, we return the state without any change
        return {
          ...state,
        };
      }
      state.allTeams.forEach((team) => {
        // get the workflows from the team
        const workflowsFromTeam = team.workflows;

        // Removes all the workflows from the team
        const remainingWorkflows: string[] = [];
        workflowsFromTeam.forEach((workflow) => {
          let exists = false;
          (workflows as string[]).forEach((wf) => {
            if (wf === workflow) {
              exists = true;
            }
          });
          if (!exists) {
            remainingWorkflows.push(workflow);
          }
        });

        // new team
        team = {
          ...team,
          workflows: remainingWorkflows,
        };

        newAllTeams = updateObjectInArray(newAllTeams, team);
      });

      return {
        ...state,
        selectedTeam: state.selectedTeam
          ? newAllTeams.find((t) => t.id === state.selectedTeam?.id)
          : null,
        allTeams: newAllTeams,
      };
    }

    // ASSIGN MOBILE USERS

    case types.ASSIGN_MOBILE_USERS_TO_TEAMS_BEGIN:
      return {
        ...state,
        isAssigningMobileUsersToTeams: true,
      };

    case types.ASSIGN_MOBILE_USERS_TO_TEAMS_FAILURE:
      return {
        ...state,
        isAssigningMobileUsersToTeams: false,
        errors: updateErrorsList(state, action),
      };

    case types.ASSIGN_MOBILE_USERS_TO_TEAMS_SUCCESS: {
      const { links } = action as IAssignMobileUsersToTeamsSuccessAction;

      const teams = {};
      (links as { team_id: string; mobile_user_id: string }[]).forEach((l) => {
        if (!teams[l.team_id]) teams[l.team_id] = [l.mobile_user_id];
        else teams[l.team_id].push(l.mobile_user_id);
      });

      let allTeams = state.allTeams;
      for (const teamId in teams) {
        // get the team to add users to
        let team: ITeam = state.allTeams.find((t) => t.id === teamId)!;

        const userIds = teams[teamId];

        // update the team
        const usersInTeam = team.mobile_users.concat(userIds);
        team = {
          ...team,
          mobile_users: usersInTeam,
        };
        allTeams = updateObjectInArray(allTeams, team);
      }

      return {
        ...state,
        isAssigningMobileUsersToTeams: false,
        allTeams,
      };
    }

    // ASSIGN WEB USERS
    case types.ASSIGN_WEB_USERS_TO_TEAMS_BEGIN:
      return {
        ...state,
        isAssigningWebUsersToTeams: true,
      };

    case types.ASSIGN_WEB_USERS_TO_TEAMS_FAILURE:
      return {
        ...state,
        isAssigningWebUsersToTeams: false,
        errors: updateErrorsList(state, action),
      };

    case types.ASSIGN_WEB_USERS_TO_TEAMS_SUCCESS: {
      const { links } = action as IAssignWebUsersToTeamsSuccessAction;

      const teams = {};
      _.forEach(links, (l) => {
        if (!teams[l.team_id]) teams[l.team_id] = [l.web_user_id];
        else teams[l.team_id].push(l.web_user_id);
      });

      let allTeams = state.allTeams;
      for (const teamId in teams) {
        // get the team to add users to
        let team: ITeam = state.allTeams.find((t) => t.id === teamId)!;

        const userIds = teams[teamId];

        // update the team
        const usersInTeam = team.web_users.concat(userIds);
        team = {
          ...team,
          web_users: usersInTeam,
        };
        allTeams = updateObjectInArray(allTeams, team);
      }

      return {
        ...state,
        isAssigningWebUsersToTeams: false,
        allTeams,
      };
    }
    // ASSIGN WORKFLOWS
    case types.ASSIGN_WORKFLOWS_TO_TEAMS_BEGIN:
      return {
        ...state,
        isAssigningWorkflowsToTeams: true,
      };

    case types.ASSIGN_WORKFLOWS_TO_TEAMS_FAILURE:
      return {
        ...state,
        isAssigningWorkflowsToTeams: false,
        errors: updateErrorsList(state, action),
      };

    case types.ASSIGN_WORKFLOWS_TO_TEAMS_SUCCESS: {
      const { links } = action as IAssignWorkflowsToTeamsSuccessAction;

      const teams = {};
      _.forEach(links, (l) => {
        if (!teams[l.team_id]) teams[l.team_id] = [l.workflow_id];
        else teams[l.team_id].push(l.workflow_id);
      });

      let allTeams = state.allTeams;
      for (const teamId in teams) {
        // get the team to workflows to
        let team: ITeam = state.allTeams.find((t) => t.id === teamId)!;

        const userIds = teams[teamId];

        // update the team
        const workflowsInTeam = team.workflows.concat(userIds);
        team = {
          ...team,
          workflows: workflowsInTeam,
        };
        allTeams = updateObjectInArray(allTeams, team);
      }

      return {
        ...state,
        isAssigningWorkflowsToTeams: false,
        allTeams,
      };
    }
    // UNASSIGN USERS
    case types.UNASSIGN_USERS_FROM_TEAMS_BEGIN:
      return {
        ...state,
        isUnAssigningUsersFromTeams: true,
      };

    case types.UNASSIGN_USERS_FROM_TEAMS_FAILURE:
      return {
        ...state,
        isUnAssigningUsersFromTeams: false,
        errors: updateErrorsList(state, action),
      };

    case types.UNASSIGN_USERS_FROM_TEAMS_SUCCESS: {
      const { links, user_type } =
        action as IUnAssignUsersFromTeamsSuccessAction;
      const field = user_type == "WEB_USER" ? "web_users" : "mobile_users";
      const teams = {};
      _.forEach(links, (l) => {
        if (!teams[l.team_id]) teams[l.team_id] = [l.user_id];
        else teams[l.team_id].push(l.user_id);
      });

      let allTeams = state.allTeams;
      for (const teamId in teams) {
        // get the team to delete users from
        let team: ITeam = state.allTeams.find((t) => t.id === teamId)!;

        const userIds: string[] = teams[teamId];

        // update the team
        const usersInTeam = team[field].filter((t) => !userIds.includes(t));
        team = {
          ...team,
          [field]: usersInTeam,
        };
        allTeams = updateObjectInArray(allTeams, team);
      }

      return {
        ...state,
        isUnAssigningUsersFromTeams: false,
        allTeams,
      };
    }
    case types.UPDATE_HIERARCHY_BEGIN:
      return {
        ...state,
        isRestoringTeams: true,
      };

    case types.UPDATE_HIERARCHY_FAILURE:
      return {
        ...state,
        isRestoringTeams: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_HIERARCHY_SUCCESS: {
      const { teams } = action as IUpdateHierarchySuccessAction;
      const ids = extractIdsFromArray(teams);

      return {
        ...state,
        isRestoringTeams: false,
        allTeams: teams,
        selectedTeam: null,
        allIds: uniq(state.allIds.concat(ids)),
      };
    }

    case types.SELECT_TEAM: {
      const { selectedTeam } = action as ISelectTeamActionCreator;
      return {
        ...state,
        selectedTeam,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }

    default:
      return state;
  }
}
