import { ReactNode } from "react";

import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

import { OffWhiteLight } from "assets/colors";
import { TViewMode } from "model/application/modal/CreateEditModal";

const styles = {
  FormFieldBackground: ({ color }: Partial<IFormFieldBackground>) => ({
    "&.VIEW, &.EDIT": {
      background: color === "grey" ? OffWhiteLight : "white",
      margin: "0 -15px",
      padding: "0px 15px",
    },
  }),
} as const;

const useStyles = makeStyles(styles);

interface IFormFieldBackground {
  color?: "grey" | "white";
  viewMode?: TViewMode;
  children: ReactNode;
}

const FormFieldBackground = ({
  color = "grey",
  viewMode = "CREATE",
  children,
}: IFormFieldBackground) => {
  const classes = useStyles({ color });

  return (
    <div className={classNames(classes.FormFieldBackground, viewMode)}>
      {children}
    </div>
  );
};

export default FormFieldBackground;
