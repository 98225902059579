import { IAccessRightProfile } from "fieldpro-tools";
import _ from "lodash";

const profileHasRight = (
  profileName: string,
  accessProfileRights: IAccessRightProfile[],
  actionCode: string
) => {
  if (profileName === "optimetriks_admin" || profileName === "superadmin") {
    return true;
  } else {
    let valueBoolean = false;
    const access = _.find(accessProfileRights, { name: profileName });
    if (access) {
      const actionWithoutS = access.actions[`${actionCode}`];

      const actionWithS = access.actions[`${actionCode}S`];

      if (
        (_.isArray(actionWithoutS) && !_.isEmpty(actionWithoutS)) ||
        (_.isBoolean(actionWithoutS) && actionWithoutS === true)
      ) {
        valueBoolean = true;
      } else {
        if (
          (_.isArray(actionWithS) && !_.isEmpty(actionWithS)) ||
          (_.isBoolean(actionWithS) && actionWithS === true)
        ) {
          valueBoolean = true;
        }
      }
      return valueBoolean;
    } else {
      return false;
    }
  }
};

export default profileHasRight;
