const getFullName = (user?: {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}) => {
  if (!user) {
    return "";
  }

  const { first_name, middle_name, last_name } = user || {};

  const middleNamePart = middle_name ? `${middle_name} ` : "";
  return `${first_name || ""} ${middleNamePart}${last_name || ""}`.trim();
};

export default getFullName;
