import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";
import { clone } from "utils/utils";

import { IMetaLevel } from "../CreateEditMetaLevelModal";

export const getLevelNbRecursive = (
  currentMeta: IMetaLevel,
  metaHierarchy: IMetaLevel[]
): number => {
  if (currentMeta.father === "none") return 0;
  const father = metaHierarchy.find(
    (h) => h.level_name === currentMeta.father
  )!;
  return getLevelNbRecursive(father, metaHierarchy) + 1;
};

export const convertMetaLevelsFromFormFormat = (
  metaHierarchy: IMetaLevel[]
): IMetaHierarchyDependency => {
  const res: IMetaHierarchyDependency = {};
  metaHierarchy.forEach((metaLevel) => {
    if (metaLevel.active) {
      let levelTypeNumber = -1;
      if (metaLevel.is_hierarchy) {
        if (
          metaHierarchy.filter(
            (h) => h.is_hierarchy && (!h.father || h.father === "none")
          ).length !== 1
        ) {
          levelTypeNumber = 0;
        } else {
          try {
            levelTypeNumber = getLevelNbRecursive(metaLevel, metaHierarchy);
          } catch (e) {
            levelTypeNumber = 0;
          }
        }
      }
      res[`level_${metaLevel.index}`] = {
        level_type_name: metaLevel.level_name,
        level_type_number: levelTypeNumber,
      };
      if (metaLevel.label_type)
        res[`level_${metaLevel.index}`].label_type_type = metaLevel.label_type;
      if (metaLevel.label_options)
        res[`level_${metaLevel.index}`].label_type_options =
          metaLevel.label_options.map((o) => ({
            key: o.key,
            value: o.label,
          }));
    }
  });
  return res;
};

export const convertMetaLevelsInFormFormat = (
  metaHierarchy: IMetaHierarchyDependency
): Array<IMetaLevel & { active?: boolean }> => {
  const activeSlots = Object.keys(metaHierarchy).map((slotNb) => {
    const currentLevelTypeNumber = metaHierarchy[slotNb].level_type_number;
    const isHierarchy = currentLevelTypeNumber !== -1;
    const result: IMetaLevel & { active?: boolean } = {
      active: true,
      index: Number.parseInt(slotNb.split("level_")[1]),
      is_hierarchy: isHierarchy,
      level_name: metaHierarchy[slotNb].level_type_name,
    };
    if (isHierarchy) {
      if (currentLevelTypeNumber === 0) {
        result.father = "none";
      } else {
        const fatherLevel = Object.keys(metaHierarchy).find(
          (e) =>
            metaHierarchy[e].level_type_number === currentLevelTypeNumber - 1
        );
        if (fatherLevel) {
          result.father = metaHierarchy[fatherLevel].level_type_name;
        }
      }
    } else {
      result.label_type = metaHierarchy[slotNb].label_type_type;
      if (metaHierarchy[slotNb].label_type_options) {
        result.label_options = metaHierarchy[slotNb].label_type_options.map(
          (o: { key: string; value: string }) => ({
            key: o.key,
            label: o.value,
          })
        );
      }
    }
    return result;
  });
  const inactiveSlots: Array<IMetaLevel & { active?: boolean }> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]
    .filter(
      (e) =>
        !Object.keys(metaHierarchy)
          .map((l) => l.split("level_")[1])
          .includes(e)
    )
    .map((e) => {
      return {
        active: false,
        level_name: "_",
        is_hierarchy: false,
        index: Number.parseInt(e),
      };
    });
  return activeSlots.concat(inactiveSlots).sort((a, b) => a.index - b.index);
};

export const getHierarchySynthetis = (elements: IMetaLevel[]) => {
  const levelNameOcc = {};
  for (const e of elements.filter((ee) => ee.active)) {
    if (levelNameOcc[e.level_name])
      return `/!\\ The level name ${e.level_name} is set multiple times. You are not allowed to do so!`;
    levelNameOcc[e.level_name] = true;
  }
  const elementsCopy: IMetaLevel[] = clone(
    elements.filter((e) => e.is_hierarchy)
  );
  if (elementsCopy.length === 0) {
    return "";
  }
  // check if there are multiple levels without father
  if (elementsCopy.filter((e) => !e.father || e.father === "none").length > 1) {
    return `/!\\ Your hierarchy does not seem complete. Please make sure you have only 1 hierarchical level without father`;
  }
  // check if there is the same father targetted several times
  for (const element of elements.filter((e) => e.is_hierarchy)) {
    if (element.father) {
      if (elements.filter((e) => e.father === element.father).length > 1) {
        return `/!\\ Your hierarchy is not acceptable: the same father is targetted several times.`;
      }
    }
  }
  for (const element of elements.filter((e) => e.is_hierarchy)) {
    try {
      getLevelNbRecursive(element, elements);
    } catch (e) {
      return `/!\\ Your hierarchy is doing some circular calls. Please correct that.`;
    }
  }
  const levelsInOrder = [
    elementsCopy.find((e) => !e.father || e.father === "none"),
  ];
  while (levelsInOrder.length !== elementsCopy.length) {
    levelsInOrder.push(
      elementsCopy.find(
        (e) => e.father === levelsInOrder[levelsInOrder.length - 1]?.level_name
      )
    );
  }
  return levelsInOrder.map((l) => l?.level_name).join(" > ");
};
