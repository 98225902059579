import { Dispatch } from "react";

import {
  IMetaExpression,
  IMetaExpressionOptionsInBE as IMetaExpressionOptions,
} from "fieldpro-tools";

import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  computeMetaExpressionBeginActionCreator,
  computeMetaExpressionFailureActionCreator,
  computeMetaExpressionSuccessActionCreator,
  createMetaExpressionBeginActionCreator,
  createMetaExpressionFailureActionCreator,
  createMetaExpressionSuccessActionCreator,
  deleteMetaExpressionBeginActionCreator,
  deleteMetaExpressionFailureActionCreator,
  deleteMetaExpressionSuccessActionCreator,
  fetchAllMetaExpressionsBeginActionCreator,
  fetchAllMetaExpressionsFailureActionCreator,
  fetchAllMetaExpressionsSuccessActionCreator,
  resetMetaExpressionSuccessActionCreator,
} from "./actionCreators";
import {
  computeMetaExpressionApiCall,
  createEditMetaExpressionApiCall,
  deleteMetaExpressionApiCall,
  fetchMetaExpressionsApiCall,
} from "./api";

/**
 * Create MetaExpression action which creates a metaExpression for embedding
 * id: string;  
 * body: string;
  queries: Query [];
  web_users: string [];
  frequency: string;
  date_range: string;
  * @param {String} id primary_key the metaExpression in the tables
 * @param {String} body body of the email
 * @param {Query []} queries list of queries to embed in the body of email.
 * @param {String []} web_users list of web_users who should receive email
 * @param {String} frequency Frequency of the email shipping (Value between ONCE, DAILY, WEEKLY, MONTHLY)
 * @param {String} date_range (Value between TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS)
 * @returns {Function}
 */
export const createMetaExpressionAction: ICreateMetaExpressionActionFunc = (
  actionName: string,
  metaExpression: IMetaExpression
): IDispatchAndGetState<any> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createMetaExpressionBeginActionCreator());

    return createEditMetaExpressionApiCall(actionName, metaExpression)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { expression } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          createMetaExpressionSuccessActionCreator({
            expression: expression,
            title: metaExpression.title || "",
          })
        );
        return expression;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateMetaExpression",
          error,
          createMetaExpressionFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Create MetaExpression action which creates a metaExpression for embedding
 * id: string;  
 * body: string;
  queries: Query [];
  web_users: string [];
  frequency: string;
  date_range: string;
  * @param {String} id primary_key the metaExpression in the tables
 * @param {String} body body of the email
 * @param {Query []} queries list of queries to embed in the body of email.
 * @param {String []} web_users list of web_users who should receive email
 * @param {String} frequency Frequency of the email shipping (Value between ONCE, DAILY, WEEKLY, MONTHLY)
 * @param {String} date_range (Value between TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS)
 * @returns {Function}
 */
export const computeMetaExpressionsAction: TComputeMetaExpressionActionFunc = (
  actionName,
  metaExpressions,
  options
): IDispatchAndGetState<any> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(computeMetaExpressionBeginActionCreator());

    return computeMetaExpressionApiCall(actionName, metaExpressions, options)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { result } = data;
        dispatch(ajaxSuccessAction());
        dispatch(computeMetaExpressionSuccessActionCreator(result));
        return metaExpressions;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ComputeMetaExpression",
          error,
          computeMetaExpressionFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Action to fetch allMetaExpressions
 * @returns {Function}
 */
export const fetchAllMetaExpressionsAction: TFetchAllMetaExpressionsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchAllMetaExpressionsBeginActionCreator());
      return fetchMetaExpressionsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { meta_expressions } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllMetaExpressionsSuccessActionCreator(meta_expressions)
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllMetaExpressionsError",
            error,
            fetchAllMetaExpressionsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete MetaExpression Action dispatches action creators to redux store and makes api calls to delete a metaExpression by id
 * @param {String} metaExpressionId MetaExpression id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteMetaExpressionAction: TDeleteMetaExpressionActionFunc = (
  actionName,
  metaExpressionId
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteMetaExpressionBeginActionCreator());
    return deleteMetaExpressionApiCall(actionName, metaExpressionId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteMetaExpressionSuccessActionCreator(metaExpressionId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.META_EXPRESSION
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteMetaExpressionError",
          error,
          deleteMetaExpressionFailureActionCreator,
          currLang
        );
      });
  };
};

export const resetMetaExpressionsAction: TResetMetaExpressionActionFunc =
  () => {
    return (dispatch) => {
      dispatch(resetMetaExpressionSuccessActionCreator());
    };
  };

export type TComputeMetaExpressionActionFunc = (
  actionName: string,
  metaExpresions: string[],
  options: IMetaExpressionOptions
) => IDispatchAndGetState<any>;
export type ICreateMetaExpressionActionFunc = (
  actionName: string,
  metaExpressionId: IMetaExpression
) => IDispatchAndGetState<any>;
export type TUpdateMetaExpressionActionFunc = (
  actionName: string,
  metaExpressionId: IMetaExpression
) => IDispatchAndGetState<any>;
export type TFetchAllMetaExpressionsActionFunc =
  () => IDispatchAndGetState<void>;
export type TDeleteMetaExpressionActionFunc = (
  actionName: string,
  metaExpressionId: string
) => IDispatchAndGetState<void>;
export type TResetMetaExpressionActionFunc = () => (
  dispatch: Dispatch<any>
) => void;
export interface IMetaExpressionActions {
  createEditMetaExpressionAction: ICreateMetaExpressionActionFunc;
  fetchAllMetaExpressionsAction: TFetchAllMetaExpressionsActionFunc;
  deleteMetaExpressionAction: TDeleteMetaExpressionActionFunc;
  resetMetaExpressionsAction: TResetMetaExpressionActionFunc;
}

const actions: IMetaExpressionActions = {
  createEditMetaExpressionAction: createMetaExpressionAction,
  fetchAllMetaExpressionsAction: fetchAllMetaExpressionsAction,
  deleteMetaExpressionAction: deleteMetaExpressionAction,
  resetMetaExpressionsAction: resetMetaExpressionsAction,
};

export default actions;
