import { IClient } from "fieldpro-tools";

import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import { TViewMode } from "model/application/modal/CreateEditModal";

export interface IOrdersManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (
    value: IClient["is_fieldpro_connect_enabled"] | undefined,
    name: string
  ) => void;
}
function OrdersManagement({
  client,
  onChange,
  viewMode,
}: Readonly<IOrdersManagement>) {
  const defaultValue = client.is_fieldpro_connect_enabled;

  return (
    <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
      <InputBoolean
        defaultValue={defaultValue}
        onChange={onChange}
        viewMode={viewMode}
        lang={{
          title: "Enable Fieldpro Connect",
        }}
        name="is_fieldpro_connect_enabled"
        dataTestId="is_fieldpro_connect_enabled"
      />
    </FormFieldBackground>
  );
}

export default OrdersManagement;
