import { Box, Button, makeStyles } from "@material-ui/core";
import FileDownload from "@mui/icons-material/FileDownload";

import { BasicCloseIcon } from "components/Dialog/BasicDialog";
import { downloadSinglePicture } from "containers/pictures/utils";
import { useTranslations } from "hooks";

const useStyles = makeStyles({
  rightContainerHeader: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "10px",
  },
  button: {
    textTransform: "capitalize",
  },
});

interface IPictureModalSidebarHeader {
  url?: string;
  handleClose: () => void;
}

const PictureSidebarHeader = ({
  handleClose,
  url,
}: IPictureModalSidebarHeader) => {
  const classes = useStyles();
  const lang = useTranslations();
  return (
    <Box className={classes.rightContainerHeader}>
      {url && (
        <Button
          startIcon={<FileDownload />}
          color="secondary"
          className={classes.button}
          onClick={() => downloadSinglePicture(url)}
          data-testid="download-button"
        >
          {lang.actions.download}
        </Button>
      )}
      <BasicCloseIcon
        style={{ height: "40px", width: "40px" }}
        filled={true}
        onClick={handleClose}
      />
    </Box>
  );
};

export default PictureSidebarHeader;
