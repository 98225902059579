import { IFormState } from "hooks/useFormState";

export const shouldMarkError = (
  field: string,
  formStateHook?: IFormState<any>
) => {
  const hasError = formStateHook && formStateHook.errors?.[field];
  return hasError;
};

export const shouldMarkWarning = (
  field: string,
  formStateHook?: IFormState<any>
) => {
  const hasWarnings = formStateHook && formStateHook.warnings?.[field];
  return hasWarnings;
};
