import { FormHelperText } from "@material-ui/core";

const WarningMessage = ({ warning }: { warning?: string }) => (
  <FormHelperText
    style={{
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "1.25",
      color: "#fbc715",
    }}
  >
    <span className="material-icons-outlined md-18">warning</span>
    {warning}
  </FormHelperText>
);
export default WarningMessage;
