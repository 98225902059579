import { IWebhookExpression } from "./reducer";

const initialState: IWebhookExpression = {
  webhooks: [],
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
};

export default initialState;
