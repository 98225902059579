import { Theme } from "@material-ui/core";
export default (theme: Theme) => ({
  FilterContainer: {
    height: "70px",
    background: "#EEE",
  },
  Title: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "10px",
  },
  CustomActionButton: {
    marginRight: "5px",
  },
  ScreenTitle: {
    color: theme.palette.primary.main,
    float: "left",
    padding: "10px",
    paddingTop: "20px",
    fontSize: "20px",
    fontWeight: "500",
    maxWidth: "300px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  BackButtonContainer: {},
  DropdownButtonContainer: {
    paddingRight: "10px",
  },
  FilterButton: {
    margin: "10px",
    float: "left",
    padding: "0px",
  },
  FilterElement: {
    marginTop: "-10px",
    float: "left",
  },
  FilterElementLabel: {
    "text-align": "center",
  },
  FilterElementChip: {
    display: "flex",
  },
  FilterCloseButton: {
    float: "right",
    background: theme.palette.secondary.main,
    color: "white",
    width: "25px",
    //marginLeft: "-10px",
    marginTop: "-10px",
    borderRadius: "45px",
    textAlign: "center",
    height: "25px",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.secondary.light,
    },
  },
  MoreButton: {
    background: `${theme.palette.secondary.main} !important`,
    color: "white !important",
    fontWeight: "500 !important",
    marginLeft: "10px",
  },
  Dropdown: {
    margin: "0 0.7px 0 0",
    padding: "0px",
    fontSize: "16px",
    lineHeight: "1.25",
    color: theme.palette.primary.main,
  },
  DropdownWithValue: {
    margin: "0 0.7px 0 0",
    padding: "0px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.25",
    color: theme.palette.primary.main,
  },
  spanCaret: {
    fontWeight: "16px",
  },
  DropdownItemHide: {
    display: "none",
  },
  DropdownItemShow: {
    display: "block",
    maxHeight: "400px",
    overflow: "auto",
    zIndex: 1111,
    height: "auto",
    overflowX: "hidden",
  },
});
