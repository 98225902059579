import { FunctionComponent } from "react";

import { Box, Button } from "@material-ui/core";

import { OffWhite, secondaryColor } from "assets/colors";
import useTranslations from "hooks/useTranslations";

import BasicDialog, { IBasicDialog } from "../BasicDialog/BasicDialog";
import BasicDialogActions from "../BasicDialog/BasicDialogActions";
import BasicDialogContent from "../BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../BasicDialog/BasicDialogTitle";
import BasicCloseIcon from "../BasicDialog/Icons/BasicCloseIcon";

export interface IConfirmationDialog extends IBasicDialog {
  open: boolean;
  title: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: (args: any) => void;
  onCancel?: (args: any) => void;
  children: any;
  onClose: () => void;
  maxWidth?: string | number;
  minWidth?: string | number;
  disabled?: boolean;
}

const ConfirmationDialog: FunctionComponent<IConfirmationDialog> = ({
  open,
  title,
  children,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  onCancel = onClose,
  disabled,
  maxWidth,
  minWidth = "600px", // /!\ minWidth should not be bigger than maxWidth...
  ...otherProps
}) => {
  const lang = useTranslations();

  return (
    <BasicDialog
      open={open}
      minWidth={minWidth}
      maxWidth={maxWidth}
      {...otherProps}
    >
      <BasicDialogTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{title}</span>
          <BasicCloseIcon onClick={onClose} />
        </div>
      </BasicDialogTitle>

      <BasicDialogContent>
        <Box padding="8px 0 40px">{children}</Box>
      </BasicDialogContent>

      <BasicDialogActions>
        <Button
          style={{
            background: OffWhite,
            color: secondaryColor,
            border: `1px solid ${secondaryColor}`,
            padding: "4px 16px",
          }}
          onClick={onCancel}
        >
          {cancelText || lang.modal.cancel}
        </Button>

        <Button
          onClick={onConfirm}
          color="secondary"
          variant="contained"
          disabled={disabled}
          disableElevation
          style={{
            marginLeft: "16px",
            padding: "4px 16px",
          }}
          data-testid="dialog-confirm-button"
        >
          {confirmText || lang.actions.create}
        </Button>
      </BasicDialogActions>
    </BasicDialog>
  );
};

export default ConfirmationDialog;
