import { ComponentProps } from "react";

//this component is identical to the <img> native tag
// it is used to have more control over the image tag specially in tests

type TImgProps = Omit<ComponentProps<"img">, "ref">; // react cant pass ref to function components
export interface ImageProps extends TImgProps {
  imageRef?: React.Ref<HTMLImageElement>;
}

function Image({ imageRef, ...props }: ImageProps) {
  return <img {...props} ref={imageRef} />;
}

export default Image;
