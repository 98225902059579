import { Box, BoxProps, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  InputEmptyContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#979797",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
});

interface IInputEmptyContainer extends BoxProps {
  children?: React.ReactNode;
}

const InputEmptyContainer = ({
  children,
  className,
  ...rest
}: IInputEmptyContainer) => {
  const classes = useStyles();
  return (
    <Box
      className={classNames(classes.InputEmptyContainer, className)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default InputEmptyContainer;
