import { IListSchema } from "fieldpro-tools/dist/src/types";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { LIST_SCOPE } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

export const getErrorMessagesForItem: TValidateFunction<IListItem> = ({
  attributes,
  lang,
  additionnalProps,
}) => {
  const { listScope } = additionnalProps as {
    listScope: LIST_SCOPE;
    schema: IListSchema[];
  };
  const { _owners } = attributes;
  const result: any = {};
  if (listScope !== LIST_SCOPE.GLOBAL) {
    if (!_owners || _owners.length === 0)
      result["_owners"] = lang.components.inputErrors.mandatoryField;
  }
  if (Object.keys(result).length === 0) return {};
  return result;
};
