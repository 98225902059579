import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import { lightgray } from "assets/colors";
import useTranslations from "hooks/useTranslations";

export interface IDialogPlaceHolderProps {
  placeholderHeight?: number;
  placeholderStyles?: React.CSSProperties;
  placeholderText?: string;
  placeholderIcon?: JSX.Element;
}
function DialogPlaceHolder({
  placeholderHeight,
  placeholderStyles,
  placeholderText,
  placeholderIcon,
}: IDialogPlaceHolderProps) {
  const lang = useTranslations();
  const nothingToDisplay = lang.genericTerms.content.nothing;
  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      style={{
        height: placeholderHeight ? placeholderHeight : 250,
        ...(placeholderStyles && {
          ...placeholderStyles,
        }),
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent="center"
        justifyItems={"center"}
        alignItems="center"
      >
        {placeholderIcon ? (
          <placeholderIcon.type
            {...placeholderIcon.props}
            style={{
              ...(placeholderIcon.props.style && {
                ...placeholderIcon.props.style,
              }),
              fontSize: "50px",
              color: lightgray,
            }}
          />
        ) : null}
        <Typography style={{ color: lightgray }}>
          {placeholderText ? placeholderText : nothingToDisplay}
        </Typography>
      </Box>
    </Grid>
  );
}

export default DialogPlaceHolder;
