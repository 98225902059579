export enum LevelNotification {
  Warning = "WARNING",
}

export enum TypeNotification {
  PartialFetchListItems = "PartialFetchListItems",
}

interface IAppNotification {
  level: LevelNotification;
  type: TypeNotification;
  id: string;
  label: string;
}

export default IAppNotification;
