import { ILogDetail } from "fieldpro-tools";
import _ from "lodash";

/**
 * SENTRY TAG KEYS
 *
 * They are used to display chips in the UI and can be filtered upon
 */
const sentryTagKeys = ["client_id", "source", "dbname"] as const;

// Union type "client_id" | "source" | ... from above array
type TSentryTagKeysUnion = (typeof sentryTagKeys)[number];

// This type is here to make sure the keys we use as tags in Sentry are valid keys from ILogDetail
type SENTRY_TAG_KEY = keyof Pick<ILogDetail, TSentryTagKeysUnion>;

export const SENTRY_TAG_KEYS: Readonly<SENTRY_TAG_KEY[]> = sentryTagKeys;

/**
 * TAGS
 */

export const getSentryTags = (logDetail?: ILogDetail) => {
  if (!logDetail) {
    return undefined;
  }
  const tags = _.pick(logDetail, SENTRY_TAG_KEYS);

  const tagsWithoutNullOrUndefinedValues = _.omitBy(tags, _.isNil);
  if (_.isEmpty(tagsWithoutNullOrUndefinedValues)) {
    return undefined;
  }

  return tags;
};
