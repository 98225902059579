/**
 * @notes: TableOptimization action types
 */
export const CREATE_TABLEOPTIMIZATION_BEGIN = "CREATE_TABLEOPTIMIZATION_BEGIN";
export const CREATE_TABLEOPTIMIZATION_SUCCESS =
  "CREATE_TABLEOPTIMIZATION_SUCCESS";
export const CREATE_TABLEOPTIMIZATION_FAILURE =
  "CREATE_TABLEOPTIMIZATION_FAILURE";

export const FETCH_ALL_TABLEOPTIMIZATIONS_BEGIN =
  "FETCH_ALL_TABLEOPTIMIZATIONS_BEGIN";
export const FETCH_ALL_TABLEOPTIMIZATIONS_SUCCESS =
  "FETCH_ALL_TABLEOPTIMIZATIONS_SUCCESS";
export const FETCH_ALL_TABLEOPTIMIZATIONS_FAILURE =
  "FETCH_ALL_TABLEOPTIMIZATIONS_FAILURE";

export const DELETE_TABLEOPTIMIZATION_BEGIN = "DELETE_TABLEOPTIMIZATION_BEGIN";
export const DELETE_TABLEOPTIMIZATION_SUCCESS =
  "DELETE_TABLEOPTIMIZATION_SUCCESS";
export const DELETE_TABLEOPTIMIZATION_FAILURE =
  "DELETE_TABLEOPTIMIZATION_FAILURE";
