import _ from "lodash";

import { TCustomerFeature } from "../subcategories/map/CustomersMap/features/customers";

const getColorForCluster = (points: TCustomerFeature[]) => {
  const colors = _.map(points, (customerPoint) => {
    return customerPoint.properties.color;
  });

  const result = {};
  _.forEach(colors, (color: string) => {
    if (result[color]) {
      result[color] = result[color] + 1;
    } else {
      result[color] = 1;
    }
  });

  let previousValue = 0;
  let backgroundColor = "conic-gradient(";
  _.keys(result).forEach((color: string) => {
    if (previousValue !== 0) {
      backgroundColor = backgroundColor + ", ";
    }
    backgroundColor =
      backgroundColor +
      `${color} ${Math.floor((previousValue / colors.length) * 360)}deg, `;
    previousValue = previousValue + result[color];
    backgroundColor =
      backgroundColor +
      `${color} ${Math.floor((previousValue / colors.length) * 360)}deg`;
  });
  backgroundColor = backgroundColor + ")";
  return backgroundColor;
};

export default getColorForCluster;
