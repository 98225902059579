import { IIntegrationState } from "./reducer";

const initialState: IIntegrationState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isFetchingJobHistory: false,
  selectedIntegration: null,
  integrations: [],
  errors: [],
};

export default initialState;
