import _ from "lodash";

import { TMatrixData } from "components/Dialog/MatrixDialog/model";
import {
  TABLE_COLUMN_TYPE,
  TColumnType,
  TRowType,
} from "components/Table/model";
import { IFileFieldUpload, TFileField } from "model/application/DynamicObjects";
import TPicture, { PICTURE_ACTIONS } from "model/entities/Picture";
import {
  CUSTOM_FIELD_TYPE,
  IActivityReportInTable,
  IQuestion,
} from "model/entities/Workflow";

export const getGalleryPictures = (
  questions: IQuestion[],
  report: IActivityReportInTable
) => {
  const galleryPictures: TPicture[] = [];

  _.each(questions, (question) => {
    if (!report[question.tag]) {
      return;
    }

    if (question.type === CUSTOM_FIELD_TYPE.PICTURE) {
      const answer = report[question.tag] as TFileField;
      galleryPictures.push({
        label: question.question_text,
        id: (answer as IFileFieldUpload)?.upload_id,
        url: answer.url,
        tag: question.question_text ?? question.tag,
        user_id: report["_completed_by"],
        action: PICTURE_ACTIONS.UPLOAD_FILE_FOR_SUBMISSION,
        activity_id: report["_activity_id"],
        activity_report_id: report["_id"],
        submission_id: report["_submission_id"],
        timestamp: report._completed_at?.toISOString(),
        workflow_id: report["_workflow_id"],
        workflow_report_id: report["_workflow_report_id"],
      });
      return;
    }

    if (question.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST) {
      const answer = report[question.tag] as TMatrixData;

      _.each(answer.rows, (row) => {
        const pictures = getMatrixGalleryPictures(
          question.question_text,
          answer.columns,
          row
        );

        galleryPictures.push(...pictures);
        return;
      });
    }
  });

  return galleryPictures;
};

const getMatrixGalleryPictures = (
  matrixLabel: string,
  columns: TColumnType[],
  row: TRowType
) => {
  const galleryPictures: TPicture[] = [];
  _.each(columns, (column) => {
    if (column.type !== TABLE_COLUMN_TYPE.PICTURE) {
      return;
    }

    const answer = row[column.name];
    if (!answer) {
      return;
    }

    galleryPictures.push({
      label: matrixLabel,
      sublabel: column.label,
      id: (answer as IFileFieldUpload)?.upload_id,
      url: answer.url,
      tag: matrixLabel,
      user_id: row["_completed_by"],
      timestamp: row["_completed_at"],
    });
  });

  return galleryPictures;
};
