export const FETCH_ALL_TRIGGERS_BEGIN = "FETCH_ALL_TRIGGERS_BEGIN";
export const FETCH_ALL_TRIGGERS_SUCCESS = "FETCH_ALL_TRIGGERS_SUCCESS";
export const FETCH_ALL_TRIGGERS_FAILURE = "FETCH_ALL_TRIGGERS_FAILURE";
export const DELETE_TRIGGER_BEGIN = "DELETE_TRIGGER_BEGIN";
export const DELETE_TRIGGER_SUCCESS = "DELETE_TRIGGER_SUCCESS";
export const DELETE_TRIGGER_FAILURE = "DELETE_TRIGGER_FAILURE";
export const CREATE_TRIGGER_BEGIN = "CREATE_TRIGGER_BEGIN";
export const CREATE_TRIGGER_SUCCESS = "CREATE_TRIGGER_SUCCESS";
export const CREATE_TRIGGER_FAILURE = "CREATE_TRIGGER_FAILURE";
export const UPDATE_TRIGGER_BEGIN = "UPDATE_TRIGGER_BEGIN";
export const UPDATE_TRIGGER_SUCCESS = "UPDATE_TRIGGER_SUCCESS";
export const UPDATE_TRIGGER_FAILURE = "UPDATE_TRIGGER_FAILURE";
