import { Button, makeStyles, Tooltip, TooltipProps } from "@material-ui/core";

const styles = {
  CustomInputTooltip: {
    verticalAlign: "bottom",
  },
} as const;

const useStyles = makeStyles(styles);

const CustomInputTooltip = ({
  dataTestid,
  text,
  placement,
}: {
  dataTestid?: string;
  text: string;
  placement?: TooltipProps["placement"];
}) => {
  const classes = useStyles();

  const convertTextToHTML = (text: string) => {
    return { __html: text };
  };

  return (
    <Tooltip
      className={classes.CustomInputTooltip}
      title={<div dangerouslySetInnerHTML={convertTextToHTML(text)} />}
      placement={placement}
      data-testid={dataTestid}
    >
      <Button
        style={{
          background: "none",
          padding: 0,
          margin: 0,
          minWidth: 0,
          color: "inherit",
        }}
      >
        <span className="material-icons-outlined md-18">help_outline</span>
      </Button>
    </Tooltip>
  );
};

export default CustomInputTooltip;
