import { useState } from "react";

import { IDSource } from "fieldpro-tools";
import _ from "lodash";

import { IInputOption } from "components/Input/BaseOptionsContainer";

interface IUseOptionsHandlers<TValue> {
  options: IInputOption<TValue>[];
  onChangeOptions: (options: IInputOption<TValue>[]) => void;
  defaultValue: TValue;
}

const useOptionsHandlers = <TValue>({
  options,
  onChangeOptions,
  defaultValue,
}: IUseOptionsHandlers<TValue>) => {
  const [optionToCreate, setOptionToCreate] =
    useState<IInputOption<TValue> | null>(null);

  const [optionToEdit, setOptionToEdit] = useState<IInputOption<TValue> | null>(
    null
  );

  /**
   * add/delete handlers
   */

  const onOpenAddOption = () => {
    setOptionToCreate({
      key: IDSource(),
      label: "",
      value: defaultValue,
    });
  };

  const onDeleteOption = (option: IInputOption<TValue>) => {
    const newOptions = _.reject(options, { key: option.key });
    onChangeOptions(newOptions);
  };

  /**
   * onChange handlers
   */
  const onChangeValueToCreate = (value: TValue) => {
    setOptionToCreate((option) => {
      return { ...option, value } as IInputOption<TValue>;
    });
  };

  const onChangeValueToEdit = (value: TValue) => {
    setOptionToEdit((option) => {
      return { ...option, value } as IInputOption<TValue>;
    });
  };

  /**
   * onConfirm handlers
   */

  // NOTE 1A: Use these when optionToCreate's state is handled in this hook
  const onConfirmOptionToCreate = () => {
    if (!optionToCreate) {
      return;
    }
    const newOptions = [...options, optionToCreate];

    onChangeOptions(newOptions);
    setOptionToCreate(null);
  };

  // NOTE 2A: Use this when the option to create is passed from outside
  const onConfirmAddOption = (optionToCreate: IInputOption<TValue>) => {
    const newOptions = [...options, optionToCreate];
    onChangeOptions(newOptions);
    setOptionToCreate(null);
  };

  // NOTE 1B: Use these when optionToEdit's state is handled in this hook
  const onConfirmOptionToEdit = () => {
    if (!optionToEdit) {
      return;
    }
    const newOptions = _.map(options, (option) => {
      return option.key === optionToEdit.key ? optionToEdit : option;
    });

    onChangeOptions(newOptions);
    setOptionToEdit(null);
  };

  // NOTE 2B: Use this when the option to edit is passed from outside
  const onConfirmEditOption = (optionToEdit: IInputOption<TValue>) => {
    const newOptions = _.map(options, (option) => {
      return option.key === optionToEdit.key ? optionToEdit : option;
    });
    onChangeOptions(newOptions);
    setOptionToEdit(null);
  };

  return {
    optionToCreate,
    optionToEdit,
    onDeleteOption,
    onOpenAddOption,
    setOptionToCreate,
    setOptionToEdit,
    onChangeValueToCreate,
    onChangeValueToEdit,
    onConfirmOptionToCreate,
    onConfirmOptionToEdit,
    onConfirmAddOption,
    onConfirmEditOption,
  };
};

export default useOptionsHandlers;
