import _ from "lodash";

import { IInputSelect } from "components/Input/InputSelect/InputSelect";
import { IActivity } from "model/entities/Workflow";

export function getActivitiesAsOptions(
  activities: IActivity[]
): IInputSelect["options"] {
  return _.map(activities, (act) => {
    return {
      key: act.id,
      label: act.name,
    };
  });
}
