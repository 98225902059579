import { Theme } from "@material-ui/core";

export const drawerWidth = "240px";
export const closedDrawerWidth = "60px";

export default (theme: Theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.main,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: closedDrawerWidth,
    background: theme.palette.primary.main,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  SidebarItemIconActive: {
    color: "#FFF",
    minWidth: "unset",
    width: "45px",
    paddingTop: "3px",
  },
  SubcategoriesScroller: {
    height: "fit-content",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "330px",
    marginRight: "12px",
  },
  SidebarItemIcon: {
    color: "#FFF9",
    minWidth: "unset",
    width: "45px",
    paddingTop: "3px",
  },
  SidebarItemTextActive: {
    color: "#FFF",
    paddingBottom: "2px",
  },
  SidebarItemText: {
    color: "#FFF9",
    paddingBottom: "2px",
  },
  NavLinkImage: {
    background: "white",
    padding: "5px",
    margin: "5px",
    borderRadius: "100px",
    width: "calc(100% - 10px)",
  },
  ExpansionPanelRoot: {
    background: "none",
    boxShadow: "none",
    borderLeft: "2px solid transparent",
    borderRadius: "unset !important",
  },
  ExpansionPanelRootActive: {
    background: "none",
    boxShadow: "none",
    borderLeft: "2px solid white !important",
    borderRadius: "unset !important",
  },
  ExpansionPanelSummaryContent: {
    margin: "0px !important",
    padding: "0px",
  },
  ExpansionPanelSummaryRoot: {
    justifyContent: "start",
    padding: "0px",
  },
  AccordionDetailsContent: {
    margin: "0px",
    padding: "0px",
  },
  AccordionDetailsRoot: {
    justifyContent: "start",
    padding: "0px",
  },
  freezeSidebar: {
    border: "none",
    textAlign: "center",
    color: "white",
    paddingBottom: "14px",
  },
  wrapIconOpen: {
    alignItems: "center",
    display: "flex",
    width: "89px",
    height: "16px",
    fontFamily: "BasierCircle",
    fontSize: "12px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "0.5px",
    color: "var(--light-blue-grey)",
    cursor: "pointer",
    paddingLeft: "25%",
  },
  Icon: {
    width: "15px",
    height: "15px",
    objectFit: "contain",
    background: "#f5f5f5",
    color: theme.palette.primary.main,
    borderRadius: "50%",
    cursor: "pointer",
  },
  ListItemIcon: {
    paddingBottom: "5px",
    fontSize: "25px",
  },
});
