import React, { useEffect } from "react";

import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import { checkUpdateAction } from "containers/authentication/redux/actions";
import { useActions, useTranslations } from "hooks";
import { getAppVersion } from "utils/utils";

interface IUpdateMonitorProps {
  children?: React.ReactNode;
}

const UpdateMonitor: React.FC<IUpdateMonitorProps> = ({ children }) => {
  const lang = useTranslations();
  const langKey =
    lang.components.updateMonitor.createEditModal.updateMonitor.customMessage;
  const checkUpdateFunc = useActions(checkUpdateAction);
  const [hasUpdate, setHasUpdate] = React.useState(false);
  useEffect(() => {
    handleCheckUpdate();
    const interval = setInterval(async () => {
      handleCheckUpdate();
    }, 3600000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleCheckUpdate() {
    try {
      const isOutofDate = await checkUpdateFunc(getAppVersion());
      setHasUpdate(isOutofDate);
    } catch (error) {
      setHasUpdate(false);
    }
  }
  return (
    <>
      <CustomDialogWarning
        lang={{
          description: langKey.newVersionAvailableDescription,
          title: langKey.newVersionAvailableTitle,
        }}
        rootLang={lang}
        isOpen={hasUpdate && process.env.NODE_ENV != "development"}
        confirmBtnText={lang.actions.reload}
        onConfirmAction={() => {
          window.location.reload();
        }}
        hideCancelButton
        onClose={() => {
          setHasUpdate(false);
        }}
      />

      {children}
    </>
  );
};

export default UpdateMonitor;
