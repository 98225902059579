import { styled } from "@material-ui/core";

/**
 * TODO: this component is meant to be temporary
 * The design system's font is BasierCircle but BasierCircle-bold is not yet available in the project
 */
const HelveticaBold = styled("div")(() => ({
  fontFamily: "Helvetica",
  fontWeight: "bold",
}));

export default HelveticaBold;
