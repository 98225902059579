import _ from "lodash";

export const formatMaxTitle = (value: string) => {
  if (_.size(value) < 4) {
    return `${value}`;
  } else if (_.size(value) >= 4 && _.size(value) < 6) {
    return `${_.toNumber(value) / 1000}K`;
  } else {
    return `${_.toNumber(value) / 1000000}M`;
  }
};

export const computePercentage = (value: number, total: number) => {
  return _.ceil(((value || 0) / total) * 100, 3);
};
