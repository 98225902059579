import { IActivity, IActivityReportInTable } from "model/entities/Workflow";

import prepareCustomFieldDataForBackend from "./prepareCustomFieldDataForBackend";

const prepareReportStateForBackend = (
  report: IActivityReportInTable,
  activity: IActivity
) => {
  return Object.fromEntries(
    Object.entries(report).map(([key, value]) => [
      key,
      prepareCustomFieldDataForBackend({
        question: activity.questionnaire.questions.find((q) => q.tag == key),
        answer: value,
        simpleMatrixFormat: true,
      }),
    ])
  );
};

export default prepareReportStateForBackend;
