/**
 * @notes: Authentication API, handles authentication calls with the API
 */

import { BUSINESS_ERROR, IClient } from "fieldpro-tools";

import apiService from "api/apiService";
import { IServerResponse } from "redux/actions/appActions";

import { TBackendNewTrialUser } from "../utils/prepareNewTrialUserForBackend";
import * as urls from "./endpoints";

export interface ILoginUserApiCall {
  actionName: string;
  id: string;
  token: string;
  name: string;
  picture: string;
  created_at: string;
  client: IClient;
  is_temporary?: boolean;
}

interface ISignUpUserApiCall {
  actionName: string;
  id: string;
}

/**
 * SignUp user api function. This calls a helper function to handle api calls
 * This returns a Promise that will either be resolved or rejected
 * @param {String} actionName The action name
 * @param {INewTrialUser} info The new user's signup information
 * @returns {Promise}
 * */
export function SignUpUserApiCall(
  actionName: string,
  info: TBackendNewTrialUser
): Promise<IServerResponse<ISignUpUserApiCall>> {
  return apiService.post(urls.SIGNUP_ENDPOINT, { ...info }, actionName);
}

/**
 * Login user api function. This calls a helper function to handle api calls
 * This returns a Promise that will either be resolved or rejected
 * @param {String} email User email
 * @param {String} password Password that will posted to Smala API
 * @returns {Promise}
 * */
export function loginUserApiCall(
  actionName: string,
  email: string,
  password: string,
  clientId?: string
): Promise<IServerResponse<ILoginUserApiCall>> {
  return apiService.post(
    urls.LOGIN_ENDPOINT,
    { email, password, clientId },
    actionName
  );
}
/**
 * Login user api function. This calls a helper function to handle api calls
 * This returns a Promise that will either be resolved or rejected
 * @param {String} token token from google
 * @returns {Promise}
 * */
export function googleLoginUserApiCall(
  actionName: string,
  token: string,
  clientId?: string
): Promise<IServerResponse<ILoginUserApiCall>> {
  return apiService.post(
    urls.GOOGLE_LOGIN_ENDPOINT,
    { token, clientId },
    actionName
  );
}

/**
 * Logs out a user from the API
 * @return {Promise}
 * */
export function logoutUserApiCall() {
  return apiService.post(urls.LOGOUT_ENDPOINT, { token: null });
}

/**
 * Recover the password
 * @param {String} email User email
 * @param {String} token The token returned by reCAPTCHA
 * @return {Promise}
 * */
export function recoverPasswordApiCall(
  actionName: string,
  email: string,
  token: string
) {
  return apiService.post(
    urls.RECOVER_PASSWORD_ENDPOINT,
    { email, token },
    actionName
  );
}

/**
 * Change password
 * @param {String} password New password
 * @param {String} oldPassword Old password
 * @return {Promise}
 * */

export function changePasswordApiCall(
  actionName: string,
  password: string,
  oldPassword: string
) {
  return apiService.post(
    urls.CHANGE_PASSWORD_ENDPOINT,
    {
      password,
      old_password: oldPassword,
    },
    actionName
  );
}

interface ICheckUpdateApiCall {
  has_update: boolean;
}
/**
 * Check update for the current version in display
 * @param {String} version Version of the app
 * @returns {Promise}
 * */
export function checkUpdateApiCall(
  version: string
): Promise<IServerResponse<ICheckUpdateApiCall>> {
  if (process.env.REACT_APP_ENV === "local") {
    return new Promise((resolve) =>
      resolve({
        data: {
          code_response: BUSINESS_ERROR.SUCCESS,
          error_message: "",
          data: { has_update: false },
        },
      })
    );
  }
  return apiService.post(urls.CHECK_UPDATE_ENDPOINT, { version });
}
