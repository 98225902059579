import { IHttpRequestState } from "./reducer";

const initialState: IHttpRequestState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isRunning: false,
  isFetchingJobHistory: false,
  selectedHttpRequest: null,
  http_requests: [],
  errors: [],
};

export default initialState;
