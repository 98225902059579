import Moment from "moment";

import { IClient } from "model/entities/Client";
export function getTimesOfDay(_client: IClient, moment: typeof Moment) {
  const times: string[] = [];
  // eslint-disable-next-line prefer-const
  let { maxEventEndTime, minEventStartTime } = getCalendarWorkingHours(
    _client,
    moment
  );

  while (minEventStartTime.isSameOrBefore(maxEventEndTime)) {
    times.push(minEventStartTime.format("hA"));
    minEventStartTime = minEventStartTime.add(1, "hour");
  }

  return times;
}

export function getCalendarWorkingHours(
  _client: IClient,
  moment: typeof Moment
) {
  return {
    minEventStartTime: moment().startOf("day").hour(0).minute(0),
    maxEventEndTime: moment().endOf("day").hour(23).minute(59),
  };
}

export function advanceDateToToday(
  date: Date | Moment.Moment,
  moment: typeof Moment
) {
  const dateMoment = moment(date).clone();

  return moment({
    hour: dateMoment.hour(),
    minute: dateMoment.minute(),
  });
}
