import { Black } from "assets/colors";

export default () => ({
  ImageContainer: {
    margin: "8px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  NavLinkImage: {
    height: "32px",
    width: "auto",
    "&:hover": {
      transition: "transform 0.3s ease",
      transform: "scale(1.2)",
    },
  },
  ClientLogoAndName: {
    display: "flex",
    height: "64px",
    zIndex: 1200,
    alignItems: "center",
    marginRight: "5px",
    paddingRight: "10px",
  },
  ClientName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    color: Black,
  },
  navLinks: {
    display: "flex",
    "justify-content": "flex-end",
    "align-items": "center",
    flex: "1",
  },
  actionWrapper: {
    margin: "0 24px",
    display: "flex",
    gap: "16px",
    "justify-content": "space-around",
  },
});
