/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import { IDashboard as IBEDashboard } from "fieldpro-tools/dist/src/types/dashboards";

import apiService from "api/apiService";
import { getBackendQuery } from "components/Filter/Filter.utils";
import { IDashboard } from "model/entities/Dashboard";
import { IServerResponse } from "redux/actions/appActions";

import { IKPI } from "./../../../../node_modules/fieldpro-tools/src/types/dashboards";
import { prepareDashboardForBackend } from "./utils";

export const DASHBOARDS_ENDPOINT = `dashboards`;

export type CreateDashboardFunc = (
  actionName: string,
  dashboard: IDashboard
) => Promise<IServerResponse<ICreateDashboardResponse>>;
export interface ICreateDashboardResponse extends IDashboard {}

export type UpdateDashboardFunc = (
  actionName: string,
  dashboard: IDashboard
) => Promise<IServerResponse<IUpdateDashboardResponse>>;
export interface IUpdateDashboardResponse extends IDashboard {}

export type DeleteDashboardFunc = (
  actionName: string,
  dashboard_id: string
) => Promise<IServerResponse<IDeleteDashboardResponse>>;
export interface IDeleteDashboardResponse {}

export type ArchiveDashboardsFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<IArchiveDashboardsResponse>>;
export interface IArchiveDashboardsResponse {}

export type RestoreDashboardsFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<IRestoreDashboardsResponse>>;
export interface IRestoreDashboardsResponse {}

export type FetchDashboardsFunc = () => Promise<
  IServerResponse<IFetchDashboardsResponse>
>;
export interface IFetchDashboardsResponse {
  dashboards: IBEDashboard[];
}

export type FetchFrequentlyViewedDashboardsFunc = () => Promise<
  IServerResponse<IFetchFrequentlyViewedDashboardsResponse>
>;

export interface IFetchFrequentlyViewedDashboardsResponse {
  dashboardIds: string[];
}

// FIXME: change the "any" type for query to a more specific one
export type ComputeDashboardFunc = (
  actionName: string,
  dashboard_id: string,
  query: any,
  degradedModeKpis?: string[]
) => Promise<IServerResponse<IComputeDashboardResponse>>;
export interface IComputeDashboardResponse {
  kpis: { id: string; data: string | any[] | { error: any } }[]; // FIXME: change the "any" type for query to a more specific one
  raw_data: any;
}
export interface IComputeKpiResponse {
  id: string;
  data: string | any[] | { error: any };
}

export type ComputeKpiFunc = (
  actionName: string,
  kpi: IKPI,
  dashboardId: string | undefined,
  query: any
) => Promise<IServerResponse<IComputeKpiResponse>>;
/**
 * Creates a new dashboard
 * @param {Object} dashboard Dashboard details
 */
export const createDashboardApiCall: CreateDashboardFunc = (
  actionName: string,
  dashboard
) => {
  const payload = prepareDashboardForBackend(dashboard);
  return apiService.post(`${DASHBOARDS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted dashboard
 * @param {Object} dashboard Detail of the new dashboard
 */
export const updateDashboardApiCall: UpdateDashboardFunc = (
  actionName: string,
  dashboard
) => {
  const payload = prepareDashboardForBackend(dashboard);
  return apiService.patch(
    `${DASHBOARDS_ENDPOINT}/${dashboard.id}`,
    payload,
    actionName
  );
};

/**
 * Delete dashboard API call makes a POST request to delete a single dashboard
 * @param {String} dashboard_id Id of the dashboard to delete
 * @return {Promise}
 * */
export const deleteDashboardApiCall: DeleteDashboardFunc = (
  actionName: string,
  dashboard_id
) => {
  return apiService.delete(
    `${DASHBOARDS_ENDPOINT}/${dashboard_id}`,
    {},
    actionName
  );
};

/**
 * Archive dashboard API call makes a POST request to archive dashboards
 * @param {String} ids Ids of the dashboards to archive
 * @return {Promise}
 * */
export const archiveDashboardsApiCall: ArchiveDashboardsFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(
    `${DASHBOARDS_ENDPOINT}/archive`,
    {
      ids,
    },
    actionName
  );
};

/**
 * Restore dashboard API call makes a POST request to restore dashboards
 * @param {String} ids Ids of the dashboards to restore
 * @return {Promise}
 * */
export const restoreDashboardsApiCall: RestoreDashboardsFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(
    `${DASHBOARDS_ENDPOINT}/restore`,
    {
      ids,
    },
    actionName
  );
};

/**
 * Api call to fetch all ${DASHBOARDS_ENDPOINT}
 */
export const fetchDashboardsApiCall: FetchDashboardsFunc = () => {
  return apiService.get(`${DASHBOARDS_ENDPOINT}`);
};

export const fetchFrequentlyViewedDashboardsApiCall: FetchFrequentlyViewedDashboardsFunc =
  () => {
    return apiService.get(`${DASHBOARDS_ENDPOINT}/frequently_viewed`);
  };

type IDownloadKpiApiCall = {
  dashboardId: string;
  actionName: string;
  kpiId: string;
  query?: any;
};
export const downloadKpiApiCall = ({
  dashboardId,
  kpiId,
  query,
  actionName,
}: IDownloadKpiApiCall) => {
  const body = getBackendQuery(query);
  return apiService.post(
    `${DASHBOARDS_ENDPOINT}/${dashboardId}/kpis/${kpiId}/download`,
    body,
    actionName
  );
};

/**
 * Compute dashboard with its parameters
 * @param {String} dashboard_id Dashboard id
 * @param {Object} query Query object, containing typically "start_date", "end_date", "teams", "users"
 * @param {Array} degradedModeKpis List of kpis to send by email (optionnal)
 * @returns {Promise}
 */
export const computeDashboardsApiCall: ComputeDashboardFunc = (
  actionName: string,
  dashboard_id,
  query,
  degradedModeKpis
) => {
  const body = getBackendQuery(query);
  if (degradedModeKpis) body["degraded_mode_kpis"] = degradedModeKpis;
  return apiService.post(
    `${DASHBOARDS_ENDPOINT}/${dashboard_id}`,
    body,
    actionName,
    undefined,
    degradedModeKpis ? true : false
  );
};
export const computeKpiApiCall: ComputeKpiFunc = (
  actionName: string,
  kpi,
  dashboardId,
  query = {}
) => {
  const body = getBackendQuery(query);
  return apiService.post(
    `${DASHBOARDS_ENDPOINT}/compute_kpi`,
    { ...body, kpi, dashboard_id: dashboardId },
    actionName
  );
};
