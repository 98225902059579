import { INotification } from "model/entities/Job";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all notifications from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllNotifications = (state: IRootState) =>
  state.jobs.notifications.notifications;

/**
 * Selector to get all Notifications from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allNotificationsSelector = customSelectorCreator(
  [getAllNotifications],
  (allNotifications: INotification[]) => {
    // map all the notifications
    return allNotifications;
  }
);

/**
 * Gets the status of fetching a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingAllNotifications = (state: IRootState) =>
  state.jobs.notifications.isFetchingAll;

/**
 * Gets the status of creating a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingNotification = (state: IRootState) =>
  state.jobs.notifications.isCreating;

/**
 * Gets the status of updating a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingNotification = (state: IRootState) =>
  state.jobs.notifications.isUpdating;

/**
 * Gets the status of deleting a notification
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingNotification = (state: IRootState) =>
  state.jobs.notifications.isDeleting;
