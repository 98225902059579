import { Component } from "react";

import { IClient } from "fieldpro-tools";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { getSelectedClient } from "containers/clients/redux/selectors";
import TLang from "model/application/Lang";
import IRootState from "redux/store/model";

import CustomNotiStack from "../../components/Snackbars/CustomNotiStack";
import { firstAppLoginAction } from "../../redux/actionCreators/appActionCreator";
import { getIsFirstLoginSelector } from "../../redux/selectors/appSelector";
import {
  allNotificationsSelector,
  recentNotificationSelector,
} from "./selectors";

interface INotificationsContainerProps {
  allNotifications: any[];
  recentNotification: any;
  isFirstLogin: boolean;
  lang: TLang;
  currentClient: IClient;
}
interface INotificationsContainerState {
  allNotifications: any[];
  recentNotification: any;
  isFirstLogin: boolean;
}

export class NotificationsContainer extends Component<
  INotificationsContainerProps,
  INotificationsContainerState
> {
  constructor(props: INotificationsContainerProps) {
    super(props);
    this.state = {
      allNotifications: [],
      recentNotification: null,
      isFirstLogin: false,
    };
  }

  /**
   * Make any necessary updates when we receive props. These props will ideally come from the Redux Store
   * @param {Object} nextProps Next Properties received from the Redux Store state using the mapStateToProps function
   * @param {Object} prevState Previous State of the component
   * */
  static getDerivedStateFromProps(
    nextProps: INotificationsContainerProps,
    prevState: INotificationsContainerState
  ) {
    const { recentNotification, allNotifications, isFirstLogin } = nextProps;
    if (
      allNotifications !== prevState.allNotifications ||
      recentNotification !== prevState.recentNotification
    ) {
      return {
        allNotifications,
        recentNotification,
        isFirstLogin,
      };
    } else {
      return null;
    }
  }

  render() {
    const { lang, currentClient } = this.props;
    const { recentNotification } = this.state;
    if (
      !isEmpty(currentClient) &&
      currentClient.name.includes("E2E DO NOT DELETE")
    ) {
      return null;
    }
    return (
      !isEmpty(recentNotification) && (
        <CustomNotiStack lang={lang} notification={recentNotification} />
      )
    );
  }
}

/**
 * Maps state of redux store to props in this component
 * @param {Object} state Redux store state
 */
function mapStateToProps(state: IRootState) {
  return {
    allNotifications: allNotificationsSelector(state),
    recentNotification: recentNotificationSelector(state),
    isFirstLogin: getIsFirstLoginSelector(state),
    currentClient: getSelectedClient(state),
  };
}

/**
 * Maps the actions to props to be used in this container component
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    firstAppLogin: bindActionCreators(firstAppLoginAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
