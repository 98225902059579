/**
 * @notes: HTTP_REQUEST action types
 */
export const CREATE_HTTP_REQUEST_BEGIN = "CREATE_HTTP_REQUEST_BEGIN";
export const CREATE_HTTP_REQUEST_SUCCESS = "CREATE_HTTP_REQUEST_SUCCESS";
export const CREATE_HTTP_REQUEST_FAILURE = "CREATE_HTTP_REQUEST_FAILURE";

export const UPDATE_HTTP_REQUEST_BEGIN = "UPDATE_HTTP_REQUEST_BEGIN";
export const UPDATE_HTTP_REQUEST_SUCCESS = "UPDATE_HTTP_REQUEST_SUCCESS";
export const UPDATE_HTTP_REQUEST_FAILURE = "UPDATE_HTTP_REQUEST_FAILURE";

export const FETCH_ALL_HTTP_REQUESTS_BEGIN = "FETCH_ALL_HTTP_REQUESTS_BEGIN";
export const FETCH_ALL_HTTP_REQUESTS_SUCCESS =
  "FETCH_ALL_HTTP_REQUESTS_SUCCESS";
export const FETCH_ALL_HTTP_REQUESTS_FAILURE =
  "FETCH_ALL_HTTP_REQUESTS_FAILURE";

export const DELETE_HTTP_REQUEST_BEGIN = "DELETE_HTTP_REQUEST_BEGIN";
export const DELETE_HTTP_REQUEST_SUCCESS = "DELETE_HTTP_REQUEST_SUCCESS";
export const DELETE_HTTP_REQUEST_FAILURE = "DELETE_HTTP_REQUEST_FAILURE";

export const RUN_HTTP_REQUEST_JOB_BEGIN = "RUN_HTTP_REQUEST_JOB_BEGIN";
export const RUN_HTTP_REQUEST_JOB_SUCCESS = "RUN_HTTP_REQUEST_JOB_SUCCESS";
export const RUN_HTTP_REQUEST_JOB_FAILURE = "RUN_HTTP_REQUEST_JOB_FAILURE";

export const FETCH_JOB_HISTORY_BEGIN = "FETCH_JOB_HISTORY_BEGIN";
export const FETCH_JOB_HISTORY_SUCCESS = "FETCH_JOB_HISTORY_SUCCESS";
export const FETCH_JOB_HISTORY_FAILURE = "FETCH_JOB_HISTORY_FAILURE";
