import { ReactNode, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import _ from "lodash";

type TOnClick = (args: any) => void;

export interface ISplitButtonOption {
  label: ReactNode;
  ariaLabel?: string;
  onClick: TOnClick;
}

export interface ISplitButton {
  options: ISplitButtonOption[];
}
export const SplitButton = ({ options }: ISplitButton) => {
  const [open, setOpen] = useState(false);

  const onClickAndClose = (onClick?: TOnClick) => {
    return (args: any) => {
      if (onClick) {
        onClick(args);
      }
      setOpen(false);
    };
  };

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && _.includes(anchorRef.current, event.target)) {
      return;
    }

    setOpen(false);
  };

  const firstOption = _.first(options);
  const menuOptions = _.slice(options, 1, options.length);

  if (!firstOption) {
    return null;
  }

  if (_.isEmpty(menuOptions)) {
    return (
      <Button
        onClick={onClickAndClose(firstOption?.onClick)}
        variant="contained"
        disableElevation
        aria-label={firstOption.ariaLabel}
      >
        {firstOption.label}
      </Button>
    );
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          disableElevation
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={onClickAndClose(firstOption?.onClick)}>
            {firstOption.label}
          </Button>

          <Button
            style={{ padding: "4px 6px" }}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {menuOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={onClickAndClose(option.onClick)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default SplitButton;
