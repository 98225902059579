import { FunctionComponent } from "react";

import _ from "lodash";
import { useSelector } from "react-redux";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { getBillingType } from "containers/authentication/utils/clientStatus";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import { BILLING_TYPE, CLIENT_STATUS, IClient } from "model/entities/Client";
export interface ICustomBillingTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onInputChange: any;
  onSelectChange: any;
  shouldMarkError: any;
  should_create_stripe_id?: boolean;
}

export const CustomBillingTab: FunctionComponent<ICustomBillingTabProps> = ({
  id,
  viewMode = "CREATE",
  app_user_licenses,
  billing_owner_mail,
  stripe_customer_id,
  status,
  billing_type,
  onInputChange,
  onSelectChange,
  shouldMarkError,
  should_create_stripe_id,
}) => {
  const lang = useTranslations();
  const tabLang = lang.containers.clients.subCategories.clients.createEditModal;

  const client = useSelector(getSelectedClient);

  const disableNumberOfLicenses =
    getBillingType(client) === BILLING_TYPE.AUTOMATED;

  const title = tabLang.inputLicenceNb.title;
  const tooltip = disableNumberOfLicenses ? tabLang.inputLicenceNb.tooltip : "";

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputText
          key={`app_user_licenses-${id}`}
          name="app_user_licenses"
          defaultValue={app_user_licenses}
          error={shouldMarkError("app_user_licenses")}
          type={INPUT_TEXT_TYPE.INTEGER}
          lang={{ title, tooltip }}
          onChange={onInputChange}
          required={true}
          viewMode={viewMode}
          disabled={disableNumberOfLicenses}
        />
      </FormFieldBackground>

      <InputText
        defaultValue={billing_owner_mail}
        name="billing_owner_mail"
        type={INPUT_TEXT_TYPE.EMAIL}
        lang={tabLang.inputBillingOwnerMail}
        onChange={onInputChange}
        required={false}
        viewMode={viewMode}
      />

      <FormFieldBackground viewMode={viewMode}>
        {viewMode === "CREATE" && (
          <InputBoolean
            viewMode={viewMode}
            name="should_create_stripe_id"
            onChange={onInputChange}
            defaultValue={should_create_stripe_id}
            lang={tabLang.inputShouldCreateStripeCustomer}
            error={shouldMarkError("should_create_stripe_id")}
          />
        )}
        {((viewMode === "CREATE" && !should_create_stripe_id) ||
          (viewMode === "VIEW" && stripe_customer_id) ||
          viewMode === "EDIT") && (
          <InputText
            defaultValue={stripe_customer_id}
            name="stripe_customer_id"
            error={shouldMarkError("stripe_customer_id")}
            lang={tabLang.inputStripeCustomerId}
            onChange={onInputChange}
            required={false}
            viewMode={viewMode}
            replaceSpaces
          />
        )}
      </FormFieldBackground>

      {viewMode !== "CREATE" && (
        <InputSelect
          viewMode={viewMode}
          lang={tabLang.inputClientStatus}
          name={"status"}
          value={status}
          options={_.map(_.entries(CLIENT_STATUS), ([STATUS, status]) => ({
            key: status,
            label: tabLang.inputClientStatus.options[STATUS],
          }))}
          onChange={onSelectChange}
          // IMPORTANT: value is read-only, and is discarded by prepareClientForBackend anyway
          disabled
        />
      )}

      <FormFieldBackground viewMode={viewMode}>
        <InputSelect
          viewMode={viewMode}
          lang={tabLang.inputBillingType}
          name={"billing_type"}
          value={billing_type}
          options={_.map(Object.values(BILLING_TYPE), (type) => ({
            key: type,
            label: _.capitalize(type),
          }))}
          onChange={onSelectChange}
          disabled={!isOptimetriksAdminRole(client.profile)} // Disabled for now, only clients created through sign-up can be automated
        />
      </FormFieldBackground>
    </>
  );
};

export default CustomBillingTab;
