import { LayerProps } from "react-map-gl";

export const forestLayer: LayerProps = {
  id: "forest-layer",
  type: "raster",
  paint: {
    "raster-opacity": 0.4,
  },
  minzoom: 0,
  maxzoom: 22,
};
