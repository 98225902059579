import dbSchemaInitState from "./dbSchema/initialState";
import { IEnvironmentState } from "./reducer";
import tableOptimizationState from "./tableOptimizations/initialState";

const initialState: IEnvironmentState = {
  subCategorySelected: "index",
  dbSchema: dbSchemaInitState,
  tableOptimizations: tableOptimizationState,
};

export default initialState;
