import React, { ReactNode } from "react";

import { Box, IconButton, makeStyles } from "@material-ui/core";
import _ from "lodash";

import * as colors from "assets/colors";
import DefaultChip from "components/Chip/DefaultChip";
import { TFormattedExpression } from "utils/expressions/formatExpression";

interface IExpressionChip {
  formattedExpression?: TFormattedExpression;
  placeholder?: string;
  prefix?: ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDelete?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const styles = {
  ExpressionChip: (props: IExpressionChip) => ({
    border: `1px solid ${colors.GreyBorder}`,
    padding: "0px 10px 0px 16px",
    height: "52px",
    borderRadius: "2px",
    boxShadow: `inset 6px 0em ${colors.GreyBorder}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...(props.onClick && { cursor: "pointer" }),
  }),
  PartsContainer: {
    overflow: "auto",
    whiteSpace: "nowrap",
  },
} as const;

const useStyles = makeStyles(styles);

const ExpressionChip = ({
  formattedExpression,
  placeholder = "No condition",
  prefix = null,
  onClick,
  onDelete,
}: IExpressionChip) => {
  const classes = useStyles({ onClick });
  const parts = _.compact(formattedExpression);
  return (
    <Box className={classes.ExpressionChip} onClick={onClick}>
      <Box className={classes.PartsContainer}>
        {!_.isEmpty(parts) && <span>{prefix}</span>}

        {_.map(parts, (part, index) => {
          if (_.isString(part)) {
            if (!part) {
              return null;
            }
            return (
              <span
                key={index}
                style={{
                  fontWeight: part === "(" || part === ")" ? 600 : 400,
                }}
              >
                {part}
              </span>
            );
          }

          if (!part.value) {
            return null;
          }

          if (part.type === "OPERATOR") {
            return (
              <span
                key={index}
                style={{
                  // fontWeight: 600,
                  fontSize: 16,
                  verticalAlign: "middle",
                  margin: "0 8px",
                }}
              >
                {part.value}
              </span>
            );
          }

          if (part.type === "CHIP") {
            return (
              <Box key={index} margin="0 2px" display="inline">
                <DefaultChip label={part.value} />
              </Box>
            );
          }
        })}

        {_.isEmpty(parts) && placeholder}
      </Box>

      {!_.isEmpty(parts) && onDelete && <DeleteButton onDelete={onDelete} />}
    </Box>
  );
};

interface IDeleteButton {
  onDelete?: () => void;
}

const DeleteButton = ({ onDelete }: IDeleteButton) => {
  return (
    <IconButton
      onClick={onDelete}
      style={{ padding: "6px", float: "right", color: colors.Black }}
    >
      <span className="material-icons-outlined md-20">cancel</span>
    </IconButton>
  );
};

export default ExpressionChip;
