import { IJobScript } from "model/entities/Job";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all script from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllScripts = (state: IRootState) => state.jobs.scripts.scripts;

/**
 * Selector to get all Scripts from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allScriptsSelector = customSelectorCreator(
  [getAllScripts],
  (allScripts: IJobScript[]) => {
    // map all the scripts
    return allScripts;
  }
);

/**
 * Gets the status of creating a script
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingScript = (state: IRootState) =>
  state.jobs.scripts.isCreating;

/**
 * Gets the status of updating a script
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingScript = (state: IRootState) =>
  state.jobs.scripts.isUpdating;

/**
 * Gets the status of deleting a script
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingScript = (state: IRootState) =>
  state.jobs.scripts.isDeleting;

/**
 * Gets the status of running a script job
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRunningScript = (state: IRootState) =>
  state.jobs.scripts.isRunning;
