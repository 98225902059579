import { IMetaExpressionsState } from "./reducer";

const initialState: IMetaExpressionsState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isComputing: false,
  selectedMetaExpression: null,
  metaExpressions: [],
  metaExpressionsSolved: {},
  errors: [],
};

export default initialState;
