import httpRequestInitState from "./http_requests/initialState";
import integrationInitState from "./integrations/initialState";
import notificationInitState from "./notifications/initialState";
import { JobsState } from "./reducer";
import scriptInitState from "./scripts/initialState";
import streamsInitState from "./streams/initialState";
import transformationsInitState from "./transformations/initialState";

const initialState: JobsState = {
  notifications: notificationInitState,
  transformations: transformationsInitState,
  streams: streamsInitState,
  integrations: integrationInitState,
  scripts: scriptInitState,
  http_requests: httpRequestInitState,
  errors: [],
};

export default initialState;
