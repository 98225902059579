import TLang, { TOperator } from "model/application/Lang";

export type TOperatorMap = {
  [key in TOperator]: string;
};

export const getOperatorMap = (lang: TLang): TOperatorMap => {
  const operatorKeys = lang.genericTerms.operators;
  return {
    ADD: operatorKeys.ADD,
    DIVIDE: operatorKeys.DIVIDE,
    MULTIPLY: operatorKeys.MULTIPLY,
    SUBTRACT: operatorKeys.SUBTRACT,
    DIFFERENT: operatorKeys.DIFFERENT,
    EQUAL: operatorKeys.EQUAL,
    GREATER_OR_EQUAL_TO: operatorKeys.GREATER_OR_EQUAL_TO,
    GREATER_THAN: operatorKeys.GREATER_THAN,
    LESS_OR_EQUAL_TO: operatorKeys.LESS_OR_EQUAL_TO,
    LESS_THAN: operatorKeys.LESS_THAN,
    OR: operatorKeys.OR,
    AND: operatorKeys.AND,
    CONTAINS: operatorKeys.CONTAINS,
    IS_EMPTY: operatorKeys.IS_EMPTY,
    IS_NOT_EMPTY: operatorKeys.IS_NOT_EMPTY,
  };
};
