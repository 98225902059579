import { fetchStripeSessionLinkAction } from "containers/clients/redux/actions";

import { useActions } from "./useActions";

const useOpenStripePortal = (clientId: string) => {
  const fetchStripeSessionLink = useActions(fetchStripeSessionLinkAction);

  const openStripePortal = async () => {
    const sessionLink = await fetchStripeSessionLink(clientId);
    sessionLink && window.open(sessionLink, "_self");
  };

  return openStripePortal;
};

export default useOpenStripePortal;
