import { IStream as IBEStream } from "fieldpro-tools/dist/src/types";

import { IStream } from "model/entities/Job";
import { clone } from "utils/utils";

const prepareStreamsForFrontend = (originalStreams: IBEStream[]): IStream[] => {
  let streams = clone(originalStreams);
  streams = streams.map((s: any) => {
    s.columns = s.columns.map((c: any, idx: number) => ({
      ...c,
      index: idx,
    }));
    return s;
  });
  return streams;
};

const prepareStreamForBackend = (originalStream: IStream): IStream => {
  const stream = JSON.parse(JSON.stringify(originalStream));
  stream.columns = stream.columns.map((c: any) => ({
    origin_name: c.origin_name,
    destination_name: c.destination_name,
    type: c.type,
  }));
  delete stream.indexes;
  delete (stream as any).job_type;
  return stream;
};

export { prepareStreamForBackend, prepareStreamsForFrontend };
