import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import _ from "lodash";

import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import useTranslations from "hooks/useTranslations";
import { TIMELINE_EVENT_TYPE, TTimelineData } from "model/entities/Dashboard";

import styles from "./styles";

const useStyles = makeStyles(styles);

export interface TimelineItemContentProps {
  reports?: IAugmentedActivityReport[];
  step: TTimelineData;
  onClickReport?: (reportId: string) => void;
}

const TimelineItemContent: React.FC<TimelineItemContentProps> = ({
  step,
  onClickReport,
  reports,
}) => {
  const classes = useStyles();
  const lang = useTranslations();
  const timelineLang =
    lang.containers.dashboards.subCategories.dashboards.createEditModal
      .timeline;

  const report_id = step.activity_report_id;

  async function handleClickReport() {
    if (onClickReport && report_id) {
      onClickReport(report_id);
    }
  }

  switch (step.action) {
    case TIMELINE_EVENT_TYPE.CHECK_IN:
      return <Box>{timelineLang.options.checkIn}</Box>;
    case TIMELINE_EVENT_TYPE.CHECK_OUT:
      return <Box>{timelineLang.options.checkOut}</Box>;
    default:
      const report = _.find(reports, (rep) => rep?.metadata?._id === report_id);

      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          onClick={handleClickReport}
          style={{ cursor: "pointer" }}
        >
          <Box
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            aria-label={report?.metadata._displayed_name}
          >
            {report?.metadata._displayed_name}
          </Box>

          <Box
            paddingLeft={"8px"}
            className={classes.iconWrapper}
            onClick={handleClickReport}
          >
            <LaunchIcon className={classes.launchIcon} />
          </Box>
        </Box>
      );
  }
};

export default TimelineItemContent;
