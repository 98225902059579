import { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  BaseTextFieldProps,
  Button,
  createTheme,
  makeStyles,
  ThemeOptions,
  ThemeProvider,
} from "@material-ui/core";
import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import { format as formatDate } from "date-fns";
import _ from "lodash";

import * as colors from "assets/colors";
import { appThemeOptions } from "containers/app/AppTheme";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isValidDate, isValidDateString } from "utils/dateUtils";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputViewValue from "../InputViewValue";

export interface IInputDatePropsBase
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  viewMode?: TViewMode;
  defaultValue?: Date | null;
  name: string;
  error?: string;
  onChange: (value: Date | null, name: string) => void;
  required?: boolean;
  // clearable?: boolean; //For this to work, we need to support null in the DB
  isUndetermined?: boolean;
  minDate?: number;
  maxDate?: number;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  lang?: {
    title?: string;
    tooltip?: string;
  };
  viewStacked?: boolean;
  views?: DatePickerView[];
  format?: string;
  closeOnSelect?: boolean; // Close dialog immediately after selecting
}

export interface IInputDateProps
  extends IInputDatePropsBase,
    Omit<IInputBaseLayout, keyof IInputDatePropsBase> {}

const MuiPickersThemeOptions = {
  ...appThemeOptions,
  overrides: {
    ...(appThemeOptions.overrides || {}),
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiButton: {
      textPrimary: {
        padding: "0",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: "unset",
        backgroundColor: colors.OffWhite,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontWeight: 500,
        color: colors.Black,
      },
      toolbarBtnSelected: {
        display: "none",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "space-between",
      },
    },
    MuiPopover: {
      paper: {
        marginTop: "8px",
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        "& > p": {
          lineHeight: "23px",
          fontSize: "14px",
          fontWeight: "bold",
          color: colors.Black,
        },
      },
      iconButton: {
        "& svg": {
          fontSize: "18px",
        },
      },
    },
  },
} as ThemeOptions;

const MuiPickersTheme = createTheme(MuiPickersThemeOptions);

const useStyles = makeStyles({
  DatePicker: {
    margin: 0,
    position: "relative",
  },
  dateText: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

const InputDate = ({
  viewMode = "CREATE",
  disabled = false,
  name,
  defaultValue = null,
  error,
  minDate,
  maxDate,
  required,
  // clearable = false,
  isUndetermined,
  onChange,
  onOpen,
  onClose,
  lang,
  viewStacked = false,
  format = "dd/MM/yyyy", // To match DEFAULT_DATE_FORMAT (but slightly different syntax, cf. casing of MM)
  views,
  closeOnSelect,
  placeholder,
  ...rest
}: IInputDateProps) => {
  const classes = useStyles();
  const rootLang = useTranslations();

  // null is explicit empty date, contrary to undefined which makes the underlying input default value today
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    defaultValue ?? null
  );

  const [isUndeterminedState, setIsUndeterminedState] =
    useState(isUndetermined);

  const value = isUndeterminedState ? "__" : selectedDate;

  const isInvalidDate =
    !_.isNil(value) && !(isValidDate(value) || isValidDateString(value));

  // const [showErrors, setShowErrors] = useState(isInvalidDate);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setIsUndeterminedState(false);
    onChange(date, name);

    // if (!getError()) {
    //   setShowErrors(false);
    // }
  };

  useEffect(() => {
    setSelectedDate(defaultValue);
  }, [defaultValue]);

  const getError = () => {
    // if (!showErrors) {
    //   return;
    // }
    if (error) {
      return error;
    }
    if (isInvalidDate) {
      return rootLang.components.filters.inputDateType.customMessage
        .invalidDate;
    }
  };

  const datePickerProps =
    viewMode === "CREATE"
      ? {}
      : {
          clearLabel: (
            <Button color="default" disableElevation>
              <span style={{ color: colors.GreyDark }}>Clear</span>
            </Button>
          ),
          cancelLabel: (
            <Button color="default" disableElevation>
              <span style={{ color: colors.GreyDark }}>Cancel</span>
            </Button>
          ),

          okLabel: (
            <Button color="secondary" variant="contained" disableElevation>
              Apply
            </Button>
          ),
        };

  const DatePicker = (
    <ThemeProvider theme={MuiPickersTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk={closeOnSelect}
          placeholder={placeholder}
          className={classNames(classes.DatePicker, `viewMode-${viewMode}`)}
          value={value}
          margin="dense"
          onChange={handleDateChange}
          id={name}
          error={!!getError()}
          minDate={minDate}
          maxDate={maxDate}
          views={views}
          onOpen={onOpen ? onOpen : () => {}}
          onClose={onClose ? onClose : () => {}}
          // onBlur={() => setShowErrors(true)}
          format={format}
          required={required}
          disabled={disabled}
          inputVariant="outlined"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          // No "OK" / "Cancel" buttons in inline variant...
          // Optimise for quick input in CREATE mode and for safe editing / cancellation in EDIT mode
          variant={viewMode === "CREATE" ? "inline" : "dialog"}
          fullWidth
          // inputProps={{ maxLength }}
          // clearable={clearable}
          {...datePickerProps}
          PopoverProps={
            viewMode === "CREATE"
              ? {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }
              : {}
          }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );

  return (
    <InputBaseLayout
      {...rest}
      label={lang?.title}
      tooltip={lang?.tooltip}
      required={required}
      viewMode={viewMode}
      error={getError()}
      htmlFor={name}
      viewStacked={viewStacked}
      dataTestId="input-date"
    >
      {viewMode === "VIEW" ? (
        <InputViewValue
          value={selectedDate ? formatDate(new Date(selectedDate), format) : ""}
        />
      ) : (
        DatePicker
      )}
    </InputBaseLayout>
  );
};

export default InputDate;
