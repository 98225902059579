import _ from "lodash";

import { CONDITION_OPERATOR, ICondition } from "model/entities/Workflow";

export const resolveCondition = (conditions: ICondition[]): boolean => {
  if (conditions.length == 0) {
    return true;
  }
  const resultsIdsMap = {} as { [e: string]: boolean };
  conditions = conditions.sort((a: ICondition, b: ICondition) => {
    return a.priority > b.priority ? 1 : -1;
  });
  _.forEach(conditions, (c: ICondition) => {
    const firstOperand = c.first.result_id
      ? resultsIdsMap[c.first.result_id] || false
      : c.first.value ?? "";
    const secondOperand = c.second.result_id
      ? resultsIdsMap[c.second.result_id] ?? false
      : c.second.value ?? "";
    switch (c.operator) {
      case CONDITION_OPERATOR.AND:
        resultsIdsMap[c.id] = (firstOperand && secondOperand) as boolean;
        break;
      case CONDITION_OPERATOR.DIFFERENT:
        resultsIdsMap[c.id] = firstOperand != secondOperand;
        break;
      case CONDITION_OPERATOR.EQUAL:
        resultsIdsMap[c.id] = firstOperand == secondOperand;
        break;
      case CONDITION_OPERATOR.GREATER_OR_EQUAL_TO:
        resultsIdsMap[c.id] = firstOperand >= secondOperand;
        break;
      case CONDITION_OPERATOR.GREATER_THAN:
        resultsIdsMap[c.id] = firstOperand > secondOperand;
        break;
      case CONDITION_OPERATOR.LESS_OR_EQUAL_TO:
        resultsIdsMap[c.id] = firstOperand <= secondOperand;
        break;
      case CONDITION_OPERATOR.LESS_THAN:
        resultsIdsMap[c.id] = firstOperand < secondOperand;
        break;
      case CONDITION_OPERATOR.OR:
        resultsIdsMap[c.id] = (firstOperand || secondOperand) as boolean;
        break;
      case CONDITION_OPERATOR.CONTAINS:
        resultsIdsMap[c.id] = (firstOperand as any as string[]).includes(
          secondOperand.toString()
        );
        break;
    }
  });
  return resultsIdsMap[conditions[conditions.length - 1].id];
};
