import { PicturesState } from "./reducer";

const initialState: PicturesState = {
  isFetchingPictures: false,
  totalPictures: 0,
  allPictures: [],
  errors: [],
};

export default initialState;
