import React from "react";

import { ISecret } from "fieldpro-tools";

import { FormFieldBackground } from "components/Forms/Form";
import CustomMultipleCreate from "components/Input/LegacyInput/CustomMultipleCreate";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

import CreateEditEnvVariableModal from "./EnvVariablesTab/CreateEditEnvVariableModal";
import { getErrorMessagesForEnvVariable } from "./EnvVariablesTab/utils";

export interface IEnvironmentVariablesTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onInputChange: any;
  secrets?: ISecret[];
}

export const EnvironmentVariablesTab: React.FunctionComponent<
  IEnvironmentVariablesTabProps
> = ({ viewMode = "CREATE", onInputChange, secrets }) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <CustomMultipleCreate
          viewMode={viewMode}
          name={"secrets"}
          createModalTitleFunction={() => label.inputEnvVars.title}
          editModalTitleFunction={() => label.inputEnvVars.title}
          langlabel={label.inputEnvVars}
          onChange={onInputChange}
          defaultValue={secrets || []}
          additionnalProps={{
            secrets: secrets || [],
          }}
          showDownloadButton={false}
          actionsDisabled={viewMode == "VIEW"}
          CreateEditModal={CreateEditEnvVariableModal}
          getErrorMessages={getErrorMessagesForEnvVariable}
          chipTitleTemplate={"$name$ : *****"}
          defaultValueForNewElement={{
            name: undefined,
            description: undefined,
            value: undefined,
          }}
          lang={lang}
          expandableChip
        />
      </FormFieldBackground>
    </>
  );
};

export default EnvironmentVariablesTab;
