import { ITrigger } from "fieldpro-tools";

import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export function fetchAllTriggersBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ALL_TRIGGERS_BEGIN,
  };
}

export function fetchAllTriggersFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_ALL_TRIGGERS_FAILURE,
    error,
  };
}

export interface FetchAllTriggersSuccessAction extends IAction {
  type: typeof types.FETCH_ALL_TRIGGERS_SUCCESS;
  triggers: ITrigger[];
}

export function fetchAllTriggersSuccessActionCreator({
  triggers,
}: {
  triggers: ITrigger[];
}): FetchAllTriggersSuccessAction {
  return {
    type: types.FETCH_ALL_TRIGGERS_SUCCESS,
    triggers,
  };
}

export function deleteTriggerBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_TRIGGER_BEGIN,
  };
}

export function deleteTriggerFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_TRIGGER_FAILURE,
    error,
  };
}

export interface IDeleteTriggerSuccessAction extends IAction {
  triggerId: string;
}

export function deleteTriggerSuccessActionCreator(
  triggerId: string
): IDeleteTriggerSuccessAction {
  return {
    type: types.DELETE_TRIGGER_SUCCESS,
    triggerId,
  };
}

export function createTriggerBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_TRIGGER_BEGIN,
  };
}

export function createTriggerFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_TRIGGER_FAILURE,
    error,
  };
}

export interface ICreateTriggerSuccessAction extends IAction {
  trigger: ITrigger;
}
export interface IUpdateTriggerSuccessAction extends IAction {
  trigger: ITrigger;
}

export function createTriggerSuccessActionCreator(
  trigger: ITrigger
): ICreateTriggerSuccessAction {
  return {
    type: types.CREATE_TRIGGER_SUCCESS,
    trigger,
  };
}

export function updateTriggerBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_TRIGGER_BEGIN,
  };
}

export function updateTriggerFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_TRIGGER_FAILURE,
    error,
  };
}

export function updateTriggerSuccessActionCreator(
  trigger: ITrigger
): IUpdateTriggerSuccessAction {
  return {
    type: types.UPDATE_TRIGGER_SUCCESS,
    trigger,
  };
}
