import { Box, Chip, makeStyles } from "@material-ui/core";
import { IDSource, IQuerySettings } from "fieldpro-tools";

import {
  AlertOrange,
  InfoColor,
  LightBackground,
  PineGreen,
  PineGreenLight,
  White,
} from "assets/colors";
import DeactivatedMessage from "components/Input/Base/DeactivatedMessage";
import { useTranslations } from "hooks";
import { IClient } from "model/entities/Client";

import { getQueryMetadata } from "./utils";

const useStyles = makeStyles({
  Chip: {
    height: "24px",
    borderRadius: "4px",
    fontWeight: 500,
    fontSize: "12px",
    "& .icon": {
      fontSize: "12px",
      marginLeft: "8px",
    },
  },
} as const);

interface IQueryChipProps {
  verified?: boolean;
  deactivated?: boolean;
  shouldVerify?: boolean;
  onVerify?: () => void;
}

const QueryChip = (args: IQueryChipProps) => {
  const classes = useStyles();
  const lang = useTranslations();
  const queryGenericTerms = lang.genericTerms.query;

  const { deactivated, verified, shouldVerify, onVerify } = args;

  return (
    <Box>
      {deactivated && (
        <Chip
          icon={
            <span
              className="material-icons icon"
              style={{ color: AlertOrange }}
            >
              error
            </span>
          }
          label={queryGenericTerms.deactivated.title}
          className={classes.Chip}
          data-testid="deactivated-chip"
          style={{
            backgroundColor: LightBackground,
            color: AlertOrange,
          }}
        />
      )}

      {shouldVerify && (
        <Chip
          icon={
            <span
              className="material-icons-outlined icon"
              style={{ color: White }}
            >
              verified_user
            </span>
          }
          label={queryGenericTerms?.verify.title}
          className={classes.Chip}
          data-testid="verify-chip"
          style={{ backgroundColor: InfoColor, color: White }}
          onClick={onVerify}
          disabled={onVerify === undefined}
        />
      )}

      {verified && (
        <Chip
          icon={
            <span className="material-icons icon" style={{ color: PineGreen }}>
              verified_user
            </span>
          }
          label={queryGenericTerms?.verified.title}
          className={classes.Chip}
          data-testid="verified-chip"
          style={{ backgroundColor: PineGreenLight, color: PineGreen }}
        />
      )}
    </Box>
  );
};

export const getQueryMetadataComponentProps = (
  attributes: object & IQuerySettings,
  client: IClient,
  role: string,
  onInput?: (value: any, name: string) => void,
  disabled?: boolean
) => {
  const metadata = getQueryMetadata(attributes, client, role, onInput);
  return {
    disabled: disabled || metadata.deactivated,
    labelIcons: [<QueryChip {...metadata} key={IDSource()} />],
    additionalMessage: metadata.deactivated ? (
      <DeactivatedMessage />
    ) : undefined,
  };
};
export default QueryChip;
