import { LIST_SCOPE } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { listsSelector } from "containers/lists/redux/selectors";
import ItemDialog from "containers/lists/subcategories/items/ItemForm/ItemDialog";
import {
  getAllActivities,
  getAllWorkflows,
} from "containers/workflows/redux/selectors";
import ICustomer from "model/entities/Customer";
import { IListItem } from "model/entities/ListItem";

interface ICustomerDialog {
  customer: ICustomer;
  onConfirm?: (customerItem: IListItem) => void;
  onClose: () => void;
}

const CustomerDialog = ({ customer, onClose, onConfirm }: ICustomerDialog) => {
  const activities = useSelector(getAllActivities);
  const workflows = useSelector(getAllWorkflows);
  const lists = useSelector(listsSelector);

  const customerList = _.find(lists, { id: "customer" });

  if (!customerList) {
    return null;
  }

  const listScope: LIST_SCOPE = customerList?.scope ?? LIST_SCOPE.GLOBAL;

  const additionnalProps = {
    lists,
    listScope: listScope,
    schema: _.filter(customerList?.schema, (att) => !att.deprecated),
    activities,
    workflows,
  };

  return (
    <ItemDialog
      open
      viewMode={"VIEW"}
      item={customer}
      list={customerList}
      listScope={listScope}
      additionnalProps={additionnalProps}
      onClose={onClose}
      hideArrows
      // TODO: if onConfirm is not passed in parent,
      // we assume this is because we don't want to bother handling the "save"
      // So we disable edition for now
      onConfirm={onConfirm ?? (() => {})}
      disableEdition={!onConfirm}
    />
  );
};

export default CustomerDialog;
