import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";
import { ICustomerLegendFE } from "./types";

export interface ICustomersState {
  subCategorySelected: string;
  allLegends: Array<ICustomerLegendFE>;
  customerLocations: Array<ICustomerLocation> | undefined;
}

const initialState: ICustomersState = {
  customerLocations: undefined,
  subCategorySelected: "map",
  allLegends: [],
};

export default initialState;
