import _ from "lodash";

import { IItemGalleryItem } from "components/Matrix/MatrixEditorDrawer/ItemGallery";
import { IMatrixAnswerForm } from "components/Matrix/MatrixEditorDrawer/MatrixEditorDrawer";
import { TRowType } from "components/Table/model";
import { IList } from "model/entities/List";

export function addItemToMatrixRows(
  item: IItemGalleryItem,
  answers: IMatrixAnswerForm,
  list: IList,
  rows: TRowType[]
) {
  const rowsCopy = _.cloneDeep(rows);
  const categoryId =
    list.id === "sku"
      ? list.key_attribute_category || "_category"
      : list.filtrable
      ? list.filtrable[0]
      : "";

  const fullItem = _.find(_.flatten(list.items), { _id: item.id });

  const newRow = {
    _item_id: item.id,
    _category_id: fullItem?.[categoryId],
    _name: item.label,
    ...answers,
  };

  const index = _.findIndex(rows, {
    _item_id: item.id,
  });

  if (index === -1) {
    rowsCopy.push(newRow);
  } else {
    rowsCopy.splice(index, 1, newRow);
  }

  return rowsCopy;
}
