import React from "react";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles as any);

export const ContentLoader: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box style={{ height: "100%" }}>
      <div className={classes.LoadingTextContent}>Loading...</div>
      <div className={classes.ContentLoaderWrapper}>
        <CircularProgress color={"inherit"} />
      </div>
    </Box>
  );
};

export default ContentLoader;
