import { useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { SnackbarKey, useSnackbar } from "notistack";

import TLang from "model/application/Lang";

interface ICustomStackProps {
  setIsDisplayingDetail: any;
  notification: any;
  lang?: TLang;
  isDisplayingDetail: boolean;
}

const CustomStack: React.FunctionComponent<ICustomStackProps> = ({
  setIsDisplayingDetail,
  notification,
  isDisplayingDetail,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const actionWithoutDetailedMessage = (key: SnackbarKey) => [
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon />
    </IconButton>,
  ];

  const actionWithDetailedMessage = (key: SnackbarKey) => [
    <IconButton
      key="more"
      aria-label="more"
      color="inherit"
      onClick={() => {
        closeSnackbar(key);
        setIsDisplayingDetail(true);
      }}
    >
      <InfoIcon />
    </IconButton>,
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon />
    </IconButton>,
  ];

  const getActionElement = (key: SnackbarKey) => {
    if (notification.detailedMessage) {
      return actionWithDetailedMessage(key);
    }
    return actionWithoutDetailedMessage(key);
  };

  useEffect(() => {
    if (!isDisplayingDetail) {
      enqueueSnackbar(<div>{notification.message}</div>, {
        variant: notification.level,
        persist: false,
        anchorOrigin: { vertical: "top", horizontal: "right" },
        action: getActionElement,
        className: `notiStackItem${notification.level}`,
        autoHideDuration: 10000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayingDetail, notification, enqueueSnackbar]);

  return null;
};

export default CustomStack;
