import React from "react";

import { useSelector } from "react-redux";
import { Redirect } from "react-router";

import { getSelectedClient } from "containers/clients/redux/selectors";
import { IClient } from "model/entities/Client";

interface IProtectedComponentProps {
  authenticationFunction: (client: IClient) => boolean;
  redirectPath?: string;
}
const ProtectedComponent: React.FunctionComponent<IProtectedComponentProps> = ({
  authenticationFunction,
  children,
  redirectPath,
}) => {
  const client = useSelector(getSelectedClient);
  const authenticated = authenticationFunction(client);
  if (!authenticated && !redirectPath) {
    return null;
  }
  if (!authenticated && redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return <>{children}</>;
};
export default ProtectedComponent;
