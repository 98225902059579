export function replaceHTMLTags(str: string) {
  if (!str) return "";
  const replacedStr = str.replace(
    /<\s*(\/)?\s*([a-zA-Z0-9]+)\s*>\s*|<><\/>/g,
    (match) => {
      return match.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }
  );

  return replacedStr;
}
