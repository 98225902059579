export const NOTIFICATION_LEVEL_SUCCESS = "success";
export const NOTIFICATION_LEVEL_ERROR = "error";
export const NOTIFICATION_LEVEL_WARNING = "warning";
export const NOTIFICATION_LEVEL_INFO = "info";

const NOTIFICATION_ACTION_LEVEL = {
  SUCCESS: NOTIFICATION_LEVEL_SUCCESS,
  ERROR: NOTIFICATION_LEVEL_ERROR,
  INFO: NOTIFICATION_LEVEL_WARNING,
  WARN: NOTIFICATION_LEVEL_INFO,
};

export type TNotificationActionLevel =
  (typeof NOTIFICATION_ACTION_LEVEL)[keyof typeof NOTIFICATION_ACTION_LEVEL];

export default NOTIFICATION_ACTION_LEVEL;
