import { FOLDER_TYPE, isOptimetriksRole } from "fieldpro-tools";

import { IFolder } from "containers/environments/SubcategoryManager";
import * as alllang from "lang";
import { isClientAdminRole } from "model/constants/profiles";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Get the state of fetching all folders
 * @param {Object} state Redux Store State
 * @returns {Boolean} value whether the application is fetching folders
 */
export const getIsFetchingFolders = (state: IRootState) =>
  state.folders.isFetchingFolders;

/**
 * Gets the state of creating a colder
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsCreatingFolder = (state: IRootState) =>
  state.folders.isCreatingFolder;

/**
 * Gets the state of updating a colder
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingFolder = (state: IRootState) =>
  state.folders.isUpdatingFolder;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingFolder = (state: IRootState) =>
  state.folders.isDeletingFolder;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsArchivingFolder = (state: IRootState) =>
  state.folders.isArchivingFolder;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringFolder = (state: IRootState) =>
  state.folders.isRestoringFolder;

/**
 * Gets the folders from Redux Store
 * @param {Object} state Redux Store state for Folders
 * @returns {Array} All folders
 */
export const getAllFolders = (state: IRootState) => state.folders.folders;

/**
 * Gets the document folders from Redux Store
 * @param {Object} state Redux Store state for document folders
 * @returns {Array} All document folders
 */
export const getAllDocumentFolders = (state: IRootState) =>
  state.folders.folders.filter((f) => f.type === FOLDER_TYPE.DOCUMENT_FOLDER);

/**
 * Gets the dashboard folders from Redux Store
 * @param {Object} state Redux Store state for dashboard folders
 * @returns {Array} All dashboard folders
 */
export const getAllDashboardFolders = (state: IRootState) =>
  state.folders.folders.filter((f) => f.type === FOLDER_TYPE.DASHBOARD_FOLDER);

export const dashboardFoldersSelector = customSelectorCreator(
  getAllDashboardFolders,
  (dashboardFolders) => dashboardFolders
);

/**
 * Gets the environment folders from Redux Store
 * @param {Object} state Redux Store state for environment folders
 * @returns {Array} All environment folders
 */

export const getAllEnvironmentFolders = (state: IRootState): IFolder[] => {
  const lang = alllang[state.auth.lang || "en"];
  const role = state.clients.selectedClient.profile;
  const subCategories = [
    {
      name: lang.mainLayout.sidebar.summary,
      description: "",
      id: "index",
    },
    {
      name: lang.containers.environment.subCategories.sections["sanityCheck"]
        .title,
      description:
        lang.containers.environment.subCategories.sections["sanityCheck"].title,
      id: "sanity_check",
    },
  ];
  if (isOptimetriksRole(role) || isClientAdminRole(role)) {
    subCategories.push({
      name: lang.containers.environment.subCategories.sections["database"]
        .title,
      description:
        lang.containers.environment.subCategories.sections["database"].title,
      id: "database",
    });
  }
  if (isOptimetriksRole(role)) {
    subCategories.push({
      name: lang.containers.environment.subCategories.sections["optimizations"]
        .title,
      description:
        lang.containers.environment.subCategories.sections["optimizations"]
          .title,
      id: "tableOptimizations",
    });
  }
  return subCategories;
};

export const environmentFoldersSelector = customSelectorCreator(
  getAllEnvironmentFolders,
  (environmentFolders) => environmentFolders
);
