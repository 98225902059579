import _ from "lodash";

import TLang from "model/application/Lang";
import { IDashboard } from "model/entities/Dashboard";

import { getDashboardName } from "./getDashboardName";

export function translateDashboards(dashboards: IDashboard[], lang: TLang) {
  return _.map(_.cloneDeep(dashboards), function (d) {
    d.title = getDashboardName(d, lang);
    return d;
  });
}
