/**
 * @notes: Api wrapper to communicate with apiService to handle api calls for team management
 */
import { ITeam as IBETeam } from "fieldpro-tools/dist/src/types";

import apiService from "api/apiService";
import * as urls from "containers/clients/redux/endpoints";
import { IHierarchyDependency } from "model/entities/HierarchyDependency";
import ITeam from "model/entities/Team";
import { IServerResponse } from "redux/actions/appActions";

const TEAMS_ENDPOINT = `/teams`;
const TEAM_ENDPOINT = `/team`;
const HIERARCHY_ENDPOINT = "/hierarchy";

/**
 * Creates a team by making a POST request to api with the given parameters
 * @param {Object} team new team details
 * @returns {Promise}
 * */
export type TCreateTeamFunc = (
  actionName: string,
  team: ITeam
) => Promise<IServerResponse<{ id: string }>>;
export const createTeamApiCall: TCreateTeamFunc = (
  actionName: string,
  team: ITeam
) => {
  return apiService.post(`${TEAM_ENDPOINT}`, team, actionName);
};

/**
 * Creates teams by making a POST request to api with the given parameters
 * @param {Array} teams Detail of the teams to create
 * @returns {Promise}
 * */
export type TCreateTeamsFunc = (
  actionName: string,
  teams: ITeam[]
) => Promise<IServerResponse<{ teams: IBETeam[] }>>;
export const createTeamsApiCall: TCreateTeamsFunc = (
  actionName: string,
  teams
) => {
  const body = { teams };
  return apiService.post(`${TEAMS_ENDPOINT}`, body, actionName);
};

/**
 * Fetch all teams api call for a single client
 * @return {Promise}
 * */
export type TFetchTeamsForClientResponse = { teams: IBETeam[] };
export type TFetchTeamsForClientFunc = () => Promise<
  IServerResponse<{ teams: IBETeam[] }>
>;
export const fetchTeamsForClientApiCall: TFetchTeamsForClientFunc = () => {
  return apiService.get(`${TEAMS_ENDPOINT}`);
};

/**
 * Delete Teams API call makes a POST request to delete teams
 * @param {String} ids Id of the teams to delete
 * @return {Promise}
 * */
export type TDeleteTeamsFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<{}>>;
export const deleteTeamsApiCall: TDeleteTeamsFunc = (
  actionName: string,
  ids
) => {
  return apiService.delete(
    `${TEAMS_ENDPOINT}`,
    {
      ids,
    },
    actionName
  );
};

/**
 * Archive Teams API call makes a POST request to archive teams
 * @param {String} ids Id of the teams to archive
 * @return {Promise}
 * */
export type TArchiveTeamsFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<{}>>;
export const archiveTeamsApiCall: TArchiveTeamsFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(`${TEAMS_ENDPOINT}/archive`, { ids }, actionName);
};

/**
 * Restore Teams API call makes a POST request to restore a teams
 * @param {String} ids Id of the teams to restore
 * @return {Promise}
 * */
export type TRestoreTeamsFunc = (
  actionName: string,
  ids: string[]
) => Promise<IServerResponse<{}>>;
export const restoreTeamsApiCall: TRestoreTeamsFunc = (
  actionName: string,
  ids
) => {
  return apiService.patch(`${TEAMS_ENDPOINT}/restore`, { ids }, actionName);
};

/**
 * Update team API call
 * @param {Array} teams Array of teams to import
 * */
export type TUpdateTeamsFunc = (
  actionName: string,
  teams: ITeam[]
) => Promise<IServerResponse<{ teams: any[] }>>;
export const updateTeamsApiCall: TUpdateTeamsFunc = (
  actionName: string,
  teams
) => {
  return apiService.patch(
    `${TEAMS_ENDPOINT}`,
    {
      teams,
    },
    actionName
  );
};

/**
 * Assign Mobile users to teams
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export type TAssignMobileUsersToTeamsFunc = (
  actionName: string,
  links: any
) => Promise<IServerResponse<any>>;
export const assignMobileUsersToTeamsApiCall: TAssignMobileUsersToTeamsFunc = (
  actionName: string,
  links
) => {
  return apiService.patch(
    `${TEAMS_ENDPOINT}/assign`,
    {
      links,
    },
    actionName
  );
};

/**
 * Assign Web users to teams
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export function assignWebUsersToTeamsApiCall(actionName: string, links: any) {
  return apiService.patch(
    `${TEAMS_ENDPOINT}/assign_web`,
    { links },
    actionName
  );
}
/**
 * Assign Workflows to teams
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export function assignWorkflowsToTeamsApiCall(actionName: string, links: any) {
  return apiService.patch(
    `${TEAMS_ENDPOINT}/assign_workflows`,
    { links },
    actionName
  );
}
/**
 * Create or update hierarchy dependencies for client
 * @param {String} hierarchyDependencies all hierarchy dependencies
 * @returns {Promise}
 */
export type TUpdateHierarchyFunc = (
  actionName: string,
  hierarchyDependencies: IHierarchyDependency[],
  clientId: string
) => Promise<IServerResponse<{ teams: IBETeam[] }>>;
export const updateHierarchyApiCall: TUpdateHierarchyFunc = (
  actionName: string,
  hierarchyDependencies,
  clientId: string
) => {
  return apiService.patch(
    `${urls.CLIENT_ENDPOINT}/${clientId}/${HIERARCHY_ENDPOINT}`,
    {
      hierarchy_dependencies: hierarchyDependencies.filter(
        (e) => !e.removeBeforeBackEnd
      ),
    },
    actionName
  );
};
/**
 * UnAssign users(web or mobile) from teams
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export function unAssignUsersFromTeamsApiCall(
  actionName: string,
  type: "WEB_USER" | "MOBILE_USER",
  links: any
) {
  return apiService.patch(
    `${TEAMS_ENDPOINT}/unassign`,
    { links, type },
    actionName
  );
}
