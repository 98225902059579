import { IIntegration as IBEIntegration } from "fieldpro-tools/dist/src";

import { IIntegration } from "model/entities/Job";
import { clone } from "utils/utils";

const prepareIntegrationForBackend = (
  originalIntegration: IIntegration
): IBEIntegration => {
  const integration = JSON.parse(JSON.stringify(originalIntegration));
  Object.keys(integration).forEach((att) => {
    if (integration[att] === "" || !integration[att]) delete integration[att];
  });
  delete integration.id;
  delete integration.job_type;
  return integration;
};
const prepareIntegrationsForFrontend = (
  originalIntegrations: IBEIntegration[]
): IIntegration[] => {
  const integrations = clone(originalIntegrations);
  const frontIntegrations: IIntegration[] = [];
  integrations.forEach((d: IBEIntegration) => {
    const integration = IntegrationBackToFront(d);
    frontIntegrations.push(integration);
  });
  return frontIntegrations;
};
const IntegrationBackToFront = (
  originalIntegration: IBEIntegration
): IIntegration => {
  const notif = {
    ...originalIntegration,
    webhooks: originalIntegration.webhooks || [],
  };
  return notif;
};

export { prepareIntegrationForBackend, prepareIntegrationsForFrontend };
