import { useState } from "react";

import AddCircleOutline from "@material-ui/icons/AddCircleOutline";

import { Black, GreyLight } from "assets/colors";
import MenuButton from "components/Buttons/MenuButton";
import { DrawAddIcon } from "components/Buttons/MenuButton/MenuButton";
import ExpressionChip from "components/Chip/ExpressionChip";
import InputBaseLayout from "components/Input/InputBaseLayout";
import { IInputBaseLayout } from "components/Input/InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "components/Input/InputEmptyContainer";
import useTranslations from "hooks/useTranslations";

export enum SHAPE_CREATE_MODE {
  DRAW = "DRAW",
  // FOR LATER
  // FILE = "FILE",
  // ADMINISTRATIVE = "ADMINISTRATIVE",
}

interface IInputCreateShape extends IInputBaseLayout {
  onDraw: () => void;
  required?: boolean;
}

const InputCreateShape = ({ onDraw, required, ...rest }: IInputCreateShape) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.territories.inputCreateShape;

  const [createMode, setCreateMode] = useState<SHAPE_CREATE_MODE | undefined>();

  const menuOptions = [
    {
      label: langKey.menuOptions.draw,
      onClick: () => {
        onDraw();
        setCreateMode(SHAPE_CREATE_MODE.DRAW);
      },
      icon: <DrawAddIcon />,
    },
  ];

  return (
    <InputBaseLayout
      {...rest}
      label={langKey.title}
      viewMode="CREATE"
      required={required}
    >
      {!createMode && (
        <InputEmptyContainer>
          <MenuButton
            label={langKey.title}
            color={GreyLight}
            fontColor={Black}
            icon={<AddCircleOutline />}
            options={menuOptions}
          />
        </InputEmptyContainer>
      )}

      {createMode === SHAPE_CREATE_MODE.DRAW && (
        <ExpressionChip placeholder={langKey.drawMode.description} />
      )}
    </InputBaseLayout>
  );
};

export default InputCreateShape;
