import {
  IFileFieldUpload,
  IFileFieldUrl,
} from "model/application/DynamicObjects";

interface IFormatFileFieldUploadValue {
  upload_id: string;
  url: string;
}

export const formatFileFieldUploadValue = ({
  upload_id,
  url,
}: IFormatFileFieldUploadValue): IFileFieldUpload => {
  return {
    source_type: "UPLOAD",
    upload_id,
    url,
  };
};

interface IFormatFileFieldUrlValue {
  url: string;
}

export const formatFileFieldUrlValue = ({
  url,
}: IFormatFileFieldUrlValue): IFileFieldUrl | undefined => {
  return {
    source_type: "URL",
    url,
  };
};
