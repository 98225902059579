import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  loaderWrapper: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "",
    color: "white",
    width: "50px",
  },
  ContentLoaderWrapper: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    backgroundColor: "",
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  linearProgress: {
    margin: "130px",
  },
  LoadingContainerInRow: {
    transform: "scale(0.5)",
  },
  LoadingTextContent: {
    textAlign: "center",
    fontSize: "20px",
    color: "black",
    fontWeight: "bold",
    paddingBottom: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  LoadingText: {
    paddingBottom: "10px",
    textAlign: "center",
  },
  LoadingTextDarkMode: {
    paddingBottom: "10px",
    textAlign: "center",
    color: "white",
  },
  CircularProgressRoot: {
    display: "block",
    margin: "auto",
  },
  // Full screen loader
  LoadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  LoadingContainerFullscreen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
  },
  LoadingTextFullscreen: {
    textAlign: "center",
    fontSize: "14px",
    color: "white",
    paddingBottom: "20px",
  },
  LoadingTitleFullscreen: {
    textAlign: "center",
    fontSize: "20px",
    color: "white",
    fontWeight: "bold",
    paddingBottom: "20px",
  },
});
