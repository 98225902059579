import type { LineLayer } from "react-map-gl";

import { MatchingRoute } from "assets/colors";

export const matchingRoutesLayer: LineLayer = {
  id: "matching-routes-layer",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": MatchingRoute,
    "line-width": 8,
    "line-opacity": 0.8,
  },
};
