import {
  CLIENT_STATUS,
  getNormalizedWorkspaceSegment,
  getNormalizedWorkspaceType,
  isOptimetriksRole,
} from "fieldpro-tools";

import {
  getActivePlanId,
  getBillingType,
  isClientStatus,
} from "containers/authentication/utils/clientStatus";
import { IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";

interface ISetHeapSessionData {
  client?: IClient;
  user?: ISignedInUser;
  heapClient: IHeap;
}

const setHeapSessionData = ({
  client,
  user,
  heapClient,
}: ISetHeapSessionData) => {
  try {
    if (user) {
      heapClient.identify(user.id);
    }

    if (client) {
      const role = client?.profile;

      heapClient.addUserProperties({
        role,
        is_optimetriks_role: isOptimetriksRole(role),

        // These only make sense for users that are associated with a single account ?
        ...(!isOptimetriksRole(role) && {
          client_name: client.name,
          client_dbname: client.dbname,
          workspace_type: getNormalizedWorkspaceType(client),
          workspace_segment: getNormalizedWorkspaceSegment(client),
          billing_type: getBillingType(client),
          plan_id: getActivePlanId(client),
          trialing: isClientStatus(client, CLIENT_STATUS.TRIAL),
        }),
      });
    }
  } catch (e) {
    console.error("Failed to set Heap metadata", e);
  }
};

export default setHeapSessionData;
