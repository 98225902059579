import {
  IMobileUser as IBEMobileUser,
  IWebUser as IBEWebUser,
} from "fieldpro-tools/dist/src/types";

import { IMobileUser, IWebUser } from "model/entities/User";
const prepareMobileUserForFrontend = (
  mobileUser: Partial<IBEMobileUser>
): IMobileUser => {
  const result: IMobileUser = { ...(mobileUser as any) } as IMobileUser;
  return result;
};

const prepareMobileUserForBackend = (
  mobileUser: IMobileUser
): Partial<IBEMobileUser> => {
  const result: Partial<IBEMobileUser> = { ...(mobileUser as any) };
  Object.keys(result).forEach((att) => {
    if (result[att] === "" || result[att] === null) {
      delete result[att];
    }
  });

  delete mobileUser.user_start_location;
  delete (mobileUser as any).user_start_location__lat;
  delete (mobileUser as any).user_start_location__lng;
  delete (mobileUser as any).user_start_location__acc;
  return result;
};

const prepareWebUserForFrontend = (webUser: IBEWebUser): IWebUser => {
  const result: IWebUser = { ...(webUser as any) } as IWebUser;
  return result;
};

const prepareWebUserForBackend = (webUser: IWebUser): IBEWebUser => {
  const result: IBEWebUser = { ...(webUser as any) } as IBEWebUser;
  Object.keys(result).forEach((att) => {
    if (result[att] === "" || result[att] === null || att === "middle_name") {
      delete result[att];
    }
  });
  return result;
};
export {
  prepareMobileUserForBackend,
  prepareMobileUserForFrontend,
  prepareWebUserForBackend,
  prepareWebUserForFrontend,
};
