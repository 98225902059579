import { IIntegration } from "model/entities/Job";
import { IAction } from "redux/store/model";
import { insertNewItemToArr, updateErrorsList } from "utils/reducerUtils";

import * as actionCreators from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareIntegrationsForFrontend } from "./utils";

export interface IIntegrationState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isFetchingJobHistory: boolean;
  selectedIntegration: IIntegration | null;
  integrations: IIntegration[];
  errors: any[];
}

/**
 * Integrations reducer takes current state and action and returns a new state for the Integration object in redux store
 * @param state initial state of Integrations
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_INTEGRATION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_INTEGRATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_INTEGRATION_SUCCESS: {
      const { integration } =
        action as actionCreators.ICreateIntegrationSuccessAction;

      return {
        ...state,
        isCreating: false,
        integrations: insertNewItemToArr(state.integrations, integration),
      };
    }

    case types.UPDATE_INTEGRATION_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_INTEGRATION_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_INTEGRATION_SUCCESS: {
      const { integration } =
        action as actionCreators.IUpdateIntegrationSuccessAction;

      const integrations = state.integrations.map((t) =>
        t.id === integration.id ? integration : t
      );

      return {
        ...state,
        isUpdating: false,
        integrations,
      };
    }

    case types.DELETE_INTEGRATION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_INTEGRATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_INTEGRATION_SUCCESS: {
      const { id } = action as actionCreators.IDeleteIntegrationSuccessAction;

      const integrations = state.integrations.filter((t) => t.id !== id);

      return {
        ...state,
        isDeleting: false,
        integrations,
      };
    }

    case types.FETCH_ALL_INTEGRATIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_INTEGRATIONS_SUCCESS: {
      const { allIntegrations: beEmailGenerations } =
        action as actionCreators.IFetchIntegrationsSuccessAction;
      const integrations = prepareIntegrationsForFrontend(beEmailGenerations);
      return {
        ...state,
        isFetchingAll: false,
        integrations,
      };
    }
    default:
      return state;
  }
}
