import React from "react";

import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import _ from "lodash";

import { IOption } from "model/application/components";
import { KPI_TYPE } from "model/entities/Dashboard";
import { isEmptyValue } from "utils/isEmptyValue";

import { ChartDataUtils } from "../Chart/ChartDataUtils";
import { styles } from "./styles";

export interface IPdfClient {
  name: string;
  logo: string;
}

export interface IPdfChartData {
  title: string;
  type: KPI_TYPE | undefined;
  width: number;
  src: string;
  legendSrc: string;
  additionalData: any;
}

interface IPdfDashboardProps {
  title: string;
  client: IPdfClient;
  data: IPdfChartData[];
  startDate: number | Date;
  endDate: number | Date;
  teamFilter?: IOption[];
  userFilter?: IOption[];
}

Font.register({
  family: "BasierCircle",
  src: "https://storage.googleapis.com/fieldpro-static-assets/emails/font/basiercircle-regular-webfont.ttf",
});

export const buildDashboardPdf = (
  title: string,
  client: IPdfClient | undefined,
  startDate: number | Date,
  endDate: number | Date,
  data: IPdfChartData[],
  teamFilter?: IOption[],
  userFilter?: IOption[]
) => {
  const clientName = client ? client.name : "";
  const clientLogo = client ? client.logo : "";
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {buildHeader(title, clientName, clientLogo)}
        <View
          style={{
            width: `100%`,
            ...styles.cardContainer,
          }}
        >
          <View
            style={{
              ...styles.card,
              ...styles.filters,
            }}
          >
            <View style={{ ...styles.inlineContainer }}>
              <Text
                style={{
                  ...styles.cardTitle,
                  ...{
                    fontSize: 12,
                  },
                }}
              >
                {`Filters abstract `}
              </Text>
              <Text style={styles.staticText}>
                {`(details at the end of the document)`}
              </Text>
            </View>
            {startDate && endDate && (
              <View style={styles.inlineContainer}>
                <Text style={styles.staticText}>Date : from </Text>
                <Text style={styles.text}>
                  {ChartDataUtils.formatDate(new Date(startDate)) + " "}
                </Text>
                <Text style={styles.staticText}>to </Text>
                <Text style={styles.text}>
                  {ChartDataUtils.formatDate(new Date(endDate))}
                </Text>
              </View>
            )}
            {_.size(teamFilter) > 0 ? (
              <View style={styles.inlineContainer}>
                <Text style={styles.staticText}>Teams count : </Text>
                <Text style={styles.text}>{teamFilter?.length}</Text>
              </View>
            ) : (
              <></>
            )}
            {userFilter && userFilter.length > 0 ? (
              <View style={styles.inlineContainer}>
                <Text style={styles.staticText}>Users count : </Text>
                <Text style={styles.text}>{userFilter.length}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>

        {data && data.length > 0 ? (
          data.map((d, index) => {
            const type: KPI_TYPE | undefined = d.type;
            const withBorder = type !== KPI_TYPE.SCORECARD;
            if (!type) {
              return <Text key={`pdfView${index}`}>Bad chart type</Text>;
            }
            return (
              <View
                key={`pdfView${index}`}
                style={{
                  maxHeight: "23cm",
                  width: `${d.width}%`,
                  ...styles.cardContainer,
                }}
              >
                <View
                  key={`pdfNestedView${index}`}
                  style={{
                    ...styles.card,
                    padding: type === KPI_TYPE.SCORECARD ? 0 : 10,
                    borderWidth: withBorder ? 1 : 0,
                  }}
                  wrap={false}
                >
                  {type !== KPI_TYPE.SCORECARD ? (
                    <Text style={styles.cardTitle}>{d.title}</Text>
                  ) : (
                    <></>
                  )}
                  {type === KPI_TYPE.TABLE ? (
                    <View
                      style={{
                        ...styles.inlineContainer,
                        ...{ marginBottom: 10 },
                      }}
                    >
                      <Text
                        style={{ ...styles.staticText, ...{ fontSize: 7 } }}
                      >
                        {d && d.additionalData ? (
                          `Displaying lines ${d.additionalData.displayedItems} out of ${d.additionalData.totalItems}`
                        ) : (
                          <></>
                        )}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  {d.legendSrc ? (
                    <Image key={`legend${index}`} src={d.legendSrc}></Image>
                  ) : (
                    <></>
                  )}
                  <View style={{ margin: "auto", width: "100%" }}>
                    <Image
                      style={{
                        objectFit: "contain",
                        maxHeight: "22cm",
                        width: "100%",
                      }}
                      key={`img${index}`}
                      src={d.src}
                    ></Image>
                  </View>
                </View>
              </View>
            );
          })
        ) : (
          <></>
        )}
      </Page>

      {buildFilterDetailsView(
        "Filter details",
        clientName,
        clientLogo,
        startDate,
        endDate,
        teamFilter,
        userFilter
      )}
    </Document>
  );
};

const buildHeader = (title: string, clientName: string, clientLogo: string) => {
  return (
    <View style={styles.headLine} fixed>
      <View style={{ width: "4cm", textAlign: "center" }}>
        {!isEmptyValue(clientLogo) && (
          <Image
            style={{
              objectFit: "contain",
              maxHeight: "15mm",
              height: "15mm",
              width: "100%",
            }}
            src={clientLogo}
          ></Image>
        )}
        <Text style={{ ...styles.text, ...{ fontSize: 11 } }}>
          {clientName}
        </Text>
      </View>
      <Text
        style={{
          ...styles.text,
          ...styles.title,
          ...{ width: "10cm", textAlign: "center" },
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          ...styles.text,
          ...{ fontSize: 10, width: "3cm", textAlign: "center" },
        }}
        render={({ pageNumber, totalPages }) => {
          return `${pageNumber} / ${totalPages}`;
        }}
        fixed
      />
    </View>
  );
};

const buildFilterDetailsView = (
  title: string,
  clientName: string,
  clientLogo: string,
  startDate: number | Date,
  endDate: number | Date,
  teamFilter?: IOption[],
  userFilter?: IOption[]
) => {
  return (
    <Page size="A4" style={styles.page} wrap>
      {buildHeader(title, clientName, clientLogo)}
      <View
        style={{
          width: `100%`,
          ...styles.cardContainer,
        }}
      >
        <View
          style={{
            ...styles.card,
            ...styles.filters,
          }}
        >
          <View style={{ ...styles.inlineContainer }}>
            <Text
              style={{
                ...styles.cardTitle,
                ...{
                  fontSize: 12,
                },
              }}
            >
              Filters
            </Text>
          </View>
          <View style={styles.inlineContainer}>
            <Text style={styles.staticText}>Date : from </Text>
            <Text style={styles.text}>
              {ChartDataUtils.formatDate(new Date(startDate)) + " "}
            </Text>
            <Text style={styles.staticText}>to </Text>
            <Text style={styles.text}>
              {ChartDataUtils.formatDate(new Date(endDate))}
            </Text>
          </View>
          {teamFilter && teamFilter.length > 0 ? (
            <View style={styles.inlineContainer}>
              <Text style={styles.staticText}>Teams : </Text>
              <Text style={styles.text}>
                {teamFilter
                  .map((f: any) => (f.label ? f.label : f.name))
                  .join(", ")}
              </Text>
            </View>
          ) : (
            <></>
          )}
          {userFilter && userFilter.length > 0 ? (
            <View style={styles.inlineContainer}>
              <Text style={styles.staticText}>Users : </Text>
              <Text style={styles.text}>
                {userFilter.map((f) => `${f.label}`).join(", ")}
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      {buildFooterView()}
    </Page>
  );
};

const buildFooterView = () => {
  return (
    <View
      style={styles.footer}
      fixed
      render={({ pageNumber }) => (
        <Text
          style={styles.pageNumber}
          render={({ totalPages }) => `${pageNumber} / ${totalPages}`}
        />
      )}
    />
  );
};

export const PdfDashboard: React.FunctionComponent<IPdfDashboardProps> = ({
  title,
  client,
  data,
  startDate,
  endDate,
  teamFilter,
  userFilter,
}) => {
  return buildDashboardPdf(
    title,
    client,
    startDate,
    endDate,
    data,
    teamFilter,
    userFilter
  );
};

export default PdfDashboard;
