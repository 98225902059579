import { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";

import { GreyMedium } from "assets/colors";
import { BasicArrowIcon } from "components/Dialog/BasicDialog";

import useResize from "./useResize";

export interface IDrawer extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  maxWidth?: number;
  minWidth?: number;
}

const styles = {
  drawer: {
    position: "relative",
  },
  bar: {
    position: "absolute",
    width: "3px",
    left: "0",
    top: 0,
    padding: "0px",
    margin: "0px",
    cursor: "ew-resize",
    transition: "0.3s",
    zIndex: 100,
    border: "none",
    background: "#E6E6E6",
    height: "100%",
    "&:hover": {
      background: GreyMedium,
    },
  },
  barContainer: {
    position: "relative",
    width: "100%",
  },
  arrow: {
    position: "absolute",
    background: "white",
    boxShadow:
      "box-shadow: 0px 0px 2px 0px rgba(1, 1, 1, 0.18); box-shadow: 0px 2px 6px 0px rgba(1, 1, 1, 0.1);",
    height: "24px",
    width: "24px",
    left: "0%",
    transform: "translate(-50%,-50%)",
    top: "20%",
    zIndex: "1000",
  },
  content: {
    overflowX: "auto",
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  },
};

const useStyles = makeStyles(styles as any);

function Drawer({ children, maxWidth = 500, minWidth = 50, ...rest }: IDrawer) {
  const [open, setOpen] = useState(true);
  const classes = useStyles({ open, maxWidth, minWidth });
  const [calculatedWith, setCalculatedWidth] = useState(maxWidth);
  const { width, enableResize } = useResize({
    minWidth: minWidth,
  });

  useEffect(() => {
    const offsetRight =
      document.body.offsetWidth - (width - document.body.offsetLeft);
    if (offsetRight < maxWidth && offsetRight > minWidth) {
      setCalculatedWidth(offsetRight);
      setOpen(calculatedWith <= offsetRight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWidth, minWidth, width]);

  function handleToggle(event: any) {
    event.stopPropagation();
    const _newValue = !open;
    if (_newValue) {
      setCalculatedWidth(maxWidth);
    } else {
      setCalculatedWidth(minWidth);
    }
    setOpen(_newValue);
  }

  return (
    <Box
      {...rest}
      className={classes.drawer}
      width={calculatedWith}
      data-testid="drawer"
    >
      <Box className={classes.bar} onMouseDown={enableResize} data-testid="bar">
        <Box className={classes.barcontainer}></Box>
      </Box>

      <BasicArrowIcon
        filled
        direction={open ? "right" : "left"}
        className={classes.arrow}
        onClick={handleToggle}
        data-testid="openclose-button"
      />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}

export default Drawer;
