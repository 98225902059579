import { Theme } from "@material-ui/core";

import { errorColor } from "assets/colors";

const styles = (theme: Theme) => ({
  chipDisplay: {
    color: "#FFF",
    margin: theme.spacing(0.5), // For being center in the table row (dirty hack will need to be changed)
    background: theme.palette.primary.light,
  },
  chipDisplayUndetermined: {
    color: "#FFF",
    margin: theme.spacing(0.5), // For being center in the table row (dirty hack will need to be changed)
    background: theme.palette.secondary.light,
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    color: "white !important",
    background: theme.palette.primary.light,
    "&:hover": {
      background: theme.palette.primary.main,
    },
    "&:focus": {
      background: theme.palette.primary.main,
    },
  },
  chipUndetermined: {
    color: "#FFF",
    margin: theme.spacing(0.5),
    background: theme.palette.secondary.light,
    "&:hover": {
      background: theme.palette.secondary.main,
    },
    "&:focus": {
      background: theme.palette.secondary.main,
    },
  },
  chipUnselected: {
    margin: theme.spacing(0.5),
  },
  whiteButton: {
    color: "#FFF",
    "&:hover": {
      color: "#FFF",
    },
    "&:focus": {
      color: "#FFF",
    },
  },
  ExpandableChip: {
    background: theme.palette.primary.main + "AA",
    color: "white",
    borderRadius: "20px",
    position: "relative",
    width: "100%",
    height: "42px",
    marginBottom: "10px",
    padding: "10px",
    "box-sizing": "border-box",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&.deprecated": {
      opacity: 0.6,
    },
  },

  ExpandableWithChip: {
    display: "flex",
    alignItems: "center",
  },

  DetailView: {
    height: "max-content",
  },
  ActionBoxCompactView: {
    position: "absolute",
    //float: 'right',
    right: "10px",
    top: "10px",
    width: "80%",
    height: "45px",
    marginTop: "-8px",
    borderBottom: "black solid 2px",
    borderImageSource: "linear-gradient(45deg, rgb(0, 0, 0, 0), rgb(0, 0, 0))",
    borderImageSlice: 1,
    //position: 'relative'
  },
  ActionBoxDetailView: {
    position: "absolute",
    right: "10px",
    width: "80%",
    height: "45px",
    marginTop: "-10px",
    //position: 'relative'
  },
  AddChipButton: {
    marginTop: "23px",
    position: "absolute",
    right: "-20px",
  },
  AddChipCircle: {
    height: "20px",
    position: "absolute",
    background: "#888",
    marginTop: "10px",
    borderRadius: "45px",
    right: "10px",
    width: "20px",
  },
  IconInChip: {
    marginRight: "5px",
    fontSize: "16px",
  },
  ChipTitle: {
    //left: "40px",
    //position: "absolute"
    fontSize: "16px",
  },
  ChipTitleRequired: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  ChipFromTemplateTitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    height: "12px",
    marginLeft: "10px",
  },
  Error: {
    background: `${errorColor}AA`,
  },
  InfoIcon: {
    margin: "0 2px",
    opacity: 0.6,
  },
});

export default styles;
