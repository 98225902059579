import axios from "axios";

export async function getCurrentCountryCode() {
  let country = "KN";

  try {
    const information = await axios.get("https://ipapi.co/json");
    country = information.data.country;
  } catch (error) {
    try {
      const information = await axios.get(
        "https://api.ipregistry.co/?key=tryout"
      );
      country = information.data.location.country.code;
    } catch (error) {
      country = "KN";
    }
  }
  return country;
}
