import { Chip,makeStyles } from "@material-ui/core";

import { Black, Teal } from "assets/colors";

const styles = {
  MiniChip: {
    fontSize: "12px",
    fontWeight: 600,
    padding: "0 4px",
    height: "18px",
    minWidth: "18px",
    color: Black,
    backgroundColor: Teal,
  },
  MiniChipLabel: {
    padding: 0,
  },
} as const;

const useStyles = makeStyles(styles);

interface IMiniChip {
  label?: string;
  style?: any;
}

const MiniChip = ({ label, style }: IMiniChip) => {
  const classes = useStyles();
  return (
    <Chip
      classes={{
        root: classes.MiniChip,
        label: classes.MiniChipLabel,
      }}
      label={label}
      style={style}
    />
  );
};

export default MiniChip;
