import React from "react";

import { Avatar, Box, makeStyles } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { capitalize } from "lodash";

import {
  getBillingType,
  getStripeCustomerId,
} from "containers/authentication/utils/clientStatus";
import useOpenStripePortal from "hooks/useOpenStripePortal";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { BILLING_TYPE, IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";

import { ACTION_TYPE } from "../../model/application/UIActionTypes";
import CustomButton from "../Buttons/CustomButton";
import CustomMoreButton, {
  IMoreButtonOptions,
} from "../Buttons/CustomMoreButton";
import CustomTooltip from "../Tooltip/CustomTooltip";
import styles from "./styles";
import UpgradeNudge from "./UpgradeNudge/UpgradeNudge";

export interface INavbarLinksProps {
  privilege: string;
  handleLogout: () => void;
  handleOpenChangePassword: () => void;
  handleGetUserDB: () => void;
  clientOptions: IOption[];
  client: IClient;
  refreshClient: () => void;
  appNotifications: any[];
  showAppNotification: () => void;
  lang: TLang;
  signedInUser: ISignedInUser;
}

const useStyles = makeStyles(styles as any);

export const NavbarLinks: React.FunctionComponent<INavbarLinksProps> = ({
  handleLogout,
  handleOpenChangePassword,
  refreshClient,
  appNotifications,
  showAppNotification,
  lang,
  privilege,
  signedInUser,
  client,
}) => {
  const classes = useStyles();
  const openStripePortal = useOpenStripePortal(client.id);

  const lastUpdate = process.env.REACT_APP_SMALA_VERSION
    ? process.env.REACT_APP_SMALA_VERSION.split(
        /(Release_stagingv3_|Release_developv3_|Release_masterv3_|Release_epic-)/
      )[2].split("-")
    : "";

  const optionsInMoreButton: IMoreButtonOptions[] = [
    {
      action: () => {},
      label: signedInUser.name ? signedInUser.name : "Super Admin",
    },
    {
      action: () => {},
      label: capitalize(privilege),
    },
    {
      action: () => {},
      label: `${lang.mainLayout.lastUpdate} - ${
        lastUpdate !== ""
          ? `${lastUpdate[2]}/${lastUpdate[1]} - ${lastUpdate[3].substring(
              1
            )}:${lastUpdate[4]}`
          : ""
      }`,
    },
    {
      action: handleOpenChangePassword,
      label: lang.mainLayout.changePasswordButton,
    },
    {
      action: () =>
        window.open(
          "https://play.google.com/store/apps/details?id=com.optimetriks.smala&hl=en",
          "_blank"
        ),
      label: lang.mainLayout.goToFPDownloadButton,
    },
    { action: handleLogout, label: lang.mainLayout.logOutButton },
  ];
  const helpButtonOptions: IMoreButtonOptions[] = [
    {
      action: () => window.open("https://learn.fieldproapp.com/en/", "_blank"),
      label: lang.mainLayout.goToHelpCenterButton,
    },
    {
      action: () =>
        window.open(
          "https://optimetriks.atlassian.net/servicedesk/customer/portal/6/group/-1",
          "_blank"
        ),
      label: lang.mainLayout.goToContactSupportButton,
    },
    {
      action: () =>
        window.open(
          "https://optimetriks.atlassian.net/servicedesk/customer/user/requests?page=1&status=open",
          "_blank"
        ),
      label: lang.mainLayout.goToViewSupportRequestsButton,
    },
  ];

  const userCanViewBilling =
    // billing is automated on Stripe
    !!getStripeCustomerId(client) &&
    getBillingType(client) === BILLING_TYPE.AUTOMATED;

  return (
    <>
      <div className={classes.navLinks}>
        {/** Refresh page */}
        <Box display={{ xs: "none", sm: "block" }}>
          <div className={classes.actionWrapper}>
            <UpgradeNudge client={client} />

            <CustomTooltip title={lang.mainLayout.forceSyncButton}>
              <CustomButton
                type={ACTION_TYPE.REFRESH}
                onClick={refreshClient}
              />
            </CustomTooltip>

            {userCanViewBilling && (
              <CustomTooltip title={lang.mainLayout.goToStripePortalButton}>
                <CustomButton
                  type={ACTION_TYPE.STRIPE_PORTAL}
                  onClick={openStripePortal}
                  data-testid="billing-button"
                />
              </CustomTooltip>
            )}

            <CustomTooltip title={lang.mainLayout.documentationButton}>
              <CustomMoreButton
                icon={
                  <HelpIcon
                    aria-label="HelpIcon"
                    style={{ marginRight: "4px" }}
                  />
                }
                options={helpButtonOptions}
                isHelpButton={true}
                lang={lang}
              />
            </CustomTooltip>

            <CustomTooltip title="Setting options">
              <CustomMoreButton
                icon={
                  signedInUser.picture ? (
                    <Avatar
                      style={{ height: "30px", width: "30px" }}
                      src={`${signedInUser.picture}`}
                    />
                  ) : (
                    <AccountCircle aria-label="AccountCircle" />
                  )
                }
                options={optionsInMoreButton}
              />
            </CustomTooltip>

            <CustomTooltip title="Notifications">
              <CustomButton
                type={ACTION_TYPE.NOTIFICATION}
                onClick={showAppNotification}
                badgeContent={appNotifications.length}
              />
            </CustomTooltip>
          </div>
        </Box>
      </div>
    </>
  );
};

export default NavbarLinks;
