import { useState } from "react";

import { Box, Button, List, ListItem, Menu, MenuItem } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import _ from "lodash";
import { useSelector } from "react-redux";

import { GreyDark, secondaryColor } from "assets/colors";
import { BUTTON_TYPES_OUTSIDE_TABLE } from "components/Buttons/CustomButton";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import InputSearch from "components/Input/InputSearch";
import CircularLoaderComponent from "components/Progress/CustomCircularProgress";
import ScrollEdgeListener from "components/ScrollEdgeListener";
import { ITerritory } from "containers/territories/model";
import {
  deleteTerritoriesAction,
  fetchTerritoriesAction,
} from "containers/territories/redux/actions";
import { getTerritories } from "containers/territories/redux/selectors";
import { useActions, useTranslations } from "hooks";
import { formatString } from "lang/utils";

export interface ITerritoryListControlMenuPros {
  onClickEditTerritory: (territory: ITerritory) => void;
}
export function TerritoryListControlMenu({
  onClickEditTerritory,
}: ITerritoryListControlMenuPros) {
  const lang = useTranslations();
  const [searchTerm, setSearchTerm] = useState("");
  const territories = useSelector(getTerritories);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTerritory, setSelectedTerritory] = useState<ITerritory | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteItemAction = useActions(deleteTerritoriesAction);
  const fetchTerritories = useActions(fetchTerritoriesAction);
  const [idsToDelete, setIdsToDelete] = useState([] as string[]);
  const increment = 20;
  const [itemsToShow, setItemsToShow] = useState(
    Math.min(territories.length, increment)
  );
  const langKey =
    lang.containers.lists.subCategories.items.createEditModal.inputTerritories;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  async function handleSearch(searchTerm: string) {
    setSearchTerm(searchTerm);
  }
  const debouncedSearch = _.debounce(handleSearch, 500);
  return (
    <Box>
      <InputSearch name="search" onChange={debouncedSearch} />
      <List>
        {_.map(
          _.slice(
            _.filter(territories, (t) => {
              return t.name.toLowerCase().includes(searchTerm.toLowerCase());
            }),
            0,
            itemsToShow
          ),
          (territory) => {
            return (
              <ListItem
                key={territory.id}
                component={Button}
                onClick={(e) => {
                  handleClick(e);
                  setSelectedTerritory(territory);
                }}
              >
                <Box
                  display={"grid"}
                  gridTemplateColumns={"1fr auto"}
                  width={"100%"}
                >
                  <Box>{territory.name}</Box>
                  <SettingsIcon htmlColor={GreyDark} />
                </Box>
              </ListItem>
            );
          }
        )}

        {/* Render more territories as we reach the bottom of the list */}
        <ScrollEdgeListener
          callback={() => {
            if (itemsToShow < territories.length) {
              setItemsToShow(
                Math.min(itemsToShow + increment, territories.length)
              );
            }
          }}
        />

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
          elevation={1}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              if (selectedTerritory) {
                onClickEditTerritory(selectedTerritory);
              }
            }}
            component={Button}
            startIcon={<EditOutlinedIcon />}
            style={{ fontSize: "14px", fontWeight: 500, width: "100%" }}
          >
            {langKey.createEditModal.editTitle}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              if (selectedTerritory) {
                setIdsToDelete([selectedTerritory.id]);
              }
            }}
            component={Button}
            startIcon={<DeleteIcon />}
            style={{
              fontSize: "14px",
              fontWeight: 500,
              color: secondaryColor,
              width: "100%",
            }}
          >
            {formatString(
              lang.containers.lists.subCategories.territories
                .deleteTerritoryModal.title,
              [""]
            )}
          </MenuItem>
        </Menu>
      </List>

      {!_.isEmpty(idsToDelete) && (
        <CustomDialogWarning
          isOpen
          lang={{
            title: "Delete Territory",
            description:
              "Deleting this territory cannot be undone and all associated data will be permanently removed. Do you want to proceed?",
          }}
          confirmBtnText={lang.actions.delete}
          rootLang={lang}
          modalType={BUTTON_TYPES_OUTSIDE_TABLE.ERROR}
          onConfirmAction={async () => {
            setIsDeleting(true);
            await deleteItemAction(idsToDelete);
            await fetchTerritories();
            setIdsToDelete([]);
            setIsDeleting(false);
          }}
          onClose={() => setIdsToDelete([])}
        >
          <Box height={"70px"}>
            {isDeleting && <CircularLoaderComponent title="" />}
          </Box>
        </CustomDialogWarning>
      )}
    </Box>
  );
}
