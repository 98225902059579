import { IListItem } from "model/entities/ListItem";
import { IActivityReport } from "model/entities/Workflow";

export const DATE_FORMAT = "Do MMMM YYYY";
export const DATE_FORMAT_FULL_DATE = "dddd, MMMM Do YYYY, h:mm:ss a";

export const ACTIVITY_REPORT_METADATA_KEYS: (keyof IActivityReport)[] = [
  "_id",
  "_activity_id",
  "_step_id",
  "_workflow_report_id",
  "_workflow_id",
  "_workflow_version",
  "_activity_version",
  "_displayed_name",
  "_started_at",
  "_due_date",
  "_created_at",
  "_completed_at",
  "_updated_locally_at",
  "_created_locally_at",
  "_updated_at",
  "_created_by",
  "_completed_by",
  "_updated_by",
  "_customer_id",
  "_duration",
  "_visit_id",
  "_status",
  "_backend_version",
  "_user_id",
  "_user_name",
  "_user_email",
  "_user_phone",
  "_updated_source",
  "_completed_source",
  "_assigned_mobile_users",
  "_assigned_teams",
];
export const LIST_ITEMS_METADATA_KEYS: (keyof IListItem)[] = [
  "_id",
  "_active",
  "_owners",
  "_notes",
  "_created_at",
  "_created_locally_at",
  "_created_by",
  "_created_source",
  "_updated_at",
  "_updated_by",
  "_update_source",
  "_updated_locally_at",
  "_displayed_name",
  "_displayed_business_id",
  "_last_visit_date",
  "_last_visit_user_id",
  "_last_visit_user_name",
  "_backend_version",
  "_filename",
];
