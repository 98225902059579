import { IMobileUserTable } from "fieldpro-tools";

import { ITableRowContent } from "model/application/components";
export enum Gender {
  Male = "male",
  Female = "female",
}

interface IUser extends Pick<IMobileUserTable, "is_team_leader"> {
  id: string;
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  created_at: string;
  created_by: string;
  picture?: string;
}

export interface IUserInTable extends ITableRowContent {
  user_id: string;
  name: string;
}

export interface IMobileUser extends IUser {
  phone: string;
  licensed: boolean;
  role: string;
  picture?: string;
  birthday?: string;
  start_date?: string;
  internal_id?: string;
  official_id?: string;
  id_picture?: string;
  gender?: Gender;
  app_version?: string;
  last_sync?: string;
  user_start_location?: {
    lat: number;
    lng: number;
    acc: number;
  };
}

export interface IMobileUserInTable extends IUserInTable {
  phone: string;
  teamNb: number;
  licensed: boolean;
  role?: string;
  appVersion?: string;
  internalId?: string;
  lastSync?: string;
}

export interface IWebUser extends IUser {
  role: string;
  picture?: string;
  business_role?: string;
  last_login?: string;
  clients?: string[];
}

export interface IWebUserInTable extends IUserInTable {
  email: string;
  role: string;
}

export interface ISignedInUser {
  id: string;
  name?: string;
  picture?: string;
  email: string;
  isAuthenticated: boolean;
  keepSignedIn: boolean;
  createdAt?: string;
}

export default IUser;
