import React from "react";

import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddCircle from "@material-ui/icons/AddCircle";
import Lock from "@material-ui/icons/Lock";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

import styles from "./styles";

export interface ICustomChipProps {
  index?: number;
  value?: number | string;
  label: string;
  undetermined?: boolean;
  onAction?: (elementIdx: number, value?: number | string) => void;
  onClick?: (elementIdx: number, value?: number | string) => void;
  type?: "display" | "locked" | "add" | "remove";
  classes: any;
}

export class CustomChip extends React.Component<ICustomChipProps> {
  handleAction = () => {
    const { onAction, index, value } = this.props;
    if (onAction) {
      onAction(index ? index : 0, value);
    }
  };

  handleClick = () => {
    const { onClick, index, value } = this.props;
    if (onClick) {
      onClick(index ? index : 0, value);
    }
  };

  render() {
    const { classes, label, type, index, undetermined, onAction } = this.props;
    if (type === "display") {
      return (
        <Chip
          className={
            undetermined ? classes.chipDisplayUndetermined : classes.chipDisplay
          }
          key={index}
          label={label}
        />
      );
    } else if (type === "locked") {
      return (
        <Chip
          className={
            undetermined
              ? classes.chipDisplayUndetermined
              : classes.chipSelected
          }
          clickable={true}
          key={index}
          label={label}
          icon={
            <Lock className={classes.whiteButton} aria-label={"LockIcon"} />
          }
          onClick={this.handleClick}
        />
      );
    } else {
      let className;
      if (type === "add") {
        className = classes.chipUnselected;
      } else {
        className = classes.chipSelected;
      }
      if (undetermined) {
        className = classes.chipUndetermined;
      }
      let deleteIcon;
      if (onAction) {
        deleteIcon =
          type === "add" ? (
            <AddCircle className={classes.greyButton} aria-label={"AddIcon"} />
          ) : (
            <RemoveCircle
              className={classes.whiteButton}
              aria-label={"RemoveIcon"}
            />
          );
      } else {
        deleteIcon = <></>;
      }
      return (
        <Chip
          key={index}
          label={label}
          onDelete={this.handleAction}
          deleteIcon={deleteIcon}
          onClick={this.handleClick}
          className={className}
        />
      );
    }
  }
}

export default withStyles(styles as any)(CustomChip);
