export const CREATE_WORKFLOW_BEGIN = "CREATE_WORKFLOW_BEGIN";
export const CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS";
export const CREATE_WORKFLOW_FAILURE = "CREATE_WORKFLOW_FAILURE";

export const FETCH_ALL_WORKFLOWS_BEGIN = "FETCH_ALL_WORKFLOWS_BEGIN";
export const FETCH_ALL_WORKFLOWS_SUCCESS = "FETCH_ALL_WORKFLOWS_SUCCESS";
export const FETCH_ALL_WORKFLOWS_FAILURE = "FETCH_ALL_WORKFLOWS_FAILURE";

export const UPDATE_WORKFLOW_BEGIN = "UPDATE_WORKFLOW_BEGIN";
export const UPDATE_WORKFLOW_SUCCESS = "UPDATE_WORKFLOW_SUCCESS";
export const UPDATE_WORKFLOW_FAILURE = "UPDATE_WORKFLOW_FAILURE";

export const REORDER_WORKFLOW_BEGIN = "REORDER_WORKFLOW_BEGIN";
export const REORDER_WORKFLOW_SUCCESS = "REORDER_WORKFLOW_SUCCESS";
export const REORDER_WORKFLOW_FAILURE = "REORDER_WORKFLOW_FAILURE";

export const DELETE_WORKFLOW_BEGIN = "DELETE_WORKFLOW_BEGIN";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_FAILURE = "DELETE_WORKFLOW_FAILURE";

export const ARCHIVE_WORKFLOW_BEGIN = "ARCHIVE_WORKFLOW_BEGIN";
export const ARCHIVE_WORKFLOW_SUCCESS = "ARCHIVE_WORKFLOW_SUCCESS";
export const ARCHIVE_WORKFLOW_FAILURE = "ARCHIVE_WORKFLOW_FAILURE";

export const RESTORE_WORKFLOW_BEGIN = "RESTORE_WORKFLOW_BEGIN";
export const RESTORE_WORKFLOW_SUCCESS = "RESTORE_WORKFLOW_SUCCESS";
export const RESTORE_WORKFLOW_FAILURE = "RESTORE_WORKFLOW_FAILURE";

export const RESTORE_ACTIVITY_BEGIN = "RESTORE_ACTIVITY_BEGIN";
export const RESTORE_ACTIVITY_SUCCESS = "RESTORE_ACTIVITY_SUCCESS";
export const RESTORE_ACTIVITY_FAILURE = "RESTORE_ACTIVITY_FAILURE";

export const FETCH_WORKFLOWS_FOR_CLIENT_SUCCESS =
  "FETCH_WORKFLOWS_FOR_CLIENT_SUCCESS";

export const CREATE_WORKFLOW_REPORT_BEGIN = "CREATE_WORKFLOW_REPORT_BEGIN";
export const CREATE_WORKFLOW_REPORT_SUCCESS = "CREATE_WORKFLOW_REPORT_SUCCESS";
export const CREATE_WORKFLOW_REPORT_FAILURE = "CREATE_WORKFLOW_REPORT_FAILURE";

export const UPDATE_WORKFLOW_REPORT_BEGIN = "UPDATE_WORKFLOW_REPORT_BEGIN";
export const UPDATE_WORKFLOW_REPORT_SUCCESS = "UPDATE_WORKFLOW_REPORT_SUCCESS";
export const UPDATE_WORKFLOW_REPORT_FAILURE = "UPDATE_WORKFLOW_REPORT_FAILURE";

export const CREATE_ACTIVITY_BEGIN = "CREATE_ACTIVITY_BEGIN";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "CREATE_ACTIVITY_FAILURE";

export const FETCH_ALL_ACTIVITIES_BEGIN = "FETCH_ALL_ACTIVITIES_BEGIN";
export const FETCH_ALL_ACTIVITIES_SUCCESS = "FETCH_ALL_ACTIVITIES_SUCCESS";
export const FETCH_ALL_ACTIVITIES_FAILURE = "FETCH_ALL_ACTIVITIES_FAILURE";

export const UPDATE_ACTIVITY_BEGIN = "UPDATE_ACTIVITY_BEGIN";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";

export const DELETE_ACTIVITY_BEGIN = "DELETE_ACTIVITY_BEGIN";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const ARCHIVE_ACTIVITY_BEGIN = "ARCHIVE_ACTIVITY_BEGIN";
export const ARCHIVE_ACTIVITY_SUCCESS = "ARCHIVE_ACTIVITY_SUCCESS";
export const ARCHIVE_ACTIVITY_FAILURE = "ARCHIVE_ACTIVITY_FAILURE";

export const FETCH_ACTIVITY_REPORTS_BEGIN = "FETCH_ACTIVITY_REPORTS_BEGIN";
export const FETCH_ACTIVITY_REPORTS_SUCCESS = "FETCH_ACTIVITY_REPORTS_SUCCESS";
export const FETCH_ACTIVITY_REPORTS_FAILURE = "FETCH_ACTIVITY_REPORTS_FAILURE";

export const FETCH_AUGMENTED_ACTIVITY_REPORTS_SUCCESS =
  "FETCH_AUGMENTED_ACTIVITY_REPORTS_SUCCESS";
export const FETCH_AUGMENTED_ACTIVITY_REPORTS_FAILURE =
  "FETCH_AUGMENTED_ACTIVITY_REPORTS_FAILURE";
export const FETCH_AUGMENTED_ACTIVITY_REPORTS_BEGIN =
  "FETCH_AUGMENTED_ACTIVITY_REPORTS_BEGIN";

export const DOWNLOAD_REPORT_RAW_DATA_BEGIN = "DOWNLOAD_REPORT_RAW_DATA_BEGIN";
export const DOWNLOAD_REPORT_RAW_DATA_SUCCESS =
  "DOWNLOAD_REPORT_RAW_DATA_SUCCESS";
export const DOWNLOAD_REPORT_RAW_DATA_FAILURE =
  "DOWNLOAD_REPORT_RAW_DATA_FAILURE";

export const FETCH_ACTIVITY_REPORT_DETAIL_BEGIN =
  "FETCH_ACTIVITY_REPORT_DETAIL_BEGIN";
export const FETCH_ACTIVITY_REPORT_DETAIL_SUCCESS =
  "FETCH_ACTIVITY_REPORT_DETAIL_SUCCESS";
export const FETCH_ACTIVITY_REPORT_DETAIL_FAILURE =
  "FETCH_ACTIVITY_REPORT_DETAIL_FAILURE";

export const DELETE_ACTIVITY_REPORT_BEGIN = "DELETE_ACTIVITY_REPORT_BEGIN";
export const DELETE_ACTIVITY_REPORT_SUCCESS = "DELETE_ACTIVITY_REPORT_SUCCESS";
export const DELETE_ACTIVITY_REPORT_FAILURE = "DELETE_ACTIVITY_REPORT_FAILURE";

export const CREATE_ACTIVITY_REPORT_BEGIN = "CREATE_ACTIVITY_REPORT_BEGIN";
export const CREATE_ACTIVITY_REPORT_SUCCESS = "CREATE_ACTIVITY_REPORT_SUCCESS";
export const CREATE_ACTIVITY_REPORT_FAILURE = "CREATE_ACTIVITY_REPORT_FAILURE";

export const UPDATE_ACTIVITY_REPORT_BEGIN = "UPDATE_ACTIVITY_REPORT_BEGIN";
export const UPDATE_ACTIVITY_REPORT_SUCCESS = "UPDATE_ACTIVITY_REPORT_SUCCESS";
export const UPDATE_ACTIVITY_REPORT_FAILURE = "UPDATE_ACTIVITY_REPORT_FAILURE";

export const SET_ACTIVITY_REPORT_SORTING = "SET_ACTIVITY_REPORT_SORTING";

export const RESET_ACTIVITY_REPORT_SELECTION =
  "RESET_ACTIVITY_REPORT_SELECTION";

export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const CLEAR_DATA = "CLEAR_DATA";

export const FETCH_WORKFLOW_REPORTS_BEGIN = "FETCH_WORKFLOW_REPORTS_BEGIN";
export const FETCH_WORKFLOW_REPORTS_SUCCESS = "FETCH_WORKFLOW_REPORTS_SUCCESS";
export const FETCH_WORKFLOW_REPORTS_FAILURE = "FETCH_WORKFLOW_REPORTS_FAILURE";

// SINGLE WORKFLOW REPORT
export const FETCH_WORKFLOW_REPORT_BEGIN = "FETCH_WORKFLOW_REPORT_BEGIN";
export const FETCH_WORKFLOW_REPORT_SUCCESS = "FETCH_WORKFLOW_REPORT_SUCCESS";
export const FETCH_WORKFLOW_REPORT_FAILURE = "FETCH_WORKFLOW_REPORT_FAILURE";

export const DELETE_WORKFLOW_REPORT_BEGIN = "DELETE_WORKFLOW_REPORT_BEGIN";
export const DELETE_WORKFLOW_REPORT_SUCCESS = "DELETE_WORKFLOW_REPORT_SUCCESS";
export const DELETE_WORKFLOW_REPORT_FAILURE = "DELETE_WORKFLOW_REPORT_FAILURE";

export const STOP_WORKFLOW_REPORT_EXECUTION_SUCCESS =
  "STOP_WORKFLOW_REPORT_EXECUTION_SUCCESS";
export const STOP_WORKFLOW_REPORT_EXECUTION_BEGIN =
  "STOP_WORKFLOW_REPORT_EXECUTION_BEGIN";
export const STOP_WORKFLOW_REPORT_EXECUTION_FAILURE =
  "STOP_WORKFLOW_REPORT_EXECUTION_FAILURE";

export const RESUME_WORKFLOW_REPORT_EXECUTION_SUCCESS =
  "RESUME_WORKFLOW_REPORT_EXECUTION_SUCCESS";
export const RESUME_WORKFLOW_REPORT_EXECUTION_BEGIN =
  "RESUME_WORKFLOW_REPORT_EXECUTION_BEGIN";
export const RESUME_WORKFLOW_REPORT_EXECUTION_FAILURE =
  "RESUME_WORKFLOW_REPORT_EXECUTION_FAILURE";

export const FETCH_JOB_LOGS_BEGIN = "FETCH_JOB_LOGS_BEGIN";
export const FETCH_JOB_LOGS_SUCCESS = "FETCH_JOB_LOGS_SUCCESS";
export const FETCH_JOB_LOGS_FAILURE = "FETCH_JOB_LOGS_FAILURE";

export const FETCH_ACTIVITY_VERSION_SCHEMA_BEGIN =
  "FETCH_ACTIVITY_VERSION_SCHEMA_BEGIN";
export const FETCH_ACTIVITY_VERSION_SCHEMA_SUCCESS =
  "FETCH_ACTIVITY_VERSION_SCHEMA_SUCCESS";
export const FETCH_ACTIVITY_VERSION_SCHEMA_FAILURE =
  "FETCH_ACTIVITY_VERSION_SCHEMA_FAILURE";

export const CHANGE_SUBCATEGORY_SELECTED_SUCCESS =
  "CHANGE_WORKFLOW_SUBCATEGORY_SELECTED_SUCCESS";

export const CHANGE_LIST_QUESTION_TAG_FAILURE =
  "CHANGE_LIST_QUESTION_TAG_FAILURE";
export const CHANGE_LIST_QUESTION_TAG_SUCCESS =
  "CHANGE_LIST_QUESTION_TAG_SUCCESS";
export const CHANGE_LIST_QUESTION_TAG_BEGIN = "CHANGE_LIST_QUESTION_TAG_BEGIN";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";
