import { IVisitEvent, TCalendarEvent } from "fieldpro-tools";

import { getItemLabel } from "containers/lists/utils/getItemLabel";
import { IList } from "model/entities/List";

import BoardItem from "../BoardItem";

interface GetEventPlaceHoldersParams {
  newEventObject: Partial<IVisitEvent> | undefined;
  dialogViewMode?: string;
  allowCreation?: boolean;
  openDialog?: boolean;
  customersList?: IList;
}

export function getEventPlaceHolders({
  newEventObject,
  dialogViewMode = "",
  allowCreation = false,
  openDialog = false,
  customersList,
}: GetEventPlaceHoldersParams) {
  let customerName: string | undefined = undefined;

  const { start_time, end_time, repetition, assigned_to, customer_id } =
    newEventObject || {};

  if (dialogViewMode === "EDIT") return [];

  if (customer_id && customersList) {
    const customerItem = customersList.items.find(
      (item) => item._id === customer_id
    );
    customerName = customerItem ? getItemLabel(customerItem) : undefined;
  }

  if (allowCreation && openDialog && start_time && end_time) {
    return [
      {
        start_time,
        end_time,
        repetition,
        assigned_to,
        customer_id,
        customerName,
        component: (
          <BoardItem
            event={newEventObject as TCalendarEvent}
            onClickDelete={() => {}}
            onEditEvent={() => {}}
            boxProps={{
              style: {
                opacity: "0.7",
              },
            }}
          />
        ),
      },
    ];
  }
  return [];
}
