import { useState } from "react";

import { Chip, Grid } from "@material-ui/core";
import _ from "lodash";

import useTranslations from "hooks/useTranslations";

export interface ICustomChipData {
  tag: string;
  label: string;
}
interface IPictureGalleryChipsProps {
  data: ICustomChipData[];
  onChipClick: (label: string | undefined) => any;
  selectedTag: string | undefined;
  hideAll?: boolean;
}
function CustomChips({
  data,
  onChipClick,
  selectedTag,
  hideAll,
}: IPictureGalleryChipsProps) {
  const labels: Array<string> = [];
  const lang = useTranslations();
  const [fallBack, setFallBack] = useState(true);

  const Chips = _.map(data, (item) => {
    const itemLabelValue: any = item.label;

    if (labels.includes(itemLabelValue)) {
      return null;
    }

    labels.push(itemLabelValue);
    const _predicate = selectedTag === item.tag;

    if (_predicate && fallBack) {
      setFallBack(false); // TODO: no setState in render
    }

    return (
      <Grid item key={item.tag}>
        <Chip
          color={_predicate ? "secondary" : "default"}
          label={itemLabelValue}
          onClick={() => {
            setFallBack(false);
            onChipClick(item.tag);
          }}
        />
      </Grid>
    );
  });

  return (
    <Grid container direction="row" spacing={2}>
      {!hideAll ? (
        <Grid item>
          <Chip
            color={fallBack ? "secondary" : "default"}
            label={lang.genericTerms.All}
            onClick={() => {
              setFallBack(true);
              onChipClick(undefined);
            }}
          />
        </Grid>
      ) : null}

      {_.compact(Chips)}
    </Grid>
  );
}

export default CustomChips;
