import React from "react";

import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

import CustomButton, { BUTTON_COLOR } from "components/Buttons/CustomButton";
import CustomMoreButton from "components/Buttons/CustomMoreButton";
import CircularLoaderComponent from "components/Progress/CustomCircularProgress";
import { TRowAction } from "components/Table/model";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";

import styles from "../styles";
import {
  checkIfActive,
  checkIfDeletePossible,
  isContinueExecutionPossible,
  isResumeExecutionPossible,
  isStopExecutionPossible,
} from "./CustomTableRow.utils";

export interface ICustomTableActionRowProps {
  classes: any;
  rowItem: any;
  onEditItem?: TRowAction;
  onDeleteItem?: TRowAction;
  onArchiveItem?: TRowAction;
  onRestoreItem?: TRowAction;
  onRestoreVersion?: TRowAction;
  onLicensedItem?: TRowAction;
  onExtraActions?: (action: TRowAction) => void;
  extraActions?: ITableAction[];
  onViewItem?: TRowAction;
  onStopExecution?: TRowAction;
  onResumeExecution?: TRowAction;
  onContinueExecution?: TRowAction;
  onReplicateItem?: TRowAction;
  onMouseEntering?: () => void;
  onMouseLeaving?: () => void;
  isHovered?: boolean;
  actionBlocked?: boolean;
}

/**
 * TableRow stateless component. A Single Table row
 * @param {Object} Props passed in to Table row
 */
const CustomTableActionRow: React.FunctionComponent<
  ICustomTableActionRowProps
> = ({
  classes,
  rowItem,
  extraActions,
  onEditItem,
  onDeleteItem,
  onArchiveItem,
  onRestoreItem,
  onRestoreVersion,
  onExtraActions,
  onViewItem,
  onLicensedItem,
  onStopExecution,
  onResumeExecution,
  onContinueExecution,
  onReplicateItem,
  onMouseEntering = () => {},
  onMouseLeaving = () => {},
  isHovered = false,
  actionBlocked = false,
}) => {
  const handleEditEvent: () => void = () => {
    onEditItem && onEditItem(rowItem);
  };
  const handleLicensedEvent: () => void = () => {
    onLicensedItem && onLicensedItem(rowItem);
  };
  const handleDeleteEvent: () => void = () => {
    onDeleteItem && onDeleteItem(rowItem);
  };
  const handleArchiveEvent: () => void = () => {
    onArchiveItem && onArchiveItem(rowItem);
  };
  const handleRestoreEvent: () => void = () => {
    onRestoreItem && onRestoreItem(rowItem);
  };
  const handleRestoreVersionEvent: () => void = () => {
    onRestoreVersion && onRestoreVersion(rowItem);
  };
  const handleViewEvent: () => void = () => {
    onViewItem && onViewItem(rowItem);
  };
  const handleStopExecutionEvent: () => void = () => {
    onStopExecution && onStopExecution(rowItem);
  };
  const handleResumeExecutionEvent: () => void = () => {
    onResumeExecution && onResumeExecution(rowItem);
  };
  const handleContinueExecutionEvent: () => void = () => {
    onContinueExecution && onContinueExecution(rowItem);
  };
  const handleReplicateExecutionEvent: () => void = () => {
    onReplicateItem && onReplicateItem(rowItem);
  };

  /** Create table actions */
  const buildActionButtons = () => {
    const isDeletePossible = checkIfDeletePossible(rowItem);
    const isStopPossible = isStopExecutionPossible(rowItem);
    const isResumePossible = isResumeExecutionPossible(rowItem);
    const isContinuePossible = isContinueExecutionPossible(rowItem);
    const isActive = checkIfActive(rowItem);

    let doesThisItemHasActions = false;
    if (
      isActive &&
      (onEditItem ||
        onArchiveItem ||
        onRestoreVersion ||
        onReplicateItem ||
        onLicensedItem ||
        onExtraActions)
    ) {
      doesThisItemHasActions = true;
    }
    if ((!isActive && (onDeleteItem || onRestoreItem)) || onViewItem) {
      doesThisItemHasActions = true;
    }
    if (isActive && !onRestoreItem && !onArchiveItem && onDeleteItem) {
      doesThisItemHasActions = true;
    }
    if (onStopExecution && isStopPossible) {
      doesThisItemHasActions = true;
    }
    if (onContinueExecution && isContinuePossible) {
      doesThisItemHasActions = true;
    }

    const actionStyle: any = {
      opacity: isHovered || rowItem._is_loading ? "1" : "0",
    };

    if (doesThisItemHasActions) {
      // this particular row has action
      if (rowItem._is_loading) {
        // the row is loading
        return (
          <td
            key="actions"
            style={actionStyle}
            className={classes.ActionRow}
            onMouseEnter={onMouseEntering}
            onMouseLeave={onMouseLeaving}
            onClick={onClickStopPropagation}
          >
            <CircularLoaderComponent small={true} inRow={true} />
          </td>
        );
      } else {
        // the row is active, we build the different actions
        return (
          <td
            key="actions"
            style={actionStyle}
            className={classes.TableCellActions}
            onMouseEnter={onMouseEntering}
            onMouseLeave={onMouseLeaving}
            onClick={onClickStopPropagation}
          >
            <Box className={classes.TableCellActionsContainer}>
              <Box
                className="cell-gradient"
                style={{
                  position: "absolute",
                  left: "-8px",
                  width: "8px",
                  height: "49px",
                }}
              />

              {!actionBlocked ? (
                <>
                  {onDeleteItem && isDeletePossible ? (
                    <CustomButton
                      onClick={handleDeleteEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      isTableAction={false}
                      title="Delete"
                      name="Delete"
                      color={BUTTON_COLOR.ERROR}
                      type={ACTION_TYPE.DELETE}
                    />
                  ) : null}

                  {onEditItem && isActive ? (
                    <CustomButton
                      onClick={handleEditEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      name="Edit"
                      isTableAction={false}
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.EDIT}
                    />
                  ) : null}

                  {onLicensedItem && isActive ? (
                    <CustomButton
                      onClick={handleLicensedEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="License"
                      title={"License"}
                      hasALicense={rowItem.licensed}
                      isTableAction={false}
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.LICENSE}
                    />
                  ) : null}

                  {onViewItem && !actionBlocked ? (
                    <CustomButton
                      style={{ float: "right" }}
                      onClick={handleViewEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="View"
                      name="View"
                      isTableAction={false}
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.DETAIL}
                    />
                  ) : null}

                  {onArchiveItem && isActive && !actionBlocked ? (
                    <CustomButton
                      onClick={handleArchiveEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="Archive"
                      isTableAction={false}
                      title="Archive"
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.ARCHIVE}
                    />
                  ) : null}

                  {onRestoreItem && isDeletePossible && !actionBlocked ? (
                    <CustomButton
                      onClick={handleRestoreEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      isTableAction={false}
                      title="Restore"
                      name="Restore"
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.RESTORE}
                    />
                  ) : null}

                  {onReplicateItem && isActive && !actionBlocked ? (
                    <CustomButton
                      onClick={handleReplicateExecutionEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Replicate"
                      name="Replicate"
                      isTableAction={false}
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.REPLICATE}
                    />
                  ) : null}

                  {onRestoreVersion && isActive && !actionBlocked ? (
                    <CustomButton
                      onClick={handleRestoreVersionEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="Restore"
                      isTableAction={false}
                      title="RestoreVersion"
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.RESTORE_VERSION}
                    />
                  ) : null}

                  {onStopExecution && isStopPossible && !actionBlocked ? (
                    <CustomButton
                      style={{ float: "right" }}
                      onClick={handleStopExecutionEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="Stop"
                      isTableAction={false}
                      title="stopExecution"
                      color={BUTTON_COLOR.ERROR}
                      type={ACTION_TYPE.STOP}
                    />
                  ) : null}

                  {onResumeExecution && isResumePossible && !actionBlocked ? (
                    <CustomButton
                      style={{ float: "right" }}
                      onClick={handleResumeExecutionEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="Resume"
                      isTableAction={false}
                      title="resumeExecution"
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.RESUME}
                    />
                  ) : null}

                  {onContinueExecution &&
                  isContinuePossible &&
                  !actionBlocked ? (
                    <CustomButton
                      style={{ float: "right" }}
                      onClick={handleContinueExecutionEvent}
                      data-toggle="tooltip"
                      data-placement="top"
                      name="Continue"
                      isTableAction={false}
                      title="ContinueExecution"
                      color={BUTTON_COLOR.SUCCESS}
                      type={ACTION_TYPE.CONTINUE}
                    />
                  ) : null}

                  {onExtraActions && extraActions && !actionBlocked ? (
                    <CustomMoreButton
                      options={extraActions.map((act) => ({
                        label: act.label,
                        action: act.action as (id?: string) => void,
                      }))}
                      onAction={onExtraActions}
                      data-toggle="tooltip"
                      data-placement="top"
                      isTableAction={true}
                    />
                  ) : null}
                </>
              ) : null}

              {onViewItem && rowItem.isWorkflowReport && actionBlocked ? (
                <CustomButton
                  style={{ float: "right" }}
                  onClick={handleViewEvent}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View"
                  name="View"
                  isTableAction={false}
                  color={BUTTON_COLOR.SUCCESS}
                  type={ACTION_TYPE.DETAIL}
                />
              ) : null}
            </Box>
          </td>
        );
      }
    } else {
      // no action for this particular row, but might be actions for the other. We append with an empty TableCell
      return <TableCell className={classes.TableCell} />;
    }
  };
  return buildActionButtons();
};

const onClickStopPropagation = (e: any) => {
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }
};

export default withStyles(styles as any)(CustomTableActionRow);
