import {
  CUSTOM_FIELD_TYPE,
  IActivityReport as IBEActivityReport,
  IDSource,
  IWorkflowStep,
} from "fieldpro-tools";
import _ from "lodash";

import { ICustomField } from "model/application/DynamicObjects";
import { STEP_TYPE } from "model/entities/Job";
import { ITeamSelector } from "model/entities/Team";
import { ISignedInUser } from "model/entities/User";
import {
  IActivity,
  IWorkflow,
  IWorkflowReportWithStepOverview,
  STEP_STATUS,
} from "model/entities/Workflow";
import { formatFileFieldUploadValue } from "utils/fields/formatFileFieldValue";
import {
  getNextStepActivity,
  getNextStepId,
} from "utils/resolveChaining/nextStep";

import convertActivityReport from "../subcategories/activities/utils/convertActivityReport";
import { resolveMetaExpressionInStringInBE } from "../subcategories/workflows/utils/metaResolver";

export const ACTIVITY_REPORT_META_KEYS = [
  "id",
  "activity_id",
  "activity_version",
  "step_id",
  "workflow_report_id",
  "workflow_id",
  "workflow_version",
  "activity_version",
  "displayed_name",
  "started_at",
  "due_date",
  "created_at",
  "completed_at",
  "updated_locally_at",
  "created_locally_at",
  "updated_at",
  "created_by",
  "completed_by",
  "updated_by",
  "customer_id",
  "duration",
  "visit_id",
  "status",
  "backend_version",
];

const getRowsAndCellsFromSubmissions = (submissions: any[]) => {
  let csvContent = [];
  const attributMap = {};
  for (const submission of submissions) {
    const row = [];
    // first add all the "known" attributes
    for (const attr in attributMap) {
      if (submission[attr] != null) {
        if (typeof submission[attr] === "object") {
          row.push(
            `"${JSON.stringify(submission[attr]).split('"').join('""')}"`
          );
          // csvContent += `"${JSON.stringify(submission[attr]).split('"').join('""')}",`;
        } else if (typeof submission[attr] === "string") {
          row.push(`"${submission[attr].split('"').join('""')}"`);
          // csvContent += `"${submission[attr].split('"').join('""')}",`
        } else {
          row.push(submission[attr]);
          // csvContent += `${submission[attr]},`
        }
      } else {
        row.push("");
        // csvContent += ","
      }
    }
    // then add the missing attributes to attributMap
    const additionnalAttributeMap = {};
    for (const attr in submission) {
      if (!attributMap.hasOwnProperty(attr)) {
        attributMap[attr] = true;
        additionnalAttributeMap[attr] = true;
      }
    }
    for (const attr in additionnalAttributeMap) {
      if (typeof submission[attr] === "object") {
        row.push(`"${JSON.stringify(submission[attr]).split('"').join('""')}"`);
        // csvContent += `"${JSON.stringify(submission[attr]).split('"').join('""')}",`;
      } else if (typeof submission[attr] === "string") {
        row.push(`"${submission[attr].split('"').join('""')}"`);
        // csvContent += `"${submission[attr].split('"').join('""')}",`
      } else {
        row.push(submission[attr]);
        // csvContent += `${submission[attr]},`
      }
    }
    csvContent.push(row);
    // csvContent += "\n";
  }
  // build header
  const header = [];
  // let header = "data:text/csv;charset=utf-8,";
  for (const attr in attributMap) {
    header.push(attr);
    // header += `${attr},`
  }
  // header += "\n";
  // add header with all the columns
  csvContent = [header, ...csvContent];
  return csvContent;
};

const getTeamsForWorkflow = (
  workflowId: string,
  teams: ITeamSelector[]
): string[] => {
  const teamsWithWorkflow: string[] = [];
  teams.forEach((team) => {
    team.workflows.forEach((wf) => {
      if (wf.key === workflowId) {
        if (teamsWithWorkflow.indexOf(team.name) === -1)
          teamsWithWorkflow.push(team.name);
      }
    });
  });
  return teamsWithWorkflow;
};

const getTeamsForActivity = (
  activityId: string,
  teams: ITeamSelector[]
): string[] => {
  const teamsWithActivity: string[] = [];
  teams.forEach((team) => {
    team.workflows.forEach((wf) => {
      if (wf.key === activityId) {
        if (teamsWithActivity.indexOf(team.name) === -1)
          teamsWithActivity.push(team.name);
      }
    });
  });
  return teamsWithActivity;
};

const formatObjectToKeyValuePairs = (obj: any) => {
  return Object.keys(obj).map((k) => {
    return { key: k, value: obj[k] };
  });
};

const getLinkedListIds = (activity: IActivity) => {
  return _.uniq(_.compact(_.map(activity.questionnaire.questions, "list_id")));
};

export const formatUploadFields = (
  uploadsData: any,
  answers: ICustomField[]
) => {
  uploadsData.urls.forEach((upload: any) => {
    const { file, upload_id, url } = upload;
    const attValue = file.split("/")[file.split("/").length - 1];
    answers.forEach((answer) => {
      for (const att in answer) {
        if (answer[att] === attValue) {
          answer[att] = formatFileFieldUploadValue({ url, upload_id });
        }
      }
    });
  });
};

export const isMonoActivityWorkflow = (workflow?: IWorkflow) => {
  if (!workflow) {
    return false;
  }
  return (
    workflow.steps.length === 1 && workflow.steps[0].type === STEP_TYPE.ACTIVITY
  );
};

export const buildReportBody = (
  selectedActivity: IActivity,
  selectedWorkflow: IWorkflow,
  workflowReport: any,
  stepId: string
) => {
  const dateNow = new Date().toISOString();
  const report = {
    id: IDSource(),
    activity_id: selectedActivity.id,
    step_id: stepId,
    workflow_id: selectedWorkflow.id,
    workflow_report_id: workflowReport.id,
    workflow_version: selectedWorkflow.version,
    activity_version: selectedActivity.version,
    started_at: dateNow,
    created_at: dateNow,
    completed_at: dateNow,
    updated_at: dateNow,
    created_by: workflowReport.created_by,
    completed_by: workflowReport.created_by,
    updated_by: workflowReport.updated_by,
    duration: 0,
    status: "DONE",
  };
  return report;
};

export const convertActivityReportToResults = (
  activityReport: any,
  activity: IActivity
): { key: string; type: CUSTOM_FIELD_TYPE; value: any }[] => {
  const questionTags = activity.questionnaire.questions.map((q) => q.tag);
  const result = [];
  for (const tag of questionTags) {
    const type = activity.questionnaire.questions.find(
      (q) => q.tag === tag
    )?.type;
    if (activityReport[tag] && type) {
      result.push({
        key: tag,
        value: activityReport[tag],
        type,
      });
    }
  }
  return result;
};

export const buildWorkflowReportBody = async (
  selectedWorkflow: IWorkflow,
  selectedWorkflowReport: any,
  selectedActivity: IActivity,
  activityReport: any,
  scopeResult: string[],
  activities: IActivity[],
  signedInUser: ISignedInUser
) => {
  const workflowReportId = selectedWorkflowReport?.id ?? IDSource();

  const reportTitleResolved = selectedWorkflow.report_title_template
    ? await resolveMetaExpressionInStringInBE(
        selectedWorkflow.report_title_template,
        {
          workflow_id: selectedWorkflow.id,
          workflow_report_id: workflowReportId,
          activity_report: convertActivityReport({
            questionsSchema: selectedActivity?.questionnaire?.questions ?? [],
            report: activityReport,
            simpleMatrixFormat: true,
          }) as IBEActivityReport,
          get_options_label: true,
        }
      )
    : "-";

  const { stepId, activity } = getNextStepActivity(
    selectedWorkflowReport,
    selectedWorkflow,
    activities
  );
  const stepsOverview =
    selectedWorkflowReport?.step_overview.map((s: any) => ({
      step_id: s.step_id,
      step_scope: s.step_scope,
      step_status: s.step_status,
      scope_result: s.scope_result ?? scopeResult,
      result: s.result,
    })) ?? [];
  if (stepId) {
    // add the new step overview in the list
    const stepSchema = selectedWorkflow.steps.find((s) => s.id === stepId);
    stepsOverview.push({
      step_id: stepSchema?.id,
      step_scope: stepSchema?.scope,
      step_status: STEP_STATUS.DONE,
      scope_result: scopeResult,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      result: convertActivityReportToResults(activityReport, activity!),
    });
    // see if there are some other activity steps after the current one
    const { stepId: futureStepId, activity: futureAct } = getNextStepActivity(
      {
        id: workflowReportId,
        step_overview: stepsOverview,
      } as IWorkflowReportWithStepOverview,
      selectedWorkflow,
      activities
    );
    if (futureStepId) {
      // add the new step overview in the list
      const stepSchema = selectedWorkflow.steps.find(
        (s) => s.id === futureStepId
      );
      stepsOverview.push({
        step_id: stepSchema?.id,
        step_scope: stepSchema?.scope,
        step_status: STEP_STATUS.ONGOING,
        scope_result: scopeResult,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result: convertActivityReportToResults({}, futureAct!),
      });
    }
  }
  const wfReport = {
    id: workflowReportId,
    name: reportTitleResolved,
    status: STEP_STATUS.ONGOING,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    workflow_id: selectedWorkflow!.id,
    created_locally_at:
      selectedWorkflowReport?.created_locally_at ?? new Date().toISOString(),
    created_at: selectedWorkflowReport?.created_at,
    updated_at: selectedWorkflowReport?.updated_at,
    updated_by: signedInUser.id,
    created_by: selectedWorkflowReport?.created_by ?? signedInUser.id,
    version: selectedWorkflowReport?.version ?? selectedWorkflow.version,
    step_overview: stepsOverview,
  };
  wfReport.status = getGlobalStatusOfTheWorkflowReport(
    selectedWorkflow,
    wfReport
  );
  return wfReport;
};

export const getGlobalStatusOfTheWorkflowReport = (
  workflow: IWorkflow,
  workflowReport: any
) => {
  if (!getNextStepId(workflowReport, workflow, false)) {
    return STEP_STATUS.DONE;
  } else {
    return STEP_STATUS.ONGOING;
  }
};
const findPreviousVisitNumber = (steps: IWorkflowStep[], index: number) => {
  for (let i = index - 1; i >= 0; i--) {
    const step = steps[i];
    if (step.visit_number) {
      return step.visit_number;
    }
  }
  return 0;
};

export {
  findPreviousVisitNumber,
  formatObjectToKeyValuePairs,
  getLinkedListIds,
  getRowsAndCellsFromSubmissions,
  getTeamsForActivity,
  getTeamsForWorkflow,
};
