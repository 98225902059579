import {
  BarSeries,
  DensitySeries,
  Histogram,
  withParentSize,
  XAxis,
  YAxis,
} from "@data-ui/histogram";
import * as d3 from "d3";
import { IKPI } from "fieldpro-tools";

import TLang from "model/application/Lang";
import { INivoConfiguration } from "model/entities/Dashboard";

import Chart from "./Chart";

export interface IHistogramProps {
  classes?: any;
  chart: IKPI;
  configuration: INivoConfiguration;
  lang: TLang;
}
export const HistogramChart = ({
  chart,
  configuration,
  lang,
}: IHistogramProps) => {
  const rawData = chart.data
    .map((d: any) => d.x)
    .filter((x: any) => x != undefined);
  const min = chart.min_value || Math.min(...rawData);
  const max = chart.max_value || Math.max(...rawData);
  const numberOfBins = chart.number_of_bins ? chart.number_of_bins : 50;
  const intervals = getIntervals(min, max, numberOfBins);
  const thresholds: any[] = [];
  intervals.forEach((inter, i) => {
    if (i == 0) {
      thresholds.push(inter[0]);
      thresholds.push(inter[1]);
    }
    if (i == intervals.length - 1) {
      thresholds.push(inter[1] + 1);
    } else {
      thresholds.push(inter[1]);
    }
  });
  const histGenerator = d3.bin().domain([min, max]).thresholds(thresholds);
  let bins: any[] = histGenerator(rawData);
  const binValues: number[] = [];
  bins = bins.map((b: any, index) => {
    const count = b.filter((x: any) => x != undefined).length;
    binValues.push(b["x0"]);
    return {
      bin0: b["x0"],
      bin1: b["x1"],
      count: count,
      id: index,
    };
  });

  const height = Chart.getChartHeight(chart.type);
  const margin = Chart.settings[chart.type].margin
    ? Chart.settings[chart.type].margin
    : Chart.defaultMargin;
  return (
    <>
      <ResponsiveHistogram
        orientation="vertical"
        height={height}
        margin={margin}
        cumulative={chart.is_cumulative}
        normalized={chart.is_normalized}
        binValues={binValues}
        binCount={numberOfBins}
        valueAccessor={(datum: any) => datum}
        binType="numeric"
        renderTooltip={({ _event, datum, _data, color }: any) => (
          <div>
            <strong style={{ color }}>
              {datum.bin0} {lang.genericTerms.to} {datum.bin1}
            </strong>
            <div>
              <strong>{lang.genericTerms.count} </strong>
              {datum.count}
            </div>
            {chart.is_cumulative && (
              <div>
                <strong>{lang.genericTerms.cumulative} </strong>
                {datum.cumulative}
              </div>
            )}
            {chart.display_density && (
              <div>
                <strong>{lang.genericTerms.density} </strong>
                {datum.density}
              </div>
            )}
          </div>
        )}
      >
        <BarSeries animated binnedData={bins} fill={configuration.colors[0]} />
        {chart.display_density && (
          <DensitySeries binnedData={bins} fill={configuration.colors[0]} />
        )}
        <XAxis
          {...(chart.x_axis_title && {
            label: chart.x_axis_title,
          })}
        />
        <YAxis
          {...(chart.y_axis_title && {
            label: chart.y_axis_title,
          })}
        />
      </ResponsiveHistogram>
    </>
  );
};
function getIntervals(
  min: number,
  max: number,
  count: number,
  end = min + Math.ceil((max - min + 1) / count) - 1
): Array<Array<number>> {
  return count < 1
    ? []
    : [[min, end], ...getIntervals(end + 1, max, count - 1)];
}
export const ResponsiveHistogram = withParentSize(
  ({ parentWidth, parentHeight, ...rest }: any) => (
    <Histogram width={parentWidth} height={parentHeight} {...rest} />
  )
);
/* https://www.npmjs.com/package/@data-ui/histogram
  https://williaster.github.io/data-ui/?selectedKind=histogram&selectedStory=Playground&full=0&addons=0&stories=1&panelRight=0
*/
