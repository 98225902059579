import { Typography } from "@material-ui/core";

const InputPlaceholder = ({ placeholder }: { placeholder?: string }) => {
  if (!placeholder) {
    return null;
  }
  return (
    <Typography
      style={{
        fontSize: "16px",
        lineHeight: "20px",
        opacity: "0.3",
        marginBottom: "16px",
      }}
    >
      {placeholder}
    </Typography>
  );
};

export default InputPlaceholder;
