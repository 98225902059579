import React from "react";

import { Box, makeStyles } from "@material-ui/core";

import Body from "./Body";
import Drawer from "./Drawer";

export interface IBodyAndDrawerLayoutProps {
  children: React.ReactNode[];
  width?: string | number;
  height?: number | string;
  maxDrawerWidth?: number;
  minDrawerWidth?: number;
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
};
const useStyles = makeStyles(styles as any);

function BodyAndDrawerLayout({
  children,
  width = "100%",
  height = "100%",
  maxDrawerWidth,
  minDrawerWidth,
}: IBodyAndDrawerLayoutProps) {
  const classes = useStyles(styles);

  return (
    <Box className={classes.container} height={height} width={width}>
      <Body>{children ? children[0] : null}</Body>
      <Drawer maxWidth={maxDrawerWidth} minWidth={minDrawerWidth}>
        {children && children.length >= 1 ? children[1] : null}
      </Drawer>
    </Box>
  );
}

export default BodyAndDrawerLayout;
