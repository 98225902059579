interface IHueRange {
  startHue: number;
  endHue: number;
}
export enum GaugeColorRanges {
  RED_TO_YELLOW = "RED_TO_YELLOW",
  RED_TO_GREEN = "RED_TO_GREEN",
  BLUE_TO_PURPLE = "BLUE_TO_PURPLE",
  YELLOW_TO_ORANGE = "YELLOW_TO_ORANGE",
  YELLOW_TO_RED = "YELLOW_TO_RED",
}
export const COLOR_RANGES: Record<GaugeColorRanges, IHueRange> = {
  //TIP: You can pick ranges from https://www.w3schools.com/colors/colors_hsl.asp or https://hslpicker.com/
  [GaugeColorRanges.RED_TO_YELLOW]: { startHue: 0, endHue: 60 },
  [GaugeColorRanges.RED_TO_GREEN]: { startHue: 0, endHue: 120 },
  [GaugeColorRanges.BLUE_TO_PURPLE]: { startHue: 240, endHue: 300 },
  [GaugeColorRanges.YELLOW_TO_ORANGE]: { startHue: 60, endHue: 30 },
  [GaugeColorRanges.YELLOW_TO_RED]: { startHue: 60, endHue: 0 },
};

export function getGaugeColor(
  value: number,
  minValue: number,
  maxValue: number,
  colorRange: GaugeColorRanges = GaugeColorRanges.RED_TO_YELLOW
): string {
  const percentage = (value - minValue) / (maxValue - minValue);
  const hueRange = COLOR_RANGES[colorRange];
  const hue = Math.floor(
    percentage * (hueRange.endHue - hueRange.startHue) + hueRange.startHue
  );
  return `hsl(${hue}, 100%, 50%)`;
}
