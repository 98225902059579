import { TerritoryState } from "./reducer";

export const initialState: TerritoryState = {
  isFetchingTerritoriesItems: false,
  isFetchingOneTerritoryItem: false,
  isUpdatingTerritoriesItems: false,
  isDeletingTerritoriesItems: false,
  isCreatingTerritoriesItems: false,
  isAssigningTerritoriesItems: false,
  isUnassigningTerritoriesItems: false,

  isUpdatingTerritoryItem: false,
  isArchivingTerritoryItem: false,
  isRestoringTerritoryItem: false,
  isCreatingTerritoryItem: false,
  isFetchingTerritorySchema: false,
  isUpdatingTerritorySchema: false,

  isFetchingTerritoryList: false,

  territoriesSchema: [],
  territories: [],
  errors: [],

  filterQuery: {},
};

export default initialState;
