import { META_EXPRESSION_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { CALLED_FROM } from "components/Input/InputMetaExpression/MetaExpressionWizard";
import { IActivity } from "model/entities/Workflow";

export const getFinalResourceId = (
  calledFrom: CALLED_FROM | undefined,
  activities: IActivity[],
  scope?: META_EXPRESSION_SCOPE,
  currentRessourceId?: string,
  calledFromMatrixTag?: string
): string | undefined => {
  if (
    calledFrom === CALLED_FROM.ACTIVITY ||
    calledFrom === CALLED_FROM.WORKFLOW ||
    calledFrom === CALLED_FROM.WORKFLOW_SETTINGS
  ) {
    if (scope && scope === META_EXPRESSION_SCOPE.CUSTOMER) {
      return "customer"; // Customer List ID
    }

    if (
      scope &&
      scope === META_EXPRESSION_SCOPE.ITEM_CELL &&
      calledFromMatrixTag
    ) {
      const currentActivity = _.find(activities, { id: currentRessourceId });
      const currentMatrixQuestion = _.find(
        currentActivity?.questionnaire?.questions,
        { tag: calledFromMatrixTag }
      );

      if (currentMatrixQuestion) {
        return currentMatrixQuestion.list_id;
      }
    }
  }
  return currentRessourceId;
};

export default getFinalResourceId;
