import { IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";

import { resolveMetaExpressionInString } from "containers/workflows/subcategories/workflows/utils/metaResolver";
import { IOperation } from "model/entities/Workflow";

/**
 * Similar (but different) approach to injectMetaExpressionsInComputation
 *
 * Instead of resolving MEs in the BE (or elsewhere) and replacing them in the strings,
 * We return a copy of the operations with the MEs resolved in place thanks to the appropriate metaExpressionOptions
 *
 * @param operation
 * @param metaExpressionOptions
 * @returns copy of the operation with resolved meta expressions
 */
export const resolveMetaExpressionInOperation = (
  operation: IOperation,
  metaExpressionOptions: IMetaExpressionOptions
) => {
  const newOperation = _.cloneDeep(operation);

  if (operation?.first.value) {
    newOperation.first.value = resolveMetaExpressionInString(
      operation.first.value,
      { ...metaExpressionOptions, resolve_in_sum: true }
    );
  }

  if (operation?.second.value) {
    newOperation.second.value = resolveMetaExpressionInString(
      operation.second.value,
      { ...metaExpressionOptions, resolve_in_sum: true }
    );
  }

  return newOperation;
};
