import { Box, makeStyles } from "@material-ui/core";

interface IPictureGalleryPictureProps {
  tag: string;
  url: string;
  width?: string;
  height?: string;
}

const styles = {
  imageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  image: {
    cursor: "pointer",
    borderRadius: "5px",
    transition: "0.2s",
    "&:hover": {
      boxShadow: "0px 0px 5px 5px #f6f6f6",
    },
    objectFit: "cover" as any,
    width: "95%",
  },
};

const useStyles = makeStyles(styles);

function PictureGalleryPicture({
  url,
  width = "100%",
  height = "240px",
}: IPictureGalleryPictureProps) {
  const classes = useStyles();

  return (
    <Box className={classes.imageContainer}>
      <img className={classes.image} src={url} width={width} height={height} />
    </Box>
  );
}

export default PictureGalleryPicture;
