import _ from "lodash";

import { TColumnType } from "components/Table/model";
import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import TLang from "model/application/Lang";
import { IList } from "model/entities/List";
import {
  IActivity,
  IActivityReport,
  IActivityReportInTable,
} from "model/entities/Workflow";
import { getDateSafe } from "utils/dateUtils";
import { formatCustomFieldValues } from "utils/formatting/customFieldValues";

/**
 * Formats the mobile user data to display
 * @returns {Array}
 */
export const formatActivityReport = (
  report: IActivityReport,
  isLoading: boolean,
  selectedActivity: IActivity,
  columnTypes: TColumnType[],
  lists: IList[],
  lang: TLang
): IActivityReportInTable => {
  const formattedMetadata = report && formatReportMetadata(report, isLoading);
  const formattedValues = formatCustomFieldValues({
    customFieldValues: report.customFieldValues,
    fieldsSchema: selectedActivity?.questionnaire?.questions,
    lists,
    lang,
  });

  const res = {
    ...formattedMetadata,
    ...formattedValues,
  };

  const resKeys = Object.keys(res);
  _.map(columnTypes, ({ name }) => {
    if (!_.includes(resKeys, name)) {
      res[name] = report.metadata?.[name];
    }
  });

  return res;
};

export const formatReportMetadata = (
  report: IActivityReport | IAugmentedActivityReport,
  _is_loading: boolean
) => {
  return {
    ...report.metadata,
    ...(_is_loading && { _is_loading }),
    _updated_at: getDateSafe(report.metadata?._updated_at) || "",
    _completed_at: getDateSafe(report.metadata?._completed_at) || "",
    completed_at: report.metadata?._completed_at,
    created_at: report.metadata?._created_at,
    displayed_name: report.metadata?._displayed_name,
    user_name: report.metadata?._user_name,
    created_by: report.metadata?._created_by,
  };
};
