import TLang from "model/application/Lang";
import { IAction } from "redux/store/model";

import * as types from "./actionTypes";
import { TFetchCustomerAttributeServerResponseData } from "./api";
import { ICustomersState } from "./initialState";
import { ICustomerLegendFE } from "./types";

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: any;
}
export interface ISetCustomersLegendActionCreator extends IAction {
  legend: ICustomerLegendFE;
  lang: TLang;
}
export interface ISetCustomerLocationsActionCreator extends IAction {
  customerLocations: ICustomersState["customerLocations"];
}

export interface ISetCustomerAttributeActionCreator extends IAction {
  attributeData: Array<TFetchCustomerAttributeServerResponseData>;
  attributeTag: string;
  isGeoAttribute?: boolean;
}

/**
 * @param {Object} subcategory Can be either "map" or "list"
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: any
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
export function setCustomersLegendActionCreator(
  legend: ICustomerLegendFE,
  lang: TLang
): ISetCustomersLegendActionCreator {
  return {
    type: types.SET_CUSTOMERS_LEGEND,
    legend,
    lang,
  };
}

export function setCustomersAttributeActionCreator(
  attributeTag: string,
  attributeData: TFetchCustomerAttributeServerResponseData[],
  isGeoAttribute?: boolean
): ISetCustomerAttributeActionCreator {
  return {
    type: types.SET_CUSTOMERS_ATTRIBUTE,
    attributeTag,
    attributeData,
    isGeoAttribute,
  };
}

export interface ISetCustomerLocationsActionCreator extends IAction {
  customerLocations: ICustomersState["customerLocations"];
}
export function setCustomerLocationsActionCreator(
  customerLocations: ICustomersState["customerLocations"]
): ISetCustomerLocationsActionCreator {
  return {
    type: types.SET_CUSTOMER_LOCATIONS,
    customerLocations,
  };
}

export function clearCustomerData() {
  return {
    type: types.CLEAR_DATA,
  };
}
