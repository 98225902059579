import { useEffect } from "react";

import { MapboxMap } from "react-map-gl";

interface IUseMapImage {
  map?: MapboxMap;
  path: string;
  name: string;
  sdf?: boolean;
}

export function useMapImage({ map, path, name, sdf = false }: IUseMapImage) {
  // IMPORTANT: re-load image when switching to satellite view
  let mapStyleName = "";
  try {
    mapStyleName = map?.getStyle()?.name ?? "";
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    if (!map) {
      return;
    }

    const image = new Image(80, 80);
    image.src = path;

    image.onload = () => {
      if (map.hasImage(name)) {
        console.warn(`>> Image ${image} already loaded`);
      } else {
        map.addImage(name, image, { sdf });
      }
    };
    image.onerror = (e) => {
      console.warn(`>> Image ${image} failed to load`, e);
    };
  }, [name, sdf, path, map, mapStyleName]);
}
