/**
 * Get a catch block error message
 * https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

export default getErrorMessage;
