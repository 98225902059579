import { IOrder } from "fieldpro-tools";

import { IAction } from "redux/store/model";

import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IOrdersState {
  orders: IOrder[];
  isLoading: boolean;
  isUpdating: boolean;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_ALL_ORDERS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_ALL_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.FETCH_ALL_ORDERS_SUCCESS:
      const { orders } = action as any;
      return {
        ...state,
        isLoading: false,
        orders,
      };

    case types.FETCH_SFA_DATA_SUCCESS: {
      const { dataKey, data } = action as any;
      return {
        ...state,
        isUpdating: false,
        [dataKey]: data,
      };
    }
    default:
      return state;
  }
}
