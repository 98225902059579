import { useState } from "react";

import { Box, Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import { useTranslations } from "hooks";

import { IInputMultipleSelectOnListProps } from "../InputMultipleSelectOnList/InputMultipleSelectOnList";
interface IBulkImportMenuProps
  extends Pick<
    IInputMultipleSelectOnListProps,
    | "BulkModal"
    | "onBulkImport"
    | "onDownload"
    | "showDownloadButton"
    | "langlabel"
    | "bulkModalConfirmBtnText"
    | "bulkModalDialogTitle"
    | "csvHeaderLine"
  > {}
function BulkImportMenu({
  BulkModal,
  onBulkImport,
  onDownload,
  showDownloadButton,
  langlabel,
  bulkModalConfirmBtnText,
  bulkModalDialogTitle,
  csvHeaderLine,
}: IBulkImportMenuProps) {
  const lang = useTranslations();
  const [isBulkOpen, setIsBulkOpen] = useState(false);
  return (
    <Box>
      {onBulkImport && (
        <BulkModal
          isOpen={isBulkOpen}
          onCloseModal={() => setIsBulkOpen(false)}
          onConfirmModal={(e: any) => {
            onBulkImport(e);
            setIsBulkOpen(false);
          }}
          headerLine={csvHeaderLine}
          confirmBtnText={bulkModalConfirmBtnText}
          dialogTitle={bulkModalDialogTitle}
          lang={lang}
          langlabel={langlabel}
          labelInput={langlabel}
        />
      )}
      {(onBulkImport || onDownload) && (
        <Box marginTop="12px" alignItems="start">
          {onBulkImport && BulkModal && (
            <Button
              startIcon={<PersonAddAltIcon />}
              onClick={() => setIsBulkOpen(true)}
              style={{ minWidth: 0 }}
            >
              {
                lang.containers.workflows.subCategories.workflows
                  .createEditModal.bulkAssignTeamsDialog.title
              }
            </Button>
          )}

          {showDownloadButton && onDownload && (
            <Button
              startIcon={<DownloadIcon />}
              onClick={onDownload}
              style={{ minWidth: 0 }}
            >
              {lang.actions.download}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default BulkImportMenu;
