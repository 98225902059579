import { Component } from "react";

import { IErrorBoundaryState } from "model/application/Container";
import TLang from "model/application/Lang";

import FallbackScreen from "../../Errors/FallbackScreen";

export default class ChartErrorBoundary extends Component<
  { lang?: TLang },
  IErrorBoundaryState
> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false,
      error: {},
      info: {},
    };
  }

  /**
   * Catches errors thrown by children
   * @param {Object} error Error thrown
   * @para {Object} info Information of the error Thrown
   * */
  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
      error,
      info,
    });

    // TODO: log these errors to an external service
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      // An appropriate error message
      // render a custom fallback UI
      return (
        <div style={{ overflow: "hidden" }}>
          <FallbackScreen />
        </div>
      );
    }

    // render children if there is no error
    return this.props.children;
  }
}
