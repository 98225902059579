/* -------------------------------------------------------------------------- */
/*                          replaceMePointersWithTags                         */
/* -------------------------------------------------------------------------- */

import { IMetaExpression } from "fieldpro-tools";
import { stripMetaExpression } from "fieldpro-tools/dist/src/metaExpressions/parseRawMetaExpression";
import _ from "lodash";

//this will look for any ${} in the code and replace it with a tag
export function replaceMePointersWithTags(
  code: string,
  metaExpressions: IMetaExpression[]
) {
  if (!code) return "";

  code.match(ME_REGEX)?.forEach((m, i) => {
    const originalExpression = _.find(metaExpressions, {
      expression: stripMetaExpression(m),
    }) as IMetaExpression | undefined;
    code = code.replaceAll(
      m,
      JSON.stringify(convertMeToTag(m, originalExpression?.title || "me" + i))
    );
  });
  return code;
}

export function convertMeToTag(me: string, value?: string) {
  const object = {
    value: value ?? "me",
    expression: me.trim().replace("$", ""),
  };
  return [[object]];
}

export const ME_REGEX = /\$\^(.*?)\^|\$\{([^}]+)\}/gs;
