import { IClient } from "fieldpro-tools";

export function getMapRegion(client: IClient) {
  switch (client?.dbname) {
    case "elephant_vert__maroc":
      return "MA";
    default:
      return undefined;
  }
}
