import { Box } from "@material-ui/core";

import { GreyDark, GreyLight, GreyMedium } from "assets/colors";

export interface IBasicPlaceholderProps {
  title?: string;
  description?: string;
  icon?: JSX.Element;
}
function BasicPlaceholder({
  description,
  title,
  icon,
}: IBasicPlaceholderProps) {
  return (
    <Box
      data-testid="basic-placeholder"
      display={"flex"}
      flexDirection={"column"}
      color={GreyLight}
    >
      {icon ? (
        <Box fontSize={"70px"}>
          <icon.type
            {...(icon.props || {})}
            style={{
              ...(icon.props?.style || {}),
              fontSize: "inherit",
            }}
          />
        </Box>
      ) : null}

      {title && (
        <Box fontWeight={"700"} color={GreyDark}>
          {title}
        </Box>
      )}

      {description && <Box color={GreyMedium}>{description}</Box>}
    </Box>
  );
}

export default BasicPlaceholder;
