import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getLangObject } from "lang/utils";

import styles from "./styles";

const useStyles = makeStyles(styles as any);
const lang = getLangObject();
const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.Wrapper}>
      <Typography variant="h6" className={classes.PageNotFoundTitle}>
        {lang.components.errors.notFoundPageTitle}
      </Typography>
      <Typography variant="h6" className={classes.PageNotFoundSubTitle}>
        {lang.components.errors.notFoundPageSubTitle}
      </Typography>
      <div className={classes.LittleOval}></div>
      <div className={classes.BigOval}></div>
      <div className={classes.Code}>404</div>
    </div>
  );
};

export default NotFound;
