/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import { IHttpRequest as IBEHttpRequest } from "fieldpro-tools";

import apiService from "api/apiService";
import { formatObjectToKeyValuePairs } from "containers/workflows/utils/";
import { IHttpRequest, STEP_TYPE } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareHttpRequestForBackend } from "./utils";

export const HTTP_REQUESTS_ENDPOINT = `http_requests`;
export const HTTP_REQUEST_ENDPOINT = "http_request";

export type TCreateHttpRequestFunc = (
  actionName: string,
  http_request: IHttpRequest
) => Promise<IServerResponse<ICreateHttpRequestResponse>>;
export interface ICreateHttpRequestResponse {
  id: string;
}

export type TUpdateHttpRequestFunc = (
  actionName: string,
  http_request: IHttpRequest
) => Promise<IServerResponse<IUpdateHttpRequestResponse>>;
export interface IUpdateHttpRequestResponse {}

export type TDeleteHttpRequestFunc = (
  actionName: string,
  httpRequestId: string
) => Promise<IServerResponse<IDeleteHttpRequestResponse>>;
export interface IDeleteHttpRequestResponse {}

export type TFetchHttpRequestsFunc = () => Promise<
  IServerResponse<IFetchHttpRequestsResponse>
>;
export interface IFetchHttpRequestsResponse {
  http_requests: IBEHttpRequest[];
}
export type TRunHttpRequestFunc = (
  actionName: string,
  http_request: IHttpRequest
) => Promise<IServerResponse<IRunHttpRequestResponse>>;

export interface IRunHttpRequestResponse {
  success: boolean;
}
//api calls for http_requests
/**
 * Creates a new http_request
 * @param {Object} http_request HttpRequest details
 */
export const createHttpRequestApiCall: TCreateHttpRequestFunc = (
  actionName,
  http_request
) => {
  const payload = prepareHttpRequestForBackend(http_request);
  return apiService.post(`/${HTTP_REQUESTS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a http_request
 * @param {Object} http_request Detail of the new http_request
 */
export const updateHttpRequestApiCall: TUpdateHttpRequestFunc = (
  actionName,
  http_request
) => {
  const payload = prepareHttpRequestForBackend(http_request);
  return apiService.patch(
    `/${HTTP_REQUESTS_ENDPOINT}/${http_request.id}`,
    payload,
    actionName
  );
};

/**
 * Delete http_request API call makes a POST request to delete a single http_request
 * @param {String} http_request Id of the http_request to delete
 * @return {Promise}
 * */
export const deleteHttpRequestApiCall: TDeleteHttpRequestFunc = (
  actionName,
  httpRequestId
) => {
  return apiService.delete(
    `/${HTTP_REQUESTS_ENDPOINT}/${httpRequestId}`,
    {},
    actionName
  );
};

/**
 * Api call to fetch all
 */
export const fetchHttpRequestsApiCall: TFetchHttpRequestsFunc = () => {
  return apiService.get(`/${HTTP_REQUESTS_ENDPOINT}`);
};
/**
 * Creates a new http_request
 * @param {Object} http_request HttpRequest details
 */
export const runHttpRequestJobApiCall: TRunHttpRequestFunc = (
  actionName,
  http_request
) => {
  const requestId = http_request.id;
  const payload = prepareHttpRequestForBackend(http_request);
  return apiService.post(
    `/step`,
    {
      job_type: STEP_TYPE.HTTP_REQUEST,
      schema_id: requestId,
      parameters: formatObjectToKeyValuePairs(payload),
    },
    actionName
  );
};
