import { Button, InputAdornment, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { primaryColor, White } from "assets/colors";
import { TSnackbarType } from "components/Snackbars/CustomSnackbar";
import { useTranslations } from "hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "16px",
  },
  iconButton: {
    padding: 10,
  },
  button: {
    background: primaryColor,
    borderRadius: "8px",
    color: White,

    "&:hover": {
      background: primaryColor,
      opacity: 0.8,
    },
  },
}));

interface IInputURL {
  onCopy?: (message: string, type: TSnackbarType) => void;
  fullWidth?: boolean;
  isEditable?: boolean;
  value: string;
  size?: "small" | "medium";
  name?: string;
}
const InputURL = ({
  onCopy,
  isEditable = false,
  value,
  fullWidth = true,
  size = "small",
  name = "url",
}: IInputURL) => {
  const classes = useStyles();
  const lang = useTranslations();
  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(value);
      onCopy?.(
        lang.notifications.successNotifications.copyInClipboardSuccess,
        "success"
      );
    } catch (error) {
      onCopy?.(
        lang.notifications.errorNotifications.copyInClipboardError,
        "error"
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <TextField
        name={name}
        value={value}
        label="" //No label define for this component
        fullWidth={fullWidth}
        size={size}
        className={classes.input}
        InputProps={{
          readOnly: !isEditable,
          contentEditable: isEditable,
          endAdornment: onCopy ? (
            <InputAdornment position="end">
              <Button onClick={handleCopyText} className={classes.button}>
                Copy
              </Button>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Paper>
  );
};

export default InputURL;
