import { ICondition, STEP_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import {
  IActivity,
  IActivityReportInTable,
  IQuestion,
  IWorkflow,
} from "model/entities/Workflow";
import { activityNeedsCustomer } from "utils/businessUtils";
import { resolveCondition } from "utils/expressions/conditionSolver";
import { injectMetaExpressionsInComputation } from "utils/metaExpressions/metaExpressionInjection";

export const changeReportFromGPS = (gpsAttributeName: string, gps: any) => {
  if (gps.lat === "" && gps.lng === "" && gps.acc === "") {
    // no value is registered for the report, we remove the attribute from the report.
    return [{ name: gpsAttributeName, value: null }];
  } else {
    return [
      {
        name: gpsAttributeName,
        value: {
          lat: gps.lat,
          lng: gps.lng,
          acc: gps.acc,
        },
      },
    ];
  }
};

export const getMetaExpressionsFromActivity = (
  activity: IActivity | undefined
) => {
  if (!activity) return [];
  const result = {};
  _.forEach(activity.questionnaire.questions, (q) => {
    if (q.deprecated) return;
    const stringifiedQuestion = JSON.stringify(q);
    const regex = /\$\{([^}]*)\}/g;
    _.forEach(stringifiedQuestion.match(regex), (r) => (result[r] = true));
  });
  return _.keys(result);
};

export const getMetaExpressionsFromWorkflow = (
  workflow: IWorkflow | undefined
) => {
  if (!workflow) return [];
  const result = {};
  _.forEach(workflow.chainings, (ch) => {
    const stringifiedChaining = JSON.stringify(ch);
    const regex = /\$\{([^}]*)\}/g;
    _.forEach(stringifiedChaining.match(regex), (r) => (result[r] = true));
  });
  return _.keys(result);
};

export const changeReportFromDate = (dateAttributeName: string, date: any) => {
  return [{ name: dateAttributeName, value: new Date(date).toISOString() }];
};

export const CUSTOMER_FIELD_KEY = "_customer_id";

export const getErrorMessagesForActivityReport: TValidateFunction<
  IActivityReportInTable,
  {
    workflow: IWorkflow;
    activity: IActivity;
    formQuestions?: IQuestion[];
    nextStepScope?: STEP_SCOPE;
  }
> = ({ attributes, lang, additionnalProps }) => {
  const { activity, formQuestions, nextStepScope } = additionnalProps;

  const schema = activity.questionnaire.questions;

  const visibleQuestions = formQuestions?.map((q) => q.tag);
  const errors: { [key: keyof IActivityReportInTable]: string } = {};

  if (
    (nextStepScope === STEP_SCOPE.MOBILE_USER ||
      nextStepScope === STEP_SCOPE.SINGLE_MOBILE_USER) &&
    _.isEmpty(attributes["_owners"])
  ) {
    errors["_owners"] = lang.components.inputErrors.empty;
  }

  if (activityNeedsCustomer(activity) && !attributes[CUSTOMER_FIELD_KEY]) {
    errors[CUSTOMER_FIELD_KEY] = lang.components.inputErrors.empty;
  }

  _.forEach(schema, (att) => {
    if (!visibleQuestions?.includes(att.tag)) {
      return;
    }
    if (_.find(formQuestions, { tag: att.tag })?.deprecated) {
      return;
    }
    if (
      att.required &&
      !attributes[att.tag] &&
      visibleQuestions?.includes(att.tag)
    ) {
      errors[att.tag] = lang.components.inputErrors.empty;
    }
  });

  return errors;
};

export const filterQuestionsOnSolvedConditions = (
  questions: IQuestion[],
  mapMetaExpressionSolved: { [metaExpression: string]: string }
): IQuestion[] => {
  const activeQuestions = _.filter(questions, (q) => !q.deprecated);

  const result = _.filter(activeQuestions, (question) => {
    if (!question.conditions) {
      return true;
    }

    const injectedConditions = injectMetaExpressionsInComputation(
      question.conditions,
      mapMetaExpressionSolved
    ) as ICondition[];

    const conditionPassed = resolveCondition(injectedConditions);
    return conditionPassed;
  });
  return result;
};
