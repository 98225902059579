import { isAlphanumericalStartingWithLetter } from "utils/utils";

export const validateOption = (key: string, label: string) => {
  key = key ? key : "";
  label = label ? label : "";
  return {
    key:
      key.length === 0 ||
      !isAlphanumericalStartingWithLetter(key) ||
      key[0] === "_" ||
      key.toLowerCase() !== key,
    label: label.length === 0,
  };
};
