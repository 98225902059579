import { makeStyles, SvgIconProps } from "@material-ui/core";
import HighlightOff from "@material-ui/icons/HighlightOff";
import classNames from "classnames";

import * as colors from "assets/colors";

const useStyles = makeStyles({
  deleteIcon: {
    opacity: 0.7,
    color: colors.GreyDark,

    "&:hover": {
      color: colors.Black,
    },
  },
});

const DeleteIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <HighlightOff
      {...props}
      className={classNames(classes.deleteIcon)}
      onClick={props.onClick}
    />
  );
};

export default DeleteIcon;
