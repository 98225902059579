import React from "react";

import { Box, Button, DialogContentText, makeStyles } from "@material-ui/core";
import Error from "@material-ui/icons/Error";

import { red } from "assets/colors";
import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import TLang, { TConfirmModalLang } from "model/application/Lang";

import {
  BasicCloseIcon,
  BasicDialog,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "./BasicDialog";
import { IBasicDialog } from "./BasicDialog/BasicDialog";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface ICustomDialogWarningProps
  extends Omit<IBasicDialog, "children" | "lang" | "open"> {
  children?: any;
  isOpen?: boolean;
  onClose?: () => void;
  onCancelAction?: () => void;
  onConfirmAction?: (e: any) => void;
  cancelBtnTxt?: string;
  confirmBtnText?: string;
  singleButton?: boolean;
  hideCancelButton?: boolean;
  hideCloseButton?: boolean;
  lang: TConfirmModalLang;
  rootLang: TLang;
  modalType?: BUTTON_TYPES_OUTSIDE_TABLE;
  disableCancel?: boolean;
  disableConfirm?: boolean;
}

/**
 * Stateless component for displaying a BasicDialog with a warning message.
 */
export const CustomDialogWarning: React.FunctionComponent<
  ICustomDialogWarningProps
> = ({
  isOpen = false,
  onClose,
  children,
  onCancelAction,
  onConfirmAction,
  cancelBtnTxt,
  confirmBtnText,
  singleButton = false,
  hideCancelButton = false,
  hideCloseButton = false,
  rootLang,
  lang,
  modalType = BUTTON_TYPES_OUTSIDE_TABLE.WARNING,
  disableCancel,
  disableConfirm,
  ...rest
}) => {
  const title = lang.title ? lang.title : rootLang.modal.warningTitle;
  confirmBtnText = confirmBtnText ? confirmBtnText : rootLang.modal.proceed;

  const classes = useStyles();

  const onClickCancel = () => {
    if (onCancelAction) {
      onCancelAction();
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <BasicDialog
      {...rest}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-BasicDialog-title"
      className={classes.Dialog}
      borderRadius={3}
    >
      <BasicDialogTitle
        id="form-BasicDialog-title"
        className={classes.dialogTitle}
        divider
      >
        <Box display="grid" gridTemplateColumns={"1fr 50px"}>
          <Box
            display={"flex"}
            alignContent="center"
            alignItems={"center"}
            gridGap={"5px"}
            style={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              ...(modalType == BUTTON_TYPES_OUTSIDE_TABLE.ERROR && {
                color: red,
              }),
            }}
          >
            {modalType == BUTTON_TYPES_OUTSIDE_TABLE.ERROR ? (
              <Error style={{ fontSize: "20px" }} />
            ) : null}
            {title}
          </Box>
          <Box>{!hideCloseButton && <BasicCloseIcon onClick={onClose} />}</Box>
        </Box>
      </BasicDialogTitle>
      <BasicDialogContent>
        <DialogContentText component={"div"}>
          {typeof lang.description === "string" ? (
            <div
              className={classes.Description}
              dangerouslySetInnerHTML={{
                __html: lang.description,
              }}
            />
          ) : (
            <div className={classes.Description}>{lang.description || ""}</div>
          )}
        </DialogContentText>
        <br />
        {children}
      </BasicDialogContent>
      <BasicDialogActions className={classes.DialogActions}>
        {!hideCancelButton && (
          <Button
            onClick={onClickCancel}
            disableElevation
            variant="outlined"
            color="secondary"
            disabled={disableCancel}
            style={{
              ...(modalType == BUTTON_TYPES_OUTSIDE_TABLE.ERROR && {
                borderColor: red,
                color: red,
              }),
            }}
          >
            {cancelBtnTxt || rootLang.modal.cancel}
          </Button>
        )}
        {onConfirmAction && !singleButton ? (
          <CustomButton
            onClick={onConfirmAction}
            type={modalType}
            style={{ boxShadow: "unset" }}
            data-testid="confirm-button"
            disabled={disableConfirm}
          >
            {confirmBtnText}
          </CustomButton>
        ) : (
          <></>
        )}
      </BasicDialogActions>
    </BasicDialog>
  );
};

export default CustomDialogWarning;
