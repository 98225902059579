import React, { useEffect } from "react";

import { Box, Popover } from "@material-ui/core";
import { Crisp } from "crisp-sdk-web";

import { GreyDark } from "assets/colors";
import DefaultChip from "components/Chip/DefaultChip";
import { useTranslations } from "hooks";

const CrispToolTipWrapper: React.FC<{}> = ({ children }) => {
  const lang = useTranslations();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  function addCrispEventListeners() {
    const crispChat = document.querySelector(
      "[data-id='chat_opened']"
    )?.parentElement;

    if (crispChat && !anchorEl) {
      setAnchorEl(crispChat as HTMLElement);

      crispChat.addEventListener("mouseover", () => {
        setOpenPopover(true);
      });
      crispChat.addEventListener("mouseout", () => {
        setOpenPopover(false);
      });
    }
  }

  useEffect(() => {
    addCrispEventListeners();
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === "H") {
        if (Crisp.chat.isVisible()) {
          Crisp.chat.hide();
        } else {
          Crisp.chat.show();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  Crisp.chat.onChatClosed(() => {
    setOpenPopover(false);
  });
  Crisp.session.onLoaded(addCrispEventListeners);

  return (
    <>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        hideBackdrop={true}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 350,
        }}
        PaperProps={{
          style: {
            padding: "4px 8px",
            fontSize: "12px",
            fontWeight: 500,
            color: GreyDark,
          },
        }}
        elevation={0}
      >
        <Box
          display={"flex"}
          gridColumnGap={"6px"}
          alignContent={"center"}
          alignItems={"center"}
          data-testid="crisp-tooltip"
        >
          <Box>
            {
              lang.containers.home.containers.home.crispPopUp.createEditModal
                .title
            }
          </Box>

          <Box>
            <DefaultChip label="SHIFT + H" size="small" />
          </Box>
        </Box>
      </Popover>
      {children}
    </>
  );
};

export default CrispToolTipWrapper;
