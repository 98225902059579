import * as Sentry from "@sentry/react";
import { USER_TYPE } from "fieldpro-tools";

import { ISignedInUser } from "model/entities/User";

/**
 * Sentry Users
 *
 * They are used to display user details in the Sentry UI. They are defined by the API and not customisable.
 * See: https://docs.sentry.io/platforms/node/enriching-events/identify-user/
 */

export const getSentryUser = (signedInUser?: ISignedInUser) => {
  if (!signedInUser) {
    return undefined;
  }

  const { id, email, name } = signedInUser;

  const user: Sentry.User = {
    id,
    username: name,
    email,
    // non-standard keys below
    user_type: USER_TYPE.WEB,
  };
  return user;
};
