import {
  ICustomersMapKpiData,
  TKpiMapLegend,
  TKpiMapLegendType,
} from "fieldpro-tools";
import _ from "lodash";

import { IKPI } from "model/entities/Dashboard";

import { getLinearLegendClassColors } from "./categoriesLegendTools";

export function getLegendFromChart(
  chart: IKPI<Array<ICustomersMapKpiData>>
): TKpiMapLegend | undefined {
  if (!chart.legend) return;
  const { label, tag, type } = chart.legend;

  if (type === TKpiMapLegendType.CATEGORIES) {
    return {
      type,
      label: label ?? chart.title,
      tag,
      values: getLinearLegendClassColors(chart) ?? [],
    };
  }

  if (type === TKpiMapLegendType.GRADIENT) {
    const values: Array<number> = _(chart.data)
      .map(chart.legend?.tag)
      .filter(_.isNumber)
      .value();
    const minValue = _.min(values) || 0;
    const maxValue = _.max(values) || 0;

    return {
      type,
      label: label ?? chart.title,
      max: maxValue,
      min: minValue,
      tag,
      values,
      valueFormater: chart.legend?.valueFormater,
    };
  }
}
