export default () => ({
  container: {
    heigth: "50px",
    display: "inline-block",
  },
  square: {
    display: "inline-block",
    height: "10px",
    width: "10px",
    backgroundColor: "#555",
  },
  label: {
    margin: "10px",
    fontSize: "0.8em",
  },
});
