import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import { useSelector } from "react-redux";

import TabPlaceHolder from "components/Tab/TabPlaceHolder";
import { allListsSelector } from "containers/lists/redux/selectors";
import { getItemLabel } from "containers/lists/utils/getItemLabel";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

import ItemGalleryCard from "./ItemGalleryCard";
import { getItemGalleryFromList } from "./utils";

export interface IItemGalleryItem {
  id: string;
  label: string;
  imageURL?: string;
}
export interface IItemGalleryProps extends BoxProps {
  listId: string;
  items: IListItem[];
  selectedItem?: string;
  onClickItem: (item: IItemGalleryItem) => void;
  onRemoveItem?: (item: IItemGalleryItem) => void;
  searchTerm?: string;
  itemLimit?: number;
}

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat( auto-fit, 217px )",
    gridGap: "24px",
    height: "inherit",
    paddingBottom: "8px",
  },
};

const useStyles = makeStyles(styles);

function ItemGallery({
  items: _items,
  onClickItem,
  onRemoveItem,
  selectedItem,
  style,
  searchTerm = "",
  itemLimit = 50,
  listId,
}: IItemGalleryProps) {
  const allLists = useSelector(allListsSelector);
  const list = useMemo(
    () => _.find(allLists, { id: listId }) || ({} as IList),
    [allLists, listId]
  );
  const [selected, setSelected] = useState(selectedItem);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const limitPerPage = itemLimit;
  const start = page * limitPerPage;
  const end = start + limitPerPage;
  const [items, setItems] = useState<IItemGalleryItem[]>([]);
  const lang = useTranslations();
  const langKey = lang.components.itemGallery;

  function handlePageChange(event: any, page: number) {
    setPage(page);
  }

  useEffect(() => {
    setItems(getItemGalleryFromList(list, _.slice(_items, start, end)));
  }, [_items, end, list, page, start]);

  useEffect(() => {
    function filterItemsWithSearchTerm(items: IListItem[]) {
      return _.filter<IListItem>(items, function (item) {
        const name = getItemLabel(item);
        if (name) {
          return name
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase().trim());
        } else {
          return true;
        }
      });
    }

    const itemsFiltered = filterItemsWithSearchTerm(_items);
    setItems(
      //@ts-ignore
      getItemGalleryFromList(list, _.slice(itemsFiltered, 0, limitPerPage))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_items, searchTerm]);

  const handleItemClick = useCallback(
    (item: IItemGalleryItem) => {
      setSelected(item.id);
      onClickItem(item);
    },
    [onClickItem]
  );

  return (
    <Box display={"flex"} flexDirection="column">
      <Box className={classes.container} style={style}>
        {_.isEmpty(items) && searchTerm != "" ? (
          <>
            <TabPlaceHolder
              icon={<SearchIcon />}
              description={langKey.customErrorMessages.noItemsFoundDescription}
              title={formatString(
                langKey.customErrorMessages.noItemsFoundTitle,
                [searchTerm]
              )}
              style={{
                gridColumn: "none",
              }}
              iconSize={"70px"}
            />
          </>
        ) : (
          _.map(items, function (item) {
            return (
              <ItemGalleryCard
                key={item.id}
                item={item}
                label={item.label}
                pictureURL={item.imageURL}
                selected={selected === item.id}
                onClick={handleItemClick}
                onRemoveItem={onRemoveItem}
              />
            );
          })
        )}
      </Box>

      {_items.length > limitPerPage ? (
        <Box
          justifySelf={"center"}
          justifyContent={"center"}
          display={"flex"}
          padding={"16px"}
        >
          <Pagination
            count={Math.floor(_items.length / limitPerPage)}
            onChange={handlePageChange}
            data-testid={"pagination-handler"}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default ItemGallery;
