import _ from "lodash";

import { ITeamSelector } from "model/entities/Team";

/**
 * This function is returning the teams that are concerned by the hierarchy
 * @param query the query object
 * @param teams all the teams of the client
 * @returns
 */
export function getTeamsInHierarchy({
  query,
  teams,
  maxHierarchyLevels = 10,
}: {
  query: Record<string, any>;
  teams: ITeamSelector[];
  maxHierarchyLevels?: number;
}) {
  const levels = _.times(maxHierarchyLevels, (num) => `level_${num}`);

  const teamIds = _.flatMap(levels, (level) => {
    const levelValues = query.filters[level] ?? {};
    const result = _.flatMap(teams, (team) => {
      if (_.includes(levelValues, team[level])) {
        return team.id;
      }
    });
    return _.compact(result);
  });

  const originalQueryTeamsQueryValue = query.filters.teams || [];
  const allTeamsJoined = _.uniq(
    _.compact(_.concat(originalQueryTeamsQueryValue, teamIds))
  );
  return allTeamsJoined;
}
