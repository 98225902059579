import { useEffect, useState } from "react";

import { IGPSStructure } from "components/Input/InputGPS/InputGPS";

const useBrowserGeolocation = () => {
  const [geolocation, setGeolocation] = useState<IGPSStructure | null>(null);

  useEffect(() => {
    // check if geolocation is supported/enabled on current browser
    if (!("geolocation" in navigator)) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      function success(position) {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          acc: 10, // TODO: this is quite random...
        };

        setGeolocation(geolocation);
      },

      function error(e: GeolocationPositionError) {
        console.error(e);
      }
    );
  }, []);

  return geolocation;
};

export default useBrowserGeolocation;
