import { useEffect, useState } from "react";

import {
  CUSTOM_FIELD_TYPE,
  IActivity as IBEActivity,
  IActivityReport as IBEActivityReport,
  IItemSafe as IBEItem,
  IList as IBEList,
  IMatrix,
  IMetaExpressionOptions,
  mxolParams,
  scolParams,
} from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { allListsSelector } from "containers/lists/redux/selectors";
import { allActivitiesSelector } from "containers/workflows/redux/selectors";
import prepareReportStateForBackend from "containers/workflows/subcategories/activities/utils/prepareReportStateForBackend";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
} from "model/entities/Workflow";

interface IUseMetaExpressionOptions {
  viewMode: TViewMode;
  report: IActivityReportInTable;
  activity: IActivity;
  workflow?: IWorkflow;
}

const useMetaExpressionOptions = ({
  viewMode,
  report,
  activity,
  workflow,
}: IUseMetaExpressionOptions) => {
  const [metaExpressionOptions, setMetaExpressionOptions] = useState({});
  const activities = useSelector(allActivitiesSelector);
  const lists = useSelector(allListsSelector);
  useEffect(() => {
    if (viewMode === "VIEW") return;

    const scolParamsArray: scolParams[] = [];
    const mxolParamsArray: mxolParams[] = [];

    _.forEach(activity.questionnaire?.questions, (question) => {
      const answer = report[question.tag];
      const listId = question?.list_id;
      const list = listId ? _.find(lists, { id: listId }) : undefined;

      if (question.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
        scolParamsArray.push({
          scolItem: answer?.value ?? answer,
          scolList: list as IBEList,
        });
      }
      if (
        question.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST &&
        question.matrix
      ) {
        mxolParamsArray.push({
          matrix: question.matrix as IMatrix,
          mxolItems: list?.items as unknown as IBEItem[],
          tag: question.tag,
          scolParams: undefined, // Placeholder for future implementation
        });
      }
    });

    const newMetaExpressionOptions: IMetaExpressionOptions = {
      activity_report: prepareReportStateForBackend(
        report,
        activity
      ) as IBEActivityReport,
      activity: activity as IBEActivity,
      activities: activities as IBEActivity[],
      workflow,
      mxolParams: mxolParamsArray,
      scolParams: scolParamsArray,
    };

    setMetaExpressionOptions(newMetaExpressionOptions);
    return () => {};
  }, [viewMode, report, activity, lists, activities, workflow]);

  return { metaExpressionOptions };
};

export default useMetaExpressionOptions;
