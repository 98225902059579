import Moment from "moment";

export function getNextDate(
  date: Date,
  interval: "week" | "month" | "year" | "day",
  direction: "forward" | "backward" = "forward",
  moment: typeof Moment
) {
  return moment(date)
    .add(direction === "forward" ? 1 : -1, interval)
    .format("YYYY/MM/DD");
}
