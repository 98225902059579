import { IStyles } from "model/application/styles";

const styles = () =>
  ({
    editOption: {
      display: "flex",
      columnGap: "100px",
      flexWrap: "wrap",
      alignItems: "center",
      textAlign: "center",
    },

    button: {
      columnGap: "20px",
      backgroundColor: "#e6e6e6",
      fontWeight: 500,
      height: "36px",
      flexShrink: 0,
    },

    buttonTextIcon: {
      display: "flex",
      columnGap: "10px",
      alignItems: "center",
    },
  } as IStyles);

export default styles;
