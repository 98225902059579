import { Component } from "react";

import FieldProLogger from "log/FieldProLogger";

import {
  IErrorBoundaryProps,
  IErrorBoundaryState,
} from "model/application/Container";

import FallbackScreen from "../../components/Errors/FallbackScreen";

export default class CalendarErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: {},
      info: {},
    };
  }

  componentDidCatch(error: any, info: any) {
    const { client, user } = this.props;
    const logger = FieldProLogger({
      client,
      user,
    });
    logger.logError(error);
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <FallbackScreen />;
    }

    return this.props.children;
  }
}
