/**
 * Configuration for the Store in production environment
 * This configuration is used when the application is build for production
 * This is normally not imported directly as the ENV variable will determine whether to use this configuration
 * or the development configuration
 */
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";

import persistedReducer from "redux/reducers/rootReducer";

import { productionMiddleware } from "../middleware";

/**
 * Configures the application store
 * */
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...productionMiddleware))
  );

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
