import { DOCUMENT_SCOPE, IDocument } from "model/entities/Document";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: string;
}
export interface IChangeFolderSuccessActionCreator extends IAction {
  folderSelected: string;
}
export function fetchDocumentsBeginAction(): IAction {
  return { type: types.FETCH_DOCUMENTS_BEGIN };
}
export function fetchDocumentsFailureAction(error: any): IActionError {
  return { type: types.FETCH_DOCUMENTS_FAILURE, error };
}
export interface IFetchDocumentsSuccessAction extends IAction {
  clientId: string;
  documents: IDocument[];
}
export const fetchDocumentsSuccessAction = (
  clientId: string,
  documents: IDocument[]
): IFetchDocumentsSuccessAction => ({
  type: types.FETCH_DOCUMENTS_SUCCESS,
  clientId,
  documents,
});

export function uploadDocumentsBeginAction(): IAction {
  return { type: types.UPLOAD_DOCUMENTS_BEGIN };
}
export function uploadDocumentsFailureAction(error: any): IActionError {
  return { type: types.UPLOAD_DOCUMENTS_FAILURE, error };
}
export function uploadDocumentsuccessAction(): IAction {
  return { type: types.UPLOAD_DOCUMENTS_SUCCESS };
}

export function deleteDocumentsBeginAction(): IAction {
  return { type: types.DELETE_DOCUMENTS_BEGIN };
}
export function deleteDocumentsFailureAction(error: any): IActionError {
  return { type: types.DELETE_DOCUMENTS_FAILURE, error };
}
export function deleteDocumentsuccessAction(): IAction {
  return { type: types.DELETE_DOCUMENTS_SUCCESS };
}

/**
 * @param {Object} subcategory Can be either index or client or optimetriks
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}

export function changeFolderSuccessActionCreator(
  folderSelected: string
): IChangeFolderSuccessActionCreator {
  return {
    type: types.CHANGE_FOLDER_SELECTED_SUCCESS,
    folderSelected,
  };
}
interface IUpdateDocumentSuccessActionCreator extends IAction {
  document: {
    fileName: string;
    teams: string[];
    scope: DOCUMENT_SCOPE;
    folder: string;
  };
}

/**
 * DOCUMENT update success action is used to inform reducer of successful ajax request
 * @param {Object} document Object with DOCUMENT information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateDocumentSuccessActionCreator(document: {
  id: string;
  fileName: string;
  teams: string[];
  scope: DOCUMENT_SCOPE;
  folder: string;
}): IUpdateDocumentSuccessActionCreator {
  return {
    type: types.UPDATE_DOCUMENT_SUCCESS,
    document,
  };
}

/**
 * FOLDER update begin action is used to inform reducer of beginning ajax request
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateDocumentBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_DOCUMENT_BEGIN,
  };
}

/**
 * FOLDER update failure action is used to inform reducer of failed ajax request
 * @return {Object} Object with type of action for reducer to handle
 * @param {Object} error Object with error information
 * */
export function updateDocumentFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_DOCUMENT_FAILURE,
    error,
  };
}
