import {
  getActionsAvailableSelector,
  getLang,
} from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IStream } from "model/entities/Job";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createStreamBeginActionCreator,
  createStreamFailureActionCreator,
  createStreamSuccessActionCreator,
  deleteStreamBeginActionCreator,
  deleteStreamFailureActionCreator,
  deleteStreamSuccessActionCreator,
  fetchAllStreamsBeginActionCreator,
  fetchAllStreamsFailureActionCreator,
  fetchAllStreamsSuccessActionCreator,
  runStreamBeginActionCreator,
  runStreamFailureActionCreator,
  runStreamJobBeginActionCreator,
  runStreamJobFailureActionCreator,
  runStreamJobSuccessActionCreator,
  runStreamSuccessActionCreator,
  updateStreamBeginActionCreator,
  updateStreamFailureActionCreator,
  updateStreamSuccessActionCreator,
} from "./actionCreators";
import {
  createStreamApiCall,
  deleteStreamApiCall,
  fetchStreamsApiCall,
  runStreamApiCall,
  runStreamJobApiCall,
  updateStreamApiCall,
} from "./api";

// STREAMS

/**
 * Create stream action which creates a stream for embedding
 *   destination_table: string;
  columns: array;
  add_data_strategy: string;
  primary_key: string;
  indexes: string;
 * @param {String} id Id of the stream
 * @param {String} destination_table Name of the destination table of the stream
 * @param {Array} columns Array of columns
 * @param {String} add_data_strategy The strategy of addition of data
 * @param {String} primary_key Primary key of the stream
 * @param {String} indexes Indexes of the stream
 * @returns {Function}
 */
export const createStreamAction: ICreateStreamActionFunc = (
  actionName: string,
  stream: IStream
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createStreamBeginActionCreator());

    return createStreamApiCall(actionName, stream)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const streamWithId = {
          ...stream,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(createStreamSuccessActionCreator(streamWithId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.STREAM
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateStream",
          error,
          createStreamFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update Stream action which creates a stream for embedding
 * @param {Object} stream stream object to edit
 * @returns {Function}
 */
export const updateStreamAction: TUpdateStreamActionFunc = (
  actionName,
  stream
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateStreamBeginActionCreator());

    return updateStreamApiCall(actionName, { ...stream })
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateStreamSuccessActionCreator(stream));

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.STREAM,
              stream.id
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateStream",
          error,
          updateStreamFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch allStreams
 * @returns {Function}
 */
export const fetchAllStreamsAction: TFetchAllStreamsActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    const availableActions = getActionsAvailableSelector(getState());
    dispatch(ajaxRequestAction());
    dispatch(fetchAllStreamsBeginActionCreator());

    return fetchStreamsApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { streams } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          fetchAllStreamsSuccessActionCreator(streams, availableActions)
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchAllStreamsError",
          error,
          fetchAllStreamsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete Stream Action dispatches action creators to redux store and makes api calls to delete the stream by id
 * @param {String} stream_id Stream id to delete
 * @param {String} stream_name Stream name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteStreamAction: TDeleteStreamActionFunc = (
  actionName,
  streamId,
  streamName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteStreamBeginActionCreator());

    return deleteStreamApiCall(actionName, streamId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteStreamSuccessActionCreator(streamId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.STREAM,
              streamName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteStreamError",
          error,
          deleteStreamFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Use Stream Action dispatches action creators to redux store and makes api calls to use the stream
 * @param {String} streamId Stream id to use
 * @param {String} table The origin table
 * @param {} zip The zip file to use as origin
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const useStreamAction: TUseStreamActionFunc = (
  streamId,
  table,
  zip,
  encoding,
  lineLength
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runStreamBeginActionCreator());

    return runStreamApiCall(streamId, table, zip, encoding, lineLength)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(runStreamSuccessActionCreator(streamId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            "Done!"
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UseStreamError",
          error,
          runStreamFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Run stream job action which creates a stream job for embedding
 *   destination_table: string;
  columns: array;
  add_data_strategy: string;
  primary_key: string;
  indexes: string;
 * @param {String} id Id of the stream
 * @param {String} destination_table Name of the destination table of the stream
 * @param {Array} columns Array of columns
 * @param {String} add_data_strategy The strategy of addition of data
 * @param {String} primary_key Primary key of the stream
 * @param {String} indexes Indexes of the stream
 * @returns {Function}
 */
export const runStreamJobAction: IRunStreamJobActionFunc = (
  actionName: string,
  stream: IStream
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runStreamJobBeginActionCreator());

    return runStreamJobApiCall(actionName, stream)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(runStreamJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.STREAM
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RunStreamJob",
          error,
          runStreamJobFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateStreamActionFunc = (
  actionName: string,
  stream: IStream
) => IDispatchAndGetState<void>;
export type IRunStreamJobActionFunc = (
  actionName: string,
  stream: IStream
) => IDispatchAndGetState<void>;
export type TUpdateStreamActionFunc = (
  actionName: string,
  stream: IStream
) => IDispatchAndGetState<any>;
export type TFetchAllStreamsActionFunc = () => IDispatchAndGetState<void>;
export type TDeleteStreamActionFunc = (
  actionName: string,
  streamId: string,
  streamName: string
) => IDispatchAndGetState<void>;
export type TUseStreamActionFunc = (
  streamId: string,
  table?: string,
  zip?: any,
  encoding?: string,
  lineLength?: number
) => IDispatchAndGetState<void>;

export interface IStreamActions {
  createStreamAction: ICreateStreamActionFunc;
  updateStreamAction: TUpdateStreamActionFunc;
  fetchAllStreamsAction: TFetchAllStreamsActionFunc;
  deleteStreamAction: TDeleteStreamActionFunc;
  useStreamAction: TUseStreamActionFunc;
  runStreamJobAction: IRunStreamJobActionFunc;
}

const actions: IStreamActions = {
  createStreamAction: createStreamAction,
  updateStreamAction: updateStreamAction,
  fetchAllStreamsAction: fetchAllStreamsAction,
  deleteStreamAction: deleteStreamAction,
  useStreamAction: useStreamAction,
  runStreamJobAction: runStreamJobAction,
};

export default actions;
