import { IHttpRequest } from "fieldpro-tools";

import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createHttpRequestBeginActionCreator,
  createHttpRequestFailureActionCreator,
  createHttpRequestSuccessActionCreator,
  deleteHttpRequestBeginActionCreator,
  deleteHttpRequestFailureActionCreator,
  deleteHttpRequestSuccessActionCreator,
  fetchAllHttpRequestsBeginActionCreator,
  fetchAllHttpRequestsFailureActionCreator,
  fetchAllHttpRequestsSuccessActionCreator,
  runHttpRequestJobBeginActionCreator,
  runHttpRequestJobFailureActionCreator,
  runHttpRequestJobSuccessActionCreator,
  updateHttpRequestBeginActionCreator,
  updateHttpRequestFailureActionCreator,
  updateHttpRequestSuccessActionCreator,
} from "./actionCreators";
import {
  createHttpRequestApiCall,
  deleteHttpRequestApiCall,
  fetchHttpRequestsApiCall,
  runHttpRequestJobApiCall,
  updateHttpRequestApiCall,
} from "./api";
/**
 * Create HttpRequest action which creates a request for embedding
 * id: string;
 * name: string;
 * url: Query [];
 * query: string [];
 * headers: string;
 * method: string;
 * @param {String} id primary_key the request in the tables
 * @param {String} name body of the request
 * @param {Query []} queries list of queries to embed in the request.
 * @param {String} method method of the http request (Value between POST,GET, PUT, PATCH)
 * @returns {Function}
 */
export const createHttpRequestAction: ICreateHttpRequestActionFunc = (
  actionName: string,
  httpRequest: IHttpRequest
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createHttpRequestBeginActionCreator());

    return createHttpRequestApiCall(actionName, httpRequest)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const requestWithId = {
          ...httpRequest,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(createHttpRequestSuccessActionCreator(requestWithId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.HTTP_REQUEST
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateHttpRequest",
          error,
          createHttpRequestFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Update httpRequest action which creates a httpRequest for embedding
 * @param {Object} httpRequest HttpRequest object to edit
 * @returns {Function}
 */
export const updateHttpRequestAction: TUpdateHttpRequestActionFunc = (
  actionName,
  httpRequest
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateHttpRequestBeginActionCreator());
    return updateHttpRequestApiCall(actionName, { ...httpRequest })
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateHttpRequestSuccessActionCreator(httpRequest));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.HTTP_REQUEST,
              httpRequest.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateHttpRequest",
          error,
          updateHttpRequestFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Action to fetch allHttpRequests
 * @returns {Function}
 */
export const fetchAllHttpRequestsAction: TFetchAllHttpRequestsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllHttpRequestsBeginActionCreator());
      return fetchHttpRequestsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { http_requests } = data;
          dispatch(ajaxSuccessAction());
          dispatch(fetchAllHttpRequestsSuccessActionCreator(http_requests));
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllHttpRequestsError",
            error,
            fetchAllHttpRequestsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete HttpRequest Action dispatches action creators to redux store and makes api calls to delete a httpRequest by id
 * @param {String} httpRequestId Notification id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteHttpRequestAction: TDeleteHttpRequestActionFunc = (
  actionName,
  httpRequestId
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteHttpRequestBeginActionCreator());
    return deleteHttpRequestApiCall(actionName, httpRequestId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteHttpRequestSuccessActionCreator(httpRequestId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.HTTP_REQUEST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteHttpRequestError",
          error,
          deleteHttpRequestFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Run http request job action which run a httpRequest job
 * id: string;
 * name: string;
 * url: Query [];
 * query: string [];
 * headers: string;
 * method: string;
 * @param {String} id primary_key the request in the tables
 * @param {String} name body of the request
 * @param {Query []} queries list of queries to embed in the request.
 * @param {String} method method of the http request (Value between POST,GET, PUT, PATCH)
 * @returns {Function}
 */
export const runHttpRequestJobAction: IRunHttpRequestJobActionFunc = (
  actionName: string,
  httpRequest: IHttpRequest
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runHttpRequestJobBeginActionCreator());

    return runHttpRequestJobApiCall(actionName, httpRequest)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(runHttpRequestJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.HTTP_REQUEST
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RunHttpRequestJob",
          error,
          runHttpRequestJobFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateHttpRequestActionFunc = (
  actionName: string,
  httpRequestId: IHttpRequest
) => IDispatchAndGetState<void>;
export type TUpdateHttpRequestActionFunc = (
  actionName: string,
  httpRequestId: IHttpRequest
) => IDispatchAndGetState<any>;
export type TFetchAllHttpRequestsActionFunc = () => IDispatchAndGetState<void>;
export type TDeleteHttpRequestActionFunc = (
  actionName: string,
  httpRequestId: string
) => IDispatchAndGetState<void>;
export type IRunHttpRequestJobActionFunc = (
  actionName: string,
  httpRequest: IHttpRequest
) => IDispatchAndGetState<void>;

export interface IHttpRequestActions {
  createHttpRequestAction: ICreateHttpRequestActionFunc;
  updateHttpRequestAction: TUpdateHttpRequestActionFunc;
  fetchAllHttpRequestsAction: TFetchAllHttpRequestsActionFunc;
  deleteHttpRequestAction: TDeleteHttpRequestActionFunc;
  runHttpRequestJobAction: IRunHttpRequestJobActionFunc;
}

const actions: IHttpRequestActions = {
  createHttpRequestAction: createHttpRequestAction,
  updateHttpRequestAction: updateHttpRequestAction,
  fetchAllHttpRequestsAction: fetchAllHttpRequestsAction,
  deleteHttpRequestAction: deleteHttpRequestAction,
  runHttpRequestJobAction: runHttpRequestJobAction,
};

export default actions;
