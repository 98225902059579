import _ from "lodash";
import { createSelector } from "reselect";

import {
  getLinkedMobileUser,
  getSignedInUser,
} from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allWebUsersComposedSelector } from "containers/users/redux/selectors/webUsersSelector";
import IRootState from "redux/store/model";
export const getIsCreatingEvent = (state: IRootState) =>
  state.calendar_events.isCreatingEvent;

export const getIsDeletingEvent = (state: IRootState) =>
  state.calendar_events.isDeletingEvent;

export const getIsFetchingEvents = (state: IRootState) =>
  state.calendar_events.isFetchingEvent;
export const getIsUpatingEvent = (state: IRootState) =>
  state.calendar_events.isUpdatingEvent;

export const getIsUpdatingEvent = (state: IRootState) =>
  state.calendar_events.isUpdatingEvent;

export const getEvents = (state: IRootState) => state.calendar_events.events;

export const getEventsErrors = (state: IRootState) =>
  state.calendar_events.errors;

export const getHasAccessToAllResources = createSelector(
  [
    (state) => getSignedInUser(state),
    allWebUsersComposedSelector,
    getSelectedClient,
  ],
  (signedInUser, webUsers, selectedClient) => {
    const webUser = webUsers.find(
      (user) =>
        _.size(
          _.intersection(
            [user.email, user.id],
            [signedInUser.email, signedInUser.id]
          )
        ) > 0
    );
    const profile =
      webUser &&
      _.find(
        selectedClient.access_right_profiles,
        (pr) => pr.name === webUser.role
      );
    return profile?.access_all_resources ? true : false;
  }
);

export const getIsUserAManager = createSelector(
  [(state) => getLinkedMobileUser(state), getHasAccessToAllResources],
  (linkedMobileUser, hasAccessToAllResources) => {
    return hasAccessToAllResources || !!linkedMobileUser?.is_team_leader;
  }
);

export const getSelectedFieldUsers = (state: IRootState) =>
  state.calendar_events.selectedFieldUsers;

export const getEventRequiresApproval = createSelector(
  [getIsUserAManager, getSelectedClient],
  (isManager, client) => {
    return !isManager && client.calendar_validation_enabled;
  }
);
