import { cleanDirtyDate, getDefaultTimeFormat } from "utils/dateUtils";

export interface ITimeCell {
  date?: string;
}

const TimeCell = ({ date: dirtyDate }: ITimeCell) => {
  const dateFormatted = cleanDirtyDate(dirtyDate, getDefaultTimeFormat);
  if (!dateFormatted) return null;
  return <span>{dateFormatted}</span>;
};

export default TimeCell;
