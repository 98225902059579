import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { INotification } from "model/entities/Job";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import { prepareNotificationForBE } from "../../Jobscreen/notification/EmailNotificationConfigForm/utils/prepareNotificationForBE";
import {
  createNotificationBeginActionCreator,
  createNotificationFailureActionCreator,
  createNotificationSuccessActionCreator,
  deleteNotificationBeginActionCreator,
  deleteNotificationFailureActionCreator,
  deleteNotificationSuccessActionCreator,
  fetchAllNotificationsBeginActionCreator,
  fetchAllNotificationsFailureActionCreator,
  fetchAllNotificationsSuccessActionCreator,
  runNotificationJobBeginActionCreator,
  runNotificationJobFailureActionCreator,
  runNotificationJobSuccessActionCreator,
  updateNotificationBeginActionCreator,
  updateNotificationFailureActionCreator,
  updateNotificationSuccessActionCreator,
} from "./actionCreators";
import {
  createNotificationApiCall,
  deleteNotificationApiCall,
  fetchNotificationsApiCall,
  ITestNotificationPayload,
  receiveTestNotificationApiCall,
  runNotificationJobApiCall,
  updateNotificationApiCall,
} from "./api";

/**
 * Create Notification action which creates a otification for embedding
 * id: string;
 * body: string;
  queries: Query [];
  web_users: string [];
  frequency: string;
  date_range: string;
  * @param {String} id primary_key the notification in the tables
 * @param {String} body body of the email
 * @param {Query []} queries list of queries to embed in the body of email.
 * @param {String []} web_users list of web_users who should receive email
 * @param {String} frequency Frequency of the email shipping (Value between ONCE, DAILY, WEEKLY, MONTHLY)
 * @param {String} date_range (Value between TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS)
 * @returns {Function}
 */
export const createNotificationAction: ICreateNotificationActionFunc = (
  actionName: string,
  notification: INotification
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createNotificationBeginActionCreator());

    return createNotificationApiCall(
      actionName,
      prepareNotificationForBE(notification)
    )
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const notificationWithId = {
          ...notification,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(createNotificationSuccessActionCreator(notificationWithId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.NOTIFICATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateNotification",
          error,
          createNotificationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Update Notification action which creates an Notification for embedding
 * @param {Object} notification Notification object to edit
 * @returns {Function}
 */
export const updateNotificationAction: TUpdateNotificationActionFunc = (
  actionName,
  notification
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateNotificationBeginActionCreator());
    return updateNotificationApiCall(
      actionName,
      prepareNotificationForBE(notification)
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateNotificationSuccessActionCreator(notification));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.NOTIFICATION,
              notification.title
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateNotification",
          error,
          updateNotificationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Action to fetch allNotifications
 * @returns {Function}
 */
export const fetchAllNotificationsAction: TFetchAllNotificationsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllNotificationsBeginActionCreator());
      return fetchNotificationsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { notifications } = data;
          dispatch(ajaxSuccessAction());
          dispatch(fetchAllNotificationsSuccessActionCreator(notifications));
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllNotificationsError",
            error,
            fetchAllNotificationsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete Notification Action dispatches action creators to redux store and makes api calls to delete a notification by id
 * @param {String} notificationId Notification id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteNotificationAction: TDeleteNotificationActionFunc = (
  actionName,
  notificationId
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteNotificationBeginActionCreator());
    return deleteNotificationApiCall(actionName, notificationId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteNotificationSuccessActionCreator(notificationId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.NOTIFICATION
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteNotificationError",
          error,
          deleteNotificationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Run Notification job action which run a notification job
 * id: string;
 * body: string;
 * queries: Query [];
 * web_users: string [];
 * frequency: string;
 * date_range: string;
 * @param {String} id primary_key the notification in the tables
 * @param {String} body body of the email
 * @param {Query []} queries list of queries to embed in the body of email.
 * @param {String []} web_users list of web_users who should receive email
 * @param {String} frequency Frequency of the email shipping (Value between ONCE, DAILY, WEEKLY, MONTHLY)
 * @param {String} date_range (Value between TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS)
 * @returns {Function}
 */
export const runNotificationJobAction: IRunNotificationJobActionFunc = (
  actionName: string,
  notification: INotification
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runNotificationJobBeginActionCreator());

    return runNotificationJobApiCall(actionName, notification)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(runNotificationJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.NOTIFICATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RunNotificationJob",
          error,
          runNotificationJobFailureActionCreator,
          currLang
        );
      });
  };
};

export const testNotificationActionFunction: ITestNotificationActionFunc = (
  actionName,
  payload
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return receiveTestNotificationApiCall(actionName, payload)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.jobs.subCategories.notifications.createEditModal
              .inputEmail.customMessage.testSent
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "testNotification",
          error,
          runNotificationJobFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateNotificationActionFunc = (
  actionName: string,
  notificationId: INotification
) => IDispatchAndGetState<void>;
export type TUpdateNotificationActionFunc = (
  actionName: string,
  notificationId: INotification
) => IDispatchAndGetState<any>;
export type TFetchAllNotificationsActionFunc = () => IDispatchAndGetState<void>;
export type TDeleteNotificationActionFunc = (
  actionName: string,
  notificationId: string
) => IDispatchAndGetState<void>;
export type IRunNotificationJobActionFunc = (
  actionName: string,
  notification: INotification
) => IDispatchAndGetState<void>;
export type ITestNotificationActionFunc<T = any> = (
  actionName: string,
  payload: ITestNotificationPayload
) => IDispatchAndGetState<T>;

export interface INotificationActions {
  createNotificationAction: ICreateNotificationActionFunc;
  updateNotificationAction: TUpdateNotificationActionFunc;
  fetchAllNotificationsAction: TFetchAllNotificationsActionFunc;
  deleteNotificationAction: TDeleteNotificationActionFunc;
  runNotificationJobAction: IRunNotificationJobActionFunc;
}

const actions: INotificationActions = {
  createNotificationAction: createNotificationAction,
  updateNotificationAction: updateNotificationAction,
  fetchAllNotificationsAction: fetchAllNotificationsAction,
  deleteNotificationAction: deleteNotificationAction,
  runNotificationJobAction: runNotificationJobAction,
};

export default actions;
