import { errorColor, GreenDark, White } from "assets/colors";

const baseVariationStyles = {
  width: "fit-content",
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
};

export default () => ({
  scorecard: {
    padding: "20px",
    minWidth: 180,
    width: "100%",
    height: "100%",
    fontWeight: "bold",
    alignItems: "center",
  },
  scorecardTitle: {
    marginBottom: "25px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  chartCard: {
    display: "flex",
  },
  chartCardLeft: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
  },
  chartCardLeftBottom: {
    marginTop: "10px",
    position: "relative",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  variationUp: {
    color: GreenDark,
    marginLeft: "-2px",
  },
  variationDown: {
    color: errorColor,
    marginLeft: "-2px",
  },

  variationUpText: {
    color: GreenDark,
    ...baseVariationStyles,
  },
  variationDownText: {
    color: errorColor,
    ...baseVariationStyles,
  },
  spanText: {
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    backgroundColor: White,
    borderRadius: "3px",
    padding: "0px 6px",
    marginRight: "-6px",
  },

  chartCardRight: {
    width: "10%",
    display: "flex",
    flexDirection: "column",
    marginTop: "-4px", //  Align the tooltip with the title
  },
  whiteIcon: {
    color: "#fff",
    fontWeight: "bold",
  },
  scorecardScore: {
    lineHeight: "normal",
    height: "80px",
    marginBottom: "0px",
    fontSize: "64px",
  },
  scorecardUnit: {
    marginBottom: "0px",
    fontSize: "2em",
  },
  rounded: {
    borderRadius: "8px",
  },
});
