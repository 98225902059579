import { FETCH_ITEMS_LIMIT, LIST_SCOPE } from "fieldpro-tools";
import _ from "lodash";
import find from "lodash/find";
import uniq from "lodash/uniq";
import moment from "moment";

import { updateItemInArray } from "containers/workflows/subcategories/jobs/Jobscreen/notification/EmailNotificationConfigForm/utils/updateItemInArray";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IAction } from "redux/store/model";

import { DATE_FORMAT_FULL_DATE } from "../../../utils/constants";
import {
  convertArrayToObject,
  extractIdsFromArray,
  insertNewItemToArr,
  removeObjectFromArray,
  removeObjectFromHash,
  updateErrorsList,
  updateObjectInArray,
} from "../../../utils/reducerUtils";
import {
  IAddItemsToListActionCreator,
  IArchiveItemsSuccessAction,
  IArchiveListSuccessAction,
  IAssignItemsSuccessAction,
  IAssignmentLink,
  ICreateItemsSuccessAction,
  ICreateListSuccessAction,
  IDeleteItemsSuccessAction,
  IDeleteListSuccessAction,
  IEditAttributeTagSuccessAction,
  IFetchCustomerReportsPicturesSuccessAction,
  IFetchItemsForListSuccessAction,
  IFetchListByIdSuccessAction,
  IFetchListOptionsSuccessAction,
  IFetchListsForClientSuccessAction,
  IRestoreItemsSuccessAction,
  IRestoreListSuccessAction,
  ISelectListActionCreator,
  IUnassignItemsSuccessAction,
  IUnassignItemsUsingLinksAction,
  IUpdateItemsSuccessAction,
  IUpdateListSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { prepareItemsForFrontend, updateListSchemaByTag } from "./utils";

export interface ListsState {
  fetchingListsForClient: boolean;
  fetchingObjectsForList: boolean;
  isFetchingList: boolean;
  isUpdatingList: boolean;
  isDeletingList: boolean;
  isArchivingList: boolean;
  isRestoringList: boolean;
  isCreatingList: boolean;
  isFetchingItems: boolean;
  isFetchingOneItem: boolean;
  isUpdatingItems: boolean;
  isArchivingItems: boolean;
  isRestoringItems: boolean;
  isDeletingItems: boolean;
  isCreatingItems: boolean;
  isUpdatingItem: boolean;
  isArchivingItem: boolean;
  isRestoringItem: boolean;
  isDeletingItem: boolean;
  isCreatingItem: boolean;
  isUploadingFile: boolean;
  isAssigningItems: boolean;
  isChangingTag: boolean;
  isUnassigningItems: boolean;
  isFindDuplicatesItems: boolean;
  isDownloadingFile: boolean;
  isFetchingCustomerReportsPictures: boolean;
  lastUpdated?: IList;
  allLists: IList[];
  selectedCustomerPictures: any[];
  selectedList?: IList;
  byId: { [id: string]: IList };
  allIds: string[];
  errors: any[];
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.ADD_ITEMS_TO_LIST: {
      const { items, listId } = action as IAddItemsToListActionCreator;
      const oldList = _.find(state.allLists, { id: listId });

      if (!oldList) {
        return state;
      }

      const newItems = oldList?.items ?? [];

      _.forEach(items, (item) => {
        const itemInStore = _.find(newItems, { _id: item._id });
        if (!itemInStore) {
          newItems.push(item);
        }
      });

      const newList = {
        ...oldList,
        items: newItems,
      };
      return {
        ...state,
        allLists: updateItemInArray(state.allLists, newList, {
          id: listId,
        }),
        byId: {
          ...state.byId,
          [newList.id]: newList,
        },
      };
    }
    case types.CREATE_LIST_BEGIN:
      return {
        ...state,
        isCreatingList: true,
      };

    case types.CREATE_LIST_SUCCESS: {
      const { list } = action as ICreateListSuccessAction;
      // if "items" attribute is not defined, add the "items" attribute with an empty array
      if (!list.items) {
        list["items"] = [];
      }

      return {
        ...state,
        isCreatingList: false,
        allLists: insertNewItemToArr(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
        errors: [],
      };
    }

    case types.CREATE_LIST_FAILURE:
      return {
        ...state,
        isCreatingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_LIST_BY_ID_BEGIN:
      return {
        ...state,
        isFetchingList: true,
      };

    case types.FETCH_LIST_BY_ID_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_LIST_BY_ID_SUCCESS: {
      const listOfItems = (action as IFetchListByIdSuccessAction).list;
      if (
        !(action as IFetchListByIdSuccessAction).list.hasOwnProperty("items")
      ) {
        listOfItems["items"] = [];
      }
      return {
        ...state,
        isFetchingList: false,
        selectedList: listOfItems,
      };
    }

    // TODO: hack to reset initialState ? (but only done partially...)
    case types.FETCH_LISTS_FOR_CLIENT_BEGIN:
      return Object.assign({}, state, {
        fetchingListsForClient: true,
        selectedList: null,
        isUpdatingList: false,
        isDeletingList: false,
        isArchivingList: false,
        isRestoringList: false,
        isCreatingList: false,
        isFetchingItems: false,
        isUpdatingItems: false,
        isArchivingItems: false,
        isRestoringItems: false,
        isDeletingItems: false,
        isCreatingItems: false,
      });

    case types.FETCH_LISTS_FOR_CLIENT_FAILURE: {
      return {
        ...state,
        fetchingListsForClient: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_LISTS_FOR_CLIENT_SUCCESS: {
      let { lists } = action as IFetchListsForClientSuccessAction;
      const ids = extractIdsFromArray(lists);
      lists = lists.map((l: any) => {
        if (!l.item_count && l.items)
          l.item_count = l.items.filter(
            (i: any) => !i.hasOwnProperty("_active") || i._active
          ).length;
        // add query in the list object
        l.query = {};
        return l;
      });
      return {
        ...state,
        fetchingListsForClient: false,
        allLists: lists,
        byId: convertArrayToObject(state.byId, lists, "id"),
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_LIST_BEGIN:
      return {
        ...state,
        isUpdatingList: true,
      };

    case types.UPDATE_LIST_FAILURE:
      return {
        ...state,
        isUpdatingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_LIST_SUCCESS: {
      const { list } = action as IUpdateListSuccessAction;
      return {
        ...state,
        isUpdatingList: false,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
        errors: [],
      };
    }

    case types.DELETE_LIST_BEGIN:
      return {
        ...state,
        isDeletingList: true,
      };

    case types.DELETE_LIST_FAILURE:
      return {
        ...state,
        isDeletingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.DELETE_LIST_SUCCESS: {
      const { id } = action as IDeleteListSuccessAction;

      return {
        ...state,
        isDeletingList: false,
        selectedList: null,
        allLists: removeObjectFromArray(state.allLists, id),
        byId: removeObjectFromHash(state.byId, id),
        allIds: state.allIds.filter((listId) => listId !== id),
      };
    }

    case types.ARCHIVE_LIST_BEGIN:
      return {
        ...state,
        isArchivingList: true,
      };

    case types.ARCHIVE_LIST_FAILURE:
      return {
        ...state,
        isArchivingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.ARCHIVE_LIST_SUCCESS: {
      const { id } = action as IArchiveListSuccessAction;
      const list = state.byId[id];
      list.active = false;

      return {
        ...state,
        isArchivingList: false,
        selectedList: undefined,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.RESTORE_LIST_BEGIN:
      return {
        ...state,
        isRestoringList: true,
      };

    case types.RESTORE_LIST_FAILURE:
      return {
        ...state,
        isRestoringList: false,
        errors: updateErrorsList(state, action),
      };

    case types.RESTORE_LIST_SUCCESS: {
      const { id } = action as IRestoreListSuccessAction;
      const list = state.byId[id];
      list.active = true;

      return {
        ...state,
        isRestoringList: false,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.CREATE_ITEMS_BEGIN:
      return {
        ...state,
        isCreatingItems: true,
      };

    case types.CREATE_ITEMS_SUCCESS: {
      const { listId, items, listSchema } = action as ICreateItemsSuccessAction;
      const oldList = _.find(state.allLists, { id: listId });

      if (!oldList) {
        throw new Error(`list with id ${listId} not found`);
      }

      const cleanedItems = prepareItemsForFrontend(
        items,
        listSchema,
        state.allLists
      );

      const oldItems = oldList?.items ?? [];
      const oldTableItems = oldList?.table_items ?? [];

      const newItems = [...cleanedItems, ...oldItems];
      const newTableItems = [...cleanedItems, ...oldTableItems];

      const activeItemsCount = _.size(_.filter(cleanedItems, "_active"));

      const newList = {
        ...oldList,
        items: newItems,
        item_count: oldList.item_count + activeItemsCount,
        table_items: newTableItems,
        table_items_count: (oldList.table_items_count ?? 0) + activeItemsCount,
      };

      const allLists = updateObjectInArray(state.allLists, newList);

      let newSelectedList = state.selectedList;
      if (state.selectedList?.id === newList.id) {
        newSelectedList = newList;
      }

      return {
        ...state,
        seletedList: newSelectedList,
        isCreatingItems: false,
        allLists,
        byId: {
          ...state.byId,
          [listId]: newList,
        },
      };
    }

    case types.CREATE_ITEMS_FAILURE:
      return {
        ...state,
        isCreatingItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_ITEMS_BEGIN:
      return {
        ...state,
        isFetchingItems: true,
      };

    case types.FETCH_ITEMS_SUCCESS: {
      const {
        listId,
        items,
        item_count,
        offset,
        eraseExisting,
        listSchema,
        query,
        storeItemsCount,
        clearExistingTableItems,
        storeAsTableItems,
      } = action as IFetchItemsForListSuccessAction;

      let cleanedItems = prepareItemsForFrontend(
        items,
        listSchema,
        state.allLists
      );
      let tableItems: IListItem[] = [];
      const cleanedItemsIds = cleanedItems.map((item) => item._id) || [];

      if (!storeAsTableItems) {
        if (eraseExisting) {
          //cleanItems will replace the existing items
        } else if (offset !== 0) {
          // if this fetch action is not the first one, we add the items in the existing items of the store
          cleanedItems = [...state.byId[listId].items, ...cleanedItems];
        } else {
          // ... otherwise we update the items fetched with the filter
          let itemsNotInArea: IListItem[];
          if (!state.byId[listId].items) {
            itemsNotInArea = [];
          } else {
            itemsNotInArea = [
              ...(state.byId[listId].items.filter(
                (item) => !cleanedItemsIds.includes(item._id)
              ) || []),
            ];
          }
          const excedent =
            itemsNotInArea.length + cleanedItemsIds.length - FETCH_ITEMS_LIMIT;
          if (excedent > 0) {
            itemsNotInArea.splice(0, excedent);
          }
          cleanedItems = [...itemsNotInArea, ...cleanedItems];
        }
      } else {
        if (clearExistingTableItems) {
          tableItems = cleanedItems;
        } else {
          const list = state.byId[listId];
          tableItems = _.uniqBy(
            [...(list?.table_items ?? []), ...cleanedItems],
            "_id"
          );
        }
      }

      const selectedList = find(state.allLists, (list) => list.id === listId);

      if (!selectedList) {
        throw new Error(`selectedList with id ${listId} not found`);
      }

      if (!storeAsTableItems) {
        selectedList.items = cleanedItems;
      } else {
        selectedList.table_items = tableItems;
        selectedList.table_items_count = item_count;
      }

      selectedList.partial_data = false;
      if (storeItemsCount) {
        selectedList.item_count = item_count;
      }

      if (query) selectedList.query = query;

      const allLists = updateObjectInArray(state.allLists, selectedList);

      return {
        ...state,
        selectedList,
        isFetchingItems: false,
        isFetchingOneItem: false,
        allLists,
        byId: {
          ...state.byId,
          [listId]: selectedList,
        },
      };
    }
    case types.FETCH_OPTIONS_FAILURE:
      return {
        ...state,
        errors: updateErrorsList(state, action),
      };
    case types.FETCH_OPTIONS_SUCCESS: {
      const { listId, options } = action as IFetchListOptionsSuccessAction;
      const selectedList = find(state.allLists, (list) => list.id === listId);

      if (!selectedList) {
        throw new Error(`selectedList with id ${listId} not found`);
      }
      const fetchedOpts = options.map((opt) => ({
        key: opt.id,
        label: opt.name,
      }));
      // drop duplicates
      const newOptions = _.uniqBy(
        [...(selectedList.options ?? []), ...fetchedOpts],
        "key"
      );
      selectedList.options = newOptions;
      const allLists = updateObjectInArray(state.allLists, selectedList);

      return {
        ...state,
        selectedList,
        allLists,
        byId: {
          ...state.byId,
          [listId]: selectedList,
        },
      };
    }

    case types.FETCH_ITEMS_FAILURE:
      return {
        ...state,
        isFetchingItems: false,
        isFetchingOneItem: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_ITEM_BEGIN:
      return {
        ...state,
        isFetchingOneItem: true,
      };

    case types.DELETE_ITEMS_BEGIN:
      return {
        ...state,
        isDeletingItems: true,
      };

    case types.DELETE_ITEMS_SUCCESS: {
      const { ids, listId } = action as IDeleteItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isDeletingItems: false,
        };
      }
      const list = state.byId[listId];
      const newItemArray = list.items.filter((item) => !ids.includes(item._id));
      const newTableItemsArray = list.table_items?.filter(
        (item) => !ids.includes(item._id)
      );

      // update the items of the list
      const selectedList = {
        ...state.selectedList,
        items: newItemArray,
        table_items: newTableItemsArray,
        table_items_count:
          (state.selectedList.table_items_count || ids.length) - ids.length,
      };

      return {
        ...state,
        isDeletingItems: false,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        selectedList,
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    case types.DELETE_ITEMS_FAILURE:
      return {
        ...state,
        isDeletingItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_ITEMS_BEGIN:
      return {
        ...state,
        isUpdatingItems: true,
      };

    case types.UPDATE_ITEMS_SUCCESS: {
      const { listId, items } = action as IUpdateItemsSuccessAction;

      const oldList = _.find(state.allLists, { id: listId });

      if (!oldList) {
        throw new Error(`list with id ${listId} not found`);
      }
      const oldItems = oldList?.items ?? [];
      const oldTableItems = oldList?.table_items ?? [];

      const newItems = updateItems(oldItems, items);
      const newTableItems = updateItems(oldTableItems, items);

      const newList = {
        ...oldList,
        items: newItems,
        table_items: newTableItems,
      };

      const allLists = updateObjectInArray(state.allLists, newList);

      let newSelectedList = state.selectedList;
      if (state.selectedList?.id === newList.id) {
        newSelectedList = newList;
      }

      return {
        ...state,
        isUpdatingItems: false,
        selectedList: newSelectedList,
        allLists,
        byId: {
          ...state.byId,
          [newList.id]: newList,
        },
      };
    }
    case types.UPDATE_ITEMS_FAILURE: {
      return {
        ...state,
        isUpdatingItems: false,
        errors: updateErrorsList(state, action),
      };
    }
    case types.ARCHIVE_ITEMS_BEGIN: {
      return {
        ...state,
        isArchivingItems: true,
      };
    }
    case types.ARCHIVE_ITEMS_FAILURE: {
      return {
        ...state,
        isArchivingItems: false,
        errors: updateErrorsList(state, action),
      };
    }
    case types.ARCHIVE_ITEMS_SUCCESS: {
      const { ids } = action as IArchiveItemsSuccessAction;

      // if the items are not loaded locally, return the store as it is
      const oldList = state.selectedList;
      if (!oldList) {
        return {
          ...state,
          isArchivingItems: false,
        };
      }

      const getArchivedItems = (items: IListItem[] = []) => {
        return _(ids)
          .map((_id) => {
            const oldItem = _.find(items, { _id });
            if (!oldItem) {
              return null;
            }
            return {
              ...oldItem,
              _active: false,
            };
          })
          .compact()
          .value();
      };

      const archivedItems = getArchivedItems(oldList.items);
      const tableArchivedItems = getArchivedItems(oldList.table_items);

      const newItems = updateItems(oldList.items, archivedItems);
      const newTableItems = updateItems(
        oldList.table_items,
        tableArchivedItems
      );

      const positiveOrZero = (count: number) => Math.max(count, 0);

      const newSelectedList = {
        ...oldList,
        items: newItems,
        item_count: oldList.item_count - _.size(archivedItems),
        table_items: newTableItems,
        table_items_count: positiveOrZero(
          (oldList.table_items_count ?? 0) - _.size(tableArchivedItems)
        ),
      };

      return {
        ...state,
        isArchivingItems: false,
        selectedList: newSelectedList,
        allLists: updateObjectInArray(state.allLists, newSelectedList),
        byId: {
          ...state.byId,
          [newSelectedList.id]: newSelectedList,
        },
      };
    }

    case types.RESTORE_ITEMS_BEGIN:
      return {
        ...state,
        isRestoringItems: true,
      };

    case types.RESTORE_ITEMS_FAILURE:
      return {
        ...state,
        isRestoringItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.RESTORE_ITEMS_SUCCESS: {
      const { ids } = action as IRestoreItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      const oldList = state.selectedList;
      if (!oldList) {
        return {
          ...state,
          isRestoringItems: false,
        };
      }

      const getRestoredItems = (items: IListItem[] = []) => {
        return _(ids)
          .map((_id) => {
            const oldItem = _.find(items, { _id });
            if (!oldItem) {
              return null;
            }
            return {
              ...oldItem,
              _active: true,
            };
          })
          .compact()
          .value();
      };

      const restoredItems = getRestoredItems(oldList.items);
      const tableRestoredItems = getRestoredItems(oldList.table_items);

      const newItems = updateItems(oldList.items, restoredItems);
      const newTableItems = updateItems(
        oldList.table_items,
        tableRestoredItems
      );

      const newSelectedList = {
        ...oldList,
        items: newItems,
        item_count: oldList.item_count + _.size(restoredItems),
        table_items: newTableItems,
        table_items_count:
          (oldList.table_items_count ?? 0) + _.size(tableRestoredItems),
      };

      return {
        ...state,
        isRestoringItems: false,
        selectedList: newSelectedList,
        allLists: updateObjectInArray(state.allLists, newSelectedList),
        byId: {
          ...state.byId,
          [newSelectedList.id]: newSelectedList,
        },
      };
    }

    case types.ASSIGN_ITEMS_BEGIN: {
      return {
        ...state,
        isAssigningItems: true,
      };
    }
    case types.ASSIGN_ITEMS_SUCCESS: {
      const { links } = action as IAssignItemsSuccessAction;
      const oldList = state.selectedList;

      // if the items are not loaded locally, return the store as it is
      if (!oldList) {
        return {
          ...state,
          isAssigningItems: false,
        };
      }

      const newItems = updateOwners(oldList.scope, oldList.items, links);
      const newTableItems = updateOwners(
        oldList.scope,
        oldList.table_items,
        links
      );

      // find the full items, update owners to reflect in app after assigning

      const newList = {
        ...oldList,
        items: newItems,
        table_items: newTableItems,
      };

      return {
        ...state,
        isAssigningItems: false,
        selectedList: newList,
        allLists: updateObjectInArray(state.allLists, newList),
        byId: {
          ...state.byId,
          [newList.id]: newList,
        },
      };
    }
    case types.ASSIGN_ITEMS_FAILURE: {
      return {
        ...state,
        isAssigningItems: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.UNASSIGN_ITEMS_BEGIN: {
      return {
        ...state,
        isUnassigningItems: true,
      };
    }
    case types.UNASSIGN_ITEMS_USING_LINKS: {
      // unassign items using links
      const { links } = action as IUnassignItemsUsingLinksAction;
      const oldList = state.selectedList;

      if (!oldList) {
        return {
          ...state,
          isUnassigningItems: false,
        };
      }
      let newItems = oldList.items;
      let newTableItems = oldList.table_items;

      _.forEach(links, (link) => {
        const item = _.find(newItems, { _id: link.item_id });
        const tableItem = _.find(newTableItems, { _id: link.item_id });

        if (item) {
          item._owners = _.filter(item._owners, (owner) => {
            return owner !== link.mobile_user_id;
          });
          newItems = updateItemInArray(
            newItems,
            item,
            {
              _id: item._id,
            },
            //do not add the item if it isnt found (should not happen anyway)
            false
          );
        }
        if (newTableItems && tableItem) {
          tableItem._owners = _.filter(tableItem._owners, (owner) => {
            return owner !== link.mobile_user_id;
          });
          newTableItems = updateItemInArray(
            newTableItems,
            tableItem,
            {
              _id: tableItem._id,
            },
            false
          );
        }
      });
      const newList = {
        ...oldList,
        items: newItems,
        table_items: newTableItems,
      };

      return {
        ...state,
        isUnassigningItems: false,
        selectedList: newList,
        allLists: updateObjectInArray(state.allLists, newList),
        byId: {
          ...state.byId,
          [newList.id]: newList,
        },
      };
    }
    case types.UNASSIGN_ITEMS_SUCCESS: {
      const { itemIds, mobileUserIds, onlyUnassignMobileUsers } =
        action as IUnassignItemsSuccessAction;

      const oldList = state.selectedList;

      // if the items are not loaded locally, return the store as it is
      if (!oldList) {
        return {
          ...state,
          isUnassigningItems: false,
        };
      }

      let newItems = oldList.items;
      let newTableItems = oldList.table_items;
      if (onlyUnassignMobileUsers && mobileUserIds) {
        newItems = unassignMobileUsersFromItems(oldList.items, mobileUserIds);
        newTableItems = unassignMobileUsersFromItems(
          oldList.table_items,
          mobileUserIds
        );
      } else {
        if (itemIds) {
          newItems = unassignItems(oldList.items, itemIds);
          newTableItems = unassignItems(oldList.table_items, itemIds);
        }
      }

      const newList = {
        ...oldList,
        items: newItems,
        table_items: newTableItems,
      };

      return {
        ...state,
        isUnassigningItems: false,
        selectedList: newList,
        allLists: updateObjectInArray(state.allLists, newList),
        byId: {
          ...state.byId,
          [newList.id]: newList,
        },
      };
    }
    case types.UNASSIGN_ITEMS_FAILURE: {
      return {
        ...state,
        isUnassigningItems: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_CUSTOMER_REPORTS_PICTURES_BEGIN: {
      return {
        ...state,
        isFetchingCustomerReportsPictures: true,
      };
    }
    case types.FETCH_CUSTOMER_REPORTS_PICTURES_FAILURE: {
      return {
        ...state,
        isFetchingCustomerReportsPictures: false,
        errors: updateErrorsList(state, action),
      };
    }
    case types.FETCH_CUSTOMER_REPORTS_PICTURES_SUCCESS: {
      const { customer_reports_pictures } =
        action as IFetchCustomerReportsPicturesSuccessAction;
      return {
        ...state,
        isFetchingCustomerReportsPictures: false,
        selectedCustomerPictures: customer_reports_pictures,
      };
    }

    case types.SELECT_LIST: {
      const { selectedList } = action as ISelectListActionCreator;
      return {
        ...state,
        selectedList,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.UPLOAD_FILE_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
      };

    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploadingFile: false,
      };
    }

    case types.DOWNLOAD_LIST_ITEM_BEGIN:
      return {
        ...state,
        isDownloadingFile: true,
      };

    case types.DOWNLOAD_LIST_ITEM_FAILURE:
      return {
        ...state,
        isDownloadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.DOWNLOAD_LIST_ITEM_SUCCESS: {
      return {
        ...state,
        isDownloadingFile: false,
      };
    }

    case types.CHANGE_LIST_ATTRIBUTE_TAG_BEGIN: {
      return {
        ...state,
        isChangingTag: true,
      };
    }
    case types.CHANGE_LIST_ATTRIBUTE_TAG_FAILURE: {
      return {
        ...state,
        isChangingTag: false,
        errors: updateErrorsList(state, action),
      };
    }
    case types.CHANGE_LIST_ATTRIBUTE_TAG_SUCCESS: {
      const { params } = action as IEditAttributeTagSuccessAction;
      const { new_tag, old_tag, matrix_tag: parent } = params;

      if (!state.selectedList) {
        return {
          ...state,
          isChangingTag: false,
        };
      }

      const selectedList = updateListSchemaByTag(
        state.selectedList,
        new_tag,
        old_tag,
        parent
      );

      return {
        ...state,
        isChangingTag: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    default:
      return state;
  }
}

const updateItems = (
  oldItems: IListItem[] = [],
  newItems: IListItem[] = []
) => {
  return _.map(oldItems, (oldItem) => {
    const updatedItem = _.find(newItems, { _id: oldItem._id });
    if (updatedItem) {
      // new values override old ones
      return { ...oldItem, ...updatedItem };
    }
    return oldItem;
  });
};

const updateOwners = (
  scope: LIST_SCOPE,
  items: IListItem[] = [],
  links: IAssignmentLink[]
) => {
  const newItems = _.map(items, (oldItem) => {
    const newOwners = _(links)
      .filter({ item_id: oldItem._id })
      .map((l) =>
        _.includes([LIST_SCOPE.SINGLE_TEAM, LIST_SCOPE.TEAM], scope)
          ? l.team_id
          : l.mobile_user_id
      )
      .value();

    const oldOwners = oldItem._owners ?? [];
    const updatedOwners = _.uniq([...oldOwners, ...newOwners]);
    return {
      ...oldItem,
      _owners: updatedOwners,
    };
  });

  return newItems;
};

const unassignItems = (items: IListItem[] = [], itemIds: string[]) => {
  return _.map(items, (item) => {
    if (_.includes(itemIds, item._id)) {
      return {
        ...item,
        _owners: [],
      };
    }
    return item;
  });
};

const unassignMobileUsersFromItems = (
  items: IListItem[] = [],
  mobileUserIds: string[]
) => {
  return _.map(items, (item) => {
    const newOwners = _.filter(item._owners, (owner) => {
      return !_.includes(mobileUserIds, owner);
    });
    return {
      ...item,
      _owners: newOwners,
    };
  });
};
