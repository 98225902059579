/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import { IMetaExpression, IMetaExpressionOptions } from "fieldpro-tools";

import apiService from "api/apiService";
import { IMetaExpressionInTable } from "model/entities/MetaExpression";
import { IServerResponse } from "redux/actions/appActions";

import { formatMetaExpressionForBackend } from "./utils";

export const META_EXPRESSIONS_ENDPOINT = `meta_expressions`;

export type TCreateEditMetaExpressionFunc = (
  actionName: string,
  metaExpression: IMetaExpression
) => Promise<IServerResponse<ICreateEditMetaExpressionResponse>>;
export interface ICreateEditMetaExpressionResponse {
  expression: string;
}

export type TComputeMetaExpressionFunc = (
  actionName: string,
  metaExpressions: string[],
  options: IMetaExpressionOptions
) => Promise<IServerResponse<IComputeMetaExpressionResponse>>;

export interface IComputeMetaExpressionResponse {
  result: { [metaExpr: string]: string };
}

export type TDeleteMetaExpressionFunc = (
  actionName: string,
  metaExpressionId: string
) => Promise<IServerResponse<IDeleteMetaExpressionResponse>>;
export interface IDeleteMetaExpressionResponse {}

export type TFetchMetaExpressionsFunc = () => Promise<
  IServerResponse<IFetchMetaExpressionsResponse>
>;
export interface IFetchMetaExpressionsResponse {
  meta_expressions: IMetaExpressionInTable[];
}
//api calls for metaExpressions
/**
 * Creates a new metaExpression
 * @param {Object} metaExpression MetaExpression details
 */
export const createEditMetaExpressionApiCall: TCreateEditMetaExpressionFunc = (
  actionName,
  metaExpression
) => {
  return apiService.post(
    `/${META_EXPRESSIONS_ENDPOINT}`,
    formatMetaExpressionForBackend(metaExpression),
    actionName
  );
};
/**
 * Delete metaExpression API call makes a POST request to delete a single metaExpression
 * @param {String} metaExpression Id of the metaExpression to delete
 * @return {Promise}
 * */
export const deleteMetaExpressionApiCall: TDeleteMetaExpressionFunc = (
  actionName,
  expression
) => {
  return apiService.delete(
    `/${META_EXPRESSIONS_ENDPOINT}/${expression}`,
    {},
    actionName
  );
};

/**
 * Api call to fetch all metaExpressions
 */
export const fetchMetaExpressionsApiCall: TFetchMetaExpressionsFunc = () => {
  return apiService.get(`/${META_EXPRESSIONS_ENDPOINT}`);
};

/**
 * Compute meta expressions
 * @param {Object} metaExpression MetaExpression details
 */
export const computeMetaExpressionApiCall: TComputeMetaExpressionFunc = (
  actionName,
  metaExpressions,
  options
) => {
  return apiService.post(
    `/${META_EXPRESSIONS_ENDPOINT}/compute`,
    {
      meta_expressions: metaExpressions,
      options,
    },
    actionName
  );
};
