import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating";
import _ from "lodash";

export interface IRatingCell {
  value?: number;
}

const RatingCell = ({ value }: IRatingCell) => {
  const rating = Number(value);
  if (!_.isFinite(rating)) {
    return null;
  }

  const icon = (
    <StarIcon
      style={{
        width: "15px",
        height: "15px",
      }}
    />
  );

  return (
    <Rating
      style={{ verticalAlign: "middle" }}
      name="rating"
      icon={icon}
      value={rating}
      size="small"
      max={10.0}
      readOnly
    />
  );
};

export default RatingCell;
