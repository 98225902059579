import _ from "lodash";
import { MapboxMap } from "react-map-gl";

import { IGeoTrackingChartData } from "../GeoTrackingChart";

interface IRouteLinesLayer {
  map?: MapboxMap;
  data: IGeoTrackingChartData;
  userColors: any;
  selectedUser?: string;
  showMatchingRoutes: boolean;
}

/**
 * TODO: could be refactored as a react-map-gl Layer, passing the data as geojson FeatureCollection of LineString's
 * (Similar to what we have for territoriesLineLayer).
 */
const RouteLinesLayer = ({
  map,
  data,
  userColors,
  selectedUser,
  showMatchingRoutes,
}: IRouteLinesLayer) => {
  if (_.isEmpty(data) || !map) {
    return null;
  }

  const width = map.getContainer().clientWidth;
  const height = map.getContainer().clientHeight;

  const originLngLat = map.unproject([0, 0]);
  const origin = map.project(originLngLat);

  const userIds = _.keys(data);

  const stepNbPerUser = {};
  for (const userId of userIds) {
    stepNbPerUser[userId] = _.keys(data[userId]).length;
  }

  const allLines = _(userIds)
    .map((userId) => {
      const originalCoords = _.keys(data[userId]).map((ts) => data[userId][ts]);

      const lines = _.keys(data[userId])
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce(
          (acc: any, curr, idx) => {
            // if the distance is too big, we don't display the path
            if (idx > 0) {
              if (
                Math.sqrt(
                  (originalCoords[acc.lastIdx].lng - data[userId][curr].lng) *
                    (originalCoords[acc.lastIdx].lng - data[userId][curr].lng) +
                    (originalCoords[acc.lastIdx].lat - data[userId][curr].lat) *
                      (originalCoords[acc.lastIdx].lat - data[userId][curr].lat)
                ) > 10
              ) {
                return acc;
              }
            }

            // we change the referential with the "project" function
            const coord = map.project([
              data[userId][curr].lng,
              data[userId][curr].lat,
            ]);

            const x2 = coord.x;
            const y2 = coord.y;

            // first and last element of the list: we don't draw anything
            if (idx !== 0 && idx !== stepNbPerUser[userId]) {
              acc.lines.push({
                userId,
                x1: acc.lines[acc.lines.length - 1].x2,
                y1: acc.lines[acc.lines.length - 1].y2,
                x2,
                y2,
              });
            } else {
              acc.lines.push({ x2, y2 });
            }

            acc.lastIdx = idx;
            return acc;
          },
          { lastIdx: 0, lines: [] } as any
        );

      return lines.lines;
    })
    .compact()
    .flatten()
    .value();

  return (
    <svg
      width={width}
      height={height}
      viewBox={`${-origin.x} ${-origin.y} ${width} ${height}`}
    >
      {allLines.slice(1, allLines.length).map((line: any, idx: number) => {
        const shouldHighlightUser =
          !selectedUser || selectedUser === line.userId;

        const strokeProps: any = {
          stroke: `${userColors[line.userId]}${
            // See https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
            shouldHighlightUser ? "B3" : "33"
          }`,
          strokeWidth: shouldHighlightUser ? "5" : "3",
        };

        if (shouldHighlightUser && showMatchingRoutes) {
          strokeProps.stroke = `${userColors[line.userId]}55`;
          strokeProps.strokeDasharray = "1, 6";
          strokeProps.strokeLinecap = "round";
        }
        return (
          <line
            id={`route-line-${line.userId}-${idx}`}
            key={`route-line-${line.userId}-${idx}`}
            x1={line.x1}
            x2={line.x2}
            y1={line.y1}
            y2={line.y2}
            {...strokeProps}
          />
        );
      })}
    </svg>
  );
};

export default RouteLinesLayer;
