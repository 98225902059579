/**
 * @notes: Dashboard action types
 */
export const CREATE_DASHBOARD_BEGIN = "CREATE_DASHBOARD_BEGIN";
export const CREATE_DASHBOARD_SUCCESS = "CREATE_DASHBOARD_SUCCESS";
export const CREATE_DASHBOARD_FAILURE = "CREATE_DASHBOARD_FAILURE";

export const UPDATE_DASHBOARD_BEGIN = "UPDATE_DASHBOARD_BEGIN";
export const UPDATE_DASHBOARD_SUCCESS = "UPDATE_DASHBOARD_SUCCESS";
export const UPDATE_DASHBOARD_FAILURE = "UPDATE_DASHBOARD_FAILURE";
export const MOVE_ARCHIVED_DASHBOARDS_TO_DEFAULT_FOLDER_SUCCESS =
  "MOVE_ARCHIVED_DASHBOARDS_TO_DEFAULT_FOLDER_SUCCESS";

export const FETCH_ALL_DASHBOARDS_BEGIN = "FETCH_ALL_DASHBOARDS_BEGIN";
export const FETCH_ALL_DASHBOARDS_SUCCESS = "FETCH_ALL_DASHBOARDS_SUCCESS";
export const FETCH_ALL_DASHBOARDS_FAILURE = "FETCH_ALL_DASHBOARDS_FAILURE";

export const FETCH_FREQUENTLY_VIEWED_DASHBOARDS_BEGIN =
  "FETCH_FREQUENTLY_VIEWED_DASHBOARDS_BEGIN";
export const FETCH_FREQUENTLY_VIEWED_DASHBOARDS_SUCCESS =
  "FETCH_FREQUENTLY_VIEWED_DASHBOARDS_SUCCESS";
export const FETCH_FREQUENTLY_VIEWED_DASHBOARDS_FAILURE =
  "FETCH_FREQUENTLY_VIEWED_DASHBOARDS_FAILURE";

export const COMPUTE_DASHBOARD_BEGIN = "COMPUTE_ALL_DASHBOARDS_BEGIN";
export const COMPUTE_DASHBOARD_SUCCESS = "COMPUTE_ALL_DASHBOARDS_SUCCESS";
export const COMPUTE_DASHBOARD_FAILURE = "COMPUTE_ALL_DASHBOARDS_FAILURE";

export const DELETE_DASHBOARD_BEGIN = "DELETE_DASHBOARD_BEGIN";
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";
export const DELETE_DASHBOARD_FAILURE = "DELETE_DASHBOARD_FAILURE";

export const ARCHIVE_DASHBOARDS_BEGIN = "ARCHIVE_DASHBOARDS_BEGIN";
export const ARCHIVE_DASHBOARDS_SUCCESS = "ARCHIVE_DASHBOARDS_SUCCESS";
export const ARCHIVE_DASHBOARDS_FAILURE = "ARCHIVE_DASHBOARDS_FAILURE";

export const RESTORE_DASHBOARDS_BEGIN = "RESTORE_DASHBOARDS_BEGIN";
export const RESTORE_DASHBOARDS_SUCCESS = "RESTORE_DASHBOARDS_SUCCESS";
export const RESTORE_DASHBOARDS_FAILURE = "RESTORE_DASHBOARDS_FAILURE";

export const COMPUTE_KPI_BEGIN = "COMPUTE_KPI_BEGIN";
export const COMPUTE_KPI_SUCCESS = "COMPUTE_ALL_KPI_SUCCESS";
export const COMPUTE_KPI_FAILURE = "COMPUTE_ALL_KPI_FAILURE";

export const CLEAR_DATA = "CLEAR_DATA";

export const DOWNLOAD_DASHBOARD_KPI_BEGIN = "DOWNLOAD_DASHBOARD_KPI_BEGIN";
export const DOWNLOAD_DASHBOARD_KPI_SUCCESS = "DOWNLOAD_DASHBOARD_KPI_SUCCESS";
export const DOWNLOAD_DASHBOARD_KPI_FAILURE = "DOWNLOAD_DASHBOARD_KPI_FAILURE";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";
