import { useEffect, useState } from "react";

import { Box, Button, Fade, makeStyles, Paper } from "@material-ui/core";
import _ from "lodash";

import { formatOptions } from "components/Filter/FilterModals/components/utils";
import InputSearch from "components/Input/InputSearch";
import CustomSpinner from "components/Progress/CustomSpinner";
import ScrollEdgeListener from "components/ScrollEdgeListener";
import {
  fetchItemsForListAction,
  fetchListOptionsAction,
} from "containers/lists/redux/actions";
import { IQuery } from "containers/lists/utils";
import { useActions, useTranslations } from "hooks";
import { IOption } from "model/application/components";

import InputCheckList from "./InputCheckList";
import OptionsFilterContainer from "./OptionsFilterContainer";

const MAX_ITEMS_TO_FETCH = 20;
const useStyles = makeStyles({
  paper: {},
  container: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    padding: "8px",
    minHeight: 150,
  },
  title: {
    fontWeight: 500,
  },
  searchBox: {},
  optionsContainer: {
    maxHeight: 300,
    overflow: "auto",
  },
  buttonContainer: {
    display: "grid",
    justifyItems: "end",
    gridRowGap: "8px",
  },
  buttonRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "16px",
  },
});

export interface IMultipleOptionsOnListSelectorProps {
  selectedOptions: IOption[];
  listId: string;
  onAddOptions: (options: Array<IOption>) => void;
  onRemoveOptions: (options: Array<IOption>) => void;
  handleClose: () => void;
  handleSave: () => void;
  handleCancel: () => void;
  multipleSelection?: boolean;
  searchDebounceTime?: number;
  infiniteScrollDebounceTime?: number;
  showFilter?: boolean;
}

function MultipleOptionsOnListSelector({
  listId,
  selectedOptions,
  onAddOptions,
  onRemoveOptions,
  handleSave,
  handleCancel,
  multipleSelection,
  searchDebounceTime = 500,
  infiniteScrollDebounceTime = 100,
  showFilter,
}: Readonly<IMultipleOptionsOnListSelectorProps>) {
  const [initialOptionsCache, setInitialOptionsCache] = useState<
    IOption[] | undefined
  >(undefined);

  const [options, setOptions] = useState<IOption[] | undefined>(undefined);

  const [listQuery, setListQuery] = useState<IQuery>({});

  const [fetchListOptions, fetchItemsForList] = useActions([
    fetchListOptionsAction,
    fetchItemsForListAction,
  ]);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [isSearching, setIsSearching] = useState<boolean>(false);

  const classes = useStyles();

  const lang = useTranslations();

  const [listSize, setListSize] = useState(MAX_ITEMS_TO_FETCH);

  const [currentPage, setCurrentPage] = useState(0);

  const [isPaginating, setIsPaginating] = useState(false);

  async function handleSearch(searchTerm: string, query: IQuery = listQuery) {
    const searchQuery = searchTerm === "" ? " " : searchTerm;
    setIsSearching(true);
    if (!listId) {
      throw new Error(
        "ListId is required for MultipleOptionsOnListSelector's search"
      );
    }
    const newOptions = await fetchListOptions(listId, {
      limit: MAX_ITEMS_TO_FETCH,
      filters: query,
      search: searchQuery,
    });
    setOptions(formatOptions(newOptions));
    setIsSearching(false);
  }

  const debouncedHandleSearch = _.debounce(handleSearch, searchDebounceTime);

  function onInputSearchTerm(value: string) {
    setSearchTerm(value);
  }
  const debouncedOnInputSearchTerm = _.debounce(
    onInputSearchTerm,
    searchDebounceTime
  );

  function getFilterOptions() {
    return _.compact(options);
  }

  async function loadInitialOptions() {
    setIsSearching(true);
    if (!initialOptionsCache) {
      const result = await fetchItemsForList(
        listId,
        {
          filters: {},
          limit: MAX_ITEMS_TO_FETCH,
          include_linked_options: true,
          order: "asc",
          order_by: "_displayed_name",
          with_count: true,
        },
        { skipStoreUpdate: true },
        {}
      );

      setOptions(formatOptions(result?.items));

      setInitialOptionsCache(formatOptions(result?.items));

      setListSize(result?.item_count ?? MAX_ITEMS_TO_FETCH);
    } else {
      setOptions(initialOptionsCache);
    }
    setIsSearching(false);
  }

  const handleInfiniteScroll = async () => {
    const nextPage = currentPage + 1;
    const newOffset = nextPage * MAX_ITEMS_TO_FETCH;
    if (isSearching || newOffset >= listSize || _.size(options) >= listSize)
      return;

    setIsPaginating(!isSearching);

    let newOptions: typeof options = [];
    if (_.isEmpty(searchTerm) && _.isEmpty(listQuery)) {
      const result = await fetchItemsForList(
        listId,
        {
          filters: {},
          limit: MAX_ITEMS_TO_FETCH,
          include_linked_options: true,
          order: "asc",
          order_by: "_displayed_name",
          offset: nextPage * MAX_ITEMS_TO_FETCH,
        },
        {
          skipStoreUpdate: true,
        },
        {}
      );
      newOptions = formatOptions(result?.items);
    } else {
      const searchQuery = searchTerm === "" ? " " : searchTerm;

      const searchResults = await fetchListOptions(listId, {
        offset: newOffset,
        limit: MAX_ITEMS_TO_FETCH,
        filters: listQuery,
        search: searchQuery,
      });
      newOptions = formatOptions(searchResults);
    }

    setOptions((prevOptions) => {
      if (prevOptions) {
        return _.compact(_.uniqBy(_.concat(prevOptions, newOptions), "key"));
      }
      return newOptions;
    });

    setIsPaginating(false);
    setCurrentPage(nextPage);
  };

  const debouncedHandleInfiniteScroll = _.debounce(
    handleInfiniteScroll,
    infiniteScrollDebounceTime
  );

  useEffect(() => {
    if (_.isEmpty(searchTerm) && _.isEmpty(listQuery)) {
      loadInitialOptions();
    } else {
      handleSearch(searchTerm as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, JSON.stringify(listQuery)]);

  return (
    <Paper
      className={classes.paper}
      data-testid="MultipleOptionsOnListSelector"
    >
      <Box className={classes.container}>
        {showFilter && (
          <OptionsFilterContainer
            listId={listId}
            onChange={(query) => {
              const newQuery = _.omit({ ...query }, "_more_filter");
              setCurrentPage(0);
              debouncedHandleSearch(searchTerm ?? " ", newQuery);
              setListQuery(newQuery);
            }}
          />
        )}

        <Box className={classes.title}>
          <InputSearch
            onChange={debouncedOnInputSearchTerm}
            about={lang.components.search}
            className={classes.searchBox}
          />
        </Box>
        <Box className={classes.optionsContainer}>
          {!isSearching && (
            <InputCheckList
              onAddOption={(option) => onAddOptions([option])}
              onRemoveOption={(option) => onRemoveOptions([option])}
              options={getFilterOptions()}
              selectedOptions={selectedOptions}
            />
          )}

          <ScrollEdgeListener
            key={String(isSearching)}
            callback={() => {
              debouncedHandleInfiniteScroll();
            }}
            threshold={0.1}
          >
            <Box
              paddingBottom={2}
              height={"50px"}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Fade in={isPaginating && !isSearching}>
                <CustomSpinner />
              </Fade>
            </Box>
          </ScrollEdgeListener>

          {isSearching && (
            <Box
              width={"100%"}
              height={150}
              display={"grid"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <CustomSpinner />
            </Box>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          {multipleSelection && (
            <Box className={classes.buttonRow}>
              <Box>
                <Button
                  onClick={() => {
                    onRemoveOptions(
                      _.concat(getFilterOptions(), selectedOptions)
                    );
                  }}
                >
                  {lang.modal.deselectAll}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    onAddOptions(_.concat(getFilterOptions(), selectedOptions));
                  }}
                >
                  {lang.genericTerms.selectAll}
                </Button>
              </Box>
            </Box>
          )}

          <Box className={classes.buttonRow}>
            <Box>
              <Button onClick={handleCancel}>{lang.genericTerms.cancel}</Button>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                onClick={handleSave}
              >
                {lang.genericTerms.save}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default MultipleOptionsOnListSelector;
