import { makeStyles } from "@material-ui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Black } from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";

const useStyles = makeStyles({
  copyIcon: (props: IBasicCopyIcon) => ({
    color: props.color ?? Black,
    fontSize: "24px",
    opacity: 0.5,
  }),
});

interface IBasicCopyIcon {
  color?: string;
  text: string;
  onClick?: () => void;
}

const BasicCopyIcon = (props: IBasicCopyIcon) => {
  const classes = useStyles(props);

  return (
    <SmallIconButton onClick={props.onClick}>
      <ContentCopyIcon className={classes.copyIcon} />
    </SmallIconButton>
  );
};

export default BasicCopyIcon;
