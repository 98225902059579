import { ReactElement, useState } from "react";

import { Box } from "@material-ui/core";

import { ChartDataUtils } from "components/Dashboard/Chart/ChartDataUtils";
import PictureDialog from "components/Dialog/PictureDialog";
import getValueAsString from "utils/formatting/getValueAsString";
import { isEmptyValue } from "utils/isEmptyValue";

import { TABLE_COLUMN_TYPE } from "../model";
import BooleanCell from "./BooleanCell";
import DateCell from "./DateCell";
import OptionsCell, { getOptionsFromLabelList } from "./OptionsCell";
import PictureCell from "./PictureCell";
import RatingCell from "./RatingCell";
import TimeCell from "./TimeCell";

export interface ICellContentProps {
  type?: TABLE_COLUMN_TYPE;
  value?: any;
}

const CellContent = ({
  type,
  value,
}: ICellContentProps): ReactElement | null => {
  const [pictureURL, setPictureURL] = useState(null);
  switch (type) {
    case TABLE_COLUMN_TYPE.TEXT: {
      return <span>{value}</span>;
    }
    case TABLE_COLUMN_TYPE.NUMBER: {
      return (
        <span>
          {isEmptyValue(value)
            ? ""
            : ChartDataUtils.addThousandSeparator(value, " ")}
        </span>
      );
    }
    case TABLE_COLUMN_TYPE.DATE: {
      return <DateCell date={value} />;
    }
    case TABLE_COLUMN_TYPE.TIME: {
      return <TimeCell date={value} />;
    }
    case TABLE_COLUMN_TYPE.PICTURE: {
      const { url } = value || {};
      return (
        <>
          <PictureCell link={url} onClick={() => setPictureURL(url)} />
          {pictureURL && (
            <PictureDialog
              open
              url={pictureURL}
              onClose={() => setPictureURL(null)}
            />
          )}
        </>
      );
    }
    case TABLE_COLUMN_TYPE.BOOLEAN: {
      return <BooleanCell value={value} />;
    }
    case TABLE_COLUMN_TYPE.SINGLE_CHOICE:
    case TABLE_COLUMN_TYPE.SINGLE_CHOICE_ON_LIST: {
      const options = getOptionsFromLabelList([value]);
      return (
        <Box margin="-8px 0">
          <OptionsCell options={options} size="small" />
        </Box>
      );
    }
    case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE:
    case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      const options = getOptionsFromLabelList(value);
      return (
        <Box margin="-8px 0">
          <OptionsCell options={options} size="small" />
        </Box>
      );
    }
    case TABLE_COLUMN_TYPE.RATING: {
      return <RatingCell value={value} />;
    }
    case TABLE_COLUMN_TYPE.SKU: {
      const { image, label } = value || {};
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gridGap={"8px"}
        >
          <Box>
            <PictureCell link={image} onClick={() => setPictureURL(image)} />
            {pictureURL && (
              <PictureDialog
                open
                url={pictureURL}
                onClose={() => setPictureURL(null)}
              />
            )}
          </Box>
          <Box>{label}</Box>
        </Box>
      );
    }
    case TABLE_COLUMN_TYPE.ACTION: {
      const { icon, action } = value || {};
      return <Box onClick={action}>{icon}</Box>;
    }
    default: {
      const valueAsString = getValueAsString(value);
      return <span>{valueAsString}</span>;
    }
  }
};

export default CellContent;
