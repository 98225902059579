import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";

/**
 * Gets all metaExpressions from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllMetaExpressions = (state: IRootState) => {
  return state?.meta_expressions?.metaExpressions;
};

/**
 * Selector to get all MetaExpressions from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allMetaExpressionsSelector = customSelectorCreator(
  [getAllMetaExpressions],
  (allMetaExpressions) => {
    // map all the metaExpressions
    return allMetaExpressions;
  }
);

/**
 * Gets all solved metaExpressions from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllSolvedMetaExpressions = (state: IRootState) => {
  return state?.meta_expressions?.metaExpressionsSolved;
};

/**
 * Selector to get all MetaExpressions solved from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allMetaExpressionsSolvedSelector = customSelectorCreator(
  [getAllSolvedMetaExpressions],
  (allMetaExpressions) => {
    // map all the metaExpressions
    return allMetaExpressions;
  }
);

/**
 * Gets the status of fetching a metaExpression
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingAllMetaExpressions = (state: IRootState) =>
  state.meta_expressions.isFetchingAll;

/**
 * Gets the status of creating a metaExpression
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingMetaExpression = (state: IRootState) =>
  state.meta_expressions.isCreating;

/**
 * Gets the status of updating a metaExpression
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingMetaExpression = (state: IRootState) =>
  state.meta_expressions.isUpdating;

/**
 * Gets the status of deleting a metaExpression
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingMetaExpression = (state: IRootState) =>
  state.meta_expressions.isDeleting;

/**
 * Gets the status of computing a metaExpression
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsComputingMetaExpression = (state: IRootState) =>
  state.meta_expressions.isComputing;
