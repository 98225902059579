import React from "react";

import { Typography } from "@material-ui/core";

class CustomTypography extends React.Component {
  render() {
    const { ...rest } = this.props;
    return <Typography {...rest} />;
  }
}

/**
 * Prop validation
 */
CustomTypography.propTypes = {
  ...Typography.propTypes,
};

export default CustomTypography;
