import { ITrigger } from "fieldpro-tools";

import apiService from "api/apiService";
import { IServerResponse } from "redux/actions/appActions";

export const TRIGGERS_ENDPOINT = "/triggers";
interface IFetchAllTriggersResponse {
  triggers: ITrigger[];
}

export const fetchAllTriggersApiCall = (): Promise<
  IServerResponse<IFetchAllTriggersResponse>
> => {
  return apiService.get(`${TRIGGERS_ENDPOINT}`);
};

interface IDeleteTriggerReportResponse {}

export const deleteTriggerApiCall = (
  triggerId: string
): Promise<IServerResponse<IDeleteTriggerReportResponse>> => {
  return apiService.delete(`${TRIGGERS_ENDPOINT}/${triggerId}`, {});
};

interface ICreateTriggersResponse {
  trigger: ITrigger;
}

export const createTriggerApiCall = (
  trigger: ITrigger
): Promise<IServerResponse<ICreateTriggersResponse>> => {
  return apiService.post(`${TRIGGERS_ENDPOINT}`, trigger);
};

interface IUpdateTriggersResponse {
  triggerId: string;
  trigger: ITrigger;
}

export const UpdateTriggerApiCall = (
  triggerId: string,
  trigger: ITrigger
): Promise<IServerResponse<IUpdateTriggersResponse>> => {
  return apiService.patch(`${TRIGGERS_ENDPOINT}/${triggerId}`, trigger);
};
