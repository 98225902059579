import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useLocation } from "react-router-dom";

import { Green } from "assets/colors";
import CustomButton, {
  BUTTON_COLOR,
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import { WarningModalContext } from "containers/app/AppProviders";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import TLang from "model/application/Lang";
import { HOME_ROUTE } from "model/constants/routes";
import { KPI_TYPE } from "model/entities/Dashboard";

export interface ITimeoutChartProps {
  chartType: KPI_TYPE;
  downloadDashboard: () => any;
}
const useStyle = makeStyles({
  timeIcon: {
    height: "36px !important",
    width: "36px !important",
    color: Green,
    marginBottom: "10px",
  },
  timeoutContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  timeoutSpan: {
    color: Green,
  },
});
const TYPES_NOT_WORKING_WITH_DEGRADED_DOWNLOAD = [
  KPI_TYPE.SCORECARD,
  KPI_TYPE.GAUGE,
];

const getMessage = (chartType: KPI_TYPE, lang: TLang) => {
  if (!TYPES_NOT_WORKING_WITH_DEGRADED_DOWNLOAD.includes(chartType)) {
    return (
      <>
        <p>
          {
            lang.containers.dashboards.subCategories.dashboards.timeoutChart
              .downloadable
          }
        </p>
      </>
    );
  } else
    return (
      <>
        {formatString(
          lang.containers.dashboards.subCategories.dashboards.timeoutChart
            .notDownloadable,
          [chartType]
        )}
      </>
    );
};

export const TimeoutChart: React.FunctionComponent<ITimeoutChartProps> = ({
  chartType,
  downloadDashboard,
}: ITimeoutChartProps) => {
  const lang = useTranslations();
  const classes = useStyle();
  const ctx = useContext(WarningModalContext);
  const location = useLocation();
  const langKey =
    lang.containers.dashboards.subCategories.dashboards.timeoutChart;
  if (location.pathname === HOME_ROUTE) {
    return null;
  }
  return (
    <div className={classes.timeoutContainer}>
      <AccessTimeIcon className={classes.timeIcon} />
      <span className={classes.timeoutSpan}>{langKey.title}</span>

      {getMessage(chartType, lang)}
      {TYPES_NOT_WORKING_WITH_DEGRADED_DOWNLOAD.includes(chartType) ? (
        <></>
      ) : (
        <CustomButton
          type={BUTTON_TYPES_OUTSIDE_TABLE.ACTION}
          color={BUTTON_COLOR.SUCCESS}
          onClick={() => {
            downloadDashboard();
            ctx.openWarningModal({
              title: langKey.downloadMessage.title,
              description: langKey.downloadMessage.description,
            });
          }}
        >
          {lang.actions.download}
        </CustomButton>
      )}
    </div>
  );
};

export default TimeoutChart;
