import React from "react";

import Button from "@material-ui/core/Button";

import useTranslations from "hooks/useTranslations";

export interface IFilterValidateButtonProps {
  classes: any;
  fullWidth?: boolean;
}

export const FilterValidateButton: React.FunctionComponent<
  IFilterValidateButtonProps
> = (props: IFilterValidateButtonProps) => {
  const { classes, fullWidth } = props;
  const lang = useTranslations();

  return (
    <div className={classes.FilterButtonWrapper}>
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        className={classes.FilterButton}
        fullWidth={fullWidth}
      >
        <span className={classes.FilterButtonLabel}>{lang.modal.apply}</span>
      </Button>
    </div>
  );
};

export default FilterValidateButton;
