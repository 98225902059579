import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import TLang from "model/application/Lang";

import { CustomDialogWarning } from "../Dialog/CustomDialogWarning";
import CustomStack from "./CustomStack";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

interface ICustomNotiStackProps {
  lang: TLang;
  notification: any;
}

const CustomNotiStack: React.FunctionComponent<ICustomNotiStackProps> = ({
  lang,
  notification,
}) => {
  const classes = useStyles();
  const [isDisplayingDetail, setIsDisplayingDetail] = useState(false);

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        root: classes.notistack,
      }}
    >
      <CustomDialogWarning
        singleButton={true}
        confirmBtnText={lang.modal.proceed}
        isOpen={isDisplayingDetail}
        onClose={() => setIsDisplayingDetail(false)}
        rootLang={lang}
        lang={{
          title: notification.message,
          description: notification?.detailedMessage,
        }}
      />
      <CustomStack
        lang={lang}
        notification={notification}
        setIsDisplayingDetail={setIsDisplayingDetail}
        isDisplayingDetail={isDisplayingDetail}
      />
    </SnackbarProvider>
  );
};

export default CustomNotiStack;
