import { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import classNames from "classnames";

import useResizeHandle from "hooks/useResizeHandle";

import { TTableLayout } from "../model";
import styles from "../styles";
import { ITableSorting } from "./CustomTable";
import { GradientSeparator } from "./CustomTableRow";

interface ICustomTableHeaderItemProps {
  classes: any;
  smallWidth?: boolean;
  isSortable?: boolean;
  isResizable?: boolean;
  index?: number;
  hasCheckBox?: boolean;
  orderBy?: string;
  order?: "asc" | "desc";
  column: { name: string; title: string };
  handleSorting?: (sorting: ITableSorting) => void;
  tableLayout?: TTableLayout;
  setTableLayout?: (tableLayout: TTableLayout) => void;
  scrollValue?: {
    x: number;
    y: number;
  };
}

const formatTitle = (title: string): string => {
  return title || "";
};

const MIN_COL_WIDTH = 100; // 3-letter colnames fit (like GPS)

const CustomTableHeaderItem: FunctionComponent<ICustomTableHeaderItemProps> = ({
  smallWidth = false,
  handleSorting,
  column,
  index,
  hasCheckBox,
  orderBy,
  order = "asc",
  isSortable = true,
  isResizable = true,
  classes,
  scrollValue,
  tableLayout,
  setTableLayout,
  ...rest
}) => {
  const onMouseDown = setTableLayout
    ? () => setTableLayout("fixed")
    : undefined;

  const { ref, width, onResizeHandleMouseDown } = useResizeHandle({
    onMouseDown,
    minWidth: MIN_COL_WIDTH,
  });

  const displayedTitle = smallWidth
    ? (column.title || "")[0]
    : formatTitle(column.title);

  const onClickSort = async () => {
    if (!handleSorting) {
      return;
    }
    let newOrder: "desc" | "asc" = "asc";
    if (column.name === orderBy) {
      newOrder = order === "asc" ? "desc" : "asc";
    }
    return handleSorting({ orderBy: column.name, order: newOrder });
  };

  return (
    <TableCell
      ref={ref}
      className={classes.TableHeaderCell}
      sort-direction={
        orderBy ? (orderBy === column.name ? "true" : "false") : "false"
      }
      data-field={(column.title || "").toLowerCase()}
      data-sortable={isSortable ? "true" : "false"}
      {...rest}
      style={{
        ...(tableLayout === "fixed" && { width }),
        ...(index === 0
          ? {
              position: "sticky",
              left: hasCheckBox ? 57 : 1,
              zIndex: 1000, // hide sort arrows on scroll
            }
          : { position: "relative" }),
      }}
    >
      <TableSortLabel
        active={column ? orderBy === column.name : false}
        direction={order}
        onClick={onClickSort}
        className={classNames(classes.TableSortLabel, classes.TableHeader)}
        disabled={!isSortable}
      >
        <Typography
          variant="overline"
          className={classNames(
            classes.TableHeaderCellContent,
            classes.TextEllipsis
          )}
          component="div"
          title={displayedTitle}
        >
          {displayedTitle}
        </Typography>
      </TableSortLabel>

      {isResizable && (
        <ResizeHandle classes={classes} onMouseDown={onResizeHandleMouseDown} />
      )}

      {index === 0 && (scrollValue?.x ?? 0) > 0 && <GradientSeparator />}
    </TableCell>
  );
};

const ResizeHandle = (props: any) => {
  return (
    <div
      className={props.classes.ResizeHandleContainer}
      onMouseDown={props?.onMouseDown}
    >
      <div className={props.classes.ResizeHandle} />
    </div>
  );
};

export default withStyles(styles as any)(CustomTableHeaderItem);
