export function getRandomNsizedDigit(range: number) {
  const rangeStart = 10 ** (range - 1);
  const rangeEnd = 10 ** range - 1;
  const number = Math.floor(
    Math.random() * (rangeEnd - rangeStart) + rangeStart
  );
  return number;
}

interface IConvertNameToTagOptions {
  prefix?: string;
  maxLength?: number;
}

export function convertNameToTag(
  name: string,
  options?: IConvertNameToTagOptions
): string {
  const { prefix = "", maxLength } = options || {};

  if (!name) {
    return "";
  }

  let result = name;

  // Replace spaces with underscores
  result = result.replaceAll(" ", "_");

  // Convert to lowercase
  result = result.toLowerCase();

  // Replace non-alphanumeric characters with underscores
  result = result.replace(/[^\w\s]/gi, "_");

  // Add the prefix
  result = prefix + result;

  // Truncate the result if maxLength is defined and the result exceeds this length
  if (maxLength !== undefined && result.length > maxLength) {
    result = result.slice(0, maxLength);
  }

  return result;
}
