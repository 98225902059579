import _ from "lodash";

import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import { TIMELINE_EVENT_TYPE, TTimelineData } from "model/entities/Dashboard";

interface IRemoveStepsWithMissingReports {
  data: TTimelineData[];
  reports: IAugmentedActivityReport[];
}

export function removeStepsWithMissingReports({
  data,
  reports,
}: IRemoveStepsWithMissingReports) {
  const filteredSteps = data.filter((step) => {
    if (
      step.action_code === TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT &&
      step.activity_report_id
    ) {
      const report = _.find(
        reports,
        (report) => report?.metadata?._id === step.activity_report_id
      );
      return !_.isUndefined(report);
    }
    if (
      step.action_code === TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT &&
      !step.activity_report_id
    ) {
      return false;
    }
    return true;
  });
  return filteredSteps;
}
