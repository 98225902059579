import apiService from "api/apiService";
import { DOCUMENT_SCOPE, IDocument } from "model/entities/Document";
import { IServerResponse } from "redux/actions/appActions";

const DOCUMENTS_ENDPOINT = "documents";

export const fetchDocumentsForClientApiCall = (): Promise<
  IServerResponse<IDocument[]>
> => {
  return apiService.get(`${DOCUMENTS_ENDPOINT}`);
};

export type UploadDocumentsFunc = (
  actionName: string,
  document: Blob,
  fileName: string,
  teams: string[],
  scope: DOCUMENT_SCOPE,
  folder: string
) => Promise<IServerResponse<any>>;
export const uploadDocumentsRequest: UploadDocumentsFunc = (
  actionName,
  documents,
  fileName,
  teams,
  scope,
  folder
) => {
  return apiService.multipartUpdate(
    `${DOCUMENTS_ENDPOINT}`,
    [{ file: documents, fileName: fileName }],
    { file_metas: { name: fileName, teams, scope, folder } },
    actionName
  );
};

export type DeleteDocumentsFunc = (
  actionName: string,
  documentId: string
) => Promise<IServerResponse<any>>;
export const deleteDocumentsRequest: DeleteDocumentsFunc = (
  actionName,
  documentId
) => {
  return apiService.delete(
    `${DOCUMENTS_ENDPOINT}/${documentId}`,
    {},
    actionName
  );
};
export type UpdateDocumentFunc = (
  actionName: string,
  id: string,
  doc: {
    fileName: string;
    teams: string[];
    scope: DOCUMENT_SCOPE;
    folder: string;
  }
) => Promise<
  IServerResponse<{
    document: {
      id: string;
      fileName: string;
      teams: string[];
      scope: DOCUMENT_SCOPE;
      folder: string;
    };
  }>
>;
export const updateDocumentRequest: UpdateDocumentFunc = (
  actionName,
  id,
  doc: {
    fileName: string;
    teams?: string[];
    scope: DOCUMENT_SCOPE;
    folder: string;
  }
) => {
  return apiService.patch(`${DOCUMENTS_ENDPOINT}/${id}/infos`, doc, actionName);
};
