import { Button } from "@material-ui/core";

import useOpenStripePortal from "hooks/useOpenStripePortal";
import useTranslations from "hooks/useTranslations";

const StripePortalButton = ({
  clientId,
  text,
}: {
  clientId: string;
  text?: string;
}) => {
  const lang = useTranslations();
  const openStripePortal = useOpenStripePortal(clientId);
  return (
    <Button
      color="secondary"
      variant="contained"
      disableElevation
      onClick={openStripePortal}
    >
      {text || lang.actions.buyLicenses}
    </Button>
  );
};

export default StripePortalButton;
