import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Marker } from "react-map-gl";

import { primaryColor } from "assets/colors";

import { TClusterFeature } from "./ClusterLayer";

const styles = {
  customCluster: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: primaryColor,
    borderRadius: "50%",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
} as const;

const useStyles = makeStyles(styles);

export interface IClusterMarkerProps {
  cluster: TClusterFeature;
  onClusterClick: () => void;
  size: number;
  pieChartColors: string;
}

export type TClusterMarker = React.FunctionComponent<IClusterMarkerProps>;

export const ClusterMarker: TClusterMarker = (props) => {
  const classes = useStyles();

  const { cluster, onClusterClick, size: dimension, pieChartColors } = props;
  const [longitude, latitude] = cluster.geometry.coordinates;
  return (
    <Marker
      key={cluster.properties.cluster_id}
      latitude={latitude}
      longitude={longitude}
      draggable={false}
      anchor="center"
    >
      <div
        style={{
          width: dimension,
          height: dimension,
          borderRadius: "50%",
          backgroundImage: `${pieChartColors}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onClick={onClusterClick}
          className={classes.customCluster}
          style={{
            width: dimension - 12,
            height: dimension - 12,
          }}
        >
          {cluster.properties.point_count}
        </div>
      </div>
    </Marker>
  );
};

export default ClusterMarker;
