import _ from "lodash";

import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import { IActivityReportInTable, IQuestion } from "model/entities/Workflow";

import prepareCustomFieldDataForBackend from "./prepareCustomFieldDataForBackend";

interface IConvertActivityReport {
  questionsSchema: IQuestion[];
  report: IActivityReportInTable;
  simpleMatrixFormat?: boolean;
}

/**
 * Turn an activity report formatted for Inputs (see formatCustomFieldValues in formatActivityReport)
 * into a report that can be sent to the mobile API (for creation, edition)
 */
const convertActivityReport = ({
  questionsSchema,
  report,
  simpleMatrixFormat = false,
}: IConvertActivityReport) => {
  const convertedReport = { ...report };
  _.each<IQuestion>(questionsSchema, (customField) => {
    const tagKey = getTagKey(customField);
    const fieldTag = customField[tagKey];

    convertedReport[fieldTag] = prepareCustomFieldDataForBackend({
      question: customField,
      answer: report[fieldTag],
      simpleMatrixFormat,
    });
  });
  return convertedReport;
};

export default convertActivityReport;
